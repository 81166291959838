import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Col, Tabs } from 'antd';
import actions from 'store/actions';
import {
    SuccessResult,
    DataObjectRequestVerificationModal
} from "components";
import { showError, showSuccess, showWarning, showInfo } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { SafetyCertificateTwoTone } from "@ant-design/icons";

class DataObjectRequestVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendingLink: false,
            showVerificationModal: false
        }
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onCreateDataObjectVerificationResultChanged(prevProps);
    }

    onCreateDataObjectVerificationResultChanged = (prevProps) => {
        if (this.state.sendingLink && this.props.createDataObjectVerificationResult && this.props.createDataObjectVerificationResult !== prevProps.createDataObjectVerificationResult) {
            if (!this.props.createDataObjectVerificationResult.success) {
                showError("An error occurred while sending magic link. Please try again.");
            }
            else {
                let successMessage = "Verification magick link sent.";
                let okMessage = "Ok";
                if (this.props.createDataObjectVerificationResult.result && this.props.createDataObjectVerificationResult.result.bulkVerification) {
                    successMessage = "Bulk verification job created.";
                    okMessage = "Go to jobs";
                }
                this.setState({
                    showSuccessResult: true,
                    okMessage,
                    successMessage
                });
            }

            this.setState({
                sendingLink: false
            });
            this.closeVerificationModal();
        }
    }

    onRequestVerificationClick = () => {
        this.setState({
            showVerificationModal: true
        });
    }

    sendMagickLink = (fields, consent) => {
        this.setState({
            sendingLink: true
        });
        this.props.createDataObjectVerification({
            businessAreaId: this.props.dataObject.businessAreaId,
            schemaId: this.props.dataObject.schemaId,
            dataSetId: this.props.dataObject.dataSetId,
            dataObjectId: this.props.dataObject.dataObjectId,
            consent,
            fields,
            bulkVerification: this.props.bulkVerification
        });
    }

    closeVerificationModal = () => {
        this.setState({
            showVerificationModal: false
        })
    }

    okSuccessResult = () => {
        if (this.props.createDataObjectVerificationResult.result.bulkVerification) {
            this.goToJobs();
        }
        else {
            this.closeSuccessResult();
        }
    }

    goToJobs = () => {
        this.props.history.push(AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase()).replace(":monitorItem", "jobs"));
    }

    closeSuccessResult = () => {
        this.setState({
            showSuccessResult: false
        });
    }

    render() {
        return <>
            <SuccessResult
                show={this.state.showSuccessResult}
                title={this.state.successMessage}
                okText={this.state.okMessage}
                cancelText="Close"
                onOkClick={this.okSuccessResult}
                onCancelClick={this.closeSuccessResult}>
            </SuccessResult>
            {
                this.state.showVerificationModal
                    ?
                    <DataObjectRequestVerificationModal
                        visible={this.state.showVerificationModal}
                        schemaFields={this.props.schemaFields}
                        recordId={this.props.dataObject.dataObjectId}
                        dataObject={this.props.dataObject}
                        onCancel={this.closeVerificationModal}
                        onOk={this.sendMagickLink}
                        busy={this.state.sendingLink}
                        showConfirm={this.props.bulkVerification}>
                    </DataObjectRequestVerificationModal>
                    :
                    <></>
            }
            <Button type="primary" onClick={this.onRequestVerificationClick}><><SafetyCertificateTwoTone /> Request Verification</></Button>
        </>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        createDataObjectVerificationResult: state.dataObjectVerification.createDataObjectVerificationResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDataObjectVerification: ({ businessAreaId, schemaId, dataSetId, dataObjectId, consent, fields, bulkVerification }) => dispatch(actions.createDataObjectVerificationRequest({ businessAreaId, schemaId, dataSetId, dataObjectId, consent, fields, bulkVerification })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectRequestVerification));