
import { put, call, takeEvery, select, spawn } from 'redux-saga/effects';
import * as actions from './actions';
import { Auth } from "aws-amplify";
import { Endpoints } from 'services/api';
import moment from 'moment';
import { keyGenerator } from 'common/Utility';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
import * as homeSaga from 'store/home/sagas';

const getTenant = (state) => state.userLogin.tenant;

function* getBusinessAreas(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/businessareas`, { tenantid: tenantId });
    return result.data;
}

function* createBusinessArea(api, tenantId, businessArea) {
    let result = yield call([api, api.post], `${Endpoints.tenants}/api/v1/businessareas`, businessArea, { tenantid: tenantId });
    return result.data;
}

function* getBusinessAreaById(api, tenantId, businessAreaId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/businessareas/${businessAreaId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* deleteBusinessArea(api, tenantId, businessAreaId) {
    let result = yield call([api, api.delete], `${Endpoints.tenants}/api/v1/businessareas/${businessAreaId}`, { tenantid: tenantId });
    return result.data;
}

function* updateBusinessArea(api, tenantId, businessAreaId, businessAreaUpdateRequest) {
    let result = yield call([api, api.put], `${Endpoints.tenants}/api/v1/businessareas/${businessAreaId}`, businessAreaUpdateRequest, { tenantid: tenantId });
    return result.data;
}

export function* getUserBusinessAreaPermissions(api, tenantId) {
    //TODo - remove the current user call and replace it with pretectum user
    let user = yield call([Auth, Auth.currentAuthenticatedUser]);
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${user.attributes.sub}/permissions/resources/businessareas`, { tenantid: tenantId });
    return result.data;
}

function* getBusinessAreaSystemConnections(api, businessAreaId) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/businessareas/${businessAreaId}/systemconnections`);
    return result.data;
}

function processBusinessAreaResponse(businessArea) {
    if (businessArea) {
        if (businessArea.users && businessArea.users.Items && businessArea.users.Items.length > 0) {
            businessArea.users = businessArea.users.Items.map((item, index) => {
                let businessAreaUser = {
                    key: keyGenerator(index),
                    ...item.user,
                    version: item.version
                };
                businessAreaUser.canAdd = false;
                businessAreaUser.canView = false;
                businessAreaUser.canEdit = false;
                businessAreaUser.canDelete = false;
                if (businessAreaUser.actions.includes("add")) {
                    businessAreaUser.canAdd = true;
                }
                if (businessAreaUser.actions.includes("view")) {
                    businessAreaUser.canView = true;
                }
                if (businessAreaUser.actions.includes("edit")) {
                    businessAreaUser.canEdit = true;
                }
                if (businessAreaUser.actions.includes("delete")) {
                    businessAreaUser.canDelete = true;
                }
                businessAreaUser.errors = {};
                return businessAreaUser;
            });
        } else {
            businessArea.users = [];
        }

        if (businessArea.appClients && businessArea.appClients.Items && businessArea.appClients.Items.length > 0) {
            businessArea.appClients = businessArea.appClients.Items.map((item, index) => {
                let businessAreaAppClient = {
                    key: keyGenerator(index),
                    ...item.appClient,
                    version: item.version
                };
                businessAreaAppClient.canAdd = false;
                businessAreaAppClient.canView = false;
                businessAreaAppClient.canEdit = false;
                businessAreaAppClient.canDelete = false;
                if (businessAreaAppClient.actions.includes("add")) {
                    businessAreaAppClient.canAdd = true;
                }
                if (businessAreaAppClient.actions.includes("view")) {
                    businessAreaAppClient.canView = true;
                }
                if (businessAreaAppClient.actions.includes("edit")) {
                    businessAreaAppClient.canEdit = true;
                }
                if (businessAreaAppClient.actions.includes("delete")) {
                    businessAreaAppClient.canDelete = true;
                }
                businessAreaAppClient.errors = {};
                return businessAreaAppClient;
            });
        } else {
            businessArea.appClients = [];
        }

        if (businessArea.systemConnections && businessArea.systemConnections.Items && businessArea.systemConnections.Items.length > 0) {
            businessArea.systemConnections = businessArea.systemConnections.Items.map((item, index) => {
                let businessAreaSystemConnection = {
                    key: keyGenerator(index),
                    ...item.systemConnection,
                    version: item.version
                };
                businessAreaSystemConnection.errors = {};
                return businessAreaSystemConnection;
            });
        } else {
            businessArea.systemConnections = [];
        }
    }
}

function addUpdatedBusinessAreaUsers(updateData, businessAreaUpdateRequest) {
    if (updateData.deletedUsers && updateData.deletedUsers.length > 0) {
        businessAreaUpdateRequest.users = {
            deleteRequest: []
        }
        for (let deletedUser of updateData.deletedUsers) {
            businessAreaUpdateRequest.users.deleteRequest.push({
                userId: deletedUser.userId,
                version: deletedUser.version
            })
        }
    }

    if (updateData.updatedUsers && updateData.updatedUsers.length > 0) {
        if (!businessAreaUpdateRequest.users) {
            businessAreaUpdateRequest.users = {
                updateRequest: []
            }
        }
        else {
            businessAreaUpdateRequest.users.updateRequest = [];
        }
        for (let user of updateData.updatedUsers) {
            let updatedUser = {
                userId: user.userId,
                active: user.active,
                version: user.version,
                actions: []
            };
            if (user.canAdd) {
                updatedUser.actions.push("add");
            }
            if (user.canView) {
                updatedUser.actions.push("view");
            }
            if (user.canEdit) {
                updatedUser.actions.push("edit");
            }
            if (user.canDelete) {
                updatedUser.actions.push("delete");
            }
            businessAreaUpdateRequest.users.updateRequest.push(updatedUser);
        }
    }

    if (updateData.newUsers && updateData.newUsers.length > 0) {
        if (!businessAreaUpdateRequest.users) {
            businessAreaUpdateRequest.users = {
                insertRequest: []
            }
        }
        else {
            businessAreaUpdateRequest.users.insertRequest = [];
        }
        for (let user of updateData.newUsers) {
            let businessAreaUser = {
                userId: user.userId,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                active: user.active,
                version: user.version,
                actions: []
            };
            if (user.canAdd) {
                businessAreaUser.actions.push("add");
            }
            if (user.canView) {
                businessAreaUser.actions.push("view");
            }
            if (user.canEdit) {
                businessAreaUser.actions.push("edit");
            }
            if (user.canDelete) {
                businessAreaUser.actions.push("delete");
            }
            businessAreaUpdateRequest.users.insertRequest.push(businessAreaUser);
        }
    }
}

function addUpdatedBusinessAreaAppClients(updateData, businessAreaUpdateRequest) {
    if (updateData.deletedAppClients && updateData.deletedAppClients.length > 0) {
        businessAreaUpdateRequest.appClients = {
            deleteRequest: []
        }
        for (let deletedAppClient of updateData.deletedAppClients) {
            businessAreaUpdateRequest.appClients.deleteRequest.push({
                clientId: deletedAppClient.clientId,
                version: deletedAppClient.version
            })
        }
    }

    if (updateData.updatedAppClients && updateData.updatedAppClients.length > 0) {
        if (!businessAreaUpdateRequest.appClients) {
            businessAreaUpdateRequest.appClients = {
                updateRequest: []
            }
        }
        else {
            businessAreaUpdateRequest.appClients.updateRequest = [];
        }
        for (let appClient of updateData.updatedAppClients) {
            let updatedAppClient = {
                clientId: appClient.clientId,
                active: appClient.active,
                version: appClient.version,
                actions: []
            };
            if (appClient.canAdd) {
                updatedAppClient.actions.push("add");
            }
            if (appClient.canView) {
                updatedAppClient.actions.push("view");
            }
            if (appClient.canEdit) {
                updatedAppClient.actions.push("edit");
            }
            if (appClient.canDelete) {
                updatedAppClient.actions.push("delete");
            }
            businessAreaUpdateRequest.appClients.updateRequest.push(updatedAppClient);
        }
    }

    if (updateData.newAppClients && updateData.newAppClients.length > 0) {
        if (!businessAreaUpdateRequest.appClients) {
            businessAreaUpdateRequest.appClients = {
                insertRequest: []
            }
        }
        else {
            businessAreaUpdateRequest.appClients.insertRequest = [];
        }
        for (let appClient of updateData.newAppClients) {
            let businessAreaAppClient = {
                clientId: appClient.clientId,
                clientName: appClient.clientName,
                active: appClient.active,
                version: appClient.version,
                actions: []
            };
            if (appClient.canAdd) {
                businessAreaAppClient.actions.push("add");
            }
            if (appClient.canView) {
                businessAreaAppClient.actions.push("view");
            }
            if (appClient.canEdit) {
                businessAreaAppClient.actions.push("edit");
            }
            if (appClient.canDelete) {
                businessAreaAppClient.actions.push("delete");
            }
            businessAreaUpdateRequest.appClients.insertRequest.push(businessAreaAppClient);
        }
    }
}

function addUpdatedBusinessAreaSystemConnections(updateData, businessAreaUpdateRequest) {
    if (updateData.deletedSystemConnections && updateData.deletedSystemConnections.length > 0) {
        businessAreaUpdateRequest.systemConnections = {
            deleteRequest: []
        }
        for (let deletedAppClient of updateData.deletedSystemConnections) {
            businessAreaUpdateRequest.systemConnections.deleteRequest.push({
                connectionId: deletedAppClient.connectionId,
                version: deletedAppClient.version
            })
        }
    }

    if (updateData.updatedSystemConnections && updateData.updatedSystemConnections.length > 0) {
        if (!businessAreaUpdateRequest.systemConnections) {
            businessAreaUpdateRequest.systemConnections = {
                updateRequest: []
            }
        }
        else {
            businessAreaUpdateRequest.systemConnections.updateRequest = [];
        }
        for (let systemConnection of updateData.updatedSystemConnections) {
            let updatedAppClient = {
                connectionId: systemConnection.connectionId,
                active: systemConnection.active,
                version: systemConnection.version
            };
            businessAreaUpdateRequest.systemConnections.updateRequest.push(updatedAppClient);
        }
    }

    if (updateData.newSystemConnections && updateData.newSystemConnections.length > 0) {
        if (!businessAreaUpdateRequest.systemConnections) {
            businessAreaUpdateRequest.systemConnections = {
                insertRequest: []
            }
        }
        else {
            businessAreaUpdateRequest.systemConnections.insertRequest = [];
        }
        for (let systemConnection of updateData.newSystemConnections) {
            let businessAreaAppClient = {
                connectionId: systemConnection.connectionId,
                name: systemConnection.name,
                type: systemConnection.type,
                active: systemConnection.active,
                version: systemConnection.version
            };
            businessAreaUpdateRequest.systemConnections.insertRequest.push(businessAreaAppClient);
        }
    }
}

export function* getBusinessAreasRequest(api) {
    try {
        let businessAreas = [];
        let tenant = yield select(getTenant);
        const response = yield call(getBusinessAreas, api, tenant.tenantId);
        if (response.Items) {
            businessAreas = response.Items;
        }
        yield put(actions.getBusinessAreasSuccess(businessAreas));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business areas");
        yield put(actions.getBusinessAreasFailure(errorObject));
    }
}

export function* createBusinessAreaRequest(api, { businessArea, users, appClients, systemConnections }) {
    try {
        let tenant = yield select(getTenant);
        let businessAreaItem = {
            name: businessArea.name,
            description: businessArea.description,
            active: businessArea.active
        }
        businessAreaItem.users = [];
        businessAreaItem.appClients = [];
        businessAreaItem.systemConnections = [];

        if (users) {
            for (let user of users) {
                let businessAreaUser = {
                    userId: user.userId,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    active: user.active,
                    version: user.version,
                    actions: []
                }
                if (user.canAdd) {
                    businessAreaUser.actions.push("add");
                }
                if (user.canView) {
                    businessAreaUser.actions.push("view");
                }
                if (user.canEdit) {
                    businessAreaUser.actions.push("edit");
                }
                if (user.canDelete) {
                    businessAreaUser.actions.push("delete");
                }
                businessAreaItem.users.push(businessAreaUser);
            }
        }

        if (appClients) {
            for (let appClient of appClients) {
                let businessAreaAppClient = {
                    clientId: appClient.clientId,
                    clientName: appClient.clientName,
                    active: appClient.active,
                    version: appClient.version,
                    actions: []
                }
                if (appClient.canAdd) {
                    businessAreaAppClient.actions.push("add");
                }
                if (appClient.canView) {
                    businessAreaAppClient.actions.push("view");
                }
                if (appClient.canEdit) {
                    businessAreaAppClient.actions.push("edit");
                }
                if (appClient.canDelete) {
                    businessAreaAppClient.actions.push("delete");
                }
                businessAreaItem.appClients.push(businessAreaAppClient);
            }
        }

        if (systemConnections) {
            for (let systemConnection of systemConnections) {
                let businessAreaSystemConnection = {
                    connectionId: systemConnection.connectionId,
                    name: systemConnection.name,
                    type: systemConnection.type,
                    active: systemConnection.active,
                    version: systemConnection.version
                }
                businessAreaItem.systemConnections.push(businessAreaSystemConnection);
            }
        }
        const createdBusinessArea = yield call(createBusinessArea, api, tenant.tenantId, businessAreaItem);
        yield put(actions.createBusinessAreasSuccess(createdBusinessArea));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "business area");
        yield put(actions.createBusinessAreasFailure(errorObject));
    }
}

export function* getBusinessAreaByIdRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        const businessArea = yield call(getBusinessAreaById, api, tenant.tenantId, businessAreaId);
        processBusinessAreaResponse(businessArea);
        yield put(actions.getBusinessAreaByIdSuccess(businessArea));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area");
        yield put(actions.getBusinessAreaByIdFailure(errorObject));
    }
}

export function* deleteBusinessAreaRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        yield call(deleteBusinessArea, api, tenant.tenantId, businessAreaId);
        yield call(getBusinessAreasRequest, api);
        yield spawn([homeSaga, homeSaga.getUserBusinessAreasRequest], api);
        yield spawn([homeSaga, homeSaga.getUserActiveBusinessAreaRequest], api);
        yield put(actions.deleteBusinessAreaSuccess());

    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "business area");
        yield put(actions.deleteBusinessAreaFailure(errorObject));
    }
}

export function* updateBusinessAreaRequest(api, { updateData, businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        let businessAreaUpdateRequest = {};
        if (updateData.businessArea) {
            businessAreaUpdateRequest.businessArea = {
                updateRequest: updateData.businessArea
            }
        }
        addUpdatedBusinessAreaUsers(updateData, businessAreaUpdateRequest);
        addUpdatedBusinessAreaAppClients(updateData, businessAreaUpdateRequest);
        addUpdatedBusinessAreaSystemConnections(updateData, businessAreaUpdateRequest);
        try {
            yield call(updateBusinessArea, api, tenant.tenantId, businessAreaId, businessAreaUpdateRequest);
            yield call(getBusinessAreaByIdRequest, api, { businessAreaId });
        }
        finally {
            yield spawn([homeSaga, homeSaga.getUserBusinessAreasRequest], api);
            yield spawn([homeSaga, homeSaga.getUserActiveBusinessAreaRequest], api);
        }
        yield put(actions.updateBusinessAreaSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "business area");
        if (error.isAxiosError) {
            if ([400, 404, 409, 412].includes(error.response.status) === false) {
                yield call(getBusinessAreaByIdRequest, api, { businessAreaId });
            }
        }
        yield put(actions.updateBusinessAreaFailure(errorObject));
    }
}

export function* activateDeactivateBusinessAreaRequest(api, { businessAreaId, businessAreaActive, businessAreaVersion }) {
    try {
        let tenant = yield select(getTenant);
        let businessAreaUpdateRequest = {
            businessArea: {
                updateRequest: {
                    active: businessAreaActive,
                    version: businessAreaVersion
                }
            }
        };

        const result = yield call(updateBusinessArea, api, tenant.tenantId, businessAreaId, businessAreaUpdateRequest);
        yield call(getBusinessAreasRequest, api);
        yield spawn([homeSaga, homeSaga.getUserBusinessAreasRequest], api);
        yield spawn([homeSaga, homeSaga.getUserActiveBusinessAreaRequest], api);
        yield put(actions.activateDeactivateBusinessAreaSuccess(businessAreaId));
    } catch (error) {
        let errorObject = errorHandler(error, "Activate/Deactivate", "business area");
        yield put(actions.activateDeactivateBusinessAreaFailure(errorObject, businessAreaId));
    }
}

export function* getUserBusinessAreasPermissionRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const result = yield call(getUserBusinessAreaPermissions, api, tenant.tenantId);
        let permission = null;
        if (result && result.Items && result.Items.length > 0) {
            for (let item of result.Items) {
                if (!permission) {
                    permission = { ...item };
                    permission.canAdd = false;
                    permission.canView = false;
                    permission.canEdit = false;
                    permission.canDelete = false;
                }

                if (item.actions.includes("add")) {
                    permission.canAdd = true;
                }
                if (item.actions.includes("view")) {
                    permission.canView = true;
                }
                if (item.actions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (item.actions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }
        yield put(actions.getUserBusinessAreasPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user business area permissions");
        yield put(actions.getUserBusinessAreasPermissionFailure(errorObject));
    }
}

export function* getBusinessAreaSystemConnectionsRequest(api, { businessAreaId }) {
    try {
        const systemConnectionsResult = yield call(getBusinessAreaSystemConnections, api, businessAreaId);
        yield put(actions.getBusinessAreaSystemConnectionsSuccess(systemConnectionsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area system connections");
        yield put(actions.getBusinessAreaSystemConnectionsFailure(errorObject));
    }
}

export function* watchGetBusinessAreasRequest(api, params) {
    yield call(getBusinessAreasRequest, api);
}

export function* watchCreateBusinessAreaRequest(api, { params }) {
    yield call(createBusinessAreaRequest, api, params);
}

export function* watchGetBusinessAreaByIdRequest(api, { params }) {
    yield call(getBusinessAreaByIdRequest, api, params);
}

export function* watchDeleteBusinessAreaRequest(api, { params }) {
    yield call(deleteBusinessAreaRequest, api, params);
}

export function* watchUpdateBusinessAreaRequest(api, { params }) {
    yield call(updateBusinessAreaRequest, api, params);
}

export function* watchActivateDeactivateBusinessAreaRequest(api, { params }) {
    yield call(activateDeactivateBusinessAreaRequest, api, params);
}

export function* watchGetUserBusinessAreasPermissionRequest(api, params) {
    yield call(getUserBusinessAreasPermissionRequest, api);
}

export function* watchGetBusinessAreaSystemConnectionsRequest(api, { params }) {
    yield call(getBusinessAreaSystemConnectionsRequest, api, params);
}


export default function* ({ api }) {
    yield takeEvery(actions.GET_BUSINESS_AREAS_REQUEST, watchGetBusinessAreasRequest, api);
    yield takeEvery(actions.CREATE_BUSINESS_AREA_REQUEST, watchCreateBusinessAreaRequest, api);
    yield takeEvery(actions.GET_BUSINESS_AREA_BY_ID_REQUEST, watchGetBusinessAreaByIdRequest, api);
    yield takeEvery(actions.DELETE_BUSINESS_AREA_REQUEST, watchDeleteBusinessAreaRequest, api);
    yield takeEvery(actions.UPDATE_BUSINESS_AREA_REQUEST, watchUpdateBusinessAreaRequest, api);
    yield takeEvery(actions.ACTIVATE_DEACTIVATE_BUSINESS_AREA_REQUEST, watchActivateDeactivateBusinessAreaRequest, api);
    yield takeEvery(actions.GET_USER_BUSINESS_AREAS_PERMISSION_REQUEST, watchGetUserBusinessAreasPermissionRequest, api);
    yield takeEvery(actions.GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_REQUEST, watchGetBusinessAreaSystemConnectionsRequest, api);
}