export const SEARCH_DATA_OBJECTS_REQUEST = "SEARCH_DATA_OBJECTS_REQUEST";
export const SEARCH_DATA_OBJECTS_SUCCESS = "SEARCH_DATA_OBJECTS_SUCCESS";
export const SEARCH_DATA_OBJECTS_FAILURE = "SEARCH_DATA_OBJECTS_FAILURE";
export const SEARCH_DATA_OBJECTS_INPROGRESS = "SEARCH_DATA_OBJECTS_INPROGRESS";

export const GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST = "GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST";
export const GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS = "GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS";
export const GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE = "GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE";

export const CLEAR_SEARCH_DATA_OBJECTS_RESULT_REQUEST = "CLEAR_SEARCH_DATA_OBJECTS_RESULT_REQUEST";

export const UPDATE_SEARCH_QUERY_REQUEST = "UPDATE_SEARCH_QUERY_REQUEST";
export const UPDATE_SEARCH_QUERY_SUCCESS = "UPDATE_SEARCH_QUERY_SUCCESS";
export const UPDATE_SEARCH_QUERY_FAILURE = "UPDATE_SEARCH_QUERY_FAILURE";

export const GET_SEARCH_FIELDS_REQUEST = "GET_SEARCH_FIELDS_REQUEST";
export const GET_SEARCH_FIELDS_SUCCESS = "GET_SEARCH_FIELDS_SUCCESS";
export const GET_SEARCH_FIELDS_FAILURE = "GET_SEARCH_FIELDS_FAILURE";

export const searchDataObjectsRequest = (query, searchId, queryType, businessArea, schema, dataSet, from, size) => ({
    type: SEARCH_DATA_OBJECTS_REQUEST,
    params: {
        query,
        searchId,
        queryType,
        businessArea,
        schema,
        dataSet,
        from,
        size
    }
})

export const searchDataObjectsInProgress = () => ({
    type: SEARCH_DATA_OBJECTS_INPROGRESS,
})

export const searchDataObjectsSuccess = (searchResult) => ({
    type: SEARCH_DATA_OBJECTS_SUCCESS,
    payload: {
        searchResult
    }
})

export const searchDataObjectsFailure = (error) => ({
    type: SEARCH_DATA_OBJECTS_FAILURE,
    payload: {
        error
    }
})

export const getUserSearchDataObjectsPermissionRequest = () => ({
    type: GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST
})

export const getUserSearchDataObjectsPermissionSuccess = (permission) => ({
    type: GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS,
    payload: {
        permission
    }
})

export const getUserSearchDataObjectsPermissionFailure = (error) => ({
    type: GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE,
    payload: {
        error
    }
})

export const clearSearchDataObjectsResultRequest = () => ({
    type: CLEAR_SEARCH_DATA_OBJECTS_RESULT_REQUEST
})

export const updateSearchQueryRequest = (searchId, { question, query, properties, accuracy, expectedDSLQuery }) => ({
    type: UPDATE_SEARCH_QUERY_REQUEST,
    params: {
        question,
        query,
        properties,
        searchId,
        accuracy,
        expectedDSLQuery
    }
})

export const updateSearchQuerySuccess = (payload) => ({
    type: UPDATE_SEARCH_QUERY_SUCCESS,
    payload: payload
})

export const updateSearchQueryFailure = (error) => ({
    type: UPDATE_SEARCH_QUERY_FAILURE,
    payload: {
        error
    }
})


export const getSearchFieldsRequest = (businessArea, schema, dataSet) => ({
    type: GET_SEARCH_FIELDS_REQUEST,
    params: {
        businessArea,
        schema,
        dataSet
    }
})

export const getSearchFieldsSuccess = (fields) => ({
    type: GET_SEARCH_FIELDS_SUCCESS,
    payload: {
        fields
    }
})

export const getSearchFieldsFailure = (error) => ({
    type: GET_SEARCH_FIELDS_FAILURE,
    payload: {
        error
    }
})
