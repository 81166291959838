import {
    SEARCH_DATA_OBJECTS_SUCCESS,
    SEARCH_DATA_OBJECTS_FAILURE,
    GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS,
    GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE,
    CLEAR_SEARCH_DATA_OBJECTS_RESULT_REQUEST,
    UPDATE_SEARCH_QUERY_SUCCESS,
    UPDATE_SEARCH_QUERY_FAILURE,
    GET_SEARCH_FIELDS_SUCCESS,
    GET_SEARCH_FIELDS_FAILURE,
    SEARCH_DATA_OBJECTS_INPROGRESS,
    GET_SEARCH_FIELDS_REQUEST,
} from "./actions";

const initialState = {
    searchDataObjectsResult: {
        success: true,
        inProgress: false,
    },
    getUserSearchDataObjectsPermisionResult: {
        success: true,
    },
    userSearchDataObjectsPermision: null,
    searchResult: {
        hits: [],
    },
    updateSearchQueryResult: {
        success: true,
    },
    getSearchFieldsResult: {
        success: true,
        inProgress: true,
    },
    searchFields: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_DATA_OBJECTS_INPROGRESS:
            return {
                ...state,
                searchDataObjectsResult: {
                    success: true,
                    inProgress: true,
                },
                searchResult: { hits: [] },
            };
        case SEARCH_DATA_OBJECTS_SUCCESS:
            return {
                ...state,
                searchDataObjectsResult: {
                    success: true,
                    inProgress: false,
                },
                searchResult: {
                    hits: action.payload.searchResult.hits,
                    total: action.payload.searchResult.total,
                    dslQuery: action.payload.searchResult.query,
                    error: action.payload.searchResult.error,
                    properties: action.payload.searchResult.properties,
                    searchId: action.payload.searchResult.searchId,
                },
            };
        case SEARCH_DATA_OBJECTS_FAILURE:
            return {
                ...state,
                searchDataObjectsResult: {
                    success: false,
                    inProgress: false,
                    ...action.payload.error,
                },
                searchResult: { hits: [] },
            };
        case GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS:
            return {
                ...state,
                getUserSearchDataObjectsPermisionResult: { success: true },
                userSearchDataObjectsPermision: action.payload.permission,
            };
        case GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE:
            return {
                ...state,
                getUserSearchDataObjectsPermisionResult: {
                    success: false,
                    ...action.payload.error,
                },
                userSearchDataObjectsPermision: null,
            };
        case CLEAR_SEARCH_DATA_OBJECTS_RESULT_REQUEST:
            return {
                ...state,
                searchDataObjectsResult: {
                    success: true,
                    inProgress: false,
                },
                searchResult: { hits: [], total: 0 },
            };
        case UPDATE_SEARCH_QUERY_SUCCESS:
            return {
                ...state,
                updateSearchQueryResult: { success: true, ...action.payload },
            };
        case UPDATE_SEARCH_QUERY_FAILURE:
            return {
                ...state,
                updateSearchQueryResult: {
                    success: false,
                    ...action.payload.error,
                },
            };
        case GET_SEARCH_FIELDS_REQUEST:
            return {
                ...state,
                getSearchFieldsResult: { success: false, inProgress: true },
                searchFields: [],
            };
        case GET_SEARCH_FIELDS_SUCCESS:
            return {
                ...state,
                getSearchFieldsResult: { success: true, inProgress: false },
                searchFields: action.payload.fields,
            };
        case GET_SEARCH_FIELDS_FAILURE:
            return {
                ...state,
                getSearchFieldsResult: {
                    success: false,
                    inProgress: false,
                    ...action.payload.error,
                },
                searchFields: [],
            };
        default:
            return state;
    }
};
