import {
    GET_DATA_OBJECTS_DUPLICATE_JOBS_SUCCESS,
    GET_DATA_OBJECTS_DUPLICATE_JOBS_FAILURE,

    SEARCH_DUPLICATE_DATA_OBJECTS_SUCCESS,
    SEARCH_DUPLICATE_DATA_OBJECTS_FAILURE,

    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUCCESS,
    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_FAILURE,

    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_SUCCESS,
    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_FAILURE,

    GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS,
    GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE,

    NOMINATE_SURVIVAL_RECORD_REQUEST,
    NOMINATE_SURVIVAL_RECORD_SUCCESS,
    NOMINATE_SURVIVAL_RECORD_FAILURE,

    REMOVE_SURVIVAL_RECORD_NOMINATION_REQUEST,
    REMOVE_SURVIVAL_RECORD_NOMINATION_SUCCESS,
    REMOVE_SURVIVAL_RECORD_NOMINATION_FAILURE,

    RUN_DEDUPLICATE_JOB_SUCCESS,
    RUN_DEDUPLICATE_JOB_FAILURE,

    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_SUCCESS,
    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_FAILURE,

    GET_DEDUPLICATED_RECORDS_SUCCESS,
    GET_DEDUPLICATED_RECORDS_FAILURE,
} from "./actions";

import {
    JOB_EXECUTION_PROGRESS,
    JOB_DELETED,
    RESTART_JOB_SUCCESS,
    CANCEL_JOB_SUCCESS
} from '../jobs/actions';

const initialState = {
    getDataObjectsDuplicateJobsResult: {
        success: true
    },
    dataObjectsDuplicateJobsResult: {
        Items: []
    },
    searchDuplicateDataObjectJobsResult: {
        success: true
    },
    getUserSearchDataObjectsPermisionResult: {
        success: true,
    },
    getDuplicateDataObjectsResultSetResult: {
        resultSet: {}
    },
    getDuplicateDataObjectsResultSetNextPageResult: {
        resultSet: {}
    },
    duplicateDataObjectsResultSet: {
        resultSet: {}
    },
    userSearchDataObjectsPermision: null,
    runDeDuplicateJobResult: {
        success: true
    },
    getDuplicateDataObjectsResultSetDataObjectResult: {
        success: true
    },
    duplicateResultSetDataObjectResult: {
        dataObject: null,
        job: null
    },
    getDeDuplicatedRecordsRequestResult: {
        success: true
    },
    deDuplicatedRecordsResult: {
        Items: []
    },
    removeSurvivalRecordNominationResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    let getDuplicateDataObjectsResultSetResult = {
        resultSet: {}
    };
    let getDuplicateDataObjectsResultSetNextPageResult = {
        resultSet: {}
    };
    let duplicateDataObjectsResultSet = {
        resultSet: {}
    };
    let dataObjectsDuplicateJobsResult = {
        Items: []
    };
    let jobItems = [];
    switch (action.type) {
        case GET_DATA_OBJECTS_DUPLICATE_JOBS_SUCCESS:
            return { ...state, getDataObjectsDuplicateJobsResult: { success: true }, dataObjectsDuplicateJobsResult: action.payload.jobResult };
        case GET_DATA_OBJECTS_DUPLICATE_JOBS_FAILURE:
            return {
                ...state, getJobsResult: { success: false, ...action.payload.error }, dataObjectsDuplicateJobsResult: {
                    Items: []
                }
            };
        case SEARCH_DUPLICATE_DATA_OBJECTS_SUCCESS:
            return { ...state, searchDuplicateDataObjectJobsResult: { success: true }, dataObjectsDuplicateJobsResult: { Items: [action.payload.job, ...state.dataObjectsDuplicateJobsResult.Items] } };
        case SEARCH_DUPLICATE_DATA_OBJECTS_FAILURE:
            return { ...state, searchDuplicateDataObjectJobsResult: { success: false, ...action.payload.error } };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUCCESS:
            getDuplicateDataObjectsResultSetResult = { ...state.getDuplicateDataObjectsResultSetResult };
            getDuplicateDataObjectsResultSetResult.resultSet = { ...getDuplicateDataObjectsResultSetResult.resultSet };
            getDuplicateDataObjectsResultSetResult.resultSet[action.payload.jobId] = {
                success: true
            };

            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            if (action.payload.result && action.payload.result.Items) {
                for (let index = 0; index < action.payload.result.Items.length; index++) {
                    action.payload.result.Items[index].sNo = index + 1;
                    if (action.payload.result.Items[index]._survivor) {
                        const survivorGroupId = action.payload.result.Items[index]._groupId;
                        const deduplicationOption = action.payload.result.Items[index]._deduplicationOption;
                        let backwardScanIndex = index - 1;
                        let forwardScanIndex = index + 1;
                        while (backwardScanIndex > -1) {
                            if (action.payload.result.Items[backwardScanIndex]._groupId === survivorGroupId) {
                                if (deduplicationOption === "DELETE_DUPLICATES") {
                                    action.payload.result.Items[backwardScanIndex]._deleteDuplicates = true;
                                }
                                else {
                                    action.payload.result.Items[backwardScanIndex]._mergeDuplicates = true;
                                }
                                backwardScanIndex = backwardScanIndex - 1;
                                continue;
                            }
                            break;
                        }
                        while (forwardScanIndex < action.payload.result.Items.length) {
                            if (action.payload.result.Items[forwardScanIndex]._groupId === survivorGroupId) {
                                if (deduplicationOption === "DELETE_DUPLICATES") {
                                    action.payload.result.Items[forwardScanIndex]._deleteDuplicates = true;
                                }
                                else {
                                    action.payload.result.Items[forwardScanIndex]._mergeDuplicates = true;
                                }
                                forwardScanIndex = forwardScanIndex + 1;
                                continue;
                            }
                            break;
                        }
                    }
                }
            }
            duplicateDataObjectsResultSet.resultSet[action.payload.jobId] = action.payload.result;

            return { ...state, getDuplicateDataObjectsResultSetResult, duplicateDataObjectsResultSet };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_FAILURE:
            getDuplicateDataObjectsResultSetResult = { ...state.getDuplicateDataObjectsResultSetResult };
            getDuplicateDataObjectsResultSetResult.resultSet = { ...getDuplicateDataObjectsResultSetResult.resultSet };
            getDuplicateDataObjectsResultSetResult.resultSet[action.payload.jobId] = {
                success: false,
                ...action.payload.error
            };

            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.payload.jobId] = { Items: [] };

            return {
                ...state, getDuplicateDataObjectsResultSetResult, duplicateDataObjectsResultSet
            };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_SUCCESS:
            getDuplicateDataObjectsResultSetNextPageResult = { ...state.getDuplicateDataObjectsResultSetNextPageResult };
            getDuplicateDataObjectsResultSetNextPageResult.resultSet = { ...getDuplicateDataObjectsResultSetNextPageResult.resultSet };
            getDuplicateDataObjectsResultSetNextPageResult.resultSet[action.payload.jobId] = {
                success: true
            };

            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };

            const currentItemsLength = duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items.length;
            const totalLength = action.payload.result.Items.length + duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items.length;

            duplicateDataObjectsResultSet.resultSet[action.payload.jobId] = {
                ...action.payload.result,
                Items: duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items.concat(action.payload.result.Items)
            }

            for (let index = currentItemsLength - 1; index < totalLength; index++) {
                duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[index].sNo = index + 1;
                if (duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[index]._survivor) {
                    const survivorGroupId = duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[index]._groupId;
                    const deduplicationOption = duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[index]._deduplicationOption;
                    let backwardScanIndex = index - 1;
                    let forwardScanIndex = index + 1;
                    while (backwardScanIndex > (currentItemsLength - 2)) {
                        if (duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[backwardScanIndex]._groupId === survivorGroupId) {
                            if (deduplicationOption === "DELETE_DUPLICATES") {
                                duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[backwardScanIndex]._deleteDuplicates = true;
                            }
                            else {
                                duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[backwardScanIndex]._mergeDuplicates = true;
                            }
                            backwardScanIndex = backwardScanIndex - 1;
                            continue;
                        }
                        break;
                    }
                    while (forwardScanIndex < totalLength) {
                        if (duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[forwardScanIndex]._groupId === survivorGroupId) {
                            if (deduplicationOption === "DELETE_DUPLICATES") {
                                duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[forwardScanIndex]._deleteDuplicates = true;
                            }
                            else {
                                duplicateDataObjectsResultSet.resultSet[action.payload.jobId].Items[forwardScanIndex]._mergeDuplicates = true;
                            }
                            forwardScanIndex = forwardScanIndex + 1;
                            continue;
                        }
                        break;
                    }
                }
            }

            return {
                ...state, getDuplicateDataObjectsResultSetNextPageResult, duplicateDataObjectsResultSet
            };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_FAILURE:
            getDuplicateDataObjectsResultSetNextPageResult = { ...state.getDuplicateDataObjectsResultSetNextPageResult };
            getDuplicateDataObjectsResultSetNextPageResult.resultSet = { ...getDuplicateDataObjectsResultSetNextPageResult.resultSet };
            getDuplicateDataObjectsResultSetNextPageResult.resultSet[action.payload.jobId] = {
                success: false,
                ...action.payload.error
            };

            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.payload.jobId] = {
                ...duplicateDataObjectsResultSet.resultSet[action.payload.jobId],
                pageKey: null
            }
            return {
                ...state, getDuplicateDataObjectsResultSetNextPageResult, duplicateDataObjectsResultSet
            };
        case GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS:
            return { ...state, getUserSearchDataObjectsPermisionResult: { success: true }, userSearchDataObjectsPermision: action.payload.permission };
        case GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE:
            return { ...state, getUserSearchDataObjectsPermisionResult: { success: false, ...action.payload.error }, userSearchDataObjectsPermision: null };
        case JOB_EXECUTION_PROGRESS:
            dataObjectsDuplicateJobsResult = { ...state.dataObjectsDuplicateJobsResult };
            jobItems = [...dataObjectsDuplicateJobsResult.Items];
            dataObjectsDuplicateJobsResult.Items = jobItems;
            let updatedJobIndex = dataObjectsDuplicateJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (updatedJobIndex > -1) {
                dataObjectsDuplicateJobsResult.Items[updatedJobIndex] = { ...dataObjectsDuplicateJobsResult.Items[updatedJobIndex], ...action.payload.message };
            }
            return { ...state, dataObjectsDuplicateJobsResult };
        case JOB_DELETED:
            dataObjectsDuplicateJobsResult = { ...state.dataObjectsDuplicateJobsResult };
            jobItems = [...dataObjectsDuplicateJobsResult.Items];
            dataObjectsDuplicateJobsResult.Items = jobItems;
            let deletedJobIndex = dataObjectsDuplicateJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (deletedJobIndex > -1) {
                dataObjectsDuplicateJobsResult.Items.splice(deletedJobIndex, 1);
            }
            return { ...state, dataObjectsDuplicateJobsResult };
        case RESTART_JOB_SUCCESS:
            dataObjectsDuplicateJobsResult = { ...state.dataObjectsDuplicateJobsResult };
            jobItems = [...dataObjectsDuplicateJobsResult.Items];
            dataObjectsDuplicateJobsResult.Items = jobItems;
            let restartedJobIndex = dataObjectsDuplicateJobsResult.Items.findIndex(job => job.jobId === action.payload.updatedJob.jobId);
            if (restartedJobIndex > -1) {
                dataObjectsDuplicateJobsResult.Items[restartedJobIndex] = action.payload.updatedJob;
            }
            return { ...state, restartJobResult: { success: true }, dataObjectsDuplicateJobsResult };
        case CANCEL_JOB_SUCCESS:
            dataObjectsDuplicateJobsResult = { ...state.dataObjectsDuplicateJobsResult };
            jobItems = [...dataObjectsDuplicateJobsResult.Items];
            dataObjectsDuplicateJobsResult.Items = jobItems;
            let cancelledJobIndex = dataObjectsDuplicateJobsResult.Items.findIndex(job => job.jobId === action.payload.updatedJob.jobId);
            if (cancelledJobIndex > -1) {
                dataObjectsDuplicateJobsResult.Items[cancelledJobIndex] = action.payload.updatedJob;
            }
            return { ...state, cancelJobResult: { success: true }, dataObjectsDuplicateJobsResult };
        case NOMINATE_SURVIVAL_RECORD_REQUEST:
            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId] = { ...duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId] };
            duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items = [...duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items];
            for (let i = 0; i < duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items.length; i++) {
                let record = duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items[i];
                if (record._groupId === action.params.groupId && record._dataObjectId === action.params.dataObjectId) {
                    duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items[i] = { ...record, _nominationResult: "IN_PROGRESS" };
                    break;
                }
            }
            return { ...state, duplicateDataObjectsResultSet };
        case NOMINATE_SURVIVAL_RECORD_SUCCESS:
            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] = { ...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items = [...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items];
            for (let i = 0; i < duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items.length; i++) {
                let record = duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i];
                if (record._groupId === action.payload.groupId) {
                    if (record._dataObjectId === action.payload.dataObjectId) {
                        duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i] = { ...record, _survivor: true, _deleteDuplicates: false, _mergeDuplicates: false, _deduplicationOption: action.payload.deduplicationOption, _nominationResult: "SUCCESS" };
                    }
                    else {
                        duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i] = { ...record, _survivor: false, _deduplicationOption: null, _nominationResult: null };
                        if (action.payload.deduplicationOption === "DELETE_DUPLICATES") {
                            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i]._deleteDuplicates = true;
                        }
                        else {
                            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i]._mergeDuplicates = true;
                        }
                    }
                }
                else if (i > 0 && duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i - 1]._groupId === action.payload.groupId) {
                    break;
                }
            }
            return { ...state, duplicateDataObjectsResultSet };
        case NOMINATE_SURVIVAL_RECORD_FAILURE:
            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] = { ...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items = [...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items];
            for (let i = 0; i < duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items.length; i++) {
                let record = duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i];
                if (record._groupId === action.payload.groupId && record._dataObjectId === action.payload.dataObjectId) {
                    duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i] = { ...record, _nominationResult: "FAILED" };
                    break;
                }
            }
            return { ...state, duplicateDataObjectsResultSet };
        case REMOVE_SURVIVAL_RECORD_NOMINATION_REQUEST:
            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId] = { ...duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId] };
            duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items = [...duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items];
            for (let i = 0; i < duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items.length; i++) {
                let record = duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items[i];
                if (record._groupId === action.params.groupId && record._dataObjectId === action.params.dataObjectId) {
                    duplicateDataObjectsResultSet.resultSet[action.params.duplicateSetId].Items[i] = { ...record, _nominationResult: "REMOVE_IN_PROGRESS" };
                    break;
                }
            }
            return { ...state, duplicateDataObjectsResultSet };
        case REMOVE_SURVIVAL_RECORD_NOMINATION_SUCCESS:
            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] = { ...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items = [...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items];
            for (let i = 0; i < duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items.length; i++) {
                let record = duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i];
                if (record._groupId === action.payload.groupId) {
                    duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i] = { ...record, _survivor: false, _deleteDuplicates: false, _mergeDuplicates: false, _deduplicationOption: null, _nominationResult: null };
                }
                else if (i > 0 && duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i - 1]._groupId === action.payload.groupId) {
                    break;
                }
            }
            return { ...state, duplicateDataObjectsResultSet, removeSurvivalRecordNominationResult: { success: true } };
        case REMOVE_SURVIVAL_RECORD_NOMINATION_FAILURE:
            duplicateDataObjectsResultSet = { ...state.duplicateDataObjectsResultSet };
            duplicateDataObjectsResultSet.resultSet = { ...duplicateDataObjectsResultSet.resultSet };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] = { ...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId] };
            duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items = [...duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items];
            for (let i = 0; i < duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items.length; i++) {
                let record = duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i];
                if (record._groupId === action.payload.groupId && record._dataObjectId === action.payload.dataObjectId) {
                    duplicateDataObjectsResultSet.resultSet[action.payload.duplicateSetId].Items[i] = { ...record, _nominationResult: null };
                    break;
                }
            }
            return { ...state, duplicateDataObjectsResultSet, removeSurvivalRecordNominationResult: { success: false, ...action.payload.error } };
        case RUN_DEDUPLICATE_JOB_SUCCESS:
            return { ...state, runDeDuplicateJobResult: { success: true } };
        case RUN_DEDUPLICATE_JOB_FAILURE:
            return { ...state, runDeDuplicateJobResult: { success: false, ...action.payload.error } };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_SUCCESS:
            return {
                ...state,
                getDuplicateDataObjectsResultSetDataObjectResult: {
                    success: true
                },
                duplicateResultSetDataObjectResult: {
                    dataObject: action.payload.dataObject,
                    job: action.payload.job
                }
            };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_FAILURE:
            return {
                ...state,
                getDuplicateDataObjectsResultSetDataObjectResult: {
                    success: false,
                    ...action.payload.error
                },
                duplicateResultSetDataObjectResult: {
                    dataObject: null,
                    job: null
                }
            };
        case GET_DEDUPLICATED_RECORDS_SUCCESS:
            return { ...state, getDeDuplicatedRecordsRequestResult: { success: true }, deDuplicatedRecordsResult: action.payload.result };
        case GET_DEDUPLICATED_RECORDS_FAILURE:
            return { ...state, getDeDuplicatedRecordsRequestResult: { success: false, ...action.payload.error }, deDuplicatedRecordsResult: { Items: [] } };
        default:
            return state;
    }
};
