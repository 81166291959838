import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { CopyInput } from 'components';

function CopyInputModal({ title, visible, value, onChange, readOnly, copyToolTip, onOkClick, onCancelClick }) {
    const [modalVisible, setModalVisible] = useState(visible);
    useEffect(() => {
        setModalVisible(visible);
    }, [visible]);

    return <Modal
        title={title}
        centered={true}
        visible={modalVisible}
        onOk={onOkClick}
        onCancel={onCancelClick}>
        <CopyInput value={value} onChange={onChange} readOnly={readOnly} copyToolTip={copyToolTip}></CopyInput>
    </Modal>
}

export default CopyInputModal