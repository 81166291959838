import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { NotFoundError, LoadingOverlay, ProhibitedArea } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";

class UserBusinessAreaSchemaWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchingUserBusinessArea: false,
            fetchingSchemaModel: false,
            fetchingSchemaModelPermission: false,
            busy: false,
            busyMessage: "Please wait...",
            invalidBusinessArea: false,
        };
    }

    componentWillMount() {
        this.loadComponent();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserBusinessAreaByNameResultChanged(prevProps);
        this.onGetUserSchemaModelsPermissionResultChanged(prevProps);
        this.onGetSchemaModelResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    loadComponent = () => {
        if (!this.props.userBusinessAreas[this.props.businessArea]) {
            this.setState({
                fetchingUserBusinessArea: true,
                busyMessage: "Please wait",
                invalidBusinessArea: false
            });
            this.props.getUserBusinessAreaByName(this.props.businessArea);
        }
        else {
            this.setSchemaPermissions(this.props.schemaPermission, this.props.userBusinessAreas[this.props.businessArea]);
            this.setState({
                fetchingUserBusinessArea: false,
                invalidBusinessArea: false
            });
            if (!this.props.schemaModel ||
                this.props.schemaModel.name.toLowerCase() !== this.props.schema.toLowerCase() ||
                this.props.schemaModel.businessAreaId !== this.props.userBusinessAreas[this.props.businessArea].businessAreaId) {
                this.setState({
                    fetchingSchemaModel: true
                });
                this.props.getSchemaModel(this.props.userBusinessAreas[this.props.businessArea].businessAreaId, this.props.schema.toLowerCase());
            }
        }

        if (!this.props.schemaPermission) {
            this.setState({
                fetchingSchemaModelPermission: true,
                busyMessage: "Please wait...",
            });
            this.props.getUserSchemasPermission();
        }
        else {
            this.setState({
                fetchingSchemaModelPermission: false,
            });
            this.setSchemaPermissions(this.props.schemaPermission, this.props.userBusinessAreas[this.props.businessArea]);
        }
    }

    onGetUserBusinessAreaByNameResultChanged = (prevProps) => {
        if (this.props.getUserBusinessAreaByNameResult && this.props.getUserBusinessAreaByNameResult !== prevProps.getUserBusinessAreaByNameResult) {
            if (!this.props.getUserBusinessAreaByNameResult.success) {
                this.setState({
                    invalidBusinessArea: true
                });
            }
            else {
                this.loadComponent();
            }
            this.setState({
                fetchingUserBusinessArea: false
            })
        }
    }

    onGetUserSchemaModelsPermissionResultChanged = (prevProps) => {
        if (this.props.getUserSchemaModelsPermissionResult && this.props.getUserSchemaModelsPermissionResult !== prevProps.getUserSchemaModelsPermissionResult) {
            if (!this.props.getUserSchemaModelsPermissionResult.success) {
                this.setState({
                    permissionDenied: true
                });
            }
            else {
                this.setSchemaPermissions(this.props.schemaPermission, this.props.userBusinessAreas[this.props.businessArea]);
            }
            this.setState({
                fetchingSchemaModelPermission: false
            });
        }
    }

    onGetSchemaModelResultChanged = (prevProps) => {
        if (this.props.getSchemaModelResult && this.props.getSchemaModelResult !== prevProps.getSchemaModelResult) {
            if (!this.props.getSchemaModelResult.success) {
                if (this.props.getSchemaModelResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema model.");
                }
            }
            this.setState({
                fetchingSchemaModel: false
            });
        }
    }

    setSchemaPermissions = (permission, userBusinessArea) => {
        let schemaPermission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        if (permission && userBusinessArea && userBusinessArea.user) {
            schemaPermission = {
                canAdd: permission.canAdd && userBusinessArea.user.canAdd,
                canView: permission.canView && userBusinessArea.user.canView,
                canEdit: permission.canEdit && userBusinessArea.user.canEdit,
                canDelete: permission.canDelete && userBusinessArea.user.canDelete,
            };
        }
        this.setState({
            schemaPermission
        });
    }

    getNotFoundError = () => {
        if (!this.props.schemaModel) {
            if (this.props.getSchemaModelResult.success) {
                return {
                    title: "Schema Model not found.",
                    description: "The Schema Model you are looking for does not exist."
                }
            }
            return {
                title: "Schema Model could not be fetched.",
                description: "An error occurred while fetching schema model. Please contact support."
            }
        }
        return null;
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.state.fetchingUserBusinessArea || this.state.fetchingSchemaModelPermission || this.state.fetchingSchemaModel;
        return isBusy;
    }

    render() {
        if (this.state.invalidBusinessArea || this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        let notFoundError = this.getNotFoundError();
        return this.isBusy() ?
            <div className='page-container'>
                <LoadingOverlay
                    busy={this.isBusy()}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
            </div>
            :
            (
                !notFoundError ?
                    this.props.children({ "userBusinessArea": this.props.userBusinessAreas[this.props.businessArea] })
                    :
                    <NotFoundError
                        title={notFoundError.title}
                        description={notFoundError.description}>
                    </NotFoundError>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        userBusinessAreas: state.schemaModels.userBusinessAreas,
        getUserBusinessAreaByNameResult: state.schemaModels.getUserBusinessAreaByNameResult,
        getSchemaModelResult: state.schemaModels.getSchemaModelResult,
        schemaModel: state.schemaModels.schemaModel,
        getUserSchemaModelsPermissionResult: state.schemaModels.getUserSchemaModelsPermissionResult,
        schemaPermission: state.schemaModels.permission,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreaByName: (businessAreaName) => dispatch(actions.getUserBusinessAreaByNameRequest(businessAreaName)),
        getSchemaModel: (businessAreaId, schemaName) => dispatch(actions.getSchemaModelByNameRequest(businessAreaId, schemaName)),
        getUserSchemasPermission: () => dispatch(actions.getUserSchemaModelsPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserBusinessAreaSchemaWrapper));