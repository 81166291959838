import {
    GET_USER_EVENTS_SUCCESS,
    GET_USER_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getUserEventsResult: {
        success: true
    },
    userEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_EVENTS_SUCCESS:
            return { ...state, getUserEventsResult: { success: true }, userEventsResult: action.payload };
        case GET_USER_EVENTS_FAILURE:
            return {
                ...state, getUserEventsResult: { success: false, ...action.payload }, userEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
