export const GET_JOBS_REQUEST = "GET_JOBS_REQUEST";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAILURE = "GET_JOBS_FAILURE";

export const GET_JOB_BY_ID_REQUEST = "GET_JOB_BY_ID_REQUEST";
export const GET_JOB_BY_ID_SUCCESS = "GET_JOB_BY_ID_SUCCESS";
export const GET_JOB_BY_ID_FAILURE = "GET_JOB_BY_ID_FAILURE";

export const RESTART_JOB_REQUEST = "RESTART_JOB_REQUEST";
export const RESTART_JOB_SUCCESS = "RESTART_JOB_SUCCESS";
export const RESTART_JOB_FAILURE = "RESTART_JOB_FAILURE";

export const CANCEL_JOB_REQUEST = "CANCEL_JOB_REQUEST";
export const CANCEL_JOB_SUCCESS = "CANCEL_JOB_SUCCESS";
export const CANCEL_JOB_FAILURE = "CANCEL_JOB_FAILURE";

export const GET_JOBS_DOMAIN_DATA_REQUEST = "GET_JOBS_DOMAIN_DATA_REQUEST";
export const GET_JOBS_DOMAIN_DATA_SUCCESS = "GET_JOBS_DOMAIN_DATA_SUCCESS";
export const GET_JOBS_DOMAIN_DATA_FAILURE = "GET_JOBS_DOMAIN_DATA_FAILURE";

export const GET_JOBS_DATA_OBJECTS_REQUEST = "GET_JOBS_DATA_OBJECTS_REQUEST";
export const GET_JOBS_DATA_OBJECTS_SUCCESS = "GET_JOBS_DATA_OBJECTS_SUCCESS";
export const GET_JOBS_DATA_OBJECTS_FAILURE = "GET_JOBS_DATA_OBJECTS_FAILURE";

export const GET_RECENT_DATAOBJECT_JOBS_REQUEST = "GET_RECENT_DATAOBJECT_JOBS_REQUEST";
export const GET_RECENT_DATAOBJECT_JOBS_SUCCESS = "GET_RECENT_DATAOBJECT_JOBS_SUCCESS";
export const GET_RECENT_DATAOBJECT_JOBS_FAILURE = "GET_RECENT_DATAOBJECT_JOBS_FAILURE";

export const JOB_EXECUTION_PROGRESS = "JOB_EXECUTION_PROGRESS";
export const JOB_DELETED = "JOB_DELETED";

export const getJobsRequest = () => ({
    type: GET_JOBS_REQUEST
})

export const getJobsSuccess = (jobResult) => ({
    type: GET_JOBS_SUCCESS,
    payload: jobResult
})

export const getJobsFailure = (error) => ({
    type: GET_JOBS_FAILURE,
    payload: error
})

export const getJobsDomainDataRequest = (jobId, itemOperation, dataVersion, pageKey) => ({
    type: GET_JOBS_DOMAIN_DATA_REQUEST,
    params: {
        jobId,
        itemOperation,
        dataVersion,
        pageKey
    }
})

export const getJobsDomainDataSuccess = (domainDataResult) => ({
    type: GET_JOBS_DOMAIN_DATA_SUCCESS,
    payload: domainDataResult
})

export const getJobsDomainDataFailure = (error) => ({
    type: GET_JOBS_DOMAIN_DATA_FAILURE,
    payload: error
})

export const getJobsDataObjectsRequest = (jobId, itemOperation, dataVersion, pageKey) => ({
    type: GET_JOBS_DATA_OBJECTS_REQUEST,
    params: {
        jobId,
        itemOperation,
        dataVersion,
        pageKey
    }
})

export const getJobsDataObjectsSuccess = (dataObjectsResult) => ({
    type: GET_JOBS_DATA_OBJECTS_SUCCESS,
    payload: dataObjectsResult
})

export const getJobsDataObjectsFailure = (error) => ({
    type: GET_JOBS_DATA_OBJECTS_FAILURE,
    payload: error
})

export const restartJobRequest = (jobId, version, carryRunInfo, undo = false) => ({
    type: RESTART_JOB_REQUEST,
    params: {
        jobId,
        version,
        undo,
        carryRunInfo
    }
})

export const restartJobSuccess = (updatedJob) => ({
    type: RESTART_JOB_SUCCESS,
    payload: {
        updatedJob
    }
})

export const restartJobFailure = (error) => ({
    type: RESTART_JOB_FAILURE,
    payload: error
})

export const cancelJobRequest = (jobId, version) => ({
    type: CANCEL_JOB_REQUEST,
    params: {
        jobId,
        version
    }
})

export const cancelJobSuccess = (updatedJob) => ({
    type: CANCEL_JOB_SUCCESS,
    payload: {
        updatedJob
    }
})

export const cancelJobFailure = (error) => ({
    type: CANCEL_JOB_FAILURE,
    payload: error
})

export const jobExecutionProgress = (message) => ({
    type: JOB_EXECUTION_PROGRESS,
    payload: {
        message
    }
})

export const onJobDeleted = (message) => ({
    type: JOB_DELETED,
    payload: {
        message
    }
})

export const getJobByIdRequest = (jobId) => ({
    type: GET_JOB_BY_ID_REQUEST,
    params: {
        jobId
    }
})

export const getJobByIdSuccess = (job) => ({
    type: GET_JOB_BY_ID_SUCCESS,
    payload: {
        job
    }
})

export const getJobByIdFailure = (jobId, error) => ({
    type: GET_JOB_BY_ID_FAILURE,
    payload: {
        jobId,
        error
    }
})

export const getRecentDataObjectJobsRequest = () => ({
    type: GET_RECENT_DATAOBJECT_JOBS_REQUEST
})

export const getRecentDataObjectJobsSuccess = (jobsResult) => ({
    type: GET_RECENT_DATAOBJECT_JOBS_SUCCESS,
    payload: {
        jobsResult
    }
})

export const getRecentDataObjectJobsFailure = (error) => ({
    type: GET_RECENT_DATAOBJECT_JOBS_FAILURE,
    payload: {
        error
    }
})