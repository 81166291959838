import React, { useState, useRef, useEffect } from "react";
import { Button, Table, Checkbox, Row, Col, Tooltip, List, Typography } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FullHeightContainerLayout, RoleUserChangedAttributes } from "components";
import _ from 'lodash';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const RoleUserHistoryList = ({ roleUserRevisions }) => {
    const [changedRecord, setChangedRecord] = useState(null);
    const [containerHeight, setContainerHeight] = useState("100%");
    const changedAttributesContainer = useRef(null);
    useEffect(() => {
        if (changedAttributesContainer && changedAttributesContainer.current) {
            changedAttributesContainer.current.scrollIntoView && changedAttributesContainer.current.scrollIntoView();
        }
    }, [changedRecord]);
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            content={<Table size="small"
                                rowKey="data.userId"
                                dataSource={roleUserRevisions}
                                pagination={false}
                                scroll={{ y: `100vh` }}
                                className={"container-height-100"}>
                                <Column
                                    width="3rem"
                                    title="No."
                                    key="index"
                                    render={(value, revision, index) => index + 1}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "email"]}
                                    title={"Email"}
                                    render={(value, revision, index) => <>{value}</>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={["data", "firstName"]}
                                    title={"First Name"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={["data", "lastName"]}
                                    title={"Last Name"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="5rem"
                                    title={"Active"}
                                    dataIndex={["data", "active"]}
                                    render={(value, revision, index) => <Checkbox disabled={true} checked={value}></Checkbox>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "createdByEmail"]}
                                    title={"Created By"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "createdDate"]}
                                    title={"Created Date"}
                                    render={(value, revision, index) => <>{moment.utc(value).toDate().toLocaleString()}</>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "updatedByEmail"]}
                                    title={"Updated By"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "updatedDate"]}
                                    title={"Updated Date"}
                                    render={(value, revision, index) => <>{moment.utc(value).toDate().toLocaleString()}</>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"changes"}
                                    title={"Changes"}
                                    render={(value, revision, index) => {
                                        if (value) {
                                            return <>
                                                {Object.keys(value).reduce((items, key) => {
                                                    if (Object.keys(value[key]).length > 0) {
                                                        items.push(<Row key={key}>
                                                            <Col>
                                                                <Link onClick={() => {
                                                                    setContainerHeight("50%");
                                                                    setChangedRecord({
                                                                        name: `${_.capitalize(key)} attributes`,
                                                                        data: value[key],
                                                                        previousData: revision.previousChanges && revision.previousChanges[key]
                                                                    });
                                                                }}>
                                                                    {`${key} attributes`}
                                                                </Link>
                                                            </Col>
                                                        </Row>);
                                                    }
                                                    return items;
                                                }, [])}
                                            </>
                                        }
                                        return <></>;
                                    }}
                                />
                            </Table>}>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
                {
                    changedRecord
                        ?
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24} ref={changedAttributesContainer}>
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    content={<RoleUserChangedAttributes record={changedRecord}></RoleUserChangedAttributes>}
                                    showFooter={false}>
                                </FullHeightContainerLayout>
                            </Col>
                        </Row>
                        :
                        <></>
                }
            </Col>
        </Row>
    );
};

export default RoleUserHistoryList;