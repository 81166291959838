import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { AwsS3BucketList, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col } from "antd";

class SystemConnectionAwsS3Buckets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busyMessage: "",
            fetchingBuckets: false,
            permissionDenied: false,
        }
    }

    componentWillMount() {
        this.setState({
            fetchingBuckets: true
        });
        this.props.getAwsS3ConnectionBuckets(this.props.connectionId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetAwsS3ConnectionBucketsResultUpdate(prevProps);
    }

    onGetAwsS3ConnectionBucketsResultUpdate = (prevProps) => {
        if (this.props.getAwsS3ConnectionBucketsResult && this.props.getAwsS3ConnectionBucketsResult !== prevProps.getAwsS3ConnectionBucketsResult) {
            if (!this.props.getAwsS3ConnectionBucketsResult.success) {
                if (this.props.getAwsS3ConnectionBucketsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connection buckets at this moment.");
                }
            }
            this.setState({
                fetchingBuckets: false
            });
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.state.fetchingBuckets}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    <AwsS3BucketList
                        buckets={this.props.awsS3ConnectionBuckets}
                        onBucketSelected={this.props.onBucketSelected}>
                    </AwsS3BucketList>
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getAwsS3ConnectionBucketsResult: state.systemConnectionAwsS3.getAwsS3ConnectionBucketsResult,
        awsS3ConnectionBuckets: state.systemConnectionAwsS3.awsS3ConnectionBuckets
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAwsS3ConnectionBuckets: (connectionId) => dispatch(actions.getAwsS3ConnectionBucketsRequest(connectionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemConnectionAwsS3Buckets));