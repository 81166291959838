import { QUERY_BUILDER_OBJECT } from "./actionTypes";

const initialState: IQueryBuilderObjectState = {
    query: null,
};

export default function queryBuilderObjectReducer(
    state: IQueryBuilderObjectState = initialState,
    action: IQueryBuilderObjectAction,
): IQueryBuilderObjectState {
    switch (action.type) {
        case QUERY_BUILDER_OBJECT:
            return {
                ...state,
                query: action.payload.query,
            };
        default:
            return state;
    }
}
