import {
    GET_EVENT_ROLE_SUCCESS,
    GET_EVENT_ROLE_FAILURE
} from "./actions";

const initialState = {
    getEventRoleResult: {
        success: true
    },
    roleRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_ROLE_SUCCESS:
            return { ...state, getEventRoleResult: { success: true }, roleRevision: action.payload };
        case GET_EVENT_ROLE_FAILURE:
            return {
                ...state, getEventRoleResult: { success: false, ...action.payload }, roleRevision: null
            };
        default:
            return state;
    }
};
