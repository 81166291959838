import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, TenantProvisionDetails, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import AppPaths from 'constants/appPaths';

class TenantProvision extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingProvisionSteps: true
        }
    }

    componentWillMount() {
        this.props.getTenantProvisionSteps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetTenantProvisionStepsResultChanged(prevProps);
    }

    onGetTenantProvisionStepsResultChanged = (prevProps) => {
        if (this.props.getTenantProvisionStepsResult && this.props.getTenantProvisionStepsResult !== prevProps.getTenantProvisionStepsResult) {
            if (!this.props.getTenantProvisionStepsResult.success) {
                showError("Could not able to load tenant provision details.");
            }
            this.setState({
                fetchingProvisionSteps: false
            });
        }
    }

    onGoToHomeClicked = () => {
        if (this.props.tenant) {
            this.props.history.push(AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.name));
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    this.state.fetchingProvisionSteps
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message="Loading tenant provision details...">
                        </LoadingOverlay>
                        :
                        <TenantProvisionDetails
                            provisionSteps={this.props.provisionSteps}
                            isTenantProvisioned={(this.props.tenant && this.props.tenant.state === "ACTIVE") || this.props.provisioned}
                            progress={this.props.progress}
                            onGoToHomeClick={this.onGoToHomeClicked}></TenantProvisionDetails>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getTenantProvisionStepsResult: state.tenantProvision.getTenantProvisionStepsResult,
        provisionSteps: state.tenantProvision.provisionSteps,
        progress: state.tenantProvision.progress,
        provisioned: state.tenantProvision.provisioned,
        tenant: state.userLogin.tenant
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantProvisionSteps: () => dispatch(actions.getTenantProvisionStepsRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantProvision));