import React, { useState } from "react";
import { Form, Input, Modal, Table, Button, Tooltip } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout, BatchProcessResult, LoadingOverlay } from 'components';
import moment from "moment";

const { Column } = Table;
const DataSetBatchUploadHistoryModal = ({ visible, jobList, onCancel, onOk, onUndoJob, loadingData = false, isBusy = false }) => {
    const [jobUndoInitiated, setJobUndoInitiated] = useState(false);
    return (
        <Modal
            visible={visible}
            bodyStyle={{ height: "50vh" }}
            centered={true}
            title="Batch Uploads"
            okText={"Ok"}
            cancelText="Cancel"
            onCancel={() => {
                onCancel(jobUndoInitiated);
            }}
            onOk={() => {
                onOk(jobUndoInitiated);
            }}
            width={1500}>
            <FullHeightContainerLayout
                content={
                    <>
                        <LoadingOverlay
                            busy={loadingData || isBusy}
                            spinner
                            message={"Please wait..."}>
                        </LoadingOverlay>
                        {!loadingData ?
                            <Table size="small"
                                rowKey="jobId"
                                dataSource={jobList}
                                pagination={false}
                                className="form-table">
                                <Column
                                    width="2rem"
                                    render={(value, job, index) => <>
                                        {
                                            job.canUndo ?
                                                <Tooltip title="Undo this job">
                                                    <Icon
                                                        color='black'
                                                        name='undo'
                                                        className="action-cursor"
                                                        onClick={() => {
                                                            setJobUndoInitiated(true);
                                                            onUndoJob(job.jobId, job.version);
                                                        }} />
                                                </Tooltip>
                                                :
                                                <></>
                                        }
                                    </>}
                                />
                                <Column
                                    width="3rem"
                                    title="No."
                                    key="index"
                                    render={(value, job, index) => index + 1}
                                />
                                <Column
                                    width="7rem"
                                    dataIndex={"status"}
                                    title={"Status"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => {
                                        let color = "#5C5B5B";
                                        switch (value) {
                                            case "COMPLETED":
                                                color = "#11A400";
                                                break;
                                            case "FAILED":
                                            case "CANCELLING":
                                            case "CANCELLED":
                                                color = "#FF0000";
                                                break;
                                            case "RUNNING":
                                            case "PENDING":
                                                color = "#1F81FF";
                                                break;
                                        }
                                        let statusText = value;
                                        if (job.undo) {
                                            if (job.jobType === "FIND_DATA_OBJECTS_DUPLICATES") {
                                                statusText = `DELETE ${statusText}`;
                                            }
                                            else {
                                                statusText = `UNDO ${statusText}`;
                                            }
                                        }
                                        return <Tooltip placement="topLeft" title={statusText}><span style={{ color: color }}>{statusText}</span></Tooltip>
                                    }}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"runInfo"}
                                    title={"Details"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => {
                                        return value ?
                                            <BatchProcessResult
                                                batchProcessResult={value}
                                                textMaxWidth="12rem"
                                                onBatchOperationClick={(item) => {
                                                }}>
                                            </BatchProcessResult> :
                                            <></>
                                    }}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={"createdByEmail"}
                                    title={"User"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value || job.createdBy}</Tooltip>}
                                />
                                <Column
                                    width="8rem"
                                    dataIndex={"startedDate"}
                                    title={"Started Date"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => {
                                        let displayValue = null;
                                        if (value) {
                                            displayValue = moment.utc(value).toDate().toLocaleDateString();
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                                <Column
                                    width="8rem"
                                    dataIndex={"startedDate"}
                                    title={"Started Time"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => {
                                        let displayValue = null;
                                        if (value) {
                                            displayValue = moment.utc(value).toDate().toLocaleTimeString();
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                                <Column
                                    width="9rem"
                                    dataIndex={"completedDate"}
                                    title={"Completed Date"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => {
                                        let displayValue = null;
                                        if (value) {
                                            displayValue = moment.utc(value).toDate().toLocaleDateString();
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                                <Column
                                    width="9rem"
                                    dataIndex={"completedDate"}
                                    title={"Completed Time"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, job, index) => {
                                        let displayValue = null;
                                        if (value) {
                                            displayValue = moment.utc(value).toDate().toLocaleTimeString();
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                                <Column
                                    width="8rem"
                                    title={"Elapsed Time"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(job) => {
                                        if (job.startedDate && job.completedDate) {
                                            let startDate = moment.utc(job.startedDate);
                                            let completedDate = moment.utc(job.completedDate);
                                            let duration = moment.duration(completedDate.diff(startDate));
                                            let displayValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                            </Table>
                            :
                            <></>}
                    </>
                }
                showFooter={false}>
            </FullHeightContainerLayout>
        </Modal >
    );
};

export default DataSetBatchUploadHistoryModal;