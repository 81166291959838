import React, { useState, useRef } from "react";
import { Card, Tag, Modal, Checkbox, Typography, Tooltip, Row, Col, Space, Button } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout, BasicFormBuilder } from 'components';
import moment from "moment";
import validator from 'validator';

const { Text, Title, Link, Paragraph } = Typography;

const GuestDataObjectSelfVerificationForm = ({ dataObject, schemaModel, showConsent, onContinue }) => {
    const basicFormRef = useRef(null);
    const [consent, setConsent] = useState(false)

    const onConsentValueChange = (e) => {
        setConsent(e.target.checked);
    };

    const onContinueClick = () => {
        basicFormRef.current.submit();
    }

    const onUpdateDataObject = (dataObjectId, updatedDataObject) => {
        onContinue(dataObjectId, updatedDataObject, consent);
    }

    return (
        <FullHeightContainerLayout
            showHeader={true}
            header={<Title level={2}>Self Service Consent as a Guest</Title>}
            content={
                <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                    <Row style={{ justifyContent: "space-between" }}>
                        <Col>
                            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                <Text>Welcome to the Pretectum Consumer Self Service Verification Portal</Text>
                                <Text>You've elected to verify your details as a guest</Text>
                            </Space>
                        </Col>
                        <Col>
                            <Space direction="horizontal" size="small" style={{ display: 'flex' }}>
                                <Text>You're verifying your data as supplied to:</Text>
                                <Link href="https://www.pretectum.com/" target="_blank">Pretectum Inc (www.pretectum.com)</Link>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <BasicFormBuilder
                            ref={basicFormRef}
                            key={dataObject.dataObjectId}
                            showHeader={false}
                            formDataObject={dataObject}
                            schemaFields={schemaModel.fields || []}
                            onCreate={() => { }}
                            onUpdate={onUpdateDataObject}
                            onCancel={() => { }}
                            readOnly={false}
                            showFooter={false}>
                        </BasicFormBuilder>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card size="small" bordered={false} title={<Text strong>Privacy Notice</Text>}>
                                <Paragraph>We may process data about your use of our website and services (“usage data“). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services.</Paragraph>
                                <Paragraph> We may process your account data (“account data“). The account data may include your name, your employer, your job title or role, your email address, business address, telephone number(s) and/or other contact details, and information contained in communications between us and you or your employer. The source of the account data is you or your employer. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</Paragraph>
                            </Card>
                        </Col>
                    </Row>
                </Space>
            }
            showFooter={true}
            footer={<Row style={{ justifyContent: "space-between" }}>
                <Col>
                    {showConsent && <Checkbox onChange={onConsentValueChange} value={consent}>I consent to the use of my information as detailed above</Checkbox>}
                </Col>
                <Col>
                    <Button type="primary" onClick={onContinueClick}>Continue</Button>
                </Col>
            </Row>}>
        </FullHeightContainerLayout>
    );
};

export default GuestDataObjectSelfVerificationForm;