import {
    BuildOutlined,
    CommentOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    FileSearchOutlined,
    SearchOutlined,
    SwapOutlined,
} from "@ant-design/icons";
import {
    Alert,
    Button,
    Card,
    ConfigProvider,
    Flex,
    Form,
    Input,
    MenuProps,
    Segmented,
    SegmentedProps,
    Skeleton,
    Space,
    Typography,
} from "antd";
import convertQueryBuilderToSimpleText from "common/convertQueryBuilderToSimpleText";
import {
    ElasticSearchQueryBuilder,
    FormDropDownTag,
    FullHeightContainerLayout,
} from "components";
import React from "react";
import { RuleGroupType } from "react-querybuilder";

interface ISelectOption {
    key: string;
    value: string;
    label: string | React.ReactNode;
}

const queryTypeOptions: SegmentedProps["options"] = [
    {
        value: "QUERY",
        label: "Advanced Query",
        icon: <FileSearchOutlined />,
    },
    {
        value: "DSL_QUERY",
        label: "Build My Query",
        icon: <BuildOutlined />,
    },
    {
        value: "NATURAL_LANGUAGE_QUERY",
        label: "Ask Anything",
        icon: <CommentOutlined />,
    },
];

const DEFAULT_BUSINESS_AREAS: ISelectOption[] = [
    { key: "all", value: "all", label: "All Business Areas" },
];
const DEFAULT_SCHEMA_LIST: ISelectOption[] = [
    { key: "all", value: "all", label: "All Schemas" },
];
const DEFAULT_DATA_SET_LIST: ISelectOption[] = [
    { key: "all", value: "all", label: "All Datasets" },
];

export default function DataObjectSearchForm({
    selectedQueryType,
    defaultSearchValues,
    onSearch,
    activeBusinessAreaId,
    isLoadingBusinessAreas,
    businessAreas,
    onBusinessAreaChange,
    loadingSchemaList,
    schemaList,
    onSchemaChange,
    loadingDataSetList,
    dataSetList,
    onQueryTypeChange,
    // For Query Builder
    rawQuery,
    builderProperties,
    isLoadingBuilderProperties,
    onUpdateQuery,
    onSearchBuilderProperties,
    onQueryBuilderSearch,
}: IDataObjectSearchFormProps) {
    const [form] = Form.useForm();

    const [businessAreaOptions, setBusinessAreaOptions] = React.useState<
        ISelectOption[]
    >(DEFAULT_BUSINESS_AREAS);
    const [schemaOptions, setSchemaOptions] =
        React.useState<ISelectOption[]>(DEFAULT_SCHEMA_LIST);
    const [dataSetOptions, setDataSetOptions] = React.useState<ISelectOption[]>(
        DEFAULT_DATA_SET_LIST,
    );

    const [selectedBusinessArea, setSelectedBusinessArea] =
        React.useState<ISelectOption>();
    const [selectedSchema, setSelectedSchema] = React.useState<ISelectOption>(
        DEFAULT_SCHEMA_LIST[0],
    );
    const [selectedDataset, setSelectedDataset] = React.useState<ISelectOption>(
        DEFAULT_DATA_SET_LIST[0],
    );
    // Store DSL query temporarily until it's ready for search
    const [tempQuery, setTempQuery] = React.useState<Record<
        string,
        any
    > | null>(null);
    const [dslQuery, setDslQuery] = React.useState<Record<string, any> | null>(
        null,
    );
    const [showQueryBuilder, setShowQueryBuilder] =
        React.useState<boolean>(true);

    React.useEffect(() => {
        setBusinessAreaOptions([
            ...DEFAULT_BUSINESS_AREAS,
            ...businessAreas.map((item) => ({
                key: item.businessAreaId,
                value: item.businessAreaId,
                label: item.name,
            })),
        ]);
    }, [businessAreas]);

    const businessAreaDropdownItems: MenuProps["items"] = React.useMemo(
        () => [
            ...businessAreaOptions.map((item) => ({
                key: item.key,
                label: item.label,
            })),
        ],
        [businessAreaOptions],
    );

    React.useEffect(() => {
        setSchemaOptions([
            ...DEFAULT_SCHEMA_LIST,
            ...schemaList.Items.map((item) => ({
                key: item.schemaId,
                value: item.schemaId,
                label: item.name,
            })),
        ]);
    }, [schemaList]);

    const schemaDropdownItems: MenuProps["items"] = React.useMemo(
        () => [
            ...schemaOptions.map((item) => ({
                key: item.key,
                label: item.label,
            })),
        ],
        [schemaOptions],
    );

    React.useEffect(() => {
        setDataSetOptions([
            ...DEFAULT_DATA_SET_LIST,
            ...dataSetList.Items.map((item) => ({
                key: item.dataSetId,
                value: item.dataSetId,
                label: item.dataSetName,
            })),
        ]);
    }, [dataSetList]);

    const dataSetDropdownItems: MenuProps["items"] = React.useMemo(
        () => [
            ...dataSetOptions.map((item) => ({
                key: item.key,
                label: item.label,
            })),
        ],
        [dataSetOptions],
    );

    const onFormValuesChanged = React.useCallback(
        (
            changedValues: ISearchFormValueType,
            allValues: ISearchFormValueType,
        ) => {
            if (changedValues.businessArea) {
                // Show Tag
                const selectedIndex = businessAreaOptions.findIndex(
                    (ba) => ba.key === changedValues.businessArea,
                );
                if (selectedIndex > -1) {
                    setSelectedBusinessArea(businessAreaOptions[selectedIndex]);
                }
                // Reset selected Schema and DataSet
                setSelectedSchema(DEFAULT_SCHEMA_LIST[0]);
                setSelectedDataset(DEFAULT_DATA_SET_LIST[0]);

                allValues["schema"] = DEFAULT_SCHEMA_LIST[0].key;
                allValues["dataSet"] = DEFAULT_DATA_SET_LIST[0].key;
                form.setFieldsValue(allValues);
                setSchemaOptions(DEFAULT_SCHEMA_LIST);
                setDataSetOptions(DEFAULT_DATA_SET_LIST);
                if (changedValues.businessArea !== "all") {
                    onBusinessAreaChange(changedValues.businessArea);
                }
            } else if (changedValues.schema) {
                // Show Tag
                const selectedIndex = schemaOptions.findIndex(
                    (sc) => sc.key === changedValues.schema,
                );
                if (selectedIndex > -1) {
                    setSelectedSchema(schemaOptions[selectedIndex]);
                }
                // Reset selected DataSet
                setSelectedDataset(DEFAULT_DATA_SET_LIST[0]);

                allValues["dataSet"] = DEFAULT_DATA_SET_LIST[0].key;
                form.setFieldsValue(allValues);
                setDataSetOptions(DEFAULT_DATA_SET_LIST);
                if (changedValues.schema !== "all" && allValues.businessArea) {
                    onSchemaChange(
                        allValues.businessArea,
                        changedValues.schema,
                    );
                }
            } else if (changedValues.dataSet) {
                // Show Tag
                const selectedIndex = dataSetOptions.findIndex(
                    (ds) => ds.key === changedValues.dataSet,
                );
                if (selectedIndex > -1) {
                    setSelectedDataset(dataSetOptions[selectedIndex]);
                }
            }

            // If query input is dirty, submit the form with new filter
            if (
                changedValues.businessArea ||
                changedValues.schema ||
                changedValues.dataSet
            ) {
                if (!!allValues.query) {
                    form.submit(); // Submit the query
                }
            }
        },
        [
            businessAreaOptions,
            dataSetOptions,
            form,
            onBusinessAreaChange,
            onSchemaChange,
            schemaOptions,
        ],
    );

    const searchInputPlaceHolder = React.useMemo(() => {
        if (selectedQueryType === "NATURAL_LANGUAGE_QUERY") {
            return "Show all people with no first name or just an initial";
        } else if (selectedQueryType === "QUERY") {
            return 'John #Name:Jon Smith DOB:10/04/1970 City:"London" Country:Canada';
        }
        return "Query Builder Selected";
    }, [selectedQueryType]);

    const handleClearForm = React.useCallback(() => {
        setSelectedBusinessArea(DEFAULT_BUSINESS_AREAS[0]);
        setSelectedDataset(DEFAULT_DATA_SET_LIST[0]);
        setSelectedSchema(DEFAULT_SCHEMA_LIST[0]);
        setSchemaOptions(DEFAULT_SCHEMA_LIST);
        setDataSetOptions(DEFAULT_DATA_SET_LIST);
        form.resetFields();
    }, [form]);

    // Update search fields for Query Build
    React.useEffect(() => {
        if (selectedQueryType === "DSL_QUERY" && selectedBusinessArea) {
            onSearchBuilderProperties(
                selectedBusinessArea.value,
                selectedSchema.value,
                selectedDataset.value,
            );
        }
    }, [
        selectedBusinessArea,
        selectedSchema,
        selectedDataset,
        selectedQueryType,
        onSearchBuilderProperties,
    ]);

    const handleUpdateQuery = React.useCallback(
        (q: Record<string, any>, _rawQuery?: RuleGroupType) => {
            setTempQuery(q);
            onUpdateQuery && onUpdateQuery(_rawQuery);
        },
        [onUpdateQuery],
    );

    const handleQueryBuilderSearch = React.useCallback(() => {
        setDslQuery(tempQuery);
    }, [tempQuery]);

    // Refresh search result when filters have changed
    React.useEffect(() => {
        if (
            selectedQueryType === "DSL_QUERY" &&
            dslQuery &&
            selectedBusinessArea
        ) {
            onQueryBuilderSearch(
                dslQuery,
                selectedBusinessArea.value,
                selectedSchema.value,
                selectedDataset.value,
            );
        }
    }, [
        selectedBusinessArea,
        selectedSchema,
        selectedDataset,
        selectedQueryType,
        dslQuery,
        onQueryBuilderSearch,
    ]);

    const handleChangeQueryType = React.useCallback(
        (_newQueryType: IQueryType) => {
            onQueryTypeChange(_newQueryType);
        },
        [onQueryTypeChange],
    );

    // Convert Query to Text and switch to Advanced Tab
    const handleQueryToText = React.useCallback(() => {
        const queryToText = convertQueryBuilderToSimpleText(rawQuery);
        form.setFieldValue("query", queryToText);
        // Change the segmented UI
        onQueryTypeChange("QUERY");
        form.setFieldValue("queryType", "QUERY");
    }, [form, onQueryTypeChange, rawQuery]);

    React.useEffect(() => {
        if (selectedQueryType === "DSL_QUERY") {
            setShowQueryBuilder(true);
        }
    }, [selectedQueryType]);

    // Update form input when query type has changed
    React.useEffect(() => {
        if (form) {
            const allValues = form.getFieldsValue(true);
            allValues["queryType"] = selectedQueryType;
            form.setFieldsValue(allValues);
        }
    }, [form, selectedQueryType]);

    React.useEffect(() => {
        // Automatically select active Business Area
        const active = businessAreaOptions.find(
            (ba) => ba.value === activeBusinessAreaId,
        );
        if (active && activeBusinessAreaId) {
            setSelectedBusinessArea(active);
            form.setFieldValue("businessArea", activeBusinessAreaId);
            onBusinessAreaChange(activeBusinessAreaId);
        }
    }, [activeBusinessAreaId, businessAreaOptions, form, onBusinessAreaChange]);

    return (
        <FullHeightContainerLayout
            showHeader
            content={
                <Form
                    form={form}
                    initialValues={defaultSearchValues}
                    name="data-object-search-form"
                    layout="inline"
                    onFinish={onSearch}
                    onValuesChange={onFormValuesChanged}
                >
                    <Flex flex={1} vertical>
                        <Form.Item
                            name="queryType"
                            initialValue={selectedQueryType}
                            noStyle
                        >
                            <Segmented
                                options={queryTypeOptions}
                                onChange={(k) =>
                                    handleChangeQueryType(k as IQueryType)
                                }
                                block
                                size="large"
                            />
                        </Form.Item>

                        <ConfigProvider wave={{ disabled: true }}>
                            <Card
                                size="small"
                                style={{
                                    marginTop: 12,
                                    backgroundColor: "#f5f5f5",
                                }}
                            >
                                <Flex flex={1} vertical gap="middle">
                                    {selectedQueryType ===
                                        "NATURAL_LANGUAGE_QUERY" && (
                                        <Alert
                                            type="warning"
                                            showIcon
                                            message={
                                                <Typography>
                                                    <b>Ask Anything</b> relies
                                                    on AI which is unpredictable
                                                    and may produce inconsistent
                                                    results on each call.
                                                </Typography>
                                            }
                                        />
                                    )}
                                    <Flex flex={1} gap="small" wrap="wrap">
                                        {selectedBusinessArea ? (
                                            <>
                                                <Form.Item
                                                    name="businessArea"
                                                    initialValue={
                                                        selectedBusinessArea.value
                                                    }
                                                    noStyle
                                                >
                                                    <FormDropDownTag
                                                        color="purple"
                                                        title="Business Area"
                                                        items={
                                                            businessAreaDropdownItems
                                                        }
                                                        label={
                                                            selectedBusinessArea.label
                                                        }
                                                        disabled={
                                                            isLoadingBusinessAreas
                                                        }
                                                        loading={
                                                            isLoadingBusinessAreas
                                                        }
                                                        showIcon
                                                        value={
                                                            selectedBusinessArea.value
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="schema"
                                                    initialValue={
                                                        DEFAULT_SCHEMA_LIST[0]
                                                            .key
                                                    }
                                                    noStyle
                                                >
                                                    <FormDropDownTag
                                                        color="blue"
                                                        items={
                                                            schemaDropdownItems
                                                        }
                                                        title="Schema"
                                                        label={
                                                            selectedSchema.label
                                                        }
                                                        disabled={
                                                            loadingSchemaList
                                                        }
                                                        loading={
                                                            loadingSchemaList
                                                        }
                                                        showIcon
                                                        value={
                                                            selectedSchema.value
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="dataSet"
                                                    initialValue={
                                                        DEFAULT_DATA_SET_LIST[0]
                                                            .key
                                                    }
                                                    noStyle
                                                >
                                                    <FormDropDownTag
                                                        color="cyan"
                                                        items={
                                                            dataSetDropdownItems
                                                        }
                                                        title="DataSet"
                                                        label={
                                                            selectedDataset.label
                                                        }
                                                        disabled={
                                                            loadingDataSetList
                                                        }
                                                        loading={
                                                            loadingDataSetList
                                                        }
                                                        showIcon
                                                        value={
                                                            selectedDataset.value
                                                        }
                                                    />
                                                </Form.Item>
                                            </>
                                        ) : (
                                            <Space>
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                />
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                />
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                />
                                            </Space>
                                        )}
                                        {selectedQueryType === "DSL_QUERY" && (
                                            <Button
                                                size="small"
                                                style={{
                                                    position: "absolute",
                                                    right: 6,
                                                    top: 6,
                                                }}
                                                icon={
                                                    showQueryBuilder ? (
                                                        <EyeInvisibleOutlined />
                                                    ) : (
                                                        <EyeOutlined />
                                                    )
                                                }
                                                onClick={() =>
                                                    setShowQueryBuilder(
                                                        (prev) => !prev,
                                                    )
                                                }
                                            >
                                                {showQueryBuilder
                                                    ? "Hide Query Builder"
                                                    : "Show Query Builder"}
                                            </Button>
                                        )}
                                    </Flex>
                                    {selectedQueryType !== "DSL_QUERY" && (
                                        <Flex flex={1} gap="middle">
                                            <Space.Compact block>
                                                <Form.Item name="query" noStyle>
                                                    <Input
                                                        placeholder={
                                                            searchInputPlaceHolder
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "query",
                                                                e.currentTarget
                                                                    .value,
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                                <Button
                                                    type="primary"
                                                    onClick={() =>
                                                        form.submit()
                                                    }
                                                    icon={<SearchOutlined />}
                                                    iconPosition="start"
                                                >
                                                    Search
                                                </Button>
                                            </Space.Compact>
                                            <Button
                                                onClick={() =>
                                                    handleClearForm()
                                                }
                                            >
                                                Clear
                                            </Button>
                                        </Flex>
                                    )}
                                    {selectedQueryType === "DSL_QUERY" && (
                                        <Flex flex={1}>
                                            {isLoadingBuilderProperties ? (
                                                <Flex flex={1}>
                                                    <Skeleton.Input
                                                        block
                                                        active
                                                    />
                                                </Flex>
                                            ) : builderProperties.length > 0 ? (
                                                showQueryBuilder && (
                                                    <Flex
                                                        flex={1}
                                                        vertical
                                                        gap="small"
                                                    >
                                                        <ElasticSearchQueryBuilder
                                                            properties={
                                                                builderProperties
                                                            }
                                                            onUpdateQuery={
                                                                handleUpdateQuery
                                                            }
                                                            query={rawQuery}
                                                            onSubmit={
                                                                handleQueryBuilderSearch
                                                            }
                                                            additionalActions={
                                                                <Flex flex={1}>
                                                                    <Button
                                                                        type="default"
                                                                        icon={
                                                                            <SwapOutlined />
                                                                        }
                                                                        iconPosition="end"
                                                                        title="Convert to Advanced Search"
                                                                        onClick={
                                                                            handleQueryToText
                                                                        }
                                                                    >
                                                                        To
                                                                        Advanced
                                                                        Search
                                                                    </Button>
                                                                </Flex>
                                                            }
                                                        />
                                                    </Flex>
                                                )
                                            ) : (
                                                <Flex flex={1}>
                                                    <Alert
                                                        type="error"
                                                        showIcon
                                                        message={`No schema found in business area "${selectedBusinessArea?.label}". Try changing the filters above.`}
                                                        banner
                                                        style={{ flex: 1 }}
                                                    />
                                                </Flex>
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            </Card>
                        </ConfigProvider>
                    </Flex>
                </Form>
            }
        />
    );
}
