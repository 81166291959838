import {
    GET_USER_BUSINESS_AREAS_SUCCESS,
    GET_USER_BUSINESS_AREAS_FAILURE,

    GET_USER_ACTIVE_BUSINESS_AREA_SUCCESS,
    GET_USER_ACTIVE_BUSINESS_AREA_FAILURE,

    SAVE_USER_ACTIVE_BUSINESS_AREA_REQUEST,
    SAVE_USER_ACTIVE_BUSINESS_AREA_SUCCESS,
    SAVE_USER_ACTIVE_BUSINESS_AREA_FAILURE,

    SET_ACTIVE_BUSINESS_AREA_PATH_REQUEST
} from "./actions";

const getDefaultBusinessAreaId = (state) => {
    let defaultBusinessArea = state.businessAreas.find(businessArea => businessArea.name === "DEFAULT");
    let defaultBusinessAreaId = null;
    if (defaultBusinessArea) {
        defaultBusinessAreaId = defaultBusinessArea.businessAreaId;
    }
    return defaultBusinessAreaId;
}

const initialState = {
    getUserBusinessAreasResult: {
        success: true
    },
    getUserActiveBusinessAreaResult: {
        success: true
    },
    saveUserActiveBusinessAreaResult: {
        success: true
    },
    businessAreas: [],
    activeBusinessAreaId: null,
    activeBusinessAreaPath: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_BUSINESS_AREAS_SUCCESS:
            return { ...state, getUserBusinessAreasResult: { success: true }, businessAreas: action.payload.businessAreas, businessAreasDict: action.payload.businessAreasDict };
        case GET_USER_BUSINESS_AREAS_FAILURE:
            return { ...state, getUserBusinessAreasResult: { success: false, ...action.payload }, businessAreas: [], businessAreasDict: {} };
        case GET_USER_ACTIVE_BUSINESS_AREA_SUCCESS:
            return { ...state, getUserActiveBusinessAreaResult: { success: true }, activeBusinessAreaId: action.payload || getDefaultBusinessAreaId(state) };
        case GET_USER_ACTIVE_BUSINESS_AREA_FAILURE:
            return { ...state, getUserActiveBusinessAreaResult: { success: false, ...action.payload }, activeBusinessAreaId: getDefaultBusinessAreaId(state) };
        case SAVE_USER_ACTIVE_BUSINESS_AREA_REQUEST:
            return { ...state, activeBusinessAreaId: action.params.businessAreaId };
        case SAVE_USER_ACTIVE_BUSINESS_AREA_SUCCESS:
            return { ...state, saveUserActiveBusinessAreaResult: { success: true } };
        case SAVE_USER_ACTIVE_BUSINESS_AREA_FAILURE:
            return { ...state, saveUserActiveBusinessAreaResult: { success: false, ...action.payload } };
        case SET_ACTIVE_BUSINESS_AREA_PATH_REQUEST:
            return { ...state, activeBusinessAreaPath: action.payload };
        default:
            return state;
    }
};
