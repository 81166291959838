import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

export default function getDslPrefixClauseValue(
    context: IDslQueryContext,
    queryClause: IDslQueryRegexpClause | Record<string, string>,
    fields: ISearchProperty[],
): RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> {
    const rules: RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> = [];

    for (const [field, clause] of Object.entries(queryClause)) {
        if (fields.some((f) => f.name === field)) {
            // Field is valid
            rules.push({
                id: uuidv4(),
                field,
                operator:
                    context === "must" ? "beginsWith" : "doesNotBeginWith",
                valueSource: "value",
                value: clause.value ?? clause,
            });
        }
    }

    return rules;
}
