import React from 'react';
import fieldDataTypes from 'common/data/fieldDataTypes';
import { Form, Input, Checkbox, Select, InputNumber, Tooltip, Empty } from "antd";

export default ({ selectedFieldRow, dataType, readOnly, getFormErrors }) => (<Form.Item
    label="Max"
    name={["fields", selectedFieldRow.name, "max"]}
    initialValue={fieldDataTypes[dataType].max}
    rules={
        [
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (value >= getFieldValue(["fields", selectedFieldRow.name, "min"])) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Max should be greater than or equal to Min.'));
                },
            }),
        ]
    }
    {...getFormErrors(`fields.${selectedFieldRow.name}.max`)}>
    <InputNumber
        type="number"
        readOnly={readOnly}
        min={fieldDataTypes[dataType].min}
        max={fieldDataTypes[dataType].max}></InputNumber>
</Form.Item>)