import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, Checkbox, Select, Tooltip, Tag, Space, Row, Col } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import fieldDataTypes from 'common/data/fieldDataTypes';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/fontawesome-free-solid';

const { Column } = Table;
const { Option } = Select;
const dataTypeMergeRules = {
    "integer": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Largest value",
            value: "LARGEST_VALUE"
        },
        {
            name: "Smallest value",
            value: "SMALLEST_VALUE"
        }
    ],
    "float": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Largest value",
            value: "LARGEST_VALUE"
        },
        {
            name: "Smallest value",
            value: "SMALLEST_VALUE"
        }
    ],
    "string": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Longest string",
            value: "LONGEST_STRING"
        },
        {
            name: "Smallest string",
            value: "SMALLEST_STRING"
        }
    ],
    "boolean": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        }
    ],
    "datetime": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Newest value",
            value: "NEWEST_VALUE"
        },
        {
            name: "Oldest value",
            value: "OLDEST_VALUE"
        }
    ],
    "date": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Newest value",
            value: "NEWEST_VALUE"
        },
        {
            name: "Oldest value",
            value: "OLDEST_VALUE"
        }
    ],
    "time": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Newest value",
            value: "NEWEST_VALUE"
        },
        {
            name: "Oldest value",
            value: "OLDEST_VALUE"
        }
    ],
    "date-year": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Newest value",
            value: "NEWEST_VALUE"
        },
        {
            name: "Oldest value",
            value: "OLDEST_VALUE"
        }
    ],
    "date-month": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Newest value",
            value: "NEWEST_VALUE"
        },
        {
            name: "Oldest value",
            value: "OLDEST_VALUE"
        }
    ],
    "date-day": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Newest value",
            value: "NEWEST_VALUE"
        },
        {
            name: "Oldest value",
            value: "OLDEST_VALUE"
        }
    ],
    "currency": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        },
        {
            name: "Largest value",
            value: "LARGEST_VALUE"
        },
        {
            name: "Smallest value",
            value: "SMALLEST_VALUE"
        }
    ],
    "picklist": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        }
    ],
    "email": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        }
    ],
    "url": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        }
    ],
    "phone": [
        {
            name: "Survivor value win",
            value: "SURVIVOR_VALUE_WIN"
        },
        {
            name: "Most common value",
            value: "MOST_COMMON_VALUE"
        },
        {
            name: "Nominated value",
            value: "NOMINATED_VALUE"
        }
    ]
}

const RuleTypeSelection = ({ value, onChange, rules, field, formRef, onSelectNominatedValueClick }) => {
    const [ruleValue, setRuleValue] = useState(value || rules[0].value);

    useEffect(() => {
        setRuleValue(value);
    }, [value]);

    const onRuleChange = (selectedValue) => {
        onChange(selectedValue);
    }

    const onSelectValueClick = () => {
        onSelectNominatedValueClick(field, formRef.current.getFieldValue(["rules", field.name, "nominatedValue"]), formRef.current.getFieldValue(["rules", field.name, "fieldName"]));
    }

    return <Row>
        <Col>
            <Select
                showSearch
                onChange={onRuleChange}
                value={ruleValue}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {rules.map(rule => (<Option key={rule.value} value={rule.value}>{rule.name}</Option>))}
            </Select>
        </Col>
        {
            value === "NOMINATED_VALUE"
                ?
                <Col>
                    <Tooltip title="Click to select a value"><Button onClick={onSelectValueClick}><FontAwesomeIcon icon={faList} style={{ color: "#3881ff" }} /></Button></Tooltip>
                </Col>
                :
                <></>
        }
    </Row>

}

const DataObjectDuplicateMergeRuleList = ({ formRef, rules, onSelectNominatedValueClick }) => {
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={false}
                    content={<Table size="small"
                        dataSource={rules}
                        pagination={false}
                        className="form-table container-height-100"
                        scroll={{ y: "100vh" }}>
                        <Column
                            width="10%"
                            title={"No."}
                            key="index"
                            render={(value, field, index) => {
                                return (index + 1)
                            }}
                        />
                        <Column
                            dataIndex={"fieldName"}
                            title={"Field Name"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}>
                                        <>{formRef.current && formRef.current.getFieldValue(["rules", field.name, "fieldName"])}</>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            width="20%"
                            dataIndex={"fieldDataType"}
                            title={"Data Type"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}>
                                        <>{formRef.current && formRef.current.getFieldValue(["rules", field.name, "fieldDataType"])}</>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            width="50%"
                            dataIndex={"rule"}
                            title={"Rule"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                let rules = [];
                                let dataType = formRef.current && formRef.current.getFieldValue(["rules", field.name, "fieldDataType"]);
                                if (dataType) {
                                    rules = dataTypeMergeRules[dataType] || [];
                                }
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "rule"]}
                                        initialValue="string">
                                        <RuleTypeSelection
                                            rules={rules}
                                            formRef={formRef}
                                            field={field}
                                            onSelectNominatedValueClick={onSelectNominatedValueClick}>
                                        </RuleTypeSelection>
                                    </Form.Item>
                                );
                            }}
                        />
                    </Table>
                    }>
                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
};

export default DataObjectDuplicateMergeRuleList;