
import { put, call, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import _ from 'lodash';
import errorHandler from 'common/errorHandler';

export function* updateUserPasswordRequest(api, { username, code, newPassword }) {
    try {
        let result = yield call([Auth, Auth.forgotPasswordSubmit], username, code, newPassword);
        yield put(actions.updateUserPasswordSuccess(result))
    } catch (error) {
        let errorObject = errorHandler(error, "Signup", "new user");
        if (error.code === "UsernameExistsException") {
            if (!errorObject.fields) {
                errorObject.fields = {};
            }
            errorObject.fields["email"] = "Email already exist.";
        }
        else if (error.code === "InvalidPasswordException") {
            if (!errorObject.fields) {
                errorObject.fields = {};
            }
            errorObject.fields["password"] = error.message;
        }
        yield put(actions.updateUserPasswordFailure(errorObject));
    }
}


export function* watchUpdateUserPasswordRequest(api, { params }) {
    yield call(updateUserPasswordRequest, api, params)
}

export default function* ({ api }) {
    yield takeLatest(actions.UPDATE_USER_PASSWORD_REQUEST, watchUpdateUserPasswordRequest, api);
}
