import { RuleGroupType, RuleType } from "react-querybuilder";

/**
 * Converts Query Builder into Elastic Search Simple Query
 *
 * + signifies AND operation
 * | signifies OR operation
 * - negates a single token
 * " wraps a number of tokens to signify a phrase for searching
 * * at the end of a term signifies a prefix query
 * ( and ) signify precedence
 * ~N after a word signifies edit distance (fuzziness)
 * ~N after a phrase signifies slop amount
 */
export default function convertQueryBuilderToSimpleText(
    query: RuleGroupType<RuleType, string>,
): string {
    function convertToText(query: RuleGroupType<RuleType, string>): string {
        const queries: string[] = [];

        let combinator = "+";
        let separator = " ";

        if (query.combinator?.toLowerCase() === "and") {
            if (query.not) {
                combinator = "+-";
            }
        } else {
            combinator = "";
            separator = " | ";
            if (query.not) {
                combinator = "-";
            }
        }

        query.rules
            .filter((queryRule) => typeof queryRule !== "string") // Ignore string only
            .forEach((queryRule) => {
                // Need to check property otherwise Typescript will return an error if testing "rules" directly.
                if (Object.hasOwnProperty.bind(queryRule)("rules")) {
                    const ruleToText = convertToText(
                        queryRule as RuleGroupType<RuleType, string>,
                    );
                    if (ruleToText) {
                        queries.push(`${combinator}(${ruleToText})`);
                    }
                    return;
                }

                const rule: RuleType = queryRule as RuleType;
                if (rule.field) {
                    let ruleValue = "";
                    let isNot = false; // If true, wrap queryString with -()
                    switch (rule.operator) {
                        case "null":
                            isNot = true;
                            ruleValue = "*";
                            break;
                        case "notNull":
                            ruleValue = "*";
                            break;
                        case "=":
                            ruleValue =
                                rule.value.indexOf(" ") > -1
                                    ? `\\"${rule.value}\\"`
                                    : rule.value;
                            break;
                        case "!=":
                            isNot = true;
                            ruleValue =
                                rule.value.indexOf(" ") > -1
                                    ? `\\"${rule.value}\\"`
                                    : rule.value;
                            break;
                        case "contains":
                            ruleValue = `*${rule.value}*`;
                            break;
                        case "doesNotContain":
                            isNot = true;
                            ruleValue = `*${rule.value}*`;
                            break;
                        case "beginsWith":
                            ruleValue = `${rule.value}*`;
                            break;
                        case "doesNotBeginWith":
                            isNot = true;
                            ruleValue = `${rule.value}*`;
                            break;
                        case "endsWith":
                            ruleValue = `*${rule.value}`;
                            break;
                        case "doesNotEndWith":
                            isNot = true;
                            ruleValue = `*${rule.value}`;
                            break;
                        default:
                            // Does not support greater or lesser operators
                            break;
                    }

                    if (ruleValue) {
                        const ruleField =
                            rule.field.indexOf(" ") > -1
                                ? `\\"${rule.field}\\"`
                                : rule.field;
                        let fieldValue = `${ruleField}:${ruleValue}`;
                        if (isNot) {
                            fieldValue = `(-${fieldValue})`;
                        }
                        queries.push(`${combinator}${fieldValue}`);
                    }
                }
            });

        return queries.join(separator);
    }

    return convertToText(query);
}
