import React, { useRef, useState } from 'react';
import { Card, Input, Button, Table, Dropdown, Menu, Space, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { FullHeightContainerLayout } from 'components';
import { v4 as uuidv4 } from 'uuid';

const { TextArea } = Input;
const { Column } = Table;

function TextFilePreview({ content, enableColumnSpec = false, onInsertColumnSpec, onAddColumnSpec, selectedColumnSpecIndex }) {
    const textAreaRef = useRef(null);
    const [columnSpecButtonStatus, setColumnSpecButtonStatus] = useState({ addDisabled: true, insertDisabled: true });

    const onContextMenuClick = ({ item, key, keyPath, domEvent }) => {
        if (textAreaRef.current && textAreaRef.current.resizableTextArea && textAreaRef.current.resizableTextArea.textArea) {
            if (key === "INSERT_COLUMN_SPEC") {
                onInsertColumnSpec && onInsertColumnSpec({
                    start: textAreaRef.current.resizableTextArea.textArea.selectionStart + 1,
                    end: textAreaRef.current.resizableTextArea.textArea.selectionEnd + 1,
                    name: content.slice(textAreaRef.current.resizableTextArea.textArea.selectionStart, textAreaRef.current.resizableTextArea.textArea.selectionEnd)
                });
            }
            else if (key === "ADD_COLUMN_SPEC") {
                onAddColumnSpec && onAddColumnSpec({
                    start: textAreaRef.current.resizableTextArea.textArea.selectionStart + 1,
                    end: textAreaRef.current.resizableTextArea.textArea.selectionEnd + 1,
                    name: content.slice(textAreaRef.current.resizableTextArea.textArea.selectionStart, textAreaRef.current.resizableTextArea.textArea.selectionEnd)
                });
            }
        }
    }

    const onTextAreaMouseUp = () => {
        if (textAreaRef.current && textAreaRef.current.resizableTextArea && textAreaRef.current.resizableTextArea.textArea) {
            setColumnSpecButtonStatus({ addDisabled: false, insertDisabled: selectedColumnSpecIndex === -1 });
        }
    }

    const onActionButtonClick = (key) => {
        onContextMenuClick({ key });
        setColumnSpecButtonStatus({ addDisabled: true, insertDisabled: true });
        if (textAreaRef.current && textAreaRef.current.resizableTextArea && textAreaRef.current.resizableTextArea.textArea) {
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            }
            else if (document.selection) {
                document.selection.empty();
            }
            textAreaRef.current.resizableTextArea.textArea.selectionStart = textAreaRef.current.resizableTextArea.textArea.selectionEnd;
        }
    }

    const contextMenu = (
        enableColumnSpec ?
            <Menu onClick={onContextMenuClick}
                items={[
                    {
                        label: 'Insert column spec',
                        key: 'INSERT_COLUMN_SPEC',
                        disabled: selectedColumnSpecIndex === -1
                    },
                    {
                        label: 'Add column spec',
                        key: 'ADD_COLUMN_SPEC',
                    }
                ]}
            />
            :
            <></>
    );

    return <FullHeightContainerLayout
        showHeader={enableColumnSpec}
        showFooter={false}
        header={
            enableColumnSpec
                ?
                <Row style={{ width: '100%' }}>
                    <Col style={{ display: "flex" }}>
                        <Button
                            type="primary"
                            size="small"
                            ghost
                            disabled={columnSpecButtonStatus.addDisabled}
                            onClick={() => onActionButtonClick("ADD_COLUMN_SPEC")}>
                            Add column spec
                        </Button>
                    </Col>
                    <Col style={{ display: "flex", paddingLeft: "1rem" }}>
                        <Button
                            type="primary"
                            size="small"
                            ghost
                            disabled={columnSpecButtonStatus.insertDisabled}
                            onClick={() => onActionButtonClick("INSERT_COLUMN_SPEC")}>
                            Insert column spec
                        </Button>
                    </Col>
                </Row>
                :
                null
        }
        content={<Card
            size="small"
            className='text-file-preview'>
            <Dropdown overlay={contextMenu} trigger={['contextMenu']}>
                <TextArea style={{fontFamily:"'Courier Prime', monospace"}} ref={textAreaRef} value={content} onMouseUp={onTextAreaMouseUp} />
            </Dropdown>
        </Card>}>
    </FullHeightContainerLayout>

}

export default TextFilePreview