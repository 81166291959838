import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getBusinessAreaHistory(api, tenantId, businessAreaId) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/businessareas/${businessAreaId}/history`, { tenantid: tenantId });
    return result.data;
}

function* getBusinessAreaEvents(api, tenantId, businessAreaId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/businessareas/${businessAreaId}/events`, { tenantid: tenantId });
    return result.data;
}

export function* getBusinessAreaHistoryRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        let businessAreaHistoryResult = yield call(getBusinessAreaHistory, api, tenant.tenantId, businessAreaId);
        yield put(actions.getBusinessAreaHistorySuccess(businessAreaHistoryResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area history");
        yield put(actions.getBusinessAreaHistoryFailure(errorObject));
    }
}

export function* getBusinessAreaEventsRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        let businessAreaEventsResult = yield call(getBusinessAreaEvents, api, tenant.tenantId, businessAreaId);
        yield put(actions.getBusinessAreaEventsSuccess(businessAreaEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area events");
        yield put(actions.getBusinessAreaEventsFailure(errorObject));
    }
}

export function* watchGetBusinessAreaHistoryRequest(api, { params }) {
    yield call(getBusinessAreaHistoryRequest, api, params);
}

export function* watchGetBusinessAreaEventsRequest(api, { params }) {
    yield call(getBusinessAreaEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_BUSINESS_AREA_HISTORY_REQUEST, watchGetBusinessAreaHistoryRequest, api);
    yield takeLatest(actions.GET_BUSINESS_AREA_EVENTS_REQUEST, watchGetBusinessAreaEventsRequest, api);
}