import React from 'react';
import { useParams } from "react-router-dom";
import { LicenseManagement } from 'containers';
const LicenseInfoPage = (props) => {
    const { tenant } = useParams();
    return (
        <div className="flex-column-container">
            <div>
                <LicenseManagement></LicenseManagement>
            </div>
        </div>
    )
}

export default LicenseInfoPage
