import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, InputNumber, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { FullHeightContainerLayout, CopyInput, ManageKinesisStreamCDCDestination } from "components";
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import { nameRules, descriptionRules } from 'common/FormValidationRules';

const { Option } = Select;

class ManageDataCaptureDestinations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            default: {
                name: '',
                description: '',
                type: ''
            },
            readOnlyView: this.props.action === "view",
            selectedDestinationType: "",
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    componentDidMount() {
        if (this.props.cdcDestination) {
            this.formRef.current.setFieldsValue({ ...this.props.cdcDestination });
            this.setState({
                selectedDestinationType: this.props.cdcDestination.destinationType
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cdcDestination && prevProps.cdcDestination !== this.props.cdcDestination) {
            this.formRef.current.setFieldsValue({ ...this.props.cdcDestination });
            this.setState({
                selectedDestinationType: this.props.cdcDestination.destinationType
            })
        }
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        let destination = { ...formData };
        if (this.props.action === "create") {
            destination.active = true;
            this.props.onSave(destination);
        }
        else {
            let diffObject = diff(this.props.cdcDestination, destination);
            let updatedObject = {};
            for (let key in diffObject) {
                if (diffObject[key] !== undefined) {
                    updatedObject[key] = destination[key];
                }
            }
            if (_.isEmpty(updatedObject) === false) {
                updatedObject.version = this.props.cdcDestination.version;
                updatedObject.destinationType = this.props.cdcDestination.destinationType;
            }

            this.props.onSave(this.props.cdcDestination.destinationId, updatedObject);
        }
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    render() {
        return (
            <Form.Provider
                onFormChange={(formName, { changedFields, forms }) => {
                    if (formName === 'manageCDCDestinationForm') {
                        const { manageCDCDestinationForm } = forms;
                        this.setState({
                            selectedDestinationType: manageCDCDestinationForm.getFieldValue('destinationType')
                        })
                    }
                }}
                onFormFinish={async (name, { values, forms }) => {
                    if (name === 'manageCDCDestinationForm') {
                        const { manageCDCDestinationForm, manageKinesisStreamCDCDestinationForm } = forms;
                        try {
                            await manageKinesisStreamCDCDestinationForm.validateFields();
                        }
                        catch (error) {
                            return;
                        }
                        this.onFormSubmit({
                            ...manageCDCDestinationForm.getFieldsValue(),
                            ...manageKinesisStreamCDCDestinationForm.getFieldsValue()
                        });
                    }
                }}>
                <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }} wrap={false}>
                    <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={true}
                            header={
                                <Form
                                    ref={this.formRef}
                                    name="manageCDCDestinationForm"
                                    initialValues={this.default}
                                    style={{ height: "100%" }}>
                                    <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }} wrap={false}>
                                        <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                                            <FullHeightContainerLayout
                                                showHeader={true}
                                                showFooter={false}
                                                header={<Row>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                                <Form.Item
                                                                    {...this.getFormErrors("name")}
                                                                    label="Name"
                                                                    name={["name"]}
                                                                    validateFirst={true}
                                                                    rules={nameRules}>
                                                                    <Input readOnly={this.isReadOnlyView()} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                                                <Form.Item
                                                                    label="Description"
                                                                    name={["description"]}
                                                                    rules={descriptionRules}>
                                                                    <Input readOnly={this.isReadOnlyView()} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                                                <Form.Item
                                                                    label="Objects"
                                                                    name={["objects"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select objects',
                                                                            type: 'array',
                                                                        },
                                                                    ]}>
                                                                    <Select mode="multiple" placeholder="Please select objects" disabled={this.isReadOnlyView()}>
                                                                        <Option value="SCHEMA">Schema</Option>
                                                                        <Option value="SCHEMA_FIELD">Schema Field</Option>
                                                                        <Option value="DATASET">Data Set</Option>
                                                                        <Option value="DATASET_RECORD">Data Set Record</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                                                <Form.Item
                                                                    label="Events"
                                                                    name={["events"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select events',
                                                                            type: 'array',
                                                                        },
                                                                    ]}>
                                                                    <Select mode="multiple" placeholder="Please select events" disabled={this.isReadOnlyView()}>
                                                                        <Option value="CREATE">Create</Option>
                                                                        <Option value="UPDATE">Update</Option>
                                                                        <Option value="DELETE">Delete</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                                                <Form.Item
                                                                    label="Destination"
                                                                    name={["destinationType"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select destination',
                                                                        },
                                                                    ]}>
                                                                    <Select placeholder="Destination"
                                                                        {...(() => (this.isReadOnlyView() ? { open: false } : {}))()}
                                                                        disabled={this.props.action === "edit"}>
                                                                        <Option value="AWS_KINESIS_STREAM">Kinesis Stream</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>}>
                                            </FullHeightContainerLayout>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                            content={
                                <>
                                    {
                                        this.state.selectedDestinationType === "AWS_KINESIS_STREAM"
                                            ?
                                            <ManageKinesisStreamCDCDestination
                                                readOnly={this.isReadOnlyView()}
                                                destinationItem={this.props.cdcDestination}
                                                getFormErrors={this.getFormErrors}
                                            ></ManageKinesisStreamCDCDestination>
                                            :
                                            <></>
                                    }
                                </>
                            }
                            footer={
                                <Row>
                                    <Col span={24} className="footer-right-column">
                                        <Space>
                                            <Button onClick={this.props.onCancel}>Cancel</Button>
                                            <Button type="primary" onClick={() => this.formRef.current.submit()} disabled={this.isReadOnlyView() || !this.isSaveAllowed()}>Save</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            }>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
            </Form.Provider>
        );
    }
}

export default ManageDataCaptureDestinations