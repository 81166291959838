export const CREATE_DATA_OBJECT_VERIFICATION_REQUEST = "CREATE_DATA_OBJECT_VERIFICATION_REQUEST";
export const CREATE_DATA_OBJECT_VERIFICATION_SUCCESS = "CREATE_DATA_OBJECT_VERIFICATION_SUCCESS";
export const CREATE_DATA_OBJECT_VERIFICATION_FAILURE = "CREATE_DATA_OBJECT_VERIFICATION_FAILURE";

export const GET_DATA_OBJECT_GUEST_VERIFICATION_REQUEST = "GET_DATA_OBJECT_GUEST_VERIFICATION_REQUEST";
export const GET_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS = "GET_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS";
export const GET_DATA_OBJECT_GUEST_VERIFICATION_FAILURE = "GET_DATA_OBJECT_GUEST_VERIFICATION_FAILURE";

export const UPDATE_DATA_OBJECT_GUEST_VERIFICATION_REQUEST = "UPDATE_DATA_OBJECT_GUEST_VERIFICATION_REQUEST";
export const UPDATE_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS = "UPDATE_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS";
export const UPDATE_DATA_OBJECT_GUEST_VERIFICATION_FAILURE = "UPDATE_DATA_OBJECT_GUEST_VERIFICATION_FAILURE";

export const GET_DATA_OBJECT_VERIFICATIONS_REQUEST = "GET_DATA_OBJECT_VERIFICATIONS_REQUEST";
export const GET_DATA_OBJECT_VERIFICATIONS_SUCCESS = "GET_DATA_OBJECT_VERIFICATIONS_SUCCESS";
export const GET_DATA_OBJECT_VERIFICATIONS_FAILURE = "GET_DATA_OBJECT_VERIFICATIONS_FAILURE";

export const GET_DATA_OBJECT_CONSENTS_REQUEST = "GET_DATA_OBJECT_CONSENTS_REQUEST";
export const GET_DATA_OBJECT_CONSENTS_SUCCESS = "GET_DATA_OBJECT_CONSENTS_SUCCESS";
export const GET_DATA_OBJECT_CONSENTS_FAILURE = "GET_DATA_OBJECT_CONSENTS_FAILURE";

export const createDataObjectVerificationRequest = ({ businessAreaId, schemaId, dataSetId, dataObjectId, consent, fields, bulkVerification }) => ({
    type: CREATE_DATA_OBJECT_VERIFICATION_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId,
        consent,
        fields,
        bulkVerification
    }
})

export const createDataObjectVerificationSuccess = (result) => ({
    type: CREATE_DATA_OBJECT_VERIFICATION_SUCCESS,
    payload: {
        result
    }
})

export const createDataObjectVerificationFailure = (error) => ({
    type: CREATE_DATA_OBJECT_VERIFICATION_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectGuestVerificationRequest = (
    {
        tenantId,
        businessAreaId,
        schemaId,
        dataObjectId,
        verificationId,
        sender,
        version,
        toEmail,
        expires,
        date,
        signature
    }) => ({
        type: GET_DATA_OBJECT_GUEST_VERIFICATION_REQUEST,
        params: {
            tenantId,
            businessAreaId,
            schemaId,
            dataObjectId,
            verificationId,
            sender,
            version,
            toEmail,
            expires,
            date,
            signature
        }
    })

export const getDataObjectGuestVerificationSuccess = (result) => ({
    type: GET_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS,
    payload: {
        result
    }
})

export const getDataObjectGuestVerificationFailure = (error) => ({
    type: GET_DATA_OBJECT_GUEST_VERIFICATION_FAILURE,
    payload: {
        error
    }
})

export const updateDataObjectGuestVerificationRequest = (
    {
        tenantId,
        businessAreaId,
        schemaId,
        dataObjectId,
        verificationId,
        sender,
        version,
        toEmail,
        expires,
        date,
        signature,
        dataObject,
        consent,
        verificationVersion
    }) => ({
        type: UPDATE_DATA_OBJECT_GUEST_VERIFICATION_REQUEST,
        params: {
            tenantId,
            businessAreaId,
            schemaId,
            dataObjectId,
            verificationId,
            sender,
            version,
            toEmail,
            expires,
            date,
            signature,
            dataObject,
            consent,
            verificationVersion
        }
    })

export const updateDataObjectGuestVerificationSuccess = (result) => ({
    type: UPDATE_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS,
    payload: {
        result
    }
})

export const updateDataObjectGuestVerificationFailure = (error) => ({
    type: UPDATE_DATA_OBJECT_GUEST_VERIFICATION_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectVerificationsRequest = (businessAreaId, schemaId, dataObjectId) => ({
    type: GET_DATA_OBJECT_VERIFICATIONS_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataObjectId
    }
})

export const getDataObjectVerificationsSuccess = (result) => ({
    type: GET_DATA_OBJECT_VERIFICATIONS_SUCCESS,
    payload: {
        result
    }
})

export const getDataObjectVerificationsFailure = (error) => ({
    type: GET_DATA_OBJECT_VERIFICATIONS_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectConsentsRequest = (dataObjectId) => ({
    type: GET_DATA_OBJECT_CONSENTS_REQUEST,
    params: {
        dataObjectId
    }
})

export const getDataObjectConsentsSuccess = (result) => ({
    type: GET_DATA_OBJECT_CONSENTS_SUCCESS,
    payload: {
        result
    }
})

export const getDataObjectConsentsFailure = (error) => ({
    type: GET_DATA_OBJECT_CONSENTS_FAILURE,
    payload: {
        error
    }
})