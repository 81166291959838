import {
    GET_DATA_OBJECT_HISTORY_SUCCESS,
    GET_DATA_OBJECT_HISTORY_FAILURE,

    GET_DATA_OBJECT_EVENTS_SUCCESS,
    GET_DATA_OBJECT_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getDataObjectHistoryResult: {
        success: true
    },
    dataObjectHistoryResult: {
        Items: []
    },
    getDataObjectEventsResult: {
        success: true
    },
    dataObjectEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_OBJECT_HISTORY_SUCCESS:
            return { ...state, getDataObjectHistoryResult: { success: true }, dataObjectHistoryResult: action.payload.dataObjectHistoryResult };
        case GET_DATA_OBJECT_HISTORY_FAILURE:
            return { ...state, getDataObjectHistoryResult: { success: false, ...action.payload.error }, dataObjectHistoryResult: { Items: [] } };
        case GET_DATA_OBJECT_EVENTS_SUCCESS:
            return { ...state, getDataObjectEventsResult: { success: true }, dataObjectEventsResult: action.payload.dataObjectEventsResult };
        case GET_DATA_OBJECT_EVENTS_FAILURE:
            return {
                ...state, getDataObjectEventsResult: { success: false, ...action.payload.error }, dataObjectEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
