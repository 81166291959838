import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

export default function getDslWildcardClauseValue(
    context: IDslQueryContext,
    queryClause: IDslQueryRegexpClause | Record<string, string>,
    fields: ISearchProperty[],
): RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> {
    const rules: RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> = [];

    for (const [field, clause] of Object.entries(queryClause)) {
        if (fields.some((f) => f.name === field)) {
            const clauseValue: string = clause.value ?? clause;

            const isBeginsWith = clauseValue.indexOf("^") === 0;
            const isEndsWith =
                clauseValue.lastIndexOf("?") === clauseValue.length - 1;

            // Assumed to be wildcard
            let operator = context === "must" ? "contains" : "doesNotContain";

            if (isBeginsWith) {
                operator =
                    context === "must" ? "beginsWith" : "doesNotBeginWith";
            } else if (isEndsWith) {
                operator = context === "must" ? "endsWith" : "doesNotEndWith";
            }

            const trimmedValue = clauseValue.replace(/[\^*?]/g, "");

            // Field is valid
            rules.push({
                id: uuidv4(),
                field,
                operator: operator,
                valueSource: "value",
                value: trimmedValue,
            });
        }
    }

    return rules;
}
