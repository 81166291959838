import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Row, Col } from 'antd';
import { AdminTenantForm, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea, TenantActionConfirmationModal, SuccessResult } from "components";
import { AdminTenantUsers } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';
import { WarningFilled, LockTwoTone } from "@ant-design/icons";

const { Title } = Typography;

class AdminTenantOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showTenantDisableConfirmation: false,
            showTenantDeleteConfirmation: false,
            showTenantDeletedSuccessResult: false,
            fetchingTenant: false,
            permissionDenied: false,
            busy: false,
            busyMessage: "Fetching tenant..."
        }
        props.instance.current = this;
    }

    componentWillMount() {
        this.setState({
            fetchingTenant: true
        })
        this.props.getTenantById(this.props.tenantId);
    }

    componentDidMount() {
        this.showBreadCrumbNavigationItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getTenantByIdResultChanged(prevProps);
        this.updateTenantResultChanged(prevProps);
        this.deleteTenantResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    getTenantByIdResultChanged = (prevProps) => {
        if (this.props.getTenantByIdResult && this.props.getTenantByIdResult !== prevProps.getTenantByIdResult) {
            if (!this.props.getTenantByIdResult.success) {
                if (this.props.getTenantByIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get tenant at this moment.");
                }
            }
            else {
                this.props.onFetchTenant(this.props.tenant);
            }
            this.setState({
                fetchingTenant: false,
            });
        }
    }

    updateTenantResultChanged = (prevProps) => {
        if (this.props.updateTenantResult && this.props.updateTenantResult !== prevProps.updateTenantResult) {
            if (!this.props.updateTenantResult.success) {
                if (this.props.updateTenantResult.code === "PERMISSION_DENIED") {
                    showError("Permission Denied");
                    if (this.state.disablingTenant) {
                        this.props.onFetchTenant(this.props.tenant);
                    }
                    else if (this.state.enablingTenant) {
                        this.props.onFetchTenant(this.props.tenant);
                    }
                }
                else {
                    if (this.state.disablingTenant) {
                        showError("Could not able to disable tenant at this moment.");
                        this.props.onFetchTenant(this.props.tenant);
                    }
                    else if (this.state.enablingTenant) {
                        showError("Could not able to enable tenant at this moment.");
                        this.props.onFetchTenant(this.props.tenant);
                    }
                    else {
                        showError("Could not able to update tenant at this moment.");
                    }
                }
            }
            else {
                if (this.state.disablingTenant) {
                    showSuccess("Tenant disabled successfully");
                    this.props.onFetchTenant({ ...this.props.tenant, enabled: false });
                }
                else if (this.state.enablingTenant) {
                    showSuccess("Tenant enabled successfully");
                    this.props.onFetchTenant({ ...this.props.tenant, enabled: true });
                }
                else {
                    showSuccess("Tenant updated successfully");
                }
            }
            this.setState({
                busy: false,
                disablingTenant: false,
                busyMessage: ""
            });
        }
    }

    deleteTenantResultChanged = (prevProps) => {
        if (this.props.deleteTenantResult && this.props.deleteTenantResult !== prevProps.deleteTenantResult) {
            if (!this.props.deleteTenantResult.success) {
                if (this.props.deleteTenantResult.code === "PERMISSION_DENIED") {
                    showError("Permission Denied");
                }
                else {
                    showError("Could not able to delete tenant at this moment.");
                }
            }
            else {
                this.setState({
                    showTenantDeletedSuccessResult: true
                })
            }
            this.setState({
                busy: false,
                busyMessage: ""
            })
        }
    }

    disableTenant = () => {
        this.setState({
            showTenantDisableConfirmation: true
        });
    }

    onDisableTenantCancel = () => {
        this.setState({
            showTenantDisableConfirmation: false,
        });
    }

    onDisableTenantConfirmed = () => {
        this.setState({
            showTenantDisableConfirmation: false,
            busy: true,
            disablingTenant: true,
            busyMessage: "Disabling tenant..."
        });
        this.props.onFetchTenant(null);
        this.props.updateTenant(this.props.tenantId, { enabled: false });
    }

    enableTenant = () => {
        this.setState({
            busy: true,
            enablingTenant: true,
            busyMessage: "Enabling tenant..."
        });
        this.props.onFetchTenant(null);
        this.props.updateTenant(this.props.tenantId, { enabled: true });
    }

    updateTenant = (tenantId, updatedValues) => {
        this.setState({
            busy: true,
            busyMessage: "Updating tenant..."
        });
        this.props.updateTenant(tenantId, updatedValues);
    }

    deleteTenant = () => {
        this.setState({
            showTenantDeleteConfirmation: true,
        });
    }

    onDeleteTenantCancel = () => {
        this.setState({
            showTenantDeleteConfirmation: false,
        });
    }

    onDeleteTenantConfirmed = () => {
        this.setState({
            showTenantDeleteConfirmation: false,
            busy: true,
            busyMessage: "Deleting tenant..."
        });
        this.props.deleteTenant(this.props.tenantId);
    }

    goToTenantAdminConsole = () => {
        this.props.history.push(AppPaths.ADMIN_CONSOLE);
    }

    fetchInProgress = () => {
        let isBusy = this.state.fetchingTenant;
        return isBusy;
    }

    isBusy = () => {
        return this.state.busy || this.fetchInProgress();
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.ADMIN_CONSOLE,
                    text: "Admin Console"
                },
                {
                    route: "",
                    text: "Tenant Overview"
                },
                {
                    route: "",
                    text: this.props.tenantId
                }
            ];

            this.props.setBreadcrumbNavigationItems(breadCrumbItems);
        }
    }

    render() {
        return <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <Row>
                    <Col span={24}>
                        <Row style={{ height: this.fetchInProgress() ? "15vh" : "unset" }}>
                            <Col span={24}>
                                <LoadingOverlay
                                    busy={this.isBusy()}
                                    spinner
                                    message={this.state.busyMessage || "Please wait..."}>
                                </LoadingOverlay>
                                <>
                                    {
                                        !this.fetchInProgress()
                                            ?
                                            <>
                                                {
                                                    this.state.permissionDenied
                                                        ?
                                                        <ProhibitedArea></ProhibitedArea>
                                                        :
                                                        <>
                                                            <TenantActionConfirmationModal
                                                                title="Disable the Tenant"
                                                                icon={<LockTwoTone style={{ fontSize: '20px' }}></LockTwoTone>}
                                                                visible={this.state.showTenantDisableConfirmation}
                                                                description={[
                                                                    "Do you wish to disable the Tenant?",
                                                                    "You will need to enter the name of the tenant exactly as it is stored in order to proceed",
                                                                    "The tenant should be disabled before the tenant is deleted.",
                                                                ]}
                                                                tenant={this.props.tenant.company}
                                                                onOk={this.onDisableTenantConfirmed}
                                                                onCancel={this.onDisableTenantCancel}>
                                                            </TenantActionConfirmationModal>
                                                            <TenantActionConfirmationModal
                                                                title="Delete Tenant Account"
                                                                icon={<WarningFilled style={{ color: "orange", fontSize: '20px' }}></WarningFilled>}
                                                                visible={this.state.showTenantDeleteConfirmation}
                                                                description={[
                                                                    "Deleting a tenant account is permanent, all data  will be deleted and all users will be offboarded",
                                                                    "Do you need to back this tenant account up?",
                                                                    "The tenant account is currently disabled, you may proceed with deletion.",
                                                                    "You will need to enter the Tenant name exactly as it is stored to proceed with the tenancy deletion"
                                                                ]}
                                                                tenant={this.props.tenant.company}
                                                                onOk={this.onDeleteTenantConfirmed}
                                                                onCancel={this.onDeleteTenantCancel}>
                                                            </TenantActionConfirmationModal>
                                                            <SuccessResult
                                                                show={this.state.showTenantDeletedSuccessResult}
                                                                title="Delete Tenant Account"
                                                                subTitle="Tenant account deletion is initiated successfully."
                                                                onOkClick={this.goToTenantAdminConsole}>
                                                            </SuccessResult>
                                                            <AdminTenantForm
                                                                tenant={this.props.tenant}
                                                                onUpdateTenant={this.updateTenant}>
                                                            </AdminTenantForm>
                                                        </>
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </>
                            </Col>
                        </Row>
                        <Row style={{ minHeight: "25vh", maxHeight: "33vh" }}>
                            <Col span={24}>
                                <AdminTenantUsers tenantId={this.props.tenantId}></AdminTenantUsers>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}></Col>
                        </Row>
                    </Col>
                </Row>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getTenantByIdResult: state.adminConsole.getTenantByIdResult,
        tenant: state.adminConsole.tenant,
        updateTenantResult: state.adminConsole.updateTenantResult,
        deleteTenantResult: state.adminConsole.deleteTenantResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantById: (tenantId) => dispatch(actions.getTenantByIdRequest(tenantId)),
        updateTenant: (tenantId, updatedValues) => dispatch(actions.updateTenantRequest(tenantId, updatedValues)),
        deleteTenant: (tenantId) => dispatch(actions.deleteTenantRequest(tenantId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminTenantOverview));