import { Typography } from "antd";
import CypressTestIds from "cypress/CypressTestIds";
import React from "react";
import { toast } from "react-toastify";

export const showError = (errorMessage, onClose = () => {}) => {
    toast.error(
        <Typography.Text
            data-testid={CypressTestIds.TOAST_ALERT_MESSAGE_ERROR}
            style={{ color: "#fff" }}
        >
            {errorMessage}
        </Typography.Text>,
        {
            className: CypressTestIds.TOAST_ALERT_MESSAGE_ERROR,
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose,
        },
    );
};

export const showWarning = (message, onClose = () => {}) => {
    toast.warning(
        <Typography.Text
            data-testid={CypressTestIds.TOAST_ALERT_MESSAGE_WARNING}
            style={{ color: "#fff" }}
        >
            {message}
        </Typography.Text>,
        {
            className: CypressTestIds.TOAST_ALERT_MESSAGE_WARNING,
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose,
        },
    );
};

export const showSuccess = (message, onClose = () => {}) => {
    toast.success(
        <Typography.Text
            data-testid={CypressTestIds.TOAST_ALERT_MESSAGE_SUCCESS}
            style={{ color: "#fff" }}
        >
            {message}
        </Typography.Text>,
        {
            className: CypressTestIds.TOAST_ALERT_MESSAGE_SUCCESS,
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose,
        },
    );
};

export const showInfo = (message, onClose = () => {}) => {
    toast.info(
        <Typography.Text
            data-testid={CypressTestIds.TOAST_ALERT_MESSAGE_INFO}
            style={{ color: "#fff" }}
        >
            {message}
        </Typography.Text>,
        {
            className: CypressTestIds.TOAST_ALERT_MESSAGE_INFO,
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose
        },
    );
};
