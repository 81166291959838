import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { EventList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class Events extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting events...",
                fetchingEvents: true
            });
            this.props.getEvents();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventsResultUpdate(prevProps);
    }

    onGetEventsResultUpdate = (prevProps) => {
        if (this.props.getEventsResult && this.props.getEventsResult !== prevProps.getEventsResult) {
            if (!this.props.getEventsResult.success) {
                if (this.props.getEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get events at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingEvents: false
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase()).replace(":monitorItem", this.props.match.params.monitorItem.toLowerCase()),
                    text: "Events"
                }
            ];

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingEvents);
        return isBusy;
    }

    render() {
        return <FullHeightContainerLayout
            content={
                <>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {
                        !this.state.fetchingEvents
                            ?
                            <EventList
                                eventList={this.props.eventsResult.Items}>
                            </EventList>
                            :
                            <></>
                    }
                </>
            }>
        </FullHeightContainerLayout>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventsResult: state.events.getEventsResult,
        eventsResult: state.events.eventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEvents: () => dispatch(actions.getEventsRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Events));