
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { Storage } from "aws-amplify";
import * as actions from './actions';
import { Auth } from "aws-amplify";
import { Endpoints } from 'services/api';
import moment from 'moment';
import { keyGenerator } from 'common/Utility';
import { getArrayBufferFromFileObject, sanitizeFileName } from 'common/fileHandler';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
import * as homeSaga from 'store/home/sagas';
import mime from 'mime-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import parsePath from 'parse-filepath';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

export function* createDataObjectsUploadJob(api, tenantId, job) {
    let result = yield call([api, api.post], `${Endpoints.jobs}/api/v1/jobs`, job, { tenantid: tenantId });
    return result.data;
}

function* getDataObjectsByDataSetId(api, tenantId, businessAreaId, schemaId, dataSetId, pageKey) {
    let params = {
    };
    if (pageKey) {
        params.pageKey = pageKey
    }
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects`, { tenantid: tenantId }, {
        params
    });
    return result.data;
}

function* getDataObject(api, tenantId, businessAreaId, schemaId, dataSetId, dataObjectId) {
    let response = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}`, { tenantid: tenantId });
    return response.data;
}

function* createDataObject(api, tenantId, businessAreaId, schemaId, dataSetId, dataObject) {
    let response = yield call([api, api.post], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects`, dataObject, { tenantid: tenantId });
    return response.data;
}

function* updateDataObject(api, tenantId, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) {
    yield call([api, api.put], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}`, dataObject, { tenantid: tenantId });
}

function* deleteDataObject(api, businessAreaId, schemaId, dataSetId, dataObjectId) {
    yield call([api, api.delete], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}`, {});
}

export function* getDataObjectPermission(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/dataobjects`, { tenantid: tenantId });
    return result.data;
}

function* getDataObjectByUUID(api, dataObjectId) {
    try {
        let response = yield call([api, api.get], `${Endpoints.datasets}/api/v1/dataobjects/${dataObjectId}`);
        return response.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getDataObjectSharedLink(api, businessAreaId, schemaId, dataSetId, dataObjectId, readOnly, expires) {
    let response = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}/shareurl?readonly=${readOnly}&expires=${expires}`);
    return response.data;
}

function* getSharedDataObjectByUUID(api, tenantId, userId, readOnly, expiresAt, signatureDate, signature, dataObjectId) {
    try {
        let response = yield call([api, api.getAnonymous], `${Endpoints.datasets}/api/v1/shared/dataobjects/${dataObjectId}`, {}, {
            params: {
                tenant: tenantId,
                user: userId,
                readonly: readOnly,
                expires: expiresAt,
                date: signatureDate,
                signature: signature
            }
        });
        return response.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateSharedDataObject(api, tenantId, userId, readOnly, expiresAt, signatureDate, signature, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) {
    yield call([api, api.putAnonymous], `${Endpoints.datasets}/api/v1/shared/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}`, dataObject, {},
        {
            params: {
                tenant: tenantId,
                user: userId,
                readonly: readOnly,
                expires: expiresAt,
                date: signatureDate,
                signature: signature
            }
        });
}

export function* createDataObjectsUploadJobRequest(api, { businessAreaId, schemaId, dataSet, dataUploadOptions, file, fieldMappings }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        if (file) {
            let jobInfo = {
                jobIdentifier: dataSet.dataSetId,
                runUniqueJob: true,
                businessAreaId,
                schemaId,
                dataSetId: dataSet.dataSetId,
                tenantId: tenant.tenantId,
                userId: user.userId,
                fileName: file.name,
                uploadOptions: dataUploadOptions,
                fieldMappings
            };
            if (!file.connectionId) {
                let headers = {
                    level: "public",
                    contentType: file.type
                };
                let fileName = `tenants/${tenant.tenantId}/users/${user.userId}/dataSets/${dataSet.dataSetId}/dataobjects/${uuidv4()}${parsePath(file.name).ext}`;
                let arrayBuffer = yield call(getArrayBufferFromFileObject, file);
                let result = yield call([Storage, Storage.put], fileName, arrayBuffer, headers);
                jobInfo = {
                    ...jobInfo,
                    s3: process.env.REACT_APP_BUCKET_NAME,
                    key: `public/${result.key}`,
                    region: process.env.REACT_APP_BUCKET_REGION,
                }
            }
            else {
                jobInfo = {
                    ...jobInfo,
                    connectionId: file.connectionId,
                    connectionType: file.connectionType
                }
                if (file.connectionType === "aws_s3") {
                    jobInfo = {
                        ...jobInfo,
                        s3: file.bucket,
                        key: file.path
                    }
                }
            }
            let dataObjectsUploadJob = {
                name: `Data upload ${dataSet.dataSetName}`,
                description: `Data upload ${dataSet.dataSetName}`,
                businessAreaId: businessAreaId,
                recordType: "DATA_OBJECTS",
                schemaId,
                dataSetId: dataSet.dataSetId,
                jobInfo: jobInfo,
                jobType: "DATA_OBJECTS_BULK_UPLOAD"
            }
            yield call(createDataObjectsUploadJob, api, tenant.tenantId, dataObjectsUploadJob);
        }

        yield put(actions.createDataObjectsUploadJobSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "data objects upload job");
        yield put(actions.createDataObjectsUploadJobFailure(errorObject));
    }
}

export function* getDataObjectsByDataSetIdRequest(api, { businessAreaId, schemaId, dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        let dataObjectsResult = yield call(getDataObjectsByDataSetId, api, tenant.tenantId, businessAreaId, schemaId, dataSetId);
        yield put(actions.getDataObjectsByDataSetIdSuccess(dataObjectsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Data Objects");
        yield put(actions.getDataObjectsByDataSetIdFailure(errorObject));
    }
}

export function* getDataObjectsByDataSetIdNextPageRequest(api, { businessAreaId, schemaId, dataSetId, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        let dataObjectsResult = yield call(getDataObjectsByDataSetId, api, tenant.tenantId, businessAreaId, schemaId, dataSetId, pageKey);
        yield put(actions.getDataObjectsByDataSetIdNextPageSuccess(dataObjectsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Data Objects");
        yield put(actions.getDataObjectsByDataSetIdNextPageFailure(errorObject));
    }
}

export function* getDataObjectRequest(api, { businessAreaId, schemaId, dataSetId, dataObjectId }) {
    try {
        let tenant = yield select(getTenant);
        let dataObject = yield call(getDataObject, api, tenant.tenantId, businessAreaId, schemaId, dataSetId, dataObjectId);
        yield put(actions.getDataObjectSuccess(dataObject));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Data Object");
        yield put(actions.getDataObjectFailure(errorObject));
    }
}

export function* createDataObjectRequest(api, { businessAreaId, schemaId, dataSetId, dataObject }) {
    try {
        let tenant = yield select(getTenant);
        let newDataObject = yield call(createDataObject, api, tenant.tenantId, businessAreaId, schemaId, dataSetId, dataObject);
        yield put(actions.createDataObjectSuccess(newDataObject));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "Data Object");
        yield put(actions.createDataObjectFailure(errorObject));
    }
}

export function* updateDataObjectRequest(api, { businessAreaId, schemaId, dataSetId, dataObjectId, dataObject }) {
    try {
        let tenant = yield select(getTenant);
        yield call(updateDataObject, api, tenant.tenantId, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject);
        dataObject.version = dataObject.version + 1;
        yield put(actions.updateDataObjectSuccess(dataObjectId, dataObject));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "Data Object");
        yield put(actions.updateDataObjectFailure(errorObject));
    }
}

export function* deleteDataObjectRequest(api, { businessAreaId, schemaId, dataSetId, dataObjectId }) {
    try {
        yield call(deleteDataObject, api, businessAreaId, schemaId, dataSetId, dataObjectId);
        yield put(actions.deleteDataObjectSuccess(businessAreaId, schemaId, dataSetId, dataObjectId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "Data Object");
        yield put(actions.deleteDataObjectFailure(errorObject));
    }
}

export function* getDataObjectPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getDataObjectPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getDataObjectPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user dataobjects permission");
        yield put(actions.getDataObjectPermissionFailure(errorObject));
    }
}

export function* getDataObjectByUUIDRequest(api, { dataObjectId }) {
    try {
        let result = yield call(getDataObjectByUUID, api, dataObjectId);
        yield put(actions.getDataObjectByUUIDSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Data Object");
        yield put(actions.getDataObjectByUUIDFailure(errorObject));
    }
}

export function* getDataObjectSharedLinkRequest(api, { businessAreaId, schemaId, dataSetId, dataObjectId, readOnly, expires }) {
    try {
        let result = yield call(getDataObjectSharedLink, api, businessAreaId, schemaId, dataSetId, dataObjectId, readOnly, expires);
        yield put(actions.getDataObjectSharedLinkSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Data Object share link");
        yield put(actions.getDataObjectSharedLinkFailure(errorObject));
    }
}

export function* getSharedDataObjectByUUIDRequest(api, { tenantId, userId, readOnly, expiresAt, signatureDate, signature, dataObjectId }) {
    try {
        let result = yield call(getSharedDataObjectByUUID, api, tenantId, userId, readOnly, expiresAt, signatureDate, signature, dataObjectId);
        yield put(actions.getDataObjectByUUIDSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Data Object");
        yield put(actions.getDataObjectByUUIDFailure(errorObject));
    }
}

export function* updateSharedDataObjectRequest(api, { tenantId, userId, readOnly, expiresAt, signatureDate, signature, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject }) {
    try {
        yield call(updateSharedDataObject, api, tenantId, userId, readOnly, expiresAt, signatureDate, signature, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject);
        dataObject.version = dataObject.version + 1;
        yield put(actions.updateDataObjectSuccess(dataObjectId, dataObject));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "Data Object");
        yield put(actions.updateDataObjectFailure(errorObject));
    }
}

export function* watchCreateDataObjectsUploadJobRequest(api, { params }) {
    yield call(createDataObjectsUploadJobRequest, api, params);
}

export function* watchGetDataObjectsByDataSetIdRequest(api, { params }) {
    yield call(getDataObjectsByDataSetIdRequest, api, params);
}

export function* watchGetDataObjectsByDataSetIdNextPageRequest(api, { params }) {
    yield call(getDataObjectsByDataSetIdNextPageRequest, api, params);
}

export function* watchGetDataObjectRequest(api, { params }) {
    yield call(getDataObjectRequest, api, params);
}

export function* watchCreateDataObjectRequest(api, { params }) {
    yield call(createDataObjectRequest, api, params);
}

export function* watchUpdateDataObjectRequest(api, { params }) {
    yield call(updateDataObjectRequest, api, params);
}

export function* watchDeleteDataObjectRequest(api, { params }) {
    yield call(deleteDataObjectRequest, api, params);
}

export function* watchGetDataObjectPermissionRequest(api, { params }) {
    yield call(getDataObjectPermissionRequest, api, params);
}

export function* watchGetDataObjectByUUIDRequest(api, { params }) {
    yield call(getDataObjectByUUIDRequest, api, params);
}

export function* watchGetDataObjectSharedLinkRequest(api, { params }) {
    yield call(getDataObjectSharedLinkRequest, api, params);
}

export function* watchGetSharedDataObjectByUUIDRequest(api, { params }) {
    yield call(getSharedDataObjectByUUIDRequest, api, params);
}

export function* watchUpdateSharedDataObjectRequest(api, { params }) {
    yield call(updateSharedDataObjectRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.CREATE_DATA_OBJECTS_UPLOAD_JOB_REQUEST, watchCreateDataObjectsUploadJobRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECTS_BY_DATASET_ID_REQUEST, watchGetDataObjectsByDataSetIdRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_REQUEST, watchGetDataObjectsByDataSetIdNextPageRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_REQUEST, watchGetDataObjectRequest, api);
    yield takeLatest(actions.CREATE_DATA_OBJECT_REQUEST, watchCreateDataObjectRequest, api);
    yield takeLatest(actions.UPDATE_DATA_OBJECT_REQUEST, watchUpdateDataObjectRequest, api);
    yield takeLatest(actions.DELETE_DATA_OBJECT_REQUEST, watchDeleteDataObjectRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_PERMISSIONS_REQUEST, watchGetDataObjectPermissionRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_BY_UUID_REQUEST, watchGetDataObjectByUUIDRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_SHARED_LINK_REQUEST, watchGetDataObjectSharedLinkRequest, api);
    yield takeLatest(actions.GET_SHARED_DATA_OBJECT_BY_UUID_REQUEST, watchGetSharedDataObjectByUUIDRequest, api);
    yield takeLatest(actions.UPDATE_SHARED_DATA_OBJECT_REQUEST, watchUpdateSharedDataObjectRequest, api);
}