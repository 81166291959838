import react from 'react';
import { Alert } from "antd";

function NotFoundError({ title, description }) {
    return <Alert
        message={title}
        description={description}
        type="error"
        showIcon
    />
}

export default NotFoundError