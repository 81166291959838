export const GET_CDC_DESTINATIONS_REQUEST = "GET_CDC_DESTINATIONS_REQUEST";
export const GET_CDC_DESTINATIONS_SUCCESS = "GET_CDC_DESTINATIONS_SUCCESS";
export const GET_CDC_DESTINATIONS_FAILURE = "GET_CDC_DESTINATIONS_FAILURE";

export const CREATE_CDC_DESTINATION_REQUEST = "CREATE_CDC_DESTINATION_REQUEST";
export const CREATE_CDC_DESTINATION_SUCCESS = "CREATE_CDC_DESTINATION_SUCCESS";
export const CREATE_CDC_DESTINATION_FAILURE = "CREATE_CDC_DESTINATION_FAILURE";

export const GET_CDC_DESTINATION_REQUEST = "GET_CDC_DESTINATION_REQUEST";
export const GET_CDC_DESTINATION_SUCCESS = "GET_CDC_DESTINATION_SUCCESS";
export const GET_CDC_DESTINATION_FAILURE = "GET_CDC_DESTINATION_FAILURE";

export const UPDATE_CDC_DESTINATION_REQUEST = "UPDATE_CDC_DESTINATION_REQUEST";
export const UPDATE_CDC_DESTINATION_SUCCESS = "UPDATE_CDC_DESTINATION_SUCCESS";
export const UPDATE_CDC_DESTINATION_FAILURE = "UPDATE_CDC_DESTINATION_FAILURE";

export const DELETE_CDC_DESTINATION_REQUEST = "DELETE_CDC_DESTINATION_REQUEST";
export const DELETE_CDC_DESTINATION_SUCCESS = "DELETE_CDC_DESTINATION_SUCCESS";
export const DELETE_CDC_DESTINATION_FAILURE = "DELETE_CDC_DESTINATION_FAILURE";

export const GET_CDC_DESTINATION_PERMISSIONS_REQUEST = "GET_CDC_DESTINATION_PERMISSIONS_REQUEST";
export const GET_CDC_DESTINATION_PERMISSIONS_SUCCESS = "GET_CDC_DESTINATION_PERMISSIONS_SUCCESS";
export const GET_CDC_DESTINATION_PERMISSIONS_FAILURE = "GET_CDC_DESTINATION_PERMISSIONS_FAILURE";

export const getCDCDestinationsRequest = () => ({
    type: GET_CDC_DESTINATIONS_REQUEST
})

export const getCDCDestinationsSuccess = (result) => ({
    type: GET_CDC_DESTINATIONS_SUCCESS,
    payload: {
        result
    }
})

export const getCDCDestinationsFailure = (error) => ({
    type: GET_CDC_DESTINATIONS_FAILURE,
    payload: {
        error
    }
})

export const createCDCDestinationRequest = (cdcDestination) => ({
    type: CREATE_CDC_DESTINATION_REQUEST,
    params: {
        cdcDestination
    }
})

export const createCDCDestinationSuccess = (cdcDestination) => ({
    type: CREATE_CDC_DESTINATION_SUCCESS,
    payload: {
        cdcDestination
    }
})

export const createCDCDestinationFailure = (error) => ({
    type: CREATE_CDC_DESTINATION_FAILURE,
    payload: {
        error
    }
})

export const getCDCDestinationRequest = (cdcDestinationId) => ({
    type: GET_CDC_DESTINATION_REQUEST,
    params: {
        cdcDestinationId
    }
})

export const getCDCDestinationSuccess = (cdcDestination) => ({
    type: GET_CDC_DESTINATION_SUCCESS,
    payload: {
        cdcDestination
    }
})

export const getCDCDestinationFailure = (error) => ({
    type: GET_CDC_DESTINATION_FAILURE,
    payload: {
        error
    }
})

export const updateCDCDestinationRequest = (cdcDestinationId, updatedCDCDestination) => ({
    type: UPDATE_CDC_DESTINATION_REQUEST,
    params: {
        cdcDestinationId,
        updatedCDCDestination
    }
})

export const updateCDCDestinationSuccess = (cdcDestination) => ({
    type: UPDATE_CDC_DESTINATION_SUCCESS,
    payload: {
        cdcDestination
    }
})

export const updateCDCDestinationFailure = (error) => ({
    type: UPDATE_CDC_DESTINATION_FAILURE,
    payload: {
        error
    }
})

export const deleteCDCDestinationRequest = (cdcDestinationId) => ({
    type: DELETE_CDC_DESTINATION_REQUEST,
    params: {
        cdcDestinationId
    }
})

export const deleteCDCDestinationSuccess = (cdcDestinationId) => ({
    type: DELETE_CDC_DESTINATION_SUCCESS,
    payload: {
        cdcDestinationId
    }
})

export const deleteCDCDestinationFailure = (error) => ({
    type: DELETE_CDC_DESTINATION_FAILURE,
    payload: {
        error
    }
})

export const getCDCDestinationPermissionRequest = () => ({
    type: GET_CDC_DESTINATION_PERMISSIONS_REQUEST
})

export const getCDCDestinationPermissionSuccess = (permission) => ({
    type: GET_CDC_DESTINATION_PERMISSIONS_SUCCESS,
    payload: {
        permission
    }
})

export const getCDCDestinationPermissionFailure = (error) => ({
    type: GET_CDC_DESTINATION_PERMISSIONS_FAILURE,
    payload: {
        error
    }
})