
import _ from 'lodash';

export default function (error, action, resource) {
    console.log(`${action} ${resource} errored`, error);
    let resultError = { code: "failed", message: `${action} ${resource} failed` };
    if (error.code && error.message) {
        resultError.code = error.code;
        resultError.message = error.message;
    }
    if (error.isAxiosError) {
        if (error.response && error.response.status === 403) {
            return { code: "PERMISSION_DENIED", message: `Permission denied` };
        }
        if (error.response && error.response.status === 404) {
            return { code: "NOT_FOUND_ERROR", message: `Resource not found` };
        }
        if (error.response) {
            let responseError = error.response.data;
            if (responseError && Object.keys(responseError).length > 0) {
                resultError = { ...resultError, ...responseError };
            }
        }
        if (resultError.fields && resultError.fields.length > 0) {
            resultError.fields = resultError.fields.reduce((errorObj, field) => {
                errorObj[field.name] = _.isArray(field.errors) ? field.errors.join("\n") : field.errors;
                return errorObj;
            }, {});
        }
    }
    return resultError;
}