import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getDataObjectHistory(api, tenantId, businessAreaId, schemaId, dataSetId, dataObjectId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}/history`, { tenantid: tenantId });
    return result.data;
}

function* getDataObjectEvents(api, tenantId, dataObjectId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/dataobjects/${dataObjectId}/events`, { tenantid: tenantId });
    return result.data;
}

export function* getDataObjectHistoryRequest(api, { businessAreaId, schemaId, dataSetId, dataObjectId }) {
    try {
        let tenant = yield select(getTenant);
        let dataObjectHistoryResult = yield call(getDataObjectHistory, api, tenant.tenantId, businessAreaId, schemaId, dataSetId, dataObjectId);
        yield put(actions.getDataObjectHistorySuccess(dataObjectHistoryResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataobject history");
        yield put(actions.getDataObjectHistoryFailure(errorObject));
    }
}

export function* getDataObjectEventsRequest(api, { dataObjectId }) {
    try {
        let tenant = yield select(getTenant);
        let dataObjectEventsResult = yield call(getDataObjectEvents, api, tenant.tenantId, dataObjectId);
        yield put(actions.getDataObjectEventsSuccess(dataObjectEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataobject events");
        yield put(actions.getDataObjectEventsFailure(errorObject));
    }
}

export function* watchGetDataObjectHistoryRequest(api, { params }) {
    yield call(getDataObjectHistoryRequest, api, params);
}

export function* watchGetDataObjectEventsRequest(api, { params }) {
    yield call(getDataObjectEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_OBJECT_HISTORY_REQUEST, watchGetDataObjectHistoryRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_EVENTS_REQUEST, watchGetDataObjectEventsRequest, api);
}