import React from "react";
import { Row, Col, Layout, Button } from 'antd';
import { Menu, Image, Popup } from 'semantic-ui-react';
import { PageFooter } from 'components';

const { Header, Content, Footer } = Layout;
function AnonymousPageWrapper(ComposedComponent, anonymousWrapperProps = { showHeader: false }) {
    return function (props) {
        return (
            <Layout className="page-layout">
                {
                    anonymousWrapperProps.showHeader && <Header className="layout-header">
                        <Menu inverted className="header-menu">
                            <Menu inverted className="header-menu">
                                <Menu.Item>
                                    <Image src='/icons/header-menu/pretectum-logo.png' />
                                    <div className="header-icon-label">Pretectum</div>
                                </Menu.Item>
                            </Menu>
                        </Menu>
                    </Header>
                }
                <Content style={{ padding: "1rem", overflowY: "auto" }}>
                    <ComposedComponent {...props} anonymousWrapperProps={anonymousWrapperProps} />
                </Content>
                <Footer className="layout-footer">
                    <PageFooter></PageFooter>
                </Footer>
            </Layout >
        );
    }
}

export default (composedComponent, anonymousWrapperProps) => AnonymousPageWrapper(composedComponent, anonymousWrapperProps)