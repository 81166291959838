import { put, call, takeEvery, select, all, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getTenantProvisionSteps(api) {
    let response = yield call([api, api.get], `${Endpoints.tenants}/api/v1/tenants/provision/steps`);
    return response.data;
}

export function* getTenantProvisionStepsRequest(api) {
    try {
        let items = yield call(getTenantProvisionSteps, api);
        yield put(actions.getTenantProvisionStepsSuccess(items));
    } catch (error) {
        let errorObject = errorHandler(error, 'Get', 'tenant provision steps');
        yield put(actions.getTenantProvisionStepsFailure(errorObject));
    }
}

export function* watchGetTenantProvisionStepsRequest(api, { params }) {
    yield call(getTenantProvisionStepsRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_TENANT_PROVISION_STEPS_REQUEST, watchGetTenantProvisionStepsRequest, api);
}
