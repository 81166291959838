
import { put, call, takeEvery, select } from 'redux-saga/effects';
import * as actions from './actions';
import { Auth } from "aws-amplify";
import { Endpoints } from 'services/api';
import moment from 'moment';
import { keyGenerator } from 'common/Utility';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;

function* getRoles(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/roles`, { tenantid: tenantId });
    return result.data;
}

function* getPermissionMasterData(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/masterdata/permissions`, { tenantid: tenantId });
    return result.data;
}

function* createRole(api, tenantId, role) {
    let result = yield call([api, api.post], `${Endpoints.permissions}/api/v1/roles`, role, { tenantid: tenantId });
    return result.data;
}

function* getRoleById(api, tenantId, roleId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/roles/${roleId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateRole(api, tenantId, roleId, updateRoleRequest) {
    let result = yield call([api, api.put], `${Endpoints.permissions}/api/v1/roles/${roleId}`, updateRoleRequest, { tenantid: tenantId });
    return result.data;
}

function* deleteRole(api, tenantId, roleId) {
    let result = yield call([api, api.delete], `${Endpoints.permissions}/api/v1/roles/${roleId}`, { tenantid: tenantId });
    return result.data;
}

function* getUserRolesPermissions(api, tenantId) {
    //TODo - remove the current user call and replace it with pretectum user
    let user = yield call([Auth, Auth.currentAuthenticatedUser]);
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${user.attributes.sub}/permissions/resources/roles`, { tenantid: tenantId });
    return result.data;
}

function addUpdatedRolePermissions(updateData, roleUpdateRequest) {
    if (updateData.deletedPermissions && updateData.deletedPermissions.length > 0) {
        roleUpdateRequest.permissions = {
            deleteRequest: []
        }
        for (let deletedPermission of updateData.deletedPermissions) {
            roleUpdateRequest.permissions.deleteRequest.push({
                permissionId: deletedPermission.permissionId,
                version: deletedPermission.version
            })
        }
    }

    if (updateData.updatedPermissions && updateData.updatedPermissions.length > 0) {
        if (!roleUpdateRequest.permissions) {
            roleUpdateRequest.permissions = {
                updateRequest: []
            }
        }
        else {
            roleUpdateRequest.permissions.updateRequest = [];
        }
        for (let permission of updateData.updatedPermissions) {
            let updatedPermission = {
                permissionId: permission.permissionId,
                description: permission.description,
                actions: [],
                active: permission.active,
                version: permission.version
            };
            if (permission.canAdd) {
                updatedPermission.actions.push("add");
            }
            if (permission.canView) {
                updatedPermission.actions.push("view");
            }
            if (permission.canEdit) {
                updatedPermission.actions.push("edit");
            }
            if (permission.canDelete) {
                updatedPermission.actions.push("delete");
            }
            roleUpdateRequest.permissions.updateRequest.push(updatedPermission);
        }
    }

    if (updateData.newPermissions && updateData.newPermissions.length > 0) {
        if (!roleUpdateRequest.permissions) {
            roleUpdateRequest.permissions = {
                insertRequest: []
            }
        }
        else {
            roleUpdateRequest.permissions.insertRequest = [];
        }
        for (let permission of updateData.newPermissions) {
            let rolePermission = {
                name: permission.name,
                description: permission.description,
                resourceName: permission.resourceName,
                resourceId: permission.resourceId,
                effect: permission.effect,
                active: permission.active,
                actions: []
            };
            if (permission.condition) {
                rolePermission.condition = permission.condition;
            }
            if (permission.canAdd) {
                rolePermission.actions.push("add");
            }
            if (permission.canView) {
                rolePermission.actions.push("view");
            }
            if (permission.canEdit) {
                rolePermission.actions.push("edit");
            }
            if (permission.canDelete) {
                rolePermission.actions.push("delete");
            }
            roleUpdateRequest.permissions.insertRequest.push(rolePermission);
        }
    }
}

function addUpdatedRoleUsers(updateData, roleUpdateRequest) {
    if (updateData.deletedUsers && updateData.deletedUsers.length > 0) {
        roleUpdateRequest.users = {
            deleteRequest: []
        }
        for (let deletedUser of updateData.deletedUsers) {
            roleUpdateRequest.users.deleteRequest.push({
                userId: deletedUser.userId,
                version: deletedUser.version
            })
        }
    }

    if (updateData.updatedUsers && updateData.updatedUsers.length > 0) {
        if (!roleUpdateRequest.users) {
            roleUpdateRequest.users = {
                updateRequest: []
            }
        }
        else {
            roleUpdateRequest.users.updateRequest = [];
        }
        for (let user of updateData.updatedUsers) {
            let updatedUser = {
                userId: user.userId,
                active: user.active,
                version: user.version
            };
            roleUpdateRequest.users.updateRequest.push(updatedUser);
        }
    }

    if (updateData.newUsers && updateData.newUsers.length > 0) {
        if (!roleUpdateRequest.users) {
            roleUpdateRequest.users = {
                insertRequest: []
            }
        }
        else {
            roleUpdateRequest.users.insertRequest = [];
        }
        for (let user of updateData.newUsers) {
            let roleUser = {
                userId: user.userId,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                active: user.active,
                version: user.version
            };
            roleUpdateRequest.users.insertRequest.push(roleUser);
        }
    }
}

function addUpdatedRoleAppClients(updateData, roleUpdateRequest) {
    if (updateData.deletedAppClients && updateData.deletedAppClients.length > 0) {
        roleUpdateRequest.appClients = {
            deleteRequest: []
        }
        for (let deletedAppClient of updateData.deletedAppClients) {
            roleUpdateRequest.appClients.deleteRequest.push({
                clientId: deletedAppClient.clientId,
                version: deletedAppClient.version
            })
        }
    }

    if (updateData.updatedAppClients && updateData.updatedAppClients.length > 0) {
        if (!roleUpdateRequest.appClients) {
            roleUpdateRequest.appClients = {
                updateRequest: []
            }
        }
        else {
            roleUpdateRequest.appClients.updateRequest = [];
        }
        for (let appClient of updateData.updatedAppClients) {
            let updatedAppClient = {
                clientId: appClient.clientId,
                active: appClient.active,
                version: appClient.version
            };
            roleUpdateRequest.appClients.updateRequest.push(updatedAppClient);
        }
    }

    if (updateData.newAppClients && updateData.newAppClients.length > 0) {
        if (!roleUpdateRequest.appClients) {
            roleUpdateRequest.appClients = {
                insertRequest: []
            }
        }
        else {
            roleUpdateRequest.appClients.insertRequest = [];
        }
        for (let appClient of updateData.newAppClients) {
            let roleAppClient = {
                clientId: appClient.clientId,
                clientName: appClient.clientName,
                active: appClient.active,
                version: appClient.version
            };
            roleUpdateRequest.appClients.insertRequest.push(roleAppClient);
        }
    }
}

function processRoleResponse(role) {
    if (role) {
        if (role.permissions && role.permissions.Items && role.permissions.Items.length > 0) {
            role.permissions = role.permissions.Items.map((item, index) => {
                item.key = keyGenerator(index);
                item.canAdd = false;
                item.canView = false;
                item.canEdit = false;
                item.canDelete = false;
                if (item.actions.includes("add")) {
                    item.canAdd = true;
                }
                if (item.actions.includes("view")) {
                    item.canView = true;
                }
                if (item.actions.includes("edit")) {
                    item.canEdit = true;
                }
                if (item.actions.includes("delete")) {
                    item.canDelete = true;
                }
                item.errors = {};
                return item;
            })
        }
        else {
            role.permissions = [];
        }

        if (role.users && role.users.Items && role.users.Items.length > 0) {
            role.users = role.users.Items.map((item, index) => {
                return {
                    key: keyGenerator(index),
                    ...item.user,
                    assignedDate: item.createdDate,
                    version: item.version
                };
            });
        } else {
            role.users = [];
        }

        if (role.appClients && role.appClients.Items && role.appClients.Items.length > 0) {
            role.appClients = role.appClients.Items.map((item, index) => {
                return {
                    key: keyGenerator(index),
                    ...item.appClient,
                    assignedDate: item.createdDate,
                    version: item.version
                };
            });
        } else {
            role.appClients = [];
        }
    }
}

export function* getRolesRequest(api) {
    try {
        let roles = [];
        let tenant = yield select(getTenant);
        const response = yield call(getRoles, api, tenant.tenantId);
        if (response.Items) {
            roles = response.Items;
        }
        yield put(actions.getRolesSuccess(roles));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "roles");
        yield put(actions.getRolesFailure(errorObject));
    }
}

export function* getPermissionMasterDataRequest(api) {
    try {
        let permissionMasterData = [];
        let tenant = yield select(getTenant);
        const response = yield call(getPermissionMasterData, api, tenant.tenantId);
        if (response.Items) {
            permissionMasterData = response.Items;
        }
        yield put(actions.getPermissionMasterDataSuccess(permissionMasterData));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "permission master data");
        yield put(actions.getPermissionMasterDataFailure(errorObject));
    }
}

export function* createRoleRequest(api, { role, permissions, users, appClients }) {
    try {
        let tenant = yield select(getTenant);
        let roleItem = {
            name: role.name,
            description: role.description,
            active: role.active
        }

        roleItem.permissions = [];
        roleItem.users = [];
        roleItem.appClients = [];

        for (let permission of permissions) {
            let rolePermission = {
                name: permission.name,
                description: permission.description,
                resourceName: permission.resourceName,
                resourceId: permission.resourceId,
                effect: permission.effect,
                active: permission.active,
                actions: []
            };
            if (permission.condition) {
                rolePermission.condition = permission.condition;
            }
            if (permission.canAdd) {
                rolePermission.actions.push("add");
            }
            if (permission.canView) {
                rolePermission.actions.push("view");
            }
            if (permission.canEdit) {
                rolePermission.actions.push("edit");
            }
            if (permission.canDelete) {
                rolePermission.actions.push("delete");
            }
            roleItem.permissions.push(rolePermission);
        }

        if (users) {
            for (let user of users) {
                roleItem.users.push({
                    userId: user.userId,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    active: user.active,
                    version: user.version
                })
            }
        }

        if (appClients) {
            for (let appClient of appClients) {
                roleItem.appClients.push({
                    clientId: appClient.clientId,
                    clientName: appClient.clientName,
                    active: appClient.active,
                    version: appClient.version
                })
            }
        }

        const createdRole = yield call(createRole, api, tenant.tenantId, roleItem);
        yield put(actions.createRolesSuccess(createdRole));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "role");
        yield put(actions.createRolesFailure(errorObject));
    }
}

export function* getRoleByIdRequest(api, { roleId }) {
    try {
        let tenant = yield select(getTenant);
        const role = yield call(getRoleById, api, tenant.tenantId, roleId);
        processRoleResponse(role);
        yield put(actions.getRoleByIdSuccess(role));

    } catch (error) {
        let errorObject = errorHandler(error, "Get", "role");
        yield put(actions.getRoleByIdFailure(errorObject));
    }
}

export function* updateRoleRequest(api, { updateData, roleId }) {
    try {
        let tenant = yield select(getTenant);
        let roleUpdateRequest = {};
        if (updateData.role) {
            roleUpdateRequest.role = {
                updateRequest: updateData.role
            }
        }
        addUpdatedRolePermissions(updateData, roleUpdateRequest);
        addUpdatedRoleUsers(updateData, roleUpdateRequest);
        addUpdatedRoleAppClients(updateData, roleUpdateRequest);
        yield call(updateRole, api, tenant.tenantId, roleId, roleUpdateRequest);
        yield put(actions.updateRoleSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "role");
        if (error.isAxiosError) {
            if ([400, 404, 409, 412].includes(error.response.status) === false) {
                yield call(getRoleByIdRequest, api, { roleId });
            }
        }
        yield put(actions.updateRoleFailure(errorObject));
    }
}

export function* deleteRoleRequest(api, { roleId }) {
    try {
        let tenant = yield select(getTenant);
        yield call(deleteRole, api, tenant.tenantId, roleId);
        yield call(getRolesRequest, api);
        yield put(actions.deleteRoleSuccess());

    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "role");
        yield put(actions.deleteRoleFailure(errorObject));
    }
}

export function* activateDeactivateRoleRequest(api, { roleId, roleActive, roleVersion }) {
    try {
        let tenant = yield select(getTenant);
        let roleUpdateRequest = {
            role: {
                updateRequest: {
                    active: roleActive,
                    version: roleVersion
                }
            }
        };

        const result = yield call(updateRole, api, tenant.tenantId, roleId, roleUpdateRequest);
        yield call(getRolesRequest, api);
        yield put(actions.activateDeactivateRoleSuccess(roleId));
    } catch (error) {
        let errorObject = errorHandler(error, "Activate/Deactivate", "role");
        yield put(actions.activateDeactivateRoleFailure(errorObject, roleId));
    }
}

export function* getUserRolesPermissionRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const result = yield call(getUserRolesPermissions, api, tenant.tenantId);
        let permission = null;
        if (result && result.Items && result.Items.length > 0) {
            for (let item of result.Items) {
                if (!permission) {
                    permission = { ...item };
                    permission.canAdd = false;
                    permission.canView = false;
                    permission.canEdit = false;
                    permission.canDelete = false;
                }

                if (item.actions.includes("add")) {
                    permission.canAdd = true;
                }
                if (item.actions.includes("view")) {
                    permission.canView = true;
                }
                if (item.actions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (item.actions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }
        yield put(actions.getUserRolesPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user roles permissons");
        yield put(actions.getUserRolesPermissionFailure(errorObject));
    }
}

export function* watchGetRolesRequest(api, params) {
    yield call(getRolesRequest, api);
}

export function* watchGetPermissionMasterDataRequest(api, params) {
    yield call(getPermissionMasterDataRequest, api);
}

export function* watchCreateRoleRequest(api, { params }) {
    yield call(createRoleRequest, api, params);
}

export function* watchGetRoleByIdRequest(api, { params }) {
    yield call(getRoleByIdRequest, api, params);
}

export function* watchUpdateRoleRequest(api, { params }) {
    yield call(updateRoleRequest, api, params);
}

export function* watchDeleteRoleRequest(api, { params }) {
    yield call(deleteRoleRequest, api, params);
}

export function* watchActivateDeactivateRoleRequest(api, { params }) {
    yield call(activateDeactivateRoleRequest, api, params);
}

export function* watchGetUserRolesPermissionRequest(api, params) {
    yield call(getUserRolesPermissionRequest, api);
}

export default function* ({ api }) {
    yield takeEvery(actions.GET_ROLES_REQUEST, watchGetRolesRequest, api);
    yield takeEvery(actions.GET_PERMISSIONS_MASTER_DATA_REQUEST, watchGetPermissionMasterDataRequest, api);
    yield takeEvery(actions.CREATE_ROLE_REQUEST, watchCreateRoleRequest, api);
    yield takeEvery(actions.GET_ROLE_BY_ID_REQUEST, watchGetRoleByIdRequest, api);
    yield takeEvery(actions.UPDATE_ROLE_REQUEST, watchUpdateRoleRequest, api);
    yield takeEvery(actions.DELETE_ROLE_REQUEST, watchDeleteRoleRequest, api);
    yield takeEvery(actions.ACTIVATE_DEACTIVATE_ROLE_REQUEST, watchActivateDeactivateRoleRequest, api);
    yield takeEvery(actions.GET_USER_ROLES_PERMISSION_REQUEST, watchGetUserRolesPermissionRequest, api);
}