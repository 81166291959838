import React, { useState } from "react";
import { Table, List, Space, Row, Col, Typography, Input, Button } from "antd";
import { StaticPickListModal } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Title, Text, Link } = Typography;

export default function ({ name, value, previousValue }) {
    const [showPicklistModal, setShowPicklistModal] = useState({ visible: false, fields: [] });
    return <>
        <StaticPickListModal
            key={name}
            fields={showPicklistModal.fields}
            visible={showPicklistModal.visible}
            onCancel={() => {
                setShowPicklistModal({ visible: false, fields: [] });
            }}>
        </StaticPickListModal>
        <List.Item key={name}>
            <Space>
                <Typography.Text>{name}:</Typography.Text>
                <Input value={value && value.map(item => item.code).join(", ")} readOnly={true} />
                <Button
                    block
                    onClick={() => {
                        setShowPicklistModal({ visible: true, fields: value || [] });
                    }}>
                    ...
                </Button>
                <Text strong type="secondary">(New Value)</Text>
                <Input value={previousValue && previousValue.map(item => item.code).join(", ")} readOnly={true} />
                <Button
                    block
                    onClick={() => {
                        setShowPicklistModal({ visible: true, fields: previousValue || [] });
                    }}>
                    ...
                </Button>
                <Text type="secondary">(Old Value)</Text>
            </Space>
        </List.Item>
    </>
}