export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_STATUS = "USER_LOGIN_STATUS";
export const GET_USER_AND_TENANT_REQUEST = "GET_USER_AND_TENANT_REQUEST";
export const GET_USER_AND_TENANT_SUCCESS = "GET_USER_AND_TENANT_SUCCESS";
export const GET_USER_AND_TENANT_FAILURE = "GET_USER_AND_TENANT_FAILURE";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const CHANGE_TEMPORARY_PASSWORD_REQUEST = "CHANGE_TEMPORARY_PASSWORD_REQUEST";
export const CHANGE_TEMPORARY_PASSWORD_SUCCESS = "CHANGE_TEMPORARY_PASSWORD_SUCCESS";
export const CHANGE_TEMPORARY_PASSWORD_FAILURE = "CHANGE_TEMPORARY_PASSWORD_FAILURE";

export const SEND_EMAIL_VERIFICATION_LINK_REQUEST = "SEND_EMAIL_VERIFICATION_LINK_REQUEST";
export const SEND_EMAIL_VERIFICATION_LINK_SUCCESS = "SEND_EMAIL_VERIFICATION_LINK_SUCCESS";
export const SEND_EMAIL_VERIFICATION_LINK_FAILURE = "SEND_EMAIL_VERIFICATION_LINK_FAILURE";

export const ADMIN_FORCE_USER_LOGOUT = "ADMIN_FORCE_USER_LOGOUT";

export const userLoginRequest = (email, password) => ({
    type: USER_LOGIN_REQUEST,
    params: {
        email,
        password
    }
})

export const userLoginSuccess = ({ cognitoUser, user, tenant, newTenant }) => ({
    type: USER_LOGIN_SUCCESS,
    payload: {
        cognitoUser,
        user,
        tenant,
        newTenant
    }
})

export const userLoginFailure = (error) => ({
    type: USER_LOGIN_FAILURE,
    payload: error
})

export const userLoginStatus = (status) => ({
    type: USER_LOGIN_STATUS,
    payload: status
})

export const getUserAndTenantRequest = () => ({
    type: GET_USER_AND_TENANT_REQUEST
})

export const getUserAndTenantSuccess = (data) => ({
    type: GET_USER_AND_TENANT_SUCCESS,
    payload: data
})

export const getUserAndTenantFailure = (error) => ({
    type: GET_USER_AND_TENANT_FAILURE,
    payload: error
})

export const userLogoutRequest = () => ({
    type: USER_LOGOUT_REQUEST
})

export const userLogoutSuccess = () => ({
    type: USER_LOGOUT_SUCCESS
})

export const changeTemporaryPasswordRequest = (email, oldPassword, newPassword) => ({
    type: CHANGE_TEMPORARY_PASSWORD_REQUEST,
    params: {
        email,
        oldPassword,
        newPassword
    }
})

export const changeTemporaryPasswordSuccess = (status) => ({
    type: CHANGE_TEMPORARY_PASSWORD_SUCCESS,
    payload: status
})

export const changeTemporaryPasswordFailure = (error) => ({
    type: CHANGE_TEMPORARY_PASSWORD_FAILURE,
    payload: error
})

export const sendEmailVerificationLinkRequest = (email) => ({
    type: SEND_EMAIL_VERIFICATION_LINK_REQUEST,
    params: {
        email
    }
})

export const sendEmailVerificationLinkSuccess = (success) => ({
    type: SEND_EMAIL_VERIFICATION_LINK_SUCCESS,
    payload: {
        success
    }
})

export const sendEmailVerificationLinkFailure = (error) => ({
    type: SEND_EMAIL_VERIFICATION_LINK_FAILURE,
    payload: {
        error
    }
})

export const adminForceUserLogout = (message) => ({
    type: ADMIN_FORCE_USER_LOGOUT,
    params: {
        message
    }
})