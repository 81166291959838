
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEventBusinessAreaUsers(api, tenantId, eventId, itemOperation, pageKey) {
    try {
        let params = {
            operation: itemOperation
        };
        if (pageKey) {
            params.pageKey = pageKey
        }

        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/businessareausers`, { tenantid: tenantId }, { params });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventBusinessAreaUsersRequest(api, { eventId, itemOperation, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        const usersResult = yield call(getEventBusinessAreaUsers, api, tenant.tenantId, eventId, itemOperation, pageKey);
        if (usersResult && usersResult.Items) {
            usersResult.Items = usersResult.Items.map((item, index) => {
                let businessAreaUser = item.data.user;
                businessAreaUser.canAdd = false;
                businessAreaUser.canView = false;
                businessAreaUser.canEdit = false;
                businessAreaUser.canDelete = false;
                if (businessAreaUser.actions.includes("add")) {
                    businessAreaUser.canAdd = true;
                }
                if (businessAreaUser.actions.includes("view")) {
                    businessAreaUser.canView = true;
                }
                if (businessAreaUser.actions.includes("edit")) {
                    businessAreaUser.canEdit = true;
                }
                if (businessAreaUser.actions.includes("delete")) {
                    businessAreaUser.canDelete = true;
                }
                return item;
            });
        }
        yield put(actions.getEventBusinessAreaUsersSuccess(usersResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event business area users");
        yield put(actions.getEventBusinessAreaUsersFailure(errorObject));
    }
}

export function* watchGetEventBusinessAreaUsersRequest(api, { params }) {
    yield call(getEventBusinessAreaUsersRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_BUSINESS_AREA_USERS_REQUEST, watchGetEventBusinessAreaUsersRequest, api);
}