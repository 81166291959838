import React from "react";
import { Table, List, Space, Row, Col, Typography } from "antd";
import { ListRecordAttributeItem } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Title, Text, Link } = Typography;

export default function ({ record }) {
    return <>
        <Row className="middle-row">
            <Col span={24}>
                <Title level={5}><Text>{record.name}</Text></Title>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <List
                    size="small"
                    bordered
                    dataSource={Object.keys(record.data)}
                    renderItem={item => {
                        switch (item) {
                            case "name":
                                return <ListRecordAttributeItem name={"Name"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "description":
                                return <ListRecordAttributeItem name={"Description"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "active":
                                return <ListRecordAttributeItem name={"Active"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "state":
                                return <ListRecordAttributeItem name={"State"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "businessAreaName":
                                return <ListRecordAttributeItem name={"BusinessArea Name"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "fieldsCount":
                                return <ListRecordAttributeItem name={"Field Count"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "updatedByEmail":
                                return <ListRecordAttributeItem name={"Updated By"} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            case "updatedDate":
                                return <ListRecordAttributeItem name={"Updated At"} value={moment.utc(record.data[item]).toDate().toLocaleString()} previousValue={record.previousData && record.previousData[item] && moment.utc(record.previousData[item]).toDate().toLocaleString()}></ListRecordAttributeItem>
                            default:
                                return <></>
                        }

                    }}
                />
            </Col>
        </Row>
    </>
}