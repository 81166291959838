import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { PicklistFieldProperties, FullHeightContainerLayout, DomainDataPreviewList } from "components";
import { withRouter } from "react-router";
import _ from 'lodash';
import { Typography } from 'antd';

const { Title, Text } = Typography;

const GET_DOMAIN_DATA_RESULT = { success: true, inProgress: true };
const DOMAIN_DATA_RESULT = { Items: [] };

class SchemaPicklistFieldProperties extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domainDataItems: [],
            fetchingDomainData: false
        }
    }

    componentWillMount() {
        let picklistType = this.props.formRef.current.getFieldValue(["fields", this.props.selectedFieldRow.name, "picklistType"]);
        if (picklistType === "dynamic") {
            let dataDomainId = this.props.formRef.current.getFieldValue(["fields", this.props.selectedFieldRow.name, "dataDomainId"]);
            if (dataDomainId) {
                this.setState({
                    dataDomainId
                });
                this.getDomainDataItems(dataDomainId);
            }
        }
        else if (picklistType === "static") {
            this.getStaticPickListItems();
        }
        this.setState({
            picklistType
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onDomainDataChanged(prevProps);
    }

    onDomainDataChanged = (prevProps) => {
        if (prevProps.domainData !== this.props.domainData
            && this.props.domainData && this.props.domainData[this.state.dataDomainId]
            && this.props.domainData[this.state.dataDomainId].getDomainDataResult && this.props.domainData[this.state.dataDomainId].getDomainDataResult.inProgress === false
            && this.props.domainData[this.state.dataDomainId].domainDataResult && this.props.domainData[this.state.dataDomainId].domainDataResult.Items) {
            if (this.state.picklistType === "dynamic") {
                this.setState({
                    domainDataItems: this.props.domainData[this.state.dataDomainId].domainDataResult.Items.slice(0, 99),
                    fetchingDomainData: false
                });
            }
        }
    }

    onPicklistTypeChange = (picklistType) => {
        this.setState({
            picklistType
        });
        if (picklistType === "static") {
            this.getStaticPickListItems();
        }
        else if (picklistType === "dynamic") {
            let dataDomainId = this.props.formRef.current.getFieldValue(["fields", this.props.selectedFieldRow.name, "dataDomainId"]);
            this.setState({
                domainDataItems: []
            })
            if (dataDomainId) {
                this.setState({
                    dataDomainId
                });
                this.getDomainDataItems(dataDomainId);
            }
        }
    }

    onDataDomainChange = (dataDomainId) => {
        this.setState({
            dataDomainId
        });
        this.getDomainDataItems(dataDomainId);
    }

    getDomainDataItems = (dataDomainId) => {
        if (this.props.domainData && this.props.domainData[dataDomainId]
            && this.props.domainData[dataDomainId].getDomainDataResult && this.props.domainData[dataDomainId].getDomainDataResult.inProgress === false
            && this.props.domainData[dataDomainId].domainDataResult && this.props.domainData[dataDomainId].domainDataResult.Items &&
            this.props.domainData[dataDomainId].domainDataResult.Items.length > 0) {
            this.setState({
                domainDataItems: this.props.domainData[dataDomainId].domainDataResult.Items.slice(0, 99)
            });
        }
        else {
            this.props.getPicklistDomainData(dataDomainId, null);
            this.setState({
                fetchingDomainData: true,
                domainDataItems: []
            });
        }
    }

    getStaticPickListItems = () => {
        let staticPicklist = this.props.formRef.current.getFieldValue(["fields", this.props.selectedFieldRow.name, "staticPicklist"]) || [];
        let items = staticPicklist.map((item, index) => ({ ...item, sNo: index + 1, domainDataId: index + 1 }));
        this.setState({
            domainDataItems: items
        });
    }

    render() {
        return <FullHeightContainerLayout
            content={
                <>
                    <FullHeightContainerLayout style={{ height: "auto", maxHeight: "50%" }} content={<PicklistFieldProperties
                        onDataDomainChange={this.onDataDomainChange}
                        onPicklistTypeChange={this.onPicklistTypeChange}
                        {...this.props}>
                    </PicklistFieldProperties>} showFooter={false}></FullHeightContainerLayout>
                    <FullHeightContainerLayout
                        style={{ height: "unset", maxHeight: "50%" }}
                        showHeader={true}
                        header={<Title level={5}>Picklist Preview</Title>}
                        content={
                            <DomainDataPreviewList
                                showRecordsHeader={false}
                                domainDataList={this.state.domainDataItems}
                                loading={this.state.fetchingDomainData ? { tip: "Loading data..." } : false}
                                tableClasses={["container-height-100", "margin-top-small"]}>
                            </DomainDataPreviewList>}
                        showFooter={false}></FullHeightContainerLayout>
                </>
            }>
        </FullHeightContainerLayout>
    }
}

const mapStateToProps = (state, ownProps) => {
    let domainData = state.formDomainDataPicklistField.domainData;
    return {
        domainData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPicklistDomainData: (dataDomainId, pageKey) => dispatch(actions.getPicklistDomainDataRequest(dataDomainId, pageKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaPicklistFieldProperties));