import React, { useState } from 'react';
import { Form, Tooltip, Row, Col, Table, Checkbox, Typography, InputNumber } from "antd";
import { FullHeightContainerLayout, BusinessAreaUserChangedAttributes } from "components";
import moment from 'moment';
import _ from 'lodash';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const BusinessAreaUserHistoryList = ({ businessAreaUserRevisions }) => {
    const [recordDetail, setRecordDetail] = useState(null);
    const [containerHeight, setContainerHeight] = useState("100%");
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            content={<Table size="small"
                                rowKey="data.user.userId"
                                dataSource={businessAreaUserRevisions}
                                pagination={false}
                                scroll={{ y: `100vh` }}
                                className={"container-height-100"}>
                                <Column
                                    width="5rem"
                                    title="No."
                                    key="index"
                                    render={(value, businessAreaUserRevision, index) => index + 1}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "user", "email"]}
                                    title={"User"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, businessAreaUserRevision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "user"]}
                                    title={"Name"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, businessAreaUserRevision, index) => <Tooltip placement="topLeft" title={`${value.firstName} ${value.lastName}`}>{`${value.firstName} ${value.lastName}`}</Tooltip>}
                                />
                                <Column
                                    width="5rem"
                                    title={"Add"}
                                    dataIndex={["data", "user", "canAdd"]}
                                    render={(value, businessAreaUserRevision, index) => <Checkbox disabled={true} checked={value}></Checkbox>}
                                />
                                <Column
                                    width="5rem"
                                    title={"View"}
                                    dataIndex={["data", "user", "canView"]}
                                    render={(value, businessAreaUserRevision, index) => <Checkbox disabled={true} checked={value}></Checkbox>}
                                />
                                <Column
                                    width="5rem"
                                    title={"Edit"}
                                    dataIndex={["data", "user", "canEdit"]}
                                    render={(value, businessAreaUserRevision, index) => <Checkbox disabled={true} checked={value}></Checkbox>}
                                />
                                <Column
                                    width="5rem"
                                    title={"Delete"}
                                    dataIndex={["data", "user", "canDelete"]}
                                    render={(value, businessAreaUserRevision, index) => <Checkbox disabled={true} checked={value}></Checkbox>}
                                />
                                <Column
                                    width="5rem"
                                    title={"Active"}
                                    dataIndex={["data", "user", "active"]}
                                    render={(value, businessAreaUserRevision, index) => <Checkbox disabled={true} checked={value}></Checkbox>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "createdByEmail"]}
                                    title={"Created By"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, businessAreaUserRevision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "createdDate"]}
                                    title={"Created Date"}
                                    render={(value, businessAreaUserRevision, index) => <>{moment.utc(value).toDate().toLocaleString()}</>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "updatedByEmail"]}
                                    title={"Updated By"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, businessAreaUserRevision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "updatedDate"]}
                                    title={"Updated Date"}
                                    render={(value, businessAreaUserRevision, index) => <>{moment.utc(value).toDate().toLocaleString()}</>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"changes"}
                                    title={"Changes"}
                                    render={(value, businessAreaUserRevision, index) => {
                                        if (value) {
                                            return <>
                                                {Object.keys(value).reduce((items, key) => {
                                                    if (Object.keys(value[key]).length > 0) {
                                                        items.push(<Row key={key}>
                                                            <Col>
                                                                <Link onClick={() => {
                                                                    setContainerHeight("50%");
                                                                    setRecordDetail({
                                                                        name: `${_.capitalize(key)} attributes`,
                                                                        recordId: businessAreaUserRevision.data.user.userId,
                                                                        data: value[key],
                                                                        previousData: businessAreaUserRevision.previousChanges && businessAreaUserRevision.previousChanges[key]
                                                                    });
                                                                }}>
                                                                    {`${key} attributes`}
                                                                </Link>
                                                            </Col>
                                                        </Row>);
                                                    }
                                                    return items;
                                                }, [])}
                                            </>
                                        }
                                        return <></>;
                                    }}
                                />
                            </Table>}>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
                {
                    recordDetail
                        ?
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24}>
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    content={<BusinessAreaUserChangedAttributes key={recordDetail.recordId} record={recordDetail}></BusinessAreaUserChangedAttributes>}
                                    showFooter={false}>
                                </FullHeightContainerLayout>
                            </Col>
                        </Row>
                        :
                        <></>}
            </Col>
        </Row>
    );
}

export default BusinessAreaUserHistoryList;