export const GET_ALL_BUSINESS_AREA_DATA_REQUEST = "GET_ALL_BUSINESS_AREA_DATA_REQUEST";
export const GET_ALL_BUSINESS_AREA_DATA_SUCCESS = "GET_ALL_BUSINESS_AREA_DATA_SUCCESS";
export const GET_ALL_BUSINESS_AREA_DATA_FAILURE = "GET_ALL_BUSINESS_AREA_DATA_FAILURE";

export const CREATE_BUSINESS_AREA_DATA_REQUEST = "CREATE_BUSINESS_AREA_DATA_REQUEST";
export const CREATE_BUSINESS_AREA_DATA_SUCCESS = "CREATE_BUSINESS_AREA_DATA_SUCCESS";
export const CREATE_BUSINESS_AREA_DATA_FAILURE = "CREATE_BUSINESS_AREA_DATA_FAILURE";

export const GET_BUSINESS_AREA_DATA_BY_ID_REQUEST = "GET_BUSINESS_AREA_DATA_BY_ID_REQUEST";
export const GET_BUSINESS_AREA_DATA_BY_ID_SUCCESS = "GET_BUSINESS_AREA_DATA_BY_ID_SUCCESS";
export const GET_BUSINESS_AREA_DATA_BY_ID_FAILURE = "GET_BUSINESS_AREA_DATA_BY_ID_FAILURE";

export const DELETE_BUSINESS_AREA_DATA_REQUEST = "DELETE_BUSINESS_AREA_DATA_REQUEST";
export const DELETE_BUSINESS_AREA_DATA_SUCCESS = "DELETE_BUSINESS_AREA_DATA_SUCCESS";
export const DELETE_BUSINESS_AREA_DATA_FAILURE = "DELETE_BUSINESS_AREA_DATA_FAILURE";

export const UPDATE_BUSINESS_AREA_DATA_REQUEST = "UPDATE_BUSINESS_AREA_DATA_REQUEST";
export const UPDATE_BUSINESS_AREA_DATA_SUCCESS = "UPDATE_BUSINESS_AREA_DATA_SUCCESS";
export const UPDATE_BUSINESS_AREA_DATA_FAILURE = "UPDATE_BUSINESS_AREA_DATA_FAILURE";

export const FETCH_BUSINESS_AREA_DOMAIN_DATA_REQUEST = "FETCH_BUSINESS_AREA_DOMAIN_DATA_REQUEST";
export const FETCH_BUSINESS_AREA_DOMAIN_DATA_SUCCESS = "FETCH_BUSINESS_AREA_DOMAIN_DATA_SUCCESS";
export const FETCH_BUSINESS_AREA_DOMAIN_DATA_FAILURE = "FETCH_BUSINESS_AREA_DOMAIN_DATA_FAILURE";

export const GET_USER_BUSINESS_AREA_DATA_PERMISSION_REQUEST = "GET_USER_BUSINESS_AREA_DATA_PERMISSION_REQUEST";
export const GET_USER_BUSINESS_AREA_DATA_PERMISSION_SUCCESS = "GET_USER_BUSINESS_AREA_DATA_PERMISSION_SUCCESS";
export const GET_USER_BUSINESS_AREA_DATA_PERMISSION_FAILURE = "GET_USER_BUSINESS_AREA_DATA_PERMISSION_FAILURE";

export const CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_SUCCESS = "CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_SUCCESS";
export const CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_FAILURE = "CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_FAILURE";

export const getAllBusinessAreaDataRequest = () => ({
    type: GET_ALL_BUSINESS_AREA_DATA_REQUEST
})

export const getAllBusinessAreaDataSuccess = (dataDomainsResult) => ({
    type: GET_ALL_BUSINESS_AREA_DATA_SUCCESS,
    payload: dataDomainsResult
})

export const getAllBusinessAreaDataFailure = (error) => ({
    type: GET_ALL_BUSINESS_AREA_DATA_FAILURE,
    payload: error
})

export const createBusinessAreaDataRequest = (businessAreaData) => ({
    type: CREATE_BUSINESS_AREA_DATA_REQUEST,
    params: {
        businessAreaData
    }
})

export const createBusinessAreaDataSuccess = (businessAreaData) => ({
    type: CREATE_BUSINESS_AREA_DATA_SUCCESS,
    payload: businessAreaData
})

export const createBusinessAreaDataFailure = (error) => ({
    type: CREATE_BUSINESS_AREA_DATA_FAILURE,
    payload: error
})

export const getBusinessAreaDataByIdRequest = (dataDomainId) => ({
    type: GET_BUSINESS_AREA_DATA_BY_ID_REQUEST,
    params: {
        dataDomainId
    }
})

export const getBusinessAreaDataByIdSuccess = (businessAreaData) => ({
    type: GET_BUSINESS_AREA_DATA_BY_ID_SUCCESS,
    payload: businessAreaData
})

export const getBusinessAreaDataByIdFailure = (error) => ({
    type: GET_BUSINESS_AREA_DATA_BY_ID_FAILURE,
    payload: error
})

export const deleteBusinessAreaDataRequest = (dataDomainId) => ({
    type: DELETE_BUSINESS_AREA_DATA_REQUEST,
    params: {
        dataDomainId
    }
})

export const deleteBusinessAreaDataSuccess = (dataDomainId) => ({
    type: DELETE_BUSINESS_AREA_DATA_SUCCESS,
    payload: {
        dataDomainId
    }
})

export const deleteBusinessAreaDataFailure = (error) => ({
    type: DELETE_BUSINESS_AREA_DATA_FAILURE,
    payload: error
})

export const updateBusinessAreaDataRequest = (businessAreaData, updatedBusinessAreaData) => ({
    type: UPDATE_BUSINESS_AREA_DATA_REQUEST,
    params: {
        businessAreaData,
        updatedBusinessAreaData
    }
})

export const updateBusinessAreaDataSuccess = () => ({
    type: UPDATE_BUSINESS_AREA_DATA_SUCCESS
})

export const updateBusinessAreaDataFailure = (error) => ({
    type: UPDATE_BUSINESS_AREA_DATA_FAILURE,
    payload: error
})

export const fetchBusinessAreaDomainDataRequest = (dataDomainId, pageKey) => ({
    type: FETCH_BUSINESS_AREA_DOMAIN_DATA_REQUEST,
    params: {
        dataDomainId,
        pageKey
    }
})

export const fetchBusinessAreaDomainDataSuccess = (dataDomainId, result) => ({
    type: FETCH_BUSINESS_AREA_DOMAIN_DATA_SUCCESS,
    payload: {
        dataDomainId,
        ...result
    }
})

export const fetchBusinessAreaDomainDataFailure = (dataDomainId, error) => ({
    type: FETCH_BUSINESS_AREA_DOMAIN_DATA_FAILURE,
    payload: {
        dataDomainId,
        error
    }
})

export const getUserBusinessAreaDataPermissionRequest = () => ({
    type: GET_USER_BUSINESS_AREA_DATA_PERMISSION_REQUEST
})

export const getUserBusinessAreaDataPermissionSuccess = (permission) => ({
    type: GET_USER_BUSINESS_AREA_DATA_PERMISSION_SUCCESS,
    payload: permission
})

export const getUserBusinessAreaDataPermissionFailure = (error) => ({
    type: GET_USER_BUSINESS_AREA_DATA_PERMISSION_FAILURE,
    payload: error
})

export const createBulkUploadDomainDataJobSuccess = () => ({
    type: CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_SUCCESS
})

export const createBulkUploadDomainDataJobFailure = (error) => ({
    type: CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_FAILURE,
    payload: error
})