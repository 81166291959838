import React, { Component } from "react";
import { connect } from "react-redux";
import actions from 'store/actions';
import { withRouter } from "react-router";
import {
    DataObjectSearchDuplicateForm,
    DuplicateDataObjectJobList,
    FullHeightContainerLayout,
    LoadingOverlay,
    ProhibitedArea,
    BasicFormBuilder,
    DuplicateDataObjectList,
    OkCancelConfirmationBox,
    SuccessResult,
    DeduplicatedRecordsTable
} from 'components';
import AppPaths from "constants/appPaths";
import { showError, showSuccess } from 'common/ToastNotifications';
import { Row, Col, Tabs, Collapse, Empty } from 'antd';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { DataObjectDuplicateSummary, DataObjectDuplicateMergePreview, DataObjectDuplicateMergeRules } from 'containers';

const { TabPane } = Tabs;
const { Panel } = Collapse;

class DataObjectDuplicateSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultListSpan: 24,
            fetchingPermission: true,
            activeResultTab: "SEARCH_CONFIGURATIONS",
            busyMessage: "Please wait...",
            duplicateDataObjectsResultTabs: [],
            dataTags: [],
            showSidePanelTabs: false,
            sidePanelActiveTab: "DATASET_RECORD"
        }
    }

    componentWillMount() {
        this.props.getUserSearchDataObjectsPermission();
        this.props.getUserBusinessAreaDataTags();
        this.showBreadCrumbNavigationItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserSearchDataObjectsPermisionResultChanged(prevProps);
        this.onGetDataObjectsDuplicateJobsResultChanged(prevProps);
        this.onGetDataTagsByBusinessAreaIdResultChanged(prevProps);
        this.onGetUserBusinessAreaDataTagsResultChanged(prevProps);
        this.onGetSchemaModelsResultChanged(prevProps);
        this.onGetSchemaDataSetResultChanged(prevProps);
        this.onGetDataObjectResultChanged(prevProps);
        this.onGetSchemaModelResultChanged(prevProps);
        this.onGetDeDuplicatedRecordsRequestResultChanged(prevProps);
        this.onSearchDuplicateDataObjectJobsResultChanged(prevProps);
        this.onGetDuplicateDataObjectsResultSetResultChanged(prevProps);
        this.onGetDuplicateDataObjectsResultSetNextPageResultChanged(prevProps);
        this.onRestartJobResultUpdate(prevProps);
        this.onCancelJobResultUpdate(prevProps);
        this.onRunDeDuplicateJobResultChanged(prevProps);
        this.onRemoveSurvivalRecordNominationResultChanged(prevProps);
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadcrumbNavigationItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: AppPaths.TENANT_DATA_OBJECT_SEARCH.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Duplicate Search"
                }
            ];
            this.setState({
                breadcrumbNavigationItems
            });
            this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
        }
    }

    onGetUserSearchDataObjectsPermisionResultChanged = (prevProps) => {
        if (this.props.getUserSearchDataObjectsPermisionResult && this.props.getUserSearchDataObjectsPermisionResult !== prevProps.getUserSearchDataObjectsPermisionResult) {
            if (!this.props.getUserSearchDataObjectsPermisionResult.success || !this.props.userSearchDataObjectsPermision || !this.props.userSearchDataObjectsPermision.canView) {
                this.setState({
                    permissionDenied: true
                });
            }
            else {
                this.setState({
                    fetchingDataObjectsDuplicateJobs: true
                });
                this.props.getDataObjectsDuplicateJobs();
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    onGetDataObjectsDuplicateJobsResultChanged = (prevProps) => {
        if (this.props.getDataObjectsDuplicateJobsResult && this.props.getDataObjectsDuplicateJobsResult !== prevProps.getDataObjectsDuplicateJobsResult) {
            if (!this.props.getDataObjectsDuplicateJobsResult.success) {
                if (this.props.getDataObjectsDuplicateJobsResult.code === "PERMISSION_DENIED") {
                    showError("Could not able to get duplicate data objects configuration jobs. Permission denied!");
                }
                else {
                    showError("Could not able to get duplicate data objects configuration jobs at this moment.");
                }
            }
            else {
                this.selectConfigurationJobInHash();
            }
            this.setState({
                fetchingDataObjectsDuplicateJobs: false
            });
        }
    }

    onGetDataTagsByBusinessAreaIdResultChanged = (prevProps) => {
        if (this.props.getDataTagsByBusinessAreaIdResult && this.props.getDataTagsByBusinessAreaIdResult !== prevProps.getDataTagsByBusinessAreaIdResult) {
            if (!this.props.getDataTagsByBusinessAreaIdResult.success) {
                if (this.props.getDataTagsByBusinessAreaIdResult.code === "PERMISSION_DENIED") {
                    showError("Could not able to get data tags. Permission denied!");
                }
                else {
                    showError("Could not able to get data tags at this moment.");
                }
            }
            else {
                let dataTags = this.props.dataTagsByBusinessAreaIdResult.Items;
                this.setState({
                    dataTags
                });
            }
            this.setState({
                loadingDataTags: false
            });
        }
    }

    onGetUserBusinessAreaDataTagsResultChanged = (prevProps) => {
        if (this.props.getUserBusinessAreaDataTagsResult && this.props.getUserBusinessAreaDataTagsResult !== prevProps.getUserBusinessAreaDataTagsResult) {
            if (!this.props.getUserBusinessAreaDataTagsResult.success) {
                if (this.props.getUserBusinessAreaDataTagsResult.code === "PERMISSION_DENIED") {
                    showError("Could not able to get data tags. Permission denied!");
                }
                else {
                    showError("Could not able to get data tags at this moment.");
                }
            }
            else {
                let dataTags = _.uniqBy(this.props.userBusinessAreaDataTags.Items, 'tagName');
                this.setState({
                    dataTags
                });
            }
            this.setState({
                loadingDataTags: false
            });
        }
    }

    onGetSchemaModelsResultChanged = (prevProps) => {
        if (this.props.getSchemaModelsResult && this.props.getSchemaModelsResult !== prevProps.getSchemaModelsResult) {
            this.setState({
                loadingSchemaList: false
            })
        }
    }

    onGetSchemaDataSetResultChanged = (prevProps) => {
        if (this.props.getSchemaDataSetResult && this.props.getSchemaDataSetResult !== prevProps.getSchemaDataSetResult) {
            this.setState({
                loadingDataSetList: false
            })
        }
    }

    onGetDataObjectResultChanged = (prevProps) => {
        if (this.props.getDataObjectResult && this.props.getDataObjectResult !== prevProps.getDataObjectResult) {
            if (!this.props.getDataObjectResult.success) {
                if (this.props.getDataObjectResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        dataObjectPermissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset record.");
                }
            }
            this.setState({
                fetchingDataObject: false
            });
        }
    }

    onGetSchemaModelResultChanged = (prevProps) => {
        if (this.props.getSchemaModelResult && this.props.getSchemaModelResult !== prevProps.getSchemaModelResult) {
            if (!this.props.getSchemaModelResult.success) {
                if (this.props.getSchemaModelResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        schemaPermissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema.");
                }
            }
            this.setState({
                fetchingSchemaModel: false
            });
        }
    }

    onGetDeDuplicatedRecordsRequestResultChanged = (prevProps) => {
        if (this.props.getDeDuplicatedRecordsRequestResult && this.props.getDeDuplicatedRecordsRequestResult !== prevProps.getDeDuplicatedRecordsRequestResult) {
            if (!this.props.getDeDuplicatedRecordsRequestResult.success) {
                showError("Could not able to get deduplicated records.");
            }
            this.setState({
                fetchingDeDuplicatedRecords: false
            });
        }
    }

    onSearchDuplicateDataObjectJobsResultChanged = (prevProps) => {
        if (this.props.searchDuplicateDataObjectJobsResult && this.props.searchDuplicateDataObjectJobsResult !== prevProps.searchDuplicateDataObjectJobsResult) {
            if (!this.props.searchDuplicateDataObjectJobsResult.success) {
                if (this.props.getSchemaModelResult.code === "PERMISSION_DENIED") {
                    showError("Permission Denied!");
                }
                else {
                    showError("Could not able to execute search duplicate data objects request.");
                }
            }
            this.setState({
                submittingSearchDuplicateDataObjectRequest: false
            });
        }
    }

    onGetDuplicateDataObjectsResultSetResultChanged = (prevProps) => {
        if (this.props.getDuplicateDataObjectsResultSetResult && this.props.getDuplicateDataObjectsResultSetResult !== prevProps.getDuplicateDataObjectsResultSetResult) {
            const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
            for (let i = 0; i < duplicateDataObjectsResultTabs.length; i++) {
                const tab = duplicateDataObjectsResultTabs[i];
                if (tab.fetchingResultSet) {
                    if (this.props.getDuplicateDataObjectsResultSetResult.resultSet[tab.id] !== prevProps.getDuplicateDataObjectsResultSetResult.resultSet[tab.id]) {
                        if (this.props.getDuplicateDataObjectsResultSetResult.resultSet[tab.id].success) {
                            duplicateDataObjectsResultTabs[i] = {
                                ...tab,
                                fetchingResultSet: false
                            }
                        }
                        else {
                            duplicateDataObjectsResultTabs[i] = {
                                ...tab,
                                fetchingResultSet: false,
                                error: this.props.getDuplicateDataObjectsResultSetResult.resultSet[tab.id].message || "Could not able to fetch the result set"
                            }
                        }
                    }
                }
            }
            this.setState({
                duplicateDataObjectsResultTabs
            });
        }
    }

    onGetDuplicateDataObjectsResultSetNextPageResultChanged = (prevProps) => {
        if (this.props.getDuplicateDataObjectsResultSetNextPageResult && this.props.getDuplicateDataObjectsResultSetNextPageResult !== prevProps.getDuplicateDataObjectsResultSetNextPageResult) {
            const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
            for (let i = 0; i < duplicateDataObjectsResultTabs.length; i++) {
                const tab = duplicateDataObjectsResultTabs[i];
                if (tab.fetchingResultSet) {
                    if (this.props.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id] !== prevProps.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id]) {
                        if (this.props.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id].success) {
                            duplicateDataObjectsResultTabs[i] = {
                                ...tab,
                                fetchingResultSet: false
                            }
                        }
                        else {
                            duplicateDataObjectsResultTabs[i] = {
                                ...tab,
                                fetchingResultSet: false,
                                error: this.props.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id].message || "Could not able to fetch the result set"
                            }
                        }
                    }
                }
                else if (tab.loadingAllData) {
                    if (this.props.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id] !== prevProps.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id]) {
                        if (this.props.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id].success) {
                            setTimeout(() => {
                                this.onLoadAllRecords(tab.id);
                            }, 200);
                        }
                        else {
                            duplicateDataObjectsResultTabs[i] = {
                                ...tab,
                                loadingAllData: false,
                                error: this.props.getDuplicateDataObjectsResultSetNextPageResult.resultSet[tab.id].message || "Could not able to fetch the result set"
                            }
                        }
                    }
                }
            }
            this.setState({
                duplicateDataObjectsResultTabs
            });
        }
    }

    onRestartJobResultUpdate = (prevProps) => {
        if (this.props.restartJobResult && this.props.restartJobResult !== prevProps.restartJobResult) {
            if (!this.props.restartJobResult.success) {
                showError(this.props.restartJobResult.message || "Could not able to restart job at this moment.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onCancelJobResultUpdate = (prevProps) => {
        if (this.props.cancelJobResult && this.props.cancelJobResult !== prevProps.cancelJobResult) {
            if (!this.props.cancelJobResult.success) {
                showError("Could not able to cancel job at this moment.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onRunDeDuplicateJobResultChanged = (prevProps) => {
        if (this.props.runDeDuplicateJobResult && this.props.runDeDuplicateJobResult !== prevProps.runDeDuplicateJobResult) {
            if (!this.props.runDeDuplicateJobResult.success) {
                showError("Could not able to create deduplicate job at this moment.");
            }
            else {
                this.setState({
                    showDeduplicateJobCreatedResult: true
                });
            }
            this.setState({
                creatingDeduplicationJob: false,
                busyMessage: ""
            });
        }
    }

    onRemoveSurvivalRecordNominationResultChanged = (prevProps) => {
        if (this.props.removeSurvivalRecordNominationResult && this.props.removeSurvivalRecordNominationResult !== prevProps.removeSurvivalRecordNominationResult) {
            if (!this.props.removeSurvivalRecordNominationResult.success) {
                showError("Could not able to remove nomination at the moment.");
            }
        }
    }

    onResultTabChanged = (activeKey) => {
        let location = this.props.history.location;
        this.setState({
            activeResultTab: activeKey
        });
        if (activeKey === "SEARCH_CONFIGURATIONS") {
            this.props.history.push(location.pathname);
            this.props.setMainColumnSpan(18);
            this.setState({
                showSidePanelTabs: false,
                resultListSpan: 24
            })
        }
        else {
            const existingTab = this.state.duplicateDataObjectsResultTabs.find(tab => tab.id === activeKey);
            this.props.history.push(`#${existingTab.job.jobId}`);
            this.props.setMainColumnSpan(24);
            this.setState({
                showSidePanelTabs: true,
                resultListSpan: 18,
                summaryJob: existingTab.job
            });
            if (existingTab.selectedDataObject) {
                this.getSelectedDataObject(existingTab.selectedDataObject);
            }
            else {
                this.setState({
                    showDataObjectFormContainer: false
                });
            }
        }
    }

    onSidePanelActiveTabChanged = (activeKey) => {
        this.setState({
            sidePanelActiveTab: activeKey
        });
    }

    search = (searchInput) => {
        if (searchInput.dataTags && searchInput.dataTags.length > 0) {
            this.props.searchDuplicateDataObjects(searchInput.name, searchInput.description, searchInput.businessArea, searchInput.schema, searchInput.dataSet, searchInput.dataTags);
            this.setState({
                activeResultTab: "SEARCH_CONFIGURATIONS",
                busyMessage: "Submitting search duplicate data objects request...",
                submittingSearchDuplicateDataObjectRequest: true,
                showSidePanelTabs: false,
                resultListSpan: 24,
                summaryJob: null,
                showDataObjectFormContainer: false
            });
            this.props.setMainColumnSpan(18);
        }
        this.setState({
            searchInput
        })
    }

    onBusinessAreaChange = (businessArea) => {
        this.setState({
            loadingSchemaList: true,
            loadingDataTags: true
        });
        if (businessArea === "all") {
            if (this.props.userBusinessAreaDataTags && this.props.userBusinessAreaDataTags.Items && this.props.userBusinessAreaDataTags.Items.length > 0) {
                let dataTags = _.uniqBy(this.props.userBusinessAreaDataTags.Items, 'tagName');
                this.setState({
                    dataTags,
                    loadingDataTags: false
                });
            }
            else {
                this.props.getUserBusinessAreaDataTags();
            }
        }
        else {
            this.props.getDataTagsByBusinessAreaId(businessArea);
        }
        this.props.getSchemaModels(businessArea);
    }

    onSchemaChange = (businessArea, schema) => {
        this.setState({
            loadingDataSetList: true
        })
        this.props.getDataSetBySchemaId(businessArea, schema);
    }

    selectConfigurationJobInHash = () => {
        if (this.props.history.location.hash) {
            let hashValue = decodeURIComponent(this.props.history.location.hash);
            if (this.props.dataObjectsDuplicateJobsResult.Items && this.props.dataObjectsDuplicateJobsResult.Items.length > 0) {
                let selectedConfigurationId = hashValue.slice(1);
                if (selectedConfigurationId) {
                    let selectedConfigurationJob = this.props.dataObjectsDuplicateJobsResult.Items.find(job => job.jobId === selectedConfigurationId);
                    if (selectedConfigurationJob) {
                        this.onViewDuplicateDataObjectResultSet(selectedConfigurationJob);
                        return;
                    }
                }
            }
            this.props.history.push(this.props.history.location.pathname);
        }
    }

    onViewDuplicateDataObjectResultSet = (job) => {
        this.props.history.push(`#${job.jobId}`);
        const existingTab = this.state.duplicateDataObjectsResultTabs.find(tab => tab.id === job.jobId);
        if (existingTab) {
            this.setState({
                activeResultTab: existingTab.id
            });
        }
        else {
            const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
            duplicateDataObjectsResultTabs.push({
                name: job.name,
                id: job.jobId,
                job: job,
                dataTags: job.jobInfo.dataTags,
                fetchingResultSet: true,
                error: null
            })
            this.setState({
                duplicateDataObjectsResultTabs,
                activeResultTab: job.jobId
            });
            this.props.getDuplicateDataObjectsResultSet(job.jobId);
        }
        this.props.setMainColumnSpan(24);
        this.setState({
            showSidePanelTabs: true,
            resultListSpan: 18,
            summaryJob: job
        })
    }

    restartJob = (job) => {
        this.setState({
            restartedJob: job,
            showJobRestartConfirmation: true,
            jobRestartConfirmMessage: `Are you sure, you want to run '${job.name}' search config job again?`
        })
    }

    onRestartJobConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        this.props.restartJob(this.state.restartedJob.jobId, this.state.restartedJob.version, true, false);
        this.onRestartJobCancel();
    }

    onRestartJobCancel = () => {
        this.setState({
            restartedJob: null,
            showJobRestartConfirmation: false,
            jobRestartConfirmMessage: ""
        })
    }

    cancelJob = (jobId, version) => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        this.props.cancelJob(jobId, version);
    }

    deleteJob = (job) => {
        this.setState({
            deletedJob: job,
            showJobDeleteConfirmation: true,
            deleteJobConfirmationMessage: `Are you sure, you want to delete '${job.name}' search config?`
        })
    }

    onDeleteJobConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        this.props.restartJob(this.state.deletedJob.jobId, this.state.deletedJob.version, true, true);
        this.onDeleteJobCancel();
    }

    onDeleteJobCancel = () => {
        this.setState({
            deletedJob: null,
            showJobDeleteConfirmation: false,
            deleteJobConfirmationMessage: ""
        })
    }

    onDuplicateDataObjectResultSetTabEdit = (targetKey, action) => {
        const existingTabIndex = this.state.duplicateDataObjectsResultTabs.findIndex(tab => tab.id === targetKey);
        if (existingTabIndex > -1) {
            const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
            duplicateDataObjectsResultTabs.splice(existingTabIndex, 1);
            this.setState({
                duplicateDataObjectsResultTabs,
                activeResultTab: "SEARCH_CONFIGURATIONS"
            });
            this.props.setMainColumnSpan(18);
            this.setState({
                showSidePanelTabs: false,
                resultListSpan: 24,
                summaryJob: null,
                showDataObjectFormContainer: false
            })
        }
    }

    onDataObjectSelected = (dataObject) => {
        const existingTabIndex = this.state.duplicateDataObjectsResultTabs.findIndex(tab => tab.id === this.state.activeResultTab);
        if (existingTabIndex > -1) {
            const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
            duplicateDataObjectsResultTabs[existingTabIndex] = {
                ...duplicateDataObjectsResultTabs[existingTabIndex],
                selectedDataObject: dataObject
            }
            this.setState({
                duplicateDataObjectsResultTabs
            })
            this.getSelectedDataObject(dataObject);
        }
    }

    getSelectedDataObject = (dataObject) => {
        this.setState({
            showDataObjectFormContainer: true
        })
        this.props.getSchemaModel(dataObject._businessAreaId, dataObject._schemaId);
        this.props.getDataObject(dataObject._businessAreaId, dataObject._schemaId, dataObject._dataSetId, dataObject._dataObjectId);
        this.props.getDeDuplicatedRecords(dataObject._dataObjectId);
        this.setState({
            schemaPermissionDenied: false,
            dataObjectPermissionDenied: false,
            fetchingSchemaModel: true,
            fetchingDataObject: true,
            fetchingDeDuplicatedRecords: true
        })
    }

    onDuplicateDataObjectsResultSetPageSelect = (pagination, jobId) => {
        if (this.props.duplicateDataObjectsResultSet.resultSet[jobId].pageKey && (pagination.current * pagination.pageSize) >= this.props.duplicateDataObjectsResultSet.resultSet[jobId].Items.length) {
            const existingTabIndex = this.state.duplicateDataObjectsResultTabs.findIndex(tab => tab.id === jobId);
            if (existingTabIndex > -1 && !this.state.duplicateDataObjectsResultTabs[existingTabIndex].loadingAllData) {
                const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
                duplicateDataObjectsResultTabs[existingTabIndex] = {
                    ...duplicateDataObjectsResultTabs[existingTabIndex],
                    fetchingResultSet: true,
                    error: null
                }
                this.setState({
                    duplicateDataObjectsResultTabs
                });
                this.props.getDuplicateDataObjectsResultSetNextPage(jobId, this.props.duplicateDataObjectsResultSet.resultSet[jobId].pageKey);
            }
        }
    }

    onLoadAllRecords = (jobId) => {
        if (this.props.duplicateDataObjectsResultSet && this.props.duplicateDataObjectsResultSet.resultSet[jobId]) {
            const existingTabIndex = this.state.duplicateDataObjectsResultTabs.findIndex(tab => tab.id === jobId);
            if (existingTabIndex > -1) {
                const duplicateDataObjectsResultTabs = [...this.state.duplicateDataObjectsResultTabs];
                if (this.props.duplicateDataObjectsResultSet.resultSet[jobId].pageKey) {
                    duplicateDataObjectsResultTabs[existingTabIndex] = {
                        ...duplicateDataObjectsResultTabs[existingTabIndex],
                        loadingAllData: true,
                        error: null
                    }
                    this.setState({
                        duplicateDataObjectsResultTabs
                    });
                    this.props.getDuplicateDataObjectsResultSetNextPage(jobId, this.props.duplicateDataObjectsResultSet.resultSet[jobId].pageKey);
                }
                else {
                    duplicateDataObjectsResultTabs[existingTabIndex] = {
                        ...duplicateDataObjectsResultTabs[existingTabIndex],
                        loadingAllData: false,
                        error: null
                    }
                    this.setState({
                        duplicateDataObjectsResultTabs
                    });
                }
            }
        }
    }

    onNominateSurvivor = (dataObject, deduplicationOption) => {
        this.props.nominateSurvivalRecord(dataObject._duplicateSetId, dataObject._duplicateSetVersion, dataObject._groupId, dataObject._dataObjectId, deduplicationOption);
    }

    onRemoveSurvivorNomination = (dataObject) => {
        this.props.removeSurvivalRecordNomination(dataObject._duplicateSetId, dataObject._duplicateSetVersion, dataObject._groupId, dataObject._dataObjectId);
    }

    onPreviewClick = (dataObject) => {
        this.setState({
            nominatedPreviewDuplicateSetId: dataObject._duplicateSetId,
            nominatedPreviewDuplicateSetVersion: dataObject._duplicateSetVersion,
            nominatedPreviewDuplicateSetGroupId: dataObject._groupId,
        })
    }

    onPreviewClose = () => {
        this.setState({
            nominatedPreviewDuplicateSetId: null,
            nominatedPreviewDuplicateSetVersion: null,
            nominatedPreviewDuplicateSetGroupId: null,
        })
    }

    onMergeRulesClick = (dataObject, dataObjectGroup) => {
        this.setState({
            showMergeRules: true,
            mergeRulesProps: {
                businessAreaId: dataObject._businessAreaId,
                schemaId: dataObject._schemaId,
                duplicateSetId: dataObject._duplicateSetId,
                duplicateSetVersion: dataObject._duplicateSetVersion,
                groupId: dataObject._groupId,
                dataObjectId: dataObject._dataObjectId,
                dataObjectGroup
            }
        })
    }

    onMergeRulesClose = () => {
        this.setState({
            showMergeRules: false,
            mergeRulesProps: {},
        })
    }

    onShowSummary = (job) => {
        this.props.setMainColumnSpan(24);
        this.setState({
            resultListSpan: 18,
            showDataObjectFormContainer: false,
            summaryJob: job
        })
    }

    goToJobs = () => {
        this.props.history.push(AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase()).replace(":monitorItem", "jobs"));
    }

    closeDeduplicateJobCreatedResult = () => {
        this.setState({
            showDeduplicateJobCreatedResult: false
        });
    }

    runDeduplicateJob = (duplicateSetId) => {
        this.setState({
            creatingDeduplicationJob: true
        });
        this.props.runDeDuplicateJob(duplicateSetId);
    }

    isBusy = () => {
        return this.state.fetchingPermission || this.state.fetchingDataObjectsDuplicateJobs || this.state.submittingSearchDuplicateDataObjectRequest || this.state.creatingDeduplicationJob || this.state.busy;
    }

    render() {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        return <>
            {this.state.nominatedPreviewDuplicateSetId &&
                <DataObjectDuplicateMergePreview
                    duplicateSetId={this.state.nominatedPreviewDuplicateSetId}
                    duplicateSetVersion={this.state.nominatedPreviewDuplicateSetVersion}
                    groupId={this.state.nominatedPreviewDuplicateSetGroupId}
                    onClose={this.onPreviewClose} >
                </DataObjectDuplicateMergePreview>
            }
            {
                this.state.showMergeRules &&
                <DataObjectDuplicateMergeRules
                    onClose={this.onMergeRulesClose}
                    {...this.state.mergeRulesProps}>
                </DataObjectDuplicateMergeRules>
            }
            <Row gutter={[8, 8]} wrap={false} className="layout-row">
                <Col span={this.state.resultListSpan}>
                    <LoadingOverlay busy={this.isBusy()} message={this.state.busyMessage || "Please wait..."}></LoadingOverlay>
                    <OkCancelConfirmationBox
                        show={this.state.showJobDeleteConfirmation}
                        message={this.state.deleteJobConfirmationMessage}
                        onCancel={this.onDeleteJobCancel}
                        onOk={this.onDeleteJobConfirmed}>
                    </OkCancelConfirmationBox>
                    <OkCancelConfirmationBox
                        show={this.state.showJobRestartConfirmation}
                        message={this.state.jobRestartConfirmMessage}
                        onCancel={this.onRestartJobCancel}
                        onOk={this.onRestartJobConfirmed}>
                    </OkCancelConfirmationBox>
                    <SuccessResult
                        show={this.state.showDeduplicateJobCreatedResult}
                        title="Deduplication job created!"
                        okText="Go to jobs"
                        cancelText="Close"
                        onOkClick={this.goToJobs}
                        onCancelClick={this.closeDeduplicateJobCreatedResult}>
                    </SuccessResult>
                    <FullHeightContainerLayout
                        showHeader={true}
                        header={<Collapse
                            bordered={false}
                            defaultActiveKey={['1']}>
                            <Panel header="Search Configuration" key="1">
                                <DataObjectSearchDuplicateForm
                                    onSearch={this.search}
                                    businessAreas={this.props.userBusinessAreas}
                                    onBusinessAreaChange={this.onBusinessAreaChange}
                                    loadingSchemaList={this.state.loadingSchemaList}
                                    schemaList={this.props.schemaModelListResult}
                                    onSchemaChange={this.onSchemaChange}
                                    loadingDataSetList={this.state.loadingDataSetList}
                                    dataSetList={this.props.schemaDataSetResult}
                                    loadingDataTags={this.state.loadingDataTags}
                                    dataTags={this.state.dataTags}>
                                </DataObjectSearchDuplicateForm>
                            </Panel>
                        </Collapse>}
                        content={<div className="container-height-100">
                            <Tabs type="editable-card" className="duplicate-search-config-tabs" style={{ height: "100%" }} onChange={this.onResultTabChanged} onEdit={this.onDuplicateDataObjectResultSetTabEdit} activeKey={this.state.activeResultTab} hideAdd={true}>
                                <TabPane tab="Search Configurations" key="SEARCH_CONFIGURATIONS" closable={false}>
                                    <DuplicateDataObjectJobList
                                        jobList={this.props.dataObjectsDuplicateJobsResult.Items}
                                        onViewData={this.onViewDuplicateDataObjectResultSet}
                                        onRestartJob={this.restartJob}
                                        onCancelJob={this.cancelJob}
                                        onDeleteJob={this.deleteJob}>
                                    </DuplicateDataObjectJobList>
                                </TabPane>
                                {
                                    this.state.duplicateDataObjectsResultTabs.map(tab => {
                                        return <TabPane tab={tab.name} key={tab.id} closable={true}>
                                            <LoadingOverlay busy={tab.fetchingResultSet} message={"Getting result set"}></LoadingOverlay>
                                            <DuplicateDataObjectList
                                                job={this.props.dataObjectsDuplicateJobsResult.Items.find(job => job.jobId === tab.id)}
                                                dataTags={tab.dataTags}
                                                dataObjectList={(this.props.duplicateDataObjectsResultSet.resultSet[tab.id] && this.props.duplicateDataObjectsResultSet.resultSet[tab.id].Items) || []}
                                                onDataObjectSelected={this.onDataObjectSelected}
                                                onPageSelect={this.onDuplicateDataObjectsResultSetPageSelect}
                                                onShowSummaryClick={this.onShowSummary}
                                                loadAllRecords={this.onLoadAllRecords}
                                                loadingMore={tab.loadingAllData}
                                                onNominateSurvivor={this.onNominateSurvivor}
                                                onRemoveSurvivorNomination={this.onRemoveSurvivorNomination}
                                                runDeduplicateJob={this.runDeduplicateJob}
                                                onPreviewClick={this.onPreviewClick}
                                                onMergeRulesClick={this.onMergeRulesClick}>
                                            </DuplicateDataObjectList>
                                        </TabPane>
                                    })
                                }
                            </Tabs>
                        </div>}>
                    </FullHeightContainerLayout>
                </Col>
                {
                    this.state.showSidePanelTabs
                        ?
                        <Col span={8}>
                            <Segment className="field-properties-container">
                                <Tabs type="editable-card" style={{ height: "100%" }} activeKey={this.state.sidePanelActiveTab} onChange={this.onSidePanelActiveTabChanged} hideAdd={true}>
                                    <TabPane tab="Dataset Record" key="DATASET_RECORD" closable={false}>
                                        {
                                            this.state.showDataObjectFormContainer
                                                ?
                                                (this.state.fetchingSchemaModel || this.state.fetchingDataObject
                                                    ?
                                                    <LoadingOverlay busy={true} message="Getting dataset record..."></LoadingOverlay>
                                                    :
                                                    (
                                                        this.state.dataObjectPermissionDenied || this.state.schemaPermissionDenied
                                                            ?
                                                            <ProhibitedArea></ProhibitedArea>
                                                            :
                                                            (
                                                                this.props.dataObject ?
                                                                    <BasicFormBuilder
                                                                        key={this.props.dataObject.dataObjectId}
                                                                        formDataObject={this.props.dataObject}
                                                                        schemaFields={this.props.schemaModel.fields}
                                                                        readOnly={true}>
                                                                    </BasicFormBuilder>
                                                                    :
                                                                    <Empty description="No record selected" />
                                                            )
                                                    )
                                                )
                                                :
                                                <Empty description="No record selected" />
                                        }
                                    </TabPane>
                                    <TabPane tab="Summary" key="SUMMARY" closable={false}>
                                        <DataObjectDuplicateSummary key={this.state.summaryJob.jobId} jobId={this.state.summaryJob.jobId} clusterSummary={this.state.summaryJob.runInfo.clusterSummary}></DataObjectDuplicateSummary>
                                    </TabPane>
                                    <TabPane tab="Deduplication History" key="DEDUPLICATION_HISTORY" closable={false}>
                                        {
                                            (this.state.fetchingDeDuplicatedRecords
                                                ?
                                                <LoadingOverlay busy={true} message="Getting records..."></LoadingOverlay>
                                                :
                                                <DeduplicatedRecordsTable items={this.props.deDuplicatedRecordsResult.Items}></DeduplicatedRecordsTable>
                                            )
                                        }
                                    </TabPane>
                                </Tabs>
                            </Segment>
                        </Col>
                        :
                        <></>
                }

            </Row>
        </>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getUserSearchDataObjectsPermisionResult: state.dataObjectSearch.getUserSearchDataObjectsPermisionResult,
        userSearchDataObjectsPermision: state.dataObjectSearch.userSearchDataObjectsPermision,
        userBusinessAreas: state.home.businessAreas,
        getSchemaModelsResult: state.schemaModels.getSchemaModelsResult,
        schemaModelListResult: state.schemaModels.schemaModelListResult,
        getSchemaDataSetResult: state.dataSets.getSchemaDataSetResult,
        schemaDataSetResult: state.dataSets.schemaDataSetResult,
        getDataObjectResult: state.dataObjects.getDataObjectResult,
        dataObject: state.dataObjects.dataObject,
        getSchemaModelResult: state.schemaModels.getSchemaModelResult,
        schemaModel: state.schemaModels.schemaModel,
        getDataTagsByBusinessAreaIdResult: state.dataTags.getDataTagsByBusinessAreaIdResult,
        dataTagsByBusinessAreaIdResult: state.dataTags.dataTagsByBusinessAreaIdResult,
        getUserBusinessAreaDataTagsResult: state.dataTags.getUserBusinessAreaDataTagsResult,
        userBusinessAreaDataTags: state.dataTags.userBusinessAreaDataTags,
        getDataObjectsDuplicateJobsResult: state.dataObjectDuplicateSearch.getDataObjectsDuplicateJobsResult,
        dataObjectsDuplicateJobsResult: state.dataObjectDuplicateSearch.dataObjectsDuplicateJobsResult,
        searchDuplicateDataObjectJobsResult: state.dataObjectDuplicateSearch.searchDuplicateDataObjectJobsResult,
        getDuplicateDataObjectsResultSetResult: state.dataObjectDuplicateSearch.getDuplicateDataObjectsResultSetResult,
        duplicateDataObjectsResultSet: state.dataObjectDuplicateSearch.duplicateDataObjectsResultSet,
        getDuplicateDataObjectsResultSetNextPageResult: state.dataObjectDuplicateSearch.getDuplicateDataObjectsResultSetNextPageResult,
        restartJobResult: state.jobs.restartJobResult,
        cancelJobResult: state.jobs.cancelJobResult,
        runDeDuplicateJobResult: state.dataObjectDuplicateSearch.runDeDuplicateJobResult,
        getDeDuplicatedRecordsRequestResult: state.dataObjectDuplicateSearch.getDeDuplicatedRecordsRequestResult,
        deDuplicatedRecordsResult: state.dataObjectDuplicateSearch.deDuplicatedRecordsResult,
        removeSurvivalRecordNominationResult: state.dataObjectDuplicateSearch.removeSurvivalRecordNominationResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSearchDataObjectsPermission: () => dispatch(actions.getUserSearchDataObjectsPermissionRequest()),
        getDataObjectsDuplicateJobs: () => dispatch(actions.getDataObjectsDuplicateJobsRequest()),
        getDuplicateDataObjectsResultSet: (jobId) => dispatch(actions.getDuplicateDataObjectsResultSetRequest(jobId)),
        getDuplicateDataObjectsResultSetNextPage: (jobId, pageKey) => dispatch(actions.getDuplicateDataObjectsResultSetNextPageRequest(jobId, pageKey)),
        restartJob: (jobId, version, carryRunInfo, undo) => dispatch(actions.restartJobRequest(jobId, version, carryRunInfo, undo)),
        cancelJob: (jobId, version) => dispatch(actions.cancelJobRequest(jobId, version)),
        searchDuplicateDataObjects: (name, description, businessAreaId, schemaId, dataSetId, dataTags) => dispatch(actions.searchDuplicateDataObjectsRequest(name, description, businessAreaId, schemaId, dataSetId, dataTags)),
        getSchemaModels: (businessAreaId) => dispatch(actions.getSchemaModelsRequest(businessAreaId)),
        getDataSetBySchemaId: (businessAreaId, schemaId) => dispatch(actions.getDataSetBySchemaIdRequest(businessAreaId, schemaId)),
        getSchemaModel: (businessAreaId, schemaId) => dispatch(actions.getSchemaModelRequest(businessAreaId, schemaId)),
        getDataObject: (businessAreaId, schemaId, dataSetId, dataObjectId) => dispatch(actions.getDataObjectRequest(businessAreaId, schemaId, dataSetId, dataObjectId)),
        getDataTagsByBusinessAreaId: (businessAreaId) => dispatch(actions.getDataTagsByBusinessAreaIdRequest(businessAreaId)),
        getUserBusinessAreaDataTags: () => dispatch(actions.getUserBusinessAreaDataTagsRequest()),
        nominateSurvivalRecord: (duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption) => dispatch(actions.nominateSurvivalRecordRequest(duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption)),
        removeSurvivalRecordNomination: (duplicateSetId, duplicateSetVersion, groupId, dataObjectId) => dispatch(actions.removeSurvivalRecordNominationRequest(duplicateSetId, duplicateSetVersion, groupId, dataObjectId)),
        runDeDuplicateJob: (duplicateSetId) => dispatch(actions.runDeDuplicateJobRequest(duplicateSetId)),
        getDeDuplicatedRecords: (survivedRecordId) => dispatch(actions.getDeDuplicatedRecordsRequest(survivedRecordId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectDuplicateSearch));