import {
    GET_AGGREGATIONS_SUCCESS,
    GET_AGGREGATIONS_FAILURE,

    GET_RECENT_LOGGED_IN_USERS_SUCCESS,
    GET_RECENT_LOGGED_IN_USERS_FAILURE,

    GET_RUNNING_JOBS_SUCCESS,
    GET_RUNNING_JOBS_FAILURE,

    GET_FAILED_JOBS_SUCCESS,
    GET_FAILED_JOBS_FAILURE
} from "./actions";

import {
    JOB_EXECUTION_PROGRESS,
    JOB_DELETED
} from '../jobs/actions';

const initialState = {
    getAggregationsResult: {
        success: true
    },
    aggregations: null,
    getRecentLoggedInUsersResult: {
        success: true
    },
    recentLoggedInUsersResult: {
        Items: []
    },
    getRunningJobsResult: {
        success: true
    },
    runningJobsResult: {
        Items: []
    },
    getFailedJobsResult: {
        success: true
    },
    failedJobsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    let runningJobsResult = {
        Items: []
    };
    let failedJobsResult = {
        Items: []
    };
    switch (action.type) {
        case GET_AGGREGATIONS_SUCCESS:
            return { ...state, getAggregationsResult: { success: true }, aggregations: action.payload.result };
        case GET_AGGREGATIONS_FAILURE:
            return { ...state, getAggregationsResult: { success: false, ...action.payload.error }, aggregations: null };
        case GET_RECENT_LOGGED_IN_USERS_SUCCESS:
            return { ...state, getRecentLoggedInUsersResult: { success: true }, recentLoggedInUsersResult: action.payload.result };
        case GET_RECENT_LOGGED_IN_USERS_FAILURE:
            return { ...state, getRecentLoggedInUsersResult: { success: false, ...action.payload.error }, recentLoggedInUsersResult: { Items: [] } };
        case GET_RUNNING_JOBS_SUCCESS:
            return { ...state, getRunningJobsResult: { success: true }, runningJobsResult: action.payload.result };
        case GET_RUNNING_JOBS_FAILURE:
            return { ...state, getRunningJobsResult: { success: false, ...action.payload.error }, runningJobsResult: { Items: [] } };
        case GET_FAILED_JOBS_SUCCESS:
            return { ...state, getFailedJobsResult: { success: true }, failedJobsResult: action.payload.result };
        case GET_FAILED_JOBS_FAILURE:
            return { ...state, getFailedJobsResult: { success: false, ...action.payload.error }, failedJobsResult: { Items: [] } };
        case JOB_EXECUTION_PROGRESS:
            runningJobsResult = { ...state.runningJobsResult };
            runningJobsResult.Items = [...runningJobsResult.Items];
            failedJobsResult = { ...state.failedJobsResult };
            failedJobsResult.Items = [...failedJobsResult.Items];
            let updatedJobIndex = runningJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (updatedJobIndex > -1) {
                if (action.payload.message.status !== "RUNNING") {
                    if (action.payload.message.status === "FAILED") {
                        failedJobsResult.Items.splice(0, 0, { ...runningJobsResult.Items[updatedJobIndex], ...action.payload.message });
                    }
                    runningJobsResult.Items.splice(updatedJobIndex, 1);
                }
                else {
                    runningJobsResult.Items[updatedJobIndex] = { ...runningJobsResult.Items[updatedJobIndex], ...action.payload.message };
                }
            }
            else if (action.payload.message.status === "RUNNING") {
                runningJobsResult.Items.splice(0, 0, action.payload.message);
            }
            return { ...state, runningJobsResult, failedJobsResult };
        case JOB_DELETED:
            runningJobsResult = { ...state.runningJobsResult };
            runningJobsResult.Items = [...runningJobsResult.Items];
            failedJobsResult = { ...state.failedJobsResult };
            failedJobsResult.Items = [...failedJobsResult.Items];
            let deletedJobIndex = runningJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (deletedJobIndex > -1) {
                runningJobsResult.Items.splice(deletedJobIndex, 1);
            }
            deletedJobIndex = failedJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (deletedJobIndex > -1) {
                failedJobsResult.Items.splice(deletedJobIndex, 1);
            }
            return { ...state, runningJobsResult, failedJobsResult };
        default:
            return state;
    }
};
