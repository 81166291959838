import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { AppClientList, LoadingOverlay, ProhibitedArea, ManageAppClient, AppClientSecretModal, OkCancelConfirmationBox } from "components";
import { AppClientAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col } from "antd";

class AppClients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            permissionDenied: false,
            fetchingPermission: true
        }
    }

    componentWillMount() {
        this.props.getAppClientPermission();
        this.showBreadCrumbNavigationItems();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting app clients...",
                fetchingAppClients: true
            });
            this.props.getAppClients();
        }
        else {
            if (this.props.clientId) {
                this.props.getAppClient(this.props.clientId);
            }
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting app client...",
                    fetchingAppClient: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingAppClient: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.onGetAppClientsResultUpdate(prevProps);

        this.onCreateAppClientResultUpdate(prevProps);

        this.onGetAppClientResultUpdate(prevProps);

        this.onUpdateAppClientResultUpdate(prevProps);

        this.onDeleteAppClientResultUpdate(prevProps);

        this.onRegenerateAppClientSecretResultUpdate(prevProps);

        this.onGetAppClientPermissionResultUpdate(prevProps);
    }

    onGetAppClientsResultUpdate = (prevProps) => {
        if (this.props.getAppClientsResult && this.props.getAppClientsResult !== prevProps.getAppClientsResult) {
            if (!this.props.getAppClientsResult.success) {
                if (this.props.getAppClientsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get app clients at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingAppClients: false
            });
        }
    }

    onCreateAppClientResultUpdate = (prevProps) => {
        if (this.props.createAppClientResult && this.props.createAppClientResult !== prevProps.createAppClientResult) {
            if (!this.props.createAppClientResult.success) {
                showError(this.props.createAppClientResult.message || "App client cannot be created at this moment.");
                this.setState({
                    busy: false,
                    manageAppClientErrors: this.props.createAppClientResult.fields
                });
            }
            else {
                showSuccess("App client created successfully.");
                this.setState({
                    busy: false,
                    busyMessage: "",
                    showAppClientSecretModal: true
                });
            }
        }
    }

    onGetAppClientResultUpdate = (prevProps) => {
        if (this.props.getAppClientResult && this.props.getAppClientResult !== prevProps.getAppClientResult) {
            if (!this.props.getAppClientResult.success) {
                if (this.props.getAppClientResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get app client.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingAppClient: false
            });
            if (this.props.appClientListResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateAppClientResultUpdate = (prevProps) => {
        if (this.props.updateAppClientResult && this.props.updateAppClientResult !== prevProps.updateAppClientResult) {
            if (!this.props.updateAppClientResult.success) {
                if (this.props.updateAppClientResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update app client.");
                }
            }
            else {
                if (this.state.isActivateDeactivateAppClient) {
                    showSuccess(`App client ${this.state.appClientActive ? "activated" : "deactivated"} successfully.`);
                    this.setState({
                        busy: false,
                        isActivateDeactivateAppClient: false,
                        appClientActive: false
                    });
                }
                else {
                    this.setState({
                        busyMessage: "App client updated. Please wait..."
                    });
                    showSuccess("App client updated successfully.", () => {
                        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                    });
                }
            }
        }
    }

    onDeleteAppClientResultUpdate = (prevProps) => {
        if (this.props.deleteAppClientResult && this.props.deleteAppClientResult !== prevProps.deleteAppClientResult) {
            if (!this.props.deleteAppClientResult.success) {
                showError(this.props.deleteAppClientResult.message || "App credentials cannot be deleted.");
            }
            else {
                showSuccess("App credentials deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onRegenerateAppClientSecretResultUpdate = (prevProps) => {
        if (this.props.regenerateAppClientSecretResult && this.props.regenerateAppClientSecretResult !== prevProps.regenerateAppClientSecretResult) {
            if (!this.props.regenerateAppClientSecretResult.success) {
                showError(this.props.regenerateAppClientSecretResult.message || "Secret cannot be regenerated.");
            }
            else {
                showSuccess("Secret regenerated successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onGetAppClientPermissionResultUpdate = (prevProps) => {
        if (this.props.getAppClientPermissionResult && this.props.getAppClientPermissionResult !== prevProps.getAppClientPermissionResult) {
            if (!this.props.getAppClientPermissionResult.success || !this.props.permission || !this.props.permission.canView) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Credentials"
                }
            ];
            if (this.props.action) {
                let clientName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New App Client"
                        });
                    case "view":
                    case "edit":
                        clientName = this.getAppClientName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: clientName
                        }]);
                        break;
                    case "history":
                        clientName = this.getAppClientName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: clientName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getAppClientName = () => {
        let clientName = this.props.clientId;
        if (this.props.appClientListResult.Items && this.props.appClientListResult.Items.length > 0) {
            let appClient = this.props.appClientListResult.Items.find(item => item.clientId === this.props.clientId);
            if (appClient) {
                clientName = appClient.clientName;
            }
        }
        else if (this.props.appClient && this.props.appClient.clientId === this.props.clientId) {
            clientName = this.props.appClient.clientName;
        }
        return clientName;
    }

    showAddAppClient = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    showEditAppClient = (clientId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", clientId)
            .replace(":itemAction", "edit"));
    }

    showViewAppClient = (clientId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", clientId)
            .replace(":itemAction", "view"));
    }

    showAppClientHistory = (clientId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", clientId)
            .replace(":itemAction", "history"));
    }

    cancelCreateAppClient = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    createAppClient = (appClient) => {
        this.setState({
            busy: true,
            busyMessage: "Creating app client..."
        });
        this.props.createAppClient(appClient);
    }

    updateAppClient = (clientId, updatedAppClient) => {
        if (_.isEmpty(updatedAppClient) === false) {
            this.setState({
                busy: true,
                busyMessage: "Updating app client..."
            });
            this.props.updateAppClient(clientId, updatedAppClient);
        }
        else {
            this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
        }
    }

    onActivateDeactivateAppClient = (appClient, active) => {
        this.setState({
            isActivateDeactivateAppClient: true,
            appClientActive: active,
            busy: true,
            busyMessage: active ? "Activating app client..." : "Deactivating app client..."
        });
        this.props.updateAppClient(appClient.clientId, { version: appClient.version, active });
    }

    onAppClientValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageAppClientErrors) === false) {
            let manageAppClientErrors = { ...this.state.manageAppClientErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageAppClientErrors[key];
            }
            this.setState({
                manageAppClientErrors
            })
        }
    }

    onAppClientSecretModalClosed = () => {
        this.setState({
            showAppClientSecretModal: false
        },
            () => {
                this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                    .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
            });
    }

    showDeleteAppClientConfirmation = (appClient) => {
        this.setState({
            deletedAppClient: appClient,
            showAppClientDeleteConfirmation: true,
            deleteAppClientConfirmMessage: `Are you sure, you want to delete '${appClient.clientName}' credentials?`
        })
    }

    onDeleteAppClientConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting app credentials..."
        });
        this.props.deleteAppClient(this.state.deletedAppClient.clientId);
        this.onDeleteAppClientCancel();
    }

    onDeleteAppClientCancel = () => {
        this.setState({
            deletedAppClient: null,
            showAppClientDeleteConfirmation: false,
            deleteAppClientConfirmMessage: ""
        })
    }

    showRegenerateSecretConfirmation = (appClient) => {
        this.setState({
            regenerateAppClient: appClient,
            showRegenerateSecretConfirmation: true,
            regenerateSecretConfirmationMessage: `Are you sure, you want to regenerate '${appClient.clientName}' secret?`
        })
    }

    onRegenerateSecretConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Regenerating secret..."
        });
        this.props.regenerateAppClientSecret(this.state.regenerateAppClient.clientId, this.state.regenerateAppClient.version);
        this.onRegenerateSecretCancel();
    }

    onRegenerateSecretCancel = () => {
        this.setState({
            regenerateAppClient: null,
            showRegenerateSecretConfirmation: false,
            regenerateSecretConfirmationMessage: ""
        })
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingAppClients || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingAppClient;
        }
        return isBusy;
    }

    getAppClientComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return (
                <>
                    <OkCancelConfirmationBox
                        show={this.state.showAppClientDeleteConfirmation}
                        message={this.state.deleteAppClientConfirmMessage}
                        onCancel={this.onDeleteAppClientCancel}
                        onOk={this.onDeleteAppClientConfirmed}>
                    </OkCancelConfirmationBox>
                    {
                        !this.state.fetchingAppClients
                            ?
                            <AppClientList
                                permission={this.props.permission}
                                appClientList={this.props.appClientListResult.Items}
                                onAddAppClient={this.showAddAppClient}
                                onViewAppClient={this.showViewAppClient}
                                onEditAppClient={this.showEditAppClient}
                                onDeleteAppClient={this.showDeleteAppClientConfirmation}
                                onActivateDeactivateAppClient={this.onActivateDeactivateAppClient}
                                onViewAppClientHistory={this.showAppClientHistory}
                            />
                            :
                            <></>
                    }
                </>
            );
        }
        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageAppClient
                    permission={this.props.permission}
                    action={this.props.action}
                    onSave={this.createAppClient}
                    onCancel={this.cancelCreateAppClient}
                    formErrors={this.state.manageAppClientErrors}
                    onValuesChanged={this.onAppClientValuesChanged}>
                </ManageAppClient>;
            case "view":
            case "edit":
                return !this.state.fetchingAppClient
                    ?
                    (this.props.appClient
                        ?
                        <>
                            <OkCancelConfirmationBox
                                show={this.state.showRegenerateSecretConfirmation}
                                message={this.state.regenerateSecretConfirmationMessage}
                                onCancel={this.onRegenerateSecretCancel}
                                onOk={this.onRegenerateSecretConfirmed}>
                            </OkCancelConfirmationBox>
                            <ManageAppClient
                                permission={this.props.permission}
                                action={this.props.action}
                                onSave={this.updateAppClient}
                                onCancel={this.cancelCreateAppClient}
                                onRegenerateAppClientSecret={this.showRegenerateSecretConfirmation}
                                appClient={this.props.appClient}
                                formErrors={this.state.manageAppClientErrors}
                                onValuesChanged={this.onAppClientValuesChanged}>
                            </ManageAppClient>
                        </>
                        :
                        (this.props.getAppClientResult.success
                            ?
                            <Message floating size='big'>
                                <Message.Header>App client not found.</Message.Header>
                                <p>The app you are looking for does not exist.</p>
                            </Message>
                            :
                            <></>
                        )
                    )
                    :
                    <></>;
            case "history":
                return this.state.showHistory ? <AppClientAudit appClient={this.props.appClient} onClose={this.cancelCreateAppClient}></AppClientAudit> : <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    {
                        this.state.showAppClientSecretModal
                            ?
                            <AppClientSecretModal
                                show={this.state.showAppClientSecretModal}
                                clientId={this.props.createdAppClient.clientId}
                                secret={this.props.createdAppClient.clientSecret}
                                onClose={this.onAppClientSecretModalClosed}>
                            </AppClientSecretModal>
                            :
                            <></>
                    }
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.state.fetchingPermission ? this.getAppClientComponent() : <></>}
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        appClientListResult: state.appClients.appClientListResult,
        getAppClientsResult: state.appClients.getAppClientsResult,
        createAppClientResult: state.appClients.createAppClientResult,
        getAppClientResult: state.appClients.getAppClientResult,
        appClient: state.appClients.appClient,
        createdAppClient: state.appClients.createdAppClient,
        updateAppClientResult: state.appClients.updateAppClientResult,
        deleteAppClientResult: state.appClients.deleteAppClientResult,
        regenerateAppClientSecretResult: state.appClients.regenerateAppClientSecretResult,
        getAppClientPermissionResult: state.appClients.getAppClientPermissionResult,
        permission: state.appClients.userAppClientPermission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAppClientPermission: () => dispatch(actions.getAppClientPermissionRequest()),
        getAppClients: () => dispatch(actions.getAppClientsRequest()),
        createAppClient: (appClient) => dispatch(actions.createAppClientRequest(appClient)),
        getAppClient: (clientId) => dispatch(actions.getAppClientRequest(clientId)),
        updateAppClient: (clientId, updatedAppClient) => dispatch(actions.updateAppClientRequest(clientId, updatedAppClient)),
        deleteAppClient: (clientId) => dispatch(actions.deleteAppClientRequest(clientId)),
        regenerateAppClientSecret: (clientId, version) => dispatch(actions.regenerateAppClientSecretRequest(clientId, version))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppClients));