import React, { useState } from "react";
import { Tooltip, Space, Button, Table, Progress } from "antd";
import { WarningTwoTone, CheckCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";
import numeral from "numeral";

const { Column } = Table;

const DataSetRecentList = ({
    permission,
    dataSetList,
    onViewDataSet,
    onEditDataSet,
    onDataSetSelected
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onClickRow = (record) => {
        return {
            onClick: () => {
                setSelectedRowKeys([record.dataSetId]);
                onDataSetSelected && onDataSetSelected(record.dataSetId);
            },
            onFocus: () => {
                setSelectedRowKeys([record.dataSetId]);
                onDataSetSelected && onDataSetSelected(record.dataSetId);
            },
        };
    }

    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="dataSetId"
                            dataSource={dataSetList}
                            onRow={onClickRow}
                            rowSelection={{
                                selectedRowKeys,
                                type: 'radio'
                            }}
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            className="container-height-100 schema-list-table">
                            <Column
                                width="4rem"
                                render={(value, dataSet, index) => <div style={{ marginLeft: "-1rem" }}>
                                    <Tooltip title ='Edit Dataset'>
                                    <Icon
                                        disabled={!permission.canEdit}
                                        color='blue'
                                        name='pencil'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onEditDataSet(dataSet);
                                        }} /> </Tooltip>
                                        <Tooltip title ='View Dataset'>
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='eye'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onViewDataSet(dataSet);
                                        }} /> </Tooltip>
                                </div>}
                            />
                            <Column
                                width="10rem"
                                dataIndex={"recordCount"}
                                title={"Size"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => {
                                    let numberOfDigits = Math.max(Math.ceil(Math.log10(value)), 2)
                                    let percentage = (value / Math.pow(10, numberOfDigits)) * 100;
                                    let formatValue = value ? numeral(value).format('0a') : 0;
                                    return <>
                                        <Tooltip placement="topLeft" title={value}><Progress style={{ width: "6rem" }} percent={percentage} size="small" format={percent => formatValue} /></Tooltip>
                                        {dataSet.erroredRecordsCount ?
                                            <Tooltip placement="topLeft" title={`Contains ${dataSet.erroredRecordsCount} error row(s)`}><WarningTwoTone twoToneColor="#FF0000" /></Tooltip>
                                            :
                                            <CheckCircleOutlined style={{ color: "#00cf18" }} />}
                                    </>
                                }}
                            />
                            <Column
                                width="15%"
                                dataIndex={"dataSetName"}
                                title={"DataSet"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="20%"
                                dataIndex={"dataSetDescription"}
                                title={"Description"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="20%"
                                dataIndex={"schemaName"}
                                title={"Parent Schema"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="15%"
                                dataIndex={"businessAreaName"}
                                title={"Business Area"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="15%"
                                dataIndex={"updatedDate"}
                                title={"Last Updated"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => {
                                    let dateValue = moment.utc(value).toDate().toLocaleString()
                                    return <Tooltip placement="topLeft" title={dateValue}>{dateValue}</Tooltip>
                                }}
                            />
                            <Column
                                width="15%"
                                dataIndex={"updatedByEmail"}
                                title={"Updated By"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                        </Table>
                    </div>
                </div>}>
        </FullHeightContainerLayout>
    );
};

export default DataSetRecentList;