import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import {
    Handle,
    Position
} from 'reactflow';
import { DoubleRightOutlined, MinusCircleTwoTone, CaretDownFilled, PlusCircleTwoTone } from '@ant-design/icons';
import 'reactflow/dist/style.css';

const { Text, Link, Title } = Typography;

function LineageDuplicateConfigNode({ data }) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Row style={{
                flexDirection: "row",
                flexWrap: false,
                padding: "0rem 0.5rem",
                width: "100%",
                height: "100%",
                justifyContent: "space-between"
            }}>
                <Row style={{ width: "100%", flexDirection: "column" }}>
                    <Row style={{ justifyContent: "space-between", width: "100%" }}>
                        <Col>
                            <Text style={{ fontSize: "8px" }}>{data.title}</Text>
                        </Col>
                        <Col style={{ marginLeft: "1rem" }}>
                            <Space direction="horizontal" size="small">
                                <Tooltip title={"Actions"}>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    label: 'View',
                                                    key: 'View',
                                                    onClick: () => data.onActionClick("VIEW")
                                                }
                                            ]
                                        }}>
                                        <CaretDownFilled style={{ fontSize: "1.2vh", color: "#0E9FFF" }} />
                                    </Dropdown>
                                </Tooltip>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: "flex", width: "100%" }}>
                            <Tooltip title={data.name}>
                                <Text ellipsis={true} style={{ fontSize: "10px", maxWidth: "80%" }} type="secondary">{data.name}</Text>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col>
                                    <Text style={{ fontSize: "8px" }}>Total records:&nbsp; </Text>
                                </Col>
                                <Col>
                                    <Text style={{ fontSize: "8px" }} type="secondary">{data.totalRecords}</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text style={{ fontSize: "8px" }}>Total clusters:&nbsp; </Text>
                                </Col>
                                <Col>
                                    <Text style={{ fontSize: "8px" }} type="secondary">{data.totalClusters}</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text style={{ fontSize: "8px" }}>Max cluster size:&nbsp; </Text>
                                </Col>
                                <Col>
                                    <Text style={{ fontSize: "8px" }} type="secondary">{data.maxClusterSize}</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text style={{ fontSize: "8px" }}>Total duplicate records:&nbsp; </Text>
                                </Col>
                                <Col>
                                    <Text style={{ fontSize: "8px" }} type="secondary">{data.duplicateRecords}</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <Row style={{ flexDirection: "row", flexWrap: "nowrap", justifyContent: "flex-end", width: "100%", alignItems: "end" }}>
                    {
                        data.dataTags && data.dataTags.length > 0
                            ?
                            <>
                                {
                                    !data.node.isExpanded
                                        ?
                                        <Col style={{ flexWrap: "nowrap" }}>
                                            <Tooltip title="Expand search data tags">
                                                <PlusCircleTwoTone style={{ cursor: "pointer" }} onClick={() => data.onExpand && data.onExpand()} />
                                            </Tooltip>
                                        </Col>
                                        :
                                        <Col style={{ flexWrap: "nowrap" }}>
                                            <Tooltip title="Contract search data tags">
                                                <MinusCircleTwoTone style={{ cursor: "pointer" }} onClick={() => data.onContract && data.onContract()} />
                                            </Tooltip>
                                        </Col>
                                }
                            </>
                            :
                            <></>
                    }
                </Row>
            </Row>
        </>
    );
}

export default LineageDuplicateConfigNode;