import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { DataObjectsPreviewList, LoadingOverlay, DataObjectHistoryList, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';

const { Title, Text } = Typography;

class EventDataObject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: false
        }
    }

    componentWillMount() {
        let headerText = "";
        let headerDetails = `(Dataset Objects: ${this.props.dataSetName})`
        let eventKey = `${this.props.partitionKey}@${this.props.sortKey}`;
        switch (this.props.eventType) {
            case "BATCH_PROCESSING":
                if (this.props.itemOperation) {
                    switch (this.props.itemOperation) {
                        case "INSERT":
                            headerText = "Inserted records"
                            break;
                        case "DELETE":
                            headerText = "Deleted records"
                            break;
                    }
                    this.props.getEventDataObjectList(this.props.eventId, this.props.itemOperation, null, eventKey);
                }
            case "UPDATED_RECORD":
                headerText = "Updated record";
                this.setState({
                    busy: true,
                    busyMessage: "Getting dataobject...",
                });
                this.props.getEventDataObject(this.props.eventId, this.props.dataObjectId, eventKey);
                break;
            case "CREATED_RECORD":
                headerText = "Created record";
                this.setState({
                    busy: true,
                    busyMessage: "Getting dataobject...",
                });
                this.props.getEventDataObject(this.props.eventId, this.props.dataObjectId, eventKey);
                break;
            case "DELETED_RECORD":
            case "DELETED_DUPLICATE_RECORD":
                headerText = "Deleted record";
                this.setState({
                    busy: true,
                    busyMessage: "Getting dataobject...",
                });
                if (this.props.eventType === "DELETED_DUPLICATE_RECORD") {
                    if (this.props.details && this.props.details.duplicateSetId && this.props.details.survivedRecordId) {
                        this.setState({
                            showSurvivalRecord: true
                        });
                    }
                }

                this.props.getEventDataObject(this.props.eventId, this.props.dataObjectId, eventKey);
                break;
        }
        this.setState({
            headerText,
            headerDetails
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventDataObjectListResultUpdate(prevProps);
        this.onGetEventDataObjectResultUpdate(prevProps);
        this.onGetDuplicateDataObjectsResultSetDataObjectResultChanged(prevProps);
    }

    onGetEventDataObjectListResultUpdate = (prevProps) => {
        if (this.props.getEventDataObjectListResult && this.props.getEventDataObjectListResult !== prevProps.getEventDataObjectListResult) {
            if (!this.props.getEventDataObjectListResult.success) {
                if (this.props.getEventDataObjectListResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataobjects at this moment.");
                }
            }
        }
    }

    onGetEventDataObjectResultUpdate = (prevProps) => {
        if (this.props.getEventDataObjectResult && this.props.getEventDataObjectResult !== prevProps.getEventDataObjectResult) {
            if (!this.props.getEventDataObjectResult.success) {
                if (this.props.getEventDataObjectResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataobject at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    onGetDuplicateDataObjectsResultSetDataObjectResultChanged = (prevProps) => {
        if (this.props.getDuplicateDataObjectsResultSetDataObjectResult && this.props.getDuplicateDataObjectsResultSetDataObjectResult !== prevProps.getDuplicateDataObjectsResultSetDataObjectResult) {
            if (!this.props.getDuplicateDataObjectsResultSetDataObjectResult.success) {
                if (this.props.getDuplicateDataObjectsResultSetDataObjectResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get record at this moment.");
                }
            }
            else {
                this.setState({
                    survivedRecordResult: this.props.duplicateResultSetDataObjectResult
                });
            }
        }
    }

    getEventDataObject = (pagination) => {
        if (this.props.dataObjectResult.pageKey &&
            (pagination.current * pagination.pageSize) >= this.props.dataObjectResult.Items.length) {
            this.props.getEventDataObjectList(this.props.eventId, this.props.itemOperation, this.props.dataObjectResult.pageKey);
        }
    }

    onSurvivalRecordOptionClick = () => {
        this.setState({
            survivedRecordResult: {
                inProgress: true
            }
        });
        this.props.getDuplicateDataObjectsResultSetDataObject(
            this.props.details.duplicateSetId,
            this.props.details.duplicateSetGroupId,
            this.props.details.duplicateSetVersion,
            this.props.details.survivedRecordId,
            {
                fetchJob: true
            });
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {this.props.eventType !== "BATCH_PROCESSING" ?
                    <>
                        {
                            this.isBusy()
                                ?
                                <LoadingOverlay
                                    busy={true}
                                    spinner
                                    message={this.state.busyMessage || "Please wait..."}>
                                </LoadingOverlay>
                                :
                                <></>
                        }
                        {
                            !this.state.fetchingData
                                ?
                                <FullHeightContainerLayout
                                    showHeader={true}
                                    showFooter={false}
                                    header={<Title level={5}><Text>Dataset Object Event Revision - </Text><Text type="secondary" italic>{`(Dataset: ${this.props.dataSetName})`}</Text></Title>}
                                    content={
                                        this.props.dataObjectRevision
                                            ?
                                            <DataObjectHistoryList
                                                showChanges={!this.state.showSurvivalRecord}
                                                showSurvivalRecord={this.state.showSurvivalRecord}
                                                dataObjectRevisions={[this.props.dataObjectRevision]}
                                                schemaFields={this.props.dataObjectRevision.schemaFields}
                                                onSurvivalRecordOptionClick={this.onSurvivalRecordOptionClick}
                                                survivedRecordResult={this.state.survivedRecordResult}>
                                            </DataObjectHistoryList>
                                            :
                                            <Empty></Empty>
                                    }>
                                </FullHeightContainerLayout>
                                :
                                <></>
                        }
                    </>
                    :
                    <FullHeightContainerLayout
                        showHeader={true}
                        showFooter={false}
                        header={<Title level={5}><Text>Event Batch - </Text><Text>{this.state.headerText}</Text><Text> - </Text><Text type="secondary">{this.state.headerDetails}</Text></Title>}
                        content={<DataObjectsPreviewList
                            recordCount={this.props.recordCount || 0}
                            schemaFields={this.props.dataObjectResult.schemaFields}
                            dataObjectList={this.props.dataObjectResult.Items && this.props.dataObjectResult.Items.map(item => item.data)}
                            loading={this.props.dataObjectResult.inProgress}
                            onPageSelect={this.getEventDataObject}>
                        </DataObjectsPreviewList>}>
                    </FullHeightContainerLayout>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventDataObjectListResult: state.eventDataObject.getEventDataObjectListResult,
        dataObjectResult: state.eventDataObject.dataObjectResult,
        getEventDataObjectResult: state.eventDataObject.getEventDataObjectResult,
        dataObjectRevision: state.eventDataObject.dataObjectRevision,
        getDuplicateDataObjectsResultSetDataObjectResult: state.dataObjectDuplicateSearch.getDuplicateDataObjectsResultSetDataObjectResult,
        duplicateResultSetDataObjectResult: state.dataObjectDuplicateSearch.duplicateResultSetDataObjectResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventDataObjectList: (eventId, itemOperation, pageKey, eventKey) => dispatch(actions.getEventDataObjectListRequest(eventId, itemOperation, pageKey, eventKey)),
        getEventDataObject: (eventId, dataObjectId, eventKey) => dispatch(actions.getEventDataObjectRequest(eventId, dataObjectId, eventKey)),
        getDuplicateDataObjectsResultSetDataObject: (duplicateSetId, groupId, setVersion, id, options = { fetchJob: true }) => dispatch(actions.getDuplicateDataObjectsResultSetDataObjectRequest(duplicateSetId, groupId, setVersion, id, options))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventDataObject));