
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    MarkerType
} from 'reactflow';
import { DoubleRightOutlined, DoubleLeftOutlined, CaretDownFilled, FullscreenOutlined } from '@ant-design/icons';
import 'reactflow/dist/style.css';
import { LineageNode, LineageDuplicateConfigNode, LineageFieldNode, LineageTagNode } from 'components';

const { Text, Link, Title } = Typography;
const NODE_HEIGHT = {
    "BUSINESS_AREA": 50,
    "DATA_DOMAIN": 50,
    "SCHEMA": 50,
    "DATA_SET": 70,
    "JOB": 140,
}

const NODE_WIDTH = 200;
const DUPLICATE_CONFIG_NODE_WIDTH = 250;

const GRAPH_START_X = 50
const NODE_VERTICLE_GAP = 20
const NODE_HORIZONTAL_GAP = 100

const FIELD_NODE_VERTICLE_GAP = 10
const FIELD_NODE_HEIGHT = 20
const FIELD_NODE_Y_OFFSET = 20

const UNSELECTED_EDGE_NODES_OPACITY = 0.3
const SELECTED_EDGE_NODES_OPACITY = 1

const LineageView = ({ lineageNodes, businessAreaPermission, schemaPermission, dataSetPermission, businessAreaDataPermission, onNodeActionClick }) => {
    const nodeTypes = useMemo(() => ({ lineageNode: LineageNode, lineageDuplicateConfigNode: LineageDuplicateConfigNode, fieldNode: LineageFieldNode, tagNode: LineageTagNode }), []);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const selectedEdgesRef = useRef(new Set());
    const [reactFlow, setReactFlow] = useState(null);
    const parentNodeContainer = useRef(null);

    useEffect(() => {
        buildGraph(lineageNodes, true);
    }, [lineageNodes]);

    const showNodeMoreDetail = (node) => {
        node.detailView = true;
        if (parentNodeContainer.current) {
            onNodeExpand(parentNodeContainer.current);
        }
        else {
            buildGraph(lineageNodes);
        }
    }

    const showNodeLessDetail = (node) => {
        node.detailView = false;
        if (parentNodeContainer.current) {
            onNodeExpand(parentNodeContainer.current);
        }
        else {
            buildGraph(lineageNodes);
        }
    }

    const buildRecursiveGraph = (
        parentNode,
        currentNodes,
        graphNodes,
        graphNodesDict,
        childParentGraphNodesDict,
        graphEdges,
        uniqueNodes,
        x,
        nodeLevels,
        currentLevel,
        groupNodes,
        isInitializing = false) => {
        let isRoot = graphNodes.length === 0;
        if (groupNodes && parentNode) {
            let nodeTypeTitle = getTitleFromNodeType(parentNode.type);
            let nodeTitle = `${parentNode.title || parentNode.name} ${(nodeTypeTitle ? `(${nodeTypeTitle})` : "")}`;
            graphNodes.push({
                id: parentNode.id,
                data: {
                    type: "PARENT_NODE",
                    label: (
                        <Row style={{ justifyContent: "space-between", flexWrap: "nowrap" }}>
                            <Col span={20} style={{ textAlign: "left" }}>
                                <Tooltip title={nodeTitle}>
                                    <Text ellipsis={true} style={{ fontSize: "14px" }} strong>{nodeTitle}</Text>
                                </Tooltip>
                            </Col>
                            <Col span={4} style={{ textAlign: "right" }}>
                                <Tooltip title={"Contract node"}>
                                    <Switch
                                        size="small"
                                        style={{ zIndex: 101 }}
                                        onChange={() => onNodeContract()}
                                        checkedChildren={<DoubleLeftOutlined />}
                                        unCheckedChildren={<DoubleRightOutlined />}
                                        defaultChecked={true}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                    )
                },
                position: {
                    x: 0,
                    y: 0
                },
            });
            processNode(parentNode, graphNodes[graphNodes.length - 1], businessAreaPermission, businessAreaDataPermission, schemaPermission, dataSetPermission, onNodeActionClick);
        }

        for (let index = 0; index < currentNodes.length; index++) {
            let node = currentNodes[index];
            if (isInitializing) {
                node.parentNode = parentNode;
            }

            if (uniqueNodes.has(node.id) === false && (!parentNode || parentNode.type !== "DATA_DOMAIN")) {
                graphNodes.push({
                    id: node.id,
                    type: 'lineageNode',
                    data: {
                        name: node.name,
                        type: node.type,
                        description: node.description,
                        node: node,
                        onNodeExpand: () => onNodeExpand(node),
                        onNodeContract: () => onNodeContract()
                    },
                    position: {
                        x: x,
                        y: ((nodeLevels[currentLevel] && nodeLevels[currentLevel].y) || (0 + nodeLevels.offSet))
                    },
                    style: {
                        height: NODE_HEIGHT[node.type],
                        width: NODE_WIDTH,
                        opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                    }
                })
                graphNodesDict[node.id] = graphNodes[graphNodes.length - 1];
                childParentGraphNodesDict[node.id] = [];

                if (groupNodes && parentNode) {
                    graphNodes[graphNodes.length - 1].extent = 'parent';
                    graphNodes[graphNodes.length - 1].parentNode = parentNode.id;
                }

                processNode(node, graphNodes[graphNodes.length - 1], businessAreaPermission, businessAreaDataPermission, schemaPermission, dataSetPermission, onNodeActionClick);
                setNodeDetails(parentNode, node);

                let y = graphNodes[graphNodes.length - 1].position.y + graphNodes[graphNodes.length - 1].style.height + NODE_VERTICLE_GAP;
                let xPosition = graphNodes[graphNodes.length - 1].position.x;
                let totalWidth = graphNodes[graphNodes.length - 1].position.x + graphNodes[graphNodes.length - 1].style.width;
                if (!nodeLevels[currentLevel]) {
                    nodeLevels[currentLevel] = {
                        level: 1,
                        y: y,
                        x: xPosition,
                        width: totalWidth
                    };
                }
                else {
                    nodeLevels[currentLevel] = {
                        ...nodeLevels[currentLevel],
                        level: nodeLevels[currentLevel].level + 1,
                        y: y
                    }
                    nodeLevels[currentLevel].x = Math.max(nodeLevels[currentLevel].x, xPosition);
                    nodeLevels[currentLevel].width = Math.max(nodeLevels[currentLevel].width, totalWidth);
                }

                if (node.type === "JOB") {
                    if (node.jobInfo && node.jobInfo.dataTags && node.jobInfo.dataTags.length > 0) {
                        if (!node.detailView) {
                            graphNodes[graphNodes.length - 1].data.onMoreClick = (clickedNode) => showNodeMoreDetail(clickedNode)
                        }
                        else {
                            let fieldNodeY = NODE_HEIGHT[node.type] + FIELD_NODE_Y_OFFSET;
                            graphNodes[graphNodes.length - 1].data.onLessClick = showNodeLessDetail
                            let fieldsParentNode = graphNodes[graphNodes.length - 1];
                            for (let i = 0; i < node.jobInfo.dataTags.length; i++) {
                                let tag = node.jobInfo.dataTags[i];
                                graphNodes.push({
                                    id: `${node.id}_${tag}`,
                                    type: 'tagNode',
                                    data: {
                                        name: tag,
                                        background: 'transparent',
                                    },
                                    extent: 'parent',
                                    parentNode: fieldsParentNode.id,
                                    position: {
                                        x: 5,
                                        y: fieldNodeY
                                    },
                                    style: {
                                        background: 'transparent',
                                        color: 'black',
                                        height: "auto",
                                        width: "auto",
                                        fontSize: "1vh",
                                        height: FIELD_NODE_HEIGHT,
                                        opacity: graphNodesDict[fieldsParentNode.id].style.opacity
                                    }
                                })

                                childParentGraphNodesDict[fieldsParentNode.id].push(graphNodes[graphNodes.length - 1]);

                                fieldNodeY = graphNodes[graphNodes.length - 1].position.y + graphNodes[graphNodes.length - 1].style.height + FIELD_NODE_VERTICLE_GAP;
                            }

                            if (!fieldsParentNode.style) {
                                fieldsParentNode.style = {};
                            }

                            fieldNodeY = fieldNodeY - FIELD_NODE_VERTICLE_GAP;
                            fieldsParentNode.style.height = fieldsParentNode.style.height + (fieldNodeY - NODE_HEIGHT[node.type]);
                            nodeLevels[currentLevel].y = nodeLevels[currentLevel].y + (fieldNodeY - NODE_HEIGHT[node.type]);
                        }
                    }
                }
                else {
                    if (node.fields && node.fields.length > 0) {
                        if (!node.detailView) {
                            graphNodes[graphNodes.length - 1].data.onMoreClick = (clickedNode) => showNodeMoreDetail(clickedNode)
                        }
                        else {
                            graphNodes[graphNodes.length - 1].data.onLessClick = showNodeLessDetail
                            let fieldsParentNode = graphNodes[graphNodes.length - 1];

                            let destination = true;
                            let source = true;
                            if (node.type === "SCHEMA") {
                                destination = false;
                                fieldsParentNode.data.source = false;
                            }
                            else if (node.type === "DATA_SET") {
                                fieldsParentNode.data.source = false;
                                fieldsParentNode.data.destination = false;
                            }

                            let fieldNodeY = NODE_HEIGHT[node.type] + FIELD_NODE_Y_OFFSET;
                            for (let i = 0; i < node.fields.length; i++) {
                                let field = node.fields[i];
                                graphNodes.push({
                                    id: `${node.id}_${field.name}`,
                                    type: 'fieldNode',
                                    data: {
                                        name: field.name,
                                        tags: field.tags,
                                        background: '#ffd9c9',
                                        destination: destination || field.dataDomainId,
                                        source,
                                        erroredDataCount: field.erroredDataCount,
                                        successDataCount: field.successDataCount,
                                        type: node.type
                                    },
                                    extent: 'parent',
                                    parentNode: fieldsParentNode.id,
                                    position: {
                                        x: 5,
                                        y: fieldNodeY
                                    },
                                    style: {
                                        background: '#ffd9c9',
                                        color: 'black',
                                        height: "auto",
                                        width: "auto",
                                        fontSize: "1vh",
                                        height: FIELD_NODE_HEIGHT,
                                        width: NODE_WIDTH - 10,
                                        opacity: graphNodesDict[fieldsParentNode.id].style.opacity
                                    }
                                })

                                childParentGraphNodesDict[fieldsParentNode.id].push(graphNodes[graphNodes.length - 1]);
                                fieldNodeY = graphNodes[graphNodes.length - 1].position.y + graphNodes[graphNodes.length - 1].style.height + FIELD_NODE_VERTICLE_GAP;
                            }

                            if (!fieldsParentNode.style) {
                                fieldsParentNode.style = {};
                            }

                            fieldNodeY = fieldNodeY - FIELD_NODE_VERTICLE_GAP - FIELD_NODE_Y_OFFSET;
                            fieldsParentNode.style.height = (fieldsParentNode.style.height + fieldNodeY);
                            nodeLevels[currentLevel].y = nodeLevels[currentLevel].y + fieldNodeY;
                        }
                    }
                }

                uniqueNodes.add(node.id);
            }

            if (parentNode && !groupNodes) {
                if (parentNode.type === "SCHEMA" && node.type === "DATA_SET") {
                    if (parentNode.detailView && node.detailView) {
                        let nodeFieldsDict = {};
                        for (let field of node.fields) {
                            nodeFieldsDict[field.name] = field;
                        }
                        for (let field of parentNode.fields) {
                            if (nodeFieldsDict[field.name]) {
                                graphEdges.push({
                                    id: `${parentNode.id}_${field.name}:${node.id}_${field.name}`,
                                    source: `${parentNode.id}_${field.name}`,
                                    target: `${node.id}_${field.name}`,
                                    markerEnd: { type: MarkerType.Arrow },
                                    data: {
                                        edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                        leftNode: parentNode,
                                        rightNode: node,
                                        leftField: field.name,
                                        rightField: field.name
                                    },
                                    zIndex: 100,
                                    interactionWidth: 5,
                                    style: {
                                        opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                    }
                                });
                                graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);
                                if (!graphEdges[graphEdges.length - 1].selected && selectedEdgesRef.current.has(`${parentNode.id}:${node.id}`)) {
                                    let selectedFieldEdgeExist = false;
                                    for (let edgeId of selectedEdgesRef.current) {
                                        if (edgeId.indexOf(`${parentNode.id}_`) > -1 && edgeId.indexOf(`${node.id}_`) > -1) {
                                            selectedFieldEdgeExist = true;
                                            break;
                                        }
                                    }
                                    if (!selectedFieldEdgeExist) {
                                        graphEdges[graphEdges.length - 1].selected = true;
                                    }
                                }

                                graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                                if (graphEdges[graphEdges.length - 1].selected) {
                                    if (graphNodesDict[parentNode.id]) {
                                        graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                        if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                            setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                        }
                                    }
                                    if (graphNodesDict[node.id]) {
                                        graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                        if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                            setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                        }
                                    }
                                    graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                                }
                            }
                        }
                    }
                    else if (parentNode.detailView && !node.detailView) {
                        for (let field of parentNode.fields) {
                            graphEdges.push({
                                id: `${parentNode.id}_${field.name}:${node.id}`,
                                source: `${parentNode.id}_${field.name}`,
                                target: node.id,
                                markerEnd: { type: MarkerType.Arrow },
                                data: {
                                    edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                    leftNode: parentNode,
                                    rightNode: node,
                                    leftField: field.name,
                                },
                                zIndex: 100,
                                interactionWidth: 5,
                                style: {
                                    opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                }
                            });
                            graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);
                            if (!graphEdges[graphEdges.length - 1].selected && selectedEdgesRef.current.has(`${parentNode.id}:${node.id}`)) {
                                let selectedFieldEdgeExist = false;
                                for (let edgeId of selectedEdgesRef.current) {
                                    if (edgeId.indexOf(`${parentNode.id}_`) > -1 && edgeId.indexOf(`${node.id}_`) === -1) {
                                        selectedFieldEdgeExist = true;
                                        break;
                                    }
                                }
                                if (!selectedFieldEdgeExist) {
                                    graphEdges[graphEdges.length - 1].selected = true;
                                }
                            }

                            graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                            if (graphEdges[graphEdges.length - 1].selected) {
                                if (graphNodesDict[parentNode.id]) {
                                    graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                if (graphNodesDict[node.id]) {
                                    graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                            }
                        }
                    }
                    else if (!parentNode.detailView && node.detailView) {
                        for (let field of node.fields) {
                            graphEdges.push({
                                id: `${parentNode.id}:${node.id}_${field.name}`,
                                source: parentNode.id,
                                target: `${node.id}_${field.name}`,
                                markerEnd: { type: MarkerType.Arrow },
                                data: {
                                    edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                    leftNode: parentNode,
                                    rightNode: node,
                                    rightField: field.name
                                },
                                zIndex: 100,
                                interactionWidth: 5,
                                style: {
                                    opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                }
                            });
                            graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);
                            if (!graphEdges[graphEdges.length - 1].selected && selectedEdgesRef.current.has(`${parentNode.id}:${node.id}`)) {
                                let selectedFieldEdgeExist = false;
                                for (let edgeId of selectedEdgesRef.current) {
                                    if (edgeId.indexOf(`${parentNode.id}_`) === -1 && edgeId.indexOf(`${node.id}_`) > -1) {
                                        selectedFieldEdgeExist = true;
                                        break;
                                    }
                                }
                                if (!selectedFieldEdgeExist) {
                                    graphEdges[graphEdges.length - 1].selected = true;
                                }
                            }

                            graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                            if (graphEdges[graphEdges.length - 1].selected) {
                                if (graphNodesDict[parentNode.id]) {
                                    graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                if (graphNodesDict[node.id]) {
                                    graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                            }
                        }
                    }
                    else {
                        graphEdges.push({
                            id: `${parentNode.id}:${node.id}`,
                            source: parentNode.id,
                            target: node.id,
                            markerEnd: { type: MarkerType.Arrow },
                            data: {
                                edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                leftNode: parentNode,
                                rightNode: node
                            },
                            zIndex: 100,
                            interactionWidth: 5,
                            style: {
                                opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                            }
                        });
                        graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);

                        graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                        if (graphEdges[graphEdges.length - 1].selected) {
                            if (graphNodesDict[parentNode.id]) {
                                graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                    setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                }
                            }
                            if (graphNodesDict[node.id]) {
                                graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                    setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                }
                            }
                            graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                        }
                    }
                }
                else if (parentNode.type === "DATA_SET" && node.type === "JOB") {
                    if (parentNode.detailView && node.detailView) {
                        for (let tag of node.jobInfo.dataTags) {
                            for (let field of parentNode.fields) {
                                if (field.tags && field.tags.findIndex(fieldTag => fieldTag === tag) > -1) {
                                    graphEdges.push({
                                        id: `${parentNode.id}_${field.name}:${node.id}_${tag}`,
                                        source: `${parentNode.id}_${field.name}`,
                                        target: `${node.id}_${tag}`,
                                        markerEnd: { type: MarkerType.Arrow },
                                        data: {
                                            edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                            leftNode: parentNode,
                                            rightNode: node,
                                            leftField: field.name,
                                            rightField: tag
                                        },
                                        zIndex: 100,
                                        interactionWidth: 5,
                                        style: {
                                            opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                        }
                                    });
                                    graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);
                                    if (!graphEdges[graphEdges.length - 1].selected && selectedEdgesRef.current.has(`${parentNode.id}:${node.id}`)) {
                                        let selectedFieldEdgeExist = false;
                                        for (let edgeId of selectedEdgesRef.current) {
                                            if (edgeId.indexOf(`${parentNode.id}_`) > -1 && edgeId.indexOf(`${node.id}_`) > -1) {
                                                selectedFieldEdgeExist = true;
                                                break;
                                            }
                                        }
                                        if (!selectedFieldEdgeExist) {
                                            graphEdges[graphEdges.length - 1].selected = true;
                                        }
                                    }

                                    graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                                    if (graphEdges[graphEdges.length - 1].selected) {
                                        if (graphNodesDict[parentNode.id]) {
                                            graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                            if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                                setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                            }
                                        }
                                        if (graphNodesDict[node.id]) {
                                            graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                            if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                                setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                            }
                                        }
                                        graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                                    }
                                }
                            }
                        }
                    }
                    else if (parentNode.detailView && !node.detailView) {
                        for (let field of parentNode.fields) {
                            graphEdges.push({
                                id: `${parentNode.id}_${field.name}:${node.id}`,
                                source: `${parentNode.id}_${field.name}`,
                                target: node.id,
                                markerEnd: { type: MarkerType.Arrow },
                                data: {
                                    edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                    leftNode: parentNode,
                                    rightNode: node,
                                    leftField: field.name
                                },
                                zIndex: 100,
                                interactionWidth: 5,
                                style: {
                                    opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                }
                            });
                            graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);
                            if (!graphEdges[graphEdges.length - 1].selected && selectedEdgesRef.current.has(`${parentNode.id}:${node.id}`)) {
                                let selectedFieldEdgeExist = false;
                                for (let edgeId of selectedEdgesRef.current) {
                                    if (edgeId.indexOf(`${parentNode.id}_`) > -1 && edgeId.indexOf(`${node.id}_`) === -1) {
                                        selectedFieldEdgeExist = true;
                                        break;
                                    }
                                }
                                if (!selectedFieldEdgeExist) {
                                    graphEdges[graphEdges.length - 1].selected = true;
                                }
                            }

                            graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                            if (graphEdges[graphEdges.length - 1].selected) {
                                if (graphNodesDict[parentNode.id]) {
                                    graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                if (graphNodesDict[node.id]) {
                                    graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                            }
                        }
                    }
                    else if (!parentNode.detailView && node.detailView) {
                        for (let tag of node.jobInfo.dataTags) {
                            graphEdges.push({
                                id: `${parentNode.id}:${node.id}_${tag}`,
                                source: parentNode.id,
                                target: `${node.id}_${tag}`,
                                markerEnd: { type: MarkerType.Arrow },
                                data: {
                                    edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                    leftNode: parentNode,
                                    rightNode: node,
                                    rightField: tag
                                },
                                zIndex: 100,
                                interactionWidth: 5,
                                style: {
                                    opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                }
                            });
                            graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);
                            if (!graphEdges[graphEdges.length - 1].selected && selectedEdgesRef.current.has(`${parentNode.id}:${node.id}`)) {
                                let selectedFieldEdgeExist = false;
                                for (let edgeId of selectedEdgesRef.current) {
                                    if (edgeId.indexOf(`${parentNode.id}_`) === -1 && edgeId.indexOf(`${node.id}_`) > -1) {
                                        selectedFieldEdgeExist = true;
                                        break;
                                    }
                                }
                                if (!selectedFieldEdgeExist) {
                                    graphEdges[graphEdges.length - 1].selected = true;
                                }
                            }

                            graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                            if (graphEdges[graphEdges.length - 1].selected) {
                                if (graphNodesDict[parentNode.id]) {
                                    graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                if (graphNodesDict[node.id]) {
                                    graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                    if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                        setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                    }
                                }
                                graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                            }
                        }
                    }
                    else {
                        graphEdges.push({
                            id: `${parentNode.id}:${node.id}`,
                            source: parentNode.id,
                            target: node.id,
                            markerEnd: { type: MarkerType.Arrow },
                            data: {
                                edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                                leftNode: parentNode,
                                rightNode: node
                            },
                            zIndex: 100,
                            interactionWidth: 5,
                            style: {
                                opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                            }
                        });
                        graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);

                        graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                        if (graphEdges[graphEdges.length - 1].selected) {
                            if (graphNodesDict[parentNode.id]) {
                                graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                    setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                }
                            }
                            if (graphNodesDict[node.id]) {
                                graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                    setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                }
                            }
                            graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                        }
                    }
                }
                else {
                    graphEdges.push({
                        id: `${parentNode.id}:${node.id}`,
                        source: parentNode.id,
                        target: node.id,
                        markerEnd: { type: MarkerType.Arrow },
                        data: {
                            edgeName: `${parentNode.name} (${getTitleFromNodeType(parentNode.type)}) and ${node.name} (${getTitleFromNodeType(node.type)})`,
                            leftNode: parentNode,
                            rightNode: node
                        },
                        zIndex: 100,
                        interactionWidth: 5,
                        style: {
                            opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                        }
                    });
                    graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id);

                    graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                    if (graphEdges[graphEdges.length - 1].selected) {
                        if (graphNodesDict[parentNode.id]) {
                            graphNodesDict[parentNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                            if (childParentGraphNodesDict[parentNode.id] && childParentGraphNodesDict[parentNode.id].length > 0) {
                                setOpacityForAllChildNodes(childParentGraphNodesDict[parentNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                            }
                        }
                        if (graphNodesDict[node.id]) {
                            graphNodesDict[node.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                            if (childParentGraphNodesDict[node.id] && childParentGraphNodesDict[node.id].length > 0) {
                                setOpacityForAllChildNodes(childParentGraphNodesDict[node.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                            }
                        }
                        graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                    }
                }
            }

            if (node.nodes && node.nodes.length > 0) {
                buildRecursiveGraph(node, node.nodes, graphNodes, graphNodesDict, childParentGraphNodesDict, graphEdges, uniqueNodes, nodeLevels[currentLevel].width + NODE_HORIZONTAL_GAP, nodeLevels, currentLevel + 1, false, isInitializing);
            }

            if (isRoot) {
                let dataDomainNodesDict = {};
                let graphEdgeSet = new Set();

                for (let graphNode of graphNodes) {
                    if (graphNode.data && graphNode.data.type === "DATA_DOMAIN") {
                        dataDomainNodesDict[graphNode.id] = graphNode.data.node;
                    }
                }

                for (let graphEdge of graphEdges) {
                    graphEdgeSet.add(graphEdge.id);
                }

                for (let graphNode of graphNodes) {
                    if (graphNode.data && graphNode.data.type === "SCHEMA") {
                        let schemaNode = graphNode.data.node;
                        if (schemaNode) {
                            if (schemaNode.fields && schemaNode.fields.length > 0) {
                                for (let field of schemaNode.fields) {
                                    if (field.dataDomainId && dataDomainNodesDict[field.dataDomainId]) {
                                        if (schemaNode.detailView) {
                                            let id = `${field.dataDomainId}:${schemaNode.id}_${field.name}`;
                                            if (graphEdgeSet.has(id) === false) {
                                                graphEdges.push({
                                                    id: id,
                                                    source: field.dataDomainId,
                                                    target: `${schemaNode.id}_${field.name}`,
                                                    markerEnd: { type: MarkerType.Arrow },
                                                    data: {
                                                        edgeName: `${dataDomainNodesDict[field.dataDomainId].name} (${getTitleFromNodeType(dataDomainNodesDict[field.dataDomainId].type)}) and ${schemaNode.name} (${getTitleFromNodeType(schemaNode.type)})`,
                                                        leftNode: dataDomainNodesDict[field.dataDomainId],
                                                        rightNode: schemaNode,
                                                        rightField: field.name
                                                    },
                                                    zIndex: 100,
                                                    interactionWidth: 5,
                                                    style: {
                                                        opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                                    }
                                                });
                                            }
                                        }
                                        else {
                                            let id = `${field.dataDomainId}:${schemaNode.id}`;
                                            if (graphEdgeSet.has(id) === false) {
                                                graphEdges.push({
                                                    id: id,
                                                    source: field.dataDomainId,
                                                    target: schemaNode.id,
                                                    markerEnd: { type: MarkerType.Arrow },
                                                    data: {
                                                        edgeName: `${dataDomainNodesDict[field.dataDomainId].name} (${getTitleFromNodeType(dataDomainNodesDict[field.dataDomainId].type)}) and ${schemaNode.name} (${getTitleFromNodeType(schemaNode.type)})`,
                                                        leftNode: dataDomainNodesDict[field.dataDomainId],
                                                        rightNode: schemaNode
                                                    },
                                                    zIndex: 100,
                                                    interactionWidth: 5,
                                                    style: {
                                                        opacity: selectedEdgesRef.current && selectedEdgesRef.current.size > 0 ? UNSELECTED_EDGE_NODES_OPACITY : SELECTED_EDGE_NODES_OPACITY
                                                    }
                                                });
                                            }
                                        }

                                        graphEdges[graphEdges.length - 1].selected = selectedEdgesRef.current.has(graphEdges[graphEdges.length - 1].id) || selectedEdgesRef.current.has(`${dataDomainNodesDict[field.dataDomainId].id}:${schemaNode.id}`);
                                        if (graphEdges[graphEdges.length - 1].selected) {
                                            graphEdges[graphEdges.length - 1].style = { ...graphEdges[graphEdges.length - 1].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                                            if (graphNodesDict[dataDomainNodesDict[field.dataDomainId].id]) {
                                                graphNodesDict[dataDomainNodesDict[field.dataDomainId].id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                                if (childParentGraphNodesDict[dataDomainNodesDict[field.dataDomainId].id] && childParentGraphNodesDict[dataDomainNodesDict[field.dataDomainId].id].length > 0) {
                                                    setOpacityForAllChildNodes(childParentGraphNodesDict[dataDomainNodesDict[field.dataDomainId].id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                                }
                                            }
                                            if (graphNodesDict[schemaNode.id]) {
                                                graphNodesDict[schemaNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
                                                if (childParentGraphNodesDict[schemaNode.id] && childParentGraphNodesDict[schemaNode.id].length > 0) {
                                                    setOpacityForAllChildNodes(childParentGraphNodesDict[schemaNode.id], SELECTED_EDGE_NODES_OPACITY, childParentGraphNodesDict)
                                                }
                                            }
                                        }
                                        graphEdges[graphEdges.length - 1].animated = !graphEdges[graphEdges.length - 1].selected;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const processNode = (node, graphNode, businessAreaPermission, businessAreaDataPermission, schemaPermission, dataSetPermission, onNodeActionClick) => {
        switch (node.type) {
            case "BUSINESS_AREA":
                graphNode.data.source = true;
                graphNode.data.destination = false;
                graphNode.data.background = '#FFFACD';
                graphNode.data.permission = businessAreaPermission;
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, node)
                graphNode.data.title = getTitleFromNodeType(node.type);
                graphNode.data.disableEditAction = node.name === "DEFAULT";
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#FFFACD',
                    color: 'black',
                }
                break;
            case "DATA_DOMAIN":
                graphNode.data.source = true;
                graphNode.data.destination = true;
                graphNode.data.background = '#ffd4d4';
                graphNode.data.permission = businessAreaDataPermission;
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, node)
                graphNode.data.title = getTitleFromNodeType(node.type);
                graphNode.data.disableEditAction = true
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#ffd4d4',
                    color: 'black',
                }
                break;
            case "SCHEMA":
                graphNode.data.source = true;
                graphNode.data.destination = true;
                graphNode.data.background = '#E0FFFF';
                graphNode.data.permission = schemaPermission;
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, node)
                graphNode.data.title = getTitleFromNodeType(node.type);
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#E0FFFF',
                    color: 'black',
                }
                if (node.parentNode && node.parentNode.descendentTypes && node.parentNode.descendentTypes.includes("DATA_DOMAIN")) {
                    graphNode.position.x = graphNode.position.x + NODE_WIDTH + NODE_HORIZONTAL_GAP;
                }
                break;
            case "DATA_SET":
                graphNode.data.source = true;
                graphNode.data.destination = true;
                graphNode.data.background = '#E6E6FA';
                graphNode.data.permission = dataSetPermission;
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, node)
                graphNode.data.title = getTitleFromNodeType(node.type);
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#E6E6FA',
                    color: 'black',
                }
                break;
            case "JOB":
                graphNode.data.source = false;
                graphNode.data.destination = true;
                graphNode.type = "lineageDuplicateConfigNode";
                graphNode.data.background = '#E0FFE0';
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, node)
                graphNode.data.title = getTitleFromNodeType(node.type);
                graphNode.data.duplicateRecords = node.duplicateRecords;
                graphNode.data.maxClusterSize = node.maxClusterSize;
                graphNode.data.totalClusters = node.totalClusters;
                graphNode.data.totalRecords = node.totalRecords;
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#E0FFE0',
                    color: 'black',
                    width: DUPLICATE_CONFIG_NODE_WIDTH
                }
                break;
            default:
                graphNode.data.source = false;
                graphNode.data.destination = false;
                graphNode.data.background = '#FFFACD';
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#FFFACD',
                    color: 'black',
                }
                break;
        }
    }

    const setNodeDetails = (parentNode, node) => {
        switch (node.type) {
            case "DATA_DOMAIN":
            case "SCHEMA":
                if (parentNode) {
                    node.businessAreaName = parentNode.name;
                    node.businessAreaId = parentNode.id;
                }
                break;
            case "DATA_SET":
                if (parentNode) {
                    node.businessAreaName = parentNode.businessAreaName;
                    node.businessAreaId = parentNode.businessAreaId;
                    node.schemaName = parentNode.name;
                    node.schemaId = parentNode.id;
                }
                break;
        }
    }

    const getTitleFromNodeType = (type) => {
        switch (type) {
            case "BUSINESS_AREA":
                return "Business Area";
            case "DATA_DOMAIN":
                return "Business Area Data";
            case "SCHEMA":
                return "Schema";
            case "DATA_SET":
                return "Dataset";
            case "JOB":
                return "Duplicate Search Config";
            default:
                return "";
        }
    }

    const buildGraph = (lineageNodes, isInitializing = false) => {
        let graphEdges = [];
        let graphNodes = [];
        let graphNodesDict = {};
        let childParentGraphNodesDict = {};
        let nodeLevels = {
            offSet: 0
        };
        let uniqueNodes = new Set();
        buildRecursiveGraph(null, lineageNodes, graphNodes, graphNodesDict, childParentGraphNodesDict, graphEdges, uniqueNodes, GRAPH_START_X, nodeLevels, 0, false, isInitializing);
        setNodes(graphNodes);
        setEdges(graphEdges);
    }

    const showRelatedNodeGraph = (parentNode, childNode) => {
        let containerNode = {};
        containerNode.title = `${parentNode.name} Relationships`;
        containerNode.type = "";
        containerNode.nodes = [parentNode];
        switch (parentNode.type) {
            case "BUSINESS_AREA":
                containerNode.id = `${containerNode.id}_${containerNode.name}`;
                break;
            case "DATA_DOMAIN":
                containerNode.id = parentNode.businessAreaId;
                containerNode.nodes.splice(0, 0, childNode);
                break;
            case "SCHEMA":
                containerNode.id = parentNode.businessAreaId;
                break;
            case "DATA_SET":
                containerNode.id = parentNode.schemaId;
                break;

        }
        onNodeExpand(containerNode);
    }

    const onNodeExpand = (node) => {
        let graphEdges = [];
        let graphNodes = [];
        let graphNodesDict = {};
        let childParentGraphNodesDict = {};
        let nodeLevels = {
            offSet: 50
        };
        let uniqueNodes = new Set();
        buildRecursiveGraph(node, node.nodes, graphNodes, graphNodesDict, childParentGraphNodesDict, graphEdges, uniqueNodes, GRAPH_START_X, nodeLevels, 0, true);

        let maxNodeHeight = 0;
        let maxNodeWidth = 50;
        let keys = Object.keys(nodeLevels);
        keys.splice(keys.findIndex(key => key === "offSet"), 1);
        let maxLevel = Math.max(...(keys.map(key => parseInt(key)))).toString();
        maxNodeWidth = maxNodeWidth + nodeLevels[maxLevel].width;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (nodeLevels[key]) {
                if (nodeLevels[key].y) {
                    maxNodeHeight = Math.max(maxNodeHeight, nodeLevels[key].y);
                }
            }
        }

        graphNodes[0].style.height = maxNodeHeight;
        graphNodes[0].style.width = maxNodeWidth;
        graphNodes[0].style.opacity = 0.5;
        setNodes(graphNodes);
        setEdges(graphEdges);
        parentNodeContainer.current = node;
    }

    const onNodeContract = () => {
        parentNodeContainer.current = null;
        buildGraph(lineageNodes);
    }

    const nodeColor = (node) => {
        return node.style.background;
    };

    const onInit = (reactFlowInstance) => {
        setReactFlow(reactFlowInstance);
    }

    const onEdgeDoubleClick = (event, edge) => {
        if (edge.data && edge.data.leftNode && edge.data.rightNode) {
            showRelatedNodeGraph(edge.data.leftNode, edge.data.rightNode);
        }
    }

    const onEdgeClick = (event, clickedEdge) => {
        let edges = reactFlow.getEdges();
        let currentNodes = [...reactFlow.getNodes()];
        let currentNodesDict = {};
        let childParentNodesDict = {};

        let selectedEdge = null;
        let currentSelectedEdges = new Set();
        let nodeEdgeDict = {};
        let nodeSourceEndingDict = {};
        let nodeTargetEndingDict = {}
        let edgeDict = {};

        let edgeAlreadySelected = selectedEdgesRef.current.has(clickedEdge.id);

        //Reset all the selection and animations
        //Also prepare node edge dictionary with left and right node IDs
        for (let edge of edges) {
            if (edge.id === clickedEdge.id) {
                selectedEdge = edge;
            }

            edge.selected = false;
            edge.animated = true;
            if (!edgeAlreadySelected) {
                edge.style = { ...edge.style, opacity: UNSELECTED_EDGE_NODES_OPACITY };
            }
            else {
                edge.style = { ...edge.style, opacity: SELECTED_EDGE_NODES_OPACITY };
            }

            if (edge.data && edge.data.leftNode && edge.data.rightNode) {
                let key = `${edge.data.leftNode.id}_${edge.data.leftNode.type}:${edge.data.rightNode.id}_${edge.data.rightNode.type}`;
                if (!nodeEdgeDict[key]) {
                    nodeEdgeDict[key] = [];
                }
                nodeEdgeDict[key].push(edge);
            }

            if (edge.source) {
                if (!nodeSourceEndingDict[edge.source]) {
                    nodeSourceEndingDict[edge.source] = [];
                }
                nodeSourceEndingDict[edge.source].push(edge);
            }

            if (edge.target) {
                if (!nodeTargetEndingDict[edge.target]) {
                    nodeTargetEndingDict[edge.target] = [];
                }
                nodeTargetEndingDict[edge.target].push(edge);
            }
            edgeDict[edge.id] = edge;
        }

        if (selectedEdge) {
            if (!edgeAlreadySelected) {
                for (let i = 0; i < currentNodes.length; i++) {
                    currentNodes[i] = { ...currentNodes[i] };
                    currentNodes[i].style = { ...currentNodes[i].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                    currentNodesDict[currentNodes[i].id] = currentNodes[i];
                    childParentNodesDict[currentNodes[i].id] = [];
                }
                for (let i = 0; i < currentNodes.length; i++) {
                    if (currentNodes[i].parentNode) {
                        childParentNodesDict[currentNodes[i].parentNode].push(currentNodes[i]);
                    }
                }

                selectedEdge.selected = true;
                selectedEdge.animated = false;
                selectedEdge.style = { ...selectedEdge.style, opacity: SELECTED_EDGE_NODES_OPACITY };

                currentSelectedEdges.add(selectedEdge.id);
                highlightSelectedEdgeNodes(selectedEdge, currentNodesDict);
                selectAllRelatedEdges(selectedEdge, currentSelectedEdges);


                selectAllLeftEdges(selectedEdge, nodeTargetEndingDict, currentSelectedEdges, nodeEdgeDict, currentNodesDict);
                //Select all left edges for which edges exist at parent level
                if (selectedEdge.data && selectedEdge.data.leftNode) {
                    let leftNode = selectedEdge.data.leftNode;
                    let leftAncestorNode = leftNode.parentNode;
                    if (leftAncestorNode && leftNode) {
                        do {
                            let key = `${leftAncestorNode.id}_${leftAncestorNode.type}:${leftNode.id}_${leftNode.type}`;
                            if (nodeEdgeDict[key]) {
                                nodeEdgeDict[key].map(edge => {
                                    edge.selected = true;
                                    edge.animated = false;
                                    edge.style = { ...edge.style, opacity: SELECTED_EDGE_NODES_OPACITY };

                                    currentSelectedEdges.add(edge.id);
                                    highlightSelectedEdgeNodes(edge, currentNodesDict);
                                    selectAllRelatedEdges(edge, currentSelectedEdges);
                                });
                            }
                            leftNode = leftAncestorNode;
                            leftAncestorNode = leftNode.parentNode;
                        }
                        while (leftAncestorNode && leftNode)
                    }
                }
                selectAllRightEdges(selectedEdge, nodeSourceEndingDict, currentSelectedEdges, nodeEdgeDict, currentNodesDict);
                let selectedNodesSet = new Set();
                for (let edgeId of currentSelectedEdges) {
                    if (edgeDict[edgeId]) {
                        selectedNodesSet.add(edgeDict[edgeId].data.leftNode.id);
                        selectedNodesSet.add(edgeDict[edgeId].data.rightNode.id);
                    }
                }

                //UnHighlight rest of the nodes which are not in selected edge path
                for (let edge of edges) {
                    if (edge.data && edge.data.leftNode && edge.data.rightNode) {
                        if (currentSelectedEdges.has(edge.id) === false) {
                            if (selectedNodesSet.has(edge.data.leftNode.id) === false) {
                                currentNodesDict[edge.data.leftNode.id].style.opacity = UNSELECTED_EDGE_NODES_OPACITY;
                                if (childParentNodesDict[edge.data.leftNode.id] && childParentNodesDict[edge.data.leftNode.id].length > 0) {
                                    setOpacityForAllChildNodes(childParentNodesDict[edge.data.leftNode.id], UNSELECTED_EDGE_NODES_OPACITY, childParentNodesDict)
                                }
                            }

                            if (selectedNodesSet.has(edge.data.rightNode.id) === false) {
                                currentNodesDict[edge.data.rightNode.id].style.opacity = UNSELECTED_EDGE_NODES_OPACITY;
                                if (childParentNodesDict[edge.data.rightNode.id] && childParentNodesDict[edge.data.rightNode.id].length > 0) {
                                    setOpacityForAllChildNodes(childParentNodesDict[edge.data.rightNode.id], UNSELECTED_EDGE_NODES_OPACITY, childParentNodesDict)
                                }
                            }
                        }
                    }
                }

                //Now unhighlight remaining nodes for which edges dont exist
                for (let node of currentNodes) {
                    if (selectedNodesSet.has(node.id) === false && !nodeSourceEndingDict[node.id] && !nodeTargetEndingDict[node.id] && node.data.type !== "PARENT_NODE" && (node.type === "lineageNode" || node.type === "lineageDuplicateConfigNode")) {
                        node.style.opacity = UNSELECTED_EDGE_NODES_OPACITY;
                    }
                }
            }
            else {
                for (let i = 0; i < currentNodes.length; i++) {
                    currentNodes[i] = { ...currentNodes[i] };
                    currentNodes[i].style = { ...currentNodes[i].style, opacity: SELECTED_EDGE_NODES_OPACITY };
                }
                event.currentTarget.blur();
            }
        }
        setNodes(currentNodes);
        setEdges(edges);
        selectedEdgesRef.current = currentSelectedEdges;
    }

    const selectAllLeftEdges = (currentEdge, nodeTargetEndingDict, currentSelectedEdges, nodeEdgeDict, currentNodesDict) => {
        if (currentEdge.source) {
            let leftTargetEdges = nodeTargetEndingDict[currentEdge.source]
            if (leftTargetEdges) {
                for (let edge of leftTargetEdges) {
                    if (currentEdge.id !== edge.id) {
                        edge.selected = true;
                        edge.animated = false;
                        edge.style = { ...edge.style, opacity: SELECTED_EDGE_NODES_OPACITY };

                        currentSelectedEdges.add(edge.id);
                        highlightSelectedEdgeNodes(edge, currentNodesDict);
                        selectAllRelatedEdges(edge, currentSelectedEdges);
                        selectAllLeftEdges(edge, nodeTargetEndingDict, currentSelectedEdges, nodeEdgeDict, currentNodesDict);
                    }
                }
            }
        }
    }

    const selectAllRightEdges = (currentEdge, nodeSourceEndingDict, currentSelectedEdges, nodeEdgeDict, currentNodesDict) => {
        if (currentEdge.target) {
            let rightTargetEdges = nodeSourceEndingDict[currentEdge.target]
            if (rightTargetEdges) {
                for (let edge of rightTargetEdges) {
                    if (currentEdge.id !== edge.id) {
                        edge.selected = true;
                        edge.animated = false;
                        edge.style = { ...edge.style, opacity: SELECTED_EDGE_NODES_OPACITY };

                        currentSelectedEdges.add(edge.id);
                        highlightSelectedEdgeNodes(edge, currentNodesDict);
                        selectAllRelatedEdges(edge, currentSelectedEdges);
                        selectAllRightEdges(edge, nodeSourceEndingDict, currentSelectedEdges, nodeEdgeDict, currentNodesDict);
                    }
                }
            }
            else {
                if (currentEdge.data && currentEdge.data.rightNode && currentEdge.data.rightNode.nodes) {
                    selectAllChildNodeEdges(currentEdge.data.rightNode, currentEdge.data.rightNode.nodes, nodeEdgeDict, currentSelectedEdges, currentNodesDict)
                }
            }
        }
    }

    const selectAllChildNodeEdges = (parentNode, childNodes, nodeEdgeDict, currentSelectedEdges, currentNodesDict) => {
        if (childNodes && childNodes.length > 0) {
            for (let childNode of childNodes) {
                let key = `${parentNode.id}_${parentNode.type}:${childNode.id}_${childNode.type}`;
                if (nodeEdgeDict[key]) {
                    nodeEdgeDict[key].map(edge => {
                        edge.selected = true;
                        edge.animated = false;
                        edge.style = { ...edge.style, opacity: SELECTED_EDGE_NODES_OPACITY };

                        currentSelectedEdges.add(edge.id);
                        highlightSelectedEdgeNodes(edge, currentNodesDict);
                        selectAllRelatedEdges(edge, currentSelectedEdges);
                    });
                }
                selectAllChildNodeEdges(childNode, childNode.nodes, nodeEdgeDict, currentSelectedEdges, currentNodesDict);
            }
        }
    }

    const selectAllRelatedEdges = (edge, currentSelectedEdges) => {
        if (edge.data) {
            currentSelectedEdges.add(`${edge.data.leftNode.id}:${edge.data.rightNode.id}`);
            if (edge.data.leftField || edge.data.rightField) {
                if (edge.data.leftField) {
                    currentSelectedEdges.add(`${edge.data.leftNode.id}_${edge.data.leftField}:${edge.data.rightNode.id}`);
                }

                if (edge.data.rightField) {
                    currentSelectedEdges.add(`${edge.data.leftNode.id}:${edge.data.rightNode.id}_${edge.data.rightField}`);
                }

                if (edge.data.leftField && edge.data.rightField) {
                    currentSelectedEdges.add(`${edge.data.leftNode.id}_${edge.data.leftField}:${edge.data.rightNode.id}_${edge.data.rightField}`);
                }
            }
        }
    }

    const highlightSelectedEdgeNodes = (edge, currentNodesDict) => {
        if (edge.data && edge.data.leftNode && edge.data.rightNode) {
            currentNodesDict[edge.data.leftNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
            currentNodesDict[edge.data.rightNode.id].style.opacity = SELECTED_EDGE_NODES_OPACITY;
        }
    }

    const setOpacityForAllChildNodes = (childNodes, opacity, childParentNodesDict) => {
        for (let node of childNodes) {
            node.style.opacity = opacity;
            if (childParentNodesDict[node.id] && childParentNodesDict[node.id].length > 0) {
                setOpacityForAllChildNodes(childNodes, opacity, childParentNodesDict);
            }
        }
    }

    return <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        fitView
        attributionPosition="top-right"
        onInit={onInit}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onEdgeClick={onEdgeClick}>
        <MiniMap nodeColor={nodeColor} zoomable pannable />
        <Controls />
        <Background variant="lines" />
    </ReactFlow>
};

export default LineageView;