
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventDataObjectVerifications(api, eventId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/verifications`);
    return result.data;
}

export function* getEventDataObjectVerificationsRequest(api, { eventId }) {
    try {
        const result = yield call(getEventDataObjectVerifications, api, eventId);
        yield put(actions.getEventDataObjectVerificationsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event record verification details");
        yield put(actions.getEventDataObjectVerificationsFailure(errorObject));
    }
}

export function* watchGetEventDataObjectVerificationsRequest(api, { params }) {
    yield call(getEventDataObjectVerificationsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_DATA_OBJECT_VERIFICATIONS_REQUEST, watchGetEventDataObjectVerificationsRequest, api);
}