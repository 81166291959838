import React, { useState } from "react";
import { Button, Table, Checkbox, Row, Col, Tooltip, Empty, Typography } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FullHeightContainerLayout, DataObjectLink } from "components";
import { DataObjectShareOption } from 'containers';
import _ from 'lodash';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const DeduplicatedRecordsTable = ({ items }) => {
    if (!items || items.length === 0) {
        return <Empty description="No records found" />;
    }
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            content={<Table size="small"
                                rowKey={record => `${record._duplicateSetId}.${record._groupSize}.${record._dataObjectId}`}
                                dataSource={items}
                                pagination={false}
                                scroll={{ y: `100vh` }}
                                className={"container-height-100"}>
                                <Column
                                    width="3rem"
                                    title="No."
                                    key="index"
                                    render={(value, item, index) => index + 1}
                                />
                                <Column
                                    width="14rem"
                                    dataIndex={"_dataObjectId"}
                                    title={"Record Id"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, dataObject, index) => {
                                        return (<Tooltip placement="topLeft" dataObjectId={value} title={value}>
                                            <DataObjectShareOption
                                                key={value}
                                                dataObjectId={value}
                                                dataSetId={dataObject._dataSetId}
                                                schemaId={dataObject._schemaId}
                                                businessAreaId={dataObject._businessAreaId}>
                                                <DataObjectLink dataObjectId={value}></DataObjectLink>
                                            </DataObjectShareOption>
                                        </Tooltip>)
                                    }}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"_dataSetName"}
                                    title={"Dataset"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value !== null && value !== undefined && String(value)}>{value !== null && value !== undefined && String(value)}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={"_schemaName"}
                                    title={"Schema"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={"_businessAreaName"}
                                    title={"Business Area"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={"_updatedBy"}
                                    title={"User"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"_updatedDate"}
                                    title={"Date"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={"_dataSetId"}
                                    title={"Search Config"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"_groupId"}
                                    title={"Group"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    title={"Rule"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, item, index) => {
                                        let rule = "";
                                        if (item._deletedDuplicate) {
                                            rule = "Deleted";
                                        }
                                        else if (item._mergedDuplicate) {
                                            rule = "Merged";
                                        }
                                        return <Tooltip placement="topLeft" title={rule}>{rule}</Tooltip>
                                    }}
                                />
                            </Table>}>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DeduplicatedRecordsTable;