import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions';
import { LoadingOverlay, FullHeightContainerLayout } from 'components';
import KeyHistoryList from 'components/organisms/KeysListHistory';
import { showError } from 'common/ToastNotifications';
import { Typography, Empty } from 'antd';
import { withRouter } from 'react-router';

const { Title, Text } = Typography;

const EventSystemKeys = ({ eventId, keyId, keyName, getEventSystemKeys, getEventSystemKeysResult, systemKeyRevision }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentKeyId, setCurrentKeyId] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const isInitialMount = useRef(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setCurrentData(null); 
            await getEventSystemKeys(eventId, keyId);
            setCurrentKeyId(keyId);
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchData();
        } else if (keyId !== currentKeyId) {
            fetchData();
        }
    }, [eventId, keyId, getEventSystemKeys, currentKeyId]);

    useEffect(() => {
        if (getEventSystemKeysResult) {
            if (!getEventSystemKeysResult.success) {
                showError("Could not get system keys at this moment.");
            }
            setIsLoading(false);
            setCurrentData(systemKeyRevision);
        }
    }, [getEventSystemKeysResult, systemKeyRevision]);

    const renderContent = () => {
        if (isLoading || !currentData) {
            return <LoadingOverlay busy={true} spinner message="Getting Key Events..." />;
        }

        return (
            <FullHeightContainerLayout
                showHeader={true}
                showFooter={false}
                header={
                    <Title level={5}>
                        <Text>Event Revision - </Text>
                        <Text type="secondary" italic>
                            {`(Credential: ${keyName})`}
                        </Text>
                    </Title>
                }
                content={
                    currentData ? (
                        <KeyHistoryList systemKeyRevisions={[currentData]} />
                    ) : (
                        <Empty description="No revision data available" />
                    )
                }
            />
        );
    };

    return renderContent();
};

const mapStateToProps = (state) => ({
    getEventSystemKeysResult: state.eventSystemKeys.getEventSystemKeysResult,
    systemKeyRevision: state.eventSystemKeys.systemKeyRevision,
});

const mapDispatchToProps = (dispatch) => ({
    getEventSystemKeys: (eventId, keyId) => dispatch(actions.getEventSystemKeyRequest(eventId, keyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventSystemKeys));