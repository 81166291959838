import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, List, Space, Button, Table, Checkbox, Empty } from "antd";
import { DataObjectVerificationListItem } from 'components';

const DataObjectVerificationList = ({ verificationList }) => {
    return (
        verificationList && verificationList.length > 0
            ?
            <List
                itemLayout="horizontal"
                dataSource={verificationList}
                renderItem={(item) => <DataObjectVerificationListItem verificationItem={item}></DataObjectVerificationListItem>}
            />
            :
            <Empty description="No verification request exist.">
            </Empty>
    );
}

export default DataObjectVerificationList;