import React from 'react';
import { TenantProvision } from 'containers';

const TenantProvisioningPage = (props) => {
    return (
        <div className="flex-column-container">
            <div>
                <TenantProvision></TenantProvision>
            </div>
        </div>
    )
}

export default TenantProvisioningPage