export const GET_SYSTEM_KEY_EVENTS_REQUEST = "GET_SYSTEM_KEY_EVENTS_REQUEST";
export const GET_SYSTEM_KEY_EVENTS_SUCCESS = "GET_SYSTEM_KEY_EVENTS_SUCCESS";
export const GET_SYSTEM_KEY_EVENTS_FAILURE = "GET_SYSTEM_KEY_EVENTS_FAILURE";

export const getSystemKeyEventsRequest = (keyId) => ({
    type: GET_SYSTEM_KEY_EVENTS_REQUEST,
    params: {keyId}
})

export const getSystemKeyEventsSuccess = (systemKeyEventResults) => ({
    type: GET_SYSTEM_KEY_EVENTS_SUCCESS, 
    payload: {
        systemKeyEventResults
    }
})

export const getSystemKeyEventsFailure = (error) => ({
    type: GET_SYSTEM_KEY_EVENTS_FAILURE,
    payload: {
        error
    }
})