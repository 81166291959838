export const SEND_USER_FORGOT_PASSWORD_REQUEST = "SEND_USER_FORGOT_PASSWORD_REQUEST";
export const SEND_USER_FORGOT_PASSWORD_SUCCESS = "SEND_USER_FORGOT_PASSWORD_SUCCESS";
export const SEND_USER_FORGOT_PASSWORD_FAILURE = "SEND_USER_FORGOT_PASSWORD_FAILURE";

export const sendUserForgotPasswordRequest = (email) => ({
    type: SEND_USER_FORGOT_PASSWORD_REQUEST,
    params: {
        email
    }
})

export const sendUserForgotPasswordSuccess = (result) => ({
    type: SEND_USER_FORGOT_PASSWORD_SUCCESS,
    payload: result
})

export const sendUserForgotPasswordFailure = (error) => ({
    type: SEND_USER_FORGOT_PASSWORD_FAILURE,
    payload: error
})