import React, { Component } from 'react';
import { connect } from "react-redux";
import { Message } from 'semantic-ui-react';
import LABELS from 'constants/labels';
import actions from 'store/actions';
import { BusinessAreaList, ManageBusinessArea, LoadingOverlay, ProhibitedArea, OkCancelConfirmationBox } from "components";
import { BusinessAreaAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';
import flatten from 'flat';

class BusinessAreas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            deletedBusinessArea: null,
            showBusinessAreaDeleteConfirmation: false,
            deleteBusinessAreaConfirmMessage: "",
            fetchingPermission: true
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getUserBusinessAreasPermission();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting Business Areas...",
                fetchingBusinessAreas: true
            });
            this.props.getBusinessAreas();
        }
        else {
            this.props.getBusinessAreaById(this.props.businessAreaId);
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting Business Area...",
                    fetchingBusinessArea: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingBusinessAreaData: true
                });
            }
        }
        this.props.getUsers();
        this.props.getAppClients();
        this.props.getSystemConnections();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getUserBusinessAreasPermissionResult && this.props.getUserBusinessAreasPermissionResult !== prevProps.getUserBusinessAreasPermissionResult) {
            if (!this.props.getUserBusinessAreasPermissionResult.success || !this.props.permission) {
                this.setState({
                    permissionDenied: true,
                    fetchingPermission: false
                });
            }
            else {
                this.setState({
                    fetchingPermission: false
                });
            }
        }


        if (this.props.getBusinessAreasResult && this.props.getBusinessAreasResult !== prevProps.getBusinessAreasResult) {
            if (!this.props.getBusinessAreasResult.success) {
                if (this.props.getBusinessAreasResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business areas at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingBusinessAreas: false
            });
        }

        if (this.props.createBusinessAreaResult && this.props.createBusinessAreaResult !== prevProps.createBusinessAreaResult) {
            if (!this.props.createBusinessAreaResult.success) {
                showError("Business area cannot be created.");
                this.setState({
                    busy: false,
                    busyMessage: "",
                    businessAreaErrors: this.props.createBusinessAreaResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Business area created. Please wait..."
                });
                showSuccess("Business area created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }

        if (this.props.getBusinessAreaByIdResult && this.props.getBusinessAreaByIdResult !== prevProps.getBusinessAreaByIdResult) {
            if (!this.props.getBusinessAreaByIdResult.success) {
                if (this.props.getBusinessAreaByIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingBusinessArea: false
            });
            if (!this.props.businessAreaList || this.props.businessAreaList.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }

        if (this.props.deleteBusinessAreaResult && this.props.deleteBusinessAreaResult !== prevProps.deleteBusinessAreaResult) {
            if (!this.props.deleteBusinessAreaResult.success) {
                showError("Business area cannot be deleted.");
            }
            else {
                showSuccess("Business area deleted successfully.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }

        if (this.props.updateBusinessAreaResult && this.props.updateBusinessAreaResult !== prevProps.updateBusinessAreaResult) {
            if (!this.props.updateBusinessAreaResult.success) {
                showError("Business area cannot be updated.");
                this.setState({
                    businessAreaErrors: this.props.updateBusinessAreaResult.fields
                });
                this.setState({
                    busy: false,
                    busyMessage: ""
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Business area updated. Please wait..."
                });
                showSuccess("Business area updated successfully.", () => {
                    this.cancelcreateBusinessArea();
                });
            }
        }

        if (this.props.activateDeactivateBusinessAreaResult && this.props.activateDeactivateBusinessAreaResult !== prevProps.activateDeactivateBusinessAreaResult) {
            if (!this.props.activateDeactivateBusinessAreaResult.success) {
                showError("Business area cannot be updated at this moment.");
            }
            else {
                showSuccess("Business area updated successfully.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Business Areas"
                }
            ];
            if (this.props.action) {
                let businessAreaName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New Business Area"
                        });
                        break;
                    case "view":
                    case "edit":
                        businessAreaName = this.getBusinessAreaName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: businessAreaName
                        }]);
                        break;
                    case "history":
                        businessAreaName = this.getBusinessAreaName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: businessAreaName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getBusinessAreaName = () => {
        let businessAreaName = this.props.businessAreaId;
        if (this.props.businessAreaList && this.props.businessAreaList.length > 0) {
            let businessArea = this.props.businessAreaList.find(businessArea => businessArea.businessAreaId === this.props.businessAreaId);
            if (businessArea) {
                businessAreaName = businessArea.name;
            }
        }
        else if (this.props.businessArea && this.props.businessArea.businessAreaId === this.props.businessAreaId) {
            businessAreaName = this.props.businessArea.name;
        }
        return businessAreaName;
    }

    showAddBusinessArea = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant.toLowerCase())
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    cancelcreateBusinessArea = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    showViewBusinessArea = (businessAreaId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", businessAreaId)
            .replace(":itemAction", "view"));
    }

    showEditBusinessArea = (businessAreaId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", businessAreaId)
            .replace(":itemAction", "edit"));
    }

    showBusinessAreaHistory = (businessAreaId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", businessAreaId)
            .replace(":itemAction", "history"));
    }

    createBusinessArea = (businessArea, users, appClients, systemConnections) => {
        this.setState({
            busy: true,
            busyMessage: "Creating business area..."
        });
        this.props.createBusinessArea(businessArea, users, appClients, systemConnections);
    }

    updateBusinessArea = (businessAreaId, updateBusinessAreaData) => {
        this.setState({
            busy: true,
            busyMessage: "Updating business area..."
        });
        this.props.updateBusinessArea(businessAreaId, updateBusinessAreaData);
    }

    showDeleteBusinessAreaConfirmation = (businessArea) => {
        this.setState({
            deletedBusinessArea: businessArea,
            showBusinessAreaDeleteConfirmation: true,
            deleteBusinessAreaConfirmMessage: `Are you sure, you want to delete '${businessArea.name}' Business Area? You wont be able to access deleted Business Area data.`
        })
    }

    onDeleteBusinessAreaConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting business area..."
        });
        this.props.deleteBusinessArea(this.state.deletedBusinessArea.businessAreaId);
        this.onDeleteBusinessAreaCancel();
    }

    onDeleteBusinessAreaCancel = () => {
        this.setState({
            deletedBusinessArea: null,
            showBusinessAreaDeleteConfirmation: false,
            deleteBusinessAreaConfirmMessage: ""
        })
    }

    activateDeactivateBusinessArea = (businessAreaId, businessAreaVersion, businessAreaActive) => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        this.props.activateDeactivateBusinessArea(businessAreaId, businessAreaVersion, businessAreaActive);
    }

    onBusinessAreaValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.businessAreaErrors) === false) {
            let businessAreaErrors = { ...this.state.businessAreaErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete businessAreaErrors[key];
            }
            this.setState({
                businessAreaErrors
            })
        }
    }

    getBusinessAreaComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return <>
                <OkCancelConfirmationBox
                    show={this.state.showBusinessAreaDeleteConfirmation}
                    message={this.state.deleteBusinessAreaConfirmMessage}
                    onCancel={this.onDeleteBusinessAreaCancel}
                    onOk={this.onDeleteBusinessAreaConfirmed}>
                </OkCancelConfirmationBox>
                {!this.state.fetchingBusinessAreas ? <BusinessAreaList
                    permission={this.props.permission}
                    businessAreaList={this.props.businessAreaList}
                    onAddBusinessArea={this.showAddBusinessArea}
                    onViewBusinessArea={this.showViewBusinessArea}
                    onEditBusinessArea={this.showEditBusinessArea}
                    onShowBusinessAreaHistory={this.showBusinessAreaHistory}
                    onDeleteBusinessArea={this.showDeleteBusinessAreaConfirmation}
                    onActivateDeactivateBusinessArea={this.activateDeactivateBusinessArea} /> : <></>}
            </>
        }
        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageBusinessArea
                    action={this.props.action}
                    permission={this.props.permission}
                    userList={this.props.userList}
                    appClientList={this.props.appClientListResult.Items}
                    systemConnectionList={this.props.systemConnectionListResult.Items}
                    onValuesChanged={this.onBusinessAreaValuesChanged}
                    onCancel={this.cancelcreateBusinessArea}
                    createBusinessArea={this.createBusinessArea}
                    formErrors={this.state.businessAreaErrors}></ManageBusinessArea>;
            case "view":
            case "edit":
                return !this.state.fetchingBusinessArea ? (this.props.businessArea ? <ManageBusinessArea
                    action={this.props.action}
                    permission={this.props.permission}
                    businessArea={this.props.businessArea}
                    userList={this.props.userList}
                    appClientList={this.props.appClientListResult.Items}
                    systemConnectionList={this.props.systemConnectionListResult.Items}
                    onValuesChanged={this.onBusinessAreaValuesChanged}
                    onCancel={this.cancelcreateBusinessArea}
                    updateBusinessArea={this.updateBusinessArea}
                    formErrors={this.state.businessAreaErrors}></ManageBusinessArea> :
                    (this.props.getBusinessAreaByIdResult.success ? <Message floating size='big'>
                        <Message.Header>Business Area not found.</Message.Header>
                        <p>The Business Area you are looking for does not exist.</p>
                    </Message> : <></>)) : <></>;
            case "history":
                return this.state.showHistory ? <BusinessAreaAudit businessArea={this.props.businessArea}></BusinessAreaAudit> : <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <div className="full-height-flex-container">
                <LoadingOverlay
                    busy={this.state.busy || this.state.fetchingPermission}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {!this.state.fetchingPermission ? this.getBusinessAreaComponent() : <></>}
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreaList: state.businessAreas.businessAreaList,
        getBusinessAreasResult: state.businessAreas.getBusinessAreasResult,
        tenant: state.userLogin.tenant,
        userList: state.users.userList,
        appClientListResult: state.appClients.appClientListResult,
        createBusinessAreaResult: state.businessAreas.createBusinessAreaResult,
        getBusinessAreaByIdResult: state.businessAreas.getBusinessAreaByIdResult,
        businessArea: state.businessAreas.businessArea,
        deleteBusinessAreaResult: state.businessAreas.deleteBusinessAreaResult,
        updateBusinessAreaResult: state.businessAreas.updateBusinessAreaResult,
        activateDeactivateBusinessAreaResult: state.businessAreas.activateDeactivateBusinessAreaResult,
        getUserBusinessAreasPermissionResult: state.businessAreas.getUserBusinessAreasPermissionResult,
        permission: state.businessAreas.permission,
        systemConnectionListResult: state.systemConnection.systemConnectionListResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessAreas: () => dispatch(actions.getBusinessAreasRequest()),
        getUsers: () => dispatch(actions.getUsersRequest()),
        getAppClients: () => dispatch(actions.getAppClientsRequest()),
        createBusinessArea: (businessArea, users, appClients, systemConnections) => dispatch(actions.createBusinessAreaRequest(businessArea, users, appClients, systemConnections)),
        getBusinessAreaById: (businessAreaId) => dispatch(actions.getBusinessAreaByIdRequest(businessAreaId)),
        deleteBusinessArea: (businessAreaId) => dispatch(actions.deleteBusinessAreaRequest(businessAreaId)),
        updateBusinessArea: (businessAreaId, updateBusinessAreaData) => dispatch(actions.updateBusinessAreaRequest(updateBusinessAreaData, businessAreaId)),
        activateDeactivateBusinessArea: (businessAreaId, businessAreaVersion, businessAreaActive) => dispatch(actions.activateDeactivateBusinessAreaRequest(businessAreaId, businessAreaVersion, businessAreaActive)),
        getUserBusinessAreasPermission: () => dispatch(actions.getUserBusinessAreasPermissionRequest()),
        getSystemConnections: () => dispatch(actions.getSystemConnectionsRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreas));