export default Object.freeze(
    {
        "hh:mm:ss a": {
            name: "hh:mm:ss a"
        },
        "hh:mm:ss A": {
            name: "hh:mm:ss A"
        },
        "hh:mm a": {
            name: "hh:mm a"
        },
        "hh:mm A": {
            name: "hh:mm A"
        },
        "HH:mm:ss": {
            name: "HH:mm:ss"
        },
        "HH:mm": {
            name: "HH:mm"
        },
    });