import React from 'react';
import { useParams } from "react-router-dom";
import { AdminConsole } from 'components';

const AdminConsolePage = (props) => {
    return (
        <div className="flex-column-container">
            <div>
                <AdminConsole></AdminConsole>
            </div>
        </div>
    )
}

export default AdminConsolePage
