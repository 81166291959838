import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, BusinessAreaHistoryList, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class BusinessAreaAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        this.props.getBusinessAreaHistory(this.props.businessArea.businessAreaId);
        this.props.getBusinessAreaEvents(this.props.businessArea.businessAreaId, this.props.businessArea.businessAreaId);
        this.setState({
            fetchingHistory: true,
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetBusinessAreaHistoryResultUpdate(prevProps);
        this.onGetBusinessAreaEventsResultUpdate(prevProps);
    }

    onGetBusinessAreaHistoryResultUpdate = (prevProps) => {
        if (this.props.getBusinessAreaHistoryResult && this.props.getBusinessAreaHistoryResult !== prevProps.getBusinessAreaHistoryResult) {
            if (!this.props.getBusinessAreaHistoryResult.success) {
                if (this.props.getBusinessAreaHistoryResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area history.");
                }
            }
            this.setState({
                fetchingHistory: false
            });
        }
    }

    onGetBusinessAreaEventsResultUpdate = (prevProps) => {
        if (this.props.getBusinessAreaEventsResult && this.props.getBusinessAreaEventsResult !== prevProps.getBusinessAreaEventsResult) {
            if (!this.props.getBusinessAreaEventsResult.success) {
                if (this.props.getBusinessAreaEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Tabs defaultActiveKey="1" className="data-audit-tabs">
                        <TabPane tab="Events" key="1">
                            {
                                this.state.fetchingEvents
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting Business Area events...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <EventList eventList={this.props.businessAreaEventsResult.Items}>
                                            </EventList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                        <TabPane tab="Revisions" key="2">
                            {
                                this.state.fetchingHistory
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting Business Area history...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <BusinessAreaHistoryList
                                                businessAreaRevisions={this.props.businessAreaHistoryResult.Items}>
                                            </BusinessAreaHistoryList>
                                        }>
                                    </FullHeightContainerLayout>
                            }

                        </TabPane>
                    </Tabs>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getBusinessAreaHistoryResult: state.businessAreaAudit.getBusinessAreaHistoryResult,
        businessAreaHistoryResult: state.businessAreaAudit.businessAreaHistoryResult,
        getBusinessAreaEventsResult: state.businessAreaAudit.getBusinessAreaEventsResult,
        businessAreaEventsResult: state.businessAreaAudit.businessAreaEventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessAreaHistory: (businessAreaId) => dispatch(actions.getBusinessAreaHistoryRequest(businessAreaId)),
        getBusinessAreaEvents: (businessAreaId) => dispatch(actions.getBusinessAreaEventsRequest(businessAreaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreaAudit));