import React, { useState } from "react";
import { Space, Table, Result, Row, Col, Tooltip, List, Typography } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FullHeightContainerLayout, DataObjectChangedAttributes, FormDisplayField, SchemaFieldTitle, LoadingOverlay, DataObjectLink } from "components";
import _ from 'lodash';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const DataObjectHistoryList = ({
    loading,
    dataObjectRevisions,
    schemaFields,
    showChanges = true,
    showSurvivalRecord = false,
    survivedRecordResult = null,
    onSurvivalRecordOptionClick = () => { }
}) => {
    const [recordDetail, setRecordDetail] = useState(null);
    const [containerHeight, setContainerHeight] = useState("100%");

    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            content={
                                <Table size="small"
                                    rowKey="metadata.txId"
                                    dataSource={dataObjectRevisions}
                                    pagination={false}
                                    scroll={{ y: `100vh`, x: 150 * ((schemaFields && schemaFields.length) || 1) }}
                                    loading={loading ? { size: "large", tip: "Loading DataObject..." } : false}
                                    className={["container-height-100", "schema-list-table"].join(" ")}
                                    rowClassName={(item, index) => {
                                        if (item.data && item.data.errors && item.data.errors.length > 0) {
                                            return "error-row";
                                        }
                                        return null;
                                    }}>
                                    {
                                        [
                                            <Column
                                                width="2rem"
                                                key={"actions"}
                                                render={(value, item, index) => <div>
                                                    {item.data.errors && item.data.errors.length > 0 > 0 ? <Tooltip placement="topLeft" title={`Record contains ${item.data.errors.length} error(s)`}><WarningTwoTone twoToneColor="#FF0000" /></Tooltip> : <></>}
                                                </div>}
                                            />,
                                            <Column
                                                width="5%"
                                                title="No."
                                                key="index"
                                                render={(value, item, index) => item.sNo || index + 1}
                                            />,
                                            ...(schemaFields && schemaFields.map(field => {
                                                return <Column
                                                    key={field.fieldId}
                                                    dataIndex={["data", field.fieldId]}
                                                    title={<SchemaFieldTitle name={field.name} tags={field.classifiers}></SchemaFieldTitle>}
                                                    render={(value, item, index) => {
                                                        let fieldError = null;
                                                        if (item.data.errors && item.data.errors.length > 0) {
                                                            for (let error of item.data.errors) {
                                                                if (error.name === field.fieldId) {
                                                                    fieldError = error.errors;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        return <FormDisplayField fieldError={fieldError} value={value} {...field}></FormDisplayField>
                                                    }}
                                                />
                                            }) || []),
                                            showChanges
                                                ?
                                                <Column
                                                    width="12rem"
                                                    dataIndex={"changes"}
                                                    title={"Changes"}
                                                    render={(value, revision, index) => {
                                                        if (value) {
                                                            return <>
                                                                {Object.keys(value).reduce((items, key) => {
                                                                    if (Object.keys(value[key]).length > 0) {
                                                                        items.push(<Row key={key}>
                                                                            <Col>
                                                                                <Link onClick={() => {
                                                                                    setContainerHeight("50%");
                                                                                    setRecordDetail({
                                                                                        name: `${_.capitalize(key)} attributes`,
                                                                                        recordId: revision.data.dataSetId,
                                                                                        data: value[key],
                                                                                        previousData: revision.previousChanges && revision.previousChanges[key],
                                                                                        schemaFields
                                                                                    });
                                                                                }}>
                                                                                    {`${key} attributes`}
                                                                                </Link>
                                                                            </Col>
                                                                        </Row>);
                                                                    }
                                                                    return items;
                                                                }, [])}
                                                            </>
                                                        }
                                                        return <></>;
                                                    }}
                                                />
                                                :
                                                <></>,
                                            showSurvivalRecord
                                                ?
                                                <Column
                                                    width="12rem"
                                                    render={(value, revision, index) => {
                                                        return <Row>
                                                            <Col>
                                                                <Link onClick={() => {
                                                                    onSurvivalRecordOptionClick();
                                                                }}>
                                                                    Survived record
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    }}
                                                />
                                                :
                                                <></>
                                        ]
                                    }
                                </Table>
                            }
                            showFooter={false}>
                        </FullHeightContainerLayout>

                    </Col>
                </Row>
                {
                    recordDetail
                        ?
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24}>
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    content={<DataObjectChangedAttributes key={recordDetail.recordId} record={recordDetail}></DataObjectChangedAttributes>}
                                    showFooter={false}>
                                </FullHeightContainerLayout>
                            </Col>
                        </Row>
                        :
                        <></>
                }
                {
                    survivedRecordResult && (
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24}>
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    content={survivedRecordResult.dataObject && survivedRecordResult.job
                                        ?
                                        <Table
                                            dataSource={[survivedRecordResult.dataObject]}
                                            size="small"
                                            rowKey="_dataObjectId"
                                            scroll={{
                                                x: 150 * (((survivedRecordResult.job.jobInfo.dataTags && survivedRecordResult.job.jobInfo.dataTags.length) || 1) + 7),
                                                y: `100vh`
                                            }}
                                            className={['container-height-100', "schema-list-table"].join(" ")}
                                            pagination={false}>
                                            {
                                                [
                                                    <Column
                                                        width={"6rem"}
                                                        title={<><>No. </></>}
                                                        key="index"
                                                        render={(value, dataObject, index) => <>{dataObject.sNo || index + 1}</>}
                                                    />,
                                                    <Column
                                                        title="Search Config ID"
                                                        dataIndex={"_duplicateSetId"}
                                                        key="_duplicateSetId"
                                                        ellipsis={{
                                                            showTitle: false,
                                                        }}
                                                        render={(value, dataObject, index) => {
                                                            return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                                        }}
                                                    />,
                                                    <Column
                                                        width={"8rem"}
                                                        title="Grouping ID"
                                                        dataIndex={"_groupId"}
                                                        key="_groupId"
                                                        render={(value, dataObject, index) => {
                                                            return <Space>
                                                                <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                                            </Space>
                                                        }}
                                                    />,
                                                    <Column
                                                        width={"10rem"}
                                                        title="Dup Rule"
                                                        dataIndex={"_deduplicationOption"}
                                                        key="_deduplicationOption"
                                                        render={(value, dataObject, index) => {
                                                            return value && <Text type="warning">{dataObject._deduplicationOption === "DELETE_DUPLICATES" ? "Delete duplicates" : "Merge duplicates"}</Text>
                                                        }}
                                                    />,
                                                    <Column
                                                        title="Business Area"
                                                        dataIndex={"_businessAreaName"}
                                                        key="_businessAreaName"
                                                        render={(value, dataObject, index) => {
                                                            return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                                        }}
                                                    />,
                                                    <Column
                                                        title="Schema"
                                                        dataIndex={"_schemaName"}
                                                        key="_schemaName"
                                                        render={(value, dataObject, index) => {
                                                            return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                                        }}
                                                    />,
                                                    <Column
                                                        title="Dataset"
                                                        dataIndex={"_dataSetName"}
                                                        key="_dataSetName"
                                                        render={(value, dataObject, index) => {
                                                            return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                                        }}
                                                    />,
                                                    <Column
                                                        title="Record ID"
                                                        dataIndex={"_dataObjectId"}
                                                        key="_dataObjectId"
                                                        ellipsis={{
                                                            showTitle: false,
                                                        }}
                                                        render={(value, dataObject, index) => {
                                                            return <Tooltip placement="topLeft" title={value}><DataObjectLink dataObjectId={value}></DataObjectLink></Tooltip>
                                                        }}
                                                    />,
                                                    ...(survivedRecordResult.job.jobInfo.dataTags && survivedRecordResult.job.jobInfo.dataTags.map((tag, index) => {
                                                        return <Column
                                                            key={index}
                                                            dataIndex={tag}
                                                            title={tag}
                                                            render={(value, dataObject, index) => {
                                                                if (dataObject._tags && dataObject._tags[tag] && dataObject._tags[tag].length > 0) {
                                                                    return dataObject._tags[tag].map(field => {
                                                                        return <Row key={field.fieldId}>
                                                                            <Col><><Text strong underline>{field.name}</Text><Text strong>{":"}&nbsp;</Text></></Col>
                                                                            <Col><Tooltip placement="topLeft" title={field.value}>{field.value}</Tooltip></Col>
                                                                        </Row>
                                                                    });
                                                                }
                                                                return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                                            }}
                                                        />
                                                    }) || [])
                                                ]
                                            }
                                        </Table>
                                        :
                                        (
                                            survivedRecordResult.inProgress
                                                ?
                                                <LoadingOverlay
                                                    busy={true}
                                                    spinner
                                                    message={"Please wait..."}>
                                                </LoadingOverlay>
                                                :
                                                <Result
                                                    style={{ padding: "unset" }}
                                                    icon={<WarningTwoTone />}
                                                    title="The duplicate search config record doesn't exist"
                                                />
                                        )}
                                    showFooter={false}>
                                </FullHeightContainerLayout>
                            </Col>
                        </Row>
                    )
                }
            </Col>
        </Row>
    );
};

export default DataObjectHistoryList;