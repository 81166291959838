import {
    GET_BUSINESS_AREAS_SUCCESS,
    GET_BUSINESS_AREAS_FAILURE,

    CREATE_BUSINESS_AREA_SUCCESS,
    CREATE_BUSINESS_AREA_FAILURE,

    GET_BUSINESS_AREA_BY_ID_SUCCESS,
    GET_BUSINESS_AREA_BY_ID_FAILURE,

    DELETE_BUSINESS_AREA_SUCCESS,
    DELETE_BUSINESS_AREA_FAILURE,

    UPDATE_BUSINESS_AREA_SUCCESS,
    UPDATE_BUSINESS_AREA_FAILURE,

    ACTIVATE_DEACTIVATE_BUSINESS_AREA_SUCCESS,
    ACTIVATE_DEACTIVATE_BUSINESS_AREA_FAILURE,

    GET_USER_BUSINESS_AREAS_PERMISSION_SUCCESS,
    GET_USER_BUSINESS_AREAS_PERMISSION_FAILURE,

    GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_SUCCESS,
    GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_FAILURE
} from "./actions";

const initialState = {
    getBusinessAreasResult: {
        success: true
    },
    businessAreaList: [],
    createBusinessAreaResult: {
        success: true
    },
    getBusinessAreaByIdResult: {
        success: true
    },
    businessArea: null,
    deleteBusinessAreaResult: {
        success: true
    },
    updateBusinessAreaResult: {
        success: true
    },
    activateDeactivateBusinessAreaResult: {
        success: true
    },
    getUserBusinessAreasPermissionResult: {
        success: true
    },
    permission: null,
    getBusinessAreaSystemConnectionsResult: {
        success: true
    },
    businessAreaSystemConnectionListResult: {
        Items: []
    },
};

export default (state = initialState, action) => {
    let businessAreaList = [];
    switch (action.type) {
        case GET_BUSINESS_AREAS_SUCCESS:
            businessAreaList = action.payload;
            return { ...state, getBusinessAreasResult: { success: true }, businessAreaList };
        case GET_BUSINESS_AREAS_FAILURE:
            return { ...state, getBusinessAreasResult: { success: false, ...action.payload }, businessAreaList: [] };
        case CREATE_BUSINESS_AREA_SUCCESS:
            return { ...state, createBusinessAreaResult: { success: true } };
        case CREATE_BUSINESS_AREA_FAILURE:
            return { ...state, createBusinessAreaResult: { success: false, ...action.payload } };
        case GET_BUSINESS_AREA_BY_ID_SUCCESS:
            return { ...state, getBusinessAreaByIdResult: { success: true }, businessArea: action.payload };
        case GET_BUSINESS_AREA_BY_ID_FAILURE:
            return { ...state, getBusinessAreaByIdResult: { success: false, ...action.payload }, businessArea: null };
        case DELETE_BUSINESS_AREA_SUCCESS:
            return { ...state, deleteBusinessAreaResult: { success: true } };
        case DELETE_BUSINESS_AREA_FAILURE:
            return { ...state, deleteBusinessAreaResult: { success: false, ...action.payload } };
        case UPDATE_BUSINESS_AREA_SUCCESS:
            return { ...state, updateBusinessAreaResult: { success: true } };
        case UPDATE_BUSINESS_AREA_FAILURE:
            return { ...state, updateBusinessAreaResult: { success: false, ...action.payload } };
        case ACTIVATE_DEACTIVATE_BUSINESS_AREA_SUCCESS:
            return { ...state, activateDeactivateBusinessAreaResult: { success: true } };
        case ACTIVATE_DEACTIVATE_BUSINESS_AREA_FAILURE:
            return { ...state, activateDeactivateBusinessAreaResult: { success: false, ...action.payload.error } };
        case GET_USER_BUSINESS_AREAS_PERMISSION_SUCCESS:
            return { ...state, getUserBusinessAreasPermissionResult: { success: true }, permission: action.payload };
        case GET_USER_BUSINESS_AREAS_PERMISSION_FAILURE:
            return { ...state, getUserBusinessAreasPermissionResult: { success: false, ...action.payload }, permission: null };
        case GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_SUCCESS:
            return { ...state, getBusinessAreaSystemConnectionsResult: { success: true }, businessAreaSystemConnectionListResult: action.payload.result };
        case GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_FAILURE:
            return { ...state, getBusinessAreaSystemConnectionsResult: { success: false, ...action.payload.error }, businessAreaSystemConnectionListResult: { Items: [] } };
        default:
            return state;
    }
};
