import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { DataObjectUUID } from 'containers';
import { PageLayout } from 'components';
import { Redirect } from 'react-router-dom';
import AppPaths from 'constants/appPaths';

const DataObjectUUIDPage = ({ tenant, ...props }) => {
    const { uuid } = useParams();

    return (
        <>
            {
                tenant && tenant.name && tenant.name === uuid
                    ?
                    <Redirect to={AppPaths.TENANT_HOME.replace(":tenant", tenant.name)}></Redirect>
                    :
                    <PageLayout
                        key={uuid}
                        mainContent={({ setBreadcrumbNavigationItems }) => {
                            return <DataObjectUUID dataObjectId={uuid} tenant={tenant.name}></DataObjectUUID>
                        }}
                        helpContent={() => {
                            return <></>;
                        }}>
                    </PageLayout>
            }
        </>
    )
}

export default DataObjectUUIDPage
