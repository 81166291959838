
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* getPicklistDomainData(api, tenantId, dataDomainId, pageKey) {
    let params = {
    };
    if (pageKey) {
        params.pageKey = pageKey
    }
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datadomains/${dataDomainId}/domaindata`, { tenantid: tenantId }, {
        params
    });
    return result.data;
}

export function* getPicklistDomainDataRequest(api, { dataDomainId, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        let domainDataResult = yield call(getPicklistDomainData, api, tenant.tenantId, dataDomainId, pageKey);
        yield put(actions.getPicklistDomainDataSuccess(dataDomainId, domainDataResult));

    } catch (error) {
        let errorObject = errorHandler(error, "Get", "picklist domain data");
        yield put(actions.getPicklistDomainDataFailure(dataDomainId, errorObject));
    }
}

export function* watchGetPicklistDomainDataRequest(api, { params }) {
    yield call(getPicklistDomainDataRequest, api, params);
}

export default function* ({ api }) {
    yield takeEvery(actions.GET_PICKLIST_DOMAIN_DATA_REQUEST, watchGetPicklistDomainDataRequest, api);
}