import {
    GET_SYSTEM_CONNECTIONS_SUCCESS,
    GET_SYSTEM_CONNECTIONS_FAILURE,

    GET_SYSTEM_CONNECTION_PERMISSIONS_SUCCESS,
    GET_SYSTEM_CONNECTION_PERMISSIONS_FAILURE
} from "./actions";

import {
    UPDATE_AWS_S3_CONNECTION_SUCCESS,
    DELETE_AWS_S3_CONNECTION_SUCCESS
} from '../systemConnectionAwsS3/actions'

const initialState = {
    getSystemConnectionsResult: {
        success: true
    },
    systemConnectionListResult: {
        Items: []
    },
    getSystemConnectionPermissionResult: {
        success: true,
    },
    userSystemConnectionPermission: null,
};

export default (state = initialState, action) => {
    let systemConnectionListResult = {
        Items: []
    };
    let connectionIndex = -1;
    switch (action.type) {
        case GET_SYSTEM_CONNECTIONS_SUCCESS:
            return { ...state, getSystemConnectionsResult: { success: true }, systemConnectionListResult: action.payload.result };
        case GET_SYSTEM_CONNECTIONS_FAILURE:
            return { ...state, getSystemConnectionsResult: { success: false, ...action.payload.error }, systemConnectionListResult: { Items: [] } };
        case UPDATE_AWS_S3_CONNECTION_SUCCESS:
            systemConnectionListResult = state.systemConnectionListResult;
            if (action.payload.connection) {
                connectionIndex = systemConnectionListResult.Items.findIndex(item => item.connectionId === action.payload.connection.connectionId);
                if (connectionIndex > -1) {
                    systemConnectionListResult = { ...systemConnectionListResult };
                    systemConnectionListResult.Items = [...systemConnectionListResult.Items];
                    systemConnectionListResult.Items[connectionIndex] = action.payload.connection;
                }
            }
            return { ...state, systemConnectionListResult };
        case DELETE_AWS_S3_CONNECTION_SUCCESS:
            systemConnectionListResult = { ...state.systemConnectionListResult };
            systemConnectionListResult.Items = state.systemConnectionListResult.Items.filter(item => item.connectionId !== action.payload.connectionId);
            return { ...state, systemConnectionListResult };
        case GET_SYSTEM_CONNECTION_PERMISSIONS_SUCCESS:
            return { ...state, getSystemConnectionPermissionResult: { success: true }, userSystemConnectionPermission: action.payload.permission };
        case GET_SYSTEM_CONNECTION_PERMISSIONS_FAILURE:
            return { ...state, getSystemConnectionPermissionResult: { success: false, ...action.payload.error }, userSystemConnectionPermission: null };
        default:
            return state;
    }
};
