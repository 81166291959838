import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tooltip, Space } from 'antd';
import actions from 'store/actions';
import {
    NotFoundError,
    LoadingOverlay,
    ProhibitedArea,
    HoverMenu,
    ShareDataObjectModalDialog
} from "components";
import { showError, showSuccess, showWarning, showInfo } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { PlusOutlined, WarningTwoTone, ShareAltOutlined } from "@ant-design/icons";
import moment from 'moment';

class DataObjectShareOption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showShareModalDialog: false,
            gettingLink: false,
            shareLink: null
        }
    }

    componentWillMount() {
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataObjectSharedLinkResultChanged(prevProps);
    }

    onGetDataObjectSharedLinkResultChanged = (prevProps) => {
        if (this.props.getDataObjectSharedLinkResult && this.props.getDataObjectSharedLinkResult !== prevProps.getDataObjectSharedLinkResult) {
            if (!this.props.getDataObjectSharedLinkResult.success) {
                showError("Could not able to get share link.");
            }
            else {
                this.setState({
                    shareLink: this.props.dataObjectSharedLinkResult.url
                });
            }
            this.setState({
                gettingLink: false
            });
        }
    }

    onShareClick = (e) => {
        e.stopPropagation && e.stopPropagation();
        e.preventDefault && e.preventDefault();
        e.stopImmediatePropagation && e.stopImmediatePropagation();
        this.setState({ showShareModalDialog: true, shareLink: null })
    }

    onGetLink = ({ readOnly, expiryDate }) => {
        if (this.state.gettingLink) {
            return;
        }
        this.setState({
            gettingLink: true
        });
        this.props.getDataObjectSharedLink(this.props.businessAreaId,
            this.props.schemaId,
            this.props.dataSetId,
            this.props.dataObjectId,
            expiryDate ? expiryDate.utc().format("YYYYMMDDHHmmss") : moment().add(5, 'minutes').utc().format("YYYYMMDDHHmmss"),
            readOnly);
    }

    onClose = () => {
        this.setState({
            shareLink: null,
            showShareModalDialog: false
        });
    }

    render() {
        return <>
            <ShareDataObjectModalDialog
                show={this.state.showShareModalDialog}
                onGetLink={this.onGetLink}
                loading={this.state.gettingLink}
                shareLink={this.state.shareLink}
                onClose={this.onClose}></ShareDataObjectModalDialog>
            <Row>
                <Col span={4}>
                    <Tooltip title={"Share link"}><ShareAltOutlined style={{ cursor: "pointer" }} onClick={this.onShareClick} /></Tooltip>
                    {/* <HoverMenu
                    items={[
                        {
                            label: 'Share link',
                            key: 'SHARE_LINK',
                            disabled: false,
                            icon: <ShareAltOutlined style={{ cursor: "pointer" }} />
                        }
                    ]}>
                     
                </HoverMenu> */}
                </Col>
                <Col span={20}>
                    {this.props.children}
                </Col>
            </Row>
        </>

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataObjectSharedLinkResult: state.dataObjects.getDataObjectSharedLinkResult,
        dataObjectSharedLinkResult: state.dataObjects.dataObjectSharedLinkResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataObjectSharedLink: (businessAreaId, schemaId, dataSetId, dataObjectId, expires, readOnly) => dispatch(actions.getDataObjectSharedLinkRequest(businessAreaId, schemaId, dataSetId, dataObjectId, expires, readOnly))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectShareOption));