import React from "react";
import { connect } from "react-redux";
import { Icon } from 'semantic-ui-react';
import { withRouter } from "react-router";
import AppPaths from 'constants/appPaths';
import { Menu } from "antd";

class UserProfileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <div>
                <Menu
                    mode="inline"
                    style={{ width: "100%", border: 0 }}
                    items={[
                        {
                            label: <><Icon color='blue' name='user' /> {`${this.props.user.firstName} ${this.props.user.lastName}`}</>,
                            key: 'USER',
                            disabled: true
                        },
                        {
                            label: <><Icon color='blue' name='address card' /> User Profile</>,
                            key: 'USER_PROFILE',
                            onClick: () => { this.props.closeMenu && this.props.closeMenu(); this.props.history.push(AppPaths.TENANT_USER_PROFILE.replace(":tenant", this.props.tenant.name)) }
                        }
                    ]}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.userLogin.tenant,
        user: state.userLogin.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfileMenu));