import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { FullHeightContainerLayout, LoadingOverlay, BasicFormBuilder } from "components";
import { Row, Col, Modal, Spin, Button, Space, Checkbox } from 'antd';
import { DataObjectAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";

class NominatedValueSelectionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            selectedValue: this.props.selectedValue
        }
    }

    componentWillMount() {
        this.props.getNominatedValueDataObjects(this.props.fieldName, this.props.dataObjectGroup.map(item => (
            {
                businessAreaId: item._businessAreaId,
                schemaId: item._schemaId,
                dataSetId: item._dataSetId,
                dataObjectId: item._dataObjectId
            }
        )));
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetNominatedValueDataObjectsRequestResultChanged(prevProps);
    }

    onGetNominatedValueDataObjectsRequestResultChanged = (prevProps) => {
        if (this.props.getNominatedValueDataObjectsRequestResult && this.props.getNominatedValueDataObjectsRequestResult !== prevProps.getNominatedValueDataObjectsRequestResult) {
            if (!this.props.getNominatedValueDataObjectsRequestResult.success) {
                showError("Could not able to get records at this moment");
            }
        }
    }

    onOkClicK = () => {
        this.setState({
            showModal: false
        });
        this.props.onOkClick(this.state.selectedValue);
    };

    onCancelClick = () => {
        this.setState({
            showModal: false
        });
        this.props.onCancelClick();
    };

    onValueSelectionChanged = (dataObjectId, fieldId) => {
        this.setState({
            selectedValue: {
                dataObjectId,
                fieldId
            }
        });
    }

    render() {
        return <Modal
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            title="Select Value"
            style={{ top: 20 }}
            open={this.state.showModal}
            visible={this.state.showModal}
            onOk={this.onOkClicK}
            onCancel={this.onCancelClick}
            bodyStyle={{ height: "80vh", width: "90vh", paddingTop: "0px" }}
            width="90vh"
            footer={null}>
            <Row style={{ background: "#fffff", overflowY: "auto", width: "100%", height: "100%" }} className="layout-row">
                <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                    <FullHeightContainerLayout
                        showHeader={this.props.getNominatedValueDataObjectsRequestResult.inProgress}
                        header={<Spin className="inline-spin-text" tip="Getting records..." size="small"></Spin>}
                        headerStyle={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                        showFooter={true}
                        content={
                            <>
                                {
                                    this.props.nominatedValueDataObjects.Items.map(item => {
                                        return item.schemaModel && item.dataObject ?
                                            <Row>
                                                <Col span={22}>
                                                    <BasicFormBuilder
                                                        key={item.dataObject.dataObjectId}
                                                        formDataObject={item.dataObject}
                                                        schemaFields={item.schemaModel.fields}
                                                        readOnly={true}
                                                        showHeader={false}>
                                                    </BasicFormBuilder>
                                                </Col>
                                                <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    <Checkbox checked={this.state.selectedValue && this.state.selectedValue.dataObjectId === item.dataObject.dataObjectId} onChange={() => this.onValueSelectionChanged(item.dataObject.dataObjectId, item.schemaModel.fields[0].fieldId)}></Checkbox>
                                                </Col>
                                            </Row>
                                            :
                                            <></>
                                    })
                                }
                            </>
                        }
                        footer={
                            <Row>
                                <Col span={24} className="footer-right-column">
                                    <Space>
                                        <Button onClick={this.onCancelClick}>Cancel</Button>
                                        <Button type="primary" onClick={this.onOkClicK}>Ok</Button>
                                    </Space>
                                </Col>
                            </Row>
                        }>
                    </FullHeightContainerLayout>
                </Col>
            </Row>
        </Modal>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getNominatedValueDataObjectsRequestResult: state.nominatedValueSelectionList.getNominatedValueDataObjectsRequestResult,
        nominatedValueDataObjects: state.nominatedValueSelectionList.nominatedValueDataObjects,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNominatedValueDataObjects: (nominatedRecordFieldName, dataObjectRequests) => dispatch(actions.getNominatedValueDataObjectsRequest(nominatedRecordFieldName, dataObjectRequests))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NominatedValueSelectionList);