import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";

/**
 * Dropdown menu that supports Form, dropdown menu fits the content without truncation.
 * Different from antd's Select where menu fits the label instead of the content.
 */
export default function FormDropDownMenu({
    id,
    value = "",
    items,
    label,
    disabled = false,
    loading = false,
    selectable = true,
    icon,
    iconPosition = "end",
    onChange,
}: IFormDropDownMenuProps) {
    const triggerChange = (changedValue: string) => {
        onChange?.(changedValue);
    };

    const handleClick: MenuProps["onClick"] = ({ key }) => {
        triggerChange(key);
    };

    return (
        <Dropdown
            menu={{
                items: items,
                onClick: handleClick,
                selectable: selectable,
                selectedKeys: [value],
            }}
            placement="bottomRight"
            trigger={["click"]}
        >
            <Button
                id={id}
                style={CustomDropDownButtonStyle}
                icon={icon ?? <DownOutlined style={{ color: "#aaa" }} />}
                iconPosition={iconPosition}
                disabled={disabled}
                loading={loading}
            >
                {label}
            </Button>
        </Dropdown>
    );
}

const CustomDropDownButtonStyle: React.CSSProperties = {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};
