import React, { useState, useEffect } from "react";
import { Form, Select, Button, Row, Col, Input } from "antd";
import _ from 'lodash';
import {
    FullHeightContainerLayout,
    TagSearchInput
} from 'components';
import { nameRules, descriptionRules } from 'common/FormValidationRules';

const { Option } = Select;
const DEFAULT_BUSINESS_AREAS = [{ key: "all", value: "All Business Areas" }];
const DEFAULT_SCHEMA_LIST = [{ key: "all", value: "All Schemas" }];
const DEFAULT_DATA_SET_LIST = [{ key: "all", value: "All Datasets" }];

const DataObjectSearchDuplicateForm = ({
    defaultSearchValues,
    businessAreas,
    onBusinessAreaChange,
    loadingSchemaList,
    schemaList,
    onSchemaChange,
    loadingDataSetList,
    dataSetList,
    loadingDataTags,
    dataTags,
    onSearch
}) => {

    const [form] = Form.useForm();
    const [businessAreaOptions, setBusinessAreaOptions] = useState(DEFAULT_BUSINESS_AREAS);
    const [schemaOptions, setSchemaOptions] = useState(DEFAULT_SCHEMA_LIST);
    const [dataSetOptions, setDataSetOptions] = useState(DEFAULT_DATA_SET_LIST);

    useEffect(() => {
        setBusinessAreaOptions([...DEFAULT_BUSINESS_AREAS, ...businessAreas.map(item => ({ key: item.businessAreaId, value: item.name }))])
    }, [businessAreas]);

    useEffect(() => {
        setSchemaOptions([...DEFAULT_SCHEMA_LIST, ...schemaList.Items.map(item => ({ key: item.schemaId, value: item.name }))])
    }, [schemaList]);

    useEffect(() => {
        setDataSetOptions([...DEFAULT_DATA_SET_LIST, ...dataSetList.Items.map(item => ({ key: item.dataSetId, value: item.dataSetName }))])
    }, [dataSetList]);

    const onFormValuesChanged = (changedValues, allValues) => {
        if (changedValues.businessArea) {
            allValues["schema"] = DEFAULT_SCHEMA_LIST[0].key;
            allValues["dataSet"] = DEFAULT_DATA_SET_LIST[0].key;
            form.setFieldsValue(allValues);
            setSchemaOptions(DEFAULT_SCHEMA_LIST);
            setDataSetOptions(DEFAULT_DATA_SET_LIST);
            onBusinessAreaChange(changedValues.businessArea);
        }
        else if (changedValues.schema) {
            allValues["dataSet"] = DEFAULT_DATA_SET_LIST[0].key;
            form.setFieldsValue(allValues);
            setDataSetOptions(DEFAULT_DATA_SET_LIST);
            onSchemaChange(allValues.businessArea, changedValues.schema);
        }
    }

    return (
        <>
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Form
                        form={form}
                        initialValues={defaultSearchValues}
                        name="basic"
                        layout="vertical"
                        onFinish={onSearch}
                        onValuesChange={onFormValuesChanged}>
                        <FullHeightContainerLayout
                            showHeader={false}
                            showFooter={false}
                            content={
                                <>
                                    <Row gutter={8}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["name"]}
                                                rules={nameRules}
                                                validateFirst={true}>
                                                <Input placeholder="Search configuration name" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["description"]}
                                                rules={descriptionRules}>
                                                <Input placeholder="Search configuration description" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col span={8}>
                                            <Form.Item
                                                initialValue={DEFAULT_BUSINESS_AREAS[0].key}
                                                name="businessArea">
                                                <Select placeholder="Please select business area">
                                                    {
                                                        businessAreaOptions.map(option => <Option key={option.key} value={option.key}>{option.value}</Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                initialValue={DEFAULT_SCHEMA_LIST[0].key}
                                                name="schema">
                                                <Select placeholder="Please select schema" loading={loadingSchemaList}>
                                                    {
                                                        schemaOptions.map(option => <Option key={option.key} value={option.key}>{option.value}</Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                initialValue={DEFAULT_DATA_SET_LIST[0].key}
                                                name="dataSet">
                                                <Select placeholder="Please select dataset" loading={loadingDataSetList}>
                                                    {
                                                        dataSetOptions.map(option => <Option key={option.key} value={option.key}>{option.value}</Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="dataTags">
                                                <TagSearchInput loadingDataTags={loadingDataTags} dataTags={dataTags} onSearch={() => form.submit()}></TagSearchInput>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            }>
                        </FullHeightContainerLayout>
                    </Form>
                }>
            </FullHeightContainerLayout>
        </>
    );
};

export default DataObjectSearchDuplicateForm;