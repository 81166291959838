export const GET_EVENT_DATA_DOMAIN_REQUEST = "GET_EVENT_DATA_DOMAIN_REQUEST";
export const GET_EVENT_DATA_DOMAIN_SUCCESS = "GET_EVENT_DATA_DOMAIN_SUCCESS";
export const GET_EVENT_DATA_DOMAIN_FAILURE = "GET_EVENT_DATA_DOMAIN_FAILURE";

export const getEventDataDomainRequest = (eventId, dataDomainId) => ({
    type: GET_EVENT_DATA_DOMAIN_REQUEST,
    params: {
        eventId,
        dataDomainId
    }
})

export const getEventDataDomainSuccess = (dataDomainResult) => ({
    type: GET_EVENT_DATA_DOMAIN_SUCCESS,
    payload: dataDomainResult
})

export const getEventDataDomainFailure = (error) => ({
    type: GET_EVENT_DATA_DOMAIN_FAILURE,
    payload: error
})