import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, BusinessAreaDataHistoryList, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class BusinessAreaDataAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        this.props.getDataDomainHistory(this.props.dataDomain.dataDomainId);
        this.props.getDataDomainEvents(this.props.dataDomain.businessAreaId, this.props.dataDomain.dataDomainId);
        this.setState({
            fetchingHistory: true,
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataDomainHistoryResultUpdate(prevProps);
        this.onGetDataDomainEventsResultUpdate(prevProps);
    }

    onGetDataDomainHistoryResultUpdate = (prevProps) => {
        if (this.props.getDataDomainHistoryResult && this.props.getDataDomainHistoryResult !== prevProps.getDataDomainHistoryResult) {
            if (!this.props.getDataDomainHistoryResult.success) {
                if (this.props.getDataDomainHistoryResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data history.");
                }
            }
            this.setState({
                fetchingHistory: false
            });
        }
    }

    onGetDataDomainEventsResultUpdate = (prevProps) => {
        if (this.props.getDataDomainEventsResult && this.props.getDataDomainEventsResult !== prevProps.getDataDomainEventsResult) {
            if (!this.props.getDataDomainEventsResult.success) {
                if (this.props.getDataDomainEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Tabs defaultActiveKey="1" className="data-audit-tabs">
                        <TabPane tab="Events" key="1">
                            {
                                this.state.fetchingEvents
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting Business Area Data events...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <EventList eventList={this.props.dataDomainEventsResult.Items}>
                                            </EventList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                        <TabPane tab="Revisions" key="2">
                            {
                                this.state.fetchingHistory
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting Business Area Data history...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <BusinessAreaDataHistoryList
                                                dataDomainRevisions={this.props.dataDomainHistoryResult.Items}>
                                            </BusinessAreaDataHistoryList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                    </Tabs>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataDomainHistoryResult: state.businessAreaDataAudit.getDataDomainHistoryResult,
        dataDomainHistoryResult: state.businessAreaDataAudit.dataDomainHistoryResult,
        getDataDomainEventsResult: state.businessAreaDataAudit.getDataDomainEventsResult,
        dataDomainEventsResult: state.businessAreaDataAudit.dataDomainEventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataDomainHistory: (dataDomainId) => dispatch(actions.getDataDomainHistoryRequest(dataDomainId)),
        getDataDomainEvents: (businessAreaId, dataDomainId) => dispatch(actions.getDataDomainEventsRequest(businessAreaId, dataDomainId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreaDataAudit));