
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

export function* createJob(api, tenantId, job) {
    let result = yield call([api, api.post], `${Endpoints.jobs}/api/v1/jobs`, job, { tenantid: tenantId });
    return result.data;
}

function* getJobs(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs`, { tenantid: tenantId });
    return result.data;
}

function* getJobById(api, tenantId, jobId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/${jobId}`, { tenantid: tenantId });
    return result.data;
}

function* getDuplicateDataObjectsResultSet(api, jobId, pageKey = null) {
    let params = {
    };
    if (pageKey) {
        params.pageKey = pageKey
    }
    let result = yield call([api, api.get], `${Endpoints.duplicates}/api/v1/dataobjects/duplicates/set/${jobId}`, {}, {
        params
    });
    return result.data;
}

function* getDuplicateDataObjectsResultSetDataObject(api, duplicateSetId, groupId, setVersion, id) {
    try {
        let result = yield call([api, api.get], `${Endpoints.duplicates}/api/v1/dataobjects/duplicates/set/${duplicateSetId}/group/${groupId}/version/${setVersion}/record/${id}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getSearchDataObjectsPermission(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/dataobjectsearch`, { tenantid: tenantId });
    return result.data;
}

export function* nominateSurvivalRecord(api, duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption) {
    let result = yield call([api, api.put], `${Endpoints.duplicates}/api/v1/dataobjects/nominate/survivor`, { duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption });
}

export function* removeSurvivalRecordNomination(api, duplicateSetId, duplicateSetVersion, groupId) {
    let result = yield call([api, api.delete], `${Endpoints.duplicates}/api/v1/dataobjects/nominate/survivor/set/${duplicateSetId}/group/${groupId}/version/${duplicateSetVersion}`);
}

export function* getDeDuplicatedRecords(api, survivedRecordId) {
    let result = yield call([api, api.get], `${Endpoints.duplicates}/api/v1/dataobjects/${survivedRecordId}/deduplicates`);
    return result.data;
}

export function* getDataObjectsDuplicateJobsRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const jobsResult = yield call(getJobs, api, tenant.tenantId);
        if (jobsResult && jobsResult.Items && jobsResult.Items.length > 0) {
            jobsResult.Items = jobsResult.Items.filter(item => item.jobType === "FIND_DATA_OBJECTS_DUPLICATES");
        }
        yield put(actions.getDataObjectsDuplicateJobsSuccess(jobsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data object duplicate jobs");
        yield put(actions.getDataObjectsDuplicateJobsFailure(errorObject));
    }
}

export function* searchDuplicateDataObjectsRequest(api, { name, description, businessAreaId, schemaId, dataSetId, dataTags }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let searchDataObjectsDuplicateJob = {
            name: name,
            description: description,
            jobInfo: {
                runUniqueJob: false,
                businessAreaId,
                schemaId,
                dataSetId: dataSetId,
                tenantId: tenant.tenantId,
                userId: user.userId,
                dataTags
            },
            jobType: "FIND_DATA_OBJECTS_DUPLICATES"
        }
        const job = yield call(createJob, api, tenant.tenantId, searchDataObjectsDuplicateJob);
        yield put(actions.searchDuplicateDataObjectsSuccess(job));
    } catch (error) {
        let errorObject = errorHandler(error, "Search", "Duplicate Data Objects");
        yield put(actions.searchDuplicateDataObjectsFailure(errorObject));
    }
}

export function* getDuplicateDataObjectsResultSetRequest(api, { jobId }) {
    try {
        const result = yield call(getDuplicateDataObjectsResultSet, api, jobId);
        yield put(actions.getDuplicateDataObjectsResultSetSuccess({ jobId, result }));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Duplicate Data Objects Result Set");
        yield put(actions.getDuplicateDataObjectsResultSetFailure({ jobId, error: errorObject }));
    }
}

export function* getDuplicateDataObjectsResultSetNextPageRequest(api, { jobId, pageKey }) {
    try {
        const result = yield call(getDuplicateDataObjectsResultSet, api, jobId, pageKey);
        yield put(actions.getDuplicateDataObjectsResultSetNextPageSuccess({ jobId, result }));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Duplicate Data Objects Result Set");
        yield put(actions.getDuplicateDataObjectsResultSetNextPageFailure({ jobId, error: errorObject }));
    }
}

export function* getSearchDataObjectsPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getSearchDataObjectsPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getUserSearchDataObjectsPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user dataset objects permission");
        yield put(actions.getUserSearchDataObjectsPermissionFailure(errorObject));
    }
}

export function* nominateSurvivalRecordRequest(api, { duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption }) {
    try {
        yield call(nominateSurvivalRecord, api, duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption);
        yield put(actions.nominateSurvivalRecordSuccess({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption }));
    } catch (error) {
        let errorObject = errorHandler(error, "Nominate", "Survival record");
        yield put(actions.nominateSurvivalRecordFailure({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption }));
    }
}

export function* removeSurvivalRecordNominationRequest(api, { duplicateSetId, duplicateSetVersion, groupId, dataObjectId }) {
    try {
        yield call(removeSurvivalRecordNomination, api, duplicateSetId, duplicateSetVersion, groupId);
        yield put(actions.removeSurvivalRecordNominationSuccess({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId }));
    } catch (error) {
        let errorObject = errorHandler(error, "Remove", "Survival record nomination");
        yield put(actions.removeSurvivalRecordNominationFailure({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId }));
    }
}

export function* runDeDuplicateJobRequest(api, { duplicateSetId }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        const name = `Deduplicate job for duplicate search config ${duplicateSetId}`;
        let deDuplicateJob = {
            name,
            description: name,
            jobInfo: {
                duplicateSetId,
                jobIdentifier: duplicateSetId,
                tenantId: tenant.tenantId,
                userId: user.userId,
                runUniqueJob: true
            },
            jobType: "DATA_OBJECTS_DEDUPLICATES"
        }
        const job = yield call(createJob, api, tenant.tenantId, deDuplicateJob);
        yield put(actions.runDeDuplicateJobSuccess(job));
    } catch (error) {
        let errorObject = errorHandler(error, "Run", "DeDuplicate Job");
        yield put(actions.runDeDuplicateJobFailure(errorObject));
    }
}

export function* getDuplicateDataObjectsResultSetDataObjectRequest(api, { duplicateSetId, groupId, setVersion, id, options = { fetchJob: true } }) {
    try {
        let tenant = yield select(getTenant);
        let result = {
            dataObject: null,
            job: null
        }
        result.dataObject = yield call(getDuplicateDataObjectsResultSetDataObject, api, duplicateSetId, groupId, setVersion, id);
        if (options.fetchJob) {
            result.job = yield call(getJobById, api, tenant.tenantId, duplicateSetId);
        }
        yield put(actions.getDuplicateDataObjectsResultSetDataObjectSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Duplicate Data Objects Result Set Record");
        yield put(actions.getDuplicateDataObjectsResultSetDataObjectFailure(errorObject));
    }
}

export function* getDeDuplicatedRecordsRequest(api, { survivedRecordId }) {
    try {
        let result = yield call(getDeDuplicatedRecords, api, survivedRecordId);
        yield put(actions.getDeDuplicatedRecordsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Deduplicated records");
        yield put(actions.getDeDuplicatedRecordsFailure(errorObject));
    }
}

export function* watchGetDataObjectsDuplicateJobsRequest(api, params) {
    yield call(getDataObjectsDuplicateJobsRequest, api);
}

export function* watchSearchDuplicateDataObjectsRequest(api, { params }) {
    yield call(searchDuplicateDataObjectsRequest, api, params);
}

export function* watchGetDuplicateDataObjectsResultSetRequest(api, { params }) {
    yield call(getDuplicateDataObjectsResultSetRequest, api, params);
}

export function* watchGetDuplicateDataObjectsResultSetNextPageRequest(api, { params }) {
    yield call(getDuplicateDataObjectsResultSetNextPageRequest, api, params);
}

export function* watchGetSearchDataObjectsPermissionRequest(api, { params }) {
    yield call(getSearchDataObjectsPermissionRequest, api);
}

export function* watchNominateSurvivalRecordRequest(api, { params }) {
    yield call(nominateSurvivalRecordRequest, api, params);
}

export function* watchRemoveSurvivalRecordNominationRequest(api, { params }) {
    yield call(removeSurvivalRecordNominationRequest, api, params);
}

export function* watchRunDeDuplicateJobRequest(api, { params }) {
    yield call(runDeDuplicateJobRequest, api, params);
}

export function* watchGetDuplicateDataObjectsResultSetDataObjectRequest(api, { params }) {
    yield call(getDuplicateDataObjectsResultSetDataObjectRequest, api, params);
}

export function* watchGetDeDuplicatedRecordsRequest(api, { params }) {
    yield call(getDeDuplicatedRecordsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_OBJECTS_DUPLICATE_JOBS_REQUEST, watchGetDataObjectsDuplicateJobsRequest, api);
    yield takeLatest(actions.SEARCH_DUPLICATE_DATA_OBJECTS_REQUEST, watchSearchDuplicateDataObjectsRequest, api);
    yield takeLatest(actions.GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_REQUEST, watchGetDuplicateDataObjectsResultSetRequest, api);
    yield takeLatest(actions.GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_REQUEST, watchGetDuplicateDataObjectsResultSetNextPageRequest, api);
    yield takeEvery(actions.GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST, watchGetSearchDataObjectsPermissionRequest, api);
    yield takeEvery(actions.NOMINATE_SURVIVAL_RECORD_REQUEST, watchNominateSurvivalRecordRequest, api);
    yield takeEvery(actions.REMOVE_SURVIVAL_RECORD_NOMINATION_REQUEST, watchRemoveSurvivalRecordNominationRequest, api);
    yield takeEvery(actions.RUN_DEDUPLICATE_JOB_REQUEST, watchRunDeDuplicateJobRequest, api);
    yield takeEvery(actions.GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_REQUEST, watchGetDuplicateDataObjectsResultSetDataObjectRequest, api);
    yield takeEvery(actions.GET_DEDUPLICATED_RECORDS_REQUEST, watchGetDeDuplicatedRecordsRequest, api);
}