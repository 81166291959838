import {
    GET_EVENT_USER_SUCCESS,
    GET_EVENT_USER_FAILURE
} from "./actions";

const initialState = {
    getEventUserResult: {
        success: true
    },
    userRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_USER_SUCCESS:
            return { ...state, getEventUserResult: { success: true }, userRevision: action.payload };
        case GET_EVENT_USER_FAILURE:
            return {
                ...state, getEventUserResult: { success: false, ...action.payload }, userRevision: null
            };
        default:
            return state;
    }
};
