import {
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_LOGIN_STATUS,
    GET_USER_AND_TENANT_SUCCESS,
    GET_USER_AND_TENANT_FAILURE,
    USER_LOGOUT_SUCCESS,
    CHANGE_TEMPORARY_PASSWORD_SUCCESS,
    CHANGE_TEMPORARY_PASSWORD_FAILURE,
    SEND_EMAIL_VERIFICATION_LINK_SUCCESS,
    SEND_EMAIL_VERIFICATION_LINK_FAILURE
} from "./actions";

const initialState = {
    cognitoUser: null,
    user: null,
    tenant: null,
    userLoginResult: {
        success: true
    },
    userLoginStatus: {
        message: ""
    },
    getUserTenantResult: {
        success: true
    },
    userLogoutResult: {
        success: false
    },
    changeTemporaryPasswordResult: {
        success: true
    },
    sendEmailVerificationLinkResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                userLoginResult: { success: true },
                getUserTenantResult: { success: true },
                userLogoutResult: { success: false },
                cognitoUser: action.payload.cognitoUser,
                tenant: action.payload.tenant,
                user: action.payload.user,
                newTenant: action.payload.newTenant
            };
        case USER_LOGIN_FAILURE:
            return { ...state, userLoginResult: { success: false, ...action.payload } };
        case USER_LOGIN_STATUS:
            return { ...state, userLoginStatus: { message: action.payload } };
        case GET_USER_AND_TENANT_SUCCESS:
            return { ...state, getUserTenantResult: { success: true }, ...action.payload };
        case GET_USER_AND_TENANT_FAILURE:
            return { ...state, getUserTenantResult: { success: false, ...action.payload } };
        case USER_LOGOUT_SUCCESS:
            return { ...state, userLogoutResult: { success: true }, cognitoUser: null, user: null, tenant: null };
        case CHANGE_TEMPORARY_PASSWORD_SUCCESS:
            return { ...state, changeTemporaryPasswordResult: { success: true } };
        case CHANGE_TEMPORARY_PASSWORD_FAILURE:
            return { ...state, changeTemporaryPasswordResult: { success: false, ...action.payload } };
        case SEND_EMAIL_VERIFICATION_LINK_SUCCESS:
            return { ...state, sendEmailVerificationLinkResult: { success: action.payload.success } };
        case SEND_EMAIL_VERIFICATION_LINK_FAILURE:
            return { ...state, sendEmailVerificationLinkResult: { success: false, ...action.payload.error } };
        default:
            return state;
    }
};
