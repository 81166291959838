import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Pie, Bar } from '@ant-design/plots';
import { LoadingOverlay } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Row, Col, Carousel, Card, Switch, Space } from 'antd';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class DataObjectDuplicateSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingSummary: false,
            busy: false,
            busyMessage: "Please wait...",
            duplicateClusterSizeDistributionChartType: "pie",
            distributionOfClustersByClusterSizeChartSortDirection: "asc"
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            fetchingSummary: true
        })
        this.props.getDuplicateDataObjectsResultSetSummary(this.props.jobId, this.props.clusterSummary);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDuplicateDataObjectsResultSetResultChanged(prevProps);
        this.onJobIdChanged(prevProps);
    }

    onGetDuplicateDataObjectsResultSetResultChanged = (prevProps) => {
        if (this.props.getDuplicateDataObjectsResultSetSummaryResult && this.props.getDuplicateDataObjectsResultSetSummaryResult !== prevProps.getDuplicateDataObjectsResultSetSummaryResult) {
            if (this.props.getDuplicateDataObjectsResultSetSummaryResult.result[this.props.jobId] !== prevProps.getDuplicateDataObjectsResultSetSummaryResult.result[this.props.jobId]) {
                if (!this.props.getDuplicateDataObjectsResultSetSummaryResult.result[this.props.jobId].success) {
                    showError(this.props.getDuplicateDataObjectsResultSetSummaryResult.result[this.props.jobId].message);
                }
                this.setState({
                    fetchingSummary: false
                })
            }
        }
    }

    onJobIdChanged = (prevProps) => {
        if (this.props.jobId && this.props.jobId !== prevProps.jobId) {
            this.setState({
                fetchingSummary: true
            })
            this.props.getDuplicateDataObjectsResultSetSummary(this.props.jobId, this.props.clusterSummary);
        }
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.state.fetchingSummary;
        return isBusy;
    }

    getSummaryComponent = () => {
        if (this.props.duplicateDataObjectsResultSetSummary && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId] && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId].length > 0) {
            let duplicateClusterSizeDistributionChart = this.getDuplicateClusterSizeDistributionChart();
            let duplicateClusterCategoryDonuts = this.getDuplicateClusterCategoryDonuts();
            let distributionOfClustersByClusterSizeChart = this.getDistributionOfClustersByClusterSizeChart();
            let distributionOfClustersAcrossIndividualDataSetsChart = this.getDistributionOfClustersAcrossIndividualDataSetsChart();
            return <Carousel dotPosition="top" style={{ padding: "1rem" }} dots={{ className: "duplicate-dataobject-charts-carousel-dots" }}>
                {
                    duplicateClusterSizeDistributionChart &&
                    <Card
                        size="small"
                        title={
                            <Space>
                                <>{"Duplicate cluster size distribution"}</>
                                <Switch checkedChildren="Pie" unCheckedChildren="Donut" defaultChecked onChange={checked => checked ? this.setState({ duplicateClusterSizeDistributionChartType: "pie" }) : this.setState({ duplicateClusterSizeDistributionChartType: "donut" })} />
                            </Space>
                        }
                        bordered={false}
                        bodyStyle={{ background: "#fffff" }}
                        headStyle={{ background: "#fffff" }}>
                        {
                            this.state.duplicateClusterSizeDistributionChartType === "pie"
                                ?
                                duplicateClusterSizeDistributionChart.pie
                                :
                                duplicateClusterSizeDistributionChart.donut
                        }
                    </Card>
                }
                {
                    duplicateClusterCategoryDonuts &&
                    <Card
                        size="small"
                        title={"Duplicate cluster size distribution"}
                        bordered={false}
                        bodyStyle={{ background: "#fffff" }}
                        headStyle={{ background: "#fffff" }}>
                        {duplicateClusterCategoryDonuts}
                    </Card>
                }
                {
                    distributionOfClustersByClusterSizeChart &&
                    <Card
                        size="small"
                        title={
                            <Space>
                                <>{"Distribution of clusters by cluster size"}</>
                                <Switch checkedChildren="Asc" unCheckedChildren="Desc" defaultChecked onChange={checked => checked ? this.setState({ distributionOfClustersByClusterSizeChartSortDirection: "asc" }) : this.setState({ distributionOfClustersByClusterSizeChartSortDirection: "desc" })} />
                            </Space>
                        }
                        bordered={false}
                        bodyStyle={{ background: "#fffff" }}
                        headStyle={{ background: "#fffff" }}>
                        {distributionOfClustersByClusterSizeChart}
                    </Card>
                }
                {
                    distributionOfClustersAcrossIndividualDataSetsChart &&
                    <Card
                        size="small"
                        title={"Distribution of clusters across individual datasets"}
                        bordered={false}
                        bodyStyle={{ background: "#fffff" }}
                        headStyle={{ background: "#fffff" }}>
                        {distributionOfClustersAcrossIndividualDataSetsChart}
                    </Card>
                }
            </Carousel>;

        }
        return <></>
    }

    getDuplicateClusterSizeDistributionChart = () => {
        if (this.props.duplicateDataObjectsResultSetSummary && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId] && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId].length > 0) {
            let data = {};
            for (let item of this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId]) {
                if (data[item.clusterType] === undefined) {
                    data[item.clusterType] = 0;
                }

                data[item.clusterType] = data[item.clusterType] + 1;
            }

            const pieConfig = {
                data: Object.keys(data).map(key => ({ clusterType: key, count: data[key] })),
                angleField: 'count',
                colorField: 'clusterType',
                radius: 0.70,
                label: {
                    type: 'spider',
                    labelHeight: 28,
                    content: '{name}\n{percentage}',
                },
                interactions: [
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                ],
            };

            const donutConfig = {
                data: Object.keys(data).map(key => ({ clusterType: key, count: data[key] })),
                angleField: 'count',
                colorField: 'clusterType',
                radius: 1,
                innerRadius: 0.6,
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{name}\n{percentage}',
                    style: {
                        textAlign: 'center',
                        fontSize: 14,
                    },
                },
                interactions: [
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                ],
                statistic: {
                    title: false,
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        content: '',
                    },
                },
            };

            return {
                pie: <Pie key={"pie"} {...pieConfig} legend={{ title: { text: "Count of name" } }} />,
                donut: <Pie key={"donut"} {...donutConfig} legend={{ title: { text: "Count of name" } }} />
            }
        }
        return null;
    }

    getDuplicateClusterCategoryDonuts = () => {
        if (this.props.duplicateDataObjectsResultSetSummary && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId] && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId].length > 0) {
            let clusterTypeAggregateData = {};
            let totalClusterTypeCount = 0;
            for (let item of this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId]) {
                if (clusterTypeAggregateData[item.clusterType] === undefined) {
                    clusterTypeAggregateData[item.clusterType] = 0;
                }

                clusterTypeAggregateData[item.clusterType] = clusterTypeAggregateData[item.clusterType] + 1;
                totalClusterTypeCount = totalClusterTypeCount + 1;
            }

            const donutConfig = {
                angleField: 'count',
                colorField: 'clusterType',
                radius: 0.8,
                innerRadius: 0.4,
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{name}\n{percentage}',
                    style: {
                        textAlign: 'center',
                        fontSize: 12,
                    },
                },
                interactions: [
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                ],
                statistic: {
                    title: false,
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        content: '',
                    },
                },
            };

            let clusterTypeDonuts = []
            for (let clusterType in clusterTypeAggregateData) {
                let clusterTypeDonutConfig = { ...donutConfig };
                let data = [];
                data.push({
                    clusterType,
                    count: clusterTypeAggregateData[clusterType]
                });
                data.push({
                    clusterType: "",
                    count: (totalClusterTypeCount - clusterTypeAggregateData[clusterType])
                });
                clusterTypeDonutConfig.data = data;
                clusterTypeDonuts.push(<Pie key={`donut_${clusterType}`} {...clusterTypeDonutConfig} legend={false} />);
            }

            return <Row style={{ background: "#fffff", overflowX: "auto", width: "100%", height: "100%" }} className="layout-row" wrap={false}>
                {clusterTypeDonuts.map(donut => <Col>{donut}</Col>)}
            </Row>
        }
        return null;
    }

    getDistributionOfClustersByClusterSizeChart = () => {
        if (this.props.duplicateDataObjectsResultSetSummary && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId] && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId].length > 0) {
            let data = {};
            for (let item of this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId]) {
                if (data[item.clusterType] === undefined) {
                    data[item.clusterType] = 0;
                }

                data[item.clusterType] = data[item.clusterType] + 1;
            }

            data = Object.keys(data).map(key => ({ clusterType: key, count: data[key] }));

            switch (this.state.distributionOfClustersByClusterSizeChartSortDirection) {
                case "asc":
                    data = _.orderBy(data, ['count', 'clusterType'], ['asc', 'asc']);
                    break;
                case "desc":
                    data = _.orderBy(data, ['count', 'clusterType'], ['desc', 'asc']);
                    break;
            }


            const config = {
                data,
                xField: 'count',
                yField: 'clusterType',
                seriesField: 'clusterType',
                legend: {
                    position: 'top-left',
                },
            };
            return <Bar key={`bar_${this.state.distributionOfClustersByClusterSizeChartSortDirection}`} {...config} height={500} />;
        }
        return null;
    }

    getDistributionOfClustersAcrossIndividualDataSetsChart = () => {
        if (this.props.duplicateDataObjectsResultSetSummary && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId] && this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId].length > 0) {
            const aggregateData = {};
            const uniqueDataSetClusterDict = {};
            for (let item of this.props.duplicateDataObjectsResultSetSummary.result[this.props.jobId]) {
                if (item.dataSetDistribution) {
                    for (let dataSet of item.dataSetDistribution) {
                        let dataSetName = `${dataSet.name}\n${dataSet.id}`;
                        if (!aggregateData[dataSetName]) {
                            aggregateData[dataSetName] = {};
                        }
                        if (!aggregateData[dataSetName][[item.clusterType]]) {
                            aggregateData[dataSetName][[item.clusterType]] = 0;
                        }

                        let uniqueDataSetCluster = `${dataSet.id}_${item.cluster}`;
                        if (!uniqueDataSetClusterDict[uniqueDataSetCluster]) {
                            aggregateData[dataSetName][item.clusterType] = aggregateData[dataSetName][item.clusterType] + 1;
                        }
                        uniqueDataSetClusterDict[uniqueDataSetCluster] = true;
                    }
                }
            }

            let data = [];
            for (let dataSet in aggregateData) {
                for (let clusterType in aggregateData[dataSet]) {
                    data.push({
                        dataSet,
                        clusterType,
                        count: aggregateData[dataSet][clusterType]
                    })
                }
            }

            const config = {
                data,
                xField: 'count',
                yField: 'dataSet',
                seriesField: 'clusterType',
                isPercent: false,
                isStack: true,
                label: {
                    position: 'middle',
                    content: (item) => {
                        return item.count;
                    },
                    style: {
                        fill: '#fff',
                    },
                },
            };
            return <Bar {...config} />;
        }
        return null;
    }

    render() {
        return <Row style={{ background: "#fffff", overflowY: "auto", width: "100%", height: "100%" }} className="layout-row">
            <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                <LoadingOverlay
                    busy={this.isBusy()}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {!this.isBusy() ? this.getSummaryComponent() : <></>}
            </Col>
        </Row>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDuplicateDataObjectsResultSetSummaryResult: state.dataObjectDuplicateSearchSummary.getDuplicateDataObjectsResultSetSummaryResult,
        duplicateDataObjectsResultSetSummary: state.dataObjectDuplicateSearchSummary.duplicateDataObjectsResultSetSummary
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDuplicateDataObjectsResultSetSummary: (jobId, clusterSummary) => dispatch(actions.getDuplicateDataObjectsResultSetSummaryRequest(jobId, clusterSummary))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectDuplicateSummary));