import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Login, ChangeTemporaryPassword, ModalLoadingOverlay, ConfirmationLinkSuccessModal } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import queryString from 'query-string';
import { Typography } from 'antd';

const { Link } = Typography;
class UserLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            changeTemporaryPassword: false
        };
    }

    componentWillMount() {
        this.setState({
            loginProgress: true,
            loginProgressMessage: "Please wait..."
        })
        this.props.onProgressStatusChange && this.props.onProgressStatusChange({ inProgress: true });
        this.props.getUserAndTenant();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onSendEmailVerificationLinkResultChanged(prevProps);
        if (this.props.getUserTenantResult !== prevProps.getUserTenantResult) {
            if (this.props.getUserTenantResult.success && this.props.tenant) {
                if (this.props.tenant.state === "ACTIVE") {
                    this.props.history.push(this.getLoginSuccessfulRedirectPath());
                }
                else {
                    this.props.history.push(AppPaths.TENANT_PROVISION.replace(":tenant", this.props.tenant.name));
                }
            }
            else {
                this.setState({
                    busy: false
                });
            }
            this.setState({
                loginProgress: false,
                loginProgressMessage: ""
            })
            this.props.onProgressStatusChange && this.props.onProgressStatusChange({ inProgress: false });
        }

        if (this.props.userLoginStatus && this.props.userLoginStatus !== prevProps.userLoginStatus && this.props.userLoginStatus.message) {
            this.setState({
                loginProgressMessage: this.props.userLoginStatus.message
            });
        }

        if (this.props.userLoginResult && this.props.userLoginResult !== prevProps.userLoginResult) {
            if (this.props.userLoginResult.success) {
                if (this.props.newTenant) {
                    if (this.props.tenant.state === "ACTIVE") {
                        showSuccess("Tenant provisioned successfully. Please wait redirecting to dashboard.", () => {
                            this.props.history.push(AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.name));
                        });
                    }
                    else {
                        this.props.history.push(AppPaths.TENANT_PROVISION.replace(":tenant", this.props.tenant.name));
                    }
                }
                else {
                    if (this.props.tenant.state === "ACTIVE") {
                        this.props.history.push(this.getLoginSuccessfulRedirectPath());
                    }
                    else {
                        this.props.history.push(AppPaths.TENANT_PROVISION.replace(":tenant", this.props.tenant.name));
                    }
                }
            }
            else {
                if (this.props.userLoginResult.fields) {
                    this.setState({
                        userLoginErrors: this.props.userLoginResult.fields
                    });
                }
                if (this.props.userLoginResult.code === "TENANT_PROVISION_FAILED") {
                    showError("Tenant could not be provisioned at this moment. Please try again later.");
                }
                else if (this.props.userLoginResult.code === "UserNotConfirmedException") {
                    this.setState({
                        userLoginErrors: {
                            "email": <>
                                <>
                                    Email is not verified. Please verify your email by clicking on the verification link sent to your email or &nbsp;
                                </>
                                <Link underline onClick={this.onResendVerificationLink}>
                                    send email verification link again.
                                </Link>
                            </>
                        }
                    });
                }
                else if (this.props.userLoginResult.code === "NotAuthorizedException") {
                    this.setState({
                        userLoginErrors: {
                            "email": <>
                                <>
                                    Email may be incorrect and if email is not verified then &nbsp;
                                </>
                                <Link underline onClick={this.onResendVerificationLink}>
                                    send email verification link again.
                                </Link>
                            </>,
                            "password": "Password may be incorrect",
                        }
                    });
                }
                else if (this.props.userLoginResult.code === "PERMISSION_DENIED"
                    || this.props.userLoginResult.code === "TENANT_ALREADY_PROVISIONED") {
                    showError(this.props.userLoginResult.message);
                }
                else if (this.props.userLoginResult.code === "CHANGE_TEMPORARY_PASSWORD") {
                    this.setState({
                        changeTemporaryPassword: true,
                        email: this.props.userLoginResult.email
                    });
                }
                else {
                    showError("Login failed. Please contact your account administrator.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                loginProgress: false,
                loginProgressMessage: ""
            });
            this.props.onProgressStatusChange && this.props.onProgressStatusChange({ inProgress: false });
        }

        if (this.props.changeTemporaryPasswordResult !== prevProps.changeTemporaryPasswordResult) {
            if (this.props.changeTemporaryPasswordResult.success) {
                this.setState({
                    changeTemporaryPassword: false
                });
            }
            else if (this.props.changeTemporaryPasswordResult.code === "NotAuthorizedException") {
                showError(this.props.changeTemporaryPasswordResult.message);
            }
            else {
                showError("Chaning password failed.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onSendEmailVerificationLinkResultChanged = (prevProps) => {
        if (this.props.sendEmailVerificationLinkResult && this.props.sendEmailVerificationLinkResult !== prevProps.sendEmailVerificationLinkResult) {
            if (this.props.sendEmailVerificationLinkResult.success) {
                this.setState({
                    showEmailSentSuccess: true,
                    userLoginErrors: {}
                });
            }
            else {
                showError("Could not able to send verification link at this moment. Please try after some time.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }


    userLogin = (email, password) => {
        this.setState({
            loginProgress: true,
            loginProgressMessage: "Please wait...",
            userLoginErrors: {},
            email: email
        });
        this.props.onProgressStatusChange && this.props.onProgressStatusChange({ inProgress: true });
        this.props.userLogin(email, password);
    }

    onResendVerificationLink = () => {
        if (this.state.email) {
            this.setState({
                busy: true,
                busyMessage: "Sending verification link...",
            });
            this.props.sendEmailVerificationLink(this.state.email);
        }
    }

    changeTemporaryPassword = (temporaryPassword, newPassword) => {
        this.setState({
            busy: true,
            busyMessage: "Changing password..."
        });
        this.props.changeTemporaryPassword(this.state.email, temporaryPassword, newPassword);
    }

    getLoginSuccessfulRedirectPath = () => {
        let redirectTo = AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.name);
        if (this.props.user && this.props.user.isGlobalAdmin) {
            redirectTo = AppPaths.ADMIN_CONSOLE;
        }
        if (this.props.location && this.props.location.search) {
            let queryParams = queryString.parse(this.props.location.search);
            if (queryParams && queryParams.redirect_to) {
                redirectTo = queryParams.redirect_to;
            }
        }
        return redirectTo;
    }

    render() {
        return (
            <div>
                <ModalLoadingOverlay busy={this.state.busy} message={this.state.busyMessage}></ModalLoadingOverlay>
                <ConfirmationLinkSuccessModal open={this.state.showEmailSentSuccess} okClick={() => this.setState({ showEmailSentSuccess: false })}></ConfirmationLinkSuccessModal>
                {
                    this.state.changeTemporaryPassword
                        ?
                        <ChangeTemporaryPassword changePassword={this.changeTemporaryPassword} formErrors={this.state.userLoginErrors}></ChangeTemporaryPassword>
                        :
                        <Login
                            inProgress={this.state.loginProgress}
                            progressMessage={this.state.loginProgressMessage}
                            onUserLogin={this.userLogin}
                            formErrors={this.state.userLoginErrors}>
                        </Login>
                }
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userLoginResult: state.userLogin.userLoginResult,
        userLoginStatus: state.userLogin.userLoginStatus,
        getUserTenantResult: state.userLogin.getUserTenantResult,
        sendEmailVerificationLinkResult: state.userLogin.sendEmailVerificationLinkResult,
        changeTemporaryPasswordResult: state.userLogin.changeTemporaryPasswordResult,
        user: state.userLogin.user,
        tenant: state.userLogin.tenant,
        newTenant: state.userLogin.newTenant
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userLogin: (email, password) => dispatch(actions.userLoginRequest(email, password)),
        getUserAndTenant: () => dispatch(actions.getUserAndTenantRequest()),
        changeTemporaryPassword: (email, oldPassword, newPassword) => dispatch(actions.changeTemporaryPasswordRequest(email, oldPassword, newPassword)),
        sendEmailVerificationLink: (email) => dispatch(actions.sendEmailVerificationLinkRequest(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);