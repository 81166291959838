import React from 'react';
import { useParams } from "react-router-dom";
import { PageLayout } from 'components';
import { DataObjectDuplicateSearch } from 'containers';

const DataObjectDuplicateSearchPage = (props) => {
    return (
        <PageLayout
            mainContent={({ setMainColumnSpan, setBreadcrumbNavigationItems }) => {
                return <DataObjectDuplicateSearch setMainColumnSpan={setMainColumnSpan} setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}></DataObjectDuplicateSearch>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default DataObjectDuplicateSearchPage