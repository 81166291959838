import React, { Component } from "react";
import { VerticleMenuContent } from 'components';
import { withRouter } from "react-router";
import { ProhibitedArea, ComingSoon, SystemConnections } from 'components';
import { Users, Roles, BusinessAreas, BusinessAreaData, DataTags, AppClients, ChangeDataCaptures, SystemKeys } from 'containers';
import { Empty } from 'antd';
import AppPaths from "constants/appPaths";

class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    name: "businessareas",
                    description: "Business Areas"
                },
                {
                    name: "businessareadata",
                    description: "Business Area Data"
                },
                {
                    name: "dataclassifiers",
                    description: "Data Classifier Tags"
                },
                {
                    name: "roles",
                    description: "Roles"
                },
                /*  {
                     name: "rolegroups",
                     description: "Role Groups"
                 }, */
                {
                    name: "users",
                    description: "Users"
                },
                {
                    name: "credentials",
                    description: "Credentials"
                },
                /* {
                    name: "usergroups",
                    description: "User Groups"
                }, */
                {
                    name: "systemconnections",
                    description: "System Connections"
                },
                {
                    name: "systemkeys",
                    description: "System Keys"
                },
                {
                    name: "changedatacapture",
                    description: "CDC Destinations"
                }
            ]
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadcrumbNavigationItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: AppPaths.TENANT_CONFIGURATION.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Configurations"
                }
            ];
            this.setState({
                breadcrumbNavigationItems
            });
            this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
        }
    }

    showSubBreadCrumbNavigationItems = (subBreadCrumbNavigationItems) => {
        let breadcrumbNavigationItems = [...this.state.breadcrumbNavigationItems, ...subBreadCrumbNavigationItems];
        this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
    }


    getConfigurationItem = (activeItem) => {
        if (activeItem) {
            switch (activeItem.toLowerCase()) {
                case "users":
                    return <Users action={this.props.itemAction} userId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></Users>;
                case "roles":
                    return <Roles action={this.props.itemAction} roleId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></Roles>;
                case "businessareas":
                    return <BusinessAreas action={this.props.itemAction} businessAreaId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></BusinessAreas>;
                case "businessareadata":
                    return <BusinessAreaData action={this.props.itemAction} dataDomainId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></BusinessAreaData>;
                case "dataclassifiers":
                    return <DataTags action={this.props.itemAction} tagId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></DataTags>;
                case "credentials":
                    return <AppClients action={this.props.itemAction} clientId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></AppClients>;
                case "systemconnections":
                    return <SystemConnections itemAction={this.props.itemAction} itemId={this.props.itemId} subItem={this.props.subItem} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></SystemConnections>
                case "systemkeys":
                    return <SystemKeys action={this.props.itemAction} keyId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></SystemKeys>;
                case "changedatacapture":
                    return <ChangeDataCaptures action={this.props.itemAction} destinationId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></ChangeDataCaptures>;
                default:
                    return <div>
                        <ComingSoon></ComingSoon>
                    </div>;
            }
        }

        return <Empty description="No configuration option selected"></Empty>;
    }

    onMenuItemClick = (itemName) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant).replace(":configurationItem", itemName.toLowerCase()));
    }

    render() {
        return <>
            {!this.props.activeItem || this.state.items.some(item => item.name === this.props.activeItem.toLowerCase()) ?
                <VerticleMenuContent
                    items={this.state.items}
                    activeItem={this.props.activeItem}
                    itemContainer={this.getConfigurationItem(this.props.activeItem)}
                    onItemClick={this.onMenuItemClick}></VerticleMenuContent>
                : <ProhibitedArea />}
        </>
    }
}

export default withRouter(Configuration);