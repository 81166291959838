
import { put, call, takeEvery, select, fork, join, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
import { Auth } from "aws-amplify";
import { getUserBusinessAreaPermissions } from 'store/businessAreas/sagas';
import { getArrayBufferFromFileObject } from 'common/fileHandler';
import { v4 as uuidv4 } from 'uuid';
import parsePath from 'parse-filepath';
import { Storage } from "aws-amplify";
import mime from 'mime-types';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;
const getUserBusinessAreasDict = (state) => state.home.businessAreasDict;

function* getUserBusinessAreaByName(api, tenantId, userId, businessAreaName) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/users/${userId}/businessareas/name/${businessAreaName}`, { tenantid: tenantId });
    return result.data;
}

function* getSchemaModels(api, tenantId, businessAreaId, pageKey = null) {
    let params = {
    };
    if (pageKey) {
        params.pageKey = pageKey
    }
    let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas`, { tenantid: tenantId }, {
        params
    });
    return result.data;
}

function* getRecentSchemaModels(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/schemas/recents`, { tenantid: tenantId });
    return result.data;
}

function* createSchemModel(api, tenantId, businessAreaId, schemaModel) {
    let result = yield call([api, api.post], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas`, schemaModel, { tenantid: tenantId });
    return result.data;
}

function* deleteSchemaModel(api, tenantId, businessAreaId, schemaId) {
    let result = yield call([api, api.delete], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}`, { tenantid: tenantId });
    return result.data;
}

function* getSchemaModel(api, tenantId, businessAreaId, schemaId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getSchemaModelByName(api, tenantId, businessAreaId, schemaName) {
    try {
        let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/name/${schemaName}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateSchemaModel(api, tenantId, businessAreaId, schemaId, updateSchemaModelRequest) {
    let result = yield call([api, api.put], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}`, updateSchemaModelRequest, { tenantid: tenantId });
    return result.data;
}

export function* getUserSchemasModelPermissions(api, tenantId) {
    //TODo - remove the current user call and replace it with pretectum user
    let user = yield call([Auth, Auth.currentAuthenticatedUser]);
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${user.attributes.sub}/permissions/resources/schemas`, { tenantid: tenantId });
    return result.data;
}

export function* getBusinessAreaDataForBusinessArea(api, tenantId, businessAreaId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/datadomains`, { tenantid: tenantId });
    return result.data;
}


export function* getUserBusinessAreaByNameRequest(api, { businessAreaName }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let userBusinessArea = yield call(getUserBusinessAreaByName, api, tenant.tenantId, user.userId, businessAreaName);
        userBusinessArea.user = { ...(userBusinessArea.user || {}), canAdd: false, canView: false, canEdit: false, canDelete: false };
        if (userBusinessArea.user && userBusinessArea.user.actions && userBusinessArea.user.actions.length > 0) {
            if (userBusinessArea.user.actions.includes("add")) {
                userBusinessArea.user.canAdd = true;
            }
            if (userBusinessArea.user.actions.includes("view")) {
                userBusinessArea.user.canView = true;
            }
            if (userBusinessArea.user.actions.includes("edit")) {
                userBusinessArea.user.canEdit = true;
            }
            if (userBusinessArea.user.actions.includes("delete")) {
                userBusinessArea.user.canDelete = true;
            }
        }
        yield put(actions.getUserBusinessAreaByNameSuccess(userBusinessArea));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user business area");
        yield put(actions.getUserBusinessAreaByNameFailure({ ...errorObject, businessAreaName }));
    }
}

export function* getSchemaModelsRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        let result = yield call(getSchemaModels, api, tenant.tenantId, businessAreaId);
        yield put(actions.getSchemaModelsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema models");
        yield put(actions.getSchemaModelsFailure(errorObject));
    }
}

export function* getSchemaModelsNextPageRequest(api, { businessAreaId, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        let result = yield call(getSchemaModels, api, tenant.tenantId, businessAreaId, pageKey);
        yield put(actions.getSchemaModelsNextPageSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema models");
        yield put(actions.getSchemaModelsNextPageFailure(errorObject));
    }
}

export function* getRecentSchemaModelsRequest(api) {
    try {
        let tenant = yield select(getTenant);
        let result = yield call(getRecentSchemaModels, api, tenant.tenantId);
        let userBusinessAreasDict = yield select(getUserBusinessAreasDict);
        if (result && result.Items && result.Items.length > 0) {
            for (let schemaModel of result.Items) {
                if (userBusinessAreasDict && userBusinessAreasDict[schemaModel.businessAreaId]) {
                    schemaModel.businessAreaName = userBusinessAreasDict[schemaModel.businessAreaId].name;
                }
            }
        }
        yield put(actions.getRecentSchemaModelsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "recent schema models");
        yield put(actions.getRecentSchemaModelsFailure(errorObject));
    }
}

export function* createSchemModelRequest(api, { businessAreaId, schemaModel }) {
    try {
        let tenant = yield select(getTenant);
        let newSchemaModel = yield call(createSchemModel, api, tenant.tenantId, businessAreaId, schemaModel);
        yield put(actions.createSchemModelSuccess({ schemaModel: newSchemaModel }));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "schema model");
        yield put(actions.createSchemModelFailure(errorObject));
    }
}

export function* deleteSchemaModelRequest(api, { businessAreaId, schemaId }) {
    try {
        let tenant = yield select(getTenant);
        yield call(deleteSchemaModel, api, tenant.tenantId, businessAreaId, schemaId);
        yield call(getSchemaModelsRequest, api, { businessAreaId });
        yield put(actions.deleteSchemaModelSuccess(businessAreaId, schemaId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "schema models");
        yield put(actions.deleteSchemaModelFailure(errorObject));
    }
}

export function* getSchemaModelRequest(api, { businessAreaId, schemaId }) {
    try {
        let tenant = yield select(getTenant);
        let schemaModel = yield call(getSchemaModel, api, tenant.tenantId, businessAreaId, schemaId);
        yield put(actions.getSchemaModelSuccess(schemaModel));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema model");
        yield put(actions.getSchemaModelFailure(errorObject));
    }
}

export function* getSchemaModelByNameRequest(api, { businessAreaId, schemaName }) {
    try {
        let tenant = yield select(getTenant);
        let schemaModel = yield call(getSchemaModelByName, api, tenant.tenantId, businessAreaId, schemaName);
        yield put(actions.getSchemaModelSuccess(schemaModel));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema model by name");
        yield put(actions.getSchemaModelFailure(errorObject));
    }
}

export function* updateSchemaModelRequest(api, { businessAreaId, schemaId, updateSchemaModelData, fieldIndexMappings }) {
    let { newFieldIndexMappings, updatedSchemaFieldIndexMapping } = fieldIndexMappings;
    try {
        let tenant = yield select(getTenant);
        let updateSchemaModelRequest = {};
        if (updateSchemaModelData.schemaModel) {
            updateSchemaModelRequest.schemaModel = {
                updateRequest: updateSchemaModelData.schemaModel
            }
        }
        if (updateSchemaModelData.newFields.length > 0 ||
            updateSchemaModelData.deletedFields.length > 0 ||
            updateSchemaModelData.updatedFields.length > 0) {
            updateSchemaModelRequest.fields = {};
            if (updateSchemaModelData.newFields.length > 0) {
                updateSchemaModelRequest.fields.insertRequest = updateSchemaModelData.newFields;
            }
            if (updateSchemaModelData.deletedFields.length > 0) {
                updateSchemaModelRequest.fields.deleteRequest = updateSchemaModelData.deletedFields;
            }
            if (updateSchemaModelData.updatedFields.length > 0) {
                updateSchemaModelRequest.fields.updateRequest = updateSchemaModelData.updatedFields;
            }
        }
        if (_.isEmpty(updateSchemaModelRequest) === false) {
            yield call(updateSchemaModel, api, tenant.tenantId, businessAreaId, schemaId, updateSchemaModelRequest);
            yield call(getSchemaModelRequest, api, { businessAreaId, schemaId });
        }
        yield put(actions.updateSchemaModelSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "schema model");
        if (error.isAxiosError) {
            if ([400, 404, 409, 412].includes(error.response.status) === false) {
                yield call(getSchemaModelRequest, api, { businessAreaId, schemaId });
            }
        }
        if (errorObject.fields) {
            let fields = {};
            for (let fieldKey in errorObject.fields) {
                let fieldPaths = fieldKey.split('.');
                if (fieldPaths[0] === "fields") {
                    if (fieldPaths[1] === "updateRequest") {
                        fieldPaths[2] = updatedSchemaFieldIndexMapping[parseInt(fieldPaths[2])];
                        fields[["fields", fieldPaths[2], fieldPaths[3]].join('.')] = errorObject.fields[fieldKey];
                    }
                    else if (fieldPaths[1] === "insertRequest") {
                        fieldPaths[2] = newFieldIndexMappings[parseInt(fieldPaths[2])];
                        fields[["fields", fieldPaths[2], fieldPaths[3]].join('.')] = errorObject.fields[fieldKey];
                    }
                    else {
                        fields[fieldKey] = errorObject.fields[fieldKey];
                    }
                }
                else {
                    fields[fieldKey] = errorObject.fields[fieldKey];
                }
            }
            errorObject.fields = fields;
        }
        yield put(actions.updateSchemaModelFailure(errorObject));
    }
}

export function* getUserSchemaModelsPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let permissionResponse = yield call(getUserSchemasModelPermissions, api, tenant.tenantId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getUserSchemaModelsPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user schema model permissions");
        yield put(actions.getUserSchemaModelsPermissionFailure(errorObject));
    }
}

export function* getBusinessAreaDataForBusinessAreaRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        let items = yield call(getBusinessAreaDataForBusinessArea, api, tenant.tenantId, businessAreaId);
        yield put(actions.getBusinessAreaDataForBusinessAreaSuccess(items));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area data for business area");
        yield put(actions.getBusinessAreaDataForBusinessAreaFailure(errorObject));
    }
}

export function* uploadSchemaModelDefinitionRequest(api, { businessAreaId, dataFile }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let headers = {
            level: "public",
            contentType: dataFile.type || mime.contentType(dataFile.name) || ""
        };
        let fileName = `tenants/${tenant.tenantId}/users/${user.userId}/schemadefinitions/${uuidv4()}${parsePath(dataFile.name).ext}`;
        let arrayBuffer = yield call(getArrayBufferFromFileObject, dataFile);
        let result = yield call([Storage, Storage.put], fileName, arrayBuffer, headers);
        yield put(actions.uploadSchemaModelDefinitionSuccess({
            bucket: process.env.REACT_APP_BUCKET_NAME,
            key: `public/${result.key}`,
            region: process.env.REACT_APP_BUCKET_REGION,
        }));
    } catch (error) {
        let errorObject = errorHandler(error, "Upload", "schema definition file");
        yield put(actions.uploadSchemaModelDefinitionFailure(errorObject));
    }
}

export function* watchGetUserBusinessAreaByNameRequest(api, { params }) {
    yield call(getUserBusinessAreaByNameRequest, api, params);
}

export function* watchGetSchemaModelsRequest(api, { params }) {
    yield call(getSchemaModelsRequest, api, params);
}

export function* watchGetSchemaModelsNextPageRequest(api, { params }) {
    yield call(getSchemaModelsNextPageRequest, api, params);
}

export function* watchGetRecentSchemaModelsRequest(api, params) {
    yield call(getRecentSchemaModelsRequest, api);
}

export function* watchCreateSchemModelRequest(api, { params }) {
    yield call(createSchemModelRequest, api, params);
}

export function* watchdeleteSchemaModelRequest(api, { params }) {
    yield call(deleteSchemaModelRequest, api, params);
}

export function* watchGetSchemaModelRequest(api, { params }) {
    yield call(getSchemaModelRequest, api, params);
}

export function* watchGetSchemaModelByNameRequest(api, { params }) {
    yield call(getSchemaModelByNameRequest, api, params);
}

export function* watchUpdateSchemaModelRequest(api, { params }) {
    yield call(updateSchemaModelRequest, api, params);
}

export function* watchGetUserSchemaModelsPermissionRequest(api, { params }) {
    yield call(getUserSchemaModelsPermissionRequest, api);
}

export function* watchGetBusinessAreaDataForBusinessAreaRequest(api, { params }) {
    yield call(getBusinessAreaDataForBusinessAreaRequest, api, params);
}

export function* watchUploadSchemaModelDefinitionRequest(api, { params }) {
    yield call(uploadSchemaModelDefinitionRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_USER_BUSINESS_AREA_BY_NAME_REQUEST, watchGetUserBusinessAreaByNameRequest, api);
    yield takeLatest(actions.GET_SCHEMA_MODELS_REQUEST, watchGetSchemaModelsRequest, api);
    yield takeLatest(actions.GET_SCHEMA_MODELS_NEXT_PAGE_REQUEST, watchGetSchemaModelsNextPageRequest, api);
    yield takeLatest(actions.GET_RECENT_SCHEMA_MODELS_REQUEST, watchGetRecentSchemaModelsRequest, api);
    yield takeLatest(actions.CREATE_SCHEMA_MODEL_REQUEST, watchCreateSchemModelRequest, api);
    yield takeLatest(actions.DELETE_SCHEMA_MODEL_REQUEST, watchdeleteSchemaModelRequest, api);
    yield takeLatest(actions.GET_SCHEMA_MODEL_REQUEST, watchGetSchemaModelRequest, api);
    yield takeLatest(actions.GET_SCHEMA_MODEL_BY_NAME_REQUEST, watchGetSchemaModelByNameRequest, api);
    yield takeLatest(actions.UPDATE_SCHEMA_MODEL_REQUEST, watchUpdateSchemaModelRequest, api);
    yield takeLatest(actions.GET_USER_SCHEMA_MODELS_PERMISSION_REQUEST, watchGetUserSchemaModelsPermissionRequest, api);
    yield takeLatest(actions.GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_REQUEST, watchGetBusinessAreaDataForBusinessAreaRequest, api);
    yield takeLatest(actions.UPLOAD_SCHEMA_MODEL_DEFINITION_REQUEST, watchUploadSchemaModelDefinitionRequest, api);
}
