import React, { useState, useEffect } from "react";
import { Space, Table, Typography, Row, Col, InputNumber, Card, List } from "antd";
import { FullHeightContainerLayout } from 'components';
import { v4 as uuidv4 } from 'uuid';
import { CloseCircleTwoTone } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Column } = Table;

const FixWidthDataColumnSpecs = ({ onColumnSpecsChange, columnSpecs, onSpecSelected }) => {

    const [columns, setColumns] = useState(columnSpecs);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        setColumns(columnSpecs);
    }, [columnSpecs])

    const onChange = (index, value, property) => {
        const updatedColumns = [...columns];
        updatedColumns[index] = { ...updatedColumns[index], [property]: value };
        setColumns(updatedColumns);
        onColumnSpecsChange && onColumnSpecsChange(updatedColumns);
    }

    const removeColumnSpec = (index) => {
        const updatedColumns = [...columns];
        updatedColumns.splice(index, 1);
        setColumns(updatedColumns);
        onColumnSpecsChange && onColumnSpecsChange(updatedColumns);
        if (index === selectedIndex) {
            onColumnSpecClicked(-1);
        }
    }

    const onColumnSpecClicked = (index) => {
        setSelectedIndex(index);
        onSpecSelected && onSpecSelected(index);
    }

    return (
        <Row style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <Col span={24}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={false}
                    content={
                        <Row wrap={false}>
                            {
                                columns.map((column, index) => (
                                    <Card
                                        onClick={(event) => {
                                            event.stopPropagation && event.stopPropagation();
                                            onColumnSpecClicked(index)
                                        }}
                                        key={column.name || index}
                                        size="small"
                                        title={column.name}
                                        extra={<CloseCircleTwoTone onClick={(event) => {
                                            event.stopPropagation && event.stopPropagation();
                                            removeColumnSpec(index)
                                        }} />}
                                        className={selectedIndex === index ? "selected-column-spec-card" : "column-spec-card"}>
                                        <Space>
                                            <InputNumber style={{ minWidth: "8rem", maxWidth: "8rem" }} min={1} addonBefore="Start" defaultValue={1} value={column.start} onChange={(value) => onChange(index, value, "start")} />
                                            <InputNumber style={{ minWidth: "8rem", maxWidth: "8rem" }} min={1} addonBefore="End" defaultValue={1} value={column.end} onChange={(value) => onChange(index, value, "end")} />
                                        </Space>

                                    </Card>))
                            }
                        </Row>
                    }>
                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
}

export default FixWidthDataColumnSpecs;