
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import {
    getDataObjectRequest
} from '../dataObjects/sagas';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

export function* createJob(api, tenantId, job) {
    let result = yield call([api, api.post], `${Endpoints.jobs}/api/v1/jobs`, job, { tenantid: tenantId });
    return result.data;
}

function* createDataObjectVerification(api, body) {
    let result = yield call([api, api.post], `${Endpoints.datasets}/api/v1/dataobjects/verification`, body);
    return result.data;
}

function* getDataObjectGuestVerification(api,
    {
        tenantId,
        businessAreaId,
        schemaId,
        dataObjectId,
        verificationId,
        sender,
        version,
        toEmail,
        expires,
        date,
        signature
    }) {
    try {
        let result = yield call([api, api.getAnonymous],
            `${Endpoints.datasets}/api/v1/dataobjects/verification/${tenantId}/${businessAreaId}/${schemaId}/${dataObjectId}/${verificationId}`,
            {},
            {
                params: {
                    s: sender,
                    v: version,
                    to: toEmail,
                    expires,
                    date,
                    signature
                }
            });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateDataObjectGuestVerification(api,
    {
        tenantId,
        businessAreaId,
        schemaId,
        dataObjectId,
        verificationId,
        sender,
        version,
        toEmail,
        expires,
        date,
        signature,
        dataObject,
        consent,
        verificationVersion
    }) {
    let result = yield call([api, api.putAnonymous],
        `${Endpoints.datasets}/api/v1/dataobjects/verification/${tenantId}/${businessAreaId}/${schemaId}/${dataObjectId}/${verificationId}`,
        {
            dataObject,
            consent,
            version: verificationVersion
        },
        {},
        {
            params: {
                s: sender,
                v: version,
                to: toEmail,
                expires,
                date,
                signature
            }
        });
    return result.data;
}

function* getDataObjectVerifications(api, { businessAreaId, schemaId, dataObjectId }) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/dataobjects/${dataObjectId}/verifications`);
    return result.data;
}


function* getDataObjectConsents(api, { dataObjectId }) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/dataobjects/${dataObjectId}/verifications/consents`);
    return result.data;
}


export function* createDataObjectVerificationRequest(api, { businessAreaId, schemaId, dataSetId, dataObjectId, consent, fields, bulkVerification }) {
    try {
        if (bulkVerification) {
            let tenant = yield select(getTenant);
            let user = yield select(getUser);
            let job = {
                name: `Records bulk verification`,
                description: `Records bulk verification`,
                businessAreaId: businessAreaId,
                recordType: "DATA_OBJECTS",
                schemaId: schemaId,
                dataSetId: dataSetId,
                jobInfo: {
                    jobIdentifier: `${dataSetId}_DATA_OBJECTS_BULK_VERIFICATION`,
                    runUniqueJob: true,
                    businessAreaId: businessAreaId,
                    schemaId: schemaId,
                    dataSetId: dataSetId,
                    tenantId: tenant.tenantId,
                    userId: user.userId,
                    consent,
                    fields
                },
                jobType: "DATA_OBJECTS_BULK_VERIFICATION"
            }
            yield call(createJob, api, tenant.tenantId, job);
            yield put(actions.createDataObjectVerificationSuccess({ bulkVerification }));
        }
        else {
            const result = yield call(createDataObjectVerification, api, { businessAreaId, schemaId, dataSetId, dataObjectId, consent, fields });
            yield put(actions.createDataObjectVerificationSuccess(result));
            yield call(getDataObjectRequest, api, { businessAreaId, schemaId, dataSetId, dataObjectId });
        }

    } catch (error) {
        let errorObject = errorHandler(error, "Create", "verification");
        yield put(actions.createDataObjectVerificationFailure(errorObject));
    }
}

export function* getDataObjectGuestVerificationRequest(api,
    {
        tenantId,
        businessAreaId,
        schemaId,
        dataObjectId,
        verificationId,
        sender,
        version,
        toEmail,
        expires,
        date,
        signature
    }) {
    try {
        const result = yield call(getDataObjectGuestVerification, api,
            {
                tenantId,
                businessAreaId,
                schemaId,
                dataObjectId,
                verificationId,
                sender,
                version,
                toEmail,
                expires,
                date,
                signature
            });
        yield put(actions.getDataObjectGuestVerificationSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "verification");
        yield put(actions.getDataObjectGuestVerificationFailure(errorObject));
    }
}

export function* updateDataObjectGuestVerificationRequest(api,
    {
        tenantId,
        businessAreaId,
        schemaId,
        dataObjectId,
        verificationId,
        sender,
        version,
        toEmail,
        expires,
        date,
        signature,
        dataObject,
        consent,
        verificationVersion
    }) {
    try {
        const result = yield call(updateDataObjectGuestVerification, api,
            {
                tenantId,
                businessAreaId,
                schemaId,
                dataObjectId,
                verificationId,
                sender,
                version,
                toEmail,
                expires,
                date,
                signature,
                dataObject,
                consent,
                verificationVersion
            });
        yield put(actions.updateDataObjectGuestVerificationSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "verification");
        yield put(actions.updateDataObjectGuestVerificationFailure(errorObject));
    }
}

export function* getDataObjectVerificationsRequest(api, { businessAreaId, schemaId, dataObjectId }) {
    try {
        const result = yield call(getDataObjectVerifications, api, { businessAreaId, schemaId, dataObjectId });
        yield put(actions.getDataObjectVerificationsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "verifications");
        yield put(actions.getDataObjectVerificationsFailure(errorObject));
    }
}

export function* getDataObjectConsentsRequest(api, { dataObjectId }) {
    try {
        const result = yield call(getDataObjectConsents, api, { dataObjectId });
        yield put(actions.getDataObjectConsentsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "consents");
        yield put(actions.getDataObjectConsentsFailure(errorObject));
    }
}

export function* watchCreateDataObjectVerificationRequest(api, { params }) {
    yield call(createDataObjectVerificationRequest, api, params);
}

export function* watchGetDataObjectVerificationRequest(api, { params }) {
    yield call(getDataObjectGuestVerificationRequest, api, params);
}

export function* watchUpdateDataObjectGuestVerificationRequest(api, { params }) {
    yield call(updateDataObjectGuestVerificationRequest, api, params);
}

export function* watchGetDataObjectVerificationsRequest(api, { params }) {
    yield call(getDataObjectVerificationsRequest, api, params);
}

export function* watchGetDataObjectConsentsRequest(api, { params }) {
    yield call(getDataObjectConsentsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.CREATE_DATA_OBJECT_VERIFICATION_REQUEST, watchCreateDataObjectVerificationRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_GUEST_VERIFICATION_REQUEST, watchGetDataObjectVerificationRequest, api);
    yield takeLatest(actions.UPDATE_DATA_OBJECT_GUEST_VERIFICATION_REQUEST, watchUpdateDataObjectGuestVerificationRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_VERIFICATIONS_REQUEST, watchGetDataObjectVerificationsRequest, api);
    yield takeLatest(actions.GET_DATA_OBJECT_CONSENTS_REQUEST, watchGetDataObjectConsentsRequest, api);
}