import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Tooltip, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { toLocalFromUtcDate } from 'common/Utility'

function FormattedDateInput({ value, onChange, readOnly }) {
    const [inputValue, setInputValue] = useState(toLocalFromUtcDate(value));
    useEffect(() => {
        setInputValue(toLocalFromUtcDate(value));
    }, [value]);

    const onInputValueChange = (e) => {
        setInputValue(e.target.value);
        onChange(e.target.value);
    }

    return <Input onChange={onInputValueChange} readOnly={readOnly} value={inputValue} style={{ width: '100%' }} />
}

export default FormattedDateInput