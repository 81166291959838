import React from "react";
import { Table, List, Space, Row, Col, Typography } from "antd";
import { ListRecordAttributeItem } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const displayLabels = {
    "domainName": "Domain Name",
    "domainDescription": "Description",
    "domainPurpose": "Purpose",
    "usedForTagging": "Used for Tagging",
    "updatedByEmail": "Updated by",
    "updatedDate": "Updated at",
    "recordCount": "Record count",
    "deleted": "Deleted",
    "businessAreaName": "Business Area"
}

export default function ({ record }) {
    return <>
        <Row className="middle-row">
            <Col span={24}>
                <Title level={5}><Text>{record.name}</Text></Title>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <List
                    size="small"
                    bordered
                    dataSource={Object.keys(record.data)}
                    renderItem={item => {
                        if (displayLabels[item]) {
                            if (item === "updatedDate") {
                                return <ListRecordAttributeItem name={displayLabels[item]} value={moment.utc(record.data[item]).toDate().toLocaleString()} previousValue={record.previousData && record.previousData[item] && moment.utc(record.previousData[item]).toDate().toLocaleString()}></ListRecordAttributeItem>
                            }
                            return <ListRecordAttributeItem name={displayLabels[item]} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                        }
                        return <></>
                    }}
                />
            </Col>
        </Row>
    </>
}