import React from "react";
import { Table, Button, Row, Col, Typography } from "antd";
import moment from 'moment';
import { BatchProcessResult, Tooltip, FullHeightContainerLayout } from 'components';
import {
    PlayCircleTwoTone,
    StopTwoTone
} from '@ant-design/icons';
const { Column } = Table;
const { Link, Text } = Typography;

const DataObjectJobRecentList = ({ jobList }) => {
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="jobId"
                            dataSource={jobList}
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            className="container-height-100">
                            <Column
                                width="15%"
                                dataIndex={"dataSetName"}
                                title={"Dataset"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="12%"
                                dataIndex={"businessAreaName"}
                                title={"Business Area"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="10%"
                                dataIndex={"status"}
                                title={"Run Status"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, job, index) => {
                                    let color = "#5C5B5B";
                                    switch (value) {
                                        case "COMPLETED":
                                            color = "#11A400";
                                            break;
                                        case "FAILED":
                                        case "CANCELLING":
                                        case "CANCELLED":
                                            color = "#FF0000";
                                            break;
                                        case "RUNNING":
                                        case "PENDING":
                                            color = "#1F81FF";
                                            break;
                                    }
                                    let statusText = value;
                                    if (job.undo) {
                                        if (job.jobType === "FIND_DATA_OBJECTS_DUPLICATES") {
                                            statusText = `DELETE ${statusText}`;
                                        }
                                        else {
                                            statusText = `UNDO ${statusText}`;
                                        }
                                    }
                                    return <Tooltip placement="topLeft" title={statusText}><span style={{ color: color }}>{statusText}</span></Tooltip>
                                }}
                            />
                            <Column
                                width="auto"
                                dataIndex={"runInfo"}
                                title={"Progress"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, job, index) => {
                                    return value ?
                                        <Tooltip placement="topLeft">
                                            <BatchProcessResult
                                                batchProcessResult={value}
                                                textMaxWidth="12rem"
                                                onBatchOperationClick={(item) => {
                                                }}>
                                            </BatchProcessResult>
                                        </Tooltip>
                                        :
                                        <></>
                                }}
                            />
                            <Column
                                width="15%"
                                dataIndex={"startedDate"}
                                title={"Started DateTime"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, job, index) => {
                                    let displayValue = null;
                                    if (value) {
                                        displayValue = moment.utc(value).toDate().toLocaleString();
                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                    }
                                    return <></>;
                                }}
                            />
                            <Column
                                width="15%"
                                title={"Running Duration"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(job) => {
                                    if (job.startedDate && job.completedDate) {
                                        let startDate = moment.utc(job.startedDate);
                                        let completedDate = moment.utc(job.completedDate);
                                        let duration = moment.duration(completedDate.diff(startDate));
                                        let displayValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                    }
                                    return <></>;
                                }}
                            />
                            <Column
                                width="20%"
                                dataIndex={"createdByEmail"}
                                title={"Job Owner"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value || job.createdBy}</Tooltip>}
                            />
                        </Table>
                    </div>
                </div>}>
        </FullHeightContainerLayout>
    );
};

export default DataObjectJobRecentList;