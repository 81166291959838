import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { DataObjectVerificationEventList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';

const { Title, Text } = Typography;

class EventDataObjectVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        this.setState({
            busy: true,
            busyMessage: "Getting record verifications...",
        });
        this.props.getEventDataObjectVerifications(this.props.eventId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventDataObjectVerificationsResultChanged(prevProps);
    }

    onGetEventDataObjectVerificationsResultChanged = (prevProps) => {
        if (this.props.getEventDataObjectVerificationsResult && this.props.getEventDataObjectVerificationsResult !== prevProps.getEventDataObjectVerificationsResult) {
            if (!this.props.getEventDataObjectVerificationsResult.success) {
                if (this.props.getEventDataObjectVerificationsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get record verifications at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Record Verifications</Text></Title>}
                            content={this.props.dataObjectVerifications ?
                                <DataObjectVerificationEventList verificationList={this.props.dataObjectVerifications}></DataObjectVerificationEventList>
                                :
                                <Empty></Empty>}>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventDataObjectVerificationsResult: state.eventDataObjectVerifications.getEventDataObjectVerificationsResult,
        dataObjectVerifications: state.eventDataObjectVerifications.dataObjectVerifications
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventDataObjectVerifications: (eventId) => dispatch(actions.getEventDataObjectVerificationsRequest(eventId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventDataObjectVerification));