import {put, call, takeEvery, select, takeLatest} from 'redux-saga/effects';
import * as actions from './actions';
import {Endpoints} from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventSystemKeys(api, eventId, keyId) {
    try{
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/keys/${keyId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventSystemKeysRequest(api, { eventId, keyId }) {
    try {
        // console.log("keyid and event: ", keyId, eventId)
        const systemKeyRevision = yield call(getEventSystemKeys, api, eventId, keyId);
        yield put(actions.getEventSystemKeySuccess(systemKeyRevision));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event credential details");
        yield put(actions.getEventSystemKeyFailure(errorObject));
    }
}

export function* watchGetEventSystemKeysRequest(api, { params }) {
    yield call(getEventSystemKeysRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_SYSTEM_KEY_REQUEST, watchGetEventSystemKeysRequest, api);
}