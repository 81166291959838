import { Col, Row } from 'antd';

export const nameRules = [
    {
        required: true,
        message: <Row style={{ whiteSpace: "break-spaces" }}><Col>Please input name</Col> </Row>
    },
    {
        min: 2,
        message: <Row style={{ whiteSpace: "break-spaces" }}><Col>Input should be minimum 2 characters long</Col> </Row>
    },
    {
        pattern: /^[a-zA-Z\u00C0-\u00FF]+[-_ a-zA-Z0-9\u00C0-\u00FF]+$/,
        message: <Row style={{ whiteSpace: "break-spaces" }}><Col>Input should start with alphabets. And only alphabets, numbers, hyphen, underscore, space are allowed</Col> </Row>
    },
    {
        max: 100,
        message: <Row style={{ whiteSpace: "break-spaces" }}><Col>Input should be maximum 100 characters long</Col> </Row>
    }
]

export const descriptionRules = [
    {
        max: 500,
        message: <Row style={{ whiteSpace: "break-spaces" }}><Col>Input should be maximum 500 characters long</Col> </Row>
    }
]