import React from 'react';
import moment from 'moment';
import _ from 'lodash';

const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss";

function FormDateTimeDisplayField({ value, dateFormat, dateSeparator, timeFormat, ...field }) {
    const format = `${_.replace(dateFormat, /\$/g, dateSeparator)} ${timeFormat}`;
    let dateTimeValue = value && moment(value, ISO_FORMAT);
    if (dateTimeValue) {
        if (dateTimeValue.isValid() === false) {
            dateTimeValue = value;
        }
        else {
            dateTimeValue = dateTimeValue.format(format);
        }
    }
    return <>{dateTimeValue}</>;
}

export default FormDateTimeDisplayField