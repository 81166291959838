import React from 'react';
import { Form, InputNumber } from "antd";
import { SchemaFieldTitle } from 'components';
const validator = require('validator');

function FormIntegerField({ fieldErrors, fieldId, name, description, max, min, isRequired, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={<SchemaFieldTitle name={name} tags={field.classifiers} orientation="horizontal" tagSummary={false}></SchemaFieldTitle>}
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {

                    if (value && value.toString().trim()) {
                        if (!validator.isInt(value.toString().trim(), { max, min })) {
                            return Promise.reject(`Value should be a valid integer value in range ${max} and ${min}`);
                        }

                        let intValue = parseInt(value);
                        if (isNaN(intValue)) {
                            return Promise.reject(`Value should be a valid integer value`);
                        }
                    }

                    return Promise.resolve();
                }
            },
            {
                type: "number",
                min: min,
                message: `Value should be greater than or equal to ${min}`
            },
            {
                type: "number",
                max: max,
                message: `Value should be less than or equal to ${max}`
            }
        ]}
        {...fieldErrors}>
        <InputNumber min={min} max={max} step={1} />
    </Form.Item>
}

export default FormIntegerField