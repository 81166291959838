import React, { useState, useEffect } from 'react';
import { Dropdown, Menu } from "antd";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/fontawesome-free-solid';

const VerticleEllipsisMenu = ({ onClick, items, data }) => {
    return <Dropdown
        overlay={<Menu onClick={(e) => onClick({ ...e, data })}
            items={items}
        />}>
        <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
    </Dropdown>
}

export default VerticleEllipsisMenu