import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getRoleEvents(api, tenantId, roleId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/roles/${roleId}/events`, { tenantid: tenantId });
    return result.data;
}

export function* getRoleEventsRequest(api, { roleId }) {
    try {
        let tenant = yield select(getTenant);
        let roleEventsResult = yield call(getRoleEvents, api, tenant.tenantId, roleId);
        yield put(actions.getRoleEventsSuccess(roleEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "role events");
        yield put(actions.getRoleEventsFailure(errorObject));
    }
}

export function* watchGetRoleEventsRequest(api, { params }) {
    yield call(getRoleEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_ROLE_EVENTS_REQUEST, watchGetRoleEventsRequest, api);
}