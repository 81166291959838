import React from "react";
import { Table, List, Space, Row, Col, Typography } from "antd";
import { ListRecordAttributeItem, StaticPicklistRecordAttributeItem } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const displayLabels = {
    "name": "Name",
    "description": "Description",
    "active": "Active",
    "isPrimaryKey": "Primary key",
    "isSortKey": "Sort key",
    "dataType": "Data type",
    "max": "Max value",
    "min": "Min value",
    "isRequired": "Required",
    "isMultivalue": "Multi value",
    "isPII": "PII",
    "classifiers": "Classifiers",
    "allowedBooleanValues": "Allowed Values",
    "picklistType": "Picklist Type",
    "dataDomainId": "Data domain",
    "staticPicklist": "Static Picklist",
    "dateFormat": "Date format",
    "dateSeparator": "Date separator",
    "timeFormat": "Time format",
    "updatedDate": "Updated at",
    "deleted": "Deleted",
    "updatedByEmail": "Updated by"
}

export default function ({ record }) {
    return <>
        <Row className="middle-row">
            <Col span={24}>
                <Title level={5}><Text>{record.name}</Text></Title>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <List
                    size="small"
                    bordered
                    dataSource={Object.keys(record.data)}
                    renderItem={item => {
                        if (displayLabels[item]) {
                            if (item === "updatedDate") {
                                return <ListRecordAttributeItem name={displayLabels[item]} value={moment.utc(record.data[item]).toDate().toLocaleString()} previousValue={record.previousData && record.previousData[item] && moment.utc(record.previousData[item]).toDate().toLocaleString()}></ListRecordAttributeItem>
                            }
                            if (item === "staticPicklist") {
                                return <StaticPicklistRecordAttributeItem name={displayLabels[item]} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></StaticPicklistRecordAttributeItem>
                            }
                            if (item === "classifiers") {
                                return <ListRecordAttributeItem name={displayLabels[item]} value={record.data[item] && record.data[item].map(tag => tag.tagName).join(", ")} previousValue={record.previousData && record.previousData[item] && record.previousData[item].map(tag => tag.tagName).join(", ")}></ListRecordAttributeItem>
                            }
                            return <ListRecordAttributeItem name={displayLabels[item]} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                        }
                        return <></>
                    }}
                />
            </Col>
        </Row>
    </>
}