
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { Storage } from "aws-amplify";
import * as actions from './actions';
import { Auth } from "aws-amplify";
import { Endpoints } from 'services/api';
import moment from 'moment';
import { keyGenerator } from 'common/Utility';
import { getArrayBufferFromFileObject, sanitizeFileName } from 'common/fileHandler';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
import * as homeSaga from 'store/home/sagas';
import mime from 'mime-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import parsePath from 'parse-filepath';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;
const getUserBusinessAreasDict = (state) => state.home.businessAreasDict;

function* createDataSet(api, tenantId, businessAreaId, schemaId, dataSet) {
    let result = yield call([api, api.post], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets`, dataSet, { tenantid: tenantId });
    return result.data;
}

function* getDataSetBySchemaId(api, tenantId, businessAreaId, schemaId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets`, { tenantid: tenantId });
    return result.data;
}

function* updateDataSet(api, tenantId, businessAreaId, schemaId, dataSetId, dataSet) {
    let result = yield call([api, api.put], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}`, dataSet, { tenantid: tenantId });
    return result.data;
}

function* getDataSetById(api, tenantId, businessAreaId, schemaId, dataSetId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getRecentDataSet(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datasets/recents`, { tenantid: tenantId });
    return result.data;
}

function* getDataSetByName(api, tenantId, businessAreaId, schemaId, dataSetName) {
    try {
        let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/name/${dataSetName}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getDataSetFieldInsights(api, tenantId, dataSetId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datasets/${dataSetId}/fieldinsights`, { tenantid: tenantId });
    return result.data;
}

function* deleteDataSet(api, tenantId, businessAreaId, schemaId, dataSetId) {
    yield call([api, api.delete], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}`, { tenantid: tenantId });
}

function* downloadExportDataSet(api, key) {
    const result = yield call([api, api.post], `${Endpoints.datasets}/api/v1/download`, { key });
    return result.data;
}

export function* getUserDataSetPermission(api, tenantId, userId) {
    //TODo - remove the current user call and replace it with pretectum user
    let user = yield call([Auth, Auth.currentAuthenticatedUser]);
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/datasets`, { tenantid: tenantId });
    return result.data;
}

export function* getUserExportJobPermission(api, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/jobs`);
    return result.data;
}

function* getDataSetJobs(api, tenantId, dataSetId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/datasets/${dataSetId}/jobs`, { tenantid: tenantId });
    return result.data;
}

function* undoJob(api, tenantId, jobId, version) {
    yield call([api, api.put], `${Endpoints.jobs}/api/v1/jobs/${jobId}/undo`, { version }, { tenantid: tenantId });
}

export function* createJob(api, tenantId, job) {
    let result = yield call([api, api.post], `${Endpoints.jobs}/api/v1/jobs`, job, { tenantid: tenantId });
    return result.data;
}

function* getJob(api, tenantId, jobId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/${jobId}`, { tenantid: tenantId });
    return result.data;
}


export function* createDataSetRequest(api, { businessAreaId, schemaId, dataSet }) {
    try {
        let tenant = yield select(getTenant);
        let newDataSet = yield call(createDataSet, api, tenant.tenantId, businessAreaId, schemaId, dataSet);

        yield put(actions.createDataSetSuccess(newDataSet));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "dataset");
        yield put(actions.createDataSetFailure(errorObject));
    }
}

export function* getDataSetBySchemaIdRequest(api, { businessAreaId, schemaId }) {
    try {
        let tenant = yield select(getTenant);
        let schemaDataSetResult = yield call(getDataSetBySchemaId, api, tenant.tenantId, businessAreaId, schemaId);
        yield put(actions.getDataSetBySchemaIdSuccess(schemaDataSetResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "datasets");
        yield put(actions.getDataSetBySchemaIdFailure(errorObject));
    }
}

export function* getDataSetByIdRequest(api, { businessAreaId, schemaId, dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        let dataSet = yield call(getDataSetById, api, tenant.tenantId, businessAreaId, schemaId, dataSetId);
        yield put(actions.getDataSetByIdSuccess(dataSet));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataset");
        yield put(actions.getDataSetByIdFailure(errorObject));
    }
}

export function* getRecentDataSetRequest(api) {
    try {
        let tenant = yield select(getTenant);
        let result = yield call(getRecentDataSet, api, tenant.tenantId);
        let userBusinessAreasDict = yield select(getUserBusinessAreasDict);
        if (result && result.Items && result.Items.length > 0) {
            for (let dataSet of result.Items) {
                if (userBusinessAreasDict && userBusinessAreasDict[dataSet.businessAreaId]) {
                    dataSet.businessAreaName = userBusinessAreasDict[dataSet.businessAreaId].name;
                }
            }
        }
        yield put(actions.getRecentDataSetSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "recent datasets");
        yield put(actions.getRecentDataSetFailure(errorObject));
    }
}

export function* getDataSetByNameRequest(api, { businessAreaId, schemaId, dataSetName }) {
    try {
        let tenant = yield select(getTenant);
        let dataSet = yield call(getDataSetByName, api, tenant.tenantId, businessAreaId, schemaId, dataSetName);
        yield put(actions.getDataSetByNameSuccess(dataSet));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataset");
        yield put(actions.getDataSetByNameFailure(errorObject));
    }
}

export function* getDataSetFieldInsightsRequest(api, { dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        let dataSetFieldInsightsResult = yield call(getDataSetFieldInsights, api, tenant.tenantId, dataSetId);
        yield put(actions.getDataSetFieldInsightsSuccess(dataSetFieldInsightsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataset insights");
        yield put(actions.getDataSetFieldInsightsFailure(errorObject));
    }
}

export function* updateDataSetRequest(api, { businessAreaId, schemaId, dataSet, updatedDataSet }) {
    try {
        let tenant = yield select(getTenant);
        if (_.isEqual(dataSet, { ...dataSet, ...updatedDataSet }) === false) {
            updatedDataSet.version = dataSet.version;
            yield call(updateDataSet, api, tenant.tenantId, businessAreaId, schemaId, dataSet.dataSetId, updatedDataSet);
            updatedDataSet = yield call(getDataSetById, api, tenant.tenantId, businessAreaId, schemaId, dataSet.dataSetId);
            yield put(actions.updateDataSetSuccess(updatedDataSet));
        }
        else {
            yield put(actions.updateDataSetSuccess(dataSet));
        }

    } catch (error) {
        let errorObject = errorHandler(error, "Update", "dataset");
        yield put(actions.updateDataSetFailure(errorObject));
    }
}

export function* deleteDataSetRequest(api, { businessAreaId, schemaId, dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        yield call(deleteDataSet, api, tenant.tenantId, businessAreaId, schemaId, dataSetId);
        yield put(actions.deleteDataSetSuccess(businessAreaId, schemaId, dataSetId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "dataset");
        yield put(actions.deleteDataSetFailure(errorObject));
    }
}

export function* getUserDataSetPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getUserDataSetPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getUserDataSetPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user dataset permissions");
        yield put(actions.getUserDataSetPermissionFailure(errorObject));
    }
}

export function* getDataSetJobsRequest(api, { dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        const jobsResult = yield call(getDataSetJobs, api, tenant.tenantId, dataSetId);
        yield put(actions.getDataSetJobsSuccess(jobsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "jobs");
        yield put(actions.getDataSetJobsFailure(errorObject));
    }
}

export function* undoJobRequest(api, { jobId, version }) {
    try {
        let tenant = yield select(getTenant);
        yield call(undoJob, api, tenant.tenantId, jobId, version);
        let updatedJob = yield call(getJob, api, tenant.tenantId, jobId);
        yield put(actions.undoJobSuccess(updatedJob));
    } catch (error) {
        let errorObject = errorHandler(error, "Undo", "job");
        yield put(actions.undoJobFailure(errorObject));
    }
}

export function* indexDataSetRequest(api, { dataSet }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let indexDataSetJob = {
            name: `Index ${dataSet.dataSetName}`,
            description: `Index ${dataSet.dataSetName}`,
            businessAreaId: dataSet.businessAreaId,
            recordType: "DATA_OBJECTS",
            schemaId: dataSet.schemaId,
            dataSetId: dataSet.dataSetId,
            jobInfo: {
                runUniqueJob: false,
                businessAreaId: dataSet.businessAreaId,
                schemaId: dataSet.schemaId,
                dataSetId: dataSet.dataSetId,
                tenantId: tenant.tenantId,
                userId: user.userId
            },
            jobType: "INDEX_SEARCH_DATA_OBJECTS"
        }
        yield call(createJob, api, tenant.tenantId, indexDataSetJob);
        yield put(actions.indexDataSetSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Index", "dataset");
        yield put(actions.indexDataSetFailure(errorObject));
    }
}

export function* exportDataSetToFileRequest(api, { dataSet }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let exportDataSetJob = {
            name: `Export ${dataSet.dataSetName} to a file`,
            description: `Export ${dataSet.dataSetName} to a file`,
            businessAreaId: dataSet.businessAreaId,
            recordType: "DATA_OBJECTS",
            schemaId: dataSet.schemaId,
            dataSetId: dataSet.dataSetId,
            jobInfo: {
                exportDestination: "FILE",
                runUniqueJob: false,
                businessAreaId: dataSet.businessAreaId,
                schemaId: dataSet.schemaId,
                dataSetId: dataSet.dataSetId,
                tenantId: tenant.tenantId,
                userId: user.userId
            },
            jobType: "DATA_OBJECTS_EXPORT"
        }
        yield call(createJob, api, tenant.tenantId, exportDataSetJob);
        yield put(actions.exportDataSetToFileSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Export", "dataset to a file");
        yield put(actions.exportDataSetToFileFailure(errorObject));
    }
}

export function* downloadExportDataSetRequest(api, { fileKey, fileName }) {
    try {
        const result = yield call(downloadExportDataSet, api, fileKey);
        yield put(actions.downloadExportDataSetSuccess({ ...result, fileName }));
    } catch (error) {
        let errorObject = errorHandler(error, "Download", "export file");
        yield put(actions.downloadExportDataSetFailure(errorObject));
    }
}

export function* exportDataSetToSystemConnectionRequest(api, { dataSet, connection }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let exportDataSetJob = {
            name: `Export ${dataSet.dataSetName} dataset to ${connection.connectionName} connection`,
            description: `Export ${dataSet.dataSetName} dataset to ${connection.connectionName} connection`,
            businessAreaId: dataSet.businessAreaId,
            recordType: "DATA_OBJECTS",
            schemaId: dataSet.schemaId,
            dataSetId: dataSet.dataSetId,
            jobInfo: {
                runUniqueJob: false,
                exportDestination: "SYSTEM_CONNECTION",
                businessAreaId: dataSet.businessAreaId,
                schemaId: dataSet.schemaId,
                dataSetId: dataSet.dataSetId,
                tenantId: tenant.tenantId,
                userId: user.userId,
                connectionId: connection.connectionId,
                connectionType: connection.connectionType
            },
            jobType: "DATA_OBJECTS_EXPORT"
        }

        if (connection.connectionType === "aws_s3") {
            exportDataSetJob.jobInfo.s3 = connection.bucket;
            exportDataSetJob.jobInfo.key = connection.file.path;
        }
        yield call(createJob, api, tenant.tenantId, exportDataSetJob);
        yield put(actions.exportDataSetToSystemConnectionSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Export", "dataset to a system connection");
        yield put(actions.exportDataSetToSystemConnectionFailure(errorObject));
    }
}

export function* getUserExportJobPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let user = yield select(getUser);
        let permissionResponse = yield call(getUserExportJobPermission, api, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let exportJobPermissions = permissionResponse.Items.filter(permission => permission.condition && permission.condition.attributeEquals && permission.condition.attributeEquals.jobType && permission.condition.attributeEquals.jobType === "DATA_OBJECTS_EXPORT");
            if (exportJobPermissions) {
                let permissionActions = exportJobPermissions.map(item => item.actions);
                permissionActions = _.union(...permissionActions);
                if (permissionActions.length > 0) {
                    if (permissionActions.includes("add")) {
                        permission.canAdd = true;
                    }
                    if (permissionActions.includes("view")) {
                        permission.canView = true;
                    }
                    if (permissionActions.includes("edit")) {
                        permission.canEdit = true;
                    }
                    if (permissionActions.includes("delete")) {
                        permission.canDelete = true;
                    }
                }
            }
        }

        yield put(actions.getUserExportJobPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user export job permissions");
        yield put(actions.getUserExportJobPermissionFailure(errorObject));
    }
}

export function* watchCreateDataSetRequest(api, { params }) {
    yield call(createDataSetRequest, api, params);
}

export function* watchGetDataSetBySchemaIdRequest(api, { params }) {
    yield call(getDataSetBySchemaIdRequest, api, params);
}

export function* watchGetDataSetByIdRequest(api, { params }) {
    yield call(getDataSetByIdRequest, api, params);
}

export function* watchGetRecentDataSetRequest(api, params) {
    yield call(getRecentDataSetRequest, api);
}

export function* watchGetDataSetByNameRequest(api, { params }) {
    yield call(getDataSetByNameRequest, api, params);
}

export function* watchGetDataSetFieldInsightsRequest(api, { params }) {
    yield call(getDataSetFieldInsightsRequest, api, params);
}

export function* watchUpdateDataSetRequest(api, { params }) {
    yield call(updateDataSetRequest, api, params);
}

export function* watchDeleteDataSetRequest(api, { params }) {
    yield call(deleteDataSetRequest, api, params);
}

export function* watchGetUserDataSetPermissionRequest(api, { params }) {
    yield call(getUserDataSetPermissionRequest, api);
}

export function* watchGetDataSetJobsRequest(api, { params }) {
    yield call(getDataSetJobsRequest, api, params);
}

export function* watchUndoJobRequest(api, { params }) {
    yield call(undoJobRequest, api, params);
}

export function* watchIndexDataSetRequest(api, { params }) {
    yield call(indexDataSetRequest, api, params);
}

export function* watchExportDataSetToFileRequest(api, { params }) {
    yield call(exportDataSetToFileRequest, api, params);
}

export function* watchDownloadExportDataSetRequest(api, { params }) {
    yield call(downloadExportDataSetRequest, api, params);
}

export function* watchExportDataSetToSystemConnectionRequest(api, { params }) {
    yield call(exportDataSetToSystemConnectionRequest, api, params);
}

export function* watchGetUserExportJobPermissionRequest(api, { params }) {
    yield call(getUserExportJobPermissionRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.CREATE_DATA_SET_REQUEST, watchCreateDataSetRequest, api);
    yield takeLatest(actions.GET_DATA_SET_BY_SCHEMA_ID_REQUEST, watchGetDataSetBySchemaIdRequest, api);
    yield takeLatest(actions.GET_DATA_SET_BY_ID_REQUEST, watchGetDataSetByIdRequest, api);
    yield takeLatest(actions.GET_RECENT_DATA_SET_REQUEST, watchGetRecentDataSetRequest, api);
    yield takeLatest(actions.GET_DATA_SET_BY_NAME_REQUEST, watchGetDataSetByNameRequest, api);
    yield takeLatest(actions.GET_DATA_SET_FIELD_INSIGHTS_REQUEST, watchGetDataSetFieldInsightsRequest, api);
    yield takeLatest(actions.UPDATE_DATA_SET_REQUEST, watchUpdateDataSetRequest, api);
    yield takeEvery(actions.DELETE_DATA_SET_REQUEST, watchDeleteDataSetRequest, api);
    yield takeEvery(actions.GET_USER_DATA_SET_PERMISSION_REQUEST, watchGetUserDataSetPermissionRequest, api);
    yield takeLatest(actions.GET_DATA_SET_JOBS_REQUEST, watchGetDataSetJobsRequest, api);
    yield takeLatest(actions.UNDO_JOB_REQUEST, watchUndoJobRequest, api);
    yield takeLatest(actions.INDEX_DATA_SET_REQUEST, watchIndexDataSetRequest, api);
    yield takeLatest(actions.EXPORT_DATA_SET_TO_FILE_REQUEST, watchExportDataSetToFileRequest, api);
    yield takeLatest(actions.DOWNLOAD_EXPORT_DATA_SET_REQUEST, watchDownloadExportDataSetRequest, api);
    yield takeLatest(actions.EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_REQUEST, watchExportDataSetToSystemConnectionRequest, api);
    yield takeLatest(actions.GET_USER_EXPORT_JOB_PERMISSION_REQUEST, watchGetUserExportJobPermissionRequest, api);
}