export const GET_ROLE_EVENTS_REQUEST = "GET_ROLE_EVENTS_REQUEST";
export const GET_ROLE_EVENTS_SUCCESS = "GET_ROLE_EVENTS_SUCCESS";
export const GET_ROLE_EVENTS_FAILURE = "GET_ROLE_EVENTS_FAILURE";

export const getRoleEventsRequest = (roleId) => ({
    type: GET_ROLE_EVENTS_REQUEST,
    params: {
        roleId
    }
})

export const getRoleEventsSuccess = (roleEventResults) => ({
    type: GET_ROLE_EVENTS_SUCCESS,
    payload: roleEventResults
})

export const getRoleEventsFailure = (error) => ({
    type: GET_ROLE_EVENTS_FAILURE,
    payload: error
})