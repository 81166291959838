import {
    GET_EVENT_DATA_DOMAIN_SUCCESS,
    GET_EVENT_DATA_DOMAIN_FAILURE
} from "./actions";

const initialState = {
    getEventDataDomainResult: {
        success: true
    },
    dataDomainRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_DATA_DOMAIN_SUCCESS:
            return { ...state, getEventDataDomainResult: { success: true }, dataDomainRevision: action.payload };
        case GET_EVENT_DATA_DOMAIN_FAILURE:
            return {
                ...state, getEventDataDomainResult: { success: false, ...action.payload }, dataDomainRevision: null
            };
        default:
            return state;
    }
};
