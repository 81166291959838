export const GET_BUSINESS_AREAS_REQUEST = "GET_BUSINESS_AREAS_REQUEST";
export const GET_BUSINESS_AREAS_SUCCESS = "GET_BUSINESS_AREAS_SUCCESS";
export const GET_BUSINESS_AREAS_FAILURE = "GET_BUSINESS_AREAS_FAILURE";

export const CREATE_BUSINESS_AREA_REQUEST = "CREATE_BUSINESS_AREA_REQUEST";
export const CREATE_BUSINESS_AREA_SUCCESS = "CREATE_BUSINESS_AREA_SUCCESS";
export const CREATE_BUSINESS_AREA_FAILURE = "CREATE_BUSINESS_AREA_FAILURE";

export const GET_BUSINESS_AREA_BY_ID_REQUEST = "GET_BUSINESS_AREA_BY_ID_REQUEST";
export const GET_BUSINESS_AREA_BY_ID_SUCCESS = "GET_BUSINESS_AREA_BY_ID_SUCCESS";
export const GET_BUSINESS_AREA_BY_ID_FAILURE = "GET_BUSINESS_AREA_BY_ID_FAILURE";

export const DELETE_BUSINESS_AREA_REQUEST = "DELETE_BUSINESS_AREA_REQUEST";
export const DELETE_BUSINESS_AREA_SUCCESS = "DELETE_BUSINESS_AREA_SUCCESS";
export const DELETE_BUSINESS_AREA_FAILURE = "DELETE_BUSINESS_AREA_FAILURE";

export const UPDATE_BUSINESS_AREA_REQUEST = "UPDATE_BUSINESS_AREA_REQUEST";
export const UPDATE_BUSINESS_AREA_SUCCESS = "UPDATE_BUSINESS_AREA_SUCCESS";
export const UPDATE_BUSINESS_AREA_FAILURE = "UPDATE_BUSINESS_AREA_FAILURE";

export const ACTIVATE_DEACTIVATE_BUSINESS_AREA_REQUEST = "ACTIVATE_DEACTIVATE_BUSINESS_AREA_REQUEST";
export const ACTIVATE_DEACTIVATE_BUSINESS_AREA_SUCCESS = "ACTIVATE_DEACTIVATE_BUSINESS_AREA_SUCCESS";
export const ACTIVATE_DEACTIVATE_BUSINESS_AREA_FAILURE = "ACTIVATE_DEACTIVATE_BUSINESS_AREA_FAILURE";

export const GET_USER_BUSINESS_AREAS_PERMISSION_REQUEST = "GET_USER_BUSINESS_AREAS_PERMISSION_REQUEST";
export const GET_USER_BUSINESS_AREAS_PERMISSION_SUCCESS = "GET_USER_BUSINESS_AREAS_PERMISSION_SUCCESS";
export const GET_USER_BUSINESS_AREAS_PERMISSION_FAILURE = "GET_USER_BUSINESS_AREAS_PERMISSION_FAILURE";

export const GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_REQUEST = "GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_REQUEST";
export const GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_SUCCESS = "GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_SUCCESS";
export const GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_FAILURE = "GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_FAILURE";

export const getBusinessAreasRequest = () => ({
    type: GET_BUSINESS_AREAS_REQUEST
})

export const getBusinessAreasSuccess = (businessAreas) => ({
    type: GET_BUSINESS_AREAS_SUCCESS,
    payload: businessAreas
})

export const getBusinessAreasFailure = (error) => ({
    type: GET_BUSINESS_AREAS_FAILURE,
    payload: error
})

export const createBusinessAreaRequest = (businessArea, users, appClients, systemConnections) => ({
    type: CREATE_BUSINESS_AREA_REQUEST,
    params: {
        businessArea,
        users,
        appClients,
        systemConnections
    }
})

export const createBusinessAreasSuccess = (businessArea) => ({
    type: CREATE_BUSINESS_AREA_SUCCESS,
    payload: businessArea
})

export const createBusinessAreasFailure = (error) => ({
    type: CREATE_BUSINESS_AREA_FAILURE,
    payload: error
})

export const getBusinessAreaByIdRequest = (businessAreaId) => ({
    type: GET_BUSINESS_AREA_BY_ID_REQUEST,
    params: {
        businessAreaId
    }
})

export const getBusinessAreaByIdSuccess = (businessArea) => ({
    type: GET_BUSINESS_AREA_BY_ID_SUCCESS,
    payload: businessArea
})

export const getBusinessAreaByIdFailure = (error) => ({
    type: GET_BUSINESS_AREA_BY_ID_FAILURE,
    payload: error
})

export const deleteBusinessAreaRequest = (businessAreaId) => ({
    type: DELETE_BUSINESS_AREA_REQUEST,
    params: {
        businessAreaId
    }
})

export const deleteBusinessAreaSuccess = () => ({
    type: DELETE_BUSINESS_AREA_SUCCESS
})

export const deleteBusinessAreaFailure = (error) => ({
    type: DELETE_BUSINESS_AREA_FAILURE,
    payload: error
})

export const updateBusinessAreaRequest = (updateData, businessAreaId) => ({
    type: UPDATE_BUSINESS_AREA_REQUEST,
    params: {
        updateData,
        businessAreaId
    }
})

export const updateBusinessAreaSuccess = () => ({
    type: UPDATE_BUSINESS_AREA_SUCCESS
})

export const updateBusinessAreaFailure = (error) => ({
    type: UPDATE_BUSINESS_AREA_FAILURE,
    payload: error
})

export const activateDeactivateBusinessAreaRequest = (businessAreaId, businessAreaVersion, businessAreaActive) => ({
    type: ACTIVATE_DEACTIVATE_BUSINESS_AREA_REQUEST,
    params: {
        businessAreaId,
        businessAreaActive,
        businessAreaVersion
    }
})

export const activateDeactivateBusinessAreaSuccess = (businessAreaId) => ({
    type: ACTIVATE_DEACTIVATE_BUSINESS_AREA_SUCCESS,
    payload: {
        businessAreaId
    }
})

export const activateDeactivateBusinessAreaFailure = (error, businessAreaId) => ({
    type: ACTIVATE_DEACTIVATE_BUSINESS_AREA_FAILURE,
    payload: {
        error,
        businessAreaId
    }
})

export const getUserBusinessAreasPermissionRequest = () => ({
    type: GET_USER_BUSINESS_AREAS_PERMISSION_REQUEST
})

export const getUserBusinessAreasPermissionSuccess = (permission) => ({
    type: GET_USER_BUSINESS_AREAS_PERMISSION_SUCCESS,
    payload: permission
})

export const getUserBusinessAreasPermissionFailure = (error) => ({
    type: GET_USER_BUSINESS_AREAS_PERMISSION_FAILURE,
    payload: error
})

export const getBusinessAreaSystemConnectionsRequest = (businessAreaId) => ({
    type: GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_REQUEST,
    params: {
        businessAreaId
    }
})

export const getBusinessAreaSystemConnectionsSuccess = (result) => ({
    type: GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_SUCCESS,
    payload: {
        result
    }
})

export const getBusinessAreaSystemConnectionsFailure = (error) => ({
    type: GET_BUSINESS_AREA_SYSTEM_CONNECTIONS_FAILURE,
    payload: {
        error
    }
})
