import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getDataSetHistory(api, tenantId, businessAreaId, schemaId, dataSetId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/history`, { tenantid: tenantId });
    return result.data;
}

function* getDataSetEvents(api, tenantId, dataSetId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/datasets/${dataSetId}/events`, { tenantid: tenantId });
    return result.data;
}

export function* getDataSetHistoryRequest(api, { businessAreaId, schemaId, dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        let dataSetHistoryResult = yield call(getDataSetHistory, api, tenant.tenantId, businessAreaId, schemaId, dataSetId);
        yield put(actions.getDataSetHistorySuccess(dataSetHistoryResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataset history");
        yield put(actions.getDataSetHistoryFailure(errorObject));
    }
}

export function* getDataSetEventsRequest(api, { dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        let dataSetEventsResult = yield call(getDataSetEvents, api, tenant.tenantId, dataSetId);
        yield put(actions.getDataSetEventsSuccess(dataSetEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "dataset events");
        yield put(actions.getDataSetEventsFailure(errorObject));
    }
}

export function* watchGetDataSetHistoryRequest(api, { params }) {
    yield call(getDataSetHistoryRequest, api, params);
}

export function* watchGetDataSetEventsRequest(api, { params }) {
    yield call(getDataSetEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_SET_HISTORY_REQUEST, watchGetDataSetHistoryRequest, api);
    yield takeLatest(actions.GET_DATA_SET_EVENTS_REQUEST, watchGetDataSetEventsRequest, api);
}