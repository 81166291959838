import React from 'react';
import moment from 'moment';
import _ from 'lodash';

const TIME_FORMAT = "HH:mm:ss";

function FormTimeDisplayField({ value, timeFormat, ...field }) {
    let timeValue = value && moment(value, TIME_FORMAT);
    if (timeValue) {
        if (timeValue.isValid() === false) {
            timeValue = value;
        }
        else {
            timeValue = timeValue.format(timeFormat);
        }
    }
    return <>{timeValue}</>;
}

export default FormTimeDisplayField