import React from 'react';
import { Form } from "antd";
import { DataTagSelect } from 'containers';

export default ({ selectedFieldRow, dataType, readOnly, getFormErrors }) => (<Form.Item
    name={["fields", selectedFieldRow.name, "classifiers"]}
    label="Classifiers"
    {...getFormErrors(`fields.${selectedFieldRow.name}.classifiers`)}>
    <DataTagSelect
        disabled={readOnly}
        mode="multiple"
        placeholder="Please select classifiers">
    </DataTagSelect>
</Form.Item>)