import {
    VERIFY_USER_EMAIL_SUCCESS,
    VERIFY_USER_EMAIL_FAILURE
} from "./actions";

const initialState = {
    userEmailVerificationResult: {
        success: true,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_USER_EMAIL_SUCCESS:
            return { ...state, userEmailVerificationResult: { success: true, ...action.payload } };
        case VERIFY_USER_EMAIL_FAILURE:
            return { ...state, userEmailVerificationResult: { success: false, ...action.payload } };
        default:
            return state;
    }
};
