import {
    GET_USER_BUSINESS_AREA_BY_NAME_SUCCESS,
    GET_USER_BUSINESS_AREA_BY_NAME_FAILURE,

    CREATE_SCHEMA_MODEL_SUCCESS,
    CREATE_SCHEMA_MODEL_FAILURE,

    GET_SCHEMA_MODELS_SUCCESS,
    GET_SCHEMA_MODELS_FAILURE,

    GET_SCHEMA_MODELS_NEXT_PAGE_SUCCESS,
    GET_SCHEMA_MODELS_NEXT_PAGE_FAILURE,

    GET_RECENT_SCHEMA_MODELS_SUCCESS,
    GET_RECENT_SCHEMA_MODELS_FAILURE,

    DELETE_SCHEMA_MODEL_SUCCESS,
    DELETE_SCHEMA_MODEL_FAILURE,

    GET_SCHEMA_MODEL_SUCCESS,
    GET_SCHEMA_MODEL_FAILURE,

    GET_SCHEMA_MODEL_BY_NAME_SUCCESS,
    GET_SCHEMA_MODEL_BY_NAME_FAILURE,

    UPDATE_SCHEMA_MODEL_SUCCESS,
    UPDATE_SCHEMA_MODEL_FAILURE,

    GET_USER_SCHEMA_MODELS_PERMISSION_SUCCESS,
    GET_USER_SCHEMA_MODELS_PERMISSION_FAILURE,

    GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_REQUEST,
    GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_SUCCESS,
    GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_FAILURE,

    UPLOAD_SCHEMA_MODEL_DEFINITION_SUCCESS,
    UPLOAD_SCHEMA_MODEL_DEFINITION_FAILURE
} from "./actions";

import {
    GET_USER_BUSINESS_AREAS_SUCCESS
} from '../home/actions';

import {
    DELETE_DATA_SET_SUCCESS
} from '../actions';

const initialState = {
    getUserBusinessAreaByNameResult: {
        success: true
    },
    createSchemaModelResult: {
        success: true
    },
    getSchemaModelsResult: {
        success: true
    },
    getSchemaModelsNextPageResult: {
        success: true
    },
    getRecentSchemaModelsResult: {
        success: true
    },
    deleteSchemaModelResult: {
        success: true
    },
    getSchemaModelResult: {
        success: true
    },
    updateSchemaModelResult: {
        success: true
    },
    userBusinessAreas: {
    },
    getUserSchemaModelsPermissionResult: {
        success: true
    },
    getBusinessAreaDataForBusinessAreaResult: {
        success: true,
        inProgress: false
    },
    schemaModelListResult: {
        Items: []
    },
    recentSchemaModelListResult: {
        Items: []
    },
    uploadSchemaModelDefinitionResult: {
        success: true
    },
    schemaModel: null,
    createdSchemaModel: null,
    permission: null,
    businessAreaDataList: [],
    schemaModelDefinitionFile: null
};

export default (state = initialState, action) => {
    let schemaModelListResult = {
        Items: []
    };
    switch (action.type) {
        case GET_USER_BUSINESS_AREA_BY_NAME_SUCCESS:
            return { ...state, getUserBusinessAreaByNameResult: { success: true }, userBusinessAreas: { [action.payload.name.toLowerCase()]: action.payload } };
        case GET_USER_BUSINESS_AREA_BY_NAME_FAILURE:
            return { ...state, getUserBusinessAreaByNameResult: { success: false, ...action.payload }, userBusinessAreas: { [action.payload.businessAreaName]: null } };
        case CREATE_SCHEMA_MODEL_SUCCESS:
            schemaModelListResult = { ...state.schemaModelListResult };
            schemaModelListResult.Items = [...schemaModelListResult.Items];
            schemaModelListResult.Items.splice(0, 0, action.payload.schemaModel);
            return { ...state, createSchemaModelResult: { success: true }, createdSchemaModel: action.payload.schemaModel, schemaModelListResult };
        case CREATE_SCHEMA_MODEL_FAILURE:
            return { ...state, createSchemaModelResult: { success: false, ...action.payload }, createdSchemaModel: null };
        case GET_SCHEMA_MODELS_SUCCESS:
            return { ...state, getSchemaModelsResult: { success: true }, schemaModelListResult: action.payload };
        case GET_SCHEMA_MODELS_FAILURE:
            return {
                ...state, getSchemaModelsResult: { success: false, ...action.payload }, schemaModelListResult: {
                    Items: []
                }
            };
        case GET_SCHEMA_MODELS_NEXT_PAGE_SUCCESS:
            return {
                ...state,
                getSchemaModelsNextPageResult: {
                    success: true
                },
                schemaModelListResult: {
                    ...action.payload,
                    Items: state.schemaModelListResult.Items.concat(action.payload.Items)
                }
            };
        case GET_SCHEMA_MODELS_NEXT_PAGE_FAILURE:
            return {
                ...state,
                getSchemaModelsNextPageResult: {
                    success: false,
                    ...action.payload
                }
            };
        case GET_RECENT_SCHEMA_MODELS_SUCCESS:
            return { ...state, getRecentSchemaModelsResult: { success: true }, recentSchemaModelListResult: action.payload };
        case GET_RECENT_SCHEMA_MODELS_FAILURE:
            return {
                ...state, getRecentSchemaModelsResult: { success: false, ...action.payload.error }, recentSchemaModelListResult: {
                    Items: []
                }
            };
        case GET_USER_BUSINESS_AREAS_SUCCESS:
            let businessAreasDict = action.payload.businessAreasDict;
            let recentSchemaModelListResult = state.recentSchemaModelListResult;
            if (recentSchemaModelListResult.Items.length > 0) {
                recentSchemaModelListResult = { ...state.recentSchemaModelListResult };
                recentSchemaModelListResult.Items = [...recentSchemaModelListResult.Items];
                for (let i = 0; i < recentSchemaModelListResult.Items.length; i++) {
                    let recentSchemaModel = recentSchemaModelListResult.Items[i];
                    if (businessAreasDict && businessAreasDict[recentSchemaModel.businessAreaId]) {
                        recentSchemaModelListResult.Items[i] = { ...recentSchemaModel, businessAreaName: businessAreasDict[recentSchemaModel.businessAreaId].name };
                    }
                }
            }
            return { ...state, recentSchemaModelListResult };
        case DELETE_SCHEMA_MODEL_SUCCESS:
            schemaModelListResult = { ...state.schemaModelListResult };
            schemaModelListResult.Items = [...schemaModelListResult.Items];
            let deletedSchemaModelIndex = schemaModelListResult.Items.findIndex(item => item.schemaId === action.payload.schemaId);
            if (deletedSchemaModelIndex > -1) {
                schemaModelListResult.Items.splice(deletedSchemaModelIndex, 1);
            }
            return { ...state, deleteSchemaModelResult: { success: true }, schemaModelListResult };
        case DELETE_SCHEMA_MODEL_FAILURE:
            return { ...state, deleteSchemaModelResult: { success: false, ...action.payload } };
        case GET_SCHEMA_MODEL_SUCCESS:
            return { ...state, getSchemaModelResult: { success: true }, schemaModel: action.payload };
        case GET_SCHEMA_MODEL_FAILURE:
            return { ...state, getSchemaModelResult: { success: false, ...action.payload }, schemaModel: null };
        case GET_SCHEMA_MODEL_BY_NAME_SUCCESS:
            return { ...state, getSchemaModelResult: { success: true }, schemaModel: action.payload.schemaModel };
        case GET_SCHEMA_MODEL_BY_NAME_FAILURE:
            return { ...state, getSchemaModelResult: { success: false, ...action.payload }, schemaModel: null };
        case UPDATE_SCHEMA_MODEL_SUCCESS:
            return { ...state, updateSchemaModelResult: { success: true } };
        case UPDATE_SCHEMA_MODEL_FAILURE:
            return { ...state, updateSchemaModelResult: { success: false, ...action.payload } };
        case GET_USER_SCHEMA_MODELS_PERMISSION_SUCCESS:
            return { ...state, getUserSchemaModelsPermissionResult: { success: true }, permission: action.payload };
        case GET_USER_SCHEMA_MODELS_PERMISSION_FAILURE:
            return { ...state, getUserSchemaModelsPermissionResult: { success: false, ...action.payload }, permission: null };
        case GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_REQUEST:
            return { ...state, getBusinessAreaDataForBusinessAreaResult: { ...state.getBusinessAreaDataForBusinessAreaResult, inProgress: true }, businessAreaDataList: [] };
        case GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_SUCCESS:
            return { ...state, getBusinessAreaDataForBusinessAreaResult: { success: true, inProgress: false }, businessAreaDataList: action.payload };
        case GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_FAILURE:
            return { ...state, getBusinessAreaDataForBusinessAreaResult: { success: false, inProgress: false, ...action.payload }, businessAreaDataList: [] };
        case DELETE_DATA_SET_SUCCESS:
            schemaModelListResult = { ...state.schemaModelListResult };
            schemaModelListResult.Items = [...schemaModelListResult.Items];
            let deletedDataSetSchemaIndex = schemaModelListResult.Items.findIndex(item => item.schemaId === action.payload.schemaId);
            if (deletedDataSetSchemaIndex > -1) {
                let dataSetCount = 0;
                if (schemaModelListResult.Items[deletedDataSetSchemaIndex].dataSetCount) {
                    dataSetCount = schemaModelListResult.Items[deletedDataSetSchemaIndex].dataSetCount - 1;
                }
                schemaModelListResult.Items[deletedDataSetSchemaIndex] = { ...schemaModelListResult.Items[deletedDataSetSchemaIndex], dataSetCount: dataSetCount }
            }
            return { ...state, schemaModelListResult };
        case UPLOAD_SCHEMA_MODEL_DEFINITION_SUCCESS:
            return { ...state, uploadSchemaModelDefinitionResult: { success: true }, schemaModelDefinitionFile: action.payload };
        case UPLOAD_SCHEMA_MODEL_DEFINITION_FAILURE:
            return { ...state, uploadSchemaModelDefinitionResult: { success: false, ...action.payload.error }, schemaModelDefinitionFile: null };
        default:
            return state;
    }
};
