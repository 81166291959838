import {
    GET_AWS_S3_CONNECTIONS_SUCCESS,
    GET_AWS_S3_CONNECTIONS_FAILURE,

    CREATE_AWS_S3_CONNECTION_SUCCESS,
    CREATE_AWS_S3_CONNECTION_FAILURE,

    GET_AWS_S3_CONNECTION_SUCCESS,
    GET_AWS_S3_CONNECTION_FAILURE,

    UPDATE_AWS_S3_CONNECTION_SUCCESS,
    UPDATE_AWS_S3_CONNECTION_FAILURE,

    DELETE_AWS_S3_CONNECTION_SUCCESS,
    DELETE_AWS_S3_CONNECTION_FAILURE,

    GET_AWS_S3_CONNECTION_BUCKETS_SUCCESS,
    GET_AWS_S3_CONNECTION_BUCKETS_FAILURE,

    GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_SUCCESS,
    GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_FAILURE,

    GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_SUCCESS,
    GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_FAILURE
} from "./actions";

const initialState = {
    getConnectionsResult: {
        success: true
    },
    connectionListResult: {
        Items: []
    },
    createConnectionResult: {
        success: true
    },
    createdConnection: null,
    getConnectionResult: {
        success: true
    },
    connection: null,
    updateConnectionResult: {
        success: true
    },
    deleteConnectionResult: {
        success: true
    },
    getAwsS3ConnectionBucketsResult: {
        success: true
    },
    awsS3ConnectionBuckets: [],
    getAwsS3ConnectionBucketContentListResult: {
        success: true
    },
    awsS3ConnectionBucketContentList: [],
    getAwsS3ConnectionBucketDownloadFileResult: {
        success: true
    },
    awsS3ConnectionBucketDownloadFile: null
};

export default (state = initialState, action) => {
    let connectionListResult = {
        Items: []
    };
    let connectionIndex = -1;
    switch (action.type) {
        case GET_AWS_S3_CONNECTIONS_SUCCESS:
            return { ...state, getConnectionsResult: { success: true }, connectionListResult: action.payload.result };
        case GET_AWS_S3_CONNECTIONS_FAILURE:
            return { ...state, getConnectionsResult: { success: false, ...action.payload.error }, connectionListResult: { Items: [] } };
        case CREATE_AWS_S3_CONNECTION_SUCCESS:
            return { ...state, createConnectionResult: { success: true }, createdConnection: action.payload.connection };
        case CREATE_AWS_S3_CONNECTION_FAILURE:
            return { ...state, createConnectionResult: { success: false, ...action.payload.error }, createdConnection: null };
        case GET_AWS_S3_CONNECTION_SUCCESS:
            return { ...state, getConnectionResult: { success: true }, connection: action.payload.connection };
        case GET_AWS_S3_CONNECTION_FAILURE:
            return { ...state, getConnectionResult: { success: false, ...action.payload.error }, connection: null };
        case UPDATE_AWS_S3_CONNECTION_SUCCESS:
            connectionListResult = state.connectionListResult;
            if (action.payload.connection) {
                connectionIndex = connectionListResult.Items.findIndex(item => item.connectionId === action.payload.connection.connectionId);
                if (connectionIndex > -1) {
                    connectionListResult = { ...connectionListResult };
                    connectionListResult.Items = [...connectionListResult.Items];
                    connectionListResult.Items[connectionIndex] = action.payload.connection;
                }
            }
            return { ...state, updateConnectionResult: { success: true }, connectionListResult };
        case UPDATE_AWS_S3_CONNECTION_FAILURE:
            return { ...state, updateConnectionResult: { success: false, ...action.payload.error } };
        case DELETE_AWS_S3_CONNECTION_SUCCESS:
            connectionListResult = { ...state.connectionListResult };
            connectionListResult.Items = state.connectionListResult.Items.filter(item => item.connectionId !== action.payload.connectionId);
            return { ...state, deleteConnectionResult: { success: true }, connectionListResult };
        case DELETE_AWS_S3_CONNECTION_FAILURE:
            return { ...state, deleteConnectionResult: { success: false, ...action.payload.error } };
        case GET_AWS_S3_CONNECTION_BUCKETS_SUCCESS:
            return { ...state, getAwsS3ConnectionBucketsResult: { success: true }, awsS3ConnectionBuckets: action.payload.result };
        case GET_AWS_S3_CONNECTION_BUCKETS_FAILURE:
            return { ...state, getAwsS3ConnectionBucketsResult: { success: false, ...action.payload.error }, awsS3ConnectionBuckets: [] };
        case GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_SUCCESS:
            return { ...state, getAwsS3ConnectionBucketContentListResult: { success: true }, awsS3ConnectionBucketContentList: action.payload.result };
        case GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_FAILURE:
            return { ...state, getAwsS3ConnectionBucketContentListResult: { success: false, ...action.payload.error }, awsS3ConnectionBucketContentList: [] };
        case GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_SUCCESS:
            return { ...state, getAwsS3ConnectionBucketDownloadFileResult: { success: true }, awsS3ConnectionBucketDownloadFile: action.payload.result };
        case GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_FAILURE:
            return { ...state, getAwsS3ConnectionBucketDownloadFileResult: { success: false, ...action.payload.error }, awsS3ConnectionBucketDownloadFile: null};
        default:
            return state;
    }
};
