import React, { Component } from "react";
import { VerticleMenuContent } from 'components';
import { withRouter } from "react-router";
import { ProhibitedArea, ComingSoon } from 'components';
import AppPaths from "constants/appPaths";
import { Empty } from 'antd';
import { Jobs, Events } from 'containers';

class Monitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    name: "jobs",
                    description: "Jobs"
                },
                {
                    name: "events",
                    description: "Events"
                }
            ]
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadcrumbNavigationItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: AppPaths.TENANT_MONITOR.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Monitor"
                }
            ];
            this.setState({
                breadcrumbNavigationItems
            });
            this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
        }
    }

    showSubBreadCrumbNavigationItems = (subBreadCrumbNavigationItems) => {
        let breadcrumbNavigationItems = [...this.state.breadcrumbNavigationItems, ...subBreadCrumbNavigationItems];
        this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
    }

    getMonitorItems = (activeItem) => {
        if (activeItem) {
            switch (activeItem.toLowerCase()) {
                case "jobs":
                    return <Jobs showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></Jobs>
                case "events":
                    return <Events showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></Events>
                default:
                    return <div>
                        <ProhibitedArea></ProhibitedArea>
                    </div>;
            }
        }
        return <Empty description="No monitoring option selected"></Empty>;
    }

    onMenuItemClick = (itemName) => {
        this.props.history.push(AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase()).replace(":monitorItem", itemName.toLowerCase()));
    }

    render() {
        return <>
            {!this.props.activeItem || this.state.items.some(item => item.name === this.props.activeItem.toLowerCase()) ?
                <VerticleMenuContent
                    items={this.state.items}
                    activeItem={this.props.activeItem}
                    itemContainer={this.getMonitorItems(this.props.activeItem)}
                    onItemClick={this.onMenuItemClick}></VerticleMenuContent>
                : <ProhibitedArea />}
        </>
    }
}

export default withRouter(Monitor);