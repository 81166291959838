import React from "react";
import { connect } from "react-redux";
import actions from 'store/actions';
import { withRouter } from "react-router";
import AppPaths from 'constants/appPaths';
import { Layout, Table, Row, Col, Tabs, Tooltip, Statistic, Typography, Space } from "antd";
import { LockOutlined, UserOutlined, ShoppingOutlined, CreditCardOutlined, FileTextOutlined } from "@ant-design/icons";
import { FullHeightContainerLayout, AggregationsCount } from 'components';
import { showError, showSuccess } from 'common/ToastNotifications';
import moment from 'moment';

const { TabPane } = Tabs;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Column, ColumnGroup } = Table;

class DomainServiceConsole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runningJobs: [
                {
                    job: "6fea8f44a782433783a654c694dfe24a",
                    started: "01/01/2020 00:00:00",
                    runningFor: "1h50m"
                },
                {
                    job: "02e9f00c7c864582b3992ec79b3b523a",
                    started: "01/01/2020 00:00:00",
                    runningFor: "1h50m"
                },
                {
                    job: "8eab6228c50640f3ae9ea8eeb0bbbff6",
                    started: "01/01/2020 00:00:00",
                    runningFor: "1h50m"
                }
            ],
            failedJobs: [
                {
                    job: "6fea8f44a782433783a654c694dfe24a",
                    started: "01/01/2020 00:00:00",
                    ended: "01/01/2020 01:00:00"
                },
                {
                    job: "02e9f00c7c864582b3992ec79b3b523a",
                    started: "01/01/2020 00:00:00",
                    ended: "01/01/2020 01:00:00"
                },
                {
                    job: "8eab6228c50640f3ae9ea8eeb0bbbff6",
                    started: "01/01/2020 00:00:00",
                    ended: "01/01/2020 01:00:00"
                }
            ]
        }
    }

    componentDidMount() {
        this.props.getRecentLoggedInUsers();
        this.props.getAggregations();
        this.props.getRunningJobs();
        this.props.getFailedJobs();
        if (!this.props.aggregations) {
            this.setState({
                fetchingAggregates: true
            });
        }
        if (!this.props.recentLoggedInUsersResult || this.props.recentLoggedInUsersResult.Items.length === 0) {
            this.setState({
                fetchingRecentLoggedInUsers: true
            });
        }
        if (!this.props.runningJobsResult || this.props.runningJobsResult.Items.length === 0) {
            this.setState({
                fetchingRunningJobs: true
            });
        }
        if (!this.props.failedJobsResult || this.props.failedJobsResult.Items.length === 0) {
            this.setState({
                fetchingFailedJobs: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetAggregationsResultChanged(prevProps);
        this.onGetRecentLoggedInUsersResultChanged(prevProps);
        this.onGetRunningJobsResultChanged(prevProps);
        this.onGetFailedJobsResultChanged(prevProps);
    }

    onGetAggregationsResultChanged = (prevProps) => {
        if (this.props.getAggregationsResult && this.props.getAggregationsResult !== prevProps.getAggregationsResult) {
            if (!this.props.getAggregationsResult.success) {
                showError("Could not able to get counts.")
            }
            this.setState({
                fetchingAggregates: false
            })
        }
    }

    onGetRecentLoggedInUsersResultChanged = (prevProps) => {
        if (this.props.getRecentLoggedInUsersResult && this.props.getRecentLoggedInUsersResult !== prevProps.getRecentLoggedInUsersResult) {
            if (!this.props.getRecentLoggedInUsersResult.success) {
                showError("Could not able to get recent logged-in users.")

            }
            this.setState({
                fetchingRecentLoggedInUsers: false
            })
        }
    }

    onGetRunningJobsResultChanged = (prevProps) => {
        if (this.props.getRunningJobsResult && this.props.getRunningJobsResult !== prevProps.getRunningJobsResult) {
            if (!this.props.getRunningJobsResult.success) {
                showError("Could not able to get running jobs.")
            }
            this.setState({
                fetchingRunningJobs: false
            })
        }
    }

    onGetFailedJobsResultChanged = (prevProps) => {
        if (this.props.getFailedJobsResult && this.props.getFailedJobsResult !== prevProps.getFailedJobsResult) {
            if (!this.props.getFailedJobsResult.success) {
                showError("Could not able to get failed jobs.")
            }
            this.setState({
                fetchingFailedJobs: false
            })
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                        <Col span={24} style={{ display: "flex", height: "100%", flexDirection: "column", rowGap: "10px" }} >
                            <Row style={{ width: "100%", height: "50%" }} gutter={[40, 16]} wrap={false}>
                                <AggregationsCount loading={this.state.fetchingAggregates} counts={this.props.aggregations}></AggregationsCount>
                            </Row>
                            <Row style={{ width: "100%", height: "50%" }} gutter={[40, 16]} wrap={false}>
                                <Col span={8} style={{ height: "100%" }} >
                                    <FullHeightContainerLayout
                                        content={
                                            <div className="full-height-flex-container verticle-scroll" style={{ padding: "unset" }}>
                                                <div className="full-height-flex-container">
                                                    <Table
                                                        size="small"
                                                        dataSource={this.props.recentLoggedInUsersResult.Items}
                                                        loading={this.state.fetchingRecentLoggedInUsers}
                                                        pagination={false}
                                                        scroll={{ y: `100vh` }}
                                                        className="container-height-80 domain-service-console-table">
                                                        <ColumnGroup title="Current Users Logged On">
                                                            <Column
                                                                title="User"
                                                                dataIndex="email"
                                                                key="email"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, data, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>} />
                                                            <Column
                                                                title="Last Login"
                                                                dataIndex="lastLogin"
                                                                key="lastLogin"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, job, index) => {
                                                                    let displayValue = null;
                                                                    if (value) {
                                                                        displayValue = moment.utc(value).toDate().toLocaleString();
                                                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                                                    }
                                                                    return <></>;
                                                                }} />
                                                        </ColumnGroup>
                                                    </Table>
                                                </div>
                                            </div>
                                        }>
                                    </FullHeightContainerLayout>
                                </Col>
                                <Col span={8} style={{ height: "100%" }} >
                                    <FullHeightContainerLayout
                                        content={
                                            <div className="full-height-flex-container verticle-scroll" style={{ padding: "unset" }}>
                                                <div className="full-height-flex-container">
                                                    <Table
                                                        size="small"
                                                        loading={this.state.fetchingRunningJobs}
                                                        dataSource={this.props.runningJobsResult.Items}
                                                        pagination={false}
                                                        scroll={{ y: `100vh` }}
                                                        className="container-height-80 domain-service-console-table">
                                                        <ColumnGroup title="Running Jobs">
                                                            <Column
                                                                title="Job"
                                                                dataIndex="jobId"
                                                                key="jobId"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, data, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>} />
                                                            <Column
                                                                title="Started"
                                                                dataIndex="startedDate"
                                                                key="startedDate"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, job, index) => {
                                                                    if (value) {
                                                                        let displayValue = moment.utc(value).toDate().toLocaleString();
                                                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                                                    }
                                                                    return <></>;
                                                                }} />
                                                            <Column
                                                                title="Running for"
                                                                dataIndex="startedDate"
                                                                key="runningFor"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, job, index) => {
                                                                    if (job.startedDate) {
                                                                        let startDate = moment.utc(value);
                                                                        let currentDate = moment.utc();
                                                                        let duration = moment.duration(currentDate.diff(startDate));
                                                                        let displayValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
                                                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                                                    }
                                                                    return <></>;
                                                                }}
                                                            />
                                                        </ColumnGroup>
                                                    </Table>
                                                </div>
                                            </div>}>
                                    </FullHeightContainerLayout>
                                </Col>
                                <Col span={8} style={{ height: "100%" }} >
                                    <FullHeightContainerLayout
                                        content={
                                            <div className="full-height-flex-container verticle-scroll" style={{ padding: "unset" }}>
                                                <div className="full-height-flex-container">
                                                    <Table
                                                        size="small"
                                                        loading={this.state.fetchingFailedJobs}
                                                        dataSource={this.props.failedJobsResult.Items}
                                                        pagination={false}
                                                        scroll={{ y: `100vh` }}
                                                        className="container-height-80 domain-service-console-table">
                                                        <ColumnGroup title="Failed Jobs">
                                                            <Column
                                                                title="Job"
                                                                dataIndex="jobId"
                                                                key="jobId"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, data, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>} />
                                                            <Column
                                                                title="Started"
                                                                dataIndex="startedDate"
                                                                key="startedDate"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, job, index) => {
                                                                    if (value) {
                                                                        let displayValue = moment.utc(value).toDate().toLocaleString();
                                                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                                                    }
                                                                    return <></>;
                                                                }} />
                                                            <Column
                                                                title="Ended"
                                                                dataIndex="completedDate"
                                                                key="ended"
                                                                ellipsis={{
                                                                    showTitle: false,
                                                                }}
                                                                render={(value, job, index) => {
                                                                    if (value) {
                                                                        let displayValue = moment.utc(value).toDate().toLocaleString();
                                                                        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                                                    }
                                                                    return <></>;
                                                                }} />
                                                        </ColumnGroup>
                                                    </Table>
                                                </div>
                                            </div>}>
                                    </FullHeightContainerLayout>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }>
            </FullHeightContainerLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getAggregationsResult: state.domainServiceConsole.getAggregationsResult,
        aggregations: state.domainServiceConsole.aggregations,
        getRecentLoggedInUsersResult: state.domainServiceConsole.getRecentLoggedInUsersResult,
        recentLoggedInUsersResult: state.domainServiceConsole.recentLoggedInUsersResult,
        getRunningJobsResult: state.domainServiceConsole.getRunningJobsResult,
        runningJobsResult: state.domainServiceConsole.runningJobsResult,
        getFailedJobsResult: state.domainServiceConsole.getFailedJobsResult,
        failedJobsResult: state.domainServiceConsole.failedJobsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAggregations: () => dispatch(actions.getAggregationsRequest()),
        getRecentLoggedInUsers: () => dispatch(actions.getRecentLoggedInUsersRequest()),
        getRunningJobs: () => dispatch(actions.getRunningJobsRequest()),
        getFailedJobs: () => dispatch(actions.getFailedJobsRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DomainServiceConsole));