export const SIGN_UP_NEW_USER_REQUEST = "SIGN_UP_NEW_USER_REQUEST";
export const SIGN_UP_NEW_USER_SUCCESS = "SIGN_UP_NEW_USER_SUCCESS";
export const SIGN_UP_NEW_USER_FAILURE = "SIGN_UP_NEW_USER_FAILURE";

export const signUpNewUserRequest = (user) => ({
    type: SIGN_UP_NEW_USER_REQUEST,
    params: {
        user
    }
})

export const signUpNewUserSuccess = (status) => ({
    type: SIGN_UP_NEW_USER_SUCCESS,
    payload: status
})

export const signUpNewUserFailure = (error) => ({
    type: SIGN_UP_NEW_USER_FAILURE,
    payload: error
})