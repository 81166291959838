
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventDataTag(api, eventId, tagId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/datatags/${tagId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventDataTagRequest(api, { eventId, tagId }) {
    try {
        const dataTag = yield call(getEventDataTag, api, eventId, tagId);
        yield put(actions.getEventDataTagSuccess(dataTag));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event datatag details");
        yield put(actions.getEventDataTagFailure(errorObject));
    }
}

export function* watchGetEventDataTagRequest(api, { params }) {
    yield call(getEventDataTagRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_DATA_TAG_REQUEST, watchGetEventDataTagRequest, api);
}