import React from 'react';
import moment from 'moment';
import _ from 'lodash';

const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss";

function FormDateDisplayField({ value, dateFormat, dateSeparator, ...field }) {
    const format = `${_.replace(dateFormat, /\$/g, dateSeparator)}`;
    let dateValue = value && moment(value, ISO_FORMAT);
    if (dateValue) {
        if (dateValue.isValid() === false) {
            dateValue = value;
        }
        else {
            dateValue = dateValue.format(format);
        }
    }
    return <>{dateValue}</>;
}

export default FormDateDisplayField