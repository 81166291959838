
import { put, call, takeEvery, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* getUserBusinessAreas(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/users/${userId}/businessareas`, { tenantid: tenantId });
    return result.data;
}

function* getUserActiveBusinessArea(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/users/${userId}/businessareas/active`, { tenantid: tenantId });
    return result.data;
}

function* saveUserActiveBusinessArea(api, tenantId, userId, activeBusinessAreaRequest) {
    yield call([api, api.put], `${Endpoints.tenants}/api/v1/users/${userId}/businessareas/active`, activeBusinessAreaRequest, { tenantid: tenantId });
}

export function* getUserBusinessAreasRequest(api) {
    try {
        let businessAreas = [];
        let businessAreasDict = {};
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        const response = yield call(getUserBusinessAreas, api, tenant.tenantId, user.userId);
        if (response.Items) {
            businessAreas = response.Items;
            if (businessAreas && businessAreas.length > 0) {
                for (let userBusinessArea of businessAreas) {
                    businessAreasDict[userBusinessArea.businessAreaId] = userBusinessArea;
                }
            }
        }
        yield put(actions.getUserBusinessAreasSuccess(businessAreas, businessAreasDict));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user business areas");
        yield put(actions.getUserBusinessAreasFailure(errorObject));
    }
}

export function* getUserActiveBusinessAreaRequest(api) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        const result = yield call(getUserActiveBusinessArea, api, tenant.tenantId, user.userId);
        let businessAreaId = null;
        if (result) {
            businessAreaId = result.businessAreaId;
        }
        yield put(actions.getUserActiveBusinessAreaSuccess(businessAreaId));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user active business area");
        yield put(actions.getUserActiveBusinessAreaFailure(errorObject));
    }
}

export function* saveUserActiveBusinessAreaRequest(api, { businessAreaId }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        yield call(saveUserActiveBusinessArea, api, tenant.tenantId, user.userId, { businessAreaId });
        yield put(actions.saveUserActiveBusinessAreaSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Save", "user active business area");
        yield put(actions.saveUserActiveBusinessAreaFailure(errorObject));
    }
}

export function* watchGetUserBusinessAreasRequest(api) {
    yield call(getUserBusinessAreasRequest, api);
}

export function* watchGetUserActiveBusinessAreaRequest(api) {
    yield call(getUserActiveBusinessAreaRequest, api);
}

export function* watchSaveUserActiveBusinessAreaRequest(api, { params }) {
    yield call(saveUserActiveBusinessAreaRequest, api, params);
}

export default function* ({ api }) {
    yield takeEvery(actions.GET_USER_BUSINESS_AREAS_REQUEST, watchGetUserBusinessAreasRequest, api);
    yield takeEvery(actions.GET_USER_ACTIVE_BUSINESS_AREA_REQUEST, watchGetUserActiveBusinessAreaRequest, api);
    yield takeEvery(actions.SAVE_USER_ACTIVE_BUSINESS_AREA_REQUEST, watchSaveUserActiveBusinessAreaRequest, api);
}
