export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const RESET_USER_PASSWORD_REQUEST = "RESET_USER_PASSWORD_REQUEST";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_FAILURE = "RESET_USER_PASSWORD_FAILURE";

export const GET_USER_USERS_PERMISSION_REQUEST = "GET_USER_USERS_PERMISSION_REQUEST";
export const GET_USER_USERS_PERMISSION_SUCCESS = "GET_USER_USERS_PERMISSION_SUCCESS";
export const GET_USER_USERS_PERMISSION_FAILURE = "GET_USER_USERS_PERMISSION_FAILURE";

export const getUsersRequest = () => ({
    type: GET_USERS_REQUEST
})

export const getUsersSuccess = (users) => ({
    type: GET_USERS_SUCCESS,
    payload: users
})

export const getUsersFailure = (error) => ({
    type: GET_USERS_FAILURE,
    payload: error
})

export const createUserRequest = (user) => ({
    type: CREATE_USER_REQUEST,
    params: {
        user
    }
})

export const createUserSuccess = (user) => ({
    type: CREATE_USER_SUCCESS,
    payload: user
})

export const createUserFailure = (error) => ({
    type: CREATE_USER_FAILURE,
    payload: error
})

export const getUserRequest = (userId) => ({
    type: GET_USER_REQUEST,
    params: {
        userId
    }
})

export const getUserSuccess = (user) => ({
    type: GET_USER_SUCCESS,
    payload: user
})

export const getUserFailure = (error) => ({
    type: GET_USER_FAILURE,
    payload: error
})

export const updateUserRequest = (userId, updatedUser) => ({
    type: UPDATE_USER_REQUEST,
    params: {
        userId,
        updatedUser
    }
})

export const updateUserSuccess = () => ({
    type: UPDATE_USER_SUCCESS
})

export const updateUserFailure = (error) => ({
    type: UPDATE_USER_FAILURE,
    payload: error
})

export const resetUserPasswordRequest = (userId) => ({
    type: RESET_USER_PASSWORD_REQUEST,
    params: {
        userId
    }
})

export const resetUserPasswordSuccess = () => ({
    type: RESET_USER_PASSWORD_SUCCESS
})

export const resetUserPasswordFailure = (error) => ({
    type: RESET_USER_PASSWORD_FAILURE,
    payload: error
})

export const getUserUsersPermissionRequest = () => ({
    type: GET_USER_USERS_PERMISSION_REQUEST
})

export const getUserUsersPermissionSuccess = (permission) => ({
    type: GET_USER_USERS_PERMISSION_SUCCESS,
    payload: permission
})

export const getUserUsersPermissionFailure = (error) => ({
    type: GET_USER_USERS_PERMISSION_FAILURE,
    payload: error
})