export const GET_EVENT_DOMAIN_DATA_LIST_REQUEST = "GET_EVENT_DOMAIN_DATA_LIST_REQUEST";
export const GET_EVENT_DOMAIN_DATA_LIST_SUCCESS = "GET_EVENT_DOMAIN_DATA_LIST_SUCCESS";
export const GET_EVENT_DOMAIN_DATA_LIST_FAILURE = "GET_EVENT_DOMAIN_DATA_LIST_FAILURE";

export const getEventDomainDataListRequest = (eventId, itemOperation, pageKey) => ({
    type: GET_EVENT_DOMAIN_DATA_LIST_REQUEST,
    params: {
        eventId,
        itemOperation,
        pageKey
    }
})

export const getEventDomainDataListSuccess = (domainDataResult) => ({
    type: GET_EVENT_DOMAIN_DATA_LIST_SUCCESS,
    payload: domainDataResult
})

export const getEventDomainDataListFailure = (error) => ({
    type: GET_EVENT_DOMAIN_DATA_LIST_FAILURE,
    payload: error
})