export const GET_SYSTEM_CONNECTIONS_REQUEST = "GET_SYSTEM_CONNECTIONS_REQUEST";
export const GET_SYSTEM_CONNECTIONS_SUCCESS = "GET_SYSTEM_CONNECTIONS_SUCCESS";
export const GET_SYSTEM_CONNECTIONS_FAILURE = "GET_SYSTEM_CONNECTIONS_FAILURE";

export const GET_SYSTEM_CONNECTION_PERMISSIONS_REQUEST = "GET_SYSTEM_CONNECTION_PERMISSIONS_REQUEST";
export const GET_SYSTEM_CONNECTION_PERMISSIONS_SUCCESS = "GET_SYSTEM_CONNECTION_PERMISSIONS_SUCCESS";
export const GET_SYSTEM_CONNECTION_PERMISSIONS_FAILURE = "GET_SYSTEM_CONNECTION_PERMISSIONS_FAILURE";

export const getSystemConnectionsRequest = () => ({
    type: GET_SYSTEM_CONNECTIONS_REQUEST
})

export const getSystemConnectionsSuccess = (result) => ({
    type: GET_SYSTEM_CONNECTIONS_SUCCESS,
    payload: {
        result
    }
})

export const getSystemConnectionsFailure = (error) => ({
    type: GET_SYSTEM_CONNECTIONS_FAILURE,
    payload: {
        error
    }
})

export const getSystemConnectionPermissionRequest = () => ({
    type: GET_SYSTEM_CONNECTION_PERMISSIONS_REQUEST
})

export const getSystemConnectionPermissionSuccess = (permission) => ({
    type: GET_SYSTEM_CONNECTION_PERMISSIONS_SUCCESS,
    payload: {
        permission
    }
})

export const getSystemConnectionPermissionFailure = (error) => ({
    type: GET_SYSTEM_CONNECTION_PERMISSIONS_FAILURE,
    payload: {
        error
    }
})