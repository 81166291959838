import {
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,

    GET_PERMISSIONS_MASTER_DATA_SUCCESS,
    GET_PERMISSIONS_MASTER_DATA_FAILURE,

    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILURE,

    GET_ROLE_BY_ID_SUCCESS,
    GET_ROLE_BY_ID_FAILURE,

    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,

    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,

    ACTIVATE_DEACTIVATE_ROLE_SUCCESS,
    ACTIVATE_DEACTIVATE_ROLE_FAILURE,

    GET_USER_ROLES_PERMISSION_SUCCESS,
    GET_USER_ROLES_PERMISSION_FAILURE
} from "./actions";

const initialState = {
    getRolesResult: {
        success: true
    },
    roleList: [],
    getPermissionMasterDataResult: {
        success: true
    },
    permissionMasterData: [],
    createRoleResult: {
        success: true
    },
    getRoleByIdResult: {
        success: true
    },
    role: null,
    updateRoleResult: {
        success: true
    },
    deleteRoleResult: {
        success: true
    },
    activateDeactivateRoleResult: {
        success: true
    },
    getUserRolesPermissionResult: {
        success: true
    },
    permission: null
};

export default (state = initialState, action) => {
    let roleList = [];
    let roleIndex = -1;
    switch (action.type) {
        case GET_ROLES_SUCCESS:
            roleList = action.payload;
            return { ...state, getRolesResult: { success: true }, roleList };
        case GET_ROLES_FAILURE:
            return { ...state, getRolesResult: { success: false, ...action.payload }, roleList: [] };
        case GET_PERMISSIONS_MASTER_DATA_SUCCESS:
            return { ...state, getPermissionMasterDataResult: { success: true }, permissionMasterData: action.payload };
        case GET_PERMISSIONS_MASTER_DATA_FAILURE:
            return { ...state, getPermissionMasterDataResult: { success: false, ...action.payload }, permissionMasterData: [] };
        case CREATE_ROLE_SUCCESS:
            return { ...state, createRoleResult: { success: true } };
        case CREATE_ROLE_FAILURE:
            return { ...state, createRoleResult: { success: false, ...action.payload } };
        case GET_ROLE_BY_ID_SUCCESS:
            return { ...state, getRoleByIdResult: { success: true }, role: action.payload };
        case GET_ROLE_BY_ID_FAILURE:
            return { ...state, getRoleByIdResult: { success: false, ...action.payload }, role: null };
        case UPDATE_ROLE_SUCCESS:
            return { ...state, updateRoleResult: { success: true } };
        case UPDATE_ROLE_FAILURE:
            return { ...state, updateRoleResult: { success: false, ...action.payload } };
        case DELETE_ROLE_SUCCESS:
            return { ...state, deleteRoleResult: { success: true } };
        case DELETE_ROLE_FAILURE:
            return { ...state, deleteRoleResult: { success: false, ...action.payload } };
        case ACTIVATE_DEACTIVATE_ROLE_SUCCESS:
            return { ...state, activateDeactivateRoleResult: { success: true } };
        case ACTIVATE_DEACTIVATE_ROLE_FAILURE:
            return { ...state, activateDeactivateRoleResult: { success: false, ...action.payload.error } };
        case GET_USER_ROLES_PERMISSION_SUCCESS:
            return { ...state, getUserRolesPermissionResult: { success: true }, permission: action.payload };
        case GET_USER_ROLES_PERMISSION_FAILURE:
            return { ...state, getUserRolesPermissionResult: { success: false, ...action.payload }, permission: null };
        default:
            return state;
    }
};
