
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

function* getDuplicateDataObjectsResultSetSummary(api, clusterSummary) {
    let result = yield call([api, api.post], `${Endpoints.duplicates}/api/v1/dataobjects/duplicates/set/summary`, { clusterSummary });
    return result.data;
}

export function* getDuplicateDataObjectsResultSetSummaryRequest(api, { jobId, clusterSummary }) {
    try {
        const result = yield call(getDuplicateDataObjectsResultSetSummary, api, clusterSummary);
        yield put(actions.getDuplicateDataObjectsResultSetSummarySuccess({ jobId, result }));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Duplicate Data Objects Result Set Summary");
        yield put(actions.getDuplicateDataObjectsResultSetSummaryFailure({ jobId, error: errorObject }));
    }
}

export function* watchGetDuplicateDataObjectsResultSetSummaryRequest(api, { params }) {
    yield call(getDuplicateDataObjectsResultSetSummaryRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_REQUEST, watchGetDuplicateDataObjectsResultSetSummaryRequest, api);
}