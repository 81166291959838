export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAILURE = "UPDATE_USER_PASSWORD_FAILURE";

export const updateUserPasswordRequest = (username, code, newPassword) => ({
    type: UPDATE_USER_PASSWORD_REQUEST,
    params: {
        username,
        code,
        newPassword
    }
})

export const updateUserPasswordSuccess = (result) => ({
    type: UPDATE_USER_PASSWORD_SUCCESS,
    payload: result
})

export const updateUserPasswordFailure = (error) => ({
    type: UPDATE_USER_PASSWORD_FAILURE,
    payload: error
})