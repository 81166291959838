export const GET_SCHEMA_MODEL_LINEAGE_REQUEST = "GET_SCHEMA_MODEL_LINEAGE_REQUEST";
export const GET_SCHEMA_MODEL_LINEAGE_SUCCESS = "GET_SCHEMA_MODEL_LINEAGE_SUCCESS";
export const GET_SCHEMA_MODEL_LINEAGE_FAILURE = "GET_SCHEMA_MODEL_LINEAGE_FAILURE";

export const GET_LINEAGE_REQUEST = "GET_LINEAGE_REQUEST";
export const GET_LINEAGE_SUCCESS = "GET_LINEAGE_SUCCESS";
export const GET_LINEAGE_FAILURE = "GET_LINEAGE_FAILURE";

export const getSchemaModelLineageRequest = (schemaId) => ({
    type: GET_SCHEMA_MODEL_LINEAGE_REQUEST,
    params: {
        schemaId
    }
})

export const getSchemaModelLineageSuccess = (result) => ({
    type: GET_SCHEMA_MODEL_LINEAGE_SUCCESS,
    payload: {
        result
    }
})

export const getSchemaModelLineageFailure = (error) => ({
    type: GET_SCHEMA_MODEL_LINEAGE_FAILURE,
    payload: {
        error
    }
})


export const getLineageRequest = () => ({
    type: GET_LINEAGE_REQUEST
})

export const getLineageSuccess = (result) => ({
    type: GET_LINEAGE_SUCCESS,
    payload: {
        result
    }
})

export const getLineageFailure = (error) => ({
    type: GET_LINEAGE_FAILURE,
    payload: {
        error
    }
})