export const GET_SYSTEM_CONNECTION_EVENTS_REQUEST = "GET_SYSTEM_CONNECTION_EVENTS_REQUEST";
export const GET_SYSTEM_CONNECTION_EVENTS_SUCCESS = "GET_SYSTEM_CONNECTION_EVENTS_SUCCESS";
export const GET_SYSTEM_CONNECTION_EVENTS_FAILURE = "GET_SYSTEM_CONNECTION_EVENTS_FAILURE";

export const getSystemConnectionEventsRequest = (connectionId) => ({
    type: GET_SYSTEM_CONNECTION_EVENTS_REQUEST,
    params: {
        connectionId
    }
})

export const getSystemConnectionEventsSuccess = (connectionEventResults) => ({
    type: GET_SYSTEM_CONNECTION_EVENTS_SUCCESS,
    payload: {
        connectionEventResults
    }
})

export const getSystemConnectionEventsFailure = (error) => ({
    type: GET_SYSTEM_CONNECTION_EVENTS_FAILURE,
    payload: {
        error
    }
})