export const GET_DATA_TAG_HISTORY_REQUEST = "GET_DATA_TAG_HISTORY_REQUEST";
export const GET_DATA_TAG_HISTORY_SUCCESS = "GET_DATA_TAG_HISTORY_SUCCESS";
export const GET_DATA_TAG_HISTORY_FAILURE = "GET_DATA_TAG_HISTORY_FAILURE";

export const GET_DATA_TAG_EVENTS_REQUEST = "GET_DATA_TAG_EVENTS_REQUEST";
export const GET_DATA_TAG_EVENTS_SUCCESS = "GET_DATA_TAG_EVENTS_SUCCESS";
export const GET_DATA_TAG_EVENTS_FAILURE = "GET_DATA_TAG_EVENTS_FAILURE";


export const getDataTagHistoryRequest = (tagId) => ({
    type: GET_DATA_TAG_HISTORY_REQUEST,
    params: {
        tagId
    }
})

export const getDataTagHistorySuccess = (dataTagHistoryResult) => ({
    type: GET_DATA_TAG_HISTORY_SUCCESS,
    payload: {
        dataTagHistoryResult
    }
})

export const getDataTagHistoryFailure = (error) => ({
    type: GET_DATA_TAG_HISTORY_FAILURE,
    payload: {
        error
    }
})

export const getDataTagEventsRequest = (tagId) => ({
    type: GET_DATA_TAG_EVENTS_REQUEST,
    params: {
        tagId
    }
})

export const getDataTagEventsSuccess = (dataTagEventsResult) => ({
    type: GET_DATA_TAG_EVENTS_SUCCESS,
    payload: {
        dataTagEventsResult
    }
})

export const getDataTagEventsFailure = (error) => ({
    type: GET_DATA_TAG_EVENTS_FAILURE,
    payload: {
        error
    }
})