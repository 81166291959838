import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tabs } from 'antd';
import actions from 'store/actions';
import {
    NotFoundError,
    LoadingOverlay,
    ProhibitedArea,
    BasicFormBuilder
} from "components";
import { showError, showSuccess, showWarning, showInfo } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";

class DataObjectShared extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingRecord: true,
            busy: false,
            busyMessage: "Please wait..."
        }
    }

    componentWillMount() {
        this.props.getSharedDataObjectByUUID(this.props.tenantId,
            this.props.userId,
            this.props.readOnly,
            this.props.expiresAt,
            this.props.signatureDate,
            this.props.signature,
            this.props.dataObjectId);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataObjectByUUIDResultChanged(prevProps);
        this.onUpdateDataObjectResultChanged(prevProps);
    }

    onGetDataObjectByUUIDResultChanged = (prevProps) => {
        if (this.props.getDataObjectByUUIDResult && this.props.getDataObjectByUUIDResult !== prevProps.getDataObjectByUUIDResult) {
            if (!this.props.getDataObjectByUUIDResult.success) {
                if (this.props.getDataObjectByUUIDResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset record.");
                }
            }
            this.setState({
                fetchingRecord: false
            });
        }
    }

    onUpdateDataObjectResultChanged = (prevProps) => {
        if (this.props.updateDataObjectResult && this.props.updateDataObjectResult !== prevProps.updateDataObjectResult) {
            if (!this.props.updateDataObjectResult.success) {
                if (this.props.updateDataObjectResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update dataset record. Permission Denied.");
                }
                else {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update dataset record.");
                }
            }
            else {
                this.setState({
                    busy: false,
                    fetchingRecord: true
                });
                this.props.getSharedDataObjectByUUID(this.props.tenantId,
                    this.props.userId,
                    this.props.readOnly,
                    this.props.expiresAt,
                    this.props.signatureDate,
                    this.props.signature,
                    this.props.dataObjectId);
            }
        }
    }

    updateDataObject = (dataObjectId, dataObject) => {
        this.setState({
            busy: true,
            busyMessage: "Updating dataset record..."
        });
        this.props.updateSharedDataObject(
            this.props.tenantId,
            this.props.userId,
            this.props.readOnly,
            this.props.expiresAt,
            this.props.signatureDate,
            this.props.signature,
            this.props.dataObjectByUUIDResult.dataObject.businessAreaId,
            this.props.dataObjectByUUIDResult.dataObject.schemaId,
            this.props.dataObjectByUUIDResult.dataObject.dataSetId,
            dataObjectId,
            dataObject);
    }

    goToHome = () => {
        this.props.history.push(AppPaths.LOGIN);
    }

    isBusy = () => {
        let isBusy = this.state.fetchingRecord || this.state.busy;
        return isBusy;
    }

    getError = () => {
        if (this.props.getDataObjectByUUIDResult.success && !this.props.dataObjectByUUIDResult) {
            return {
                title: "Dataset record not found.",
                description: "The dataset record you are looking for does not exist."
            }
        }
        else if (!this.props.getDataObjectByUUIDResult.success) {
            return {
                title: "System Error",
                description: "An error occurred while fetching the record."
            }
        }
        return null;
    }

    render() {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (this.isBusy()) {
            return <LoadingOverlay
                busy={this.isBusy()}
                spinner
                message={this.state.busyMessage || "Please wait..."}>
            </LoadingOverlay>;
        }
        let error = this.getError();
        return !error ? <BasicFormBuilder
            key={`${this.props.dataObjectId}_${this.props.dataObjectByUUIDResult.dataObject.version}`}
            showHeader={false}
            formDataObject={this.props.dataObjectByUUIDResult.dataObject}
            schemaFields={(this.props.dataObjectByUUIDResult.schemaModel && this.props.dataObjectByUUIDResult.schemaModel.fields) || []}
            onCreate={() => { }}
            onUpdate={this.updateDataObject}
            onCancel={this.goToHome}
            readOnly={(this.props.readOnly === 'false' || this.props.readOnly === false) ? false : true}>
        </BasicFormBuilder>
            :
            <NotFoundError
                title={error.title}
                description={error.description}>
            </NotFoundError>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataObjectByUUIDResult: state.dataObjects.getDataObjectByUUIDResult,
        dataObjectByUUIDResult: state.dataObjects.dataObjectByUUIDResult,
        updateDataObjectResult: state.dataObjects.updateDataObjectResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSharedDataObjectByUUID: (tenantId, userId, readOnly, expiresAt, signatureDate, signature, dataObjectId) => dispatch(actions.getSharedDataObjectByUUIDRequest(tenantId, userId, readOnly, expiresAt, signatureDate, signature, dataObjectId)),
        updateSharedDataObject: (tenantId, userId, readOnly, expiresAt, signatureDate, signature, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) => dispatch(actions.updateSharedDataObjectRequest(tenantId, userId, readOnly, expiresAt, signatureDate, signature, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectShared));