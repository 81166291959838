import React from "react";
import { Form, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea } from "components";

const { Column } = Table;

const BusinessAreaDataList = ({ businessAreaDataList, onCreateNewBusinessAreaData, onDeleteBusinessAreaData, onViewBusinessAreaData, onEditBusinessAreaData, onViewBusinessAreaDataHistory, permission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <>
            <Table size="small"
                rowKey="dataDomainId"
                dataSource={businessAreaDataList}
                pagination={false}
                scroll={{ y: 600 }}>
                <Column
                    width="6em"
                    render={(value, businessAreaData, index) => <>
                    <Tooltip title ='Edit Business Area Data'>
                        <Icon
                            disabled={!permission.canEdit}
                            color='blue'
                            name='pencil'
                            className="action-cursor"
                            onClick={() => onEditBusinessAreaData(businessAreaData.dataDomainId)} /> </Tooltip>

                            <Tooltip title ='View Business Area Data'>
                        <Icon
                            disabled={!permission.canView}
                            color='blue'
                            name='eye'
                            className="action-cursor"
                            onClick={() => onViewBusinessAreaData(businessAreaData.dataDomainId)} /> </Tooltip>
                            <Tooltip title='View Business Area Data History'>
                        <Icon
                            disabled={!permission.canView}
                            color='blue'
                            name='history'
                            className="action-cursor"
                            onClick={() => onViewBusinessAreaDataHistory(businessAreaData.dataDomainId)} /></Tooltip>
                    </>}
                />
                <Column
                    width="15%"
                    dataIndex={"businessAreaName"}
                    title={"Business Area"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, businessAreaData, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="10%"
                    dataIndex={"domainName"}
                    title={"Domain"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, businessAreaData, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="20%"
                    dataIndex={"domainDescription"}
                    title={"Domain Description"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, businessAreaData, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="15%"
                    dataIndex={"domainPurpose"}
                    title={"Domain Purpose"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, businessAreaData, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="15%"
                    dataIndex={"usedForTagging"}
                    title={"Used for tagging"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, businessAreaData, index) => {
                        return <Checkbox checked={value} onChange={e => ({})}></Checkbox>;
                    }}
                />
                <Column
                    width="10%"
                    title={"Records"}
                    render={(value, businessAreaData, index) => <>{businessAreaData.recordCount || 0}</>}
                />
                <Column
                    width="5%"
                    render={(value, businessAreaData, index) => {
                        return (
                            

                            <Tooltip title ='Delete Business Area Data'>
                            <Icon
                                disabled={!permission.canDelete}
                                color='blue'
                                name='trash alternate outline'
                                className="action-cursor"
                                onClick={() => onDeleteBusinessAreaData(businessAreaData)} /> </Tooltip>
                        );
                    }}
                />
            </Table>
            <Row className="table-footer-row">
                <Col span={24} className="footer-right-column">
                    <Button type="primary"
                        disabled={!permission.canAdd}
                        onClick={onCreateNewBusinessAreaData}>
                        <PlusOutlined /> Add Business Area Data
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default BusinessAreaDataList;