import { Typography } from "antd";
import { BasicFormBuilder, LoadingOverlay, ProhibitedArea } from "components";

export default function DataSetRecordForm({
    isLoading,
    loadingMessage = "Getting dataset record...",
    isProhibited,
    dataObject,
    schemaModel,
}: IDataSetRecordFormProps) {
    return isLoading ? (
        <LoadingOverlay busy={true} message={loadingMessage} />
    ) : isProhibited ? (
        <ProhibitedArea />
    ) : dataObject ? (
        <BasicFormBuilder
            key={dataObject.dataObjectId}
            formDataObject={dataObject}
            schemaFields={schemaModel ? schemaModel.fields : []}
            readOnly={true}
        ></BasicFormBuilder>
    ) : (
        <Typography.Text>No data</Typography.Text>
    );
}
