import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { SchemaModelHistoryList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class EventSchemaModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        if (!this.props.itemOperation) {
            this.setState({
                busy: true,
                busyMessage: "Getting schema...",
            });
            this.props.getEventSchemaModel(this.props.eventId, this.props.schemaId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventSchemaModelResultUpdate(prevProps);
    }

    onGetEventSchemaModelResultUpdate = (prevProps) => {
        if (this.props.getEventSchemaModelResult && this.props.getEventSchemaModelResult !== prevProps.getEventSchemaModelResult) {
            if (!this.props.getEventSchemaModelResult.success) {
                if (this.props.getEventSchemaModelResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema model at this moment.");
                }
            }
            else {
                this.setState({
                    eventListHeight: 36
                })
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Revision - </Text><Text type="secondary" italic>{`(Schema: ${this.props.schemaName})`}</Text></Title>}
                            content={
                                this.props.schemaModelRevision
                                    ?
                                    <SchemaModelHistoryList
                                        schemaModelRevisions={[this.props.schemaModelRevision]}>
                                    </SchemaModelHistoryList>
                                    :
                                    <Empty></Empty>
                            }>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventSchemaModelResult: state.eventSchemaModel.getEventSchemaModelResult,
        schemaModelRevision: state.eventSchemaModel.schemaModelRevision
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventSchemaModel: (eventId, schemaId) => dispatch(actions.getEventSchemaModelRequest(eventId, schemaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventSchemaModel));