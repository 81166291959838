import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Spin } from 'antd';
import { DataSetRecentList, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';

const { Title } = Typography;

class DataSetHomeOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingDataSets: false,
            fetchingPermission: false,
            busy: false,
            busyMessage: "Please wait...",
            refreshingDataSets: false
        }
    }

    componentWillMount() {
        this.props.getUserDataSetPermission();
        this.props.getRecentDataSet();
        if (!this.props.recentDataSetListResult ||
            !this.props.recentDataSetListResult.Items ||
            this.props.recentDataSetListResult.Items.length === 0) {
            this.setState({
                fetchingDataSets: true,
                fetchingPermission: true
            })
        }
        else {
            this.setState({
                refreshingDataSets: true
            });
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserDataSetPermissionResultChanged(prevProps);
        this.onGetRecentDataSetResultChanged(prevProps);
        this.onRecentDataSetListResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    onGetUserDataSetPermissionResultChanged = (prevProps) => {
        if (this.props.getUserDataSetPermissionResult && this.props.getUserDataSetPermissionResult !== prevProps.getUserDataSetPermissionResult) {
            if (!this.props.getUserDataSetPermissionResult.success) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    onGetRecentDataSetResultChanged = (prevProps) => {
        if (this.props.getRecentDataSetResult && this.props.getRecentDataSetResult !== prevProps.getRecentDataSetResult) {
            if (!this.props.getRecentDataSetResult.success) {
                if (this.props.getRecentDataSetResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get datasets at this moment.");
                }
            }
            this.setState({
                fetchingDataSets: false,
                refreshingDataSets: false
            });
        }
    }

    onRecentDataSetListResultChanged = (prevProps) => {
        if (this.props.recentDataSetListResult && this.props.recentDataSetListResult !== prevProps.recentDataSetListResult) {
            if (this.state.selectedDataSetId) {
                this.props.onDataSetSelected && this.props.onDataSetSelected(this.state.selectedDataSetId);
            }
        }
    }

    showViewDataSet = (dataSet) => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", dataSet.businessAreaName.toLowerCase())
            .replace(":schema", dataSet.schemaName)
            .replace(":dataSetId", dataSet.dataSetId)
            .replace(":dataSetAction", "view"));
    }

    showEditDataSet = (dataSet) => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", dataSet.businessAreaName.toLowerCase())
            .replace(":schema", dataSet.schemaName)
            .replace(":dataSetId", dataSet.dataSetId)
            .replace(":dataSetAction", "edit"));
    }

    onDataSetSelected = (selectedDataSetId) => {
        this.setState({
            selectedDataSetId
        });
        this.props.onDataSetSelected && this.props.onDataSetSelected(selectedDataSetId);
    }

    fetchingDataSets = () => {
        let isBusy = this.state.fetchingDataSets || this.state.fetchingPermission;
        return isBusy;
    }

    isBusy = () => {
        return this.state.isBusy || this.fetchingDataSets();
    }

    render() {
        return <FullHeightContainerLayout
            showHeader={true}
            header={<Title level={4}>Datasets{this.state.refreshingDataSets ? <> <Spin size="small" /></> : <></>}</Title>}
            content={
                <>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.fetchingDataSets() ?
                        (
                            this.state.permissionDenied
                                ?
                                <ProhibitedArea></ProhibitedArea>
                                :
                                <DataSetRecentList
                                    dataSetList={this.props.recentDataSetListResult.Items}
                                    onViewDataSet={this.showViewDataSet}
                                    onEditDataSet={this.showEditDataSet}
                                    onDataSetSelected={this.onDataSetSelected}
                                    permission={this.props.permission}>
                                </DataSetRecentList>
                        )
                        :
                        <></>
                    }
                </>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        recentDataSetListResult: state.dataSets.recentDataSetListResult,
        getRecentDataSetResult: state.dataSets.getRecentDataSetResult,
        getUserDataSetPermissionResult: state.dataSets.getUserDataSetPermissionResult,
        permission: state.dataSets.permission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRecentDataSet: () => dispatch(actions.getRecentDataSetRequest()),
        getUserDataSetPermission: () => dispatch(actions.getUserDataSetPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSetHomeOverview));