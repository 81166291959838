export const GET_AGGREGATIONS_REQUEST = "GET_AGGREGATIONS_REQUEST";
export const GET_AGGREGATIONS_SUCCESS = "GET_AGGREGATIONS_SUCCESS";
export const GET_AGGREGATIONS_FAILURE = "GET_AGGREGATIONS_FAILURE";

export const GET_RECENT_LOGGED_IN_USERS_REQUEST = "GET_RECENT_LOGGED_IN_USERS_REQUEST";
export const GET_RECENT_LOGGED_IN_USERS_SUCCESS = "GET_RECENT_LOGGED_IN_USERS_SUCCESS";
export const GET_RECENT_LOGGED_IN_USERS_FAILURE = "GET_RECENT_LOGGED_IN_USERS_FAILURE";

export const GET_RUNNING_JOBS_REQUEST = "GET_RUNNING_JOBS_REQUEST";
export const GET_RUNNING_JOBS_SUCCESS = "GET_RUNNING_JOBS_SUCCESS";
export const GET_RUNNING_JOBS_FAILURE = "GET_RUNNING_JOBS_FAILURE";

export const GET_FAILED_JOBS_REQUEST = "GET_FAILED_JOBS_REQUEST";
export const GET_FAILED_JOBS_SUCCESS = "GET_FAILED_JOBS_SUCCESS";
export const GET_FAILED_JOBS_FAILURE = "GET_FAILED_JOBS_FAILURE";

export const getAggregationsRequest = () => ({
    type: GET_AGGREGATIONS_REQUEST
})

export const getAggregationsSuccess = (result) => ({
    type: GET_AGGREGATIONS_SUCCESS,
    payload: {
        result
    }
})

export const getAggregationsFailure = (error) => ({
    type: GET_AGGREGATIONS_FAILURE,
    payload: {
        error
    }
})

export const getRecentLoggedInUsersRequest = () => ({
    type: GET_RECENT_LOGGED_IN_USERS_REQUEST
})

export const getRecentLoggedInUsersSuccess = (result) => ({
    type: GET_RECENT_LOGGED_IN_USERS_SUCCESS,
    payload: {
        result
    }
})

export const getRecentLoggedInUsersFailure = (error) => ({
    type: GET_RECENT_LOGGED_IN_USERS_FAILURE,
    payload: {
        error
    }
})

export const getRunningJobsRequest = () => ({
    type: GET_RUNNING_JOBS_REQUEST
})

export const getRunningJobsSuccess = (result) => ({
    type: GET_RUNNING_JOBS_SUCCESS,
    payload: {
        result
    }
})

export const getRunningJobsFailure = (error) => ({
    type: GET_RUNNING_JOBS_FAILURE,
    payload: {
        error
    }
})

export const getFailedJobsRequest = () => ({
    type: GET_FAILED_JOBS_REQUEST
})

export const getFailedJobsSuccess = (result) => ({
    type: GET_FAILED_JOBS_SUCCESS,
    payload: {
        result
    }
})

export const getFailedJobsFailure = (error) => ({
    type: GET_FAILED_JOBS_FAILURE,
    payload: {
        error
    }
})