
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { Storage } from "aws-amplify";
import * as actions from './actions';
import { Auth } from "aws-amplify";
import { Endpoints } from 'services/api';
import moment from 'moment';
import { keyGenerator } from 'common/Utility';
import { getArrayBufferFromFileObject, sanitizeFileName } from 'common/fileHandler';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
import * as homeSaga from 'store/home/sagas';
import mime from 'mime-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import parsePath from 'parse-filepath';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* getAllBusinessAreaData(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datadomains`, { tenantid: tenantId });
    return result.data;
}

function* createBusinessAreaData(api, tenantId, businessAreaData) {
    let result = yield call([api, api.post], `${Endpoints.datasets}/api/v1/datadomains`, businessAreaData, { tenantid: tenantId });
    return result.data;
}

function* createDataDomainUploadJob(api, tenantId, job) {
    let result = yield call([api, api.post], `${Endpoints.jobs}/api/v1/jobs`, job, { tenantid: tenantId });
    return result.data;
}

function* updateBusinessAreaData(api, tenantId, dataDomainId, businessAreaData) {
    let result = yield call([api, api.put], `${Endpoints.datasets}/api/v1/datadomains/${dataDomainId}`, businessAreaData, { tenantid: tenantId });
    return result.data;
}

function* getBusinessAreaDataById(api, tenantId, dataDomainId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datadomains/${dataDomainId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* deleteBusinessAreaData(api, tenantId, dataDomainId) {
    yield call([api, api.delete], `${Endpoints.datasets}/api/v1/datadomains/${dataDomainId}`, { tenantid: tenantId });
}

export function* getUserBusinessAreaDataPermission(api, tenantId, userId) {
    //TODo - remove the current user call and replace it with pretectum user
    let user = yield call([Auth, Auth.currentAuthenticatedUser]);
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/businessareadata`, { tenantid: tenantId });
    return result.data;
}

function* getBulkUploadDomainDataJob(api, tenantId, dataDomainId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/jobidentifiers/${dataDomainId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getDomainData(api, tenantId, dataDomainId, pageKey) {
    let params = {
    };
    if (pageKey) {
        params.pageKey = pageKey
    }
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datadomains/${dataDomainId}/domaindata`, { tenantid: tenantId }, {
        params
    });
    return result.data;
}

export function* getAllBusinessAreaDataRequest(api) {
    try {
        let tenant = yield select(getTenant);
        let dataDomainsResult = yield call(getAllBusinessAreaData, api, tenant.tenantId);
        yield put(actions.getAllBusinessAreaDataSuccess(dataDomainsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area data");
        yield put(actions.getAllBusinessAreaDataFailure(errorObject));
    }
}


export function* createBusinessAreaDataRequest(api, { businessAreaData }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let uploadFile = businessAreaData.uploadFile;
        let dataUploadOptions = businessAreaData.dataUploadOptions;

        delete businessAreaData.uploadFile;
        delete businessAreaData.dataFile;
        delete businessAreaData.dataUploadOptions;

        let newBusinessAreaData = yield call(createBusinessAreaData, api, tenant.tenantId, businessAreaData);
        if (uploadFile && newBusinessAreaData) {
            let headers = {
                level: "public",
                contentType: uploadFile.type
            };
            let fileName = `tenants/${tenant.tenantId}/users/${user.userId}/businessareadata/${uuidv4()}${parsePath(uploadFile.name).ext}`;
            let arrayBuffer = yield call(getArrayBufferFromFileObject, uploadFile);
            let result = yield call([Storage, Storage.put], fileName, arrayBuffer, headers);
            let domainDataUploadJob = {
                name: `Data upload ${businessAreaData.domainName}`,
                description: `Data upload ${businessAreaData.domainName}`,
                businessAreaId: businessAreaData.businessAreaId,
                recordType: "DOMAIN_DATA",
                dataDomainId: newBusinessAreaData.dataDomainId,
                jobInfo: {
                    jobIdentifier: newBusinessAreaData.dataDomainId,
                    runUniqueJob: true,
                    dataDomainId: newBusinessAreaData.dataDomainId,
                    tenantId: tenant.tenantId,
                    userId: user.userId,
                    fileName: uploadFile.name,
                    s3: process.env.REACT_APP_BUCKET_NAME,
                    key: `public/${result.key}`,
                    region: process.env.REACT_APP_BUCKET_REGION,
                    uploadOptions: dataUploadOptions
                },
                jobType: "DOMAIN_DATA_BULK_UPLOAD"
            }
            yield call(createBulkUploadDomainDataJobRequest, api, { domainDataUploadJob });
        }

        yield put(actions.createBusinessAreaDataSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "business area data");
        yield put(actions.createBusinessAreaDataFailure(errorObject));
    }
}

export function* getBusinessAreaDataByIdRequest(api, { dataDomainId }) {
    try {
        let tenant = yield select(getTenant);
        let businessAreaData = yield call(getBusinessAreaDataById, api, tenant.tenantId, dataDomainId);
        yield put(actions.getBusinessAreaDataByIdSuccess(businessAreaData));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area data");
        yield put(actions.getBusinessAreaDataByIdFailure(errorObject));
    }
}

export function* updateBusinessAreaDataRequest(api, { businessAreaData, updatedBusinessAreaData }) {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let uploadFile = updatedBusinessAreaData.uploadFile;
        let dataUploadOptions = updatedBusinessAreaData.dataUploadOptions;

        delete updatedBusinessAreaData.uploadFile;
        delete updatedBusinessAreaData.dataFile;
        delete updatedBusinessAreaData.dataUploadOptions;

        if (_.isEqual(businessAreaData, { ...businessAreaData, ...updatedBusinessAreaData }) === false) {
            updatedBusinessAreaData.version = businessAreaData.version;
            yield call(updateBusinessAreaData, api, tenant.tenantId, businessAreaData.dataDomainId, updatedBusinessAreaData);
        }

        if (uploadFile) {
            let headers = {
                level: "public",
                contentType: uploadFile.type
            };
            let fileName = `tenants/${tenant.tenantId}/users/${user.userId}/businessareadata/${uuidv4()}${parsePath(uploadFile.name).ext}`;
            let arrayBuffer = yield call(getArrayBufferFromFileObject, uploadFile);
            let result = yield call([Storage, Storage.put], fileName, arrayBuffer, headers);
            let domainDataUploadJob = {
                name: `Data upload ${businessAreaData.domainName}`,
                description: `Data upload ${businessAreaData.domainName}`,
                businessAreaId: businessAreaData.businessAreaId,
                recordType: "DOMAIN_DATA",
                dataDomainId: businessAreaData.dataDomainId,
                jobInfo: {
                    jobIdentifier: businessAreaData.dataDomainId,
                    runUniqueJob: true,
                    dataDomainId: businessAreaData.dataDomainId,
                    tenantId: tenant.tenantId,
                    userId: user.userId,
                    fileName: uploadFile.name,
                    s3: process.env.REACT_APP_BUCKET_NAME,
                    key: `public/${result.key}`,
                    region: process.env.REACT_APP_BUCKET_REGION,
                    uploadOptions: dataUploadOptions
                },
                jobType: "DOMAIN_DATA_BULK_UPLOAD"
            }
            yield call(createBulkUploadDomainDataJobRequest, api, { domainDataUploadJob });
        }

        yield put(actions.updateBusinessAreaDataSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "business area data");
        yield put(actions.updateBusinessAreaDataFailure(errorObject));
    }
}

export function* createBulkUploadDomainDataJobRequest(api, { domainDataUploadJob }) {
    try {
        let tenant = yield select(getTenant);
        yield call(createDataDomainUploadJob, api, tenant.tenantId, domainDataUploadJob);
        yield put(actions.createBulkUploadDomainDataJobSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "bulk upload domain data job");
        yield put(actions.createBulkUploadDomainDataJobFailure(errorObject));
    }
}

export function* deleteBusinessAreaDataRequest(api, { dataDomainId }) {
    try {
        let tenant = yield select(getTenant);
        yield call(deleteBusinessAreaData, api, tenant.tenantId, dataDomainId);
        yield put(actions.deleteBusinessAreaDataSuccess(dataDomainId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "business area data");
        yield put(actions.deleteBusinessAreaDataFailure(errorObject));
    }
}

export function* fetchBusinessAreaDomainDataRequest(api, { dataDomainId, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        let domainDataResult = yield call(getDomainData, api, tenant.tenantId, dataDomainId, pageKey);
        yield put(actions.fetchBusinessAreaDomainDataSuccess(dataDomainId, { domainDataResult }));

    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area domain data");
        yield put(actions.fetchBusinessAreaDomainDataFailure(dataDomainId, errorObject));
    }
}

export function* getUserBusinessAreaDataPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getUserBusinessAreaDataPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getUserBusinessAreaDataPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user business area data permissions");
        yield put(actions.getUserBusinessAreaDataPermissionFailure(errorObject));
    }
}

export function* watchGetAllBusinessAreaDataRequest(api, params) {
    yield call(getAllBusinessAreaDataRequest, api);
}

export function* watchCreateBusinessAreaDataRequest(api, { params }) {
    yield call(createBusinessAreaDataRequest, api, params);
}

export function* watchGetBusinessAreaDataByIdRequest(api, { params }) {
    yield call(getBusinessAreaDataByIdRequest, api, params);
}

export function* watchUpdateBusinessAreaDataRequest(api, { params }) {
    yield call(updateBusinessAreaDataRequest, api, params);
}

export function* watchDeleteBusinessAreaDataRequest(api, { params }) {
    yield call(deleteBusinessAreaDataRequest, api, params);
}

export function* watchFetchBusinessAreaDomainDataRequest(api, { params }) {
    yield call(fetchBusinessAreaDomainDataRequest, api, params);
}

export function* watchGetUserBusinessAreaDataPermissionRequest(api, { params }) {
    yield call(getUserBusinessAreaDataPermissionRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_ALL_BUSINESS_AREA_DATA_REQUEST, watchGetAllBusinessAreaDataRequest, api);
    yield takeLatest(actions.CREATE_BUSINESS_AREA_DATA_REQUEST, watchCreateBusinessAreaDataRequest, api);
    yield takeLatest(actions.GET_BUSINESS_AREA_DATA_BY_ID_REQUEST, watchGetBusinessAreaDataByIdRequest, api);
    yield takeLatest(actions.UPDATE_BUSINESS_AREA_DATA_REQUEST, watchUpdateBusinessAreaDataRequest, api);
    yield takeEvery(actions.DELETE_BUSINESS_AREA_DATA_REQUEST, watchDeleteBusinessAreaDataRequest, api);
    yield takeLatest(actions.FETCH_BUSINESS_AREA_DOMAIN_DATA_REQUEST, watchFetchBusinessAreaDomainDataRequest, api);
    yield takeEvery(actions.GET_USER_BUSINESS_AREA_DATA_PERMISSION_REQUEST, watchGetUserBusinessAreaDataPermissionRequest, api);
}