import {
    GET_SYSTEM_CONNECTION_EVENTS_SUCCESS,
    GET_SYSTEM_CONNECTION_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getConnectionEventsResult: {
        success: true
    },
    connectionEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SYSTEM_CONNECTION_EVENTS_SUCCESS:
            return { ...state, getConnectionEventsResult: { success: true }, connectionEventsResult: action.payload.connectionEventResults };
        case GET_SYSTEM_CONNECTION_EVENTS_FAILURE:
            return {
                ...state, getConnectionEventsResult: { success: false, ...action.payload.error }, connectionEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
