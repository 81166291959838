import React, { useState, useEffect } from "react";
import { Layout, Typography, Space, Button } from "antd";
import { SystemConnectionItems } from 'components';
import { SystemConnections as Connections } from 'containers';
import { ArrowLeftOutlined } from "@ant-design/icons";
import AppPaths from "constants/appPaths";
import { withRouter } from "react-router";

const { Title } = Typography;
const { Header, Footer, Sider, Content } = Layout;

const SystemConnections = ({ itemAction, itemId, subItem, showSubBreadCrumbNavigationItems, ...props }) => {
    const breadCrumbItems = [
        {
            route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", props.match.params.tenant).replace(":configurationItem", "systemconnections"),
            text: "System Connections"
        }
    ];

    const [currentBreadCrumbItems, setCurrentBreadCrumbItems] = useState(breadCrumbItems);

    useEffect(() => {
        showSubBreadCrumbNavigationItems(currentBreadCrumbItems);
    }, []);

    useEffect(() => {
        showSubBreadCrumbNavigationItems(currentBreadCrumbItems);
    }, [currentBreadCrumbItems]);

    const addSubBreadCrumbNavigationItems = (items) => {
        setCurrentBreadCrumbItems([...breadCrumbItems, ...items]);
    }

    let action = null;
    let connectionId = null;
    let connectionType = null;
    if (!subItem) {
        action = itemAction;
        connectionType = itemId;
    }
    else {
        action = itemAction;
        connectionId = itemId;
        connectionType = subItem;
    }

    return <Connections key={connectionId || connectionType} action={action} connectionId={connectionId} connectionType={connectionType} showSubBreadCrumbNavigationItems={addSubBreadCrumbNavigationItems}></Connections>
};

export default withRouter(SystemConnections);