export const CREATE_DATA_SET_REQUEST = "CREATE_DATA_SET_REQUEST";
export const CREATE_DATA_SET_SUCCESS = "CREATE_DATA_SET_SUCCESS";
export const CREATE_DATA_SET_FAILURE = "CREATE_DATA_SET_FAILURE";

export const GET_DATA_SET_BY_SCHEMA_ID_REQUEST = "GET_DATA_SET_BY_SCHEMA_ID_REQUEST";
export const GET_DATA_SET_BY_SCHEMA_ID_SUCCESS = "GET_DATA_SET_BY_SCHEMA_ID_SUCCESS";
export const GET_DATA_SET_BY_SCHEMA_ID_FAILURE = "GET_DATA_SET_BY_SCHEMA_ID_FAILURE";

export const GET_RECENT_DATA_SET_REQUEST = "GET_RECENT_DATA_SET_REQUEST";
export const GET_RECENT_DATA_SET_SUCCESS = "GET_RECENT_DATA_SET_SUCCESS";
export const GET_RECENT_DATA_SET_FAILURE = "GET_RECENT_DATA_SET_FAILURE";

export const GET_DATA_SET_BY_ID_REQUEST = "GET_DATA_SET_BY_ID_REQUEST";
export const GET_DATA_SET_BY_ID_SUCCESS = "GET_DATA_SET_BY_ID_SUCCESS";
export const GET_DATA_SET_BY_ID_FAILURE = "GET_DATA_SET_BY_ID_FAILURE";

export const GET_DATA_SET_BY_NAME_REQUEST = "GET_DATA_SET_BY_NAME_REQUEST";
export const GET_DATA_SET_BY_NAME_SUCCESS = "GET_DATA_SET_BY_NAME_SUCCESS";
export const GET_DATA_SET_BY_NAME_FAILURE = "GET_DATA_SET_BY_NAME_FAILURE";

export const GET_DATA_SET_FIELD_INSIGHTS_REQUEST = "GET_DATA_SET_FIELD_INSIGHTS_REQUEST";
export const GET_DATA_SET_FIELD_INSIGHTS_SUCCESS = "GET_DATA_SET_FIELD_INSIGHTS_SUCCESS";
export const GET_DATA_SET_FIELD_INSIGHTS_FAILURE = "GET_DATA_SET_FIELD_INSIGHTS_FAILURE";

export const DELETE_DATA_SET_REQUEST = "DELETE_DATA_SET_REQUEST";
export const DELETE_DATA_SET_SUCCESS = "DELETE_DATA_SET_SUCCESS";
export const DELETE_DATA_SET_FAILURE = "DELETE_DATA_SET_FAILURE";

export const UPDATE_DATA_SET_REQUEST = "UPDATE_DATA_SET_REQUEST";
export const UPDATE_DATA_SET_SUCCESS = "UPDATE_DATA_SET_SUCCESS";
export const UPDATE_DATA_SET_FAILURE = "UPDATE_DATA_SET_FAILURE";

export const GET_USER_DATA_SET_PERMISSION_REQUEST = "GET_USER_DATA_SET_PERMISSION_REQUEST";
export const GET_USER_DATA_SET_PERMISSION_SUCCESS = "GET_USER_DATA_SET_PERMISSION_SUCCESS";
export const GET_USER_DATA_SET_PERMISSION_FAILURE = "GET_USER_DATA_SET_PERMISSION_FAILURE";

export const GET_DATA_SET_JOBS_REQUEST = "GET_DATA_SET_JOBS_REQUEST";
export const GET_DATA_SET_JOBS_SUCCESS = "GET_DATA_SET_JOBS_SUCCESS";
export const GET_DATA_SET_JOBS_FAILURE = "GET_DATA_SET_JOBS_FAILURE";

export const DATA_SET_JOB_EXECUTION_PROGRESS = "DATA_SET_JOB_EXECUTION_PROGRESS";

export const DATA_SET_UPDATED_EVENT = "DATA_SET_UPDATED_EVENT";

export const UNDO_JOB_REQUEST = "UNDO_JOB_REQUEST";
export const UNDO_JOB_SUCCESS = "UNDO_JOB_SUCCESS";
export const UNDO_JOB_FAILURE = "UNDO_JOB_FAILURE";

export const INDEX_DATA_SET_REQUEST = "INDEX_DATA_SET_REQUEST";
export const INDEX_DATA_SET_SUCCESS = "INDEX_DATA_SET_SUCCESS";
export const INDEX_DATA_SET_FAILURE = "INDEX_DATA_SET_FAILURE";

export const EXPORT_DATA_SET_TO_FILE_REQUEST = "EXPORT_DATA_SET_TO_FILE_REQUEST";
export const EXPORT_DATA_SET_TO_FILE_SUCCESS = "EXPORT_DATA_SET_TO_FILE_SUCCESS";
export const EXPORT_DATA_SET_TO_FILE_FAILURE = "EXPORT_DATA_SET_TO_FILE_FAILURE";

export const DOWNLOAD_EXPORT_DATA_SET_REQUEST = "DOWNLOAD_EXPORT_DATA_SET_REQUEST";
export const DOWNLOAD_EXPORT_DATA_SET_SUCCESS = "DOWNLOAD_EXPORT_DATA_SET_SUCCESS";
export const DOWNLOAD_EXPORT_DATA_SET_FAILURE = "DOWNLOAD_EXPORT_DATA_SET_FAILURE";

export const EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_REQUEST = "EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_REQUEST";
export const EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_SUCCESS = "EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_SUCCESS";
export const EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_FAILURE = "EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_FAILURE";

export const GET_USER_EXPORT_JOB_PERMISSION_REQUEST = "GET_USER_EXPORT_JOB_PERMISSION_REQUEST";
export const GET_USER_EXPORT_JOB_PERMISSION_SUCCESS = "GET_USER_EXPORT_JOB_PERMISSION_SUCCESS";
export const GET_USER_EXPORT_JOB_PERMISSION_FAILURE = "GET_USER_EXPORT_JOB_PERMISSION_FAILURE";

export const createDataSetRequest = (businessAreaId, schemaId, dataSet) => ({
    type: CREATE_DATA_SET_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSet
    }
})

export const createDataSetSuccess = (dataSet) => ({
    type: CREATE_DATA_SET_SUCCESS,
    payload: {
        dataSet
    }
})

export const createDataSetFailure = (error) => ({
    type: CREATE_DATA_SET_FAILURE,
    payload: {
        error
    }
})

export const getDataSetBySchemaIdRequest = (businessAreaId, schemaId) => ({
    type: GET_DATA_SET_BY_SCHEMA_ID_REQUEST,
    params: {
        businessAreaId,
        schemaId
    }
})

export const getDataSetBySchemaIdSuccess = (schemaDataSetResult) => ({
    type: GET_DATA_SET_BY_SCHEMA_ID_SUCCESS,
    payload: {
        schemaDataSetResult
    }
})

export const getDataSetBySchemaIdFailure = (error) => ({
    type: GET_DATA_SET_BY_SCHEMA_ID_FAILURE,
    payload: {
        error
    }
})

export const getRecentDataSetRequest = () => ({
    type: GET_RECENT_DATA_SET_REQUEST
})

export const getRecentDataSetSuccess = (dataSetResult) => ({
    type: GET_RECENT_DATA_SET_SUCCESS,
    payload: dataSetResult
})

export const getRecentDataSetFailure = (error) => ({
    type: GET_RECENT_DATA_SET_FAILURE,
    payload: {
        error
    }
})

export const getDataSetByIdRequest = (businessAreaId, schemaId, dataSetId) => ({
    type: GET_DATA_SET_BY_ID_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId
    }
})

export const getDataSetByIdSuccess = (dataSet) => ({
    type: GET_DATA_SET_BY_ID_SUCCESS,
    payload: {
        dataSet
    }
})

export const getDataSetByIdFailure = (error) => ({
    type: GET_DATA_SET_BY_ID_FAILURE,
    payload: {
        error
    }
})

export const getDataSetByNameRequest = (businessAreaId, schemaId, dataSetName) => ({
    type: GET_DATA_SET_BY_NAME_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetName
    }
})

export const getDataSetByNameSuccess = (dataSet) => ({
    type: GET_DATA_SET_BY_NAME_SUCCESS,
    payload: {
        dataSet
    }
})

export const getDataSetByNameFailure = (error) => ({
    type: GET_DATA_SET_BY_NAME_FAILURE,
    payload: {
        error
    }
})

export const getDataSetFieldInsightsRequest = (dataSetId) => ({
    type: GET_DATA_SET_FIELD_INSIGHTS_REQUEST,
    params: {
        dataSetId
    }
})

export const getDataSetFieldInsightsSuccess = (dataSetFieldInsightsResult) => ({
    type: GET_DATA_SET_FIELD_INSIGHTS_SUCCESS,
    payload: {
        dataSetFieldInsightsResult
    }
})

export const getDataSetFieldInsightsFailure = (error) => ({
    type: GET_DATA_SET_FIELD_INSIGHTS_FAILURE,
    payload: {
        error
    }
})

export const deleteDataSetRequest = (businessAreaId, schemaId, dataSetId) => ({
    type: DELETE_DATA_SET_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId
    }
})

export const deleteDataSetSuccess = (businessAreaId, schemaId, dataSetId) => ({
    type: DELETE_DATA_SET_SUCCESS,
    payload: {
        businessAreaId,
        schemaId,
        dataSetId
    }
})

export const deleteDataSetFailure = (error) => ({
    type: DELETE_DATA_SET_FAILURE,
    payload: {
        error
    }
})

export const updateDataSetRequest = (businessAreaId, schemaId, dataSet, updatedDataSet) => ({
    type: UPDATE_DATA_SET_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSet,
        updatedDataSet
    }
})

export const updateDataSetSuccess = (dataSet) => ({
    type: UPDATE_DATA_SET_SUCCESS,
    payload: {
        dataSet
    }
})

export const updateDataSetFailure = (error) => ({
    type: UPDATE_DATA_SET_FAILURE,
    payload: {
        error
    }
})

export const getUserDataSetPermissionRequest = () => ({
    type: GET_USER_DATA_SET_PERMISSION_REQUEST
})

export const getUserDataSetPermissionSuccess = (permission) => ({
    type: GET_USER_DATA_SET_PERMISSION_SUCCESS,
    payload: {
        permission
    }
})

export const getUserDataSetPermissionFailure = (error) => ({
    type: GET_USER_DATA_SET_PERMISSION_FAILURE,
    payload: {
        error
    }
})

export const getDataSetJobsRequest = (dataSetId) => ({
    type: GET_DATA_SET_JOBS_REQUEST,
    params: {
        dataSetId
    }
})

export const getDataSetJobsSuccess = (jobResult) => ({
    type: GET_DATA_SET_JOBS_SUCCESS,
    payload: {
        jobResult
    }
})

export const getDataSetJobsFailure = (error) => ({
    type: GET_DATA_SET_JOBS_FAILURE,
    payload: {
        error
    }
})

export const dataSetJobExecutionProgress = (message) => ({
    type: DATA_SET_JOB_EXECUTION_PROGRESS,
    payload: {
        message
    }
})

export const dataSetUpdatedEvent = (dataSet) => ({
    type: DATA_SET_UPDATED_EVENT,
    payload: {
        dataSet
    }
})

export const undoJobRequest = (jobId, version) => ({
    type: UNDO_JOB_REQUEST,
    params: {
        jobId,
        version
    }
})

export const undoJobSuccess = (updatedJob) => ({
    type: UNDO_JOB_SUCCESS,
    payload: {
        updatedJob
    }
})

export const undoJobFailure = (error) => ({
    type: UNDO_JOB_FAILURE,
    payload: {
        error
    }
})

export const indexDataSetRequest = (dataSet) => ({
    type: INDEX_DATA_SET_REQUEST,
    params: {
        dataSet
    }
})

export const indexDataSetSuccess = () => ({
    type: INDEX_DATA_SET_SUCCESS
})

export const indexDataSetFailure = (error) => ({
    type: INDEX_DATA_SET_FAILURE,
    payload: {
        error
    }
})

export const exportDataSetToFileRequest = (dataSet) => ({
    type: EXPORT_DATA_SET_TO_FILE_REQUEST,
    params: {
        dataSet
    }
})

export const exportDataSetToFileSuccess = () => ({
    type: EXPORT_DATA_SET_TO_FILE_SUCCESS
})

export const exportDataSetToFileFailure = (error) => ({
    type: EXPORT_DATA_SET_TO_FILE_FAILURE,
    payload: {
        error
    }
})

export const downloadExportDataSetRequest = (fileKey, fileName) => ({
    type: DOWNLOAD_EXPORT_DATA_SET_REQUEST,
    params: {
        fileKey,
        fileName
    }
})

export const downloadExportDataSetSuccess = (result) => ({
    type: DOWNLOAD_EXPORT_DATA_SET_SUCCESS,
    payload: {
        result
    }
})

export const downloadExportDataSetFailure = (error) => ({
    type: DOWNLOAD_EXPORT_DATA_SET_FAILURE,
    payload: {
        error
    }
})

export const exportDataSetToSystemConnectionRequest = (dataSet, connection) => ({
    type: EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_REQUEST,
    params: {
        dataSet,
        connection
    }
})

export const exportDataSetToSystemConnectionSuccess = () => ({
    type: EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_SUCCESS
})

export const exportDataSetToSystemConnectionFailure = (error) => ({
    type: EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const getUserExportJobPermissionRequest = () => ({
    type: GET_USER_EXPORT_JOB_PERMISSION_REQUEST
})

export const getUserExportJobPermissionSuccess = (permission) => ({
    type: GET_USER_EXPORT_JOB_PERMISSION_SUCCESS,
    payload: {
        permission
    }
})

export const getUserExportJobPermissionFailure = (error) => ({
    type: GET_USER_EXPORT_JOB_PERMISSION_FAILURE,
    payload: {
        error
    }
})
