import {
    GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_SUCCESS,
    GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_FAILURE,
} from "./actions";

const initialState = {
    getNominatedDataObjectMergePreviewResult: {
        success: true
    },
    nominatedDataObjectMergePreview: {
        record: null,
        schema: null
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_SUCCESS:
            return {
                ...state,
                getNominatedDataObjectMergePreviewResult:
                {
                    success: true
                },
                nominatedDataObjectMergePreview:
                {
                    record: action.payload.record,
                    schema: action.payload.schema
                }
            };
        case GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_FAILURE:
            return {
                ...state,
                getNominatedDataObjectMergePreviewResult:
                {
                    success: false,
                    ...action.payload.error
                },
                nominatedDataObjectMergePreview:
                {
                    record: null,
                    schema: null
                }
            };
        default:
            return state;
    }
};
