import {
    GET_ALL_BUSINESS_AREA_DATA_SUCCESS,
    GET_ALL_BUSINESS_AREA_DATA_FAILURE,

    CREATE_BUSINESS_AREA_DATA_SUCCESS,
    CREATE_BUSINESS_AREA_DATA_FAILURE,

    GET_BUSINESS_AREA_DATA_BY_ID_SUCCESS,
    GET_BUSINESS_AREA_DATA_BY_ID_FAILURE,

    DELETE_BUSINESS_AREA_DATA_SUCCESS,
    DELETE_BUSINESS_AREA_DATA_FAILURE,

    UPDATE_BUSINESS_AREA_DATA_SUCCESS,
    UPDATE_BUSINESS_AREA_DATA_FAILURE,

    FETCH_BUSINESS_AREA_DOMAIN_DATA_REQUEST,
    FETCH_BUSINESS_AREA_DOMAIN_DATA_SUCCESS,
    FETCH_BUSINESS_AREA_DOMAIN_DATA_FAILURE,

    GET_USER_BUSINESS_AREA_DATA_PERMISSION_SUCCESS,
    GET_USER_BUSINESS_AREA_DATA_PERMISSION_FAILURE,

    CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_SUCCESS,
    CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_FAILURE
} from "./actions";

const initialState = {
    getAllBusinessAreaDataResult: {
        success: true
    },
    dataDomainsResult: {
        Items: []
    },
    createBusinessAreaDataResult: {
        success: true
    },
    getBusinessAreaDataByIdResult: {
        success: true
    },
    businessAreaData: null,
    deleteBusinessAreaDataResult: {
        success: true
    },
    updateBusinessAreaDataResult: {
        success: true
    },
    businessAreaDomainDataResult: {
    },
    getUserBusinessAreaDataPermissionResult: {
        success: true
    },
    permission: null,
    createBulkUploadDomainDataJobResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    let dataDomainsResult = {
        Items: []
    };
    let domainDataResult = {
        Items: []
    };
    switch (action.type) {
        case GET_ALL_BUSINESS_AREA_DATA_SUCCESS:
            dataDomainsResult = action.payload;
            return { ...state, getAllBusinessAreaDataResult: { success: true }, dataDomainsResult };
        case GET_ALL_BUSINESS_AREA_DATA_FAILURE:
            return {
                ...state, getAllBusinessAreaDataResult: { success: false, ...action.payload },
                dataDomainsResult: {
                    Items: []
                }
            };
        case CREATE_BUSINESS_AREA_DATA_SUCCESS:
            return { ...state, createBusinessAreaDataResult: { success: true } };
        case CREATE_BUSINESS_AREA_DATA_FAILURE:
            return { ...state, createBusinessAreaDataResult: { success: false, ...action.payload } };
        case GET_BUSINESS_AREA_DATA_BY_ID_SUCCESS:
            return { ...state, getBusinessAreaDataByIdResult: { success: true }, businessAreaData: action.payload };
        case GET_BUSINESS_AREA_DATA_BY_ID_FAILURE:
            return { ...state, getBusinessAreaDataByIdResult: { success: false, ...action.payload }, businessAreaData: null };
        case DELETE_BUSINESS_AREA_DATA_SUCCESS:
            dataDomainsResult = { ...dataDomainsResult };
            dataDomainsResult.Items = state.dataDomainsResult.Items.filter(dataDomain => dataDomain.dataDomainId !== action.payload.dataDomainId);
            return { ...state, deleteBusinessAreaDataResult: { success: true }, dataDomainsResult };
        case DELETE_BUSINESS_AREA_DATA_FAILURE:
            return { ...state, deleteBusinessAreaDataResult: { success: false, ...action.payload } };
        case UPDATE_BUSINESS_AREA_DATA_SUCCESS:
            return { ...state, updateBusinessAreaDataResult: { success: true } };
        case UPDATE_BUSINESS_AREA_DATA_FAILURE:
            return { ...state, updateBusinessAreaDataResult: { success: false, ...action.payload } };
        case FETCH_BUSINESS_AREA_DOMAIN_DATA_REQUEST:
            return {
                ...state,
                businessAreaDomainDataResult: {
                    ...state.businessAreaDomainDataResult,
                    [action.params.dataDomainId]: {
                        inProgress: true,
                        domainDataResult: !action.params.pageKey ? { Items: [] } : state.businessAreaDomainDataResult[action.params.dataDomainId].domainDataResult,
                        error: null
                    }
                }
            };
        case FETCH_BUSINESS_AREA_DOMAIN_DATA_SUCCESS:
            domainDataResult = action.payload.domainDataResult;
            let sNo = state.businessAreaDomainDataResult[action.payload.dataDomainId].domainDataResult.Items.length;
            for (let item of domainDataResult.Items) {
                item.sNo = sNo + 1;
                sNo = item.sNo;
            }
            domainDataResult.Items = state.businessAreaDomainDataResult[action.payload.dataDomainId].domainDataResult.Items.concat(domainDataResult.Items)
            return {
                ...state,
                businessAreaDomainDataResult: {
                    ...state.businessAreaDomainDataResult,
                    [action.payload.dataDomainId]: {
                        inProgress: false,
                        domainDataResult,
                        error: null
                    }
                }
            };
        case FETCH_BUSINESS_AREA_DOMAIN_DATA_FAILURE:
            return {
                ...state,
                businessAreaDomainDataResult: {
                    ...state.businessAreaDomainDataResult,
                    [action.payload.dataDomainId]: {
                        inProgress: false,
                        domainDataResult: { Items: [] },
                        error: action.payload.error
                    }
                }
            };
        case GET_USER_BUSINESS_AREA_DATA_PERMISSION_SUCCESS:
            return { ...state, getUserBusinessAreaDataPermissionResult: { success: true }, permission: action.payload };
        case GET_USER_BUSINESS_AREA_DATA_PERMISSION_FAILURE:
            return { ...state, getUserBusinessAreaDataPermissionResult: { success: false, ...action.payload }, permission: null };
        case CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_SUCCESS:
            return { ...state, createBulkUploadDomainDataJobResult: { success: true } };
        case CREATE_BULK_UPLOAD_DOMAIN_DATA_JOB_FAILURE:
            return { ...state, createBulkUploadDomainDataJobResult: { success: false, ...action.payload } };
        default:
            return state;
    }
};
