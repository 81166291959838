import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, EventList, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

const SystemKeysAudit = (props) => {
    const [fetchingEvents, setFetchingEvents] = useState(true);
    const [prevKeyId, setPrevKeyId] = useState(null);

    useEffect(() => {
        if (props.keyData.keyId !== prevKeyId) {
            setPrevKeyId(props.keyData.keyId);
            props.getSystemKeyEvents(props.keyData.keyId);
            setFetchingEvents(true);
        }
    }, [props.keyData.keyId, prevKeyId, props.getSystemKeyEvents]);

    useEffect(() => {
        if (props.getSystemKeyEventsResult && !props.getSystemKeyEventsResult.success) {
            showError("Could not get system key events.");
            setFetchingEvents(false);
        } else {
            setFetchingEvents(false);
        }
    }, [props.getSystemKeyEventsResult]);

    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                fetchingEvents ? (
                    <LoadingOverlay
                        busy={true}
                        spinner
                        message="Getting system key events..."
                    ></LoadingOverlay>
                ) : (
                    <EventList eventList={props.systemKeyEventsResult.Items } />
                )
            }
        />
    );
};

const mapStateToProps = (state, ownProps) => ({
    getSystemKeyEventsResult: state.systemKeysAudit.getSystemKeyEventsResult,
    systemKeyEventsResult: state.systemKeysAudit.systemKeyEventsResult,
});

const mapDispatchToProps = (dispatch) => ({
    getSystemKeyEvents: (keyId) => dispatch(actions.getSystemKeyEventsRequest(keyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemKeysAudit));
