export const GET_EVENT_DATA_SET_REQUEST = "GET_EVENT_DATA_SET_REQUEST";
export const GET_EVENT_DATA_SET_SUCCESS = "GET_EVENT_DATA_SET_SUCCESS";
export const GET_EVENT_DATA_SET_FAILURE = "GET_EVENT_DATA_SET_FAILURE";

export const getEventDataSetRequest = (eventId, dataSetId) => ({
    type: GET_EVENT_DATA_SET_REQUEST,
    params: {
        eventId,
        dataSetId
    }
})

export const getEventDataSetSuccess = (dataSetResult) => ({
    type: GET_EVENT_DATA_SET_SUCCESS,
    payload: dataSetResult
})

export const getEventDataSetFailure = (error) => ({
    type: GET_EVENT_DATA_SET_FAILURE,
    payload: error
})