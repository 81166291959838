
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* getAppClients(api) {
    let result = yield call([api, api.get], `${Endpoints.users}/api/v1/appclients`);
    return result.data;
}

function* createAppClient(api, appClient) {
    let result = yield call([api, api.post], `${Endpoints.users}/api/v1/appclients`, appClient);
    return result.data;
}

function* getAppClient(api, clientId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.users}/api/v1/appclients/${clientId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateAppClient(api, clientId, updatedAppClient) {
    let response = yield call([api, api.put], `${Endpoints.users}/api/v1/appclients/${clientId}`, updatedAppClient);
    return response.data;
}

function* deleteAppClient(api, clientId) {
    yield call([api, api.delete], `${Endpoints.users}/api/v1/appclients/${clientId}`);
}

function* regenerateAppClientSecret(api, clientId, updatedAppClient) {
    let response = yield call([api, api.put], `${Endpoints.users}/api/v1/appclients/${clientId}/secret/regenerate`, updatedAppClient);
    return response.data;
}

export function* getAppClientPermission(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/credentials`, { tenantid: tenantId });
    return result.data;
}

export function* getAppClientsRequest(api) {
    try {
        const result = yield call(getAppClients, api);
        yield put(actions.getAppClientsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "appClients");
        yield put(actions.getAppClientsFailure(errorObject));
    }
}

export function* createAppClientsRequest(api, { appClient }) {
    try {
        let createdAppClient = yield call(createAppClient, api, appClient);
        yield put(actions.createAppClientSuccess(createdAppClient));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "app client");
        yield put(actions.createAppClientFailure(errorObject));
    }
}

export function* getAppClientRequest(api, { clientId }) {
    try {
        const appClient = yield call(getAppClient, api, clientId);
        yield put(actions.getAppClientSuccess(appClient));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "app client");
        yield put(actions.getAppClientFailure(errorObject));
    }
}

export function* updateAppClientRequest(api, { clientId, updatedAppClient }) {
    try {
        const appClient = yield call(updateAppClient, api, clientId, updatedAppClient);
        yield put(actions.updateAppClientSuccess(appClient));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "app client");
        yield put(actions.updateAppClientFailure(errorObject));
    }
}

export function* deleteAppClientRequest(api, { clientId }) {
    try {
        yield call(deleteAppClient, api, clientId);
        yield put(actions.deleteAppClientSuccess(clientId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "app client");
        yield put(actions.deleteAppClientFailure(errorObject));
    }
}

export function* regenerateAppClientSecretRequest(api, { clientId, version }) {
    try {
        const appClient = yield call(regenerateAppClientSecret, api, clientId, { version });
        yield put(actions.regenerateAppClientSecretSuccess(appClient));
    } catch (error) {
        let errorObject = errorHandler(error, "Regenerate", "app client secret");
        yield put(actions.regenerateAppClientSecretFailure(errorObject));
    }
}

export function* getAppClientPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getAppClientPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getAppClientPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user credentials permission");
        yield put(actions.getAppClientPermissionFailure(errorObject));
    }
}

export function* watchGetAppClientsRequest(api, params) {
    yield call(getAppClientsRequest, api);
}

export function* watchCreateAppClientRequest(api, { params }) {
    yield call(createAppClientsRequest, api, params);
}

export function* watchGetAppClientRequest(api, { params }) {
    yield call(getAppClientRequest, api, params);
}

export function* watchUpdateAppClientRequest(api, { params }) {
    yield call(updateAppClientRequest, api, params);
}

export function* watchDeleteAppClientRequest(api, { params }) {
    yield call(deleteAppClientRequest, api, params);
}

export function* watchRegenerateAppClientSecretRequest(api, { params }) {
    yield call(regenerateAppClientSecretRequest, api, params);
}

export function* watchGetAppClientPermissionRequest(api, { params }) {
    yield call(getAppClientPermissionRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_APP_CLIENTS_REQUEST, watchGetAppClientsRequest, api);
    yield takeEvery(actions.CREATE_APP_CLIENT_REQUEST, watchCreateAppClientRequest, api);
    yield takeLatest(actions.GET_APP_CLIENT_REQUEST, watchGetAppClientRequest, api);
    yield takeLatest(actions.UPDATE_APP_CLIENT_REQUEST, watchUpdateAppClientRequest, api);
    yield takeLatest(actions.DELETE_APP_CLIENT_REQUEST, watchDeleteAppClientRequest, api);
    yield takeLatest(actions.REGENERATE_APP_CLIENT_SECRET_REQUEST, watchRegenerateAppClientSecretRequest, api);
    yield takeEvery(actions.GET_APP_CLIENT_PERMISSIONS_REQUEST, watchGetAppClientPermissionRequest, api);
}
