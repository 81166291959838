export default Object.freeze(
    [
        'Countries',
        'States',
        'E164',
        'Language',
        'Currency',
        'Gender',
        'Honorific',
        'CH Canton',
        'CA Province',
        'ISO Country'
    ]);