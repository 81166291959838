import React, { Component } from 'react';
import { connect } from "react-redux";
import LABELS from 'constants/labels';
import actions from 'store/actions';
import { BusinessAreaDataList, ManageBusinessAreaData, OkCancelConfirmationBox, LoadingOverlay, ProhibitedArea, BusinessAreaDataHistoryList } from "components";
import { BusinessAreaDataAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import flatten from 'flat';

class BusinessAreaData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingPermission: true
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getUserBusinessAreaDataPermission();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting Business Area Data...",
                fetchingAllBusinessAreaData: true
            });
            this.props.getAllBusinessAreaData();
        }
        else {
            this.props.getUserBusinessAreas();
            if (this.props.dataDomainId) {
                this.props.getBusinessAreaDataById(this.props.dataDomainId);
            }
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting Business Area Data...",
                    fetchingBusinessAreaData: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingBusinessAreaData: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.onGetAllBusinessAreaDataResultUpdate(prevProps);

        this.onCreateBusinessAreaDataResultUpdate(prevProps);

        this.onCreateBulkUploadDomainDataJobResultUpdate(prevProps);

        this.onGetBusinessAreaDataByIdResultUpdate(prevProps);

        this.onUpdateBusinessAreaDataResultUpdate(prevProps);

        this.onDeleteBusinessAreaDataResultUpdate(prevProps);

        this.onGetUserBusinessAreaDataPermissionResultUpdate(prevProps);
    }

    onGetAllBusinessAreaDataResultUpdate = (prevProps) => {
        if (this.props.getAllBusinessAreaDataResult && this.props.getAllBusinessAreaDataResult !== prevProps.getAllBusinessAreaDataResult) {
            if (!this.props.getAllBusinessAreaDataResult.success) {
                if (this.props.getAllBusinessAreaDataResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingAllBusinessAreaData: false
            });
        }
    }

    onCreateBusinessAreaDataResultUpdate = (prevProps) => {
        if (this.props.createBusinessAreaDataResult && this.props.createBusinessAreaDataResult !== prevProps.createBusinessAreaDataResult) {
            if (!this.props.createBusinessAreaDataResult.success) {
                showError(this.props.createBusinessAreaDataResult.message || "Business area data cannot be created.");
                this.setState({
                    busy: false,
                    manageBusinessAreaDataErrors: this.props.createBusinessAreaDataResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Business area data created. Please wait..."
                });
                showSuccess("Business area data created successfully.", () => {
                    this.cancelManageBusinessAreaData();
                });
            }
        }
    }

    onCreateBulkUploadDomainDataJobResultUpdate = (prevProps) => {
        if (this.props.createBulkUploadDomainDataJobResult && this.props.createBulkUploadDomainDataJobResult !== prevProps.createBulkUploadDomainDataJobResult) {
            if (!this.props.createBulkUploadDomainDataJobResult.success) {
                if (this.props.createBulkUploadDomainDataJobResult.code === "JOB_ALREADY_RUNNING") {
                    showError("File bulk upload failed. A bulk data upload job is already running.");
                }
                else {
                    showError(this.props.createBulkUploadDomainDataJobResult.message || "An error occurred while creating job for bulk upload domain data");
                }
            }
        }
    }

    onGetBusinessAreaDataByIdResultUpdate = (prevProps) => {
        if (this.props.getBusinessAreaDataByIdResult && this.props.getBusinessAreaDataByIdResult !== prevProps.getBusinessAreaDataByIdResult) {
            if (!this.props.getBusinessAreaDataByIdResult.success) {
                if (this.props.getBusinessAreaDataByIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data.");
                }
            }
            else {
                if (this.props.action === "view" || this.props.action === "edit") {
                    this.props.fetchBusinessAreaDomainData(this.props.businessAreaData.dataDomainId, null);
                }
                else if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                fetchingBusinessAreaData: false
            });
            if (!this.props.dataDomainsResult || !this.props.dataDomainsResult.Items || this.props.dataDomainsResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateBusinessAreaDataResultUpdate = (prevProps) => {
        if (this.props.updateBusinessAreaDataResult && this.props.updateBusinessAreaDataResult !== prevProps.updateBusinessAreaDataResult) {
            if (!this.props.updateBusinessAreaDataResult.success) {
                showError(this.props.updateBusinessAreaDataResult.message || "Business area data cannot be updated.");
                this.setState({
                    busy: false,
                    manageBusinessAreaDataErrors: this.props.updateBusinessAreaDataResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Business area data updated. Please wait..."
                });
                showSuccess("Business area data updated successfully.", () => {
                    this.cancelManageBusinessAreaData();
                });
            }
        }
    }

    onDeleteBusinessAreaDataResultUpdate = (prevProps) => {
        if (this.props.deleteBusinessAreaDataResult && this.props.deleteBusinessAreaDataResult !== prevProps.deleteBusinessAreaDataResult) {
            if (!this.props.deleteBusinessAreaDataResult.success) {
                showError(this.props.deleteBusinessAreaDataResult.message || "Business area data cannot be deleted.");
            }
            else {
                showSuccess("Business area data deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onGetUserBusinessAreaDataPermissionResultUpdate = (prevProps) => {
        if (this.props.getUserBusinessAreaDataPermissionResult && this.props.getUserBusinessAreaDataPermissionResult !== prevProps.getUserBusinessAreaDataPermissionResult) {
            if (!this.props.getUserBusinessAreaDataPermissionResult.success) {
                this.setState({
                    permissionDenied: true,
                    fetchingPermission: false
                });
            }
            else {
                this.setState({
                    fetchingPermission: false,
                });
            }
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Business Area Data"
                }
            ];
            if (this.props.action) {
                let dataDomainName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New Business Area Data"
                        });
                        break;
                    case "view":
                    case "edit":
                        dataDomainName = this.getDomainDataName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: dataDomainName
                        }]);
                        break;
                    case "history":
                        dataDomainName = this.getDomainDataName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: dataDomainName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getDomainDataName = () => {
        let dataDomainName = this.props.dataDomainId;
        if (this.props.dataDomainsResult && this.props.dataDomainsResult.Items && this.props.dataDomainsResult.Items.length > 0) {
            let dataDomain = this.props.dataDomainsResult.Items.find(dataDomain => dataDomain.dataDomainId === this.props.dataDomainId);
            if (dataDomain) {
                dataDomainName = dataDomain.domainName;
            }
        }
        else if (this.props.businessAreaData && this.props.businessAreaData.dataDomainId === this.props.dataDomainId) {
            dataDomainName = this.props.businessAreaData.domainName;
        }
        return dataDomainName;
    }

    showCreateBusinessAreaData = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    showViewBusinessAreaData = (dataDomainId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", dataDomainId)
            .replace(":itemAction", "view"));
    }

    showEditBusinessAreaData = (dataDomainId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", dataDomainId)
            .replace(":itemAction", "edit"));
    }

    showBusinessAreaDataHistory = (dataDomainId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", dataDomainId)
            .replace(":itemAction", "history"));
    }

    cancelManageBusinessAreaData = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    createBusinessAreaData = businessAreaData => {
        this.setState({
            busy: true,
            busyMessage: "Creating business area data"
        });
        this.props.createBusinessAreaData(businessAreaData);
    }

    updateBusinessAreaData = (businessAreaData, updatedBusinessAreaData) => {
        this.setState({
            busy: true,
            busyMessage: "Updating business area data..."
        });
        this.props.updateBusinessAreaData(businessAreaData, updatedBusinessAreaData);
    }

    showDeleteBusinessAreaDataConfirmation = (businessAreaData) => {
        this.setState({
            deletedBusinessAreaData: businessAreaData,
            showBusinessAreaDataDeleteConfirmation: true,
            deleteBusinessAreaDataConfirmMessage: `Are you sure, you want to delete '${businessAreaData.domainName}' business area data?`
        })
    }

    onDeleteBusinessAreaDataConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting business area data..."
        });
        this.props.deleteBusinessAreaData(this.state.deletedBusinessAreaData.dataDomainId);
        this.onDeleteBusinessAreaDataCancel();
    }

    onDeleteBusinessAreaDataCancel = () => {
        this.setState({
            deletedBusinessAreaData: null,
            showBusinessAreaDataDeleteConfirmation: false,
            deleteBusinessAreaDataConfirmMessage: ""
        })
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingAllBusinessAreaData || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingBusinessAreaData;
        }
        return isBusy;
    }

    onBusinessAreaDataValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageBusinessAreaDataErrors) === false) {
            let manageBusinessAreaDataErrors = { ...this.state.manageBusinessAreaDataErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageBusinessAreaDataErrors[key];
            }
            this.setState({
                manageBusinessAreaDataErrors
            })
        }
    }

    fetchNextBusinessAreaDomainData = (dataDomainId, pageKey) => {
        this.props.fetchBusinessAreaDomainData(dataDomainId, pageKey);
    }

    getBusinessAreaDataComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return <>
                <OkCancelConfirmationBox
                    show={this.state.showBusinessAreaDataDeleteConfirmation}
                    message={this.state.deleteBusinessAreaDataConfirmMessage}
                    onCancel={this.onDeleteBusinessAreaDataCancel}
                    onOk={this.onDeleteBusinessAreaDataConfirmed}>
                </OkCancelConfirmationBox>
                {!this.state.fetchingAllBusinessAreaData ? <BusinessAreaDataList
                    businessAreaDataList={this.props.dataDomainsResult.Items}
                    onCreateNewBusinessAreaData={this.showCreateBusinessAreaData}
                    onEditBusinessAreaData={this.showEditBusinessAreaData}
                    onDeleteBusinessAreaData={this.showDeleteBusinessAreaDataConfirmation}
                    onViewBusinessAreaData={this.showViewBusinessAreaData}
                    onViewBusinessAreaDataHistory={this.showBusinessAreaDataHistory}
                    permission={this.props.permission}
                ></BusinessAreaDataList> : <></>}
            </>
        }
        switch (this.props.action) {
            case "create":
                return <ManageBusinessAreaData
                    action={this.props.action}
                    businessAreaList={this.props.businessAreaList}
                    createBusinessAreaData={this.createBusinessAreaData}
                    onCancel={this.cancelManageBusinessAreaData}
                    formErrors={this.state.manageBusinessAreaDataErrors}
                    onValuesChanged={this.onBusinessAreaDataValuesChanged}
                    permission={this.props.permission}>
                </ManageBusinessAreaData>;
            case "view":
            case "edit":
                return !this.state.fetchingBusinessAreaData ? (this.props.businessAreaData ? <ManageBusinessAreaData
                    action={this.props.action}
                    businessAreaData={this.props.businessAreaData}
                    businessAreaList={this.props.businessAreaList}
                    onCancel={this.cancelManageBusinessAreaData}
                    updateBusinessAreaData={this.updateBusinessAreaData}
                    formErrors={this.state.manageBusinessAreaDataErrors}
                    businessAreaDomainDataResult={this.props.businessAreaDomainDataResult[this.props.businessAreaData.dataDomainId]}
                    onValuesChanged={this.onBusinessAreaDataValuesChanged}
                    permission={this.props.permission}
                    onFetchNextBusinessAreaDomainData={this.fetchNextBusinessAreaDomainData}></ManageBusinessAreaData> :
                    (this.props.getBusinessAreaDataByIdResult.success ? <Message floating size='big'>
                        <Message.Header>Business Area Data not found.</Message.Header>
                        <p>The Business Area Data you are looking for does not exist.</p>
                    </Message> : <></>)) : <></>;
            case "history":
                return this.state.showHistory ? <BusinessAreaDataAudit dataDomain={this.props.businessAreaData} onClose={this.cancelManageBusinessAreaData}></BusinessAreaDataAudit> : <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <div className="full-height-flex-container verticle-scroll">
                <LoadingOverlay
                    busy={this.isBusy()}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {!this.state.fetchingPermission ? this.getBusinessAreaDataComponent() : <></>}
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreaList: state.home.businessAreas,
        getUserBusinessAreasResult: state.home.getUserBusinessAreasResult,
        dataDomainsResult: state.businessAreaData.dataDomainsResult,
        getAllBusinessAreaDataResult: state.businessAreaData.getAllBusinessAreaDataResult,
        createBusinessAreaDataResult: state.businessAreaData.createBusinessAreaDataResult,
        getBusinessAreaDataByIdResult: state.businessAreaData.getBusinessAreaDataByIdResult,
        businessAreaData: state.businessAreaData.businessAreaData,
        updateBusinessAreaDataResult: state.businessAreaData.updateBusinessAreaDataResult,
        deleteBusinessAreaDataResult: state.businessAreaData.deleteBusinessAreaDataResult,
        businessAreaDomainDataResult: state.businessAreaData.businessAreaDomainDataResult,
        getUserBusinessAreaDataPermissionResult: state.businessAreaData.getUserBusinessAreaDataPermissionResult,
        permission: state.businessAreaData.permission,
        createBulkUploadDomainDataJobResult: state.businessAreaData.createBulkUploadDomainDataJobResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreas: () => dispatch(actions.getUserBusinessAreasRequest()),
        getAllBusinessAreaData: () => dispatch(actions.getAllBusinessAreaDataRequest()),
        createBusinessAreaData: (businessAreaData) => dispatch(actions.createBusinessAreaDataRequest(businessAreaData)),
        getBusinessAreaDataById: (dataDomainId) => dispatch(actions.getBusinessAreaDataByIdRequest(dataDomainId)),
        updateBusinessAreaData: (businessAreaData, updatedBusinessAreaData) => dispatch(actions.updateBusinessAreaDataRequest(businessAreaData, updatedBusinessAreaData)),
        deleteBusinessAreaData: (dataDomainId) => dispatch(actions.deleteBusinessAreaDataRequest(dataDomainId)),
        fetchBusinessAreaDomainData: (dataDomainId, pageKey) => dispatch(actions.fetchBusinessAreaDomainDataRequest(dataDomainId, pageKey)),
        getUserBusinessAreaDataPermission: () => dispatch(actions.getUserBusinessAreaDataPermissionRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreaData));