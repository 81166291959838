import React, { useState, useEffect, useRef } from "react";
import { Select, Input, Button, Table, Modal, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FormulaParser } from "components";

const { Column } = Table;
const { Option } = Select;

const DataMapper = ({ destinationFields, sourceFields, sourceData, onMappingChanged }) => {
    const [fields, setFields] = useState(destinationFields);
    const [expressionInput, setExpressionInput] = useState({ isVisible: false, targetField: null, targetFieldIndex: -1 });
    const formulaParserRef = useRef();

    useEffect(() => {
        setFields(destinationFields);
    }, [destinationFields]);

    const onMappingTypeChange = (value, fieldIndex) => {
        const newFields = [...fields];
        newFields[fieldIndex] = { ...newFields[fieldIndex] };
        newFields[fieldIndex].mappingType = value;
        newFields[fieldIndex].value = "";
        newFields[fieldIndex].variables = [];
        setFields(newFields);
        onMappingChanged && onMappingChanged(newFields);
    }

    const onDataColumnChange = (value, fieldIndex) => {
        const newFields = [...fields];
        newFields[fieldIndex] = { ...newFields[fieldIndex] };
        newFields[fieldIndex].value = value;
        setFields(newFields);
        onMappingChanged && onMappingChanged(newFields);
    }

    const onExpressionModelOpenClick = (targetFieldIndex) => {
        const newExpressionInput = { ...expressionInput };
        newExpressionInput.targetFieldIndex = targetFieldIndex;
        newExpressionInput.isVisible = true;
        newExpressionInput.variables = fields[targetFieldIndex].variables || [];
        newExpressionInput.expression = fields[targetFieldIndex].value || "";
        setExpressionInput(newExpressionInput);
    }

    const onExpressionModelOkClick = () => {
        const evaluationResult = formulaParserRef.current.evaluateExpression();
        if (!evaluationResult.error) {
            const newExpressionInput = { ...expressionInput };
            newExpressionInput.targetFieldIndex = -1;
            newExpressionInput.isVisible = false;

            const newFields = [...fields];
            newFields[expressionInput.targetFieldIndex] = { ...newFields[expressionInput.targetFieldIndex] };
            newFields[expressionInput.targetFieldIndex].value = evaluationResult.formulaExpression;
            newFields[expressionInput.targetFieldIndex].variables = evaluationResult.variables;
            setFields(newFields);
            onMappingChanged && onMappingChanged(newFields);
            setExpressionInput(newExpressionInput);
        }
    }

    const onExpressionModelCancelClick = () => {
        const newExpressionInput = { ...expressionInput };
        newExpressionInput.targetFieldIndex = -1;
        newExpressionInput.isVisible = false;
        setExpressionInput(newExpressionInput);
    }


    return (
        <>
            <Modal
                title="Formula Expression"
                width={"50%"}
                centered
                destroyOnClose={true}
                visible={expressionInput.isVisible}
                onOk={() => onExpressionModelOkClick()}
                onCancel={() => onExpressionModelCancelClick()}
                bodyStyle={{ height: "75vh", width: "100%" }}>
                <FormulaParser
                    ref={formulaParserRef}
                    inputColumns={sourceFields}
                    inputData={sourceData}
                    variables={expressionInput.variables}
                    expression={expressionInput.expression}>
                </FormulaParser>
            </Modal>

            <Table size="small"
                rowKey="fieldId"
                dataSource={fields}
                pagination={false}
                scroll={{ y: "100vh" }}
                className='container-height-100'>
                <Column
                    width="15%"
                    dataIndex={"name"}
                    title={"Name"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, field, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="10%"
                    dataIndex={"dataType"}
                    title={"Data Type"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, field, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="10%"
                    dataIndex={"mappingType"}
                    title={"Mapping Type"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, field, index) => (
                        <Select style={{ width: "100%" }} defaultValue="DATA_COLUMN" onChange={(value) => onMappingTypeChange(value, index)} value={field.mappingType}>
                            <Option value="DATA_COLUMN">Data column</Option>
                            <Option value="FORMULA">Formula</Option>
                        </Select>
                    )}
                />
                <Column
                    width="10%"
                    dataIndex={"value"}
                    title={"Mapped Value"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    shouldCellUpdate={(record, prevRecord) => {
                        if (record.mappingType !== prevRecord.mappingType) {
                            return true;
                        }
                        if (record.value !== prevRecord.value) {
                            return true;
                        }
                        return false;
                    }}
                    render={(value, field, index) => (
                        field.mappingType === "DATA_COLUMN"
                            ?
                            <Select
                                style={{ width: "100%" }}
                                showSearch
                                allowClear={true}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(value) => onDataColumnChange(value, index)}
                                value={value}>
                                {
                                    sourceFields && sourceFields.map((column, index) => <Option key={index} value={column}>{column}</Option>)
                                }
                            </Select>
                            :
                            <Input readOnly={true} addonBefore="=" addonAfter={<Button size="small" type="link" onClick={() => onExpressionModelOpenClick(index)}>fx</Button>} placeholder='SUM(amount,100)' value={value} />
                    )}
                />
            </Table>
        </>
    );
};

export default DataMapper;