import {
    GET_KEY_REQUEST,
    GET_KEY_SUCCESS,
    GET_KEY_FAILURE,
    CREATE_KEY_REQUEST,
    CREATE_KEY_SUCCESS,
    CREATE_KEY_FAILURE,
    UPDATE_KEY_REQUEST,
    UPDATE_KEY_SUCCESS,
    UPDATE_KEY_FAILURE,
    DELETE_KEY_REQUEST,
    DELETE_KEY_SUCCESS,
    DELETE_KEY_FAILURE,
    GET_KEYBYID_REQUEST,
    GET_KEYBYID_SUCCESS,
    GET_KEYBYID_FAILURE,
    GET_KEY_KEYTYPE_REQUEST,
    GET_KEY_KEYTYPE_SUCCESS,
    GET_KEY_KEYTYPE_FAILURE,
  } from './actions';
  
  const initialState = {
    getKeyListResult: { success: true },
    keys: { Items: [] },
    createKeyResult: { success: true },
    updateKeyResult: { success: true },
    deleteKeyResult: { success: true },
    getKeyByIdResult: { success: true },
    getKeyByTypeResult: { success: true },
    selectedKey: null,
    loading: false,
    error: null,
    apiKey: null,
  };
  
  export default (state = initialState, action) => {
    let keys = { Items: [] };
    switch (action.type) {
      case GET_KEY_REQUEST:
      case CREATE_KEY_REQUEST:
      case UPDATE_KEY_REQUEST:
      case DELETE_KEY_REQUEST:
      case GET_KEYBYID_REQUEST:
      case GET_KEY_KEYTYPE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case GET_KEY_SUCCESS:
        keys = action.payload.keys;
        return {
          ...state,
          getKeyListResult: { success: true },
          keys,
          loading: false,
        };
  
      case GET_KEY_FAILURE:
        return {
          ...state,
          getKeyListResult: { success: false, ...action.payload.error },
          keys: { Items: [] },
          loading: false,
        };
  
      case CREATE_KEY_SUCCESS:
        if (action.payload && action.payload.keys) {
          return {
              ...state,
              createKeyResult: { success: true },
              keys: { Items: [...(state.keys.Items || []).filter(item => item !== undefined), action.payload.keys] },
              loading: false,
          };
      } else {
          return {
              ...state,
              createKeyResult: { success: false, error: "Invalid key data" },
              loading: false,
          };
      }
  
      case CREATE_KEY_FAILURE:
        return {
          ...state,
          createKeyResult: { success: false, ...action.payload.error },
          loading: false,
        };
  
      case UPDATE_KEY_SUCCESS:
        keys = {
          Items: state.keys.Items.map((key) =>
            key.keyId === action.payload.keyId ? action.payload.keyData : key
          ),
        };
        return {
          ...state,
          updateKeyResult: { success: true },
          keys,
          loading: false,
        };
  
      case UPDATE_KEY_FAILURE:
        return {
          ...state,
          updateKeyResult: { success: false, ...action.payload.error },
          loading: false,
        };
  
      case DELETE_KEY_SUCCESS:
      const filteredKeys = state.keys.Items.filter(key => key.keyId !== action.payload.keyId);
      return {
          ...state,
          deleteKeyResult: { success: true },
          keys: { Items: filteredKeys },
          loading: false,
      };
  
      case DELETE_KEY_FAILURE:
        return {
          ...state,
          deleteKeyResult: { success: false, ...action.payload.error },
          loading: false,
        };
  
      case GET_KEYBYID_SUCCESS:
        return {
          ...state,
          getKeyByIdResult: { success: true },
          selectedKey: action.payload.key,
          loading: false,
        };
  
      case GET_KEYBYID_FAILURE:
        return {
          ...state,
          getKeyByIdResult: { success: false, ...action.payload.error },
          selectedKey: null,
          loading: false,
        };
  
      case GET_KEY_KEYTYPE_SUCCESS:
        return {
          ...state,
          getKeyByTypeResult: { success: true },
          apiKey: action.payload.key,
          loading: false,
        };
  
      case GET_KEY_KEYTYPE_FAILURE:
        return {
          ...state,
          getKeyByTypeResult: { success: false, ...action.payload.error },
          apiKey: null,
          loading: false,
        };
      
  
      default:
        return state;
    }
  };
  