import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { InboxOutlined, FileTwoTone } from '@ant-design/icons';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import _ from 'lodash';
import businessAreaDataDomains from 'common/data/businessAreaDataDomains';
import parsePath from 'parse-filepath';
import { DomainDataPreviewList, FileDragger } from 'components';
import { DataTagSelect } from 'containers';
import FormItem from 'antd/es/form/FormItem';

const { Option } = Select;
const { Dragger } = Upload;
const { Column } = Table;
const { Title, Text } = Typography;

class ManageBusinessAreaData extends Component {

    constructor(props) {
        super(props);
        this.businessAreaDataDefault = {
            usedForTagging: false
        }
        this.formRef = React.createRef();
        this.state = {
            readOnlyView: this.props.action === "view"
        };
        this.emptyObject = {};
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.businessAreaData) {
            this.formRef.current.setFieldsValue({ ...this.props.businessAreaData });
        }
        else {
            this.selectDefaultBusinessArea();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.businessAreaList !== prevProps.businessAreaList && this.props.businessAreaList.length > 0) {
            this.selectDefaultBusinessArea();
        }
    }

    selectDefaultBusinessArea = () => {
        if (this.props.businessAreaList.length > 0) {
            if (this.formRef.current) {
                let selectedBusinessAreaId = this.formRef.current.getFieldValue(["businessAreaId"]);
                if (!selectedBusinessAreaId) {
                    this.formRef.current.setFieldsValue({ businessAreaId: this.props.businessAreaList[0].businessAreaId });
                }
            }
        }
    }

    onFormSubmit = (formData) => {
        if (!this.isSaveAllowed()) {
            return;
        }
        if (this.props.action === "edit") {
            this.props.updateBusinessAreaData(this.props.businessAreaData, { ...formData });
        }
        else {
            let businessAreaData = { ...this.businessAreaDataDefault, ...formData };
            this.props.createBusinessAreaData(businessAreaData);
        }
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    fileEvent = (e) => {
        return e && e.file;
    }

    readFileComplete = (fileReadResult) => {
        this.formRef.current.setFields([
            {
                name: "uploadFile",
                value: fileReadResult.file
            }
        ]);
        this.setState({
            uploadFileExist: true
        })
    }

    cancelUpload = (fileReadResult) => {
        this.formRef.current.setFields([
            {
                name: "uploadFile",
                value: null
            }
        ]);
        this.setState({
            uploadFileExist: false
        })
    }

    uploadFileExist = () => {
        if (this.formRef.current) {
            let uploadFile = this.formRef.current.getFieldValue(["uploadFile"]);
            if (uploadFile) {
                return true;
            }
        }
        return false;
    }

    onFieldsChange = (fields, allFields) => {
        if (this.formRef.current) {
            if (fields && fields.length > 0) {
                for (let field of fields) {
                    if (field.touched && field.name && field.name.length > 0) {
                        if (field.name[0] === "businessAreaId") {
                            this.formRef.current.setFields([
                                {
                                    name: "classifiers",
                                    value: []
                                }
                            ]);
                        }
                    }
                }
            }
            let uploadFile = this.formRef.current.getFieldValue(["uploadFile"]);
            if (uploadFile) {
                this.setState({
                    uploadFileExist: true
                })
            }
        }
    }

    onDomainDataPageSelect = (pagination) => {
        if (this.props.onFetchNextBusinessAreaDomainData &&
            this.props.businessAreaDomainDataResult.domainDataResult.pageKey
            && (pagination.current * pagination.pageSize) >= this.props.businessAreaDomainDataResult.domainDataResult.Items.length) {
            this.props.onFetchNextBusinessAreaDomainData(this.props.businessAreaData.dataDomainId,
                this.props.businessAreaDomainDataResult.domainDataResult.pageKey);
        }
    }

    render() {
        let businessAreaOptions = this.props.businessAreaList.map(businessArea => ({ id: businessArea.businessAreaId, name: businessArea.name }));
        let dataDomains = businessAreaDataDomains.map(domain => ({ value: domain }));
        return (
            <>
                <Form
                    labelCol={{ span: 3 }}
                    layout="horizontal"
                    labelAlign="left"
                    ref={this.formRef}
                    name="basic"
                    onFinish={this.onFormSubmit}
                    initialValues={this.businessAreaDataDefault}
                    onFieldsChange={this.onFieldsChange}
                    onValuesChange={this.props.onValuesChanged}>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                className="wrap-label"
                                {...this.getFormErrors("businessAreaId")}
                                label="Business Area"
                                name={["businessAreaId"]}>
                                <Select
                                    {...(() => (this.isReadOnlyView() ? { open: false } : {}))()}>
                                    {businessAreaOptions.map(item => (<Option key={item.id || (Date.now())} value={item.id}>{item.name}</Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                className="wrap-label"
                                {...this.getFormErrors("domainName")}
                                label="Domain Name"
                                name={["domainName"]}
                                validateFirst={true}
                                rules={nameRules}>
                                <AutoComplete
                                    disabled={this.isReadOnlyView()}
                                    options={dataDomains}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                {...this.getFormErrors("domainDescription")}
                                label="Description"
                                name={["domainDescription"]}
                                rules={descriptionRules}>
                                <Input readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                {...this.getFormErrors("domainPurpose")}
                                label="Purpose"
                                name={["domainPurpose"]}
                                rules={descriptionRules}>
                                <Input readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <FormItem dependencies={['businessAreaId']} label="Data Tags">
                                {({ getFieldValue }) => {
                                    return <Form.Item
                                        {...this.getFormErrors("classifiers")}
                                        name={["classifiers"]}>
                                        <DataTagSelect
                                            businessAreaId={getFieldValue(["businessAreaId"])}
                                            disabled={this.isReadOnlyView()}
                                            mode="multiple"
                                            placeholder="Please select classifiers">
                                        </DataTagSelect>
                                    </Form.Item>
                                }
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="bottom-gap-15">
                        <Col span={24}>
                            <Form.Item
                                name={["uploadFile"]}
                                valuePropName="file"
                                noStyle>
                                <FileDragger
                                    disabled={this.isReadOnlyView()}
                                    onReadFileComplete={this.readFileComplete}
                                    onCancelUpload={this.cancelUpload}>
                                </FileDragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item shouldUpdate={true}>
                                {() => {
                                    let file = "";
                                    if (this.formRef.current) {
                                        let uploadFile = this.formRef.current.getFieldValue(["uploadFile"]);
                                        if (uploadFile) {
                                            file = parsePath(uploadFile.name).base;
                                        }
                                    }
                                    return file && <span style={{ color: "#037ffc" }}><FileTwoTone twoToneColor="#037ffc" /> {file}</span>
                                }}
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row>
                        {this.props.action !== "create" ? <Col span={8} >
                            <Form.Item
                                name={["dataUploadOptions", "uploadType"]}
                                className="no-bottom-margin"
                                initialValue={"REPLACE_DATA"}>
                                <Radio.Group
                                    disabled={this.isReadOnlyView() || !this.state.uploadFileExist}>
                                    <Space direction="vertical">
                                        <Radio value="REPLACE_DATA">Replace existing data in the dataset</Radio>
                                        <Radio value="APPEND_DATA">Append this data to the existing</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col> : <></>}
                        <Col span={8} >
                            <Form.Item
                                name={["dataUploadOptions", "firstRowHeader"]}
                                className="no-bottom-margin"
                                valuePropName="checked"
                                initialValue={true}>
                                <Checkbox disabled={this.isReadOnlyView() || !this.state.uploadFileExist}>First row is header</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className="footer-right-column">
                            <Space>
                                <Button onClick={this.props.onCancel}>Cancel</Button>
                                <Button type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()}>{this.state.uploadFileExist ? "Save and upload" : "Save"}</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                {this.props.businessAreaDomainDataResult && !this.props.businessAreaDomainDataResult.error ?
                    <DomainDataPreviewList
                        recordCount={this.props.businessAreaData.recordCount || 0}
                        domainDataList={this.props.businessAreaDomainDataResult && this.props.businessAreaDomainDataResult.domainDataResult ? this.props.businessAreaDomainDataResult.domainDataResult.Items : []}
                        loading={!this.props.businessAreaDomainDataResult || this.props.businessAreaDomainDataResult.inProgress ? { tip: "Loading data..." } : false}
                        tableHeight={0}
                        tableClasses={["container-height-100", "margin-top-small"]}
                        contentStyle={{ "overflow-y": "unset" }}
                        style={{ "overflow-y": "unset" }}
                        headerStyle={{ "height": "0px" }}
                        onPageSelect={this.onDomainDataPageSelect}>
                    </DomainDataPreviewList>
                    : <></>}
            </>
        );
    }
}

export default ManageBusinessAreaData