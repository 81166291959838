import React, { useState } from "react";
import { Layout, Row, Col, Table, Typography, Button, Flex, Progress, Tooltip, Result, Modal } from "antd";
import moment from 'moment';

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Column } = Table;

const headerStyle = {
    textAlign: 'left',
    color: '#fff',
    height: 50,
    paddingInline: 48,
    lineHeight: '50px',
    backgroundColor: '#1890FF',
};

const footerStyle = {
    background: "white",
    padding: "0px"
};

const TenantProvisionDetails = ({ provisionSteps, isTenantProvisioned, progress, onGoToHomeClick }) => {
    return (
        <>
            <Modal open={isTenantProvisioned} centered={true} footer={null} closable={false}>
                <Result
                    status="success"
                    title="Tenant provisioned successfully!"
                    subTitle="Your account is now active and ready to use."
                    extra={[
                        <Button type="primary" onClick={onGoToHomeClick}>Go to home</Button>
                    ]}
                />
            </Modal>
            <Layout style={{ height: "100%" }}>
                <Header style={headerStyle}><Title level={3} style={{ lineHeight: headerStyle.lineHeight, color: "#fff" }}>Tenant Provision</Title></Header>
                <Content>
                    <Row style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                        <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%" }}>
                            <Table dataSource={provisionSteps}>
                                <Column
                                    title="Resource"
                                    dataIndex="resource"
                                    key="resource"
                                    render={(name, record) => {
                                        switch (name) {
                                            case "ADMINUSER":
                                                return "Admin User";
                                            case "BUSINESSAREA":
                                                return "System Business Area";
                                            case "ROLESANDPERMISSIONS":
                                                return "System Roles and Permissions";
                                            case "TENANTADMIN":
                                                return "Account Admin";
                                        }
                                    }}
                                />
                                <Column
                                    title="Started At"
                                    dataIndex="createdDate"
                                    key="createdDate"
                                    render={(createdDate, record) => {
                                        let displayValue = null;
                                        if (createdDate) {
                                            displayValue = moment.utc(createdDate).toDate().toLocaleString();
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                                <Column
                                    title="Completed At"
                                    dataIndex="updatedDate"
                                    key="updatedDate"
                                    render={(updatedDate, record) => {
                                        let displayValue = null;
                                        if (updatedDate) {
                                            displayValue = moment.utc(updatedDate).toDate().toLocaleString();
                                            return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                        }
                                        return <></>;
                                    }}
                                />
                                <Column
                                    title="State"
                                    dataIndex="state"
                                    key="state"
                                    render={(state, record) => {
                                        switch (state) {
                                            case "CREATING":
                                                return "Creating";
                                            case "CREATED":
                                                return "Created";
                                            case "ERRORED":
                                                return "Errored";
                                        }
                                    }}
                                />
                            </Table>
                        </Col>
                    </Row>
                </Content>
                <Footer style={footerStyle}>
                    <Flex gap="small" vertical>
                        <Progress
                            className="tenant-provisioning-progress"
                            percent={isTenantProvisioned ? 100 : progress}
                            percentPosition={{
                                align: 'center',
                                type: 'inner',
                            }}
                            size={["100%", 20]}
                        />
                    </Flex>
                </Footer>
            </Layout>
        </>
    );
};

export default TenantProvisionDetails;