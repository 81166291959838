import React, { Component } from 'react';
import { connect } from "react-redux";
import { Dropdown } from 'semantic-ui-react';
import actions from 'store/actions';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";

class HomeBusinessAreaSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingBusinessAreas: true
        }
    }

    componentWillMount() {
        this.props.getUserBusinessAreas();
        this.props.getUserActiveBusinessArea();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getUserBusinessAreasResult && this.props.getUserBusinessAreasResult !== prevProps.getUserBusinessAreasResult) {
            if (!this.props.getUserBusinessAreasResult.success) {
                if (this.props.getUserBusinessAreasResult.code === "PERMISSION_DENIED") {
                    showError("Business Areas permission denied.");
                }
                else {
                    showError("Could not able to get Business Areas at this moment.");
                }
            }
            this.setState({
                fetchingBusinessAreas: false
            });
        }
    }

    render() {
        let businessAreaOptions = this.props.businessAreas.map(businessArea => {
            return { key: businessArea.businessAreaId, text: businessArea.name, value: businessArea.businessAreaId }
        });
        return <Dropdown
            placeholder='Select Business Area'
            openOnFocus
            closeOnBlur
            closeOnEscape
            closeOnChange
            selection
            options={businessAreaOptions}
            onChange={(e, { value }) => this.props.saveUserActiveBusinessArea(value)}
            value={this.props.activeBusinessAreaId}
            loading={this.state.fetchingBusinessAreas}
        />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreas: state.home.businessAreas,
        activeBusinessAreaId: state.home.activeBusinessAreaId,
        getUserBusinessAreasResult: state.home.getUserBusinessAreasResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreas: () => dispatch(actions.getUserBusinessAreasRequest()),
        getUserActiveBusinessArea: () => dispatch(actions.getUserActiveBusinessAreaRequest()),
        saveUserActiveBusinessArea: (businessAreaId) => dispatch(actions.saveUserActiveBusinessAreaRequest(businessAreaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeBusinessAreaSelect));