import {
    GET_EVENT_BUSINESS_AREA_CONNECTIONS_SUCCESS,
    GET_EVENT_BUSINESS_AREA_CONNECTIONS_FAILURE
} from "./actions";

const initialState = {
    getEventBusinessAreaConnectionsResult: {
        success: true
    },
    connectionsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    let connectionsResult = {
        Items: []
    };
    switch (action.type) {
        case GET_EVENT_BUSINESS_AREA_CONNECTIONS_SUCCESS:
            connectionsResult = action.payload.connectionsResult;
            return { ...state, getEventBusinessAreaConnectionsResult: { success: true }, connectionsResult };
        case GET_EVENT_BUSINESS_AREA_CONNECTIONS_FAILURE:
            return {
                ...state,
                getEventBusinessAreaConnectionsResult: { success: false, ...action.payload.error },
                connectionsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
