import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import {
    Handle,
    Position
} from 'reactflow';
import { DoubleRightOutlined, DoubleLeftOutlined, CaretDownFilled, FullscreenOutlined } from '@ant-design/icons';
import { FieldInsightProgress } from 'components';
import 'reactflow/dist/style.css';

const { Text, Link, Title } = Typography;

function LineageDataSetFieldNode({ data }) {
    return (
        <>
            {
                data.destination
                    ?
                    <Handle type="target" position={Position.Left} />
                    :
                    <></>
            }
            <Row style={{
                flexDirection: "row",
                flexWrap: false,
                padding: "0rem 0.5rem",
                width: "100%",
                height: "100%",
                alignItems: "stretch"
            }}>
                <Row style={{ width: "100%", display: "flex", flexWrap: "nowrap", columnGap: "5px" }}>
                    <Col style={{ alignSelf: "center" }}>
                        <Text style={{ fontSize: "8px" }}>{data.name}</Text>
                    </Col>
                    {
                        data.tags && data.tags.map(tag => <Col key={tag} style={{ alignSelf: "center" }}><Tag color="volcano" style={{ marginRight: "unset", lineHeight: "unset", fontSize: "0.8vh", height: "1.6vh", padding: "0 2px" }}>{tag}</Tag></Col>)
                    }
                    <Col style={{ flex: 1, flexWrap: "nowrap" }}>
                        <FieldInsightProgress
                            progressWidth="90%"
                            successDataCount={data.successDataCount}
                            erroredDataCount={data.erroredDataCount}
                        > </FieldInsightProgress>
                    </Col>

                </Row>
            </Row>
            {
                data.source
                    ?
                    <Handle type="source" position={Position.Right} />
                    :
                    <></>
            }
        </>
    );
}

export default LineageDataSetFieldNode;