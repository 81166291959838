import {
    GET_SCHEMA_MODEL_HISTORY_SUCCESS,
    GET_SCHEMA_MODEL_HISTORY_FAILURE,

    GET_SCHEMA_MODEL_EVENTS_SUCCESS,
    GET_SCHEMA_MODEL_EVENTS_FAILURE,

    GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_SUCCESS,
    GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_FAILURE
} from "./actions";

const initialState = {
    getSchemaModelHistoryResult: {
        success: true
    },
    schemaModelHistoryResult: {
        Items: []
    },
    getSchemaModelEventsResult: {
        success: true
    },
    schemaModelEventsResult: {
        Items: []
    },
    getSchemaModelVersionFieldRevisionsResult: {
        success: true
    },
    schemaModelVersionFieldsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHEMA_MODEL_HISTORY_SUCCESS:
            return { ...state, getSchemaModelHistoryResult: { success: true }, schemaModelHistoryResult: action.payload };
        case GET_SCHEMA_MODEL_HISTORY_FAILURE:
            return { ...state, getSchemaModelHistoryResult: { success: false, ...action.payload }, schemaModelHistoryResult: { Items: [] } };
        case GET_SCHEMA_MODEL_EVENTS_SUCCESS:
            return { ...state, getSchemaModelEventsResult: { success: true }, schemaModelEventsResult: action.payload };
        case GET_SCHEMA_MODEL_EVENTS_FAILURE:
            return {
                ...state, getSchemaModelEventsResult: { success: false, ...action.payload }, schemaModelEventsResult: {
                    Items: []
                }
            };
        case GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_SUCCESS:
            let schemaModelHistoryResult = state.schemaModelHistoryResult;
            let schemaModelRevisionIndex = schemaModelHistoryResult.Items && schemaModelHistoryResult.Items.findIndex(item => item.data.schemaId === action.payload.schemaId && item.metadata.version === action.payload.version);
            if (schemaModelRevisionIndex > -1) {
                schemaModelHistoryResult = { ...schemaModelHistoryResult };
                schemaModelHistoryResult.Items = [...schemaModelHistoryResult.Items];
                schemaModelHistoryResult.Items[schemaModelRevisionIndex] = {
                    ...schemaModelHistoryResult.Items[schemaModelRevisionIndex]
                }
                schemaModelHistoryResult.Items[schemaModelRevisionIndex].data.fieldResult = action.payload.fieldResult;
            }
            return {
                ...state,
                getSchemaModelVersionFieldRevisionsResult: {
                    success: true
                },
                schemaModelVersionFieldsResult: action.payload,
                schemaModelHistoryResult
            };
        case GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_FAILURE:
            return { ...state, getSchemaModelVersionFieldRevisionsResult: { success: false, ...action.payload }, schemaModelVersionFieldsResult: { Items: [] } };
        default:
            return state;
    }
};
