import {
    GET_EVENT_SYSTEM_CONNECTION_SUCCESS,
    GET_EVENT_SYSTEM_CONNECTION_FAILURE
} from "./actions";

const initialState = {
    getEventConnectionResult: {
        success: true
    },
    connectionRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_SYSTEM_CONNECTION_SUCCESS:
            return { ...state, getEventConnectionResult: { success: true }, connectionRevision: action.payload.connectionRevision };
        case GET_EVENT_SYSTEM_CONNECTION_FAILURE:
            return {
                ...state, getEventConnectionResult: { success: false, ...action.payload.error }, connectionRevision: null
            };
        default:
            return state;
    }
};
