export const GET_EVENT_SYSTEM_KEY_REQUEST = 'GET_EVENT_SYSTEM_KEY_REQUEST';
export const GET_EVENT_SYSTEM_KEY_SUCCESS = 'GET_EVENT_SYSTEM_KEY_SUCCESS';
export const GET_EVENT_SYSTEM_KEY_FAILURE = 'GET_EVENT_SYSTEM_KEY_FAILURE';

export const getEventSystemKeyRequest = (eventId, keyId) => ({
    type: GET_EVENT_SYSTEM_KEY_REQUEST,
    params: {
        eventId,
        keyId
    }
})

export const getEventSystemKeySuccess = (systemKeyRevision) => ({
    type: GET_EVENT_SYSTEM_KEY_SUCCESS,
    payload: {
        systemKeyRevision
    }
})

export const getEventSystemKeyFailure = (error) => ({
    type: GET_EVENT_SYSTEM_KEY_FAILURE,
    payload: {
        error
    }
})