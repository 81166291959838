import {
    GET_APP_CLIENT_EVENTS_SUCCESS,
    GET_APP_CLIENT_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getAppClientEventsResult: {
        success: true
    },
    appClientEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_APP_CLIENT_EVENTS_SUCCESS:
            return { ...state, getAppClientEventsResult: { success: true }, appClientEventsResult: action.payload.appClientEventResults };
        case GET_APP_CLIENT_EVENTS_FAILURE:
            return {
                ...state, getAppClientEventsResult: { success: false, ...action.payload.error }, appClientEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
