import React from 'react';
import { UserVerifyEmail } from 'containers';

const UserVerifyEmailPage = (props) => {
    return (
        <div className="flex-row-container">
            <div>
                <UserVerifyEmail {...props}></UserVerifyEmail>
            </div>
        </div>
    )
}

export default UserVerifyEmailPage