export const GET_EVENT_DATA_OBJECT_LIST_REQUEST = "GET_EVENT_DATA_OBJECT_LIST_REQUEST";
export const GET_EVENT_DATA_OBJECT_LIST_SUCCESS = "GET_EVENT_DATA_OBJECT_LIST_SUCCESS";
export const GET_EVENT_DATA_OBJECT_LIST_FAILURE = "GET_EVENT_DATA_OBJECT_LIST_FAILURE";

export const GET_EVENT_DATA_OBJECT_REQUEST = "GET_EVENT_DATA_OBJECT_REQUEST";
export const GET_EVENT_DATA_OBJECT_SUCCESS = "GET_EVENT_DATA_OBJECT_SUCCESS";
export const GET_EVENT_DATA_OBJECT_FAILURE = "GET_EVENT_DATA_OBJECT_FAILURE";

export const getEventDataObjectListRequest = (eventId, itemOperation, pageKey, eventKey) => ({
    type: GET_EVENT_DATA_OBJECT_LIST_REQUEST,
    params: {
        eventId,
        itemOperation,
        pageKey,
        eventKey
    }
})

export const getEventDataObjectListSuccess = (dataObjectResult) => ({
    type: GET_EVENT_DATA_OBJECT_LIST_SUCCESS,
    payload: dataObjectResult
})

export const getEventDataObjectListFailure = (error) => ({
    type: GET_EVENT_DATA_OBJECT_LIST_FAILURE,
    payload: error
})

export const getEventDataObjectRequest = (eventId, dataObjectId, eventKey) => ({
    type: GET_EVENT_DATA_OBJECT_REQUEST,
    params: {
        eventId,
        dataObjectId,
        eventKey
    }
})

export const getEventDataObjectSuccess = (dataObjectResult) => ({
    type: GET_EVENT_DATA_OBJECT_SUCCESS,
    payload: dataObjectResult
})

export const getEventDataObjectFailure = (error) => ({
    type: GET_EVENT_DATA_OBJECT_FAILURE,
    payload: error
})