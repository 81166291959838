import {
    GET_DATA_TAGS_SUCCESS,
    GET_DATA_TAGS_FAILURE,

    CREATE_DATA_TAG_SUCCESS,
    CREATE_DATA_TAG_FAILURE,

    GET_DATA_TAG_BY_ID_SUCCESS,
    GET_DATA_TAG_BY_ID_FAILURE,

    GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS,
    GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE,

    GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS,
    GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE,

    DELETE_DATA_TAG_SUCCESS,
    DELETE_DATA_TAG_FAILURE,

    UPDATE_DATA_TAG_SUCCESS,
    UPDATE_DATA_TAG_FAILURE,

    GET_USER_DATA_TAG_PERMISSION_SUCCESS,
    GET_USER_DATA_TAG_PERMISSION_FAILURE
} from "./actions";

const initialState = {
    getDataTagsResult: {
        success: true
    },
    dataTagsResult: {
        Items: []
    },
    createDataTagResult: {
        success: true
    },
    getDataTagByIdResult: {
        success: true
    },
    dataTag: null,
    getDataTagsByBusinessAreaIdResult: {
        success: true,
        businessAreaId: null
    },
    dataTagsByBusinessAreaIdResult: {
        Items: []
    },
    getUserBusinessAreaDataTagsResult: {
        success: true
    },
    userBusinessAreaDataTags: {
        Items: []
    },
    deleteDataTagResult: {
        success: true
    },
    updateDataTagResult: {
        success: true
    },
    getUserDataTagPermissionResult: {
        success: true
    },
    permission: null
};

export default (state = initialState, action) => {
    let dataTagsResult = {
        Items: []
    };
    switch (action.type) {
        case GET_DATA_TAGS_SUCCESS:
            dataTagsResult = action.payload.dataTagsResult;
            return { ...state, getDataTagsResult: { success: true }, dataTagsResult };
        case GET_DATA_TAGS_FAILURE:
            return {
                ...state, getDataTagsResult: { success: false, ...action.payload.error },
                dataTagsResult: {
                    Items: []
                }
            };
        case CREATE_DATA_TAG_SUCCESS:
            return { ...state, createDataTagResult: { success: true } };
        case CREATE_DATA_TAG_FAILURE:
            return { ...state, createDataTagResult: { success: false, ...action.payload.error } };
        case GET_DATA_TAG_BY_ID_SUCCESS:
            return { ...state, getDataTagByIdResult: { success: true }, dataTag: action.payload.dataTag };
        case GET_DATA_TAG_BY_ID_FAILURE:
            return { ...state, getDataTagByIdResult: { success: false, ...action.payload.error }, dataTag: null };
        case GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS:
            if (action.payload.result && action.payload.result.Items) {
                action.payload.result.ItemsDict = {};
                for (let item of action.payload.result.Items) {
                    action.payload.result.ItemsDict[item.tagId] = item;
                }
            }
            return { ...state, getDataTagsByBusinessAreaIdResult: { success: true, businessAreaId: action.payload.businessAreaId }, dataTagsByBusinessAreaIdResult: action.payload.result };
        case GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE:
            return { ...state, getDataTagsByBusinessAreaIdResult: { success: false, businessAreaId: action.payload.businessAreaId, ...action.payload.error }, dataTagsByBusinessAreaIdResult: { Items: [] } };
        case GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS:
            return { ...state, getUserBusinessAreaDataTagsResult: { success: true }, userBusinessAreaDataTags: action.payload.result };
        case GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE:
            return { ...state, getUserBusinessAreaDataTagsResult: { success: false, ...action.payload.error }, userBusinessAreaDataTags: { Items: [] } };
        case DELETE_DATA_TAG_SUCCESS:
            dataTagsResult = { ...state.dataTagsResult };
            dataTagsResult.Items = state.dataTagsResult.Items.filter(dataTag => dataTag.tagId !== action.payload.tagId);
            return { ...state, deleteDataTagResult: { success: true }, dataTagsResult };
        case DELETE_DATA_TAG_FAILURE:
            return { ...state, deleteDataTagResult: { success: false, ...action.payload.error } };
        case UPDATE_DATA_TAG_SUCCESS:
            return { ...state, updateDataTagResult: { success: true } };
        case UPDATE_DATA_TAG_FAILURE:
            return { ...state, updateDataTagResult: { success: false, ...action.payload.error } };
        case GET_USER_DATA_TAG_PERMISSION_SUCCESS:
            return { ...state, getUserDataTagPermissionResult: { success: true }, permission: action.payload.permission };
        case GET_USER_DATA_TAG_PERMISSION_FAILURE:
            return { ...state, getUserDataTagPermissionResult: { success: false, ...action.payload.error }, permission: null };
        default:
            return state;
    }
};
