import { LoadingOverlay, NaturalLanguageQueryViewer } from "components";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Action, Dispatch } from "redux";
import actions from "store/actions";
import AppState from "store/AppState";

const mapStateToProps = (state: AppState) => {
    return {
        updateSearchQueryResult: state.dataObjectSearch.updateSearchQueryResult,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<string>>) => {
    const updateSearchQuery: IUpdateSearchQueryRequest = (
        searchId,
        { question, query, properties, accuracy, expectedDSLQuery },
    ) => {
        dispatch(
            actions.updateSearchQueryRequest(searchId, {
                question,
                query,
                properties,
                accuracy,
                expectedDSLQuery,
            }),
        );
    };

    return {
        updateSearchQuery,
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type IPropsFromRedux = ConnectedProps<typeof connector>;

interface INaturalLanguageQueryProps extends IPropsFromRedux {
    naturalLanguageText?: string | null;
    dslQuery?: string;
    error?: string;
    searchId?: string;
    properties?: ISearchProperty[];
    onSearch?: (s: string) => void;
    onEditInBuilder?: (dsl: IDslCompoundQueryClause) => void;
}

function NaturalLanguageQuery({
    error,
    properties,
    naturalLanguageText,
    dslQuery,
    updateSearchQueryResult,
    searchId,
    onSearch,
    updateSearchQuery,
    onEditInBuilder,
}: INaturalLanguageQueryProps) {
    const [updatingSearchQuery, setUpdatingSearchQuery] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        setUpdatingSearchQuery(!updateSearchQueryResult);
        if (
            updateSearchQueryResult?.success &&
            updateSearchQueryResult?.expectedDSLQuery &&
            searchId
        ) {
            onSearch && onSearch(searchId);
        }
    }, [updateSearchQueryResult, searchId, onSearch]);

    const onSubmitQueryFeedback = React.useCallback(
        ({ accuracy }: { accuracy: unknown }) => {
            if (searchId) {
                updateSearchQuery(searchId, {
                    question: naturalLanguageText,
                    query: dslQuery,
                    properties: properties,
                    accuracy,
                });
            }
        },
        [
            dslQuery,
            naturalLanguageText,
            properties,
            searchId,
            updateSearchQuery,
        ],
    );

    const handleEditInQueryBuilder = React.useCallback(() => {
        if (onEditInBuilder && dslQuery) {
            onEditInBuilder(dslQuery as IDslCompoundQueryClause);
        }
    }, [dslQuery, onEditInBuilder]);

    return (
        <>
            {updatingSearchQuery && (
                <LoadingOverlay
                    busy={true}
                    message="Updating search..."
                ></LoadingOverlay>
            )}
            <NaturalLanguageQueryViewer
                naturalLanguageText={naturalLanguageText}
                dslQuery={dslQuery}
                error={error}
                showQueryEditor={properties ? true : false}
                onClickQueryFeedback={onSubmitQueryFeedback}
                onEditQuery={() => handleEditInQueryBuilder()}
            ></NaturalLanguageQueryViewer>
        </>
    );
}

export default connector(NaturalLanguageQuery);
