import React, { useState, useEffect } from 'react';
import { Form, DatePicker } from "antd";
import moment from 'moment';
import _ from 'lodash';
import { SchemaFieldTitle } from 'components';

const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const getValidDateValue = (value) => {
    let dateValue = value && moment(value, ISO_FORMAT);
    if (dateValue && dateValue.isValid() === false) {
        dateValue = null;
    }
    return dateValue;
}

const DateTimeInput = ({ value, onChange, format }) => {
    const [dateTimeValue, setDateTimeValue] = useState(getValidDateValue(value));

    useEffect(() => {
        setDateTimeValue(getValidDateValue(value));
    }, [value]);

    const onDateTimeValueChange = (date, dateString) => {
        setDateTimeValue(date);
        onChange(date && date.format(ISO_FORMAT));
    }

    return <DatePicker showTime value={dateTimeValue} onChange={onDateTimeValueChange} format={format} showToday={true} />
}

function FormDateTimeField({ fieldErrors, fieldId, name, description, max, min, isRequired, dateFormat, dateSeparator, timeFormat, ...field }) {
    const format = `${_.replace(dateFormat, /\$/g, dateSeparator)} ${timeFormat}`;

    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={<SchemaFieldTitle name={name} tags={field.classifiers} orientation="horizontal" tagSummary={false}></SchemaFieldTitle>}
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim()) {
                        let dateTimeValue = moment(value, ISO_FORMAT);
                        if (dateTimeValue.isValid() === false) {
                            return Promise.reject(`Value should be a valid datetime in format ${format}`);
                        }

                        if (min && dateTimeValue.isBefore(moment(min, ISO_FORMAT))) {
                            return Promise.reject(`Value should be greater than or equal to ${moment(min, ISO_FORMAT).format(format)}`);
                        }

                        if (max && dateTimeValue.isAfter(moment(max, ISO_FORMAT))) {
                            return Promise.reject(`Value should be less than or equal to ${moment(max, ISO_FORMAT).format(format)}`);
                        }
                    }

                    return Promise.resolve();
                },
            },
        ]}
        {...fieldErrors}>
        <DateTimeInput format={format} />
    </Form.Item>
}

export default FormDateTimeField