import { InfoCircleTwoTone } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Drawer, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Segment } from "semantic-ui-react";

type PageLayoutProps = {
    mainContent: (props: IPageLayoutMainContentProps) => React.ReactNode;
    helpContent?: () => React.ReactNode;
};

export default function PageLayout({
    mainContent,
    helpContent,
}: PageLayoutProps) {
    // Unused, to be removed. This is still here for backward compatibility.
    const setMainColumnSpan = (_unused: number) => null;

    const [breadcrumbNavigationItems, setBreadcrumbNavigationItems] = useState<
        IBreadcrumbItem[]
    >([]);

    const [showDrawer, setShowDrawer] = React.useState<boolean>(false);

    return (
        <Col
            id="page-layout-new"
            flex={1}
            style={{ display: "flex", flexDirection: "column" }}
        >
            {breadcrumbNavigationItems.length > 0 && (
                <Row
                    className="bread-crumbs-container"
                    style={{ minHeight: "30px" }}
                >
                    <Col flex="auto">
                        <Breadcrumb separator=">">
                            {breadcrumbNavigationItems.map((item, index) => (
                                <Breadcrumb.Item
                                    className="breadcrumb-item"
                                    key={`${index}_${item.text}`}
                                >
                                    {item.route &&
                                    index <
                                        breadcrumbNavigationItems.length - 1 ? (
                                        <Link to={item.route}>{item.text}</Link>
                                    ) : (
                                        <>{item.text}</>
                                    )}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Col>
                    {helpContent && (
                        <Col flex="none">
                            <Button
                                onClick={() => setShowDrawer(true)}
                                size="small"
                                icon={<InfoCircleTwoTone />}
                            >
                                How To Use
                            </Button>
                        </Col>
                    )}
                </Row>
            )}
            <Row
                id="page-layout-content-wrapper"
                style={{ flex: 1, height: "calc(100% - 30px)" }}
            >
                <Col
                    id="page-layout-content-area"
                    flex={1}
                    style={{ height: "100%" }}
                >
                    <Segment className="full-height-flex-container">
                        {mainContent({
                            setMainColumnSpan,
                            setBreadcrumbNavigationItems,
                        })}
                    </Segment>
                </Col>
                {helpContent && (
                    <Drawer
                        title="How To Use"
                        open={showDrawer}
                        onClose={() => setShowDrawer(false)}
                    >
                        <Segment className="full-height-flex-container">
                            {helpContent()}
                        </Segment>
                    </Drawer>
                )}
            </Row>
        </Col>
    );
}
