import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

export default function getDslExistClauseValue(
    context: IDslQueryContext,
    queryClause: IDslQueryExistClause,
    fields: ISearchProperty[],
): RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> {
    const rules: RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> = [];

    let operator = "notNull"; // Defaults to notNull for all context
    if (context === "must_not") {
        operator = "null";
    }

    if (queryClause.field && fields.some((f) => f.name === queryClause.field)) {
        rules.push({
            id: uuidv4(),
            field: queryClause.field,
            operator: operator,
            valueSource: "value",
            value: "",
        });
    }

    return rules;
}
