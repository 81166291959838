export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_REQUEST = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_REQUEST";
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_SUCCESS = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_SUCCESS";
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_FAILURE = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_FAILURE";

export const getDuplicateDataObjectsResultSetSummaryRequest = (jobId, clusterSummary) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_REQUEST,
    params: {
        jobId,
        clusterSummary
    }
})

export const getDuplicateDataObjectsResultSetSummarySuccess = ({ jobId, result }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_SUCCESS,
    payload: {
        jobId,
        result
    }
})

export const getDuplicateDataObjectsResultSetSummaryFailure = ({ jobId, error }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_FAILURE,
    payload: {
        jobId,
        error
    }
})
