import { Modal } from "antd";
import {
    ElasticSearchQueryBuilder,
    FullHeightContainerLayout,
    LoadingOverlay,
} from "components";

export default function SearchQueryBuilderModal({
    isBusy = false,
    properties,
    open,
    onCancel,
    onUpdateQuery,
    onOk,
    okText = "Search",
}: ISearchQueryBuilderModalProps) {
    return (
        <Modal
            title={"Search query builder"}
            width={"75%"}
            styles={{
                body: {
                    minHeight: "25vh",
                    ...(isBusy ? { padding: "unset" } : {}),
                },
            }}
            destroyOnClose={true}
            closable={false}
            open={open}
            centered={true}
            okText={okText}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={onOk}
        >
            {isBusy ? (
                <LoadingOverlay
                    busy={true}
                    message="Please wait..."
                ></LoadingOverlay>
            ) : (
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={false}
                    content={
                        <ElasticSearchQueryBuilder
                            properties={properties}
                            onUpdateQuery={onUpdateQuery}
                            onSubmit={() => null}
                        />
                    }
                ></FullHeightContainerLayout>
            )}
        </Modal>
    );
}
