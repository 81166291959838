export default {
    "FORGOT_YOUR_PASSWORD": "Forgot Your Password?",
    "NEW_CUSTOMER": "New Customer",
    "LOGON_EMAIL": "Logon Email",
    "LOGIN": "Login",
    "CORPORATE_EMAIL_ADDRESS": "Corporate Email Address",
    "CORPORATE_EMAIL_ADDRESS_PLACE_HOLDER": "yourname@company.com",
    "FIRST_NAME": "First Name",
    "FIRST_NAME_PLACE_HOLDER": "Your First Name",
    "LAST_NAME": "Last Name",
    "LAST_NAME_PLACE_HOLDER": "Your Last Name",
    "AGREE_TO_TERMS_AND_CONDITIONS_OF_USE": "Agree to Terms and Conditions of Use",
    "SEND_VERIFICATION_EMAIL": "Send Verification Email",
    "PASSWORD": "Password"
}