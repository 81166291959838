export const GET_EVENT_ROLE_REQUEST = "GET_EVENT_ROLE_REQUEST";
export const GET_EVENT_ROLE_SUCCESS = "GET_EVENT_ROLE_SUCCESS";
export const GET_EVENT_ROLE_FAILURE = "GET_EVENT_ROLE_FAILURE";

export const getEventRoleRequest = (eventId, roleId) => ({
    type: GET_EVENT_ROLE_REQUEST,
    params: {
        eventId,
        roleId
    }
})

export const getEventRoleSuccess = (roleRevision) => ({
    type: GET_EVENT_ROLE_SUCCESS,
    payload: roleRevision
})

export const getEventRoleFailure = (error) => ({
    type: GET_EVENT_ROLE_FAILURE,
    payload: error
})