import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { UserHistoryList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class EventUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        if (!this.props.itemOperation) {
            this.setState({
                busy: true,
                busyMessage: "Getting user...",
            });
            this.props.getEventUser(this.props.eventId, this.props.userId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventUserResultUpdate(prevProps);
    }

    onGetEventUserResultUpdate = (prevProps) => {
        if (this.props.getEventUserResult && this.props.getEventUserResult !== prevProps.getEventUserResult) {
            if (!this.props.getEventUserResult.success) {
                if (this.props.getEventUserResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get user at this moment.");
                }
            }
            else {
                this.setState({
                    eventListHeight: 36
                })
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Revision - </Text><Text type="secondary" italic>{`(User: ${this.props.userEmail})`}</Text></Title>}
                            content={this.props.userRevision ?
                                <UserHistoryList
                                    userRevisions={[this.props.userRevision]}>
                                </UserHistoryList>
                                :
                                <Empty></Empty>}>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventUserResult: state.eventUser.getEventUserResult,
        userRevision: state.eventUser.userRevision
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventUser: (eventId, userId) => dispatch(actions.getEventUserRequest(eventId, userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventUser));