export const GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_REQUEST = "GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_REQUEST";
export const GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_SUCCESS = "GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_SUCCESS";
export const GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_FAILURE = "GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_FAILURE";

export const getNominatedDataObjectMergePreviewRequest = (duplicateSetId, duplicateSetVersion, groupId) => ({
    type: GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_REQUEST,
    params: {
        duplicateSetId,
        duplicateSetVersion,
        groupId
    }
})

export const getNominatedDataObjectMergePreviewSuccess = ({ record, schema }) => ({
    type: GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_SUCCESS,
    payload: {
        record,
        schema
    }
})

export const getNominatedDataObjectMergePreviewFailure = (error) => ({
    type: GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_FAILURE,
    payload: {
        error
    }
})
