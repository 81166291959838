
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventAppClient(api, eventId, clientId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/appclients/${clientId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventAppClientRequest(api, { eventId, clientId }) {
    try {
        const appClientRevision = yield call(getEventAppClient, api, eventId, clientId);
        yield put(actions.getEventAppClientSuccess(appClientRevision));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event credential details");
        yield put(actions.getEventAppClientFailure(errorObject));
    }
}

export function* watchGetEventAppClientRequest(api, { params }) {
    yield call(getEventAppClientRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_APP_CLIENT_REQUEST, watchGetEventAppClientRequest, api);
}