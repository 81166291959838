export const GET_EVENT_DATA_OBJECT_VERIFICATIONS_REQUEST = "GET_EVENT_DATA_OBJECT_VERIFICATIONS_REQUEST";
export const GET_EVENT_DATA_OBJECT_VERIFICATIONS_SUCCESS = "GET_EVENT_DATA_OBJECT_VERIFICATIONS_SUCCESS";
export const GET_EVENT_DATA_OBJECT_VERIFICATIONS_FAILURE = "GET_EVENT_DATA_OBJECT_VERIFICATIONS_FAILURE";

export const getEventDataObjectVerificationsRequest = (eventId) => ({
    type: GET_EVENT_DATA_OBJECT_VERIFICATIONS_REQUEST,
    params: {
        eventId
    }
})

export const getEventDataObjectVerificationsSuccess = (result) => ({
    type: GET_EVENT_DATA_OBJECT_VERIFICATIONS_SUCCESS,
    payload: {
        result
    }
})

export const getEventDataObjectVerificationsFailure = (error) => ({
    type: GET_EVENT_DATA_OBJECT_VERIFICATIONS_FAILURE,
    payload: {
        error
    }
})