import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { SchemaModels, DataSets } from 'containers';
import { PageLayout } from 'components';
import { useHistory } from "react-router-dom";

function usePreviousAction(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const SchemaPage = (props) => {
    const history = useHistory();
    const { tenant, businessarea, schemaAction, schemaId } = useParams();
    const prevSchemaAction = usePreviousAction(schemaAction);
    const [showDataSets, setShowDataSets] = useState(false);
    const [schemaModelDisplaySize, setSchemaModelDisplaySize] = useState("full");
    const [selectedSchemaModelInfo, setSelectedSchemaModelInfo] = useState({ schemaModel: null, userBusinessArea: null });

    const onSchemaModelListLoaded = () => {
        setShowDataSets(true);
        if (!schemaAction) {
            setSchemaModelDisplaySize("half");
        }
    }
    const onSchemaModelListUnLoaded = () => {
        setShowDataSets(false);
        setSchemaModelDisplaySize("full");
        setSelectedSchemaModelInfo({ schemaModel: null, userBusinessArea: null });
    }

    const onSchemaModelSelected = ({ schemaModel, userBusinessArea }) => {
        history.push(`#${schemaModel.name}`);
        setSelectedSchemaModelInfo({ schemaModel, userBusinessArea });
    }

    return (
        <PageLayout
            key={`${businessarea}_schema_page`}
            mainContent={({ setMainColumnSpan, setBreadcrumbNavigationItems }) => {
                return <>
                    <SchemaModels
                        key={`${businessarea}_${schemaAction || "default"}_schema_model`}
                        setMainColumnSpan={setMainColumnSpan}
                        setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}
                        tenant={tenant}
                        businessArea={businessarea.toLowerCase()}
                        action={schemaAction}
                        prevAction={prevSchemaAction}
                        schemaId={schemaId}
                        onSchemaModelListLoaded={onSchemaModelListLoaded}
                        onSchemaModelListUnLoaded={onSchemaModelListUnLoaded}
                        displaySize={schemaModelDisplaySize}
                        onSchemaModelSelected={onSchemaModelSelected}></SchemaModels>
                    {showDataSets
                        ?
                        <DataSets
                            key={`${businessarea}_${(selectedSchemaModelInfo.schemaModel && selectedSchemaModelInfo.schemaModel.schemaId) || "default"}_schema_datasets`}
                            selectedSchemaModel={selectedSchemaModelInfo.schemaModel}
                            userBusinessArea={selectedSchemaModelInfo.userBusinessArea}
                            tenant={tenant}
                            businessArea={businessarea.toLowerCase()}
                            displaySize="half">
                        </DataSets>
                        :
                        <></>}
                </>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default SchemaPage
