import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { ChangeDataCaptureList, LoadingOverlay, ProhibitedArea, ManageSystemConnectionAwsS3, OkCancelConfirmationBox, ManageDataCaptureDestinations } from "components";
import { SystemConnectionAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col } from "antd";

class ChangeDataCaptures extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            permissionDenied: false,
            fetchingPermission: true,
            manageCDCDestinationErrors: {}
        }
    }

    componentWillMount() {
        this.props.getCDCDestinationPermission();
        this.showBreadCrumbNavigationItems();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting destinations...",
                fetchingCDCDestinations: true
            });
            this.props.getCDCDestinations();
        }
        else {
            if (this.props.destinationId) {
                this.props.getCDCDestination(this.props.destinationId);
            }
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting destination...",
                    fetchingDestination: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingDestination: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.onGetCDCDestinationsResultChanged(prevProps);

        this.onCreateCDCDestinationResultChanged(prevProps);

        this.onGetCDCDestinationResultChanged(prevProps);

        this.onUpdateCDCDestinationResultChanged(prevProps);

        this.onDeleteCDCDestinationResultChanged(prevProps);

        this.onGetCDCDestinationPermissionResultUpdate(prevProps);
    }

    onGetCDCDestinationsResultChanged = (prevProps) => {
        if (this.props.getCDCDestinationsResult && this.props.getCDCDestinationsResult !== prevProps.getCDCDestinationsResult) {
            if (!this.props.getCDCDestinationsResult.success) {
                if (this.props.getCDCDestinationsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get items at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingCDCDestinations: false
            });
        }
    }

    onCreateCDCDestinationResultChanged = (prevProps) => {
        if (this.props.createCDCDestinationResult && this.props.createCDCDestinationResult !== prevProps.createCDCDestinationResult) {
            if (!this.props.createCDCDestinationResult.success) {
                showError(this.props.createCDCDestinationResult.message || "Destination cannot be created at this moment.");
                this.setState({
                    busy: false,
                    manageCDCDestinationErrors: this.props.createCDCDestinationResult.fields
                });
            }
            else {
                showSuccess("Change data capture destination created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }
    }

    onGetCDCDestinationResultChanged = (prevProps) => {
        if (this.props.getCDCDestinationResult && this.props.getCDCDestinationResult !== prevProps.getCDCDestinationResult) {
            if (!this.props.getCDCDestinationResult.success) {
                if (this.props.getCDCDestinationResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get destination.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingDestination: false
            });
            if (this.props.cdcDestinationListResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateCDCDestinationResultChanged = (prevProps) => {
        if (this.props.updateCDCDestinationResult && this.props.updateCDCDestinationResult !== prevProps.updateCDCDestinationResult) {
            if (!this.props.updateCDCDestinationResult.success) {
                if (this.props.updateCDCDestinationResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    this.setState({
                        busy: false,
                        manageCDCDestinationErrors: this.props.updateCDCDestinationResult.fields
                    });
                    showError("Could not able to update destination.");
                }
            }
            else {
                if (this.state.isActivateDeactivateConnection) {
                    showSuccess(`Connection ${this.state.connectionActive ? "activated" : "deactivated"} successfully.`);
                    this.setState({
                        busy: false,
                        isActivateDeactivateConnection: false,
                        connectionActive: false
                    });
                }
                else {
                    this.setState({
                        busyMessage: "Destination updated. Please wait..."
                    });
                    showSuccess("Destination updated successfully.", () => {
                        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                    });
                }
            }
        }
    }

    onDeleteCDCDestinationResultChanged = (prevProps) => {
        if (this.props.deleteCDCDestinationResult && this.props.deleteCDCDestinationResult !== prevProps.deleteCDCDestinationResult) {
            if (!this.props.deleteCDCDestinationResult.success) {
                showError(this.props.deleteCDCDestinationResult.message || "Destination cannot be deleted.");
            }
            else {
                showSuccess("Destination deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onGetCDCDestinationPermissionResultUpdate = (prevProps) => {
        if (this.props.getCDCDestinationPermissionResult && this.props.getCDCDestinationPermissionResult !== prevProps.getCDCDestinationPermissionResult) {
            if (!this.props.getCDCDestinationPermissionResult.success || !this.props.permission || !this.props.permission.canView) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }


    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Change data capture destinations"
                }
            ];
            if (this.props.action) {
                let connectionName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New change data capture destination"
                        });
                        break;
                    case "view":
                    case "edit":
                        connectionName = this.getDestinationName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: connectionName
                        }]);
                        break;
                    case "history":
                        connectionName = this.getDestinationName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: connectionName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getDestinationName = () => {
        let name = this.props.destinationId;
        if (this.props.cdcDestinationListResult.Items && this.props.cdcDestinationListResult.Items.length > 0) {
            let destination = this.props.cdcDestinationListResult.Items.find(item => item.destinationId === this.props.destinationId);
            if (destination) {
                name = destination.name;
            }
        }
        else if (this.props.cdcDestination && this.props.cdcDestination.destinationId === this.props.destinationId) {
            name = this.props.cdcDestination.name;
        }
        return name;
    }

    showAddCDCDestination = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    showEditCDCDestination = (destinationId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", destinationId)
            .replace(":itemAction", "edit"));
    }

    showViewCDCDestination = (destinationId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", destinationId)
            .replace(":itemAction", "view"));
    }

    showConnectionHistory = (connectionId, connectionType) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", connectionId)
            .replace(":itemAction", "history"));
    }

    goBackToDestinationList = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    createDestination = (destination) => {
        this.setState({
            busy: true,
            busyMessage: "Creating destination..."
        });
        this.props.createCDCDestination(destination);
    }

    updateDestination = (destinationId, updatedDestination) => {
        if (_.isEmpty(updatedDestination) === false) {
            this.setState({
                busy: true,
                busyMessage: "Updating destination..."
            });
            this.props.updateCDCDestination(destinationId, updatedDestination);
        }
        else {
            this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
        }
    }

    onActivateDeactivateConnection = (connection, active, connectionType) => {
        if (connectionType === "aws_s3") {
            this.setState({
                isActivateDeactivateConnection: true,
                connectionActive: active,
                busy: true,
                busyMessage: active ? "Activating connection..." : "Deactivating connection..."
            });
            this.props.updateAwsS3Connection(connection.connectionId, { version: connection.version, active });
        }
    }

    onManageCDCDestinationValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageCDCDestinationErrors) === false) {
            let manageCDCDestinationErrors = { ...this.state.manageCDCDestinationErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageCDCDestinationErrors[key];
            }
            this.setState({
                manageCDCDestinationErrors
            })
        }
    }

    showDeleteDestinationConfirmation = (destination) => {
        this.setState({
            deletedDestination: destination,
            showDestinationDeleteConfirmation: true,
            deleteDestinationConfirmMessage: `Are you sure, you want to delete '${destination.name}' destination?`
        })
    }

    onDeleteDestinationConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting destination..."
        });
        this.props.deleteCDCDestination(this.state.deletedDestination.destinationId);
        this.onDeleteDestinationCancel();
    }

    onDeleteDestinationCancel = () => {
        this.setState({
            deletedDestination: null,
            showDestinationDeleteConfirmation: false,
            deleteDestinationConfirmMessage: ""
        })
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingCDCDestinations || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingDestination;
        }
        return isBusy;
    }

    getComponentContent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return (
                <>
                    <OkCancelConfirmationBox
                        show={this.state.showDestinationDeleteConfirmation}
                        message={this.state.deleteDestinationConfirmMessage}
                        onCancel={this.onDeleteDestinationCancel}
                        onOk={this.onDeleteDestinationConfirmed}>
                    </OkCancelConfirmationBox>
                    {
                        !this.state.fetchingCDCDestinations
                            ?
                            <ChangeDataCaptureList
                                permission={this.props.permission}
                                items={this.props.cdcDestinationListResult.Items}
                                onAddCDCDestination={this.showAddCDCDestination}
                                onViewCDCDestination={this.showViewCDCDestination}
                                onEditCDCDestination={this.showEditCDCDestination}
                                onDeleteCDCDestination={this.showDeleteDestinationConfirmation}
                                onActivateDeactivateConnection={this.onActivateDeactivateConnection}
                                onViewConnectionHistory={this.showConnectionHistory}
                            />
                            :
                            <></>
                    }
                </>
            );
        }

        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageDataCaptureDestinations
                    action={this.props.action}
                    permission={this.props.permission}
                    formErrors={this.state.manageCDCDestinationErrors}
                    onValuesChanged={this.onManageCDCDestinationValuesChanged}
                    onSave={this.createDestination}
                    onCancel={this.goBackToDestinationList}>
                </ManageDataCaptureDestinations>;
            case "view":
            case "edit":
                return !this.state.fetchingDestination
                    ?
                    (this.props.cdcDestination
                        ?
                        <>
                            <OkCancelConfirmationBox
                                show={this.state.showRegenerateSecretConfirmation}
                                message={this.state.regenerateSecretConfirmationMessage}
                                onCancel={this.onRegenerateSecretCancel}
                                onOk={this.onRegenerateSecretConfirmed}>
                            </OkCancelConfirmationBox>
                            <ManageDataCaptureDestinations
                                action={this.props.action}
                                permission={this.props.permission}
                                formErrors={this.state.manageCDCDestinationErrors}
                                cdcDestination={this.props.cdcDestination}
                                onValuesChanged={this.onManageCDCDestinationValuesChanged}
                                onSave={this.updateDestination}
                                onCancel={this.goBackToDestinationList}>
                            </ManageDataCaptureDestinations>;
                        </>
                        :
                        (this.props.getCDCDestinationResult.success
                            ?
                            <Message floating size='big'>
                                <Message.Header>Destination not found.</Message.Header>
                                <p>The destination you are looking for does not exist.</p>
                            </Message>
                            :
                            <></>
                        )
                    )
                    :
                    <></>;
            case "history":
                return this.state.showHistory ? <SystemConnectionAudit connection={this.props.cdcDestination} onClose={this.goBackToDestinationList}></SystemConnectionAudit> : <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.state.fetchingPermission ? this.getComponentContent() : <></>}
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cdcDestinationListResult: state.changeDataCapture.cdcDestinationListResult,
        getCDCDestinationsResult: state.changeDataCapture.getCDCDestinationsResult,

        createCDCDestinationResult: state.changeDataCapture.createCDCDestinationResult,
        createdCDCDestination: state.changeDataCapture.createdCDCDestination,

        getCDCDestinationResult: state.changeDataCapture.getCDCDestinationResult,
        cdcDestination: state.changeDataCapture.cdcDestination,

        updateCDCDestinationResult: state.changeDataCapture.updateCDCDestinationResult,

        deleteCDCDestinationResult: state.changeDataCapture.deleteCDCDestinationResult,

        getCDCDestinationPermissionResult: state.changeDataCapture.getCDCDestinationPermissionResult,
        permission: state.changeDataCapture.userCDCDestinationPermission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCDCDestinationPermission: () => dispatch(actions.getCDCDestinationPermissionRequest()),
        getCDCDestinations: () => dispatch(actions.getCDCDestinationsRequest()),
        createCDCDestination: (cdcDestination) => dispatch(actions.createCDCDestinationRequest(cdcDestination)),
        getCDCDestination: (cdcDestinationId) => dispatch(actions.getCDCDestinationRequest(cdcDestinationId)),
        updateCDCDestination: (cdcDestinationId, updatedCDCDestination) => dispatch(actions.updateCDCDestinationRequest(cdcDestinationId, updatedCDCDestination)),
        deleteCDCDestination: (cdcDestinationId) => dispatch(actions.deleteCDCDestinationRequest(cdcDestinationId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangeDataCaptures));