/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_C7Zl7OTI1",
    "aws_user_pools_web_client_id": "61junkfaas0egho45e9q71uljj",
    "oauth": {
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.pretectum.com/",
        "redirectSignOut": "http://localhost:3000/,https://www.pretectum.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "Auth": {
        "region": "us-east-2",
        "userPoolId": "us-east-2_C7Zl7OTI1",
        "userPoolWebClientId": "61junkfaas0egho45e9q71uljj",
        "identityPoolId": "us-east-2:4f8d7c88-8890-4de1-9c00-d9b635199ee8",
        "mandatorySignIn": true
    },
    "Storage": {
        "bucket": process.env.REACT_APP_BUCKET_NAME,
        "region": process.env.REACT_APP_BUCKET_REGION,
        "identityPoolId": process.env.REACT_APP_IDENTITY_POOL_ID
    }
};


export default awsmobile;
