export default Object.freeze(
    {
        "integer": {
            name: "Integer",
            min: Number.MIN_SAFE_INTEGER,
            max: Number.MAX_SAFE_INTEGER
        },
        "float": {
            name: "Float",
            min: Number.MIN_SAFE_INTEGER,
            max: Number.MAX_SAFE_INTEGER
        },
        "string": {
            name: "String",
            min: 1,
            max: 500
        },
        "boolean": {
            name: "Boolean",
            min: 1,
            max: 1
        },
        "datetime": {
            name: "Datetime",
            min: 8,
            max: 50
        },
        "date": {
            name: "Date",
            min: 4,
            max: 50
        },
        "time": {
            name: "Time",
            min: 5,
            max: 50,
        },
        "date-year": {
            name: "Date-Year",
            min: 1970,
            max: 9999
        },
        "date-month": {
            name: "Date-Month",
            min: 1,
            max: 12
        },
        "date-day": {
            name: "Date-Day",
            min: 1,
            max: 31
        },
        "currency": {
            name: "Currency",
            min: -1000000000.00, //-10 billion
            max: 1000000000.00 //10 billion
        },
        "picklist": {
            name: "Picklist",
            min: 1,
            max: 200
        },
        "email": {
            name: "Email",
            min: 3,
            max: 320
        },
        "url": {
            name: "URL",
            min: 2,
            max: 2048
        },
        "phone": {
            name: "Phone",
            min: 4,
            max: 20,
            phoneFormats: [
                {
                    name: "+1 AAAA BBB BBB (E.123 International)",
                    value: "E.123",
                    expression: "^[\+][0-9]{1,3}[SEPARATERS]?[0-9]{1,4}[SEPARATERS]?[0-9]{3}[SEPARATERS]?[0-9]{3}$"
                },
                {
                    name: "0 AAAA BBBBBB (DIN5008)",
                    value: "DIN5008",
                    expression: "^[0][SEPARATERS]?[0-9]{1,4}[SEPARATERS]?[0-9]{6}$"
                },
                {
                    name: "0 AAAA BBB BBBXX (DIN5008 with Ext)",
                    value: "DIN5008EXT",
                    expression: "^[0][SEPARATERS]?[0-9]{1,4}[SEPARATERS]?[0-9]{3}[SEPARATERS]?[0-9]{5}$"
                },
                {
                    name: "+1 AAAA BBBBBB (DIN5008 International)",
                    value: "DIN5008INTERNATIONAL",
                    expression: "^[\+][0-9]{1,3}[SEPARATERS]?[0-9]{1,4}[SEPARATERS]?[0-9]{6}$"
                },
                {
                    name: "1 (NPA) NXX XXXX (NPA)",
                    value: "NPA",
                    expression: "^[0-9]{1,3}[SEPARATERS]?[(][0-9]{1,4}[)][SEPARATERS]?[0-9]{3}[SEPARATERS]?[0-9]{4}$"
                }
            ]
        },
        "latlng": {
            name: "GeoCoordinates",
            min: 2,
            max: 2,
            latitude: {
                min: -90,
                max: 90
            },
            longitude: {
                min: -180,
                max: 180
            },
            precision: 4,
            format: "decimal",
            separator: ",",
            expression: "^(-?\\d{1,2}(\\.\\d{1,8})?),\\s*(-?\\d{1,3}(\\.\\d{1,8})?)$"
        }
    });