
import { put, call, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import _ from 'lodash';
import errorHandler from 'common/errorHandler';

export function* signupNewUser(api, { user }) {
    try {
        const signUpUser = {
            username: user.email,
            password: user.password,
            attributes: {
                email: user.email,
                name: user.firstName,
                family_name: user.lastName
            }
        }
        let status = yield call([Auth, Auth.signUp], signUpUser);
        yield put(actions.signUpNewUserSuccess({ code: "success", message: status }));
    } catch (error) {
        if (error.code && error.code === "UserLambdaValidationException" && error.message && error.message.indexOf("TENANT_ALREADY_EXIST") > -1) {
            yield put(actions.signUpNewUserFailure({ code: "TENANT_ALREADY_EXIST", message: "Tenant already exist", fields: { "email": "Tenant already exist with the given email domain." } }));
            return;
        }

        let errorObject = errorHandler(error, "Signup", "new user");
        if (error.code === "UsernameExistsException") {
            if (!errorObject.fields) {
                errorObject.fields = {};
            }
            errorObject.fields["email"] = "Email already exist.";
        }
        else if (error.code === "InvalidPasswordException") {
            if (!errorObject.fields) {
                errorObject.fields = {};
            }
            errorObject.fields["password"] = error.message;
        }
        yield put(actions.signUpNewUserFailure(errorObject));
    }
}


export function* watchSignupNewUserRequest(api, { params }) {
    yield call(signupNewUser, api, params)
}

export default function* ({ api }) {
    yield takeEvery(actions.SIGN_UP_NEW_USER_REQUEST, watchSignupNewUserRequest, api);
}
