import react from 'react';
import { Row, Col, Tree } from 'antd';

const BusinessAreaSchemaTree = ({ treeData, expandedNodes, onSchemaSelect, selectedNodes, onNodeExpand }) => {
    return <Tree
        onCheck={onSchemaSelect}
        checkedKeys={selectedNodes}
        checkable
        expandedKeys={expandedNodes}
        showIcon={true}
        treeData={treeData}
        onExpand={(expandedKeys, { expanded, node }) => onNodeExpand(expandedKeys)}
    />
}

export default BusinessAreaSchemaTree