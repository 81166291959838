import React from 'react';
import { Form, InputNumber } from "antd";
import { SchemaFieldTitle } from 'components';

function FormCurrencyField({ fieldErrors, fieldId, name, description, max, min, isRequired, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={<SchemaFieldTitle name={name} tags={field.classifiers} orientation="horizontal" tagSummary={false}></SchemaFieldTitle>}
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim()) {
                        let currencyValue = parseFloat(value);
                        if (isNaN(currencyValue)) {
                            return Promise.reject(`Value should be a valid currency value`);
                        }
                    }

                    return Promise.resolve();
                }
            },
            {
                type: "number",
                min: min,
                message: `Value should be greater than or equal to ${min}`
            },
            {
                type: "number",
                max: max,
                message: `Value should be less than or equal to ${max}`
            }
        ]}
        {...fieldErrors}>
        <InputNumber min={min} max={max} precision={2} step={0.01} />
    </Form.Item>
}

export default FormCurrencyField