export const GET_EVENT_JOB_REQUEST = "GET_EVENT_JOB_REQUEST";
export const GET_EVENT_JOB_SUCCESS = "GET_EVENT_JOB_SUCCESS";
export const GET_EVENT_JOB_FAILURE = "GET_EVENT_JOB_FAILURE";

export const getEventJobRequest = (eventId, jobId) => ({
    type: GET_EVENT_JOB_REQUEST,
    params: {
        eventId,
        jobId
    }
})

export const getEventJobSuccess = (jobRevision) => ({
    type: GET_EVENT_JOB_SUCCESS,
    payload: {
        jobRevision
    }
})

export const getEventJobFailure = (error) => ({
    type: GET_EVENT_JOB_FAILURE,
    payload: {
        error
    }
})