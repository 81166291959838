import {
    GET_DATA_DOMAIN_HISTORY_SUCCESS,
    GET_DATA_DOMAIN_HISTORY_FAILURE,

    GET_DATA_DOMAIN_EVENTS_SUCCESS,
    GET_DATA_DOMAIN_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getDataDomainHistoryResult: {
        success: true
    },
    dataDomainHistoryResult: {
        Items: []
    },
    getDataDomainEventsResult: {
        success: true
    },
    dataDomainEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_DOMAIN_HISTORY_SUCCESS:
            return { ...state, getDataDomainHistoryResult: { success: true }, dataDomainHistoryResult: action.payload };
        case GET_DATA_DOMAIN_HISTORY_FAILURE:
            return { ...state, getDataDomainHistoryResult: { success: false, ...action.payload }, dataDomainHistoryResult: { Items: [] } };
        case GET_DATA_DOMAIN_EVENTS_SUCCESS:
            return { ...state, getDataDomainEventsResult: { success: true }, dataDomainEventsResult: action.payload };
        case GET_DATA_DOMAIN_EVENTS_FAILURE:
            return {
                ...state, getDataDomainEventsResult: { success: false, ...action.payload }, dataDomainEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
