import React, { useState } from "react";
import { Card, Tag, Modal, Checkbox, Typography, Tooltip, Input, Space } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout, BatchProcessResult, LoadingOverlay } from 'components';
import moment from "moment";
import validator from 'validator';

const { CheckableTag } = Tag;
const { Text, Link } = Typography;

const DataObjectRequestVerificationModal = ({ recordId, visible, onCancel, onOk, schemaFields, dataObject, busy, showConfirm }) => {

    const [selectedFields, setSelectedFields] = useState(schemaFields.filter(field => {
        if (field.dataType === "email" ||
            field.dataType === "phone" ||
            field.name.toLowerCase().indexOf("email") > -1 ||
            field.name.toLowerCase().indexOf("phone") > -1 ||
            (field.classifiers && field.classifiers.find(tag => tag.tagName === "email" || tag.tagName === "phone"))) {
            return true;
        }
        return false;
    }).map(field => field.fieldId));
    const [consentChecked, setConsentChecked] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const [confirmError, setConfirmError] = useState(null)
    const [confirmText, setConfirmText] = useState(null);

    const handleChange = (fieldId, checked) => {
        setValidationError(null);
        const nextSelectedFields = checked ? [...selectedFields, fieldId] : selectedFields.filter((t) => t !== fieldId);
        setSelectedFields(nextSelectedFields);
    };

    const onConsentChange = (e) => {
        setConsentChecked(e.target.checked);
    };

    const onOkClick = () => {
        if (showConfirm && confirmText !== "CONFIRM") {
            setConfirmError("error");
            return
        }

        setConfirmError(null);
        let isEmailFieldSelected = false;
        const fieldsDict = {};
        for (let field of schemaFields) {
            fieldsDict[field.fieldId] = field;
        }
        for (let fieldId of selectedFields) {
            if (fieldsDict[fieldId].dataType === "email" ||
                (fieldsDict[fieldId].classifiers && fieldsDict[fieldId].classifiers.length > 0 && fieldsDict[fieldId].classifiers.find(item => item.tagName.toLowerCase() === "email")) ||
                (dataObject[fieldId] && validator.isEmail(dataObject[fieldId]))) {
                isEmailFieldSelected = true;
                break;
            }
        }
        if (!isEmailFieldSelected) {
            setValidationError("You must select at least one attribute of type EMAIL or PHONE to send magic links");
            return;
        }
        onOk(selectedFields, consentChecked);
    }

    return (
        <Modal
            closable={!busy}
            visible={visible}
            bodyStyle={{ minHeight: "25vh" }}
            centered={true}
            title={`Consumer Record Verification (${recordId})`}
            okText={!busy ? "Send Magic Link" : "Sending magick link..."}
            cancelText="Cancel"
            onCancel={() => {
                onCancel();
            }}
            onOk={onOkClick}
            okButtonProps={{ disabled: busy, loading: busy }}
            cancelButtonProps={{ disabled: busy }}
            width={1000}>
            <FullHeightContainerLayout
                style={{ minHeight: "25vh" }}
                showHeader={validationError !== null}
                header={validationError && <Text type="danger">{validationError}</Text>}
                content={
                    <Card size="small" title="Choose data attribute for verification" bordered={false} style={{ width: "100%", background: "#fafafa" }}>
                        {schemaFields.map((field) => (
                            <CheckableTag
                                style={{ border: "1px solid #d9d9d9" }}
                                key={field.fieldId}
                                checked={selectedFields.indexOf(field.fieldId) > -1}
                                onChange={(checked) => handleChange(field.fieldId, checked)}
                            >
                                {field.name}
                            </CheckableTag>
                        ))}
                    </Card>
                }
                showFooter={true}
                footer={<Space direction="vertical" style={{ width: "100%" }}>
                    <Checkbox checked={consentChecked} onChange={onConsentChange}>Request Consent</Checkbox>
                    {confirmError && <Text type="danger">Type CONFIRM to verify to run a bulk job</Text>}
                    {showConfirm && <Input status={confirmError} value={confirmText} onChange={(e) => setConfirmText(e.target.value)} placeholder="Type CONFIRM to verify to run a bulk job"></Input>}
                </Space>}>
            </FullHeightContainerLayout>
        </Modal >
    );
};

export default DataObjectRequestVerificationModal;