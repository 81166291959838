import {
    GET_EVENT_DOMAIN_DATA_LIST_REQUEST,
    GET_EVENT_DOMAIN_DATA_LIST_SUCCESS,
    GET_EVENT_DOMAIN_DATA_LIST_FAILURE
} from "./actions";

const initialState = {
    getEventDomainDataListResult: {
        success: true
    },
    domainDataResult: {
        Items: [],
        inProgress: true
    }
};

export default (state = initialState, action) => {
    let domainDataResult = {
        Items: [],
        inProgress: true
    };
    switch (action.type) {
        case GET_EVENT_DOMAIN_DATA_LIST_REQUEST:
            return { ...state, domainDataResult: !action.params.pageKey ? domainDataResult : { ...state.domainDataResult, inProgress: true } };
        case GET_EVENT_DOMAIN_DATA_LIST_SUCCESS:
            domainDataResult = action.payload;
            let sNo = state.domainDataResult.Items.length;
            for (let item of domainDataResult.Items) {
                item.data.sNo = sNo + 1;
                sNo = item.data.sNo;
            }
            domainDataResult.Items = state.domainDataResult.Items.concat(domainDataResult.Items)
            domainDataResult.inProgress = false;
            return { ...state, getEventDomainDataListResult: { success: true }, domainDataResult };
        case GET_EVENT_DOMAIN_DATA_LIST_FAILURE:
            return {
                ...state,
                getEventDomainDataListResult: { success: false, ...action.payload },
                domainDataResult: {
                    Items: [],
                    inProgress: false
                }
            };
        default:
            return state;
    }
};
