import react from 'react';
import { Checkbox, Space, Button, Table, Empty, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";

function EmptyDataRow({ description }) {
    return <Row>
        <Empty style={{ height: "100%", width: "100%" }} description={description}>
        </Empty>
    </Row>
}

export default EmptyDataRow