import React from "react";

export default function useScreenSize(): IUseScreenSizeResult {
    const [screenSize, setScreenSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    React.useEffect(() => {
        // Track screen resize
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const memoedValue: IUseScreenSizeResult = React.useMemo(
        () => screenSize,
        [screenSize],
    );

    return memoedValue;
}
