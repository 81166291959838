export const GET_ALL_TENANTS_REQUEST = "GET_ALL_TENANTS_REQUEST";
export const GET_ALL_TENANTS_SUCCESS = "GET_ALL_TENANTS_SUCCESS";
export const GET_ALL_TENANTS_FAILURE = "GET_ALL_TENANTS_FAILURE";

export const GET_ALL_RUNNING_JOBS_REQUEST = "GET_ALL_RUNNING_JOBS_REQUEST";
export const GET_ALL_RUNNING_JOBS_SUCCESS = "GET_ALL_RUNNING_JOBS_SUCCESS";
export const GET_ALL_RUNNING_JOBS_FAILURE = "GET_ALL_RUNNING_JOBS_FAILURE";

export const GET_TENANT_BY_ID_REQUEST = "GET_TENANT_BY_ID_REQUEST";
export const GET_TENANT_BY_ID_SUCCESS = "GET_TENANT_BY_ID_SUCCESS";
export const GET_TENANT_BY_ID_FAILURE = "GET_TENANT_BY_ID_FAILURE";

export const GET_TENANT_USERS_REQUEST = "GET_TENANT_USERS_REQUEST";
export const GET_TENANT_USERS_SUCCESS = "GET_TENANT_USERS_SUCCESS";
export const GET_TENANT_USERS_FAILURE = "GET_TENANT_USERS_FAILURE";

export const UPDATE_TENANT_ADMIN_REQUEST = "UPDATE_TENANT_ADMIN_REQUEST";
export const UPDATE_TENANT_ADMIN_SUCCESS = "UPDATE_TENANT_ADMIN_SUCCESS";
export const UPDATE_TENANT_ADMIN_FAILURE = "UPDATE_TENANT_ADMIN_FAILURE";

export const UPDATE_TENANT_REQUEST = "UPDATE_TENANT_REQUEST";
export const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS";
export const UPDATE_TENANT_FAILURE = "UPDATE_TENANT_FAILURE";

export const DELETE_TENANT_REQUEST = "DELETE_TENANT_REQUEST";
export const DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS";
export const DELETE_TENANT_FAILURE = "DELETE_TENANT_FAILURE";


export const getAllTenantsRequest = () => ({
    type: GET_ALL_TENANTS_REQUEST
})

export const getAllTenantsSuccess = (result) => ({
    type: GET_ALL_TENANTS_SUCCESS,
    payload: {
        result
    }
})

export const getAllTenantsFailure = (error) => ({
    type: GET_ALL_TENANTS_FAILURE,
    payload: {
        error
    }
})

export const getAllRunningJobsRequest = () => ({
    type: GET_ALL_RUNNING_JOBS_REQUEST
})

export const getAllRunningJobsSuccess = (result) => ({
    type: GET_ALL_RUNNING_JOBS_SUCCESS,
    payload: {
        result
    }
})

export const getAllRunningJobsFailure = (error) => ({
    type: GET_ALL_RUNNING_JOBS_FAILURE,
    payload: {
        error
    }
})

export const getTenantByIdRequest = (tenantId) => ({
    type: GET_TENANT_BY_ID_REQUEST,
    params: {
        tenantId
    }
})

export const getTenantByIdSuccess = (tenant) => ({
    type: GET_TENANT_BY_ID_SUCCESS,
    payload: {
        tenant
    }
})

export const getTenantByIdFailure = (error) => ({
    type: GET_TENANT_BY_ID_FAILURE,
    payload: {
        error
    }
})

export const getTenantUsersRequest = (tenantId) => ({
    type: GET_TENANT_USERS_REQUEST,
    params: {
        tenantId
    }
})

export const getTenantUsersSuccess = (result) => ({
    type: GET_TENANT_USERS_SUCCESS,
    payload: {
        result
    }
})

export const getTenantUsersFailure = (error) => ({
    type: GET_TENANT_USERS_FAILURE,
    payload: {
        error
    }
})

export const updateTenantAdminRequest = (tenantId, userId) => ({
    type: UPDATE_TENANT_ADMIN_REQUEST,
    params: {
        tenantId,
        userId
    }
})

export const updateTenantAdminSuccess = (result) => ({
    type: UPDATE_TENANT_ADMIN_SUCCESS,
    payload: {
        result
    }
})

export const updateTenantAdminFailure = (error) => ({
    type: UPDATE_TENANT_ADMIN_FAILURE,
    payload: {
        error
    }
})

export const updateTenantRequest = (tenantId, updatedValues) => ({
    type: UPDATE_TENANT_REQUEST,
    params: {
        tenantId,
        updatedValues
    }
})

export const updateTenantSuccess = (result) => ({
    type: UPDATE_TENANT_SUCCESS,
    payload: {
        result
    }
})

export const updateTenantFailure = (error) => ({
    type: UPDATE_TENANT_FAILURE,
    payload: {
        error
    }
})

export const deleteTenantRequest = (tenantId) => ({
    type: DELETE_TENANT_REQUEST,
    params: {
        tenantId
    }
})

export const deleteTenantSuccess = (result) => ({
    type: DELETE_TENANT_SUCCESS,
    payload: {
        result
    }
})

export const deleteTenantFailure = (error) => ({
    type: DELETE_TENANT_FAILURE,
    payload: {
        error
    }
})