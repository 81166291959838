import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Table, Checkbox, Select, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Option } = Select;

const BusinessAreaSelectSystemConnectionList = ({ businessAreaSystemConnections, onConnectionSelected }) => {
    const [systemConnections, setSystemConnections] = useState((businessAreaSystemConnections && businessAreaSystemConnections.filter(item => item.systemConnection.active)) || []);
    
    useEffect(() => {
        setSystemConnections((businessAreaSystemConnections && businessAreaSystemConnections.filter(item => item.systemConnection.active)) || []);
    }, [businessAreaSystemConnections]);

    const getConnectionTypeLabel = (type) => {
        let displayValue = "";
        if (type === "aws_s3") {
            displayValue = "AWS S3 Connection";
        }
        return displayValue;
    }

    const onRowSelectionChanged = (selectedRowKeys, selectedRows) => {
        onConnectionSelected(selectedRows[0]);
    }

    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <Table size="small"
                    rowKey={(item) => item.systemConnection.connectionId}
                    dataSource={systemConnections}
                    pagination={false}
                    scroll={{ y: "100vh" }}
                    className="container-height-100"
                    rowSelection={{
                        type: "radio",
                        onChange: onRowSelectionChanged
                    }}>
                    <Column
                        width="5%"
                        title="No."
                        key="index"
                        render={(value, field, index) => index + 1}
                    />
                    <Column
                        title={"Name"}
                        dataIndex={["systemConnection", "name"]}
                        render={(value, field, index) => {
                            return <>{value}</>;
                        }}
                    />
                    <Column
                        title={"Type"}
                        dataIndex={["systemConnection", "type"]}
                        render={(value, field, index) => {
                            return <>{getConnectionTypeLabel(value)}</>;
                        }}
                    />
                </Table>
            }>
        </FullHeightContainerLayout>
    );
}

export default BusinessAreaSelectSystemConnectionList;