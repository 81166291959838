import React, { Component } from 'react';
import { connect } from "react-redux";
import { Message } from 'semantic-ui-react';
import LABELS from 'constants/labels';
import actions from 'store/actions';
import { RoleList, LoadingOverlay, ProhibitedArea, ManageRole, OkCancelConfirmationBox } from "components";
import { RoleAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';
import flatten from 'flat';
import { Row, Col } from "antd";

class Roles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            deleteRole: null,
            showRoleDeleteConfirmation: false,
            deleteRoleConfirmMessage: "",
            fetchingPermission: true
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getUserRolesPermission();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting roles...",
                fetchingRoles: true
            });
            this.props.getRoles();
        }
        else {
            this.props.getRole(this.props.roleId);
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting role...",
                    fetchingRole: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingRole: true
                });
            }
        }
        this.props.getUsers();
        this.props.getAppClients();
        this.props.getPermissionMasterData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getUserRolesPermissionResult && this.props.getUserRolesPermissionResult !== prevProps.getUserRolesPermissionResult) {
            if (!this.props.getUserRolesPermissionResult.success || !this.props.permission) {
                this.setState({
                    permissionDenied: true,
                    fetchingPermission: false
                });
            }
            else {
                this.setState({
                    fetchingPermission: false
                });
            }
        }

        if (this.props.getRolesResult && this.props.getRolesResult !== prevProps.getRolesResult) {
            if (!this.props.getRolesResult.success) {
                if (this.props.getRolesResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError(this.props.getRolesResult.message || "Could not able to get roles at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingRoles: false
            });
        }

        if (this.props.createRoleResult && this.props.createRoleResult !== prevProps.createRoleResult) {
            if (!this.props.createRoleResult.success) {
                showError(this.props.createRoleResult.message || "Role cannot be created at this moment.");
                this.setState({
                    busy: false,
                    busyMessage: "",
                    manageRoleErrors: this.props.createRoleResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Role created. Please wait..."
                });
                showSuccess("Role created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }

        if (this.props.getRoleByIdResult && this.props.getRoleByIdResult !== prevProps.getRoleByIdResult) {
            if (!this.props.getRoleByIdResult.success) {
                if (this.props.getRoleByIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError(this.props.getRoleByIdResult.message || "Could not able to get role at this moment.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingRole: false
            });
            if (!this.props.roleList || this.props.roleList.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }

        if (this.props.updateRoleResult && this.props.updateRoleResult !== prevProps.updateRoleResult) {
            if (!this.props.updateRoleResult.success) {
                showError(this.props.updateRoleResult.message || "Role cannot be updated at this moment.");
                this.setState({
                    busy: false,
                    busyMessage: "",
                    manageRoleErrors: this.props.updateRoleResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Role updated. Please wait..."
                });
                showSuccess("Role updated successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }

        if (this.props.deleteRoleResult && this.props.deleteRoleResult !== prevProps.deleteRoleResult) {
            if (!this.props.deleteRoleResult.success) {
                showError(this.props.deleteRoleResult.message || "Role cannot be deleted at this moment.");
            }
            else {
                showSuccess("Role deleted successfully.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }

        if (this.props.activateDeactivateRoleResult && this.props.activateDeactivateRoleResult !== prevProps.activateDeactivateRoleResult) {
            if (!this.props.activateDeactivateRoleResult.success) {
                showError(this.props.activateDeactivateRoleResult.message || "Role cannot be updated at this moment.");
            }
            else {
                showSuccess("Role updated successfully.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Roles"
                }
            ];
            if (this.props.action) {
                let roleName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New Role"
                        });
                        break;
                    case "view":
                    case "edit":
                        roleName = this.getRoleName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: roleName
                        }]);
                        break;
                    case "history":
                        roleName = this.getRoleName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: roleName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getRoleName = () => {
        let roleName = this.props.roleId;
        if (this.props.roleList && this.props.roleList.length > 0) {
            let role = this.props.roleList.find(role => role.roleId === this.props.roleId);
            if (role) {
                roleName = role.name;
            }
        }
        else if (this.props.role && this.props.role.roleId === this.props.roleId) {
            roleName = this.props.role.name;
        }
        return roleName;
    }

    showAddRole = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    cancelcreateRole = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    showViewRole = (roleId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", roleId)
            .replace(":itemAction", "view"));
    }

    showEditRole = (roleId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", roleId)
            .replace(":itemAction", "edit"));
    }

    showRoleHistory = (roleId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", roleId)
            .replace(":itemAction", "history"));
    }

    createRole = (role, permissions, users, appClients) => {
        this.setState({
            busy: true,
            busyMessage: "Creating role..."
        });
        this.props.createRole(role, permissions, users, appClients);
    }

    updateRole = (roleId, updateRoleData) => {
        this.setState({
            busy: true,
            busyMessage: "Updating role..."
        });
        this.props.updateRole(roleId, updateRoleData);
    }

    showDeleteRoleConfirmation = (role) => {
        this.setState({
            deleteRole: role,
            showRoleDeleteConfirmation: true,
            deleteRoleConfirmMessage: `Are you sure, you want to delete '${role.name}' role?`
        })
    }

    onDeleteRoleConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting role..."
        });
        this.props.deleteRole(this.state.deleteRole.roleId);
        this.onDeleteRoleCancel();
    }

    onDeleteRoleCancel = () => {
        this.setState({
            deleteRole: null,
            showRoleDeleteConfirmation: false,
            deleteRoleConfirmMessage: ""
        })
    }

    activateDeactivateRole = (roleId, roleVersion, roleActive) => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        this.props.activateDeactivateRole(roleId, roleVersion, roleActive);
    }

    onRoleValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageRoleErrors) === false) {
            let manageRoleErrors = { ...this.state.manageRoleErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageRoleErrors[key];
            }
            this.setState({
                manageRoleErrors
            })
        }
    }

    getRoleComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return <>
                <OkCancelConfirmationBox
                    show={this.state.showRoleDeleteConfirmation}
                    message={this.state.deleteRoleConfirmMessage}
                    onCancel={this.onDeleteRoleCancel}
                    onOk={this.onDeleteRoleConfirmed}>
                </OkCancelConfirmationBox>
                {!this.state.fetchingRoles ? <RoleList
                    permission={this.props.permission}
                    roleList={this.props.roleList}
                    onAddRole={this.showAddRole}
                    onViewRole={this.showViewRole}
                    onEditRole={this.showEditRole}
                    onDeleteRole={this.showDeleteRoleConfirmation}
                    onActivateDeactivateRole={this.activateDeactivateRole}
                    onViewRoleHistory={this.showRoleHistory} /> : <></>}
            </>
        }
        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageRole
                    action={this.props.action}
                    permission={this.props.permission}
                    permissionMasterData={this.props.permissionMasterData}
                    userList={this.props.userList}
                    appClientList={this.props.appClientListResult.Items}
                    onCancel={this.cancelcreateRole}
                    createRole={this.createRole}
                    formErrors={this.state.manageRoleErrors}></ManageRole>;
            case "view":
            case "edit":
                return !this.state.fetchingRole ? (this.props.role ? <ManageRole
                    action={this.props.action}
                    permission={this.props.permission}
                    role={this.props.role}
                    permissionMasterData={this.props.permissionMasterData}
                    userList={this.props.userList}
                    appClientList={this.props.appClientListResult.Items}
                    onCancel={this.cancelcreateRole}
                    updateRole={this.updateRole}
                    formErrors={this.state.manageRoleErrors}
                    onValuesChanged={this.onRoleValuesChanged}></ManageRole> :
                    (this.props.getRoleByIdResult.success ? <Message floating size='big'>
                        <Message.Header>Role not found.</Message.Header>
                        <p>The role you are looking for does not exist.</p>
                    </Message> : <></>)) : <></>;
            case "history":
                return this.state.showHistory ?
                    <RoleAudit role={this.props.role}></RoleAudit>
                    :
                    <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.state.busy || this.state.fetchingPermission}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.state.fetchingPermission ? this.getRoleComponent() : <></>}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        roleList: state.roles.roleList,
        getRolesResult: state.roles.getRolesResult,
        tenant: state.userLogin.tenant,
        userList: state.users.userList,
        appClientListResult: state.appClients.appClientListResult,
        permissionMasterData: state.roles.permissionMasterData,
        createRoleResult: state.roles.createRoleResult,
        getRoleByIdResult: state.roles.getRoleByIdResult,
        role: state.roles.role,
        updateRoleResult: state.roles.updateRoleResult,
        deleteRoleResult: state.roles.deleteRoleResult,
        activateDeactivateRoleResult: state.roles.activateDeactivateRoleResult,
        getUserRolesPermissionResult: state.roles.getUserRolesPermissionResult,
        permission: state.roles.permission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: () => dispatch(actions.getRolesRequest()),
        getUsers: () => dispatch(actions.getUsersRequest()),
        getAppClients: () => dispatch(actions.getAppClientsRequest()),
        getPermissionMasterData: () => dispatch(actions.getPermissionMasterDataRequest()),
        createRole: (role, permissions, users, appClients) => dispatch(actions.createRolesRequest(role, permissions, users, appClients)),
        getRole: (roleId) => dispatch(actions.getRoleByIdRequest(roleId)),
        updateRole: (roleId, updatedData) => dispatch(actions.updateRoleRequest(updatedData, roleId)),
        deleteRole: (roleId) => dispatch(actions.deleteRoleRequest(roleId)),
        activateDeactivateRole: (roleId, roleVersion, roleActive) => dispatch(actions.activateDeactivateRoleRequest(roleId, roleVersion, roleActive)),
        getUserRolesPermission: () => dispatch(actions.getUserRolesPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Roles));