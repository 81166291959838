import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getParsedSchemaDefinition(api, parseConfig) {
    let result = yield call([api, api.post], `${Endpoints.dataParser}/api/v1/schema/parse`, parseConfig);
    return result.data;
}

export function* getParsedSchemaDefinitionRequest(api, { parseConfig }) {
    try {
        let schemaDefinition = yield call(getParsedSchemaDefinition, api, parseConfig);
        yield put(actions.getParsedSchemaDefinitionSuccess(schemaDefinition));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "parsed schema definition");
        yield put(actions.getParsedSchemaDefinitionFailure(errorObject));
    }
}

export function* watchGetParsedSchemaDefinitionRequest(api, { params }) {
    yield call(getParsedSchemaDefinitionRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_PARSED_SCHEMA_DEFINITION_REQUEST, watchGetParsedSchemaDefinitionRequest, api);
}