import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { BusinessAreaDataHistoryList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class EventDataDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        if (!this.props.itemOperation) {
            this.setState({
                busy: true,
                busyMessage: "Getting business area data...",
            });
            this.props.getEventDataDomain(this.props.eventId, this.props.dataDomainId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventDataDomainResultUpdate(prevProps);
    }

    onGetEventDataDomainResultUpdate = (prevProps) => {
        if (this.props.getEventDataDomainResult && this.props.getEventDataDomainResult !== prevProps.getEventDataDomainResult) {
            if (!this.props.getEventDataDomainResult.success) {
                if (this.props.getEventDataDomainResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Revision - </Text><Text type="secondary" italic>{`(Business Area Data: ${this.props.domainName})`}</Text></Title>}
                            content={
                                this.props.dataDomainRevision
                                    ?
                                    <BusinessAreaDataHistoryList
                                        dataDomainRevisions={[this.props.dataDomainRevision]}>
                                    </BusinessAreaDataHistoryList>
                                    :
                                    <Empty></Empty>
                            }>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventDataDomainResult: state.eventDataDomain.getEventDataDomainResult,
        dataDomainRevision: state.eventDataDomain.dataDomainRevision
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventDataDomain: (eventId, dataDomainId) => dispatch(actions.getEventDataDomainRequest(eventId, dataDomainId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventDataDomain));