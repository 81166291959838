import { put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";

function* watchSetQueryBuilderObject(action: IQueryBuilderObjectAction) {
    yield put(actions.setQueryBuilderObjectAction(action.payload.query));
}

export default function* queryBuilderObjectSaga() {
    yield takeLatest("SET_QUERY_BUILDER_OBJECT", watchSetQueryBuilderObject);
}
