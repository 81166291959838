import React, { useState } from "react";
import { Tooltip, Space, Button, Table, Checkbox, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";

const { Column } = Table;

const SchemaModelRecentList = ({
    permission,
    schemaModelList,
    onViewSchemaModel,
    onEditSchemaModel
}) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="schemaId"
                            dataSource={schemaModelList}
                            rowSelection={{
                                type: 'radio'
                            }}
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            className="container-height-100 schema-list-table">
                            <Column
                                width="4rem"
                                render={(value, schema, index) => <div style={{ marginLeft: "-1rem" }}>
                                    <Tooltip title ='Edit Schema'>
                                    <Icon
                                        disabled={!permission.canEdit}
                                        color='blue'
                                        name='pencil'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onEditSchemaModel(schema);
                                        }} /> </Tooltip>
                                        <Tooltip title ='View Schema'>
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='eye'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onViewSchemaModel(schema);
                                        }} /> </Tooltip>
                                </div>}
                            />
                            <Column
                                width="20%"
                                dataIndex={"name"}
                                title={"Schema"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="20%"
                                dataIndex={"description"}
                                title={"Description"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="20%"
                                dataIndex={"businessAreaName"}
                                title={"Business Area"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="20%"
                                dataIndex={"updatedDate"}
                                title={"Last Updated"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => {
                                    let dateValue = moment.utc(value).toDate().toLocaleString()
                                    return <Tooltip placement="topLeft" title={dateValue}>{dateValue}</Tooltip>
                                }}
                            />
                            <Column
                                width="20%"
                                dataIndex={"updatedByEmail"}
                                title={"Updated By"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                        </Table>
                    </div>
                </div>}>
        </FullHeightContainerLayout>
    );
};

export default SchemaModelRecentList;