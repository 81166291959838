import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, DataObjectHistoryList, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class DataObjectAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        this.props.getDataObjectHistory(this.props.businessAreaId, this.props.schemaId, this.props.dataSetId, this.props.dataObjectId);
        this.props.getDataObjectEvents(this.props.dataObjectId);
        this.setState({
            fetchingHistory: true,
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataObjectHistoryResultUpdate(prevProps);
        this.onGetDataObjectEventsResultUpdate(prevProps);
    }

    onGetDataObjectHistoryResultUpdate = (prevProps) => {
        if (this.props.getDataObjectHistoryResult && this.props.getDataObjectHistoryResult !== prevProps.getDataObjectHistoryResult) {
            if (!this.props.getDataObjectHistoryResult.success) {
                if (this.props.getDataObjectHistoryResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data history.");
                }
            }
            this.setState({
                fetchingHistory: false
            });
        }
    }

    onGetDataObjectEventsResultUpdate = (prevProps) => {
        if (this.props.getDataObjectEventsResult && this.props.getDataObjectEventsResult !== prevProps.getDataObjectEventsResult) {
            if (!this.props.getDataObjectEventsResult.success) {
                if (this.props.getDataObjectEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area data events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Tabs defaultActiveKey="1" className="data-audit-tabs">
                        <TabPane tab="Events" key="1">
                            {
                                this.state.fetchingEvents
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting dataobject events...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <EventList eventList={this.props.dataObjectEventsResult.Items}>
                                            </EventList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                        <TabPane tab="Revisions" key="2">
                            {
                                this.state.fetchingHistory
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting dataobject history...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <DataObjectHistoryList
                                                dataObjectRevisions={this.props.dataObjectHistoryResult.Items}
                                                schemaFields={this.props.schemaFields}>
                                            </DataObjectHistoryList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                    </Tabs>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataObjectHistoryResult: state.dataObjectAudit.getDataObjectHistoryResult,
        dataObjectHistoryResult: state.dataObjectAudit.dataObjectHistoryResult,
        getDataObjectEventsResult: state.dataObjectAudit.getDataObjectEventsResult,
        dataObjectEventsResult: state.dataObjectAudit.dataObjectEventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataObjectHistory: (businessAreaId, schemaId, dataSetId, dataObjectId) => dispatch(actions.getDataObjectHistoryRequest(businessAreaId, schemaId, dataSetId, dataObjectId)),
        getDataObjectEvents: (dataObjectId) => dispatch(actions.getDataObjectEventsRequest(dataObjectId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectAudit));