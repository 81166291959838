import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { UserBusinessAreaSchemaWrapper, DataSets } from 'containers';
import { PageLayout } from 'components';

function usePreviousAction(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const DataSetPage = (props) => {
    const { tenant, businessarea, schema, dataSetAction, dataSetId } = useParams();
    const prevDataSetAction = usePreviousAction(dataSetAction);

    return (
        <PageLayout
            key={`${businessarea}_${schema}_datasets_page`}
            mainContent={({ setMainColumnSpan, setBreadcrumbNavigationItems }) => {
                return <UserBusinessAreaSchemaWrapper
                    key={`${businessarea}_${schema}_wrapper`}
                    tenant={tenant}
                    businessArea={businessarea.toLowerCase()}
                    schema={schema}>
                    {
                        childProps => (<DataSets
                            key={`${businessarea}_${schema}_${dataSetAction || "default"}_datasets`}
                            setMainColumnSpan={setMainColumnSpan}
                            setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}
                            tenant={tenant}
                            businessArea={businessarea.toLowerCase()}
                            schema={schema}
                            action={dataSetAction}
                            prevAction={prevDataSetAction}
                            dataSetId={dataSetId}
                            displaySize="full"
                            {...childProps}>
                        </DataSets>)
                    }
                </UserBusinessAreaSchemaWrapper>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default DataSetPage
