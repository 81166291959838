import React from "react";
import { connect } from "react-redux";
import { Icon } from 'semantic-ui-react';
import { withRouter } from "react-router";
import AppPaths from 'constants/appPaths';
import { Layout, Card, Avatar, Row, Col, Form, Input, Space, Typography } from "antd";

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Text, Title, Link } = Typography;

class LicenseManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return <Row style={{ height: "100%", width: "100%" }}>
            <Col span={24} style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Row style={{ flexDirection: "row", justifyContent: "center" }}>
                    <Col style={{ display: "flex", flexDirection: "column" }}>
                        <Title level={2}>License Management</Title>
                        <Text style={{ fontSize: 24 }}>Active Free Trial</Text>
                        <Text style={{ fontSize: 24, marginTop: "2rem" }}>Your license administrator is <Link underline>{this.props.tenant.adminEmail}</Link></Text>
                        <Text style={{ fontSize: 24, marginTop: "2rem" }}>Thank you for your interest in the Pretectum CMDM.</Text>
                        <Text style={{ fontSize: 24, marginTop: "2rem" }}>During this free trial you will be able to make use of a number of </Text>
                        <Text style={{ fontSize: 24 }}>features of the platform but other activities may be limited or not </Text>
                        <Text style={{ fontSize: 24 }}>available as part of the free trial.</Text>
                        <Text style={{ fontSize: 24, marginTop: "2rem" }}>To learn more please visit <Link href="https://www.pretectum.com" target="_blank" underline>www.pretectum.com</Link></Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.userLogin.tenant,
        user: state.userLogin.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LicenseManagement));