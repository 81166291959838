import React, { useState, useEffect } from "react";
import { Card, Tag, Modal, Checkbox, Typography, Row, Col, Input, Space } from "antd";
import { WarningFilled, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout, BatchProcessResult, LoadingOverlay } from 'components';
import moment from "moment";
import validator from 'validator';

const { CheckableTag } = Tag;
const { Text, Title, Paragraph } = Typography;

const TenantActionConfirmationModal = ({ title, description, visible, onCancel, onOk, tenant, icon }) => {
    const [open, setOpen] = useState(visible);
    const [confirmError, setConfirmError] = useState(null)
    const [confirmText, setConfirmText] = useState(null);

    useEffect(() => {
        if (open !== visible) {
            setOpen(visible);
        }
    }, [visible]);

    const onOkClick = () => {
        if (!confirmText) {
            setConfirmError("Type Tenant name to continue");
            return
        }

        if (confirmText !== tenant) {
            setConfirmError("Tenant name didn't match");
            return
        }
        setConfirmText("");
        setConfirmError("");
        onOk && onOk();
    }

    const onCancelClick = () => {
        setOpen(false);
        setConfirmText("");
        setConfirmError("");
        onCancel && onCancel();
    }

    const onTenantNameChange = (value) => {
        setConfirmText(value);
        setConfirmError("");
    }

    return (
        <Modal
            destroyOnClose={true}
            closable={false}
            open={open}
            centered={true}
            okText={"Ok"}
            cancelText="Cancel"
            onCancel={onCancelClick}
            onOk={onOkClick}>
            <Row>
                {
                    icon && <Col span={2}>
                        {icon}
                    </Col>
                }
                <Col span={22}>
                    <FullHeightContainerLayout
                        showHeader={true}
                        headerStyle={{ lineHeight: "unset" }}
                        header={<Title level={4}>{title}</Title>}
                        content={<Typography>
                            {
                                description.map((value, index) => {
                                    return <Paragraph key={index}>
                                        {value}
                                    </Paragraph>
                                })
                            }
                        </Typography>}
                        showFooter={true}
                        footer={<Space direction="vertical" style={{ width: "100%" }}>
                            {confirmError && <Text type="danger">{confirmError}</Text>}
                            {<Input status={confirmError} value={confirmText} onChange={(e) => onTenantNameChange(e.target.value)} placeholder="Type Tenant name to continue"></Input>}
                        </Space>}>
                    </FullHeightContainerLayout>
                </Col>
            </Row>
        </Modal >
    );
};

export default TenantActionConfirmationModal;