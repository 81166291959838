import {
    GET_DATA_SET_HISTORY_SUCCESS,
    GET_DATA_SET_HISTORY_FAILURE,

    GET_DATA_SET_EVENTS_SUCCESS,
    GET_DATA_SET_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getDataSetHistoryResult: {
        success: true
    },
    dataSetHistoryResult: {
        Items: []
    },
    getDataSetEventsResult: {
        success: true
    },
    dataSetEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_SET_HISTORY_SUCCESS:
            return { ...state, getDataSetHistoryResult: { success: true }, dataSetHistoryResult: action.payload.dataSetHistoryResult };
        case GET_DATA_SET_HISTORY_FAILURE:
            return { ...state, getDataSetHistoryResult: { success: false, ...action.payload.error }, dataSetHistoryResult: { Items: [] } };
        case GET_DATA_SET_EVENTS_SUCCESS:
            return { ...state, getDataSetEventsResult: { success: true }, dataSetEventsResult: action.payload.dataSetEventsResult };
        case GET_DATA_SET_EVENTS_FAILURE:
            return {
                ...state, getDataSetEventsResult: { success: false, ...action.payload.error }, dataSetEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
