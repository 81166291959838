import React from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  MainPage,
  UserSignupPage,
  UserVerifyEmailPage,
  TenantProvisioningPage,
  HomePage,
  AdminConsolePage,
  TenantOverviewPage,
  SchemaPage,
  DataSetPage,
  DataObjectPage,
  DataObjectUUIDPage,
  DomainServiceConsolePage,
  ConfigurationPage,
  LicenseInfoPage,
  UserProfilePage,
  MonitorPage,
  DataObjectDuplicateSearchPage,
  SearchPage,
  LineagePage,
  ProhibitedArea,
  PageWrapper,
  AnonymousPageWrapper,
  SharedDataObjectPage,
  DataObjectSelfVerificationPage
} from 'components';
import { AuthenticatedPageWrapper } from 'containers';
import AppPaths from 'constants/appPaths';
import { Redirect } from 'react-router-dom';

const App = () => {
  return (
    <div>
      <Switch>
        <Route path={AppPaths.LOGIN} component={PageWrapper(AnonymousPageWrapper(MainPage))} exact />
        <Route path={AppPaths.RESET_PASSWORD} component={PageWrapper(AnonymousPageWrapper(MainPage, { resetPassword: true }))} exact />
        <Route path={AppPaths.SIGN_UP} component={PageWrapper(AnonymousPageWrapper(UserSignupPage))} exact />
        <Route path={AppPaths.VERIFY_USER_EMAIL} component={PageWrapper(AnonymousPageWrapper(UserVerifyEmailPage))} exact />
        <Route path={AppPaths.TENANT_PROVISION} component={PageWrapper(AuthenticatedPageWrapper(TenantProvisioningPage, { page: "tenantProvision", requireUserBusinessAreas: true, showHeader: false, showFooter: false }))} exact />
        <Route path={AppPaths.TENANT_HOME} component={PageWrapper(AuthenticatedPageWrapper(HomePage, { page: "home", requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.ADMIN_CONSOLE} component={PageWrapper(AuthenticatedPageWrapper(AdminConsolePage, { page: "adminConsole" }))} exact />
        <Route path={AppPaths.TENANT_OVERVIEW} component={PageWrapper(AuthenticatedPageWrapper(TenantOverviewPage, { page: "adminConsole" }))} exact />
        <Route path={AppPaths.TENANT_BUSINESS_AREA_SCHEMA} component={PageWrapper(AuthenticatedPageWrapper(SchemaPage, { page: "schema", activeBusinessAreaBasePath: AppPaths.TENANT_BUSINESS_AREA_SCHEMA, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ACTION} component={PageWrapper(AuthenticatedPageWrapper(SchemaPage, { page: "schema", activeBusinessAreaBasePath: AppPaths.TENANT_BUSINESS_AREA_SCHEMA, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION} component={PageWrapper(AuthenticatedPageWrapper(SchemaPage, { page: "schema", activeBusinessAreaBasePath: AppPaths.TENANT_BUSINESS_AREA_SCHEMA, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_SCHEMA_DATASET_ACTION} component={PageWrapper(AuthenticatedPageWrapper(DataSetPage, { page: "dataset", activeBusinessAreaBasePath: AppPaths.TENANT_SCHEMA_DATASET_ACTION, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION} component={PageWrapper(AuthenticatedPageWrapper(DataSetPage, { page: "dataset", activeBusinessAreaBasePath: AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_DATASET_DATAOBJECT_ACTION} component={PageWrapper(AuthenticatedPageWrapper(DataObjectPage, { page: "dataobject", activeBusinessAreaBasePath: AppPaths.TENANT_DATASET_DATAOBJECT_ACTION, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_DATASET_DATAOBJECT_ID_ACTION} component={PageWrapper(AuthenticatedPageWrapper(DataObjectPage, { page: "dataobject", activeBusinessAreaBasePath: AppPaths.TENANT_DATASET_DATAOBJECT_ID_ACTION, requireUserBusinessAreas: true }))} exact />
        <Route path={AppPaths.TENANT_DOMAIN_SERVICE_CONSOLE} component={PageWrapper(AuthenticatedPageWrapper(DomainServiceConsolePage, { page: "domainserviceconsole" }))} exact />
        <Route path={AppPaths.TENANT_CONFIGURATION} component={PageWrapper(AuthenticatedPageWrapper(ConfigurationPage, { page: "configuration" }))} exact />
        <Route path={AppPaths.TENANT_CONFIGURATION_ITEM} component={PageWrapper(AuthenticatedPageWrapper(ConfigurationPage, { page: "configuration" }))} exact />
        <Route path={AppPaths.TENANT_CONFIGURATION_ITEM_ACTION} component={PageWrapper(AuthenticatedPageWrapper(ConfigurationPage, { page: "configuration" }))} exact />
        <Route path={AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION} component={PageWrapper(AuthenticatedPageWrapper(ConfigurationPage, { page: "configuration" }))} exact />
        <Route path={AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION} component={PageWrapper(AuthenticatedPageWrapper(ConfigurationPage, { page: "configuration" }))} exact />
        <Route path={AppPaths.TENANT_LICENSE_INFO} component={PageWrapper(AuthenticatedPageWrapper(LicenseInfoPage, { page: "licenseinfo" }))} exact />
        <Route path={AppPaths.TENANT_USER_PROFILE} component={PageWrapper(AuthenticatedPageWrapper(UserProfilePage, { page: "userprofile" }))} exact />
        <Route path={AppPaths.TENANT_MONITOR} component={PageWrapper(AuthenticatedPageWrapper(MonitorPage, { page: "monitor" }))} exact />
        <Route path={AppPaths.TENANT_MONITOR_ITEM} component={PageWrapper(AuthenticatedPageWrapper(MonitorPage, { page: "monitor" }))} exact />
        <Route path={AppPaths.TENANT_DATA_OBJECT_SEARCH} component={PageWrapper(AuthenticatedPageWrapper(SearchPage, { page: "search" }))} exact />
        <Route path={AppPaths.TENANT_DATA_OBJECT_DUPLICATE_SEARCH} component={PageWrapper(AuthenticatedPageWrapper(DataObjectDuplicateSearchPage, { page: "dataobjectduplicatesearchpage" }))} exact />
        <Route path={AppPaths.TENANT_LINEAGE} component={PageWrapper(AuthenticatedPageWrapper(LineagePage, { page: "lineagePage" }))} exact />
        <Route path={AppPaths.SHARED_DATAOBJECT} component={PageWrapper(AnonymousPageWrapper(SharedDataObjectPage, { showHeader: true }))} exact />
        <Route path={AppPaths.DATA_OBJECT_SELF_VERIFICATION} component={PageWrapper(AnonymousPageWrapper(DataObjectSelfVerificationPage, { showHeader: true }))} exact />
        <Route path={AppPaths.UUID} component={PageWrapper(AuthenticatedPageWrapper(DataObjectUUIDPage, { page: "dataobjectuuidpage" }))} exact />
        <Route component={ProhibitedArea} />
      </Switch>
    </div>
  )
}

export default App
