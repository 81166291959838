import React from 'react';
import { useParams } from "react-router-dom";
import { Monitor, PageLayout } from 'components';

const MonitorPage = (props) => {
    const { monitorItem, itemAction, itemId } = useParams();
    return (
        <PageLayout
            mainContent={({ setBreadcrumbNavigationItems }) => {
                return <Monitor activeItem={monitorItem} setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}></Monitor>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default MonitorPage
