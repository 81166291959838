import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { UserList, LoadingOverlay, ProhibitedArea, ManageUser } from "components";
import { UserAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            permissionDenied: false,
            fetchingPermission: true
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getUserUsersPermission();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting users...",
                fetchingUsers: true
            });
            this.props.getUsers();
        }
        else {
            this.props.getUser(this.props.userId);
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting user...",
                    fetchingUser: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingUser: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserUsersPermissionResultUpdate(prevProps);

        this.onGetUsersResultUpdate(prevProps);

        this.onCreateUserResultUpdate(prevProps);

        this.onGetUserResultUpdate(prevProps);

        this.onUpdateUserResultUpdate(prevProps);

        this.onResetUserPasswordResultUpdate(prevProps);
    }

    onGetUserUsersPermissionResultUpdate = (prevProps) => {
        if (this.props.getUserUsersPermissionResult && this.props.getUserUsersPermissionResult !== prevProps.getUserUsersPermissionResult) {
            if (!this.props.getUserUsersPermissionResult.success || !this.props.permission) {
                this.setState({
                    permissionDenied: true,
                    fetchingPermission: false
                });
            }
            else {
                this.setState({
                    fetchingPermission: false
                });
            }
        }
    }

    onGetUsersResultUpdate = (prevProps) => {
        if (this.props.getUsersResult && this.props.getUsersResult !== prevProps.getUsersResult) {
            if (!this.props.getUsersResult.success) {
                if (this.props.getUsersResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get users at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingUsers: false
            });
        }
    }

    onCreateUserResultUpdate = (prevProps) => {
        if (this.props.createUserResult && this.props.createUserResult !== prevProps.createUserResult) {
            if (!this.props.createUserResult.success) {
                showError(this.props.createUserResult.message || "User cannot be created at this moment.");
                this.setState({
                    busy: false,
                    manageUserErrors: this.props.createUserResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "User created. Please wait..."
                });
                showSuccess("User created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                });
            }
        }
    }

    onGetUserResultUpdate = (prevProps) => {
        if (this.props.getUserResult && this.props.getUserResult !== prevProps.getUserResult) {
            if (!this.props.getUserResult.success) {
                if (this.props.getUserResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get user.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingUser: false
            });
            if (!this.props.userList || this.props.userList.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateUserResultUpdate = (prevProps) => {
        if (this.props.updateUserResult && this.props.updateUserResult !== prevProps.updateUserResult) {
            if (!this.props.updateUserResult.success) {
                if (this.props.updateUserResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to update user.");
                }
            }
            else {
                if (this.state.activateDeactiveUser) {
                    this.props.getUsers();
                    this.setState({
                        busyMessage: "User updated. Please wait..."
                    });
                    showSuccess("User updated successfully.");
                }
                else {
                    this.setState({
                        busy: true,
                        busyMessage: "User updated. Please wait..."
                    });
                    showSuccess("User updated successfully.", () => {
                        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
                            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
                    });
                }
            }
        }
    }

    onResetUserPasswordResultUpdate = (prevProps) => {
        if (this.props.resetUserPasswordResult && this.props.resetUserPasswordResult !== prevProps.resetUserPasswordResult) {
            if (!this.props.resetUserPasswordResult.success) {
                if (this.props.resetUserPasswordResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to send password reset link. Please contact support.");
                }
            }
            else {
                showSuccess("Password reset link sent.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Users"
                }
            ];
            if (this.props.action) {
                let userEmail = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New User"
                        });
                    case "view":
                    case "edit":
                        userEmail = this.getUserEmail();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: userEmail
                        }]);
                        break;
                    case "history":
                        userEmail = this.getUserEmail();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: userEmail
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getUserEmail = () => {
        let userEmail = this.props.userId;
        if (this.props.userList && this.props.userList.length > 0) {
            let user = this.props.userList.find(businessArea => businessArea.userId === this.props.userId);
            if (user) {
                userEmail = user.email;
            }
        }
        else if (this.props.user && this.props.user.userId === this.props.userId) {
            userEmail = this.props.user.email;
        }
        return userEmail;
    }

    showAddUser = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    showViewUser = (userId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", userId)
            .replace(":itemAction", "view"));
    }

    showEditUser = (userId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", userId)
            .replace(":itemAction", "edit"));
    }

    showUserHistory = (userId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", userId)
            .replace(":itemAction", "history"));
    }

    createUser = (user) => {
        this.setState({
            busy: true,
            busyMessage: "Creating user..."
        });
        this.props.createUser(user);
    }

    updateUser = (userId, updatedUser) => {
        this.setState({
            busy: true,
            busyMessage: "Updating user..."
        });
        this.props.updateUser(userId, updatedUser);
    }

    activateDeactivateUser = (user, active) => {
        this.setState({
            busy: true,
            busyMessage: active ? "Activating user..." : "Deactivating user...",
            activateDeactiveUser: true
        });
        this.props.updateUser(user.userId, { active, version: user.version });
    }

    resetUserPassword = (userId) => {
        this.setState({
            busy: true,
            busyMessage: "Sending password reset link..."
        });
        this.props.resetUserPassword(userId);
    }

    cancelcreateUser = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    onUserValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageUserErrors) === false) {
            let manageUserErrors = { ...this.state.manageUserErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageUserErrors[key];
            }
            this.setState({
                manageUserErrors
            })
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingUsers || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingUser;
        }
        return isBusy;
    }

    getUserComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return (
                !this.state.fetchingUsers
                    ?
                    <UserList
                        permission={this.props.permission}
                        userList={this.props.userList}
                        onAddUser={this.showAddUser}
                        onViewUser={this.showViewUser}
                        onEditUser={this.showEditUser}
                        onActivateDeactiveUser={this.activateDeactivateUser}
                        onViewUserHistory={this.showUserHistory} />
                    :
                    <></>
            );
        }
        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageUser
                    permission={this.props.permission}
                    action={this.props.action}
                    tenantDomain={this.props.tenant.domain}
                    onSave={this.createUser}
                    onCancel={this.cancelcreateUser}
                    formErrors={this.state.manageUserErrors}
                    onValuesChanged={this.onUserValuesChanged}>
                </ManageUser>;
            case "view":
            case "edit":
                return !this.state.fetchingUser
                    ?
                    (this.props.user
                        ?
                        <ManageUser
                            permission={this.props.permission}
                            action={this.props.action}
                            onSave={this.updateUser}
                            onCancel={this.cancelcreateUser}
                            onResetPassword={this.resetUserPassword}
                            user={this.props.user}
                            formErrors={this.state.manageUserErrors}
                            onValuesChanged={this.onUserValuesChanged}>
                        </ManageUser>
                        :
                        (this.props.getUserResult.success
                            ?
                            <Message floating size='big'>
                                <Message.Header>User not found.</Message.Header>
                                <p>The User you are looking for does not exist.</p>
                            </Message>
                            :
                            <></>
                        )
                    )
                    :
                    <></>;
            case "history":
                return this.state.showHistory ?
                    <UserAudit user={this.props.user}></UserAudit>
                    :
                    <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <div className="full-height-flex-container">
                <LoadingOverlay
                    busy={this.isBusy()}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {!this.state.fetchingPermission ? this.getUserComponent() : <></>}
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userList: state.users.userList,
        getUsersResult: state.users.getUsersResult,
        createUserResult: state.users.createUserResult,
        getUserResult: state.users.getUserResult,
        updateUserResult: state.users.updateUserResult,
        resetUserPasswordResult: state.users.resetUserPasswordResult,
        user: state.users.user,
        tenant: state.userLogin.tenant,
        getUserUsersPermissionResult: state.users.getUserUsersPermissionResult,
        permission: state.users.permission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(actions.getUsersRequest()),
        createUser: (user) => dispatch(actions.createUserRequest(user)),
        getUser: (userId) => dispatch(actions.getUserRequest(userId)),
        updateUser: (userId, updatedUser) => dispatch(actions.updateUserRequest(userId, updatedUser)),
        resetUserPassword: (userId) => dispatch(actions.resetUserPasswordRequest(userId)),
        getUserUsersPermission: () => dispatch(actions.getUserUsersPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));