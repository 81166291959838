export const GET_EVENT_BUSINESS_AREA_USERS_REQUEST = "GET_EVENT_BUSINESS_AREA_USERS_REQUEST";
export const GET_EVENT_BUSINESS_AREA_USERS_SUCCESS = "GET_EVENT_BUSINESS_AREA_USERS_SUCCESS";
export const GET_EVENT_BUSINESS_AREA_USERS_FAILURE = "GET_EVENT_BUSINESS_AREA_USERS_FAILURE";

export const getEventBusinessAreaUsersRequest = (eventId, itemOperation, pageKey) => ({
    type: GET_EVENT_BUSINESS_AREA_USERS_REQUEST,
    params: {
        eventId,
        itemOperation,
        pageKey
    }
})

export const getEventBusinessAreaUsersSuccess = (usersResult) => ({
    type: GET_EVENT_BUSINESS_AREA_USERS_SUCCESS,
    payload: usersResult
})

export const getEventBusinessAreaUsersFailure = (error) => ({
    type: GET_EVENT_BUSINESS_AREA_USERS_FAILURE,
    payload: error
})