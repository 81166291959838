import React, { useState, useEffect } from 'react';
import { Modal, Card, Col, Row, Alert } from 'antd';
import { CopyInput } from "components";

const AppClientSecretModal = ({ show, clientId, secret, onClose }) => {
    const [isVisible, setIsVisible] = useState(show);

    useEffect(() => {
        setIsVisible(show);
    }, [show]);

    const close = () => {
        setIsVisible(false);
    }

    return (
        <Modal
            closable={false}
            title={<Alert message="App client created successfully" type="success" showIcon />}
            centered
            visible={isVisible}
            onOk={close}
            onCancel={close}
            afterClose={onClose}
            destroyOnClose={true}
            width={1000}
            className="model-alert-title">
            <Row>
                <Col span={12}>
                    <Card title="Client ID" bordered={false} size="small">
                        <CopyInput readOnly={true} value={clientId}></CopyInput>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Secret" bordered={false} size="small">
                        <CopyInput readOnly={true} value={secret}></CopyInput>
                    </Card>
                </Col>
            </Row>
        </Modal>
    )
}

export default AppClientSecretModal
