import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Alert, Button } from 'antd';
import { CopyInput } from 'components';

function ConfirmationLinkSuccessModal({ open, okClick }) {
    return <Modal
        centered
        closable={false}
        footer={null}
        forceRender={true}
        keyboard={false}
        maskClosable={false}
        open={open}>
        <>
            <Row className="full-height-container center-align-items">
                <Col span={24}>
                    <Alert
                        message="Email verification link sent successfully"
                        description="Please check your mail box for email verification."
                        type="success"
                        showIcon
                    />
                </Col>
            </Row>
            <Row className="middle-row full-height-container center-align-items">
                <Col span={24}>
                    <Button block type="primary" onClick={okClick}>Ok</Button>
                </Col>
            </Row>
        </>
    </Modal>
}

export default ConfirmationLinkSuccessModal