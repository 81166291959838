import React, { useState } from "react";
import { Layout, Table, Row, Col, Tabs, Tooltip, Statistic, Typography, Spin } from "antd";
import { LockOutlined, UserOutlined, ShoppingOutlined, CreditCardOutlined, FileTextOutlined } from "@ant-design/icons";
import { FullHeightContainerLayout } from 'components';

const { TabPane } = Tabs;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Column, ColumnGroup } = Table;

const AggregationsCount = ({ loading, counts }) => {
    return <>
        <Col span={6} style={{ display: "flex", flexDirection: "column", rowGap: "10px" }} >
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#4472C4" }} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            Business
                                        </Col>
                                        <Col>
                                            Areas
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.activeBusinessAreaCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
                <Col span={11} style={{ background: "#4472C4" }} offset={1} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            Credentials
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.activeCredentialCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#4472C4" }} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            No
                                        </Col>
                                        <Col>
                                            of
                                        </Col>
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            Users
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.activeUserCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
                <Col span={11} style={{ background: "#7F7F7F" }} offset={1} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Calls
                                        </Col>
                                        <Col>
                                            per
                                        </Col>
                                        <Col>
                                            hour
                                        </Col>
                                    </Row>
                                }
                                value={0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
        </Col>
        <Col span={6} style={{ display: "flex", flexDirection: "column", rowGap: "10px" }} >
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#4472C4" }} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            Schemas
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.activeSchemaCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
                <Col span={11} style={{ background: "#4472C4" }} offset={1} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            DataSets
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.activeDataSetCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={24} style={{ background: "#548235" }} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            Records
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.activeDataSetRecordCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
        </Col>
        <Col span={6} style={{ display: "flex", flexDirection: "column", rowGap: "10px" }} >
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#4472C4" }} className="colum-center-aligned-items" >
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Duplicates
                                        </Col>
                                        <Col>
                                            Configurations
                                        </Col>
                                    </Row>
                                }
                                value={(counts && counts.duplicateConfigurationCount) || 0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#BF9000" }} className="colum-center-aligned-items" >
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <>
                                <Statistic
                                    title={
                                        <Row className="domain-service-console-statistics-title">
                                            <Col>
                                                Largest
                                            </Col>
                                            <Col>
                                                Cluster
                                            </Col>
                                        </Row>
                                    }
                                    value={(counts && counts.maxClusterSize) || 0}
                                    className="domain-service-console-statistics" />
                                <>records</>
                            </>
                    }
                </Col>
            </Row>
        </Col>
        <Col span={6} style={{ display: "flex", flexDirection: "column", rowGap: "10px" }} >
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#7F7F7F" }} className="colum-center-aligned-items" >
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Active
                                        </Col>
                                        <Col>
                                            Workflows
                                        </Col>
                                    </Row>
                                }
                                value={0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
            <Row style={{ width: "100%", height: "100%" }} wrap={false}>
                <Col span={12} style={{ background: "#7F7F7F" }} className="colum-center-aligned-items" >
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Waiting
                                        </Col>
                                        <Col>
                                            Approval
                                        </Col>
                                    </Row>
                                }
                                value={0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
                <Col span={11} style={{ background: "#7F7F7F" }} offset={1} className="colum-center-aligned-items">
                    {
                        loading
                            ?
                            <Spin className="domain-service-console-spinner" />
                            :
                            <Statistic
                                title={
                                    <Row className="domain-service-console-statistics-title">
                                        <Col>
                                            Days
                                        </Col>
                                        <Col>
                                            Waiting
                                        </Col>
                                        <Col>
                                            Avg
                                        </Col>
                                    </Row>
                                }
                                value={0}
                                className="domain-service-console-statistics" />
                    }
                </Col>
            </Row>
        </Col>
    </>
};

export default AggregationsCount;