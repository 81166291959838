import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button, Spin } from 'antd';
import { LoadingOverlay, BusinessAreaSchemaTree, FullHeightContainerLayout, SchemaCloneForm } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import { FolderOutlined, FileTextOutlined } from '@ant-design/icons';
import _ from 'lodash';
import AppPaths from 'constants/appPaths';
import flatten from 'flat';

class SchemaModelClone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            busyMessage: "Fetching data...",
            expandedNodes: [],
            selectedNodes: [],
            treeData: [],
            allowClone: false,
            cloneSchema: {
                name: "",
                description: ""
            },
            schemasDict: {},
            cloneSchemaModelErrors: {}
        }
    }

    componentWillMount() {
        this.props.getBusinessAreaSchemaTree();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserBusinessAreasResultChange(prevProps);
        this.onCreateSchemaCloneRequestResultChange(prevProps);
    }

    onGetUserBusinessAreasResultChange(prevProps) {
        if (this.props.getBusinessAreaSchemaTreeResult && this.props.getBusinessAreaSchemaTreeResult !== prevProps.getBusinessAreaSchemaTreeResult) {
            if (!this.props.getBusinessAreaSchemaTreeResult.success) {
                if (this.props.getBusinessAreaSchemaTreeResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied!");
                }
                else {
                    showError("Could not able to get Business Areas or Schemas at this moment.");
                }
            }
            else {
                let expandedNodes = [];
                let schemasDict = { ...this.state.schemasDict };
                let treeData = this.props.businessAreaSchemaTree.map(businessArea => {
                    let node = {
                        title: businessArea.name,
                        key: businessArea.businessAreaId,
                        icon: businessArea.loadingSchema ? <Spin className="inline-spin-text" size="small"></Spin> : <FolderOutlined />,
                        children: [],
                        checkable: false
                    }
                    if (businessArea.schemaModels && businessArea.schemaModels.length > 0) {
                        expandedNodes.push(businessArea.businessAreaId);
                        for (let schemaModel of businessArea.schemaModels) {
                            schemasDict[schemaModel.schemaId] = schemaModel;
                            node.children.push({
                                title: schemaModel.name,
                                key: schemaModel.schemaId,
                                icon: <FileTextOutlined />,
                                checkable: true
                            })
                        }
                    }
                    return node;
                })
                this.setState({
                    treeData,
                    expandedNodes,
                    schemasDict
                })
            }
        }
    }

    onCreateSchemaCloneRequestResultChange(prevProps) {
        if (this.props.createSchemaCloneRequestResult && this.props.createSchemaCloneRequestResult !== prevProps.createSchemaCloneRequestResult) {
            if (!this.props.createSchemaCloneRequestResult.success) {
                if (this.props.createSchemaCloneRequestResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied!");
                }
                else {
                    this.setState({
                        cloneSchemaModelErrors: this.props.createSchemaCloneRequestResult.fields,
                    })
                    showError("Could not able to create clone of schema at this moment.");
                }

                this.setState({
                    isBusy: false
                });
            }
            else {
                this.setState({
                    busyMessage: "Schema model cloned. Please wait..."
                });
                showSuccess("Schema model cloned successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase()));
                });
            }
        }
    }

    onSchemaSelect = (selectedSchemaIds) => {
        let selectedNodes = [];
        let cloneSchema = {
            name: "",
            description: ""
        };
        for (let schemaId of selectedSchemaIds) {
            if (this.state.selectedNodes.indexOf(schemaId) === -1) {
                selectedNodes.push(schemaId);
                cloneSchema = {
                    name: this.props.activeBusinessAreaId !== this.state.schemasDict[schemaId].businessAreaId ? this.state.schemasDict[schemaId].name : `${this.state.schemasDict[schemaId].name} Clone`,
                    description: this.state.schemasDict[schemaId].description,
                    schemaId: this.state.schemasDict[schemaId].schemaId,
                    businessAreaId: this.state.schemasDict[schemaId].businessAreaId
                }
                break;
            }
        }
        this.setState({
            selectedNodes,
            allowClone: selectedNodes.length > 0,
            cloneSchema
        });
    }

    onCloneSchemaModel = (schemaModel) => {
        this.setState({
            isBusy: true,
            busyMessage: "Clonning schema model..."
        });
        this.props.createSchemaClone(schemaModel);
    }

    onCloneSchemaModelValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.cloneSchemaModelErrors) === false) {
            let cloneSchemaModelErrors = { ...this.state.cloneSchemaModelErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete cloneSchemaModelErrors[key];
            }
            this.setState({
                cloneSchemaModelErrors
            })
        }
    }

    onNodeExpand = (expandedKeys) => {
        this.setState({
            expandedNodes: expandedKeys
        })
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={true}
                content={
                    <>
                        <LoadingOverlay
                            busy={this.props.getBusinessAreaSchemaTreeResult.inProgress || this.state.isBusy}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        <BusinessAreaSchemaTree
                            expandedNodes={this.state.expandedNodes}
                            treeData={this.state.treeData}
                            onSchemaSelect={this.onSchemaSelect}
                            selectedNodes={this.state.selectedNodes}
                            onNodeExpand={this.onNodeExpand}>
                        </BusinessAreaSchemaTree>
                    </>
                }
                footer={<SchemaCloneForm
                    formErrors={this.state.cloneSchemaModelErrors}
                    onValuesChanged={this.onCloneSchemaModelValuesChanged}
                    allowClone={this.state.allowClone}
                    schema={this.state.cloneSchema}
                    onCloneSchemaModel={this.onCloneSchemaModel}>
                </SchemaCloneForm>}>
            </FullHeightContainerLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getBusinessAreaSchemaTreeResult: state.schemaModelClone.getBusinessAreaSchemaTreeResult,
        businessAreaSchemaTree: state.schemaModelClone.businessAreaSchemaTree,
        createSchemaCloneRequestResult: state.schemaModelClone.createSchemaCloneRequestResult,
        activeBusinessAreaId: state.home.activeBusinessAreaId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessAreaSchemaTree: () => dispatch(actions.getBusinessAreaSchemaTreeRequest()),
        createSchemaClone: (schemaModel) => dispatch(actions.createSchemaCloneRequest(schemaModel))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModelClone));