import {
    GET_EVENT_BUSINESS_AREA_APP_CLIENTS_SUCCESS,
    GET_EVENT_BUSINESS_AREA_APP_CLIENTS_FAILURE
} from "./actions";

const initialState = {
    getEventBusinessAreaAppClientsResult: {
        success: true
    },
    appClientsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    let appClientsResult = {
        Items: []
    };
    switch (action.type) {
        case GET_EVENT_BUSINESS_AREA_APP_CLIENTS_SUCCESS:
            appClientsResult = action.payload.appClientsResult;
            return { ...state, getEventBusinessAreaAppClientsResult: { success: true }, appClientsResult };
        case GET_EVENT_BUSINESS_AREA_APP_CLIENTS_FAILURE:
            return {
                ...state,
                getEventBusinessAreaAppClientsResult: { success: false, ...action.payload.error },
                appClientsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
