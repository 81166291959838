import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { BusinessAreaUserHistoryList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';

const { Title, Text } = Typography;

class EventBusinessAreaUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        if (this.props.itemOperation && this.props.eventType === "BATCH_PROCESSING") {
            let headerText = "";
            let headerDetails = `(Business Area: ${this.props.businessAreaName})`
            switch (this.props.itemOperation) {
                case "INSERT":
                    headerText = "Added business area users"
                    break;
                case "DELETE":
                    headerText = "Deleted business area users"
                    break;
                case "UPDATE":
                    headerText = "Updated business area users"
                    break;
            }
            this.setState({
                headerText,
                headerDetails
            });
            this.setState({
                busy: true,
                busyMessage: "Getting business area users...",
            });
            this.props.getEventBusinessAreaUsers(this.props.eventId, this.props.itemOperation, null);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventBusinessAreaUsersResultUpdate(prevProps);
    }

    onGetEventBusinessAreaUsersResultUpdate = (prevProps) => {
        if (this.props.getEventBusinessAreaUsersResult && this.props.getEventBusinessAreaUsersResult !== prevProps.getEventBusinessAreaUsersResult) {
            if (!this.props.getEventBusinessAreaUsersResult.success) {
                if (this.props.getEventBusinessAreaUsersResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area users at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Batch - </Text><Text>{this.state.headerText}</Text><Text> - </Text><Text type="secondary">{this.state.headerDetails}</Text></Title>}
                            content={this.props.usersResult ?
                                <BusinessAreaUserHistoryList
                                    businessAreaUserRevisions={this.props.usersResult.Items}>
                                </BusinessAreaUserHistoryList>
                                :
                                <Empty></Empty>}>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }

            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventBusinessAreaUsersResult: state.eventBusinessAreaUsers.getEventBusinessAreaUsersResult,
        usersResult: state.eventBusinessAreaUsers.usersResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBusinessAreaUsers: (eventId, itemOperation, pageKey) => dispatch(actions.getEventBusinessAreaUsersRequest(eventId, itemOperation, pageKey))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventBusinessAreaUsers));