export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const GET_PERMISSIONS_MASTER_DATA_REQUEST = "GET_PERMISSIONS_MASTER_DATA_REQUEST";
export const GET_PERMISSIONS_MASTER_DATA_SUCCESS = "GET_PERMISSIONS_MASTER_DATA_SUCCESS";
export const GET_PERMISSIONS_MASTER_DATA_FAILURE = "GET_PERMISSIONS_MASTER_DATA_FAILURE";

export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const GET_ROLE_BY_ID_REQUEST = "GET_ROLE_BY_ID_REQUEST";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";
export const GET_ROLE_BY_ID_FAILURE = "GET_ROLE_BY_ID_FAILURE";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const ACTIVATE_DEACTIVATE_ROLE_REQUEST = "ACTIVATE_DEACTIVATE_ROLE_REQUEST";
export const ACTIVATE_DEACTIVATE_ROLE_SUCCESS = "ACTIVATE_DEACTIVATE_ROLE_SUCCESS";
export const ACTIVATE_DEACTIVATE_ROLE_FAILURE = "ACTIVATE_DEACTIVATE_ROLE_FAILURE";

export const GET_USER_ROLES_PERMISSION_REQUEST = "GET_USER_ROLES_PERMISSION_REQUEST";
export const GET_USER_ROLES_PERMISSION_SUCCESS = "GET_USER_ROLES_PERMISSION_SUCCESS";
export const GET_USER_ROLES_PERMISSION_FAILURE = "GET_USER_ROLES_PERMISSION_FAILURE";

export const getRolesRequest = () => ({
    type: GET_ROLES_REQUEST
})

export const getRolesSuccess = (roles) => ({
    type: GET_ROLES_SUCCESS,
    payload: roles
})

export const getRolesFailure = (error) => ({
    type: GET_ROLES_FAILURE,
    payload: error
})

export const getPermissionMasterDataRequest = () => ({
    type: GET_PERMISSIONS_MASTER_DATA_REQUEST
})

export const getPermissionMasterDataSuccess = (permissions) => ({
    type: GET_PERMISSIONS_MASTER_DATA_SUCCESS,
    payload: permissions
})

export const getPermissionMasterDataFailure = (error) => ({
    type: GET_PERMISSIONS_MASTER_DATA_FAILURE,
    payload: error
})

export const createRolesRequest = (role, permissions, users, appClients) => ({
    type: CREATE_ROLE_REQUEST,
    params: {
        role,
        permissions,
        users,
        appClients
    }
})

export const createRolesSuccess = (role) => ({
    type: CREATE_ROLE_SUCCESS,
    payload: role
})

export const createRolesFailure = (error) => ({
    type: CREATE_ROLE_FAILURE,
    payload: error
})

export const getRoleByIdRequest = (roleId) => ({
    type: GET_ROLE_BY_ID_REQUEST,
    params: {
        roleId
    }
})

export const getRoleByIdSuccess = (role) => ({
    type: GET_ROLE_BY_ID_SUCCESS,
    payload: role
})

export const getRoleByIdFailure = (error) => ({
    type: GET_ROLE_BY_ID_FAILURE,
    payload: error
})

export const updateRoleRequest = (updateData, roleId) => ({
    type: UPDATE_ROLE_REQUEST,
    params: {
        updateData,
        roleId
    }
})

export const updateRoleSuccess = () => ({
    type: UPDATE_ROLE_SUCCESS
})

export const updateRoleFailure = (error) => ({
    type: UPDATE_ROLE_FAILURE,
    payload: error
})

export const deleteRoleRequest = (roleId) => ({
    type: DELETE_ROLE_REQUEST,
    params: {
        roleId
    }
})

export const deleteRoleSuccess = () => ({
    type: DELETE_ROLE_SUCCESS
})

export const deleteRoleFailure = (error) => ({
    type: DELETE_ROLE_FAILURE,
    payload: error
})

export const activateDeactivateRoleRequest = (roleId, roleVersion, roleActive) => ({
    type: ACTIVATE_DEACTIVATE_ROLE_REQUEST,
    params: {
        roleId,
        roleActive,
        roleVersion
    }
})

export const activateDeactivateRoleSuccess = (roleId) => ({
    type: ACTIVATE_DEACTIVATE_ROLE_SUCCESS,
    payload: {
        roleId
    }
})

export const activateDeactivateRoleFailure = (error, roleId) => ({
    type: ACTIVATE_DEACTIVATE_ROLE_FAILURE,
    payload: {
        error,
        roleId
    }
})

export const getUserRolesPermissionRequest = () => ({
    type: GET_USER_ROLES_PERMISSION_REQUEST
})

export const getUserRolesPermissionSuccess = (permission) => ({
    type: GET_USER_ROLES_PERMISSION_SUCCESS,
    payload: permission
})

export const getUserRolesPermissionFailure = (error) => ({
    type: GET_USER_ROLES_PERMISSION_FAILURE,
    payload: error
})