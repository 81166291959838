
import { put, call, takeEvery, select, takeLatest, all } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEventDataObjectList(api, tenantId, eventId, itemOperation, pageKey) {
    try {
        let params = {
            operation: itemOperation
        };
        if (pageKey) {
            params.pageKey = pageKey
        }

        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/dataobjects`, { tenantid: tenantId }, { params });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getEventDataObject(api, tenantId, eventId, dataObjectId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/dataobjects/${dataObjectId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getEvent(api, tenantId, eventKey) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventKey}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}


export function* getEventDataObjectListRequest(api, { eventId, itemOperation, pageKey, eventKey }) {
    try {
        let tenant = yield select(getTenant);
        const [dataObjectResult, event] = yield all([
            call(getEventDataObjectList, api, tenant.tenantId, eventId, itemOperation, pageKey),
            call(getEvent, api, tenant.tenantId, eventKey)
        ]);
        if (dataObjectResult) {
            dataObjectResult.schemaFields = [];
            if (event && event.details && event.details.schemaFields) {
                dataObjectResult.schemaFields = event.details.schemaFields;
            }
        }
        yield put(actions.getEventDataObjectListSuccess(dataObjectResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event data object list");
        yield put(actions.getEventDataObjectListFailure(errorObject));
    }
}

export function* getEventDataObjectRequest(api, { eventId, dataObjectId, eventKey }) {
    try {
        let tenant = yield select(getTenant);
        const [dataObjectRevision, event] = yield all([
            call(getEventDataObject, api, tenant.tenantId, eventId, dataObjectId),
            call(getEvent, api, tenant.tenantId, eventKey)
        ]);
        if (dataObjectRevision) {
            dataObjectRevision.schemaFields = [];
            if (event && event.details && event.details.schemaFields) {
                dataObjectRevision.schemaFields = event.details.schemaFields;
            }
        }
        yield put(actions.getEventDataObjectSuccess(dataObjectRevision));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event dataobject details");
        yield put(actions.getEventDataObjectFailure(errorObject));
    }
}

export function* watchGetEventDataObjectListRequest(api, { params }) {
    yield call(getEventDataObjectListRequest, api, params);
}

export function* watchGetEventDataObjectRequest(api, { params }) {
    yield call(getEventDataObjectRequest, api, params);
}


export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_DATA_OBJECT_LIST_REQUEST, watchGetEventDataObjectListRequest, api);
    yield takeLatest(actions.GET_EVENT_DATA_OBJECT_REQUEST, watchGetEventDataObjectRequest, api);
}