export const GET_APP_CLIENTS_REQUEST = "GET_APP_CLIENTS_REQUEST";
export const GET_APP_CLIENTS_SUCCESS = "GET_APP_CLIENTS_SUCCESS";
export const GET_APP_CLIENTS_FAILURE = "GET_APP_CLIENTS_FAILURE";

export const CREATE_APP_CLIENT_REQUEST = "CREATE_APP_CLIENT_REQUEST";
export const CREATE_APP_CLIENT_SUCCESS = "CREATE_APP_CLIENT_SUCCESS";
export const CREATE_APP_CLIENT_FAILURE = "CREATE_APP_CLIENT_FAILURE";

export const GET_APP_CLIENT_REQUEST = "GET_APP_CLIENT_REQUEST";
export const GET_APP_CLIENT_SUCCESS = "GET_APP_CLIENT_SUCCESS";
export const GET_APP_CLIENT_FAILURE = "GET_APP_CLIENT_FAILURE";

export const UPDATE_APP_CLIENT_REQUEST = "UPDATE_APP_CLIENT_REQUEST";
export const UPDATE_APP_CLIENT_SUCCESS = "UPDATE_APP_CLIENT_SUCCESS";
export const UPDATE_APP_CLIENT_FAILURE = "UPDATE_APP_CLIENT_FAILURE";

export const DELETE_APP_CLIENT_REQUEST = "DELETE_APP_CLIENT_REQUEST";
export const DELETE_APP_CLIENT_SUCCESS = "DELETE_APP_CLIENT_SUCCESS";
export const DELETE_APP_CLIENT_FAILURE = "DELETE_APP_CLIENT_FAILURE";

export const REGENERATE_APP_CLIENT_SECRET_REQUEST = "REGENERATE_APP_CLIENT_SECRET_REQUEST";
export const REGENERATE_APP_CLIENT_SECRET_SUCCESS = "REGENERATE_APP_CLIENT_SECRET_SUCCESS";
export const REGENERATE_APP_CLIENT_SECRET_FAILURE = "REGENERATE_APP_CLIENT_SECRET_FAILURE";

export const GET_APP_CLIENT_PERMISSIONS_REQUEST = "GET_APP_CLIENT_PERMISSIONS_REQUEST";
export const GET_APP_CLIENT_PERMISSIONS_SUCCESS = "GET_APP_CLIENT_PERMISSIONS_SUCCESS";
export const GET_APP_CLIENT_PERMISSIONS_FAILURE = "GET_APP_CLIENT_PERMISSIONS_FAILURE";

export const getAppClientsRequest = () => ({
    type: GET_APP_CLIENTS_REQUEST
})

export const getAppClientsSuccess = (result) => ({
    type: GET_APP_CLIENTS_SUCCESS,
    payload: {
        result
    }
})

export const getAppClientsFailure = (error) => ({
    type: GET_APP_CLIENTS_FAILURE,
    payload: {
        error
    }
})

export const createAppClientRequest = (appClient) => ({
    type: CREATE_APP_CLIENT_REQUEST,
    params: {
        appClient
    }
})

export const createAppClientSuccess = (appClient) => ({
    type: CREATE_APP_CLIENT_SUCCESS,
    payload: {
        appClient
    }
})

export const createAppClientFailure = (error) => ({
    type: CREATE_APP_CLIENT_FAILURE,
    payload: {
        error
    }
})

export const getAppClientRequest = (clientId) => ({
    type: GET_APP_CLIENT_REQUEST,
    params: {
        clientId
    }
})

export const getAppClientSuccess = (appClient) => ({
    type: GET_APP_CLIENT_SUCCESS,
    payload: {
        appClient
    }
})

export const getAppClientFailure = (error) => ({
    type: GET_APP_CLIENT_FAILURE,
    payload: {
        error
    }
})

export const updateAppClientRequest = (clientId, updatedAppClient) => ({
    type: UPDATE_APP_CLIENT_REQUEST,
    params: {
        clientId,
        updatedAppClient
    }
})

export const updateAppClientSuccess = (appClient) => ({
    type: UPDATE_APP_CLIENT_SUCCESS,
    payload: {
        appClient
    }
})

export const updateAppClientFailure = (error) => ({
    type: UPDATE_APP_CLIENT_FAILURE,
    payload: {
        error
    }
})

export const deleteAppClientRequest = (clientId) => ({
    type: DELETE_APP_CLIENT_REQUEST,
    params: {
        clientId
    }
})

export const deleteAppClientSuccess = (clientId) => ({
    type: DELETE_APP_CLIENT_SUCCESS,
    payload: {
        clientId
    }
})

export const deleteAppClientFailure = (error) => ({
    type: DELETE_APP_CLIENT_FAILURE,
    payload: {
        error
    }
})

export const regenerateAppClientSecretRequest = (clientId, version) => ({
    type: REGENERATE_APP_CLIENT_SECRET_REQUEST,
    params: {
        clientId,
        version
    }
})

export const regenerateAppClientSecretSuccess = (appClient) => ({
    type: REGENERATE_APP_CLIENT_SECRET_SUCCESS,
    payload: {
        appClient
    }
})

export const regenerateAppClientSecretFailure = (error) => ({
    type: REGENERATE_APP_CLIENT_SECRET_FAILURE,
    payload: {
        error
    }
})

export const getAppClientPermissionRequest = () => ({
    type: GET_APP_CLIENT_PERMISSIONS_REQUEST
})

export const getAppClientPermissionSuccess = (permission) => ({
    type: GET_APP_CLIENT_PERMISSIONS_SUCCESS,
    payload: {
        permission
    }
})

export const getAppClientPermissionFailure = (error) => ({
    type: GET_APP_CLIENT_PERMISSIONS_FAILURE,
    payload: {
        error
    }
})
