import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, LineageView2, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import AppPaths from 'constants/appPaths';

const { TabPane } = Tabs;

class SchemaModelLineage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingLineage: true
        }
    }

    componentWillMount() {
        this.props.getSchemaModelLineage(this.props.schemaModel.schemaId);
        if (!this.props.businessAreaDataPermission) {
            this.props.getUserBusinessAreaDataPermission();
        }
        if (!this.props.schemaPermission) {
            this.props.getUserSchemasPermission();
        }
        if (!this.props.dataSetPermission) {
            this.props.getUserDataSetPermission();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetSchemaModelLineageResultChanged(prevProps);
    }

    onGetSchemaModelLineageResultChanged(prevProps) {
        if (this.props.getSchemaModelLineageResult && this.props.getSchemaModelLineageResult !== prevProps.getSchemaModelLineageResult) {
            this.setState({
                fetchingLineage: false
            });
        }
    }

    onNodeActionClick = (action, node) => {
        switch (node.type) {
            case "DATA_DOMAIN":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":configurationItem", "businessareadata")
                        .replace(":itemId", node.id)
                        .replace(":itemAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":configurationItem", "businessareadata")
                        .replace(":itemId", node.id)
                        .replace(":itemAction", "edit"));
                }
                break;
            case "SCHEMA":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase())
                        .replace(":schemaId", node.id)
                        .replace(":schemaAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase())
                        .replace(":schemaId", node.id)
                        .replace(":schemaAction", "edit"));
                }
                break;
            case "DATA_SET":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase())
                        .replace(":schema", node.parentNode.name.toLowerCase())
                        .replace(":dataSetId", node.id)
                        .replace(":dataSetAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase())
                        .replace(":schema", node.parentNode.name.toLowerCase())
                        .replace(":dataSetId", node.id)
                        .replace(":dataSetAction", "edit"));
                }
                break;
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={<>
                    <LoadingOverlay
                        busy={this.state.fetchingLineage
                            || !this.props.businessAreaDataPermission
                            || !this.props.schemaPermission
                            || !this.props.dataSetPermission}
                        spinner
                        message={"Please wait..."}>
                    </LoadingOverlay>
                    {
                        this.props.schemaModelLineageResult
                            ?
                            <LineageView2
                                lineageNodes={this.props.schemaModelLineageResult}
                                businessAreaDataPermission={this.props.businessAreaDataPermission}
                                schemaPermission={this.props.schemaPermission}
                                dataSetPermission={this.props.dataSetPermission}
                                onNodeActionClick={this.onNodeActionClick}
                                treeInitNodeFilter={["DATA_DOMAIN", "SCHEMA", "DATA_SET", "JOB"]}></LineageView2>
                            :
                            <></>
                    }
                </>}>
            </FullHeightContainerLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getSchemaModelLineageResult: state.lineage.getSchemaModelLineageResult,
        schemaModelLineageResult: state.lineage.schemaModelLineageResult,
        businessAreaDataPermission: state.businessAreaData.permission,
        schemaPermission: state.schemaModels.permission,
        dataSetPermission: state.dataSets.permission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSchemaModelLineage: (schemaId) => dispatch(actions.getSchemaModelLineageRequest(schemaId)),
        getUserBusinessAreaDataPermission: () => dispatch(actions.getUserBusinessAreaDataPermissionRequest()),
        getUserSchemasPermission: () => dispatch(actions.getUserSchemaModelsPermissionRequest()),
        getUserDataSetPermission: () => dispatch(actions.getUserDataSetPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModelLineage));