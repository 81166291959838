import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { basename } from 'config';
import App from 'components/App';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './assets/css/masterstyle.css';
import './assets/css/semantic-ui-styles.css';
import './assets/css/antd-styles.css';
import awsconfig from './aws-exports';
import store from 'store';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
AWS.config.update({
    correctClockSkew: true
});

const renderApp = () => (
  <Provider store={store}>
    <BrowserRouter basename={basename}>
      <App />
    </BrowserRouter>
  </Provider>
)

const container = document.getElementById('app');
const root = createRoot(container);
root.render(renderApp());
