import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, List, Space, Button, Table, Checkbox, Tooltip } from "antd";
import moment from 'moment';

const { Title, Paragraph, Text, Link } = Typography;

const DataObjectVerificationEventListItem = ({ eventType, verificationItem }) => {

    return (
        <List.Item key={`${verificationItem.verificationId}_${verificationItem.version}`}>
            {
                verificationItem.consentItem
                    ?
                    <Typography>
                        <Paragraph>
                            <Text type="secondary">{`Record verification completed for schema fields: ${verificationItem.fields.map(field => field.name).join(", ")}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            {verificationItem.consentItem.consent
                                ?
                                <Text type="secondary">{`and consent was granted by: ${verificationItem.consentItem.email}`}</Text>
                                :
                                <Text type="secondary">{`but consent was not provided by: ${verificationItem.consentItem.email}`}</Text>
                            }
                        </Paragraph>
                        <Paragraph>
                            <Text type="secondary">{`on ${moment.utc(verificationItem.updatedDate).toDate().toLocaleString()}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text type="secondary">{`under IP Address: ${verificationItem.consentItem.ipAddress}`}</Text>
                        </Paragraph>
                    </Typography>
                    :
                    <Typography>
                        <Paragraph>
                            <Text type="secondary">{`Verification requested on ${moment.utc(verificationItem.updatedDate).toDate().toLocaleString()}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text type="secondary">{`for schema fields: ${verificationItem.fields.map(field => field.name).join(", ")}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            {verificationItem.consent
                                ?
                                <Text type="secondary">{"with consent option"}</Text>
                                :
                                <Text type="secondary">{`without consent option`}</Text>
                            }
                        </Paragraph>
                    </Typography>
            }
        </List.Item>
    );
}

export default DataObjectVerificationEventListItem;