import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Switch, Space, Button, Table, Checkbox, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import moment from 'moment';

const { Meta } = Card;
const { Text, Link } = Typography;
const { Column } = Table;

const AwsS3BucketList = ({ buckets, onBucketSelected }) => {
    const [s3Buckets, setS3Buckets] = useState(buckets);

    useEffect(() => {
        setS3Buckets(buckets);
    }, [buckets]);

    const onBucketItemClick = (bucket, index) => {
        let currentS3Buckets = [...s3Buckets];
        for (let i = 0; i < currentS3Buckets.length; i++) {
            if (index === i) {
                currentS3Buckets[i] = { ...currentS3Buckets[i], selected: true };
            }
            else {
                currentS3Buckets[i] = { ...currentS3Buckets[i], selected: false };
            }
        }
        setS3Buckets(currentS3Buckets);
        onBucketSelected(bucket);
    }

    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={false}
                    content={
                        <Table size="small"
                            rowKey="path"
                            dataSource={s3Buckets}
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            className="container-height-100">
                            <Column
                                dataIndex="name"
                                title="Name"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, bucket, index) => {
                                    return <Tooltip placement="topLeft" title={value}>
                                        <Space>
                                            <img alt={bucket.Name} src="/icons/aws/Arch_Amazon-Simple-Storage-Service_16.png" />
                                            <Link onClick={() => onBucketItemClick(bucket, index)}>
                                                {bucket.Name}
                                            </Link>
                                        </Space>
                                    </Tooltip>
                                }}
                            />
                            <Column
                                dataIndex="CreationDate"
                                title="Creation Date"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, bucket, index) => {
                                    return <Tooltip placement="topLeft" title={value}>
                                        <Text>{moment.utc(value).toDate().toLocaleString()}</Text>
                                    </Tooltip>
                                }}
                            />
                        </Table>}>

                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
}

export default AwsS3BucketList;