export const GET_PICKLIST_DOMAIN_DATA_REQUEST = "GET_PICKLIST_DOMAIN_DATA_REQUEST";
export const GET_PICKLIST_DOMAIN_DATA_SUCCESS = "GET_PICKLIST_DOMAIN_DATA_SUCCESS";
export const GET_PICKLIST_DOMAIN_DATA_FAILURE = "GET_PICKLIST_DOMAIN_DATA_FAILURE";

export const getPicklistDomainDataRequest = (dataDomainId, pageKey) => ({
    type: GET_PICKLIST_DOMAIN_DATA_REQUEST,
    params: {
        dataDomainId,
        pageKey
    }
})

export const getPicklistDomainDataSuccess = (dataDomainId, domainDataResult) => ({
    type: GET_PICKLIST_DOMAIN_DATA_SUCCESS,
    payload: {
        dataDomainId,
        domainDataResult
    }
})

export const getPicklistDomainDataFailure = (dataDomainId, error) => ({
    type: GET_PICKLIST_DOMAIN_DATA_FAILURE,
    payload: {
        dataDomainId,
        error
    }
})