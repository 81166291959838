
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* getSystemConnections(api) {
    let result = yield call([api, api.get], `${Endpoints.systemConnections}/api/v1/connections`);
    return result.data;
}

export function* getSystemConnectionPermission(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/systemconnections`, { tenantid: tenantId });
    return result.data;
}

export function* getSystemConnectionsRequest(api) {
    try {
        const systemConnectionsResult = yield call(getSystemConnections, api);
        yield put(actions.getSystemConnectionsSuccess(systemConnectionsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "system connections");
        yield put(actions.getSystemConnectionsFailure(errorObject));
    }
}

export function* getSystemConnectionPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getSystemConnectionPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getSystemConnectionPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user system connection permission");
        yield put(actions.getSystemConnectionPermissionFailure(errorObject));
    }
}


export function* watchGetSystemConnectionsRequest(api, params) {
    yield call(getSystemConnectionsRequest, api);
}

export function* watchGetSystemConnectionPermissionRequest(api, { params }) {
    yield call(getSystemConnectionPermissionRequest, api);
}

export default function* ({ api }) {
    yield takeEvery(actions.GET_SYSTEM_CONNECTIONS_REQUEST, watchGetSystemConnectionsRequest, api);
    yield takeEvery(actions.GET_SYSTEM_CONNECTION_PERMISSIONS_REQUEST, watchGetSystemConnectionPermissionRequest, api);
}
