import React from "react";
import { connect } from "react-redux";
import { Icon } from 'semantic-ui-react';
import { withRouter } from "react-router";
import AppPaths from 'constants/appPaths';
import { Layout, Card, Avatar, Row, Col, Form, Input, Space, Button } from "antd";

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;

class UserProfileDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDefault: {
                name: '',
                email: '',
                about: ''
            },
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.user) {
            this.formRef.current.setFieldsValue({
                name: `${this.props.user.firstName} ${this.props.user.lastName}`,
                email: this.props.user.email,
                about: "Hello, I am the admin user."
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Card
                size="small"
                title={`${this.props.user.firstName} ${this.props.user.lastName}'s Profile Settings`}
                style={{
                    width: "100%",
                    height: "100%"
                }}>
                <Layout className="layout-user-profile-details">
                    <Sider width={100} className="sider-user-profile-details">
                        <Row style={{ width: "100%", height: "100%" }}>
                            <Col span={24} className="user-profile-details-avatar-content">
                                <Avatar size={64} src="/icons/profile_avatar.png" />
                            </Col>
                        </Row>
                    </Sider>
                    <Layout className="layout-user-profile-details">
                        <Content>
                            <Form
                                layout="vertical"
                                ref={this.formRef}
                                name="basic"
                                initialValues={this.state.userDefault}>
                                <Form.Item
                                    className="wrap-label"
                                    label={"Name"}
                                    name={["name"]}>
                                    <Input readOnly={true} />
                                </Form.Item>
                                <Form.Item
                                    className="wrap-label"
                                    label={"Email Address"}
                                    name={["email"]}>
                                    <Input readOnly={true} />
                                </Form.Item>
                                <Form.Item
                                    className="wrap-label"
                                    label={"About"}
                                    name={["about"]}>
                                    <TextArea readOnly={true} />
                                </Form.Item>
                                <Row gutter={[16, 16]}>
                                    <Col span={24} className="footer-right-column">
                                        <Space>
                                            <Button type="primary">Save</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                        </Content>
                    </Layout>
                </Layout>
            </Card>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.userLogin.tenant,
        user: state.userLogin.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfileDetails));