import {
    GET_CDC_DESTINATIONS_SUCCESS,
    GET_CDC_DESTINATIONS_FAILURE,

    CREATE_CDC_DESTINATION_SUCCESS,
    CREATE_CDC_DESTINATION_FAILURE,

    GET_CDC_DESTINATION_SUCCESS,
    GET_CDC_DESTINATION_FAILURE,

    UPDATE_CDC_DESTINATION_SUCCESS,
    UPDATE_CDC_DESTINATION_FAILURE,

    DELETE_CDC_DESTINATION_SUCCESS,
    DELETE_CDC_DESTINATION_FAILURE,

    GET_CDC_DESTINATION_PERMISSIONS_SUCCESS,
    GET_CDC_DESTINATION_PERMISSIONS_FAILURE
} from "./actions";

const initialState = {
    getCDCDestinationsResult: {
        success: true
    },
    cdcDestinationListResult: {
        Items: []
    },
    createCDCDestinationResult: {
        success: true
    },
    createdCDCDestination: null,

    getCDCDestinationResult: {
        success: true
    },
    cdcDestination: null,
    updateCDCDestinationResult: {
        success: true
    },
    deleteCDCDestinationResult: {
        success: true
    },
    getCDCDestinationPermissionResult: {
        success: true,
    },
    userCDCDestinationPermission: null,
};

export default (state = initialState, action) => {
    let cdcDestinationListResult = {
        Items: []
    };
    let cdcDestinationIndex = -1;
    switch (action.type) {
        case GET_CDC_DESTINATIONS_SUCCESS:
            return { ...state, getCDCDestinationsResult: { success: true }, cdcDestinationListResult: action.payload.result };
        case GET_CDC_DESTINATIONS_FAILURE:
            return { ...state, getCDCDestinationsResult: { success: false, ...action.payload.error }, cdcDestinationListResult: { Items: [] } };
        case CREATE_CDC_DESTINATION_SUCCESS:
            return { ...state, createCDCDestinationResult: { success: true }, createdCDCDestination: action.payload.cdcDestination };
        case CREATE_CDC_DESTINATION_FAILURE:
            return { ...state, createCDCDestinationResult: { success: false, ...action.payload.error }, createdCDCDestination: null };
        case GET_CDC_DESTINATION_SUCCESS:
            return { ...state, getCDCDestinationResult: { success: true }, cdcDestination: action.payload.cdcDestination };
        case GET_CDC_DESTINATION_FAILURE:
            return { ...state, getCDCDestinationResult: { success: false, ...action.payload.error }, cdcDestination: null };
        case UPDATE_CDC_DESTINATION_SUCCESS:
            cdcDestinationListResult = state.cdcDestinationListResult;
            if (action.payload.cdcDestination) {
                cdcDestinationIndex = cdcDestinationListResult.Items.findIndex(item => item.destinationId === action.payload.cdcDestination.destinationId);
                if (cdcDestinationIndex > -1) {
                    cdcDestinationListResult = { ...cdcDestinationListResult };
                    cdcDestinationListResult.Items = [...cdcDestinationListResult.Items];
                    cdcDestinationListResult.Items[cdcDestinationIndex] = action.payload.cdcDestination;
                }
            }
            return { ...state, updateCDCDestinationResult: { success: true }, cdcDestinationListResult };
        case UPDATE_CDC_DESTINATION_FAILURE:
            return { ...state, updateCDCDestinationResult: { success: false, ...action.payload.error } };
        case DELETE_CDC_DESTINATION_SUCCESS:
            cdcDestinationListResult = { ...state.cdcDestinationListResult };
            cdcDestinationListResult.Items = state.cdcDestinationListResult.Items.filter(item => item.destinationId !== action.payload.cdcDestinationId);
            return { ...state, deleteCDCDestinationResult: { success: true }, cdcDestinationListResult };
        case DELETE_CDC_DESTINATION_FAILURE:
            return { ...state, deleteCDCDestinationResult: { success: false, ...action.payload.error } };
        case GET_CDC_DESTINATION_PERMISSIONS_SUCCESS:
            return { ...state, getCDCDestinationPermissionResult: { success: true }, userCDCDestinationPermission: action.payload.permission };
        case GET_CDC_DESTINATION_PERMISSIONS_FAILURE:
            return { ...state, getCDCDestinationPermissionResult: { success: false, ...action.payload.error }, userCDCDestinationPermission: null };
        default:
            return state;
    }
};
