import react from 'react';
import { Menu, Dropdown, Button, Space } from 'antd';

const menu = (items) => {
    return <Menu>
        {
            items.map(item => {
                return <Menu.Item key={item.text}>
                    <a onClick={item.callback}>
                        {item.text}
                    </a>
                </Menu.Item>
            })
        }
    </Menu>
}

function DropdownMenu({ items, children }) {
    return <Dropdown overlay={menu(items)} placement="bottomCenter">
        <Button>{children}</Button>
    </Dropdown>
}

export default DropdownMenu