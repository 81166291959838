import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Modal, Alert, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { UserAccountRecoveryView, ModalLoadingOverlay } from 'components';
import { showError, showSuccess } from 'common/ToastNotifications';

const { Title, Text, Link } = Typography;

class UserAccountRecover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recoveryEmailSent: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onSendUserForgotPasswordResultChanged(prevProps);
        this.onSendEmailVerificationLinkResultChanged(prevProps);
    }

    onSendUserForgotPasswordResultChanged = (prevProps) => {
        if (this.props.sendUserForgotPasswordResult && this.props.sendUserForgotPasswordResult !== prevProps.sendUserForgotPasswordResult) {
            if (this.props.sendUserForgotPasswordResult.success) {
                this.setState({
                    recoveryEmailSent: true
                })
            }
            else {
                if (this.props.sendUserForgotPasswordResult.fields) {
                    this.setState({
                        recoverErrors: this.props.sendUserForgotPasswordResult.fields
                    });
                }
                showError("Password reset failed. Please contact your account administrator.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onSendEmailVerificationLinkResultChanged = (prevProps) => {
        if (this.props.sendEmailVerificationLinkResult && this.props.sendEmailVerificationLinkResult !== prevProps.sendEmailVerificationLinkResult) {
            if (this.props.sendEmailVerificationLinkResult.success) {
                this.setState({
                    recoveryEmailSent: true
                });
            }
            else {
                showError("Could not able to send verification link at this moment. Please try after some time.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onRecoverExecuted = (email) => {
        if (this.props.recoveryType === "FORGOT_PASSWORD") {
            this.setState({
                busy: true,
                busyMessage: "Sending password reset link..."
            });
            this.props.sendUserForgotPassword(email);
        }
        else if (this.props.recoveryType === "RESEND_VERIFICATION_LINK") {
            this.setState({
                busy: true,
                busyMessage: "Sending email verification link..."
            });
            this.props.sendEmailVerificationLink(email);
        }
    }

    render() {
        return (
            <>
                <ModalLoadingOverlay busy={this.state.busy} message={this.state.busyMessage}></ModalLoadingOverlay>
                <UserAccountRecoveryView
                    isEmailSent={this.state.recoveryEmailSent}
                    recoveryType={this.props.recoveryType}
                    onRecover={this.onRecoverExecuted}
                    recoverErrors={this.state.passwordResetErrors}>
                </UserAccountRecoveryView>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        sendUserForgotPasswordResult: state.userForgotPassword.sendUserForgotPasswordResult,
        sendEmailVerificationLinkResult: state.userLogin.sendEmailVerificationLinkResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendUserForgotPassword: (email) => dispatch(actions.sendUserForgotPasswordRequest(email)),
        sendEmailVerificationLink: (email) => dispatch(actions.sendEmailVerificationLinkRequest(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountRecover);