import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, List, Space, Button, Table, Checkbox, Empty } from "antd";
import { DataObjectConsentListItem } from 'components';

const DataObjectConsentList = ({ consentList }) => {
    return (
        consentList && consentList.length > 0
            ?
            <List
                itemLayout="horizontal"
                dataSource={consentList}
                renderItem={(item) => <DataObjectConsentListItem consentItem={item}></DataObjectConsentListItem>}
            />
            :
            <Empty description="No consent exist.">
            </Empty>
    );
}

export default DataObjectConsentList;