import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Spin } from 'antd';
import { SchemaModelRecentList, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';

const { Title } = Typography;

class SchemaModelHomeOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingSchemaModels: false,
            fetchingPermission: false,
            busy: false,
            busyMessage: "Please wait...",
        }
    }

    componentWillMount() {
        this.props.getUserSchemasPermission();
        this.props.getRecentSchemaModels();
        if (!this.props.recentSchemaModelListResult ||
            !this.props.recentSchemaModelListResult.Items ||
            this.props.recentSchemaModelListResult.Items.length === 0) {
            this.setState({
                fetchingSchemaModels: true,
                fetchingPermission: true
            })
        }
        else {
            this.setState({
                refreshingSchemaModels: true
            });
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserSchemaModelsPermissionResultChanged(prevProps);
        this.onGetRecentSchemaModelsResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    onGetUserSchemaModelsPermissionResultChanged = (prevProps) => {
        if (this.props.getUserSchemaModelsPermissionResult && this.props.getUserSchemaModelsPermissionResult !== prevProps.getUserSchemaModelsPermissionResult) {
            if (!this.props.getUserSchemaModelsPermissionResult.success) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    onGetRecentSchemaModelsResultChanged = (prevProps) => {
        if (this.props.getRecentSchemaModelsResult && this.props.getRecentSchemaModelsResult !== prevProps.getRecentSchemaModelsResult) {
            if (!this.props.getRecentSchemaModelsResult.success) {
                if (this.props.getRecentSchemaModelsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema models at this moment.");
                }
            }
            this.setState({
                fetchingSchemaModels: false,
                refreshingSchemaModels: false
            });
        }
    }

    setSchemaPermissions = (schemaPermission, userBusinessArea) => {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        if (schemaPermission && userBusinessArea && userBusinessArea.user) {
            permission = {
                canAdd: schemaPermission.canAdd && userBusinessArea.user.canAdd,
                canView: schemaPermission.canView && userBusinessArea.user.canView,
                canEdit: schemaPermission.canEdit && userBusinessArea.user.canEdit,
                canDelete: schemaPermission.canDelete && userBusinessArea.user.canDelete,
            };
        }
        this.setState({
            permission
        });
    }

    showViewSchemaModel = (schema) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", schema.businessAreaName.toLowerCase())
            .replace(":schemaId", schema.schemaId)
            .replace(":schemaAction", "view"));
    }

    showEditSchemaModel = (schema) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", schema.businessAreaName.toLowerCase())
            .replace(":schemaId", schema.schemaId)
            .replace(":schemaAction", "edit"));
    }

    fetchingSchemaModels = () => {
        let isBusy = this.state.fetchingSchemaModels || this.state.fetchingPermission;
        return isBusy;
    }

    isBusy = () => {
        return this.state.isBusy || this.fetchingSchemaModels();
    }

    render() {
        return <FullHeightContainerLayout
            showHeader={true}
            header={<Title level={4}>Schema Models{this.state.refreshingSchemaModels ? <> <Spin size="small" /></> : <></>}</Title>}
            content={
                <>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.fetchingSchemaModels()
                        ?
                        (
                            this.state.permissionDenied
                                ?
                                <ProhibitedArea></ProhibitedArea>
                                :
                                <SchemaModelRecentList
                                    schemaModelList={this.props.recentSchemaModelListResult.Items}
                                    onViewSchemaModel={this.showViewSchemaModel}
                                    onEditSchemaModel={this.showEditSchemaModel}
                                    permission={this.props.permission}>
                                </SchemaModelRecentList>
                        )
                        :
                        <></>
                    }
                </>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        recentSchemaModelListResult: state.schemaModels.recentSchemaModelListResult,
        getRecentSchemaModelsResult: state.schemaModels.getRecentSchemaModelsResult,
        getUserSchemaModelsPermissionResult: state.schemaModels.getUserSchemaModelsPermissionResult,
        permission: state.schemaModels.permission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRecentSchemaModels: () => dispatch(actions.getRecentSchemaModelsRequest()),
        getUserSchemasPermission: () => dispatch(actions.getUserSchemaModelsPermissionRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModelHomeOverview));