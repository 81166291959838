
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* searchDataObjects(api, query, searchId, queryType, businessArea, schema, dataSet, from, size) {
    let searchInput = {
        query,
        searchId: searchId,
        queryType,
        businessArea,
        schema,
        dataSet,
        from,
        size
    }
    if (!searchInput.searchId) {
        delete searchInput.searchId;
    }

    let result = yield call([api, api.post], `${Endpoints.search}/api/v1/dataobjects/search`, searchInput);
    return result.data;
}

function* getSearchFields(api, businessArea, schema, dataSet) {
    let params = {
        businessArea,
        schema,
        dataSet
    }

    let result = yield call([api, api.post], `${Endpoints.search}/api/v1/dataobjects/search/fields`, params);

    // Sort by name
    result.data.sort((a, b) => {
        if (a.name === b.name) {
            // If same name, give priority to those with fieldType
            if (a.fieldType && b.fieldType) {
                if (a.fieldType < b.fieldType) {
                    return -1;
                } else if (a.fieldType > b.fieldType) {
                    return 1;
                }
            } else if (a.fieldType) {
                return -1;
            } else if (b.fieldType) {
                return 1;
            }
        }
        // Else sort by name (case-insensitive)
        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
    });

    // Only return unique
    const filtered = result.data.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.name === obj1.name)) === i
    );

    return filtered;
}

function* updateSearchQuery(api, searchId, question, query, properties, accuracy, expectedDSLQuery) {
    let requestBody = {
        question,
        query,
        properties
    };
    if (accuracy !== undefined) {
        requestBody.accuracy = accuracy;
    }

    if (expectedDSLQuery !== undefined) {
        requestBody.expectedDSLQuery = JSON.stringify(expectedDSLQuery);
    }

    yield call([api, api.put], `${Endpoints.search}/api/v1/dataobjects/search/${searchId}`, requestBody);
}

export function* getSearchDataObjectsPermission(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/dataobjectsearch`, { tenantid: tenantId });
    return result.data;
}

export function* searchDataObjectsRequest(api, { query, searchId, queryType, businessArea, schema, dataSet, from, size }) {
    yield put(actions.searchDataObjectsInProgress());
    
    try {
        let searchResult = yield call(searchDataObjects, api, query, searchId, queryType, businessArea, schema, dataSet, from, size);
        yield put(actions.searchDataObjectsSuccess(searchResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Search", "Data Objects");
        yield put(actions.searchDataObjectsFailure(errorObject));
    }
}

export function* getSearchDataObjectsPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getSearchDataObjectsPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getUserSearchDataObjectsPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user dataset objects permission");
        yield put(actions.getUserSearchDataObjectsPermissionFailure(errorObject));
    }
}

export function* updateSearchQueryRequest(api, { searchId, question, query, properties, accuracy, expectedDSLQuery }) {
    try {
        yield call(updateSearchQuery, api, searchId, question, query, properties, accuracy, expectedDSLQuery);
        yield put(actions.updateSearchQuerySuccess({
            accuracy,
            expectedDSLQuery
        }));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "Search query");
        yield put(actions.updateSearchQueryFailure(errorObject));
    }
}

export function* getSearchFieldsRequest(api, { businessArea, schema, dataSet }) {
    try {
        let fields = yield call(getSearchFields, api, businessArea, schema, dataSet);
        yield put(actions.getSearchFieldsSuccess(fields));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Search fields");
        yield put(actions.getSearchFieldsFailure(errorObject));
    }
}

export function* watchSearchDataObjectsRequest(api, { params }) {
    yield call(searchDataObjectsRequest, api, params);
}

export function* watchGetSearchDataObjectsPermissionRequest(api, { params }) {
    yield call(getSearchDataObjectsPermissionRequest, api);
}

export function* watchUpdateSearchQueryRequest(api, { params }) {
    yield call(updateSearchQueryRequest, api, params);
}

export function* watchGetSearchFieldsRequest(api, { params }) {
    yield call(getSearchFieldsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.SEARCH_DATA_OBJECTS_REQUEST, watchSearchDataObjectsRequest, api);
    yield takeEvery(actions.GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST, watchGetSearchDataObjectsPermissionRequest, api);
    yield takeLatest(actions.UPDATE_SEARCH_QUERY_REQUEST, watchUpdateSearchQueryRequest, api);
    yield takeLatest(actions.GET_SEARCH_FIELDS_REQUEST, watchGetSearchFieldsRequest, api);
}