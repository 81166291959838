import React from 'react';
import { useParams } from "react-router-dom";
import queryString from 'query-string'
import { SharedDataObject } from 'components';

const SharedDataObjectPage = (props) => {
  const { uuid } = useParams();
  const urlParams = queryString.parse(props.location.search);
  return (
    <SharedDataObject
      {...props}
      dataObjectId={uuid}
      tenantId={urlParams.tenant}
      userId={urlParams.user}
      readOnly={urlParams.readonly}
      expiresAt={urlParams.expires}
      signatureDate={urlParams.date}
      signature={urlParams.signature}>
    </SharedDataObject>
  )
}

export default SharedDataObjectPage
