import React from "react";
import { Switch, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";

const { Column } = Table;

const AppClientList = ({ appClientList, onAddAppClient, onViewAppClient, onEditAppClient, onActivateDeactivateAppClient, onViewAppClientHistory, onDeleteAppClient, permission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }

    return <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
        <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={true}
                content={
                    <Table size="small"
                        rowKey="clientId"
                        dataSource={appClientList}
                        pagination={false}
                        scroll={{ y: "100vh" }}
                        className="container-height-100">
                        <Column
                            width="6em"
                            render={(value, appClient, index) => <>
                            <Tooltip title ='Edit Credentials'>  
                                <Icon
                                    disabled={!permission.canEdit}
                                    color='blue'
                                    name='pencil'
                                    className="action-cursor"
                                    onClick={() => onEditAppClient(appClient.clientId)} /> </Tooltip>
                                    <Tooltip title ='View Credentials'>
                                <Icon
                                    disabled={!permission.canView}
                                    color='blue'
                                    name='eye'
                                    className="action-cursor"
                                    onClick={() => onViewAppClient(appClient.clientId)} /></Tooltip>
                                    <Tooltip title ='View Credentials History'>
                                <Icon
                                    disabled={!permission.canView}
                                    color='blue'
                                    name='history'
                                    className="action-cursor"
                                    onClick={() => onViewAppClientHistory(appClient.clientId)} /></Tooltip>
                            </>}
                        />
                        <Column
                            dataIndex="clientName"
                            title="App Name"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, appClient, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="20%"
                            dataIndex="businessAreaCount"
                            title="Assigned Business Areas"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, appClient, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="20%"
                            dataIndex="roleCount"
                            title="Assigned Roles"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, appClient, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="10%"
                            dataIndex="active"
                            title={"Active"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, appClient, index) => {
                                return <Switch checked={value} disabled={!permission.canEdit || appClient.appClientType === "ACCOUNT_ADMIN"} onClick={(checked, event) => onActivateDeactivateAppClient(appClient, checked)}></Switch>;
                            }}
                        />
                        <Column
                            width="5%"
                            render={(value, appClient, index) => {
                                return (
                                     <Tooltip title ='Delete Credentials'>
                                    <Icon
                                        disabled={!permission.canDelete}
                                        color='blue'
                                        name='trash alternate outline'
                                        className="action-cursor"
                                        onClick={() => onDeleteAppClient(appClient)} /></Tooltip>
                                );
                            }}
                        />
                    </Table>}
                footer={
                    <Row className="table-footer-row">
                        <Col span={24} className="footer-right-column">
                            <Button type="primary"
                                disabled={!permission.canAdd}
                                onClick={onAddAppClient}>
                                <PlusOutlined /> Add App Client
                            </Button>
                        </Col>
                    </Row>}
                footerStyle={{ paddingTop: "1rem" }}>

            </FullHeightContainerLayout>
        </Col>
    </Row>
};

export default AppClientList;