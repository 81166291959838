import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { JobList, LoadingOverlay, DomainDataPreviewList, DataObjectsPreviewList, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography } from 'antd';
import AppPaths from 'constants/appPaths';
import * as FileSaver from 'file-saver';

const { Title, Text } = Typography;

class Jobs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            containerHeight: "100%"
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting Jobs...",
                fetchingJobs: true
            });
            this.props.getJobs();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetJobsResultUpdate(prevProps);
        this.onGetJobByIdResultUpdate(prevProps);
        this.onGetJobsDomainDataResultUpdate(prevProps);
        this.onGetJobsDataObjectsResultUpdate(prevProps);
        this.onRestartJobResultUpdate(prevProps);
        this.onCancelJobResultUpdate(prevProps);
        this.onDownloadExportDataSetResultUpdate(prevProps);
    }

    onGetJobsResultUpdate = (prevProps) => {
        if (this.props.getJobsResult && this.props.getJobsResult !== prevProps.getJobsResult) {
            if (!this.props.getJobsResult.success) {
                if (this.props.getJobsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError(this.props.getJobsResult.message || "Could not able to get jobs at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingJobs: false
            });
        }
    }

    onGetJobByIdResultUpdate = (prevProps) => {
        if (this.props.getJobByIdResult && this.props.getJobByIdResult !== prevProps.getJobByIdResult) {
            if (this.state.selectedJob && this.props.getJobByIdResult.jobId === this.state.selectedJob.jobId) {
                if (!this.props.getJobByIdResult.success) {
                    if (this.props.getJobByIdResult.code === "PERMISSION_DENIED") {
                        this.setState({
                            permissionDenied: true
                        });
                    }
                    else {
                        showError(this.props.getJobByIdResult.message || "Could not able to get job at this moment.");
                    }
                }
                else {
                    if (this.props.job && this.props.job.jobId === this.state.selectedJob.jobId) {
                        this.setState({
                            selectedJob: this.props.job
                        });
                    }
                }
                this.setState({
                    fetchingJob: false
                });
            }
        }
    }

    onGetJobsDomainDataResultUpdate = (prevProps) => {
        if (this.props.getJobsDomainDataResult && this.props.getJobsDomainDataResult !== prevProps.getJobsDomainDataResult) {
            if (!this.props.getJobsDomainDataResult.success) {
                showError("Could not able to get data at this moment.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onGetJobsDataObjectsResultUpdate = (prevProps) => {
        if (this.props.getJobsDataObjectsResult && this.props.getJobsDataObjectsResult !== prevProps.getJobsDataObjectsResult) {
            if (!this.props.getJobsDataObjectsResult.success) {
                showError("Could not able to get data at this moment.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onRestartJobResultUpdate = (prevProps) => {
        if (this.props.restartJobResult && this.props.restartJobResult !== prevProps.restartJobResult) {
            if (!this.props.restartJobResult.success) {
                showError(this.props.restartJobResult.message || "Could not able to restart job at this moment.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onCancelJobResultUpdate = (prevProps) => {
        if (this.props.cancelJobResult && this.props.cancelJobResult !== prevProps.cancelJobResult) {
            if (!this.props.cancelJobResult.success) {
                showError("Could not able to cancel job at this moment.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onDownloadExportDataSetResultUpdate = (prevProps) => {
        if (this.props.downloadExportDataSetResult && this.props.downloadExportDataSetResult !== prevProps.downloadExportDataSetResult) {
            if (!this.props.downloadExportDataSetResult.success) {
                showError("Could not able to download exported file.");
            }
            else {
                FileSaver.saveAs(this.props.downloadExportFileResult.url, this.props.downloadExportFileResult.fileName);
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase()).replace(":monitorItem", this.props.match.params.monitorItem.toLowerCase()),
                    text: "Jobs"
                }
            ];

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getProcessedData = (job, jobItemOperation) => {
        if (job.jobType === "DATA_OBJECTS_EXPORT") {
            this.setState({
                busy: true,
                busyMessage: "Downloading file..."
            });
            this.props.downloadExportDataSet(job.runInfo.s3Key, `${job.dataSetName}.csv`);
            return;
        }
        this.setState({
            selectedJob: job,
            selectedItemOperation: jobItemOperation,
            containerHeight: "50%",
            jobDataHeader: ""
        })
        switch (job.recordType) {
            case "DOMAIN_DATA":
                this.setState({
                    jobDataHeader: `Business Area Data (${job.domainName})`
                });
                this.props.getJobsDomainData(job.jobId, jobItemOperation.itemOperation, jobItemOperation.dataVersion, null);
                break;
            case "DATA_OBJECTS":
                this.setState({
                    jobDataHeader: `Dataset Objects (${job.dataSetName})`,
                    fetchingJob: true
                });
                this.props.getJobById(job.jobId);
                this.props.getJobsDataObjects(job.jobId, jobItemOperation.itemOperation, jobItemOperation.dataVersion, null);
                break;
        }
    }

    restartJob = (job) => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        let carryRunInfo = job.jobType === "FIND_DATA_OBJECTS_DUPLICATES";
        this.props.restartJob(job.jobId, job.version, carryRunInfo);
    }

    cancelJob = (jobId, version) => {
        this.setState({
            busy: true,
            busyMessage: "Please wait..."
        });
        this.props.cancelJob(jobId, version);
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingJobs);
        return isBusy;
    }

    getNextJobsDomainData = (pagination) => {
        if (this.props.domainDataResult.pageKey &&
            (pagination.current * pagination.pageSize) >= this.props.domainDataResult.Items.length) {
            this.props.getJobsDomainData(this.state.selectedJob.jobId, this.state.selectedItemOperation.itemOperation, this.state.selectedItemOperation.dataVersion, this.props.domainDataResult.pageKey);
        }
    }

    getNextJobsDataObjects = (pagination) => {
        if (this.props.dataObjectsResult.pageKey &&
            (pagination.current * pagination.pageSize) >= this.props.dataObjectsResult.Items.length) {
            this.props.getJobsDataObjects(this.state.selectedJob.jobId, this.state.selectedItemOperation.itemOperation, this.state.selectedItemOperation.dataVersion, this.props.dataObjectsResult.pageKey);
        }
    }

    getSelectedJobDataComponent = () => {
        if (this.state.selectedJob && this.state.selectedJob.recordType) {
            let jobChangeDataComponent = <></>;
            let headerText = "";
            switch (this.state.selectedItemOperation.itemOperation) {
                case "INSERT":
                    headerText = "Inserted records"
                    break;
                case "DELETE":
                    headerText = "Deleted records"
                    break;
            }
            let headerComponent = <Title level={5}><Text>{headerText}</Text><Text> - </Text><Text type="secondary">{this.state.jobDataHeader}</Text></Title>;
            switch (this.state.selectedJob.recordType) {
                case "DOMAIN_DATA":
                    jobChangeDataComponent = <DomainDataPreviewList
                        key={this.state.selectedJob.jobId}
                        recordCount={this.state.selectedItemOperation.recordCount || 0}
                        domainDataList={this.props.domainDataResult.Items}
                        loading={this.props.domainDataResult.inProgress ? { tip: "Loading data..." } : false}
                        tableHeight={100}
                        tableClasses={["container-height-100"]}
                        onPageSelect={this.getNextJobsDomainData}>
                    </DomainDataPreviewList>
                    break;
                case "DATA_OBJECTS":
                    jobChangeDataComponent = <DataObjectsPreviewList
                        key={this.state.selectedJob.jobId}
                        recordCount={this.state.selectedItemOperation.recordCount || 0}
                        schemaFields={(this.state.selectedJob.runInfo && this.state.selectedJob.runInfo.schemaFields) || []}
                        dataObjectList={this.props.dataObjectsResult.Items}
                        loading={this.state.fetchingJob || this.props.dataObjectsResult.inProgress}
                        onPageSelect={this.getNextJobsDataObjects}>
                    </DataObjectsPreviewList>
                    break;
                default:
                    jobChangeDataComponent = <></>;
                    break;
            }
            return <Row style={{ flexDirection: "column", flexGrow: 1, height: this.state.containerHeight }}>
                <Col span={24}>
                    <FullHeightContainerLayout
                        showHeader={true}
                        showFooter={false}
                        header={headerComponent}
                        content={jobChangeDataComponent}>
                    </FullHeightContainerLayout>
                </Col>
            </Row>
        }
        return <></>
    }

    render() {
        return (
            <FullHeightContainerLayout
                content={
                    <>
                        <LoadingOverlay
                            busy={this.isBusy()}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        {
                            !this.state.fetchingJobs
                                ?
                                <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                                    <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                        <Row style={{ flexDirection: "column", flexGrow: 1, height: this.state.containerHeight }}>
                                            <Col span={24}>
                                                <FullHeightContainerLayout
                                                    showFooter={false}
                                                    showHeader={false}
                                                    content={<JobList
                                                        jobList={this.props.jobsResult.Items}
                                                        onGetProcessedData={this.getProcessedData}
                                                        onRestartJob={this.restartJob}
                                                        onCancelJob={this.cancelJob}>
                                                    </JobList>}>
                                                </FullHeightContainerLayout>
                                            </Col>
                                        </Row>

                                        {this.getSelectedJobDataComponent()}
                                    </Col>
                                </Row>
                                :
                                <></>
                        }
                    </>
                }>
            </FullHeightContainerLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getJobsResult: state.jobs.getJobsResult,
        jobsResult: state.jobs.jobsResult,
        getJobByIdResult: state.jobs.getJobByIdResult,
        job: state.jobs.job,
        restartJobResult: state.jobs.restartJobResult,
        cancelJobResult: state.jobs.cancelJobResult,
        getJobsDomainDataResult: state.jobs.getJobsDomainDataResult,
        getJobsDataObjectsResult: state.jobs.getJobsDataObjectsResult,
        domainDataResult: state.jobs.domainDataResult,
        dataObjectsResult: state.jobs.dataObjectsResult,
        downloadExportDataSetResult: state.dataSets.downloadExportDataSetResult,
        downloadExportFileResult: state.dataSets.downloadExportDataSet
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJobs: () => dispatch(actions.getJobsRequest()),
        getJobById: (jobId) => dispatch(actions.getJobByIdRequest(jobId)),
        restartJob: (jobId, version, carryRunInfo) => dispatch(actions.restartJobRequest(jobId, version, carryRunInfo)),
        cancelJob: (jobId, version) => dispatch(actions.cancelJobRequest(jobId, version)),
        getJobsDomainData: (jobId, itemOperation, dataVersion, pageKey) => dispatch(actions.getJobsDomainDataRequest(jobId, itemOperation, dataVersion, pageKey)),
        getJobsDataObjects: (jobId, itemOperation, dataVersion, pageKey) => dispatch(actions.getJobsDataObjectsRequest(jobId, itemOperation, dataVersion, pageKey)),
        downloadExportDataSet: (fileKey, fileName) => dispatch(actions.downloadExportDataSetRequest(fileKey, fileName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Jobs));