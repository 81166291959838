
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

function* getSchemaModelLineage(api, schemaId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/schemas/${schemaId}/lineage`);
    return result.data;
}

function* getLineage(api) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/lineage`);
    return result.data;
}

export function* getSchemaModelLineageRequest(api, { schemaId }) {
    try {
        const result = yield call(getSchemaModelLineage, api, schemaId);
        yield put(actions.getSchemaModelLineageSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema model relationships");
        yield put(actions.getSchemaModelLineageFailure(errorObject));
    }
}

export function* getLineageRequest(api) {
    try {
        const result = yield call(getLineage, api);
        yield put(actions.getLineageSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "relationships");
        yield put(actions.getLineageFailure(errorObject));
    }
}

export function* watchGetSchemaModelLineageRequest(api, { params }) {
    yield call(getSchemaModelLineageRequest, api, params);
}

export function* watchGetLineageRequest(api, { params }) {
    yield call(getLineageRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_SCHEMA_MODEL_LINEAGE_REQUEST, watchGetSchemaModelLineageRequest, api);
    yield takeLatest(actions.GET_LINEAGE_REQUEST, watchGetLineageRequest, api);
}