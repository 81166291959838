import React, { useState, useEffect } from "react";
import { Table, Tooltip, Input, Row, Col, Select } from "antd";
import moment from 'moment';

const { Option } = Select;

const DataColumnMappingItem = ({ dataColumns = [], onChange, mappedValue = { mappingType: "DATA_COLUMN", value: "" } }) => {
    const [selectedMappedValue, setSelectedMappedValue] = useState(mappedValue || { mappingType: "DATA_COLUMN", value: "" });

    useEffect(() => {
        const currentMappedValue = { ...selectedMappedValue };
        if (mappedValue) {
            setSelectedMappedValue(currentMappedValue);
        }
    }, [mappedValue && mappedValue.mappingType, mappedValue && mappedValue.value]);

    useEffect(() => {
        onChange && onChange(selectedMappedValue);
    }, [selectedMappedValue.mappingType, selectedMappedValue.value]);

    const onMappingTypeChange = (value) => {
        const currentMappedValue = { ...selectedMappedValue };
        if (currentMappedValue.mappingType !== value) {
            currentMappedValue.mappingType = value;
            if (value === "DATA_COLUMN" && dataColumns.length > 0) {
                currentMappedValue.value = dataColumns[0];
            }
            else {
                currentMappedValue.value = "";
            }
            setSelectedMappedValue(currentMappedValue);
        }
    }

    const onDataColumnChange = (value) => {
        const currentMappedValue = { ...selectedMappedValue };
        currentMappedValue.value = value;
        setSelectedMappedValue(currentMappedValue);
    }

    const onFormulaValueChange = (e) => {
        const currentMappedValue = { ...selectedMappedValue };
        currentMappedValue.value = e.target.value;
        setSelectedMappedValue(currentMappedValue);
    }

    return (
        <Row className='data-column-mapper-row' style={{ flexWrap: "nowrap" }}>
            <Col span={10} className='data-column-mapper-column'>
                <Select style={{ width: "100%" }} defaultValue="DATA_COLUMN" onChange={onMappingTypeChange} value={selectedMappedValue.mappingType}>
                    <Option value="DATA_COLUMN">Data column</Option>
                    <Option value="FORMULA">Formula</Option>
                </Select>
            </Col>
            <Col span={14} className='data-column-mapper-column'>
                {
                    selectedMappedValue.mappingType === "DATA_COLUMN"
                        ?
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            allowClear={true}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={onDataColumnChange}
                            value={selectedMappedValue.value}>
                            {
                                dataColumns.map((column, index) => <Option key={index} value={column}>{column}</Option>)
                            }
                        </Select>
                        :
                        <Input addonBefore="=" placeholder='SUM(amount,100)' value={selectedMappedValue.value} onChange={onFormulaValueChange} />
                }
            </Col>
        </Row>);
};

export default DataColumnMappingItem;