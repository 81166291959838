import {
    CREATE_DATA_OBJECT_VERIFICATION_SUCCESS,
    CREATE_DATA_OBJECT_VERIFICATION_FAILURE,

    GET_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS,
    GET_DATA_OBJECT_GUEST_VERIFICATION_FAILURE,

    UPDATE_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS,
    UPDATE_DATA_OBJECT_GUEST_VERIFICATION_FAILURE,

    GET_DATA_OBJECT_VERIFICATIONS_SUCCESS,
    GET_DATA_OBJECT_VERIFICATIONS_FAILURE,

    GET_DATA_OBJECT_CONSENTS_SUCCESS,
    GET_DATA_OBJECT_CONSENTS_FAILURE
} from "./actions";

const initialState = {
    createDataObjectVerificationResult: {
        success: true
    },
    getDataObjectGuestVerificationResult: {
        success: true
    },
    dataObjectGuestVerificationResult: null,
    updateDataObjectVerificationResult: {
        success: true
    },
    getDataObjectVerificationsResult: {
        success: true
    },
    dataObjectVerifications: [],
    getDataObjectConsentsResult: {
        success: true
    },
    dataObjectConsents: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_DATA_OBJECT_VERIFICATION_SUCCESS:
            return {
                ...state,
                createDataObjectVerificationResult:
                {
                    success: true,
                    result: action.payload.result
                }
            };
        case CREATE_DATA_OBJECT_VERIFICATION_FAILURE:
            return {
                ...state,
                createDataObjectVerificationResult:
                {
                    success: false,
                    ...action.payload.error
                }
            };
        case GET_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS:
            return {
                ...state,
                getDataObjectGuestVerificationResult:
                {
                    success: true
                },
                dataObjectGuestVerificationResult: action.payload.result
            };
        case GET_DATA_OBJECT_GUEST_VERIFICATION_FAILURE:
            return {
                ...state,
                getDataObjectGuestVerificationResult:
                {
                    success: false,
                    ...action.payload.error
                },
                dataObjectGuestVerificationResult: null
            };
        case UPDATE_DATA_OBJECT_GUEST_VERIFICATION_SUCCESS:
            return {
                ...state,
                updateDataObjectVerificationResult:
                {
                    success: true
                }
            };
        case UPDATE_DATA_OBJECT_GUEST_VERIFICATION_FAILURE:
            return {
                ...state,
                updateDataObjectVerificationResult:
                {
                    success: false,
                    ...action.payload.error
                }
            };
        case GET_DATA_OBJECT_VERIFICATIONS_SUCCESS:
            return {
                ...state,
                getDataObjectVerificationsResult:
                {
                    success: true
                },
                dataObjectVerifications: action.payload.result
            };
        case GET_DATA_OBJECT_VERIFICATIONS_FAILURE:
            return {
                ...state,
                getDataObjectVerificationsResult:
                {
                    success: false,
                    ...action.payload.error
                },
                dataObjectVerifications: []
            };
        case GET_DATA_OBJECT_CONSENTS_SUCCESS:
            return {
                ...state,
                getDataObjectConsentsResult:
                {
                    success: true
                },
                dataObjectConsents: action.payload.result
            };
        case GET_DATA_OBJECT_CONSENTS_FAILURE:
            return {
                ...state,
                getDataObjectConsentsResult:
                {
                    success: false,
                    ...action.payload.error
                },
                dataObjectConsents: []
            };
        default:
            return state;
    }
};
