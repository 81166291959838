import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import {
    Handle,
    Position
} from 'reactflow';
import { DoubleRightOutlined, DoubleLeftOutlined, CaretDownFilled, PlusCircleTwoTone, ZoomOutOutlined, ZoomInOutlined } from '@ant-design/icons';
import 'reactflow/dist/style.css';

const { Text, Link, Title } = Typography;

function LineageBusinessAreaNode({ data }) {
    return (
        <>
            <Row style={{
                flexDirection: "column",
                flexWrap: false,
                padding: "0rem 0.5rem",
                width: "100%",
                height: "100%"
            }}>
                <Row style={{ justifyContent: "space-between", width: "100%" }}>
                    <Col>
                        <Text style={{ fontSize: "8px" }}>{data.title}</Text>
                    </Col>
                    <Col style={{ marginLeft: "1rem" }}>
                        <Space direction="horizontal" size="small">
                            {data.node.linkedResourceExist ?
                                <>
                                    {
                                        data.node.isZoom
                                            ?
                                            <Tooltip title={"Zoom-out business area"}>
                                                <ZoomOutOutlined style={{ cursor: "pointer", color: "#1990FE" }} onClick={() => data.onZoomOut && data.onZoomOut()} />
                                            </Tooltip>
                                            :
                                            <Tooltip title={"Zoom-in business area"}>
                                                <ZoomInOutlined style={{ cursor: "pointer", color: "#1990FE" }} onClick={() => data.onZoomIn && data.onZoomIn()} />
                                            </Tooltip>
                                    }
                                </>
                                :
                                <></>
                            }
                            <Tooltip title={"Actions"}>
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: 'View',
                                                key: 'View',
                                                disabled: !data.permission.canView,
                                                onClick: () => data.onActionClick("VIEW")
                                            },
                                            {
                                                label: 'Edit',
                                                key: 'Edit',
                                                disabled: !data.permission.canEdit || data.disableEditAction,
                                                onClick: () => data.onActionClick("EDIT")
                                            }
                                        ]
                                    }}>
                                    <CaretDownFilled style={{ fontSize: "1.2vh", color: "#0E9FFF" }} />
                                </Dropdown>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between" }}>
                    <Col style={{ flexWrap: "nowrap", display: "flex", alignItems: "center" }}>
                        <Tooltip title={data.name}>
                            <Text ellipsis={true} style={{ fontSize: "10px" }} type="secondary">{data.name}</Text>
                        </Tooltip>
                    </Col>
                    {
                        data.node.linkedResourceExist
                            ?
                            <Col style={{ flexWrap: "nowrap" }}>
                                <Tooltip title="Expand Business area">
                                    <PlusCircleTwoTone style={{ cursor: "pointer" }} onClick={() => data.onExpand && data.onExpand()} />
                                </Tooltip>
                            </Col>
                            :
                            <></>
                    }

                </Row>
            </Row>
            {
                data.source
                    ?
                    <Handle type="source" position={Position.Right} />
                    :
                    <></>
            }
        </>
    );
}

export default LineageBusinessAreaNode;