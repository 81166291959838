
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEvents(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events`, { tenantid: tenantId });
    return result.data;
}

export function* getEventsRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const eventsResult = yield call(getEvents, api, tenant.tenantId);
        yield put(actions.getEventsSuccess(eventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "events");
        yield put(actions.getEventsFailure(errorObject));
    }
}

export function* watchGetEventsRequest(api, params) {
    yield call(getEventsRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENTS_REQUEST, watchGetEventsRequest, api);
}