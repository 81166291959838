import {
    GET_EVENT_JOB_SUCCESS,
    GET_EVENT_JOB_FAILURE
} from "./actions";

const initialState = {
    getEventJobResult: {
        success: true
    },
    jobRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_JOB_SUCCESS:
            return { ...state, getEventJobResult: { success: true }, jobRevision: action.payload.jobRevision };
        case GET_EVENT_JOB_FAILURE:
            return {
                ...state, getEventJobResult: { success: false, ...action.payload.error }, jobRevision: null
            };
        default:
            return state;
    }
};
