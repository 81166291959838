import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { DomainDataPreviewList, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';

const { Title, Text } = Typography;

class EventDomainData extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        if (this.props.itemOperation && this.props.eventType === "BATCH_PROCESSING") {
            let headerText = "";
            let headerDetails = `(Business Area Data: ${this.props.domainName})`
            switch (this.props.itemOperation) {
                case "INSERT":
                    headerText = "Inserted domain records"
                    break;
                case "DELETE":
                    headerText = "Deleted domain records"
                    break;
            }
            this.setState({
                headerText,
                headerDetails
            });
            this.props.getEventDomainDataList(this.props.eventId, this.props.itemOperation, null);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventDomainDataListResultUpdate(prevProps);
    }

    onGetEventDomainDataListResultUpdate = (prevProps) => {
        if (this.props.getEventDomainDataListResult && this.props.getEventDomainDataListResult !== prevProps.getEventDomainDataListResult) {
            if (!this.props.getEventDomainDataListResult.success) {
                if (this.props.getEventDomainDataListResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get domain at this moment.");
                }
            }
        }
    }

    getEventDomainData = (pagination) => {
        if (this.props.domainDataResult.pageKey &&
            (pagination.current * pagination.pageSize) >= this.props.domainDataResult.Items.length) {
            this.props.getEventDomainDataList(this.props.eventId, this.props.itemOperation, this.props.domainDataResult.pageKey);
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={true}
                showFooter={false}
                header={<Title level={5}><Text>Event Batch - </Text><Text>{this.state.headerText}</Text><Text> - </Text><Text type="secondary">{this.state.headerDetails}</Text></Title>}
                content={
                    <DomainDataPreviewList
                        recordCount={this.props.recordCount || 0}
                        domainDataList={this.props.domainDataResult.Items && this.props.domainDataResult.Items.map(item => item.data)}
                        loading={this.props.domainDataResult.inProgress ? { tip: "Loading data..." } : false}
                        tableClasses={["container-height-100"]}
                        tableHeight={100}
                        onPageSelect={this.getEventDomainData}>
                    </DomainDataPreviewList>}>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventDomainDataListResult: state.eventDomainData.getEventDomainDataListResult,
        domainDataResult: state.eventDomainData.domainDataResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventDomainDataList: (eventId, itemOperation, pageKey) => dispatch(actions.getEventDomainDataListRequest(eventId, itemOperation, pageKey))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventDomainData));