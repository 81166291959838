import React, { useState, useEffect, useRef } from 'react';
import { useComponentDidUpdateEffect } from 'common/customHooks';
import { Upload, Typography, Row, Col, Radio, Space, Select, Checkbox, Button, Table, Input, Divider, Empty, Form } from 'antd';
import { InboxOutlined } from "@ant-design/icons";
import { LoadingOverlay, FullHeightContainerLayout, ClosableContainer, TextFilePreview, FixWidthDataColumnSpecs, SchemaModelFieldList } from 'components';
import Papaparse from 'papaparse/papaparse';
import { v4 as uuidv4 } from 'uuid';
import schemaFieldTemplates from 'common/data/schemaFieldTemplates';
import _ from 'lodash';
import { showError, showSuccess } from 'common/ToastNotifications';

const { Text, Link } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { Column } = Table;
const STACKED_SCHEMA_DEFINITION_LABELS = ["COLUMN", "DESCRIPTION", "DATATYPE", "LENGTH", "PRIMARY", "SORT", "MANDATORY", "MV", "PII"];

function SchemaModelCreateFromFile({
    onCreateSchemaModel,
    onUploadFile,
    uploadFileResult,
    uploadedFile,
    getParsedSchemaDefinition,
    getParsedSchemaDefinitionResult,
    schemaDefinition,
    onManageParsedSchemaModel,
    onCancelManageParsedSchemaModel,
    manageParsedSchemaModelCancelled,
    ...props }) {
    let [fileReadResult, setFileReadResult] = useState({
        file: null,
        textContent: "",
        schemaFields: [],
        error: null
    });

    const [busyStatus, setBusyStatus] = useState({ message: "", busy: false });
    const [firstRowLabel, setFirstRowLabel] = useState(true);
    const [schemaDetectOption, setSchemaDetectOption] = useState("AUTO_DETECT_STRUCTURE");
    const [fixWidthColumnSpecOption, setFixWidthColumnSpecOption] = useState("AUTO_DETECT_COL_SPECS");
    const [delimiterOption, setDelimiterOption] = useState("AUTO_DETECT");
    const [customDelimiter, setCustomDelimiter] = useState({ value: "", status: "" });
    const [showParsedPreview, setShowParsedPreview] = useState(false);
    const [fixedWidthColumSpecs, setFixedWidthColumSpecs] = useState([]);
    const [fixedWidthColumnSpecSelectedIndex, setFixedWidthColumnSpecSelectedIndex] = useState(-1);

    const fileDraggerRef = useRef(null);

    useComponentDidUpdateEffect(() => {
        if (fileReadResult.file) {
            if (uploadFileResult.success) {
                fileReader.readAsText(fileReadResult.file);
            }
            else {
                showError("Could not able to generate preview");
                setBusyStatus({ message: "", busy: false });
            }
        }
    }, [uploadFileResult, uploadedFile])

    useComponentDidUpdateEffect(() => {
        if (fileReadResult.file && uploadedFile) {
            if (getParsedSchemaDefinitionResult.success) {
                onDataParsingComplete(schemaDefinition);
            }
            else {
                showError("Could not able to parse data file");
                setBusyStatus({ message: "", busy: false });
            }
        }
    }, [getParsedSchemaDefinitionResult, schemaDefinition])

    useComponentDidUpdateEffect(() => {
        if (manageParsedSchemaModelCancelled) {
            setShowParsedPreview(false);
        }
    }, [manageParsedSchemaModelCancelled])

    const onSchemaDetectOptionChange = (e) => {
        setSchemaDetectOption(e.target.value);
        setShowParsedPreview(false);
        onCancelManageParsedSchemaModel();
    };

    const onFixWidthColumnSpecOptionChange = (e) => {
        setFixWidthColumnSpecOption(e.target.value);
        setShowParsedPreview(false);
        onCancelManageParsedSchemaModel();
    };

    const onDelimiterOptionChange = (value) => {
        setDelimiterOption(value);
        setShowParsedPreview(false);
        onCancelManageParsedSchemaModel();
    };

    const onFirstRowLabelValueChanged = (e) => {
        setFirstRowLabel(e.target.checked);
        setShowParsedPreview(false);
        onCancelManageParsedSchemaModel();
    };

    const onUploadFileChange = async (file) => {
        fileReadResult = { ...fileReadResult };
        fileReadResult.file = file;
        setFileReadResult(fileReadResult);
        setBusyStatus({ message: "Preparing preview...", busy: true });
        onUploadFile(file);
    }

    const onTextContentReadComplete = () => {
        fileReadResult = { ...fileReadResult };
        fileReadResult.textContent = fileReader.result;
        setFileReadResult(fileReadResult);
        setBusyStatus({ message: "", busy: false });
    }

    const onTextContentReadError = () => {
        fileReadResult = { ...this.state.fileReadResult };
        fileReadResult.schemaFields = [];
        fileReadResult.error = fileReader.error;
        fileReadResult.textContent = "";
        setFileReadResult(fileReadResult);
        setBusyStatus({ message: "", busy: false });
    }

    const onFilePreviewClose = () => {
        fileReadResult = {
            file: null,
            schemaFields: [],
            error: null,
            textContent: ""
        };
        setFileReadResult(fileReadResult);
        setFirstRowLabel(false);
        setSchemaDetectOption("AUTO_DETECT_STRUCTURE");
        setFixWidthColumnSpecOption("AUTO_DETECT_COL_SPECS");
        setDelimiterOption("AUTO_DETECT");
        setCustomDelimiter({ value: "", status: "" });
        setShowParsedPreview(false);
        onCancelManageParsedSchemaModel();
        setFixedWidthColumSpecs([]);
        setFixedWidthColumnSpecSelectedIndex(-1);
    }

    const onFileNext = () => {
        let delimiter = "";
        switch (delimiterOption) {
            case "COMMA":
                delimiter = ",";
                break;
            case "PIPE":
                delimiter = "|";
                break;
            case "TAB":
                delimiter = "\t";
                break;
            case "SEMI_COLON":
                delimiter = ";";
                break;
            case "CUSTOM":
                if (!customDelimiter.value) {
                    setCustomDelimiter({ ...customDelimiter, status: "error" });
                    return;
                }
                delimiter = customDelimiter.value;
                break;
            default:
                delimiter = delimiterOption
                break;
        }

        setBusyStatus({ message: "Parsing data...", busy: true });
        getParsedSchemaDefinition({
            dataFile: uploadedFile,
            firstRowLabel,
            schemaDetectOption,
            delimiter,
            fixWidthColumnDetectOption: fixWidthColumnSpecOption,
            fixWidthColumnSpecs: fixedWidthColumSpecs
        })
    }

    const onDataParsingComplete = (schemaDefinition) => {
        if (schemaDefinition.schemaFields && schemaDefinition.schemaFields.length > 0) {
            setBusyStatus({ message: "", busy: false });
            const filename = fileReadResult.file.name.replace(/\.[^/.]+$/, "");
            setShowParsedPreview(true);
            onManageParsedSchemaModel(`${filename}_${Date.now()}`, schemaDefinition.schemaFields);
        }
        else {
            showError("No schema fields found.");
        }
    }

    const onAddFixWidthColumnSpec = ({ start, end, name }) => {
        const columnSpec = [...fixedWidthColumSpecs];
        columnSpec.push({ start, end, name });
        setFixedWidthColumSpecs(columnSpec);
    }

    const onInsertFixedWidthColumnSpec = ({ start, end, name }) => {
        if (fixedWidthColumnSpecSelectedIndex > -1) {
            const columnSpec = [...fixedWidthColumSpecs];
            columnSpec.splice(fixedWidthColumnSpecSelectedIndex, 0, { start, end, name });
            setFixedWidthColumSpecs(columnSpec);
        }
    }

    const onColumnSpecsChange = (columnSpecs) => {
        setFixedWidthColumSpecs(columnSpecs);
    }

    const onFixWidthSpecSelected = (selectedIndex) => {
        setFixedWidthColumnSpecSelectedIndex(selectedIndex);
    }

    const fileReader = new FileReader();
    fileReader.onload = onTextContentReadComplete;
    fileReader.onerror = onTextContentReadError;

    return <>
        <LoadingOverlay
            busy={busyStatus.busy}
            spinner
            message={busyStatus.message}>
        </LoadingOverlay>
        {
            fileReadResult.textContent ?
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={!showParsedPreview}
                    content={
                        <FullHeightContainerLayout
                            style={{ height: "100%", overflowY: "hidden" }}
                            showHeader={false}
                            showFooter={true}
                            content={
                                <TextFilePreview
                                    content={fileReadResult.textContent}
                                    enableColumnSpec={delimiterOption === "FIX_WIDTH" && fixWidthColumnSpecOption === "MANUAL_DEFINE_COL_SPECS"}
                                    selectedColumnSpecIndex={fixedWidthColumnSpecSelectedIndex}
                                    onInsertColumnSpec={onInsertFixedWidthColumnSpec}
                                    onAddColumnSpec={onAddFixWidthColumnSpec}>
                                </TextFilePreview>
                            }
                            footer={
                                <Row style={{ paddingTop: "1rem" }} wrap={false}>
                                    <Col span={24}>
                                        <Row wrap={false}>
                                            <Col>
                                                <Space direction="vertical">
                                                    <Checkbox checked={firstRowLabel} onChange={onFirstRowLabelValueChanged}>First row is column labels</Checkbox>
                                                    <Radio.Group value={schemaDetectOption} onChange={onSchemaDetectOptionChange}>
                                                        <Space direction="vertical">
                                                            <Radio value={"AUTO_DETECT_STRUCTURE"}>Auto detect structure</Radio>
                                                            <Radio value={"SCHEMA_DEFINITION_STACKED"}>Schema definition is stacked</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Space>
                                            </Col>
                                            <Col style={{ paddingLeft: "1rem" }}>
                                                <Select style={{ width: 300 }} defaultValue={delimiterOption} onChange={onDelimiterOptionChange}>
                                                    <Option value="AUTO_DETECT">Autodetect Delimiter</Option>
                                                    <Option value="COMMA">Comma delimited</Option>
                                                    <Option value="PIPE">Pipe delimited</Option>
                                                    <Option value="TAB">tab delimited</Option>
                                                    <Option value="SEMI_COLON">Semi-Colon delimited</Option>
                                                    <Option value="FIX_WIDTH">Fix Width</Option>
                                                    <Option value="CUSTOM">Custom character delimited</Option>
                                                </Select>
                                            </Col>
                                            {
                                                delimiterOption === "CUSTOM"
                                                    ?
                                                    <Col style={{ paddingLeft: "1rem" }}>
                                                        <Input
                                                            value={customDelimiter.value}
                                                            status={customDelimiter.status}
                                                            onChange={e => {
                                                                setCustomDelimiter({ value: e.target.value, status: "" });
                                                            }}
                                                            placeholder="Enter custom delimiter" />
                                                    </Col>
                                                    :
                                                    <></>
                                            }
                                            {
                                                delimiterOption === "FIX_WIDTH"
                                                    ?
                                                    <Col style={{ paddingLeft: "1rem" }}>
                                                        <Radio.Group value={fixWidthColumnSpecOption} onChange={onFixWidthColumnSpecOptionChange}>
                                                            <Space direction="vertical">
                                                                <Radio value={"AUTO_DETECT_COL_SPECS"}>Auto detect columns</Radio>
                                                                <Radio value={"MANUAL_DEFINE_COL_SPECS"}>Manually define columns</Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                    </Col>
                                                    :
                                                    <></>
                                            }
                                            {/* <Col style={{ paddingLeft: "1rem" }}>
                                                <Space direction="vertical">
                                                    <Checkbox>Encapsulated text</Checkbox>
                                                    <Checkbox>Carriage return/line feed</Checkbox>
                                                </Space>
                                            </Col> */}
                                        </Row>
                                        {
                                            delimiterOption === "FIX_WIDTH" && fixWidthColumnSpecOption === "MANUAL_DEFINE_COL_SPECS"
                                                ?
                                                <Row>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Divider>Fix width column specs</Divider>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            fixedWidthColumSpecs.length > 0
                                                                ?
                                                                <FixWidthDataColumnSpecs
                                                                    columnSpecs={fixedWidthColumSpecs}
                                                                    onColumnSpecsChange={onColumnSpecsChange}
                                                                    onSpecSelected={onFixWidthSpecSelected}>
                                                                </FixWidthDataColumnSpecs>
                                                                :
                                                                <Empty description="No column specs defined. Please select text and right click to add column spec."></Empty>
                                                        }
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                        }
                                    </Col>
                                </Row>
                            }>
                        </FullHeightContainerLayout>
                    }
                    footer={
                        <Row className="table-footer-row" style={{ paddingTop: "1rem" }}>
                            <Col span={24} className="footer-right-column">
                                <Space>
                                    <Button
                                        onClick={onFilePreviewClose}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" onClick={onFileNext} disabled={!uploadedFile}>
                                        Next
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    }>
                </FullHeightContainerLayout>
                :
                <Dragger
                    name="file"
                    multiple={false}
                    showUploadList={false}
                    action={onUploadFileChange}
                    ref={fileDraggerRef}
                    {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a singleupload.
                    </p>
                </Dragger>
        }
    </>
}

export default SchemaModelCreateFromFile