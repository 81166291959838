import react from 'react';
import { Card, Space, Button, Table, Empty, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ClosableContainer } from 'components';
import { v4 as uuidv4 } from 'uuid';

const { Column } = Table;

function DataPreview({ onClose, columns, data, title = "Data Preview (100 Records)", tableHeight = 30, dataSchemaColumns = [] }) {
    let scroll = {
        x: 150 * ((columns && columns.length) || 1)
    }
    if (tableHeight) {
        scroll.y = `${tableHeight}vh`
    }
    return <ClosableContainer
        onClose={onClose}>
        <Card size="small" title={title}>
            <Table size="small"
                rowKey={(record) => uuidv4()}
                dataSource={data}
                pagination={false}
                scroll={scroll}
                className='container-height-100'>
                {
                    columns.map((column, index) => {
                        let titleColumn = column;
                        if (dataSchemaColumns) {
                            let schemaColumnIndex = dataSchemaColumns.findIndex(item => item.toLowerCase() === column.toLowerCase());
                            if (schemaColumnIndex > -1) {
                                titleColumn = <>{column} <CheckCircleOutlined style={{ color: "#00cf18" }} /></>
                            }
                            else {
                                titleColumn = <>{column} <CloseCircleOutlined style={{ color: "#ff0000" }} /></>
                            }
                        }
                        return <Column
                            key={index}
                            dataIndex={column}
                            title={titleColumn}
                            render={(value, record, index) => <>{value}</>}
                        />
                    })
                }
            </Table>
        </Card>
    </ClosableContainer>
}

export default DataPreview