export const GET_EVENT_DATA_TAG_REQUEST = "GET_EVENT_DATA_TAG_REQUEST";
export const GET_EVENT_DATA_TAG_SUCCESS = "GET_EVENT_DATA_TAG_SUCCESS";
export const GET_EVENT_DATA_TAG_FAILURE = "GET_EVENT_DATA_TAG_FAILURE";

export const getEventDataTagRequest = (eventId, tagId) => ({
    type: GET_EVENT_DATA_TAG_REQUEST,
    params: {
        eventId,
        tagId
    }
})

export const getEventDataTagSuccess = (dataTagResult) => ({
    type: GET_EVENT_DATA_TAG_SUCCESS,
    payload: {
        dataTagResult
    }
})

export const getEventDataTagFailure = (error) => ({
    type: GET_EVENT_DATA_TAG_FAILURE,
    payload: {
        error
    }
})