
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getUsers(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.users}/api/v1/users`, { tenantid: tenantId });
    return result.data;
}

function* createUser(api, tenantId, user) {
    let result = yield call([api, api.post], `${Endpoints.users}/api/v1/users`, user, { tenantid: tenantId });
    return result.data;
}

function* getUser(api, tenantId, userId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.users}/api/v1/users/${userId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateUser(api, tenantId, userId, updatedUser) {
    yield call([api, api.put], `${Endpoints.users}/api/v1/users/${userId}`, updatedUser, { tenantid: tenantId });
}

function* resetUserPassword(api, tenantId, userId) {
    yield call([api, api.put], `${Endpoints.users}/api/v1/users/${userId}/actions/resetpassword`, {}, { tenantid: tenantId });
}

function* getUserUsersPermissions(api, tenantId) {
    //TODo - remove the current user call and replace it with pretectum user
    let user = yield call([Auth, Auth.currentAuthenticatedUser]);
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${user.attributes.sub}/permissions/resources/users`, { tenantid: tenantId });
    return result.data;
}

export function* getUsersRequest(api) {
    try {
        let users = [];
        let tenant = yield select(getTenant);
        const response = yield call(getUsers, api, tenant.tenantId);
        if (response.Items) {
            users = response.Items;
        }
        yield put(actions.getUsersSuccess(users));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "users");
        yield put(actions.getUsersFailure(errorObject));
    }
}

export function* createUsersRequest(api, { user }) {
    try {
        let tenant = yield select(getTenant);
        let createdUser = yield call(createUser, api, tenant.tenantId, user);
        //Set the user counters temporarily
        createdUser = { ...createdUser, assignedBusinessAreaCount: 1, assignedRoleCount: 1 };
        yield put(actions.createUserSuccess(createdUser));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "user");
        yield put(actions.createUserFailure(errorObject));
    }
}

export function* getUserRequest(api, { userId }) {
    try {
        let tenant = yield select(getTenant);
        const user = yield call(getUser, api, tenant.tenantId, userId);
        yield put(actions.getUserSuccess(user));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user");
        yield put(actions.getUserFailure(errorObject));
    }
}

export function* updateUserRequest(api, { userId, updatedUser }) {
    try {
        let tenant = yield select(getTenant);
        const user = yield call(updateUser, api, tenant.tenantId, userId, updatedUser);
        yield put(actions.updateUserSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "user");
        yield put(actions.updateUserFailure(errorObject));
    }
}

export function* resetUserPasswordRequest(api, { userId }) {
    try {
        let tenant = yield select(getTenant);
        const user = yield call(resetUserPassword, api, tenant.tenantId, userId);
        yield put(actions.resetUserPasswordSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Reset", "user password");
        yield put(actions.resetUserPasswordFailure(errorObject));
    }
}

export function* getUserUsersPermissionRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const result = yield call(getUserUsersPermissions, api, tenant.tenantId);
        let permission = null;
        if (result && result.Items && result.Items.length > 0) {
            for (let item of result.Items) {
                if (!permission) {
                    permission = { ...item };
                    permission.canAdd = false;
                    permission.canView = false;
                    permission.canEdit = false;
                    permission.canDelete = false;
                }

                if (item.actions.includes("add")) {
                    permission.canAdd = true;
                }
                if (item.actions.includes("view")) {
                    permission.canView = true;
                }
                if (item.actions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (item.actions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }
        yield put(actions.getUserUsersPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user permissions");
        yield put(actions.getUserUsersPermissionFailure(errorObject));
    }
}

export function* watchGetUsersRequest(api, params) {
    yield call(getUsersRequest, api);
}

export function* watchCreateUserRequest(api, { params }) {
    yield call(createUsersRequest, api, params);
}

export function* watchGetUserRequest(api, { params }) {
    yield call(getUserRequest, api, params);
}

export function* watchUpdateUserRequest(api, { params }) {
    yield call(updateUserRequest, api, params);
}

export function* watchResetUserPasswordRequest(api, { params }) {
    yield call(resetUserPasswordRequest, api, params);
}

export function* watchGetUserUsersPermissionRequest(api, params) {
    yield call(getUserUsersPermissionRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_USERS_REQUEST, watchGetUsersRequest, api);
    yield takeEvery(actions.CREATE_USER_REQUEST, watchCreateUserRequest, api);
    yield takeLatest(actions.GET_USER_REQUEST, watchGetUserRequest, api);
    yield takeLatest(actions.UPDATE_USER_REQUEST, watchUpdateUserRequest, api);
    yield takeLatest(actions.RESET_USER_PASSWORD_REQUEST, watchResetUserPasswordRequest, api);
    yield takeLatest(actions.GET_USER_USERS_PERMISSION_REQUEST, watchGetUserUsersPermissionRequest, api);
}
