
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventJob(api, eventId, jobId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/jobs/${jobId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventJobRequest(api, { eventId, jobId }) {
    try {
        const jobRevision = yield call(getEventJob, api, eventId, jobId);
        yield put(actions.getEventJobSuccess(jobRevision));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event job details");
        yield put(actions.getEventJobFailure(errorObject));
    }
}

export function* watchGetEventJobRequest(api, { params }) {
    yield call(getEventJobRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_JOB_REQUEST, watchGetEventJobRequest, api);
}