
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventSystemConnection(api, eventId, connectionId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/systemconnections/${connectionId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventSystemConnectionRequest(api, { eventId, connectionId }) {
    try {
        const connectionRevision = yield call(getEventSystemConnection, api, eventId, connectionId);
        yield put(actions.getEventSystemConnectionSuccess(connectionRevision));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event connection details");
        yield put(actions.getEventSystemConnectionFailure(errorObject));
    }
}

export function* watchGetEventSystemConnectionRequest(api, { params }) {
    yield call(getEventSystemConnectionRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_SYSTEM_CONNECTION_REQUEST, watchGetEventSystemConnectionRequest, api);
}