import {
    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_SUCCESS,
    GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_FAILURE,
} from "./actions";

const initialState = {
    getDuplicateDataObjectsResultSetSummaryResult: {
        result: {}
    },
    duplicateDataObjectsResultSetSummary: {
        result: {}
    },
};

export default (state = initialState, action) => {
    let getDuplicateDataObjectsResultSetSummaryResult = {
        result: {}
    };
    let duplicateDataObjectsResultSetSummary = {
        result: {}
    }
    switch (action.type) {
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_SUCCESS:
            getDuplicateDataObjectsResultSetSummaryResult = { ...state.getDuplicateDataObjectsResultSetSummaryResult };
            getDuplicateDataObjectsResultSetSummaryResult.result = { ...getDuplicateDataObjectsResultSetSummaryResult.result };
            getDuplicateDataObjectsResultSetSummaryResult.result[action.payload.jobId] = {
                success: true
            };

            duplicateDataObjectsResultSetSummary = { ...state.duplicateDataObjectsResultSetSummary };
            duplicateDataObjectsResultSetSummary.result = { ...duplicateDataObjectsResultSetSummary.result };
            duplicateDataObjectsResultSetSummary.result[action.payload.jobId] = action.payload.result;

            return { ...state, getDuplicateDataObjectsResultSetSummaryResult, duplicateDataObjectsResultSetSummary };
        case GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUMMARY_FAILURE:
            getDuplicateDataObjectsResultSetSummaryResult = { ...state.getDuplicateDataObjectsResultSetSummaryResult };
            getDuplicateDataObjectsResultSetSummaryResult.result = { ...getDuplicateDataObjectsResultSetSummaryResult.resultSet };
            getDuplicateDataObjectsResultSetSummaryResult.result[action.payload.jobId] = {
                success: false,
                ...action.payload.error
            };

            duplicateDataObjectsResultSetSummary = { ...state.duplicateDataObjectsResultSetSummary };
            duplicateDataObjectsResultSetSummary.result = { ...duplicateDataObjectsResultSetSummary.result };
            duplicateDataObjectsResultSetSummary.result[action.payload.jobId] = [];

            return {
                ...state, getDuplicateDataObjectsResultSetSummaryResult, duplicateDataObjectsResultSetSummary
            };
        default:
            return state;
    }
};
