import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, LineageView2, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import AppPaths from 'constants/appPaths';

class Lineage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingLineage: true
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getLineage();
        if (!this.props.businessAreaPermission) {
            this.props.getUserBusinessAreasPermission();
        }
        if (!this.props.businessAreaDataPermission) {
            this.props.getUserBusinessAreaDataPermission();
        }
        if (!this.props.schemaPermission) {
            this.props.getUserSchemasPermission();
        }
        if (!this.props.dataSetPermission) {
            this.props.getUserDataSetPermission();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetLineageResultChanged(prevProps);
    }

    onGetLineageResultChanged(prevProps) {
        if (this.props.getLineageResult && this.props.getLineageResult !== prevProps.getLineageResult) {
            this.setState({
                fetchingLineage: false
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadcrumbNavigationItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: "",
                    text: "Relationships"
                }
            ];
            this.setState({
                breadcrumbNavigationItems
            });
            this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
        }
    }

    onNodeActionClick = (action, node) => {
        switch (node.type) {
            case "BUSINESS_AREA":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":configurationItem", "businessareas")
                        .replace(":itemId", node.id)
                        .replace(":itemAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":configurationItem", "businessareas")
                        .replace(":itemId", node.id)
                        .replace(":itemAction", "edit"));
                }
                break;
            case "DATA_DOMAIN":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":configurationItem", "businessareadata")
                        .replace(":itemId", node.id)
                        .replace(":itemAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":configurationItem", "businessareadata")
                        .replace(":itemId", node.id)
                        .replace(":itemAction", "edit"));
                }
                break;
            case "SCHEMA":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", node.businessAreaName.toLowerCase())
                        .replace(":schemaId", node.id)
                        .replace(":schemaAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", node.businessAreaName.toLowerCase())
                        .replace(":schemaId", node.id)
                        .replace(":schemaAction", "edit"));
                }
                break;
            case "DATA_SET":
                if (action === "VIEW") {
                    this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", node.businessAreaName.toLowerCase())
                        .replace(":schema", node.schemaName.toLowerCase())
                        .replace(":dataSetId", node.id)
                        .replace(":dataSetAction", "view"));
                }
                else if (action === "EDIT") {
                    this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
                        .replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", node.businessAreaName.toLowerCase())
                        .replace(":schema", node.schemaName.toLowerCase())
                        .replace(":dataSetId", node.id)
                        .replace(":dataSetAction", "edit"));
                }
                break;
            case "JOB":
                if (action === "VIEW") {
                    this.props.history.push(`${AppPaths.TENANT_DATA_OBJECT_DUPLICATE_SEARCH.replace(":tenant", this.props.tenant.toLowerCase())}#${node.id}`);
                }
                break;
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={<>
                    <LoadingOverlay
                        busy={this.state.fetchingLineage
                            || !this.props.businessAreaPermission
                            || !this.props.businessAreaDataPermission
                            || !this.props.schemaPermission
                            || !this.props.dataSetPermission}
                        spinner
                        message={"Please wait..."}>
                    </LoadingOverlay>
                    {
                        this.props.lineageResult
                            && this.props.businessAreaPermission
                            && this.props.businessAreaDataPermission
                            && this.props.schemaPermission
                            && this.props.dataSetPermission
                            ?
                            <LineageView2
                                lineageNodes={this.props.lineageResult}
                                businessAreaPermission={this.props.businessAreaPermission}
                                businessAreaDataPermission={this.props.businessAreaDataPermission}
                                schemaPermission={this.props.schemaPermission}
                                dataSetPermission={this.props.dataSetPermission}
                                onNodeActionClick={this.onNodeActionClick}></LineageView2>
                            :
                            <></>
                    }
                </>}>
            </FullHeightContainerLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getLineageResult: state.lineage.getLineageResult,
        lineageResult: state.lineage.lineageResult,
        businessAreaPermission: state.businessAreas.permission,
        businessAreaDataPermission: state.businessAreaData.permission,
        schemaPermission: state.schemaModels.permission,
        dataSetPermission: state.dataSets.permission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLineage: () => dispatch(actions.getLineageRequest()),
        getUserBusinessAreasPermission: () => dispatch(actions.getUserBusinessAreasPermissionRequest()),
        getUserBusinessAreaDataPermission: () => dispatch(actions.getUserBusinessAreaDataPermissionRequest()),
        getUserSchemasPermission: () => dispatch(actions.getUserSchemaModelsPermissionRequest()),
        getUserDataSetPermission: () => dispatch(actions.getUserDataSetPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lineage));