import react from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { Menu } from 'antd';
import { Row, Col } from 'antd';

const VerticleMenuContent = (props) => {
    return <Row className="layout-row">
        <Col className="full-height-container" span={4}>
            <Menu onSelect={({ item, key, keyPath, selectedKeys, domEvent }) => props.onItemClick(key)} mode="vertical" selectedKeys={[props.activeItem]}>
                {props.items.map(item => {
                    return <Menu.Item key={item.name} data-testid={`vertical-menu-configuration-${item.name}`}>{item.description}</Menu.Item>
                })}
            </Menu>
        </Col>
        <Col className="full-height-container" span={20}>
            <Segment className="full-height-flex-container no-verticle-scroll">
                {props.itemContainer}
            </Segment>
        </Col>
    </Row>
}

export default VerticleMenuContent;
