import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getDataTagHistory(api, tagId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datatags/${tagId}/history`);
    return result.data;
}

function* getDataTagEvents(api, tagId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/datatags/${tagId}/events`);
    return result.data;
}

export function* getDataTagHistoryRequest(api, { tagId }) {
    try {
        let dataTagHistoryResult = yield call(getDataTagHistory, api, tagId);
        yield put(actions.getDataTagHistorySuccess(dataTagHistoryResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data tag history");
        yield put(actions.getDataTagHistoryFailure(errorObject));
    }
}

export function* getDataTagEventsRequest(api, { tagId }) {
    try {
        let dataTagEventsResult = yield call(getDataTagEvents, api, tagId);
        yield put(actions.getDataTagEventsSuccess(dataTagEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data tag events");
        yield put(actions.getDataTagEventsFailure(errorObject));
    }
}

export function* watchGetDataTagHistoryRequest(api, { params }) {
    yield call(getDataTagHistoryRequest, api, params);
}

export function* watchGetDataTagEventsRequest(api, { params }) {
    yield call(getDataTagEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_TAG_HISTORY_REQUEST, watchGetDataTagHistoryRequest, api);
    yield takeLatest(actions.GET_DATA_TAG_EVENTS_REQUEST, watchGetDataTagEventsRequest, api);
}