import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { AWSS3BucketContentList, LoadingOverlay, ProhibitedArea, FullHeightContainerLayout, PathBreadcrumb } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col, Typography } from "antd";

const { Text, Link } = Typography;

class SystemConnectionAwsS3BucketContentList extends Component {

    constructor(props) {
        super(props);
        this.defaultPath = `Buckets/${this.props.bucket}/`;
        this.state = {
            busyMessage: "",
            fetchingContent: false,
            permissionDenied: false,
            path: this.defaultPath
        }
    }

    componentWillMount() {
        this.onGetFolderContentList(this.state.path);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetAwsS3ConnectionBucketContentListResultUpdate(prevProps);
        this.onGetAwsS3ConnectionBucketDownloadFileResultUpdate(prevProps);
    }

    onGetAwsS3ConnectionBucketContentListResultUpdate = (prevProps) => {
        if (this.props.getAwsS3ConnectionBucketContentListResult && this.props.getAwsS3ConnectionBucketContentListResult !== prevProps.getAwsS3ConnectionBucketContentListResult) {
            if (!this.props.getAwsS3ConnectionBucketContentListResult.success) {
                if (this.props.getAwsS3ConnectionBucketContentListResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get bucket contents at this moment.");
                }
            }
            this.setState({
                fetchingContent: false
            });
        }
    }

    onGetAwsS3ConnectionBucketDownloadFileResultUpdate = (prevProps) => {
        if (this.props.getAwsS3ConnectionBucketDownloadFileResult && this.props.getAwsS3ConnectionBucketDownloadFileResult !== prevProps.getAwsS3ConnectionBucketDownloadFileResult) {
            if (!this.props.getAwsS3ConnectionBucketDownloadFileResult.success) {
                if (this.props.getAwsS3ConnectionBucketDownloadFileResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to read file at this moment.");
                }
            }
            else if (!this.props.awsS3ConnectionBucketDownloadFile) {
                showError("Could not able to read file at this moment.");
            }
            else {
                this.props.onFileSelected({ file: this.state.selectedFile, fileReadResult: this.props.awsS3ConnectionBucketDownloadFile });
            }
            this.setState({
                fetchingContent: false
            });
        }
    }

    onGetFolderContentList = (path) => {
        this.setState({
            fetchingContent: true,
            path: path
        });
        if (path.startsWith(this.defaultPath)) {
            path = path.substring(this.defaultPath.length);
            this.props.getAwsS3ConnectionBucketContentList(this.props.connectionId, this.props.bucket, path);
        }
        else if (path === "Buckets/") {
            this.props.goToBuckets();
        }

    }

    onGetDownloadFile = (file) => {
        if (file) {
            if (file.read) {
                this.setState({
                    fetchingContent: true,
                    selectedFile: file,
                    busyMessage: `Reading file ${file.name}...`
                });
                this.props.getAwsS3ConnectionBucketDownloadFile(this.props.connectionId, this.props.bucket, file.path);
            }
            else {
                this.props.onFileSelected({ file, fileReadResult: null });
            }
        }
        else {
            this.props.onFileSelected(null);
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.state.fetchingContent}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    <AWSS3BucketContentList
                        fetchingContent={this.state.fetchingContent}
                        contentList={this.props.awsS3ConnectionBucketContentList}
                        bucket={this.props.bucket}
                        home="Buckets"
                        path={this.state.path}
                        onFolderSelected={this.onGetFolderContentList}
                        onFileSelected={this.onGetDownloadFile}
                        allowNewKey={this.props.allowNewKey}
                        readFile={this.props.readSelectedFile}
                        allowedFileTypes={this.props.allowedFileTypes}>
                    </AWSS3BucketContentList>
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getAwsS3ConnectionBucketContentListResult: state.systemConnectionAwsS3.getAwsS3ConnectionBucketContentListResult,
        awsS3ConnectionBucketContentList: state.systemConnectionAwsS3.awsS3ConnectionBucketContentList,
        getAwsS3ConnectionBucketDownloadFileResult: state.systemConnectionAwsS3.getAwsS3ConnectionBucketDownloadFileResult,
        awsS3ConnectionBucketDownloadFile: state.systemConnectionAwsS3.awsS3ConnectionBucketDownloadFile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAwsS3ConnectionBucketContentList: (connectionId, bucket, path) => dispatch(actions.getAwsS3ConnectionBucketContentListRequest(connectionId, bucket, path)),
        getAwsS3ConnectionBucketDownloadFile: (connectionId, bucket, file) => dispatch(actions.getAwsS3ConnectionBucketDownloadFileRequest(connectionId, bucket, file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemConnectionAwsS3BucketContentList));