import React from "react";
import { Form, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea } from "components";

const { Column } = Table;

const DataTagList = ({ dataTagList, onCreateNewDataTag, onDeleteDataTag, onViewDataTag, onEditDataTag, onViewDataTagHistory, permission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <>
            <Table size="small"
                rowKey="tagId"
                dataSource={dataTagList}
                pagination={false}
                scroll={{ y: 600 }}>
                <Column
                    width="6em"
                    render={(value, dataTag, index) => <>
                    <Tooltip title ='Edit Data Classifier Tags'>
                        <Icon
                            disabled={!permission.canEdit}
                            color='blue'
                            name='pencil'
                            className="action-cursor"
                            onClick={() => onEditDataTag(dataTag.tagId)} />  </Tooltip>
                            <Tooltip title ='View Data Classifier Tags'>
                        <Icon
                            disabled={!permission.canView}
                            color='blue'
                            name='eye'
                            className="action-cursor"
                            onClick={() => onViewDataTag(dataTag.tagId)} /> </Tooltip>
                           <Tooltip title='View Data Classifier History'>
                        <Icon
                            disabled={!permission.canView}
                            color='blue'
                            name='history'
                            className="action-cursor"
                            onClick={() => onViewDataTagHistory(dataTag.tagId)} /> </Tooltip>
                    </>}
                />
                <Column
                    width="15%"
                    dataIndex={"businessAreaName"}
                    title={"Business Area"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, dataTag, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="10%"
                    dataIndex={"tagName"}
                    title={"Tag Name"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, dataTag, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="20%"
                    dataIndex={"tagDescription"}
                    title={"Tag Description"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, dataTag, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="20%"
                    dataIndex={"updatedDate"}
                    title={"Last Updated"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, dataTag, index) => {
                        let dateValue = moment.utc(value).toDate().toLocaleString()
                        return <Tooltip placement="topLeft" title={dateValue}>{dateValue}</Tooltip>
                    }}
                />
                <Column
                    width="18%"
                    dataIndex={"updatedByEmail"}
                    title={"Updated By"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, dataTag, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="5%"
                    render={(value, dataTag, index) => {
                        return (  
                            
                            <Tooltip title ='Delete Data Classifier Tags'>
                            <Icon
                                disabled={!permission.canDelete}
                                color='blue'
                                name='trash alternate outline'
                                className="action-cursor"
                                onClick={() => onDeleteDataTag(dataTag)} /></Tooltip> 
                        );
                    }}
                />
            </Table>
            <Row className="table-footer-row">
                <Col span={24} className="footer-right-column">
                    <Button type="primary"
                        disabled={!permission.canAdd}
                        onClick={onCreateNewDataTag}>
                        <PlusOutlined /> Add Tag
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default DataTagList;