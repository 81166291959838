import React, { useState } from "react";
import { Row, Col, Typography } from 'antd';
import { FullHeightContainerLayout, ComingSoon } from 'components';
import { SchemaModelHomeOverview, DataSetHomeOverview, DataObjectJobHomeOverview, DataSetInsightsHomeOverview } from 'containers';

const { Title } = Typography;

function Home({ tenant }) {
    const [selectedDataSetId, setSelectedDataSetId] = useState(null);
    const [dataSetInsightsRefreshFlag, setDataSetInsightsRefreshFlag] = useState(false);
    const onDataSetSelected = (dataSetId) => {
        setSelectedDataSetId(dataSetId);
        setDataSetInsightsRefreshFlag(!dataSetInsightsRefreshFlag);
    }
    return <FullHeightContainerLayout
        showHeader={false}
        showFooter={false}
        content={
            <>
                <Row style={{ height: "50%" }}>
                    <Col span={12} style={{ height: "95%" }}>
                        <SchemaModelHomeOverview tenant={tenant}></SchemaModelHomeOverview>
                    </Col>
                    <Col span={12} style={{ height: "95%" }}>
                        <DataSetHomeOverview tenant={tenant} onDataSetSelected={onDataSetSelected}></DataSetHomeOverview>
                    </Col>
                </Row>
                <Row style={{ height: "50%" }}>
                    <Col span={12} style={{ height: "95%" }}>
                        <DataObjectJobHomeOverview tenant={tenant}></DataObjectJobHomeOverview>
                    </Col>
                    <Col span={12} style={{ height: "95%" }}>
                        <DataSetInsightsHomeOverview tenant={tenant} dataSetId={selectedDataSetId} refreshFlag={dataSetInsightsRefreshFlag}></DataSetInsightsHomeOverview>
                    </Col>
                </Row>
            </>
        } />
}

export default Home;