import React, { useState, useEffect } from 'react';
import { Typography, Col, Select, Breadcrumb, } from "antd";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text, Link } = Typography;

const getPathParts = (path) => {
    let paths = [];
    if (path) {
        if (path.startsWith("/")) {
            path = path.substring(1);
        }
        if (path.endsWith("/")) {
            path = path.substring(0, path.length - 1);
        }
        let pathParts = path.split("/");
        let pathBuilder = "";
        for (let part of pathParts) {
            pathBuilder = `${pathBuilder}${part}/`;
            paths.push({
                name: part,
                path: pathBuilder
            });
        }
    }
    return paths;
}
const PathBreadcrumb = ({ path, onPathClick, homeImageSrc }) => {
    const [breadcrumbItems, setBreadcrumbItems] = useState(getPathParts(path));

    useEffect(() => {
        setBreadcrumbItems(getPathParts(path));
    }, [path]);

    const onBreadcrumbItemClick = (path) => {
        onPathClick(path);
    }

    return <Breadcrumb>
        {
            breadcrumbItems.map((item, index) => {
                return <Breadcrumb.Item key={item.path}>
                    {homeImageSrc && index === 0 && <><img src={homeImageSrc} /> </>}
                    <Link style={{ color: "#1890ff" }} onClick={() => onBreadcrumbItemClick(item.path)}>{item.name}</Link>
                </Breadcrumb.Item>
            })
        }
    </Breadcrumb>
}

export default PathBreadcrumb