export const GET_PARSED_SCHEMA_DEFINITION_REQUEST = "GET_PARSED_SCHEMA_DEFINITION_REQUEST";
export const GET_PARSED_SCHEMA_DEFINITION_SUCCESS = "GET_PARSED_SCHEMA_DEFINITION_SUCCESS";
export const GET_PARSED_SCHEMA_DEFINITION_FAILURE = "GET_PARSED_SCHEMA_DEFINITION_FAILURE";

export const getParsedSchemaDefinitionRequest = (
    {
        dataFile,
        firstRowLabel,
        schemaDetectOption,
        delimiter,
        fixWidthColumnDetectOption,
        fixWidthColumnSpecs

    }) => ({
        type: GET_PARSED_SCHEMA_DEFINITION_REQUEST,
        params: {
            parseConfig: {
                dataFile,
                firstRowLabel,
                schemaDetectOption,
                delimiter,
                fixWidthColumnDetectOption,
                fixWidthColumnSpecs

            }
        }
    })

export const getParsedSchemaDefinitionSuccess = (schemaDefinition) => ({
    type: GET_PARSED_SCHEMA_DEFINITION_SUCCESS,
    payload: {
        schemaDefinition
    }
})

export const getParsedSchemaDefinitionFailure = (error) => ({
    type: GET_PARSED_SCHEMA_DEFINITION_FAILURE,
    payload: {
        error
    }
})