import React from 'react';
import { Modal } from 'semantic-ui-react';

const ModalLoadingOverlay = ({ busy, message }) => {
    return (
        busy ? <Modal
            basic
            open={busy}>
            <></>
        </Modal> : <></>
    )
}

export default ModalLoadingOverlay