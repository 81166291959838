import React from "react";
import { Table, Tooltip, Space, Row, Col, Typography } from "antd";
import moment from 'moment';

const { Column } = Table;
const { Link, Text } = Typography;

const BatchProcessResult = ({ batchProcessResult, onBatchOperationClick, textMaxWidth = "12rem", readOnly = false, jobType = null }) => {
    if (jobType === "DATA_OBJECTS_EXPORT") {
        if (batchProcessResult.s3Key) {
            return <Tooltip placement="topLeft" title="Download file">
                <Link ellipsis={true} style={{ maxWidth: textMaxWidth }} onClick={() => onBatchOperationClick && onBatchOperationClick(null)}>
                    Download file
                </Link>
            </Tooltip>
        }
        return <></>
    }

    return (
        <Space align="start" direction="vertical">
            {batchProcessResult.error ?
                <>
                    {batchProcessResult.error.code ?
                        <Row>
                            <Col>
                                <Tooltip placement="topLeft" title={batchProcessResult.error.code}>
                                    <Text type="danger" strong={true} ellipsis={true} style={{ maxWidth: textMaxWidth }}>{batchProcessResult.error.code}</Text>
                                </Tooltip>
                            </Col>
                        </Row> : <></>}
                    {batchProcessResult.error.message ?
                        <Row>
                            <Col>
                                <Tooltip placement="topLeft" title={batchProcessResult.error.message}>
                                    <Text type="danger" ellipsis={true} style={{ maxWidth: textMaxWidth }}>{batchProcessResult.error.message}</Text>
                                </Tooltip>
                            </Col>
                        </Row> :
                        <Row>
                            <Col>
                                <Tooltip placement="topLeft" title="System error occurred. Please contact support.">
                                    <Text type="danger" ellipsis={true} style={{ maxWidth: textMaxWidth }}>System error occurred. Please contact support.</Text>
                                </Tooltip>
                            </Col>
                        </Row>}
                </> : <></>}
            {batchProcessResult.itemOperations
                ?
                batchProcessResult.itemOperations.map(item => {
                    let text = "";
                    if (jobType === "DATA_OBJECTS_BULK_VERIFICATION") {
                        text = `Verification links sent for ${item.recordCount} record(s)`;
                    }
                    else {
                        switch (item.itemOperation) {
                            case "INSERT":
                                text = `Inserted ${item.recordCount} record(s)`;
                                break;
                            case "DELETE":
                                text = `Deleted ${item.recordCount} record(s)`;
                                break;
                            case "UPDATE":
                                text = `Updated ${item.recordCount} record(s)`;
                                break;
                            case "INDEX":
                                text = `Indexed ${item.recordCount} record(s)`;
                                break;
                        }
                    }
                    return text
                        ?
                        <Row key={item.itemOperation}>
                            <Col>
                                <Tooltip placement="topLeft" title={text}>
                                    {!readOnly
                                        ?
                                        <Link onClick={() => onBatchOperationClick && onBatchOperationClick(item)} ellipsis={true} style={{ maxWidth: textMaxWidth }}>
                                            {text}
                                        </Link>
                                        :
                                        <Text type="secondary" ellipsis={true} style={{ maxWidth: textMaxWidth }}>{text}</Text>
                                    }
                                </Tooltip>
                            </Col>
                        </Row>
                        :
                        <></>
                })
                :
                (
                    batchProcessResult.status
                        ?
                        <Tooltip placement="topLeft" title={batchProcessResult.status}>
                            <Link ellipsis={true} style={{ maxWidth: textMaxWidth }}>
                                {batchProcessResult.status}
                            </Link>
                        </Tooltip>
                        :
                        <></>
                )}
        </Space>);
};

export default BatchProcessResult;