import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Row, Col, Space, Button, Checkbox, Typography, DatePicker, Card } from 'antd';
import { CopyInput, AbsoluteRelativeDatePicker } from 'components';

const { Text } = Typography;

function ShareDataObjectModalDialog({ show, onGetLink, shareLink, loading, onClose }) {
    const [modalVisible, setModalVisible] = useState(show);
    const [activeTabKey, setActiveTabKey] = useState('ABSOLUTE_DATE');
    const [readOnly, setReadOnly] = useState(false);
    const [expiryDate, setExpiryDate] = useState(null);

    useEffect(() => {
        setModalVisible(show);
    }, [show]);

    const closeModal = () => {
        setModalVisible(false);
    }

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const onExpiryDateChange = (value) => {
        setExpiryDate(value);
        console.log(value);
    }

    const onReadOnlyValueChange = (e) => {
        setReadOnly(e.target.checked);
    };

    const onGetLinkClick = () => {
        onGetLink && onGetLink({ readOnly, expiryDate });
    }

    return <Modal
        title={"Dataset record share"}
        centered={true}
        visible={modalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        afterClose={onClose}
        bodyStyle={{ height: "500px" }}
        destroyOnClose={true}>
        <Row style={{ height: "100%" }}>
            <Col span={24}>
                <Row style={{ height: "60%" }}>
                    <Col span={24}>
                        <Card
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                            title="Link Expiry"
                            tabList={[
                                {
                                    key: 'ABSOLUTE_DATE',
                                    tab: 'Absolute',
                                },
                                {
                                    key: 'RELATIVE',
                                    tab: 'Relative',
                                },
                            ]}
                            activeTabKey={activeTabKey}
                            onTabChange={(key) => {
                                onTabChange(key);
                            }}
                        >
                            {
                                activeTabKey === "ABSOLUTE_DATE"
                                    ?
                                    <DatePicker showTime value={expiryDate} onChange={onExpiryDateChange} />
                                    :
                                    (
                                        activeTabKey === "RELATIVE"
                                            ?
                                            <AbsoluteRelativeDatePicker onChange={onExpiryDateChange}></AbsoluteRelativeDatePicker>
                                            :
                                            <></>
                                    )
                            }
                        </Card>
                    </Col>
                </Row>
                <Row style={{ height: "40%", alignItems: "end" }}>
                    <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col style={{ display: "flex", alignItems: "end" }} span={24}>
                                <Checkbox onChange={onReadOnlyValueChange} value={readOnly}>Readonly</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ display: "flex", alignItems: "end" }} span={24}>
                                <Space className='share-datarecord-sharelink' style={{ width: "100%" }}>
                                    <CopyInput value={shareLink} readOnly={true} copyToolTip={"Copy link"}></CopyInput>
                                    <Button loading={loading} onClick={onGetLinkClick}>Get link</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Modal>
}

export default ShareDataObjectModalDialog