import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getSchemaModelHistory(api, tenantId, businessAreaId, schemaId) {
    let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/history`, { tenantid: tenantId });
    return result.data;
}

function* getSchemaModelEvents(api, tenantId, schemaId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/schemas/${schemaId}/events`, { tenantid: tenantId });
    return result.data;
}

function* getSchemaModelVersionFieldRevisions(api, tenantId, businessAreaId, schemaId, version) {
    let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/version/${version}/fields`, { tenantid: tenantId });
    return result.data;
}

export function* getSchemaModelHistoryRequest(api, { businessAreaId, schemaId }) {
    try {
        let tenant = yield select(getTenant);
        let SchemaModelHistoryResult = yield call(getSchemaModelHistory, api, tenant.tenantId, businessAreaId, schemaId);
        yield put(actions.getSchemaModelHistorySuccess(SchemaModelHistoryResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema history");
        yield put(actions.getSchemaModelHistoryFailure(errorObject));
    }
}

export function* getSchemaModelEventsRequest(api, { schemaId }) {
    try {
        let tenant = yield select(getTenant);
        let SchemaModelEventsResult = yield call(getSchemaModelEvents, api, tenant.tenantId, schemaId);
        yield put(actions.getSchemaModelEventsSuccess(SchemaModelEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema events");
        yield put(actions.getSchemaModelEventsFailure(errorObject));
    }
}

export function* getSchemaModelVersionFieldRevisionsRequest(api, { businessAreaId, schemaId, version }) {
    try {
        let tenant = yield select(getTenant);
        let fieldResult = yield call(getSchemaModelVersionFieldRevisions, api, tenant.tenantId, businessAreaId, schemaId, version);
        yield put(actions.getSchemaModelVersionFieldRevisionsSuccess({
            fieldResult,
            schemaId,
            version
        }));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "schema version field revisions");
        yield put(actions.getSchemaModelVersionFieldRevisionsFailure({ ...errorObject, schemaId }));
    }
}

export function* watchGetSchemaModelHistoryRequest(api, { params }) {
    yield call(getSchemaModelHistoryRequest, api, params);
}

export function* watchGetSchemaModelEventsRequest(api, { params }) {
    yield call(getSchemaModelEventsRequest, api, params);
}

export function* watchGetSchemaModelVersionFieldRevisionsRequest(api, { params }) {
    yield call(getSchemaModelVersionFieldRevisionsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_SCHEMA_MODEL_HISTORY_REQUEST, watchGetSchemaModelHistoryRequest, api);
    yield takeLatest(actions.GET_SCHEMA_MODEL_EVENTS_REQUEST, watchGetSchemaModelEventsRequest, api);
    yield takeLatest(actions.GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_REQUEST, watchGetSchemaModelVersionFieldRevisionsRequest, api)
}