export const GET_EVENT_SCHEMA_MODEL_REQUEST = "GET_EVENT_SCHEMA_MODEL_REQUEST";
export const GET_EVENT_SCHEMA_MODEL_SUCCESS = "GET_EVENT_SCHEMA_MODEL_SUCCESS";
export const GET_EVENT_SCHEMA_MODEL_FAILURE = "GET_EVENT_SCHEMA_MODEL_FAILURE";

export const getEventSchemaModelRequest = (eventId, schemaId) => ({
    type: GET_EVENT_SCHEMA_MODEL_REQUEST,
    params: {
        eventId,
        schemaId
    }
})

export const getEventSchemaModelSuccess = (schemaModelRevision) => ({
    type: GET_EVENT_SCHEMA_MODEL_SUCCESS,
    payload: schemaModelRevision
})

export const getEventSchemaModelFailure = (error) => ({
    type: GET_EVENT_SCHEMA_MODEL_FAILURE,
    payload: error
})