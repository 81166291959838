import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, List, Space, Button, Table, Checkbox, Empty } from "antd";
import { DataObjectVerificationEventListItem } from 'components';

const DataObjectVerificationEventList = ({ verificationList }) => {
    return (
        verificationList && verificationList.length > 0
            ?
            <List
                itemLayout="horizontal"
                dataSource={verificationList}
                renderItem={(item) => <DataObjectVerificationEventListItem verificationItem={item}></DataObjectVerificationEventListItem>}
            />
            :
            <Empty description="No verification request exist.">
            </Empty>
    );
}

export default DataObjectVerificationEventList;