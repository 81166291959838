import{
    GET_SYSTEM_KEY_EVENTS_REQUEST,
    GET_SYSTEM_KEY_EVENTS_SUCCESS,
    GET_SYSTEM_KEY_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getSystemKeyEventsResult: {
        success: true,
    },
    systemKeyEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SYSTEM_KEY_EVENTS_SUCCESS:
            return { ...state, getSystemKeyEventsResult: { success: true }, systemKeyEventsResult: action.payload.systemKeyEventResults };
        case GET_SYSTEM_KEY_EVENTS_FAILURE:
            return {
                ...state, getSystemKeyEventsResult: { success: false, ...action.payload.error }, systemKeyEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};

