import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

export default function getDslRangeClauseValue(
    context: IDslQueryContext,
    queryClause: IDslQueryRangeClause,
    fields: ISearchProperty[],
) {
    const rules: RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> = [];

    for (const [field, clause] of Object.entries(queryClause)) {
        if (fields.some((f) => f.name === field)) {
            // Field is valid

            if (clause.gte) {
                // Greater than or equals to
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: context === "must_not" ? "<" : ">=",
                    valueSource: "value",
                    value: clause.gte,
                });
            }

            if (clause.gt) {
                // Greater than
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: context === "must_not" ? "<=" : ">",
                    valueSource: "value",
                    value: clause.gt,
                });
            }

            if (clause.lte) {
                // Lesser than or equals to
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: context === "must_not" ? ">" : "<=",
                    valueSource: "value",
                    value: clause.lte,
                });
            }

            if (clause.lt) {
                // Lesser than
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: context === "must_not" ? ">=" : "<",
                    valueSource: "value",
                    value: clause.lt,
                });
            }
        }
    }

    return rules;
}
