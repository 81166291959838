import React, { useState } from "react";
import { Tooltip, Typography, Button, Table, Checkbox, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";

const { Column } = Table;
const { Text, Link } = Typography;

const AdminConsoleTenantList = ({ items = [], onTenantClick }) => {
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="tenantId"
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            dataSource={items}
                            className={items.length === 0 ? "container-height-100 table-empty-body" : "container-height-100"}>
                            <Column
                                width="15%"
                                dataIndex={"name"}
                                title={"Tenant"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}><Link style={{ color: "#1890ff" }} onClick={() => onTenantClick(item.tenantId)}>{value}</Link></Tooltip>}
                            />
                            <Column
                                width="15%"
                                dataIndex={"tenantId"}
                                title={"Tenant ID"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="15%"
                                dataIndex={"domain"}
                                title={"Tenant Domain"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="10%"
                                dataIndex={"renewalDate"}
                                title={"Renewal Date"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex={"activeUserCount"}
                                title={"Users"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex={"activeBusinessAreaCount"}
                                title={"Business Areas"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex={"activeSchemaCount"}
                                title={"Schemas"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex={"activeDataSetCount"}
                                title={"Data Sets"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex={"activeDataSetRecordCount"}
                                title={"Customer Records"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, item, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                        </Table>
                    </div>
                </div >}>
        </FullHeightContainerLayout >
    );
};

export default AdminConsoleTenantList;