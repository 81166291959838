import React, { useState, useEffect } from 'react';
import { Typography, Card, Col, Row, Radio } from 'antd';
import { CopyInput } from "components";
import moment from 'moment';

const { Text } = Typography;

const AbsoluteRelativeDatePicker = ({ onChange }) => {
    const [dateValue, setDateValue] = useState(null);
    const onDateValueChange = (e) => {
        setDateValue(e.target.value);
        const [itemValue, itemType] = e.target.value.split("_");
        onChange && onChange(moment().add(parseInt(itemValue), itemType));
    };
    return (
        <Row>
            <Col span={24}>
                <Row>
                    <Col span={4}><Text strong>Minutes</Text></Col>
                    <Col>
                        <Radio.Group onChange={onDateValueChange} value={dateValue}>
                            <Radio.Button style={{ marginRight: "1rem" }} value="5_minutes">5</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="10_minutes">10</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="15_minutes">15</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="20_minutes">20</Radio.Button>
                            <Radio.Button value="30_minutes">30</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col span={4}><Text strong>Hours</Text></Col>
                    <Col>
                        <Radio.Group onChange={onDateValueChange} value={dateValue}>
                            <Radio.Button style={{ marginRight: "1rem" }} value="1_hours">1</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="2_hours">2</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="3_hours">3</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="6_hours">6</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="8_hours">8</Radio.Button>
                            <Radio.Button value="12_hours">12</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col span={4}><Text strong>Days</Text></Col>
                    <Col>
                        <Radio.Group onChange={onDateValueChange} value={dateValue}>
                            <Radio.Button style={{ marginRight: "1rem" }} value="1_days">1</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="2_days">2</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="3_days">3</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="4_days">4</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="5_days">5</Radio.Button>
                            <Radio.Button value="6_days">6</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col span={4}><Text strong>Weeks</Text></Col>
                    <Col>
                        <Radio.Group onChange={onDateValueChange} value={dateValue}>
                            <Radio.Button style={{ marginRight: "1rem" }} value="1_weeks">1</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="2_weeks">2</Radio.Button>
                            <Radio.Button style={{ marginRight: "1rem" }} value="3_weeks">3</Radio.Button>
                            <Radio.Button value="4_weeks">4</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AbsoluteRelativeDatePicker
