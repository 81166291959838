import {
    GET_EVENT_BUSINESS_AREA_USERS_SUCCESS,
    GET_EVENT_BUSINESS_AREA_USERS_FAILURE
} from "./actions";

const initialState = {
    getEventBusinessAreaUsersResult: {
        success: true
    },
    usersResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    let usersResult = {
        Items: []
    };
    switch (action.type) {
        case GET_EVENT_BUSINESS_AREA_USERS_SUCCESS:
            usersResult = action.payload;
            return { ...state, getEventBusinessAreaUsersResult: { success: true }, usersResult };
        case GET_EVENT_BUSINESS_AREA_USERS_FAILURE:
            return {
                ...state,
                getEventBusinessAreaUsersResult: { success: false, ...action.payload },
                usersResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
