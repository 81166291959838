import {
    GET_BUSINESS_AREA_HISTORY_SUCCESS,
    GET_BUSINESS_AREA_HISTORY_FAILURE,

    GET_BUSINESS_AREA_EVENTS_SUCCESS,
    GET_BUSINESS_AREA_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getBusinessAreaHistoryResult: {
        success: true
    },
    businessAreaHistoryResult: {
        Items: []
    },
    getBusinessAreaEventsResult: {
        success: true
    },
    businessAreaEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BUSINESS_AREA_HISTORY_SUCCESS:
            return { ...state, getBusinessAreaHistoryResult: { success: true }, businessAreaHistoryResult: action.payload };
        case GET_BUSINESS_AREA_HISTORY_FAILURE:
            return { ...state, getBusinessAreaHistoryResult: { success: false, ...action.payload }, businessAreaHistoryResult: { Items: [] } };
        case GET_BUSINESS_AREA_EVENTS_SUCCESS:
            return { ...state, getBusinessAreaEventsResult: { success: true }, businessAreaEventsResult: action.payload };
        case GET_BUSINESS_AREA_EVENTS_FAILURE:
            return {
                ...state, getBusinessAreaEventsResult: { success: false, ...action.payload }, businessAreaEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
