import React from "react";
import _ from 'lodash';
import {
    FormBooleanField,
    FormCurrencyField,
    FormDateDayField,
    FormDateField,
    FormDateMonthField,
    FormDateTimeField,
    FormDateYearField,
    FormEmailField,
    FormFloatField,
    FormIntegerField,
    FormPhoneField,
    FormPicklistField,
    FormStringField,
    FormTimeField,
    FormUrlField,
    FormLatLongField
} from 'components';

import {
    FormDomainDataPicklistField
} from 'containers';

const FormField = ({ dataType, ...props }) => {
    switch (dataType) {
        case "integer":
            return <FormIntegerField {...props} />;
        case "float":
            return <FormFloatField {...props} />;
        case "string":
            return <FormStringField {...props} />;
        case "boolean":
            return <FormBooleanField {...props} />;
        case "datetime":
            return <FormDateTimeField {...props} />;
        case "date":
            return <FormDateField {...props} />;
        case "time":
            return <FormTimeField {...props} />;
        case "date-year":
            return <FormDateYearField {...props} />;
        case "date-month":
            return <FormDateMonthField {...props} />;
        case "date-day":
            return <FormDateDayField {...props} />;
        case "currency":
            return <FormCurrencyField {...props} />;
        case "picklist":
            if (props.picklistType === "static") {
                return <FormPicklistField items={props.staticPicklist} fetchingData={false} {...props} />;
            }
            else if (props.picklistType === "dynamic") {
                return <FormDomainDataPicklistField {...props} />;
            }
            return <FormPicklistField items={[]} fetchingData={false} {...props} />;
        case "email":
            return <FormEmailField {...props} />;
        case "url":
            return <FormUrlField {...props} />;
        case "phone":
            return <FormPhoneField {...props} />;
        case "latlng":
            return <FormLatLongField
                    precision={4}
                    format="decimal"
                    separator=","
                    {...props}
                />;
    }
    return <FormStringField {...props} />;
}

export default FormField;