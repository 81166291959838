import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { BusinessAreaSelectSystemConnectionList, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col, Empty } from "antd";

class BusinessAreaSystemConnections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            permissionDenied: false,
            fetchingPermission: false,
        }
    }

    componentWillMount() {
        this.setState({
            fetchingConnections: true
        });
        this.props.getBusinessAreaSystemConnections(this.props.businessAreaId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetBusinessAreaSystemConnectionsResultUpdate(prevProps);
    }

    onGetBusinessAreaSystemConnectionsResultUpdate = (prevProps) => {
        if (this.props.getBusinessAreaSystemConnectionsResult && this.props.getBusinessAreaSystemConnectionsResult !== prevProps.getBusinessAreaSystemConnectionsResult) {
            if (!this.props.getBusinessAreaSystemConnectionsResult.success) {
                if (this.props.getBusinessAreaSystemConnectionsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area system connections at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingConnections: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingConnections || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingConnection;
        }
        return isBusy;
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {
                        !this.isBusy()
                            ?
                            (
                                this.props.businessAreaSystemConnectionListResult.Items && this.props.businessAreaSystemConnectionListResult.Items.length > 0
                                    ?
                                    <BusinessAreaSelectSystemConnectionList
                                        businessAreaSystemConnections={this.props.businessAreaSystemConnectionListResult.Items}
                                        onConnectionSelected={this.props.onConnectionSelected}>
                                    </BusinessAreaSelectSystemConnectionList>
                                    :
                                    <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                                        <Col span={24} style={{ display: "flex", flexDirection: "row", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No system connections found in the business area" />
                                        </Col>
                                    </Row>
                            )
                            :
                            <></>
                    }

                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreaSystemConnectionListResult: state.businessAreas.businessAreaSystemConnectionListResult,
        getBusinessAreaSystemConnectionsResult: state.businessAreas.getBusinessAreaSystemConnectionsResult,
        permission: { canAdd: true, canEdit: true, canView: true, canDelete: true }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessAreaSystemConnections: (businessAreaId) => dispatch(actions.getBusinessAreaSystemConnectionsRequest(businessAreaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreaSystemConnections));