import React from 'react';
import { useParams } from "react-router-dom";
import { UserProfile } from 'components';

const UserProfilePage = (props) => {
    const { tenant } = useParams();
    return (
        <div className="flex-column-container">
            <div>
                <UserProfile prefix="User Profile"></UserProfile>
            </div>
        </div>
    )
}

export default UserProfilePage
