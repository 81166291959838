import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, Modal } from 'antd';
import { DataObjectDuplicateMergeRuleList, FullHeightContainerLayout } from 'components';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import fieldDataTypes from 'common/data/fieldDataTypes';
import moment from 'moment';
import { unflatten } from 'flat';
import flatten from 'flat';

class DuplicateDataObjectMergeRulesBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mergeRulesForm: {
                rules: []
            }
        }
        this.formRef = React.createRef();
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.rules && this.props.rules.length > 0) {
            this.setState({
                mergeRulesForm: {
                    rules: this.props.rules
                }
            });
            this.formRef.current.setFieldsValue({ rules: this.props.rules });
        }
        else {
            this.createRuleForm(this.props.schemaModel && this.props.schemaModel.fields);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.schemaModel !== prevProps.schemaModel) {
            this.createRuleForm(this.props.schemaModel.fields);
        }
    }

    createRuleForm = (fields) => {
        let rules = []
        if (fields) {
            for (let field of fields) {
                rules.push({
                    fieldId: field.fieldId,
                    fieldName: field.name,
                    fieldDataType: field.dataType,
                    rule: "SURVIVOR_VALUE_WIN"
                })
            }
        }
        this.setState({
            mergeRulesForm: {
                rules
            }
        });
        this.formRef.current.setFieldsValue({ rules });
    }

    onFormSubmit = (mergeRulesForm) => {
        this.props.onSaveRules(mergeRulesForm.rules);
    }

    onSelectNominatedValueClick = (formField, nominatedValue, fieldName) => {
        this.props.onSelectNominatedValueClick(formField, fieldName, nominatedValue, this.formRef);
    }

    render() {
        return (
            <>
                <Form
                    ref={this.formRef}
                    name="basic"
                    onFinish={this.onFormSubmit}
                    initialValues={this.state.mergeRulesForm}
                    style={{ height: "100%" }}>
                    <FullHeightContainerLayout
                        showHeader={false}
                        showFooter={true}
                        content={<Form.List name="rules">
                            {(fields, { add, remove }) => {
                                return <DataObjectDuplicateMergeRuleList
                                    formRef={this.formRef}
                                    rules={fields}
                                    onSelectNominatedValueClick={this.onSelectNominatedValueClick} />;
                            }}
                        </Form.List>}
                        footer={<Row>
                            <Col span={24} className="footer-right-column">
                                <Space>
                                    <Button onClick={this.props.onClose}>Cancel</Button>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                        }>
                    </FullHeightContainerLayout>
                </Form>
            </>
        );
    }
}

export default DuplicateDataObjectMergeRulesBuilder