import {
    GET_JOBS_SUCCESS,
    GET_JOBS_FAILURE,

    GET_JOB_BY_ID_SUCCESS,
    GET_JOB_BY_ID_FAILURE,

    RESTART_JOB_SUCCESS,
    RESTART_JOB_FAILURE,

    CANCEL_JOB_SUCCESS,
    CANCEL_JOB_FAILURE,

    GET_JOBS_DOMAIN_DATA_REQUEST,
    GET_JOBS_DOMAIN_DATA_SUCCESS,
    GET_JOBS_DOMAIN_DATA_FAILURE,

    GET_JOBS_DATA_OBJECTS_REQUEST,
    GET_JOBS_DATA_OBJECTS_SUCCESS,
    GET_JOBS_DATA_OBJECTS_FAILURE,

    GET_RECENT_DATAOBJECT_JOBS_SUCCESS,
    GET_RECENT_DATAOBJECT_JOBS_FAILURE,

    JOB_EXECUTION_PROGRESS,
    JOB_DELETED
} from "./actions";

import {
    GET_USER_BUSINESS_AREAS_SUCCESS
} from '../home/actions';

const initialState = {
    getJobsResult: {
        success: true
    },
    jobsResult: {
        Items: []
    },
    getJobByIdResult: {
        success: true
    },
    job: null,
    getJobsDomainDataResult: {
        success: true
    },
    getJobsDataObjectsResult: {
        success: true
    },
    domainDataResult: {
        inProgress: false,
        Items: []
    },
    dataObjectsResult: {
        inProgress: false,
        Items: []
    },
    restartJobResult: {
        success: true
    },
    cancelJobResult: {
        success: true
    },
    getRecentDataObjectJobsResult: {
        success: true
    },
    recentDataObjectJobsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    let jobsResult = null;
    let jobItems = null;
    let restartedJobIndex = -1;
    let domainDataResult = {
        inProgress: false,
        Items: []
    };
    let dataObjectsResult = {
        inProgress: false,
        Items: []
    };
    let recentDataObjectJobsResult = {
        Items: []
    }
    switch (action.type) {
        case GET_JOBS_SUCCESS:
            return { ...state, getJobsResult: { success: true }, jobsResult: action.payload };
        case GET_JOBS_FAILURE:
            return {
                ...state, getJobsResult: { success: false, ...action.payload }, jobsResult: {
                    Items: []
                }
            };
        case GET_JOB_BY_ID_SUCCESS:
            return { ...state, getJobByIdResult: { success: true, jobId: action.payload.job.jobId }, job: action.payload.job };
        case GET_JOB_BY_ID_FAILURE:
            return { ...state, getJobByIdResult: { success: false, ...action.payload.error, jobId: action.payload.jobId }, job: null };
        case RESTART_JOB_SUCCESS:
            jobsResult = { ...state.jobsResult };
            jobItems = [...jobsResult.Items];
            jobsResult.Items = jobItems;
            restartedJobIndex = jobsResult.Items.findIndex(job => job.jobId === action.payload.updatedJob.jobId);
            if (restartedJobIndex > -1) {
                jobsResult.Items[restartedJobIndex] = action.payload.updatedJob;
            }
            return { ...state, restartJobResult: { success: true }, jobsResult };
        case RESTART_JOB_FAILURE:
            return {
                ...state, restartJobResult: { success: false, ...action.payload }
            };
        case CANCEL_JOB_SUCCESS:
            jobsResult = { ...state.jobsResult };
            jobItems = [...jobsResult.Items];
            jobsResult.Items = jobItems;
            restartedJobIndex = jobsResult.Items.findIndex(job => job.jobId === action.payload.updatedJob.jobId);
            if (restartedJobIndex > -1) {
                jobsResult.Items[restartedJobIndex] = action.payload.updatedJob;
            }
            return { ...state, cancelJobResult: { success: true }, jobsResult };
        case CANCEL_JOB_FAILURE:
            return {
                ...state, cancelJobResult: { success: false, ...action.payload }
            };
        case GET_JOBS_DOMAIN_DATA_REQUEST:
            return { ...state, domainDataResult: !action.params.pageKey ? { Items: [], inProgress: true } : { ...state.domainDataResult, inProgress: true } };
        case GET_JOBS_DOMAIN_DATA_SUCCESS:
            domainDataResult = action.payload;
            let sNo = state.domainDataResult.Items.length;
            for (let item of domainDataResult.Items) {
                item.sNo = sNo + 1;
                sNo = item.sNo;
            }
            domainDataResult.Items = state.domainDataResult.Items.concat(domainDataResult.Items)
            domainDataResult.inProgress = false;
            return { ...state, getJobsDomainDataResult: { success: true }, domainDataResult };
        case GET_JOBS_DOMAIN_DATA_FAILURE:
            return {
                ...state, getJobsDomainDataResult: { success: false, ...action.payload }, domainDataResult: {
                    Items: [],
                    inProgress: false
                }
            };
        case GET_JOBS_DATA_OBJECTS_REQUEST:
            return { ...state, dataObjectsResult: !action.params.pageKey ? { Items: [], inProgress: true } : { ...state.dataObjectsResult, inProgress: true } };
        case GET_JOBS_DATA_OBJECTS_SUCCESS:
            dataObjectsResult = action.payload;
            let dataObjectSno = state.dataObjectsResult.Items.length;
            for (let item of dataObjectsResult.Items) {
                item.sNo = dataObjectSno + 1;
                dataObjectSno = item.sNo;
            }
            dataObjectsResult.Items = state.dataObjectsResult.Items.concat(dataObjectsResult.Items)
            dataObjectsResult.inProgress = false;
            return { ...state, getJobsDataObjectsResult: { success: true }, dataObjectsResult };
        case GET_JOBS_DATA_OBJECTS_FAILURE:
            return {
                ...state, getJobsDataObjectsResult: { success: false, ...action.payload }, dataObjectsResult: {
                    Items: [],
                    inProgress: false
                }
            };
        case JOB_EXECUTION_PROGRESS:
            jobsResult = { ...state.jobsResult };
            jobItems = [...jobsResult.Items];
            jobsResult.Items = jobItems;
            let updatedJobIndex = jobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (updatedJobIndex > -1) {
                jobsResult.Items[updatedJobIndex] = { ...jobsResult.Items[updatedJobIndex], ...action.payload.message };
            }

            recentDataObjectJobsResult = { ...state.recentDataObjectJobsResult };
            recentDataObjectJobsResult.Items = [...recentDataObjectJobsResult.Items];
            updatedJobIndex = recentDataObjectJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (updatedJobIndex > -1) {
                recentDataObjectJobsResult.Items[updatedJobIndex] = { ...recentDataObjectJobsResult.Items[updatedJobIndex], ...action.payload.message };
            }
            return { ...state, jobsResult, recentDataObjectJobsResult };
        case JOB_DELETED:
            jobsResult = { ...state.jobsResult };
            jobItems = [...jobsResult.Items];
            jobsResult.Items = jobItems;
            let deletedJobIndex = jobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (deletedJobIndex > -1) {
                jobsResult.Items.splice(deletedJobIndex, 1);
            }

            recentDataObjectJobsResult = { ...state.recentDataObjectJobsResult };
            recentDataObjectJobsResult.Items = [...recentDataObjectJobsResult.Items];
            deletedJobIndex = recentDataObjectJobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (deletedJobIndex > -1) {
                recentDataObjectJobsResult.Items.splice(deletedJobIndex, 1);
            }
            return { ...state, jobsResult, recentDataObjectJobsResult };
        case GET_RECENT_DATAOBJECT_JOBS_SUCCESS:
            return { ...state, getRecentDataObjectJobsResult: { success: true }, recentDataObjectJobsResult: action.payload.jobsResult };
        case GET_RECENT_DATAOBJECT_JOBS_FAILURE:
            return { ...state, getRecentDataObjectJobsResult: { success: false, ...action.payload.error }, recentDataObjectJobsResult: { Items: [] } };
        case GET_USER_BUSINESS_AREAS_SUCCESS:
            let businessAreasDict = action.payload.businessAreasDict;
            recentDataObjectJobsResult = state.recentDataObjectJobsResult;
            if (recentDataObjectJobsResult.Items.length > 0) {
                recentDataObjectJobsResult = { ...state.recentDataObjectJobsResult };
                recentDataObjectJobsResult.Items = [...recentDataObjectJobsResult.Items];
                for (let i = 0; i < recentDataObjectJobsResult.Items.length; i++) {
                    let recentDataObjectJob = recentDataObjectJobsResult.Items[i];
                    if (businessAreasDict && businessAreasDict[recentDataObjectJob.businessAreaId]) {
                        recentDataObjectJobsResult.Items[i] = { ...recentDataObjectJob, businessAreaName: businessAreasDict[recentDataObjectJob.businessAreaId].name };
                    }
                }
            }
            return { ...state, recentDataObjectJobsResult };
        default:
            return state;
    }
};
