import React from 'react';
import { Space, Tooltip, Tag } from "antd";


export default ({ name, tags, orientation = "verticle", tagSummary = true }) => {
    return <>
        {
            tags && tags.length > 0
                ?
                (orientation === "verticle"
                    ?
                    <Space direction="vertical">
                        {tagSummary
                            ?
                            <Space className='tags-summary'>
                                <Tag>{tags[0].tagName}</Tag>
                                {
                                    tags.length > 1
                                        ?
                                        <Tooltip title={tags.slice(1).map(tag => tag.tagName).join(", ")}>
                                            <Tag>...</Tag>
                                        </Tooltip>
                                        :
                                        <></>
                                }
                            </Space>
                            :
                            tags.map((tag, index) => <Tag key={tag.tagId}>{tag.tagName}</Tag>)
                        }
                        <p>{name}</p>
                    </Space>
                    :
                    <Space>
                        <p>{name}</p>
                        {tagSummary
                            ?
                            <Space>
                                <Tag>{tags[0].tagName}</Tag>
                                {
                                    tags.length > 1
                                        ?
                                        <Tooltip title={tags.slice(1).map(tag => tag.tagName).join(", ")}>
                                            <Tag>...</Tag>
                                        </Tooltip>
                                        :
                                        <></>
                                }
                            </Space>
                            :
                            tags.map((tag, index) => <Tag key={tag.tagId}>{tag.tagName}</Tag>)
                        }
                    </Space>)
                :
                <Space>
                    <p>{name}</p>
                </Space>
        }
    </>
}