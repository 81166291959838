import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Empty, Spin } from 'antd';
import { DataSetInsightsList, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';

const { Title } = Typography;

class DataSetInsightsHomeOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingDataSetInsights: false,
            busy: false,
            busyMessage: "Please wait...",
        }
    }

    componentWillMount() {
        if (this.props.dataSetId) {
            this.setState({
                fetchingDataSetInsights: true
            });
            this.props.getDataSetFieldInsights(this.props.dataSetId);
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onDataSetIdChanged(prevProps);
        this.onGetDataSetFieldInsightsResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    onDataSetIdChanged(prevProps) {
        if (prevProps.dataSetId !== this.props.dataSetId) {
            this.setState({
                fetchingDataSetInsights: true
            });
            this.props.getDataSetFieldInsights(this.props.dataSetId);
        }
        else if (this.props.dataSetId && prevProps.refreshFlag !== this.props.refreshFlag) {
            this.setState({
                refreshingDataSetInsights: true
            });
            this.props.getDataSetFieldInsights(this.props.dataSetId);
        }
    }

    onGetDataSetFieldInsightsResultChanged = (prevProps) => {
        if (this.props.getDataSetFieldInsightsResult && this.props.getDataSetFieldInsightsResult !== prevProps.getDataSetFieldInsightsResult) {
            if (!this.props.getDataSetFieldInsightsResult.success) {
                if (this.props.getDataSetFieldInsightsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get datasets insights at this moment.");
                }
            }
            this.setState({
                fetchingDataSetInsights: false,
                refreshingDataSetInsights: false
            });
        }
    }

    isBusy = () => {
        return this.state.isBusy || this.state.fetchingDataSetInsights;
    }

    getContent = () => {
        if (!this.props.dataSetId) {
            return <Empty description="Please select a dataset"></Empty>
        }

        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }

        return <DataSetInsightsList
            dataSetInsightList={this.props.dataSetFieldInsightsResult.Items}>
        </DataSetInsightsList>
    }

    render() {

        return <FullHeightContainerLayout
            showHeader={true}
            header={<Title level={4}>Dataset Insights{this.state.refreshingDataSetInsights ? <> <Spin size="small" /></> : <></>}</Title>}
            content={
                <>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.isBusy() ?
                        this.getContent()
                        :
                        <></>
                    }
                </>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dataSetFieldInsightsResult: state.dataSets.dataSetFieldInsightsResult,
        getDataSetFieldInsightsResult: state.dataSets.getDataSetFieldInsightsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataSetFieldInsights: (dataSetId) => dispatch(actions.getDataSetFieldInsightsRequest(dataSetId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSetInsightsHomeOverview));