export const GET_USER_BUSINESS_AREAS_REQUEST = "GET_USER_BUSINESS_AREAS_REQUEST";
export const GET_USER_BUSINESS_AREAS_SUCCESS = "GET_USER_BUSINESS_AREAS_SUCCESS";
export const GET_USER_BUSINESS_AREAS_FAILURE = "GET_USER_BUSINESS_AREAS_FAILURE";

export const GET_USER_ACTIVE_BUSINESS_AREA_REQUEST = "GET_USER_ACTIVE_BUSINESS_AREA_REQUEST";
export const GET_USER_ACTIVE_BUSINESS_AREA_SUCCESS = "GET_USER_ACTIVE_BUSINESS_AREA_SUCCESS";
export const GET_USER_ACTIVE_BUSINESS_AREA_FAILURE = "GET_USER_ACTIVE_BUSINESS_AREA_FAILURE";

export const SAVE_USER_ACTIVE_BUSINESS_AREA_REQUEST = "SAVE_USER_ACTIVE_BUSINESS_AREA_REQUEST";
export const SAVE_USER_ACTIVE_BUSINESS_AREA_SUCCESS = "SAVE_USER_ACTIVE_BUSINESS_AREA_SUCCESS";
export const SAVE_USER_ACTIVE_BUSINESS_AREA_FAILURE = "SAVE_USER_ACTIVE_BUSINESS_AREA_FAILURE";

export const SET_ACTIVE_BUSINESS_AREA_PATH_REQUEST = "SET_ACTIVE_BUSINESS_AREA_PATH_REQUEST";

export const getUserBusinessAreasRequest = () => ({
    type: GET_USER_BUSINESS_AREAS_REQUEST
})

export const getUserBusinessAreasSuccess = (businessAreas, businessAreasDict) => ({
    type: GET_USER_BUSINESS_AREAS_SUCCESS,
    payload: {
        businessAreas,
        businessAreasDict
    }
})

export const getUserBusinessAreasFailure = (error) => ({
    type: GET_USER_BUSINESS_AREAS_FAILURE,
    payload: error
})

export const getUserActiveBusinessAreaRequest = () => ({
    type: GET_USER_ACTIVE_BUSINESS_AREA_REQUEST
})

export const getUserActiveBusinessAreaSuccess = (businessAreaId) => ({
    type: GET_USER_ACTIVE_BUSINESS_AREA_SUCCESS,
    payload: businessAreaId
})

export const getUserActiveBusinessAreaFailure = (error) => ({
    type: GET_USER_ACTIVE_BUSINESS_AREA_FAILURE,
    payload: error
})

export const saveUserActiveBusinessAreaRequest = (businessAreaId) => ({
    type: SAVE_USER_ACTIVE_BUSINESS_AREA_REQUEST,
    params: {
        businessAreaId
    }
})

export const saveUserActiveBusinessAreaSuccess = () => ({
    type: SAVE_USER_ACTIVE_BUSINESS_AREA_SUCCESS
})

export const saveUserActiveBusinessAreaFailure = (error) => ({
    type: SAVE_USER_ACTIVE_BUSINESS_AREA_FAILURE,
    payload: error
})

export const setActiveBusinessAreaPathRequest = (path) => ({
    type: SET_ACTIVE_BUSINESS_AREA_PATH_REQUEST,
    payload: path
})