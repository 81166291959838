import React, { useState, useEffect } from 'react';
import { Form, Radio } from "antd";
import { SchemaFieldTitle } from 'components';

const BooleanInput = ({ value, onChange, trueValue, falseValue }) => {
    const [booleanValue, setBooleanValue] = useState(value || trueValue);

    useEffect(() => {
        setBooleanValue(value);
    }, [value]);

    const onBooleanValueChange = (e) => {
        setBooleanValue(e.target.value);
        onChange(e.target.value);
    }

    return <Radio.Group onChange={onBooleanValueChange} value={booleanValue}>
        <Radio value={trueValue}>{trueValue}</Radio>
        <Radio value={falseValue}>{falseValue}</Radio>
    </Radio.Group>
}

function FormBooleanField({ fieldErrors, fieldId, name, description, max, min, isRequired, allowedBooleanValues, ...field }) {
    const booleanValues = (allowedBooleanValues && allowedBooleanValues.split('/')) || ['y', 'n'];

    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={<SchemaFieldTitle name={name} tags={field.classifiers} orientation="horizontal" tagSummary={false}></SchemaFieldTitle>}
        tooltip={description}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value !== null && value !== undefined && value.toString().trim()) {
                        if (booleanValues.includes(value) === false) {
                            return Promise.reject(`Value should be a valid boolean value ${allowedBooleanValues}`);
                        }
                    }

                    return Promise.resolve();
                },
            }
        ]}
        {...fieldErrors}>
        <BooleanInput trueValue={booleanValues[0] || 'y'} falseValue={booleanValues[1] || 'n'} />
    </Form.Item>
}

export default FormBooleanField