export default Object.freeze(
    {
        "-": {
            name: "-"
        },
        "/": {
            name: "/"
        },
        ".": {
            name: "."
        },
        " ": {
            name: " "
        }
    });