
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventBusinessAreaConnections(api, eventId, itemOperation, pageKey) {
    try {
        let params = {
            operation: itemOperation
        };
        if (pageKey) {
            params.pageKey = pageKey
        }

        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/businessareasystemconnections`, {}, { params });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventBusinessAreaConnectionsRequest(api, { eventId, itemOperation, pageKey }) {
    try {
        const connectionsResult = yield call(getEventBusinessAreaConnections, api, eventId, itemOperation, pageKey);
        yield put(actions.getEventBusinessAreaConnectionsSuccess(connectionsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event business area system connections");
        yield put(actions.getEventBusinessAreaConnectionsFailure(errorObject));
    }
}

export function* watchGetEventBusinessAreaConnectionsRequest(api, { params }) {
    yield call(getEventBusinessAreaConnectionsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_BUSINESS_AREA_CONNECTIONS_REQUEST, watchGetEventBusinessAreaConnectionsRequest, api);
}