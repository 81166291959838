import react from 'react';
import { Typography, Tooltip } from 'antd';

const { Link } = Typography;

function DataObjectLink({ dataObjectId }) {
    return <Link style={{
        "textOverflow": "ellipsis",
        "display": "block",
        "whiteSpace": "nowrap",
        "overflow": "hidden",
        "textDecoration": "underline"
    }} href={`${window.location.origin}/${dataObjectId}`} target="_blank">
        <Tooltip placement="topLeft" title={dataObjectId}>{dataObjectId}</Tooltip>
    </Link>
}

export default DataObjectLink