import React, { useState } from 'react';
import { Form, Input, Row, Col, Button, Space, InputNumber, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { FullHeightContainerLayout, FormattedDateInput } from "components";
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import validator from 'validator';
import { nameRules } from 'common/FormValidationRules';

const AdminTenantForm = ({ tenant, onUpdateTenant, ...props }) => {
    const [form] = Form.useForm();

    const onFormSubmit = (tenantValues) => {
        let updateKeys = ["primaryContactName", "primaryContactEmail"];
        let updatedTenantValues = {};
        for (let key of updateKeys) {
            if (tenantValues[key] && tenantValues[key] !== tenant[key]) {
                updatedTenantValues[key] = tenantValues[key];
            }
        }

        if (_.isEmpty(updatedTenantValues) === false) {
            onUpdateTenant(tenant.tenantId, updatedTenantValues);
        }
    }

    return (
        <Form
            form={form}
            initialValues={tenant}
            onFinish={onFormSubmit}
            layout="horizontal"
            name="basic"
            style={{ height: "100%" }}>
            <FullHeightContainerLayout
                showFooter={true}
                contentStyle={{ overflowX: "hidden" }}
                content={
                    <Row>
                        <Col span={24}>
                            <Row gutter={12}>
                                <Col span={8}>
                                    <Form.Item
                                        labelCol={{ span: 4 }}
                                        labelAlign="right"
                                        className="wrap-label"
                                        label="Email Domain"
                                        name={["domain"]}>
                                        <Input readOnly={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        labelAlign="right"
                                        labelCol={{ span: 4 }}
                                        className="wrap-label"
                                        label="Company"
                                        name={["company"]}>
                                        <Input readOnly={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        labelAlign="right"
                                        labelCol={{ span: 4 }}
                                        className="wrap-label"
                                        label="Subscription"
                                        name={["subscriptionType"]}
                                        rules={[{ required: true, message: `Subscription is required` }]}
                                        hasFeedback>
                                        <Input placeholder="Subscription type" readOnly={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12} wrap={false}>
                                <Col span={8}>
                                    <Form.Item
                                        labelAlign="right"
                                        labelCol={{ span: 4 }}
                                        className="wrap-label"
                                        label="Start Date"
                                        name={["createdDate"]}>
                                        <FormattedDateInput readOnly={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        labelAlign="right"
                                        labelCol={{ span: 6 }}
                                        className="wrap-label"
                                        label="Renewal Date"
                                        name={["renewalDate"]}>
                                        <Input readOnly={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Row gutter={12} wrap={false}>
                                        <Col span={12}>
                                            <Form.Item
                                                labelAlign="right"
                                                labelCol={{ span: 10 }}
                                                className="wrap-label"
                                                label="Primary Contact"
                                                name={["primaryContactName"]}
                                                validateFirst={true}
                                                rules={nameRules}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                labelAlign="right"
                                                labelCol={{ span: 10 }}
                                                className="wrap-label"
                                                label="Primary Contact Email"
                                                name={["primaryContactEmail"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Value is required`,
                                                    },
                                                    {
                                                        validator(_, value) {
                                                            if (value && value.toString().trim()) {
                                                                if (validator.isEmail(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                            return Promise.reject(`Value should be a valid email`);
                                                        }
                                                    }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                footer={
                    < Row >
                        <Col span={24} className="footer-right-column">
                            <Space>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Space>
                        </Col>
                    </Row >
                }>
            </FullHeightContainerLayout >
        </Form >
    )
}

export default AdminTenantForm
