
import { put, call, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import _ from 'lodash';
import errorHandler from 'common/errorHandler';

export function* sendUserForgotPasswordRequest(api, { email }) {
    try {
        let result = yield call([Auth, Auth.forgotPassword], email);
        yield put(actions.sendUserForgotPasswordSuccess(result))
    } catch (error) {
        let errorObject = errorHandler(error, "Signup", "new user");
        if (error.code === "UsernameExistsException") {
            if (!errorObject.fields) {
                errorObject.fields = {};
            }
            errorObject.fields["email"] = "Email already exist.";
        }
        else if (error.code === "InvalidPasswordException") {
            if (!errorObject.fields) {
                errorObject.fields = {};
            }
            errorObject.fields["password"] = error.message;
        }
        yield put(actions.sendUserForgotPasswordFailure(errorObject));
    }
}


export function* watchSendUserForgotPasswordRequest(api, { params }) {
    yield call(sendUserForgotPasswordRequest, api, params)
}

export default function* ({ api }) {
    yield takeLatest(actions.SEND_USER_FORGOT_PASSWORD_REQUEST, watchSendUserForgotPasswordRequest, api);
}
