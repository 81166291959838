import {
    GET_EVENT_DATA_OBJECT_LIST_REQUEST,
    GET_EVENT_DATA_OBJECT_LIST_SUCCESS,
    GET_EVENT_DATA_OBJECT_LIST_FAILURE,

    GET_EVENT_DATA_OBJECT_SUCCESS,
    GET_EVENT_DATA_OBJECT_FAILURE
} from "./actions";

const initialState = {
    getEventDataObjectListResult: {
        success: true
    },
    dataObjectResult: {
        Items: [],
        inProgress: true
    },
    getEventDataObjectResult: {
        success: true
    },
    dataObjectRevision: null
};

export default (state = initialState, action) => {
    let dataObjectResult = {
        Items: [],
        inProgress: true
    };
    switch (action.type) {
        case GET_EVENT_DATA_OBJECT_LIST_REQUEST:
            return { ...state, dataObjectResult: !action.params.pageKey ? dataObjectResult : { ...state.dataObjectResult, inProgress: true } };
        case GET_EVENT_DATA_OBJECT_LIST_SUCCESS:
            dataObjectResult = action.payload;
            let sNo = state.dataObjectResult.Items.length;
            for (let item of dataObjectResult.Items) {
                item.data.sNo = sNo + 1;
                sNo = item.data.sNo;
            }
            dataObjectResult.Items = state.dataObjectResult.Items.concat(dataObjectResult.Items)
            dataObjectResult.inProgress = false;
            return { ...state, getEventDataObjectListResult: { success: true }, dataObjectResult };
        case GET_EVENT_DATA_OBJECT_LIST_FAILURE:
            return {
                ...state,
                getEventDataObjectListResult: { success: false, ...action.payload },
                dataObjectResult: {
                    Items: [],
                    inProgress: false
                }
            };
        case GET_EVENT_DATA_OBJECT_SUCCESS:
            return { ...state, getEventDataObjectResult: { success: true }, dataObjectRevision: action.payload };
        case GET_EVENT_DATA_OBJECT_FAILURE:
            return {
                ...state, getEventDataObjectResult: { success: false, ...action.payload }, dataObjectRevision: null
            };
        default:
            return state;
    }
};
