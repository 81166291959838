import React, { Component } from 'react';
import { Typography, Row, Col, Button, Upload, Radio, Space, List, Card, Select, Input } from 'antd';
import { PlusOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { FullHeightContainerLayout, FileDragger, ErrorList, DataMapper, SystemConnectionSelector, DataPreview } from 'components';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import _ from 'lodash';
import moment from 'moment';
import { unflatten } from 'flat';
import flatten from 'flat';
import Papaparse from 'papaparse/papaparse';
import fieldDataTypes from 'common/data/fieldDataTypes';

const { Dragger } = Upload;
const { Text } = Typography;

class DataObjectBulkUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileReadResult: {
                file: null,
                data: null,
                columns: null,
                error: null
            },
            dataUploadOptions: "REPLACE_DATA",
            dataColumnMapping: {}
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        let listGridRow = document.querySelector('.schema-data-columns .ant-row:nth-of-type(1)');
        if (listGridRow) {
            listGridRow.style.width = `${this.props.schemaModel.fields.length * 320}px`;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    readingFile = (fileReadResult) => {
        this.setState({
            fileReadResult,
            readingFile: true
        });
    }

    readFileComplete = (fileReadResult) => {
        const dataColumnMapping = { ...this.state.dataColumnMapping };
        if (fileReadResult.columns && fileReadResult.columns.length > 0) {
            const columnsDict = fileReadResult.columns.reduce((dict, column) => {
                dict[column] = column;
                return dict;
            }, {});
            for (let field of this.props.schemaModel.fields) {
                if (columnsDict[field.name]) {
                    dataColumnMapping[field.name] = {
                        mappingType: "DATA_COLUMN",
                        value: columnsDict[field.name],
                        fieldId: field.fieldId,
                        name: field.name,
                        dataType: field.dataType
                    };
                }
                else {
                    dataColumnMapping[field.name] = {
                        mappingType: "DATA_COLUMN",
                        value: "",
                        fieldId: field.fieldId,
                        name: field.name,
                        dataType: field.dataType
                    };
                }
            }
        }
        this.setState({
            fileReadResult,
            readingFile: false,
            dataColumnMapping
        });
    }

    readFileError = (fileReadResult) => {
        this.setState({
            fileReadResult,
            readingFile: false
        });
    }

    cancelUpload = (fileReadResult) => {
        this.setState({
            fileReadResult,
            validationErrors: [],
            dataColumnMapping: {}
        });
    }

    isUploadAllowed = () => {
        let uploadAllowed = this.state.fileReadResult.file && !this.state.fileReadResult.error && !this.state.readingFile;
        return uploadAllowed;
    }

    validateAndUpload = () => {
        let isValid = this.validateData();
        if (isValid) {
            let fieldMappings = [];
            for (let field of this.props.schemaModel.fields) {
                if (this.state.dataColumnMapping[field.name]) {
                    fieldMappings.push({
                        ...this.state.dataColumnMapping[field.name],
                        fieldId: field.fieldId
                    });
                }
            }
            this.props.onCreateDataObjectsBulkUploadJob(this.state.dataUploadOptions, this.state.fileReadResult.file, fieldMappings);
        }
    }

    validateData = () => {
        let validationErrors = [];
        let validUploadColumnCount = 0;
        for (let field of this.props.schemaModel.fields) {
            if (field.isPrimary && (!this.state.dataColumnMapping[field.name] || !this.state.dataColumnMapping[field.name].value)) {
                validationErrors.push(`No data column for primary key field '${field.name}'`);
            }
            else if (field.isRequired && (!this.state.dataColumnMapping[field.name] || !this.state.dataColumnMapping[field.name].value)) {
                validationErrors.push(`No data column for required field '${field.name}'`);
            }
            else if (this.state.dataColumnMapping[field.name] && this.state.dataColumnMapping[field.name].mappingType === "FORMULA" && !this.state.dataColumnMapping[field.name].value) {
                validationErrors.push(`Formula expression is empty for field '${field.name}'`);
            }
            else if (this.state.dataColumnMapping[field.name] && this.state.dataColumnMapping[field.name].value) {
                validUploadColumnCount++;
            }
        }

        if (validUploadColumnCount === 0 && validationErrors.length === 0) {
            validationErrors.push("There are no data columns to upload for schema fields.");
        }

        this.setState({
            validationErrors
        });
        return validationErrors.length === 0;
    }

    onValidationErrorsClose = () => {
        this.setState({
            validationErrors: []
        })
    }

    onMappingChanged = (mappedFields) => {
        const dataColumnMapping = { ...this.state.dataColumnMapping };
        for (let mappedField of mappedFields) {
            dataColumnMapping[mappedField.name] = {
                ...dataColumnMapping[mappedField.name],
                mappingType: mappedField.mappingType,
                value: mappedField.value,
                variables: mappedField.variables || []
            }
        }
        this.setState({
            dataColumnMapping
        })
    }

    onConnectionSelectionComplete = (connection) => {
        if (connection && connection.fileReadResult) {
            let fileReadResult = { ...this.state.fileReadResult };
            fileReadResult.file = connection.file;
            fileReadResult.file.connectionId = connection.connectionId;
            fileReadResult.file.connectionType = connection.connectionType;
            fileReadResult.file.bucket = connection.bucket;
            fileReadResult.data = connection.fileReadResult.data;
            //Remove last empty row.
            if (fileReadResult.data.length > 0) {
                let lastRow = fileReadResult.data[fileReadResult.data.length - 1];
                let lastRowValues = Object.values(lastRow);
                if (!lastRowValues.some(value => !value === false)) {
                    fileReadResult.data.splice(fileReadResult.data.length - 1, 1);
                }
            }
            fileReadResult.columns = connection.fileReadResult.meta.fields.filter(field => field);
            fileReadResult.error = null;
            this.setState({
                fileReadResult
            });
            this.readFileComplete(fileReadResult);
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={true}
                showFooter={false}
                header={
                    <Row style={{ lineHeight: "normal", padding: "1rem 0" }}>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Row>
                                <Col>
                                    <Text>Schema Name</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col>
                                    <Text strong>{this.props.schemaModel.name}</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Row>
                                <Col>
                                    <Text>Schema Description</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col>
                                    <Text strong>{this.props.schemaModel.description}</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                content={
                    <FullHeightContainerLayout
                        showHeader={false}
                        showFooter={false}
                        content={
                            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                    {
                                        Object.values(this.state.dataColumnMapping).length > 0
                                            ?
                                            <FullHeightContainerLayout
                                                showHeader={false}
                                                showFooter={false}
                                                content={
                                                    <Card size="small" title="Data Upload Mapper">
                                                        <FullHeightContainerLayout
                                                            showHeader={false}
                                                            showFooter={false}
                                                            content={
                                                                <DataMapper
                                                                    destinationFields={[...Object.values(this.state.dataColumnMapping)]}
                                                                    sourceFields={this.state.fileReadResult.columns}
                                                                    sourceData={this.state.fileReadResult.data}
                                                                    onMappingChanged={this.onMappingChanged}
                                                                ></DataMapper>
                                                            }>
                                                        </FullHeightContainerLayout>
                                                        {/* <List
                                    dataSource={this.props.schemaModel.fields}
                                    grid={{ column: this.props.schemaModel.fields.length }}
                                    className="schema-data-columns"
                                    renderItem={item => (
                                        <List itemLayout="vertical">
                                            <List.Item>
                                                {item.name}
                                            </List.Item>
                                            <List.Item>
                                                {fieldDataTypes[item.dataType].name}
                                            </List.Item>
                                            {
                                                this.state.fileReadResult && this.state.fileReadResult.columns && this.state.fileReadResult.columns.length > 0 ?
                                                    <List.Item>
                                                        <DataColumnMappingItem
                                                            dataColumns={this.state.fileReadResult.columns}
                                                            mappedValue={this.state.dataColumnMapping[item.name]}
                                                            onChange={(mappedValue) => this.onMappedValueChange(mappedValue, item.name)}>
                                                        </DataColumnMappingItem>
                                                    </List.Item>
                                                    :
                                                    <></>
                                            }
                                        </List>
                                    )}
                                /> */}
                                                    </Card>
                                                }>
                                            </FullHeightContainerLayout>
                                            :
                                            <></>
                                    }
                                    <FullHeightContainerLayout
                                        showHeader={true}
                                        showFooter={true}
                                        header={
                                            <Row style={{ lineHeight: "normal", padding: "1rem 0" }}>
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <Row>
                                                        <Col>
                                                            <Text>Dataset Name</Text>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "1rem" }}>
                                                        <Col>
                                                            <Text strong>{this.props.dataSet.dataSetName}</Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <Row>
                                                        <Col>
                                                            <Text>Dataset Description</Text>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "1rem" }}>
                                                        <Col>
                                                            <Text strong>{this.props.dataSet.dataSetDescription}</Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }
                                        content={
                                            <Row style={{ height: "100%" }}>
                                                <Col span={24} style={{ height: "100%" }}>
                                                    <ErrorList
                                                        key={this.state.validationErrors}
                                                        title="Validation Error"
                                                        errors={this.state.validationErrors}
                                                        onClose={this.onValidationErrorsClose}>
                                                    </ErrorList>
                                                    <Row style={{ height: "100%" }}>
                                                        <Col span={24} style={{ height: "100%" }}>
                                                            {
                                                                this.props.bulkUploadSource === "systemconnection"
                                                                    ?
                                                                    <>
                                                                        {
                                                                            this.state.fileReadResult.data ?
                                                                                <DataPreview
                                                                                    title={<><Text>{this.state.fileReadResult && this.state.fileReadResult.file && this.state.fileReadResult.file.name}</Text> - <Text type="secondary">Data Preview (100 Records)</Text></>}
                                                                                    data={this.state.fileReadResult.data}
                                                                                    columns={this.state.fileReadResult.columns}
                                                                                    dataSchemaColumns={Object.keys(this.state.dataColumnMapping).reduce((columns, key) => {
                                                                                        if (this.state.dataColumnMapping[key].mappingType === "DATA_COLUMN" && this.state.dataColumnMapping[key].value) {
                                                                                            columns.push(this.state.dataColumnMapping[key].value);
                                                                                        }
                                                                                        return columns;
                                                                                    }, [])}
                                                                                    onClose={() => this.cancelUpload({
                                                                                        file: null,
                                                                                        data: null,
                                                                                        columns: null,
                                                                                        error: null
                                                                                    })}>
                                                                                </DataPreview>
                                                                                :
                                                                                <SystemConnectionSelector
                                                                                    businessAreaId={this.props.schemaModel.businessAreaId}
                                                                                    onConnectionSelectionComplete={this.onConnectionSelectionComplete}>
                                                                                </SystemConnectionSelector>
                                                                        }
                                                                    </>

                                                                    :
                                                                    <FileDragger
                                                                        onReadingFile={this.readingFile}
                                                                        onReadFileComplete={this.readFileComplete}
                                                                        onReadFileError={this.readFileError}
                                                                        onCancelUpload={this.cancelUpload}
                                                                        destinationConnection={this.state.destinationConnection}
                                                                        previewColumns={Object.keys(this.state.dataColumnMapping).reduce((columns, key) => {
                                                                            if (this.state.dataColumnMapping[key].mappingType === "DATA_COLUMN" && this.state.dataColumnMapping[key].value) {
                                                                                columns.push(this.state.dataColumnMapping[key].value);
                                                                            }
                                                                            return columns;
                                                                        }, [])}>
                                                                    </FileDragger>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }
                                        footer={
                                            <Row className="table-footer-row">
                                                <Col span={24} className="footer-right-column">
                                                    <Radio.Group defaultValue={this.state.dataUploadOptions} value={this.state.dataUploadOptions} onChange={e => this.setState({ dataUploadOptions: e.target.value })}>
                                                        <Space>
                                                            <Radio value="REPLACE_DATA">Replace existing data in the dataset</Radio>
                                                            <Radio value="APPEND_DATA">Append this data to the existing</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Col>
                                                <Col span={24} className="footer-right-column">
                                                    <Button
                                                        type="primary"
                                                        disabled={!this.isUploadAllowed()}
                                                        onClick={this.validateAndUpload}>
                                                        Verify and Upload
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }>
                                    </FullHeightContainerLayout>
                                </Col>
                            </Row>
                        } >
                    </FullHeightContainerLayout>
                }>
            </FullHeightContainerLayout>
        );
    }
}

export default DataObjectBulkUpload