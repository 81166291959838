import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { UserBusinessAreaSchemaWrapper, DataObject } from 'containers';
import { PageLayout } from 'components';

function usePreviousAction(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const DataObjectPage = (props) => {
    const { tenant, businessarea, schema, dataset, dataObjectId, dataObjectAction } = useParams();
    const prevDataObjectAction = usePreviousAction(dataObjectAction);

    return (
        <PageLayout
            key={`${businessarea}_${schema}_${dataset}_dataobjects_page`}
            mainContent={({ setBreadcrumbNavigationItems }) => {
                return <UserBusinessAreaSchemaWrapper
                    key={`${businessarea}_${schema}_wrapper`}
                    tenant={tenant}
                    businessArea={businessarea.toLowerCase()}
                    schema={schema}>
                    {
                        childProps => (<DataObject
                            key={`${businessarea}_${schema}_${dataset}_${dataObjectAction || "default"}_dataobjects`}
                            setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}
                            tenant={tenant}
                            businessArea={businessarea.toLowerCase()}
                            schema={schema}
                            dataset={dataset}
                            action={dataObjectAction}
                            prevAction={prevDataObjectAction}
                            dataObjectId={dataObjectId}
                            {...childProps}>
                        </DataObject>)
                    }
                </UserBusinessAreaSchemaWrapper>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default DataObjectPage
