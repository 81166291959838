import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getSystemConnectionEvents(api, connectionId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/systemconnections/${connectionId}/events`);
    return result.data;
}

export function* getSystemConnectionEventsRequest(api, { connectionId }) {
    try {
        let connectionEventsResult = yield call(getSystemConnectionEvents, api, connectionId);
        yield put(actions.getSystemConnectionEventsSuccess(connectionEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "connection events");
        yield put(actions.getSystemConnectionEventsFailure(errorObject));
    }
}

export function* watchGetSystemConnectionEventsRequest(api, { params }) {
    yield call(getSystemConnectionEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_SYSTEM_CONNECTION_EVENTS_REQUEST, watchGetSystemConnectionEventsRequest, api);
}