import {
  GET_TENANT_PROVISION_STEPS_SUCCESS,
  GET_TENANT_PROVISION_STEPS_FAILURE,

  TENANT_PROVISION_PROGRESS,
  TENANT_PROVISIONED
} from './actions';

const initialState = {
  getTenantProvisionStepsResult: { success: true },
  provisionSteps: [],
  progress: 0,
  provisioned: false
};

export default (state = initialState, action) => {
  let provisionSteps = [];
  let progress = 0;
  switch (action.type) {
    case GET_TENANT_PROVISION_STEPS_SUCCESS:
      provisionSteps = action.payload.items;
      for (let step of provisionSteps) {
        progress = Math.max(progress, step.progress || 0);
      }
      return {
        ...state,
        getTenantProvisionStepsResult: { success: true },
        provisionSteps: action.payload.items,
        progress
      };

    case GET_TENANT_PROVISION_STEPS_FAILURE:
      return {
        ...state,
        getTenantProvisionStepsResult: { success: false, ...action.payload.error },
        provisionSteps: [],
        progress: 0
      };

    case TENANT_PROVISION_PROGRESS:
      progress = action.payload.progressStep.progress || 0;
      provisionSteps = [...state.provisionSteps];
      let stepIndex = provisionSteps.findIndex(step => step.resource === action.payload.progressStep.resource);
      if (stepIndex > -1) {
        provisionSteps.splice(stepIndex, 1, action.payload.progressStep);
      }
      else {
        provisionSteps.push(action.payload.progressStep);
      }
      return {
        ...state,
        provisionSteps,
        progress
      };
    case TENANT_PROVISIONED:
      return {
        ...state,
        provisioned: true
      };
    default:
      return state;
  }
};
