import React, { useState } from "react";
import { Layout, Card, Row, Col, Tabs, Menu } from "antd";
import { LockOutlined, UserOutlined, ShoppingOutlined, CreditCardOutlined, FileTextOutlined } from "@ant-design/icons";
import { UserProfileDetails } from 'containers';

const { TabPane } = Tabs;
const { Header, Content, Footer, Sider } = Layout;

const UserProfile = ({ }) => {
    const [selectedMenu, setSelectedMenu] = useState(["PROFILE"]);

    return <Layout className="layout-user-profile-main">
        <Layout>
            <Sider width={500} className="sider-user-profile-menu">
                <Row style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                    <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                        <Row style={{ width: "100%" }}>
                            <Col span={24}>
                                <Card size="small"
                                    title="SETTINGS"
                                    bordered={true}
                                    headStyle={{ border: 0 }}
                                    bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <Menu
                                        mode="inline"
                                        style={{ width: "100%", border: 0 }}
                                        selectedKeys={selectedMenu}
                                        items={[
                                            {
                                                label: <><UserOutlined /> Profile</>,
                                                key: 'PROFILE'
                                            },
                                            {
                                                label: <><LockOutlined /> Security</>,
                                                key: 'SECURITY',
                                                disabled: true
                                            }
                                        ]}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%", marginTop: "2rem" }}>
                            <Col span={24}>
                                <Card size="small"
                                    title="BILLING"
                                    bordered={true}
                                    headStyle={{ border: 0 }}
                                    bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <Menu
                                        mode="inline"
                                        style={{ width: "100%", border: 0 }}
                                        items={[
                                            {
                                                label: <><ShoppingOutlined /> Plans</>,
                                                key: 'PLANS',
                                                disabled: true
                                            },
                                            {
                                                label: <><CreditCardOutlined /> Subscription</>,
                                                key: 'SUBSCRIPTION',
                                                disabled: true
                                            },
                                            {
                                                label: <><FileTextOutlined /> Invoice</>,
                                                key: 'INVOICE',
                                                disabled: true
                                            }
                                        ]}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Sider>
            <Layout style={{ paddingLeft: "2rem" }}>
                <Content>
                    <Row style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                        <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                            <UserProfileDetails></UserProfileDetails>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    </Layout>
};

export default UserProfile;