import react from 'react';
import { Alert, Space, Button, Table, Empty, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";

function ClosableContainer({ onClose, children, style = {}, className = "" }) {
    return <Alert
        style={style}
        className={['closable-container', className].join(" ")}
        type="info"
        closable
        onClose={onClose}
        description={children}>
    </Alert>
}

export default ClosableContainer