import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

export default function getDslTermClauseValue(
    context: IDslQueryContext,
    queryClause: Record<string, string | IDslQueryTermClause>,
    fields: ISearchProperty[],
): RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> {
    const rules: RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> = [];
    let operator = "=";

    switch (context) {
        case "must":
        case "filter":
            operator = "=";
            break;
        case "must_not":
            operator = "!=";
            break;
        default:
            break;
    }

    for (const [field, clause] of Object.entries(queryClause)) {
        if (fields.some((f) => f.name === field)) {
            // Field is valid
            if (typeof clause === "string") {
                // Simple match query
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: operator,
                    valueSource: "value",
                    value: clause,
                });
            } else {
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: operator,
                    valueSource: "value",
                    value: clause.value,
                });
            }
        }
    }

    return rules;
}
