export const GET_BUSINESS_AREA_SCHEMA_TREE_REQUEST = "GET_BUSINESS_AREA_SCHEMA_TREE_REQUEST";
export const GET_BUSINESS_AREA_SCHEMA_TREE_SUCCESS = "GET_BUSINESS_AREA_SCHEMA_TREE_SUCCESS";
export const GET_BUSINESS_AREA_SCHEMA_TREE_FAILURE = "GET_BUSINESS_AREA_SCHEMA_TREE_FAILURE";

export const CREATE_SCHEMA_CLONE_REQUEST = "CREATE_SCHEMA_CLONE_REQUEST";
export const CREATE_SCHEMA_CLONE_SUCCESS = "CREATE_SCHEMA_CLONE_SUCCESS";
export const CREATE_SCHEMA_CLONE_FAILURE = "CREATE_SCHEMA_CLONE_FAILURE";

export const getBusinessAreaSchemaTreeRequest = () => ({
    type: GET_BUSINESS_AREA_SCHEMA_TREE_REQUEST
})

export const getBusinessAreaSchemaTreeSuccess = (result) => ({
    type: GET_BUSINESS_AREA_SCHEMA_TREE_SUCCESS,
    payload: {
        result
    }
})

export const getBusinessAreaSchemaTreeFailure = (error) => ({
    type: GET_BUSINESS_AREA_SCHEMA_TREE_FAILURE,
    payload: {
        error
    }
})


export const createSchemaCloneRequest = (schemaModel) => ({
    type: CREATE_SCHEMA_CLONE_REQUEST,
    params: {
        schemaModel
    }
})

export const createSchemaCloneSuccess = (result) => ({
    type: CREATE_SCHEMA_CLONE_SUCCESS,
    payload: {
        result
    }
})

export const createSchemaCloneFailure = (error) => ({
    type: CREATE_SCHEMA_CLONE_FAILURE,
    payload: {
        error
    }
})