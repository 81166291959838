
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEventRole(api, tenantId, eventId, roleId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/roles/${roleId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventRoleRequest(api, { eventId, roleId }) {
    try {
        let tenant = yield select(getTenant);
        const roleRevision = yield call(getEventRole, api, tenant.tenantId, eventId, roleId);
        if (roleRevision.data && roleRevision.data.permissions) {
            roleRevision.data.permissions = roleRevision.data.permissions.map((permissionRevision, index) => {
                permissionRevision.data.canAdd = false;
                permissionRevision.data.canView = false;
                permissionRevision.data.canEdit = false;
                permissionRevision.data.canDelete = false;
                if (permissionRevision.data.actions.includes("add")) {
                    permissionRevision.data.canAdd = true;
                }
                if (permissionRevision.data.actions.includes("view")) {
                    permissionRevision.data.canView = true;
                }
                if (permissionRevision.data.actions.includes("edit")) {
                    permissionRevision.data.canEdit = true;
                }
                if (permissionRevision.data.actions.includes("delete")) {
                    permissionRevision.data.canDelete = true;
                }
                return permissionRevision;
            });
        }
        yield put(actions.getEventRoleSuccess(roleRevision));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event role details");
        yield put(actions.getEventRoleFailure(errorObject));
    }
}

export function* watchGetEventRoleRequest(api, { params }) {
    yield call(getEventRoleRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_ROLE_REQUEST, watchGetEventRoleRequest, api);
}