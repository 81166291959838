import React from 'react';
import { Form, Input } from "antd";
import validator from 'validator';
import { SchemaFieldTitle } from 'components';

function FormUrlField({ fieldErrors, fieldId, name, description, max, min, isRequired, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={<SchemaFieldTitle name={name} tags={field.classifiers} orientation="horizontal" tagSummary={false}></SchemaFieldTitle>}
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim()) {
                        if (validator.isURL(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(`Invalid value. Value should be a valid url`);
                    }
                    return Promise.resolve();
                }
            },
            {
                type: "string",
                min: min,
                message: `Value should be minimum ${min} characters long`
            },
            {
                type: "string",
                max: max,
                message: `Value should be maximum ${max} characters long`
            }
        ]}
        {...fieldErrors}>
        <Input />
    </Form.Item>
}

export default FormUrlField