
import { put, call, all, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

function* getDataObject(api, businessAreaId, schemaId, dataSetId, dataObjectId) {
    try {
        let response = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}/datasets/${dataSetId}/dataobjects/${dataObjectId}`);
        return response.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getSchemaModel(api, businessAreaId, schemaId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas/${schemaId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getNominatedValueDataObjectsRequest(api, { nominatedRecordFieldName, dataObjectRequests }) {
    try {
        for (let i = 0; i < dataObjectRequests.length; i++) {
            let { businessAreaId, schemaId, dataSetId, dataObjectId } = dataObjectRequests[i];
            let [schemaModel, dataObject] = yield all([
                call(getSchemaModel, api, businessAreaId, schemaId),
                call(getDataObject, api, businessAreaId, schemaId, dataSetId, dataObjectId)
            ])
            if (schemaModel) {
                let selectedField = null;
                for (let field of schemaModel.fields) {
                    if (field.name === nominatedRecordFieldName) {
                        selectedField = field;
                        break;
                    }
                }
                if (selectedField) {
                    schemaModel.fields = [selectedField];
                }
                else {
                    schemaModel = null;
                }
            }
            yield put(actions.getNominatedValueDataObjectsSuccess({ loadingMore: i < dataObjectRequests.length - 1, data: { schemaModel, dataObject } }));
        }
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Nominated values");
        yield put(actions.getNominatedValueDataObjectsFailure(errorObject));
    }
}

export function* watchGetNominatedValueDataObjectsRequest(api, { params }) {
    yield call(getNominatedValueDataObjectsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_NOMINATED_VALUE_DATAOBJECTS_REQUEST, watchGetNominatedValueDataObjectsRequest, api);
}