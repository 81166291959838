
import { put, call, takeEvery, select, all, spawn, join } from 'redux-saga/effects'
import * as actions from './actions';
import * as globalActions from '../actions';
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;
const getLoggedInUser = (state) => state.userLogin.user;

function* getUserTenant(api) {
    try {
        let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/tenant`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getUser(api, userId, tenantId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.users}/api/v1/users/${userId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* userLogin(api, { email, password }) {
    try {
        let status = yield call([Auth, Auth.signIn], email, password);
        if (status.challengeName && status.challengeName === "NEW_PASSWORD_REQUIRED") {
            yield put(actions.userLoginFailure({ code: "CHANGE_TEMPORARY_PASSWORD", message: "Change temporary password", email }));
            return;
        }

        let cognitoUser = yield call([Auth, Auth.currentAuthenticatedUser]);

        let newTenant = false;

        let userTenantTask = yield spawn(getUserTenant, api);
        let currentUserTask = yield spawn(getUser, api, cognitoUser.attributes.sub, cognitoUser.attributes["custom:tenantId"]);

        let userTenant = yield join(userTenantTask);
        if (userTenant) {
            if (userTenant.state === "ACTIVE") {
                let currentUser = yield join(currentUserTask);
                if (!currentUser) {
                    yield put(actions.userLoginFailure({ code: "PERMISSION_DENIED", message: "Permission denied. Please contact your account administrator." }));
                    return;
                }
                else {
                    yield put(actions.userLoginSuccess({ cognitoUser, user: currentUser, tenant: userTenant, newTenant }));
                }
            }
            else {
                yield put(actions.userLoginSuccess({ cognitoUser, user: cognitoUser, tenant: userTenant, newTenant }));
            }
        }
        else {
            yield put(actions.userLoginFailure({ code: "PERMISSION_DENIED", message: "Permission denied. Please contact your account administrator." }));
        }

    } catch (error) {
        yield call([Auth, Auth.signOut]);
        let errorObject = errorHandler(error, "login", "user");
        yield put(actions.userLoginFailure(errorObject));
    }
}

export function* getUserAndTenant(api) {
    let tenant = null;
    let user = null;
    try {
        tenant = yield select(getTenant);
        user = yield select(getLoggedInUser);
        let cognitoUser = yield call([Auth, Auth.currentAuthenticatedUser]);
        if (cognitoUser) {
            let userTenantTask = yield spawn(getUserTenant, api);
            let currentUserTask = yield spawn(getUser, api, cognitoUser.attributes.sub, cognitoUser.attributes["custom:tenantId"]);

            let userTenant = yield join(userTenantTask);
            if (userTenant) {
                tenant = userTenant;
                if (userTenant.state === "ACTIVE") {
                    let currentUser = yield join(currentUserTask);
                    if (!currentUser) {
                        yield put(actions.getUserAndTenantFailure({ code: "failed", message: "Get user failed" }));
                        return;
                    }
                    else {
                        user = currentUser;
                        yield put(globalActions.registerNotificationRequest(userTenant.tenantId, currentUser.userId));
                        yield put(actions.getUserAndTenantSuccess({ cognitoUser, user: currentUser, tenant: userTenant }));
                    }
                }
                else {
                    yield put(globalActions.registerNotificationRequest(userTenant.tenantId, cognitoUser.attributes.sub));
                    yield put(actions.getUserAndTenantSuccess({ cognitoUser, user: cognitoUser, tenant: userTenant }));
                }
            }
            else {
                yield put(actions.getUserAndTenantFailure({ code: "failed", message: "Account not found." }));
                return;
            }
        }
        else {
            if (tenant && user) {
                yield put(globalActions.unRegisterNotificationRequest(tenant.tenantId, user.userId));
            }
            yield put(actions.getUserAndTenantFailure({ code: "failed", message: "Get user failed" }));
        }
    } catch (error) {
        if (tenant && user) {
            yield put(globalActions.unRegisterNotificationRequest(tenant.tenantId, user.userId));
        }
        let errorObject = errorHandler(error, "Get", "user and tenant");
        yield put(actions.getUserAndTenantFailure(errorObject));
    }
}

export function* userLogout() {
    try {
        let tenant = yield select(getTenant);
        let user = yield select(getLoggedInUser);
        let status = yield call([Auth, Auth.signOut]);
        yield put(globalActions.unRegisterNotificationRequest(tenant.tenantId, user.userId));
        yield put(actions.userLogoutSuccess());
    } catch (error) {
        yield put(actions.userLogoutSuccess());
    }
}

export function* changeTemporaryPassword({ email, oldPassword, newPassword }) {
    try {
        let cognitoUser = yield call([Auth, Auth.signIn], email, oldPassword);
        if (cognitoUser.challengeName && cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            let result = yield call([Auth, Auth.completeNewPassword], cognitoUser, newPassword);
            yield put(actions.changeTemporaryPasswordSuccess("success"));
        }
        else {
            yield put(actions.changeTemporaryPasswordSuccess("success"));
        }
    } catch (error) {
        let errorObject = errorHandler(error, "Change", "temporary password");
        yield put(actions.changeTemporaryPasswordFailure(errorObject));
    }
}

export function* sendEmailVerificationLink({ email }) {
    try {
        yield call([Auth, Auth.resendSignUp], email);
        yield put(actions.sendEmailVerificationLinkSuccess(true));
    } catch (error) {
        let errorObject = errorHandler(error, "Send", "email verification link.");
        yield put(actions.sendEmailVerificationLinkFailure(errorObject));
    }
}

export function* adminForceUserLogoutRequest({ message }) {
    try {
        let cognitoUser = yield call([Auth, Auth.currentAuthenticatedUser]);
        if (cognitoUser) {
            if (cognitoUser.attributes["custom:tenantId"] === message.tenantId && cognitoUser.username === message.userId) {
                yield call(userLogout);
            }
        }
    } catch (error) {
    }
}

export function* watchUserLoginRequest(api, { params }) {
    yield call(userLogin, api, params)
}

export function* watchGetUserAndTenantRequest(api, params) {
    yield call(getUserAndTenant, api);
}

export function* watchUserLogoutRequest(api, params) {
    yield call(userLogout);
}

export function* watchChangeTemporaryPasswordRequest(api, { params }) {
    yield call(changeTemporaryPassword, params);
}

export function* watchSendEmailVerificationLinkRequest(api, { params }) {
    yield call(sendEmailVerificationLink, params);
}

export function* watchAdminForceUserLogoutRequest(api, { params }) {
    yield call(adminForceUserLogoutRequest, params);
}

export default function* ({ api }) {
    yield takeEvery(actions.USER_LOGIN_REQUEST, watchUserLoginRequest, api);
    yield takeEvery(actions.USER_LOGOUT_REQUEST, watchUserLogoutRequest, api);
    yield takeEvery(actions.GET_USER_AND_TENANT_REQUEST, watchGetUserAndTenantRequest, api);
    yield takeEvery(actions.CHANGE_TEMPORARY_PASSWORD_REQUEST, watchChangeTemporaryPasswordRequest, api);
    yield takeEvery(actions.SEND_EMAIL_VERIFICATION_LINK_REQUEST, watchSendEmailVerificationLinkRequest, api);
    yield takeEvery(globalActions.ADMIN_FORCE_USER_LOGOUT, watchAdminForceUserLogoutRequest, api);
}
