const CypressTestIds = {
    // Toast Messages
    TOAST_ALERT_MESSAGE_SUCCESS: "toast-alert-message-success",
    TOAST_ALERT_MESSAGE_ERROR: "toast-alert-message-error",
    TOAST_ALERT_MESSAGE_WARNING: "toast-alert-message-warning",
    TOAST_ALERT_MESSAGE_INFO: "toast-alert-message-info",

    // HeaderMenu
    HEADER_MENU_CONFIGURATION: "header-menu-configuration",
    HEADER_MENU_BUSINESS_AREA_SELECT_MENU_BUTTON:
        "header-menu-business-area-select-menu-button",
    HEADER_MENU_BUSINESS_AREA_SELECT_MENU:
        "header-menu-business-area-select-menu",

    // Configuration > VerticleMenuContent
    // NOTE: This is generated automatically for all menus
    VERTICAL_MENU_BUSINESSAREAS: "vertical-menu-configuration-businessareas",

    // OkCancelConfirmationBox
    CONFIRMATION_BOX_OK_BUTTON: "confirmation-box-ok-button",
    CONFIRMATION_BOX_CANCEL_BUTTON: "confirmation-box-cancel-button",

    // User Login
    USER_LOGIN_EMAIL_LINK_SENT_MESSAGE: "user-login-email-link-sent-message",
    USER_LOGIN_EMAIL_INPUT: "user-login-email-input",
    USER_LOGIN_PASSWORD_INPUT: "user-login-password-input",
    USER_LOGIN_SUBMIT_BUTTON: "user-login-submit-button",
    USER_LOGIN_FORGOT_PASSWORD_LINK: "user-login-forgot-password-link",
    USER_LOGIN_RESEND_VERIFY_EMAIL_LINK: "user-login-resend-verify-email-link",

    // Reset Password
    USER_RESET_PASSWORD_BACKTOLOGIN_BUTTON:
        "user-reset-password-backtologin-button",
    USER_RESET_PASSWORD_FORM: "user-reset-password-form",
    USER_RESET_PASSWORD_EMAIL_INPUT: "user-reset-password-email-input",
    USER_RESET_PASSWORD_SUBMIT_BUTTON: "user-reset-password-submit-button",
    USER_RESET_PASSWORD_EMAIL_ERROR_MESSAGE:
        "user-reset-password-email-error-message",
    USER_RESET_PASSWORD_LINK_SENT_MESSAGE:
        "user-reset-password-link-sent-message",

    // Change Password
    USER_CHANGE_PASSWORD_INPUT: "user-change-password-input",
    USER_CHANGE_PASSWORD_CONFIRM_INPUT: "user-change-password-confirm-input",
    USER_CHANGE_PASSWORD_SUBMIT_BUTTON: "user-change-password-submit-button",
    USER_CHANGE_PASSWORD_ERROR_MESSAGE: "user-change-password-error-message",
    USER_CHANGE_PASSWORD_SUCCESS_MESSAGE:
        "user-change-password-success-message",

    // BusinessAreaList
    ADD_BUSINESS_AREA_BUTTON: "add-business-area-button",
    DELETE_BUSINESS_AREA_BUTTON: "delete-business-area-button",
    EDIT_BUSINESS_AREA_BUTTON: "edit-business-area-button",
    VIEW_BUSINESS_AREA_BUTTON: "view-business-area-button",
    HISTORY_BUSINESS_AREA_BUTTON: "history-business-area-button",
    ACTIVATE_BUSINESS_AREA_SWITCH: "activate-business-area-switch",

    // ManageBusinessArea
    MANAGE_BUSINESS_AREA_NAME_INPUT: "manage-business-area-name-input",
    MANAGE_BUSINESS_AREA_DESCRIPTION_INPUT:
        "manage-business-area-description-input",
    MANAGE_BUSINESS_AREA_SAVE_BUTTON: "manage-business-area-save-button",
    MANAGE_BUSINESS_AREA_CANCEL_BUTTON: "manage-business-area-cancel-button",

    // ManageBusinessArea tabs
    MANAGE_BUSINESS_AREA_TAB_USER: "manage-business-area-tab-user",
    MANAGE_BUSINESS_AREA_TAB_CREDENTIALS:
        "manage-business-area-tab-credentials",
    MANAGE_BUSINESS_AREA_TAB_SYSTEM_CONNECTIONS:
        "manage-business-area-tab-system-connections",

    // BusinessAreaUserList
    BUSINESS_AREA_USER_SELECT: "business-area-user-select",
    ADD_BUSINESS_AREA_USER_BUTTON: "add-business-area-user-button",
    DELETE_BUSINESS_AREA_USER_BUTTON: "delete-business-area-user-button",
    ACTIVATE_BUSINESS_AREA_USER_CHECKBOX:
        "activate-business-area-user-checkbox",
    CAN_DELETE_BUSINESS_AREA_USER_CHECKBOX:
        "can-delete-business-area-user-checkbox",
    CAN_EDIT_BUSINESS_AREA_USER_CHECKBOX:
        "can-edit-business-area-user-checkbox",
    CAN_VIEW_BUSINESS_AREA_USER_CHECKBOX:
        "can-view-business-area-user-checkbox",
    CAN_ADD_BUSINESS_AREA_USER_CHECKBOX: "can-add-business-area-user-checkbox",

    // RoleUserList
    ADD_ROLE_USER_BUTTON: "add-role-user-button",
};

export default CypressTestIds;
