
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEventDataDomain(api, tenantId, eventId, dataDomainId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/datadomains/${dataDomainId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventDataDomainRequest(api, { eventId, dataDomainId }) {
    try {
        let tenant = yield select(getTenant);
        const dataDomain = yield call(getEventDataDomain, api, tenant.tenantId, eventId, dataDomainId);
        yield put(actions.getEventDataDomainSuccess(dataDomain));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event business area data details");
        yield put(actions.getEventDataDomainFailure(errorObject));
    }
}

export function* watchGetEventDataDomainRequest(api, { params }) {
    yield call(getEventDataDomainRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_DATA_DOMAIN_REQUEST, watchGetEventDataDomainRequest, api);
}