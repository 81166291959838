import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getAllTenants(api) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/admin/tenants`);
    return result.data;
}

function* getAllRunningJobs(api) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/admin/jobs/running`);
    return result.data;
}

function* getTenantById(api, tenantId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/admin/tenants/uuid/${tenantId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getTenantUsers(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.users}/api/v1/admin/tenants/${tenantId}/users`);
    return result.data;
}

function* updateTenantAdmin(api, tenantId, userId) {
    let result = yield call([api, api.put], `${Endpoints.users}/api/v1/admin/tenants/${tenantId}/admin`, { userId });
    return result.data;
}

function* updateTenant(api, tenantId, updatedValues) {
    let result = yield call([api, api.put], `${Endpoints.tenants}/api/v1/admin/tenants/uuid/${tenantId}`, updatedValues);
    return result.data;
}

function* deleteTenant(api, tenantId) {
    yield call([api, api.delete], `${Endpoints.tenants}/api/v1/admin/tenants/uuid/${tenantId}`);
}

export function* getAllTenantsRequest(api) {
    try {
        let result = yield call(getAllTenants, api);
        yield put(actions.getAllTenantsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "tenants");
        yield put(actions.getAllTenantsFailure(errorObject));
    }
}

export function* getAllRunningJobsRequest(api) {
    try {
        let result = yield call(getAllRunningJobs, api);
        yield put(actions.getAllRunningJobsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "running jobs");
        yield put(actions.getAllRunningJobsFailure(errorObject));
    }
}

export function* getTenantByIdRequest(api, { tenantId }) {
    try {
        let result = yield call(getTenantById, api, tenantId);
        yield put(actions.getTenantByIdSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "tenant");
        yield put(actions.getTenantByIdFailure(errorObject));
    }
}

export function* getTenantUsersRequest(api, { tenantId }) {
    try {
        let result = yield call(getTenantUsers, api, tenantId);
        yield put(actions.getTenantUsersSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "tenant users");
        yield put(actions.getTenantUsersFailure(errorObject));
    }
}

export function* updateTenantAdminRequest(api, { tenantId, userId }) {
    try {
        let result = yield call(updateTenantAdmin, api, tenantId, userId);
        yield put(actions.updateTenantAdminSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "tenant admin");
        yield put(actions.updateTenantAdminFailure(errorObject));
    }
}

export function* updateTenantRequest(api, { tenantId, updatedValues }) {
    try {
        let result = yield call(updateTenant, api, tenantId, updatedValues);
        yield put(actions.updateTenantSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "tenant");
        yield put(actions.updateTenantFailure(errorObject));
    }
}

export function* deleteTenantRequest(api, { tenantId }) {
    try {
        yield call(deleteTenant, api, tenantId);
        yield put(actions.deleteTenantSuccess({ tenantId }));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "tenant");
        yield put(actions.deleteTenantFailure(errorObject));
    }
}

export function* watchGetAllTenantsRequest(api, { params }) {
    yield call(getAllTenantsRequest, api);
}

export function* watchGetAllRunningJobsRequest(api, { params }) {
    yield call(getAllRunningJobsRequest, api);
}

export function* watchGetTenantByIdRequest(api, { params }) {
    yield call(getTenantByIdRequest, api, params);
}

export function* watchGetTenantUsersRequest(api, { params }) {
    yield call(getTenantUsersRequest, api, params);
}

export function* watchUpdateTenantAdminRequest(api, { params }) {
    yield call(updateTenantAdminRequest, api, params);
}

export function* watchUpdateTenantRequest(api, { params }) {
    yield call(updateTenantRequest, api, params);
}

export function* watchDeleteTenantRequest(api, { params }) {
    yield call(deleteTenantRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_ALL_TENANTS_REQUEST, watchGetAllTenantsRequest, api);
    yield takeLatest(actions.GET_ALL_RUNNING_JOBS_REQUEST, watchGetAllRunningJobsRequest, api);
    yield takeLatest(actions.GET_TENANT_BY_ID_REQUEST, watchGetTenantByIdRequest, api);
    yield takeLatest(actions.GET_TENANT_USERS_REQUEST, watchGetTenantUsersRequest, api);
    yield takeLatest(actions.UPDATE_TENANT_ADMIN_REQUEST, watchUpdateTenantAdminRequest, api);
    yield takeLatest(actions.UPDATE_TENANT_REQUEST, watchUpdateTenantRequest, api);
    yield takeLatest(actions.DELETE_TENANT_REQUEST, watchDeleteTenantRequest, api);
}