import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

export default function getDslMatchClauseValue(
    context: IDslQueryContext,
    queryClause: Record<string, string | IDslQueryMatchClause>,
    fields: ISearchProperty[],
): RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> {
    const rules: RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> = [];
    let operator = "=";

    switch (context) {
        case "must":
        case "filter":
            operator = "=";
            break;
        case "must_not":
            operator = "!=";
            break;
        default:
            break;
    }

    for (const [field, value] of Object.entries(queryClause)) {
        if (fields.some((f) => f.name === field)) {
            // Field is valid
            if (typeof value === "string") {
                // Simple match query
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: operator,
                    valueSource: "value",
                    value,
                });
            } else {
                rules.push({
                    id: uuidv4(),
                    field,
                    operator: operator,
                    valueSource: "value",
                    value: value.query,
                });
            }
        }
    }

    return rules;
}
