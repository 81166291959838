import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, List, Space, Button, Table, Checkbox, Tooltip } from "antd";
import moment from 'moment';

const { Title, Paragraph, Text, Link } = Typography;

const DataObjectVerificationListItem = ({ verificationItem }) => {

    return (
        <List.Item key={`${verificationItem.verificationId}_${verificationItem.version}`}>
            <Typography>
                <Paragraph>
                    <Text type="secondary">{`Verification requested on ${moment.utc(verificationItem.updatedDate).toDate().toLocaleString()}`}</Text>
                </Paragraph>
                {
                    verificationItem.verified && <Paragraph>
                        <Text type="secondary">And record is verified</Text>
                    </Paragraph>
                }
                <Paragraph>
                    <Text type="secondary">{`for schema fields: ${verificationItem.fields.map(field => field.name).join(", ")}`}</Text>
                </Paragraph>
                <Paragraph>
                    {verificationItem.consent
                        ?
                        <Text type="secondary">{"with consent option"}</Text>
                        :
                        <Text type="secondary">{`without consent option`}</Text>
                    }
                </Paragraph>
            </Typography>
        </List.Item>
    );
}

export default DataObjectVerificationListItem;