import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Spin } from 'antd';
import { DataObjectJobRecentList, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';

const { Title } = Typography;

class DataObjectJobHomeOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingJobs: false,
            fetchingPermission: false,
            busy: false,
            busyMessage: "Please wait...",
        }
    }

    componentWillMount() {
        this.props.getUserDataSetPermission();
        this.props.getRecentDataObjectJobs();
        if (!this.props.recentDataObjectJobsResult ||
            !this.props.recentDataObjectJobsResult.Items ||
            this.props.recentDataObjectJobsResult.Items.length === 0) {
            this.setState({
                fetchingJobs: true,
                fetchingPermission: true
            })
        }
        else {
            this.setState({
                refreshingJobs: true
            });
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserDataSetPermissionResultChanged(prevProps);
        this.onGetRecentDataObjectJobsResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    onGetUserDataSetPermissionResultChanged = (prevProps) => {
        if (this.props.getUserDataSetPermissionResult && this.props.getUserDataSetPermissionResult !== prevProps.getUserDataSetPermissionResult) {
            if (!this.props.getUserDataSetPermissionResult.success) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    onGetRecentDataObjectJobsResultChanged = (prevProps) => {
        if (this.props.getRecentDataObjectJobsResult && this.props.getRecentDataObjectJobsResult !== prevProps.getRecentDataObjectJobsResult) {
            if (!this.props.getRecentDataObjectJobsResult.success) {
                if (this.props.getRecentDataObjectJobsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get recent jobs at this moment.");
                }
            }
            this.setState({
                fetchingJobs: false,
                refreshingJobs: false
            });
        }
    }

    fetchingJobs = () => {
        let isBusy = this.state.fetchingJobs || this.state.fetchingPermission;
        return isBusy;
    }

    isBusy = () => {
        return this.state.isBusy || this.fetchingJobs();
    }

    render() {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        return <FullHeightContainerLayout
            showHeader={true}
            header={<Title level={4}>System Events{this.state.refreshingJobs ? <> <Spin size="small" /></> : <></>}</Title>}
            content={
                <>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.fetchingJobs() ?
                        <DataObjectJobRecentList
                            jobList={this.props.recentDataObjectJobsResult.Items}>
                        </DataObjectJobRecentList>
                        :
                        <></>
                    }
                </>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getRecentDataObjectJobsResult: state.jobs.getRecentDataObjectJobsResult,
        recentDataObjectJobsResult: state.jobs.recentDataObjectJobsResult,
        getUserDataSetPermissionResult: state.dataSets.getUserDataSetPermissionResult,
        permission: state.dataSets.permission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRecentDataObjectJobs: () => dispatch(actions.getRecentDataObjectJobsRequest()),
        getUserDataSetPermission: () => dispatch(actions.getUserDataSetPermissionRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectJobHomeOverview));