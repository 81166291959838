export const GET_DATA_OBJECT_HISTORY_REQUEST = "GET_DATA_OBJECT_HISTORY_REQUEST";
export const GET_DATA_OBJECT_HISTORY_SUCCESS = "GET_DATA_OBJECT_HISTORY_SUCCESS";
export const GET_DATA_OBJECT_HISTORY_FAILURE = "GET_DATA_OBJECT_HISTORY_FAILURE";

export const GET_DATA_OBJECT_EVENTS_REQUEST = "GET_DATA_OBJECT_EVENTS_REQUEST";
export const GET_DATA_OBJECT_EVENTS_SUCCESS = "GET_DATA_OBJECT_EVENTS_SUCCESS";
export const GET_DATA_OBJECT_EVENTS_FAILURE = "GET_DATA_OBJECT_EVENTS_FAILURE";


export const getDataObjectHistoryRequest = (businessAreaId, schemaId, dataSetId, dataObjectId) => ({
    type: GET_DATA_OBJECT_HISTORY_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId
    }
})

export const getDataObjectHistorySuccess = (dataObjectHistoryResult) => ({
    type: GET_DATA_OBJECT_HISTORY_SUCCESS,
    payload: {
        dataObjectHistoryResult
    }
})

export const getDataObjectHistoryFailure = (error) => ({
    type: GET_DATA_OBJECT_HISTORY_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectEventsRequest = (dataObjectId) => ({
    type: GET_DATA_OBJECT_EVENTS_REQUEST,
    params: {
        dataObjectId
    }
})

export const getDataObjectEventsSuccess = (dataObjectEventsResult) => ({
    type: GET_DATA_OBJECT_EVENTS_SUCCESS,
    payload: {
        dataObjectEventsResult
    }
})

export const getDataObjectEventsFailure = (error) => ({
    type: GET_DATA_OBJECT_EVENTS_FAILURE,
    payload: {
        error
    }
})