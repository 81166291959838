export const VERIFY_USER_EMAIL_REQUEST = "VERIFY_USER_EMAIL_REQUEST";
export const VERIFY_USER_EMAIL_SUCCESS = "VERIFY_USER_EMAIL_SUCCESS";
export const VERIFY_USER_EMAIL_FAILURE = "VERIFY_USER_EMAIL_FAILURE";

export const verifyUserEmailRequest = (user) => ({
    type: VERIFY_USER_EMAIL_REQUEST,
    params: {
        user
    }
})

export const verifyUserEmailSuccess = (status) => ({
    type: VERIFY_USER_EMAIL_SUCCESS,
    payload: status
})

export const verifyUserEmailFailure = (error) => ({
    type: VERIFY_USER_EMAIL_FAILURE,
    payload: error
})