export const GET_NOMINATED_VALUE_DATAOBJECTS_REQUEST = "GET_NOMINATED_VALUE_DATAOBJECTS_REQUEST";
export const GET_NOMINATED_VALUE_DATAOBJECTS_SUCCESS = "GET_NOMINATED_VALUE_DATAOBJECTS_SUCCESS";
export const GET_NOMINATED_VALUE_DATAOBJECTS_FAILURE = "GET_NOMINATED_VALUE_DATAOBJECTS_FAILURE";

export const getNominatedValueDataObjectsRequest = (nominatedRecordFieldName, dataObjectRequests) => ({
    type: GET_NOMINATED_VALUE_DATAOBJECTS_REQUEST,
    params: {
        dataObjectRequests,
        nominatedRecordFieldName
    }
})

export const getNominatedValueDataObjectsSuccess = (result) => ({
    type: GET_NOMINATED_VALUE_DATAOBJECTS_SUCCESS,
    payload: {
        result
    }
})

export const getNominatedValueDataObjectsFailure = (error) => ({
    type: GET_NOMINATED_VALUE_DATAOBJECTS_FAILURE,
    payload: {
        error
    }
})