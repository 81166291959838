import {
    GET_ALL_TENANTS_SUCCESS,
    GET_ALL_TENANTS_FAILURE,

    GET_ALL_RUNNING_JOBS_SUCCESS,
    GET_ALL_RUNNING_JOBS_FAILURE,

    GET_TENANT_BY_ID_SUCCESS,
    GET_TENANT_BY_ID_FAILURE,

    GET_TENANT_USERS_SUCCESS,
    GET_TENANT_USERS_FAILURE,

    UPDATE_TENANT_ADMIN_SUCCESS,
    UPDATE_TENANT_ADMIN_FAILURE,

    UPDATE_TENANT_SUCCESS,
    UPDATE_TENANT_FAILURE,

    DELETE_TENANT_SUCCESS,
    DELETE_TENANT_FAILURE
} from "./actions";

const initialState = {
    getAllTenantsResult: {
        success: true
    },
    allTenantsResult: {
        Items: []
    },
    getAllRunningJobsResult: {
        success: true
    },
    allRunningJobsResult: {
        Items: []
    },
    getTenantByIdResult: {
        success: true
    },
    tenant: null,
    getTenantUsersResult: {
        success: true
    },
    tenantUsers: {
        Items: []
    },
    updateTenantAdminResult: {
        success: true
    },
    updateTenantResult: {
        success: true
    },
    deleteTenantResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TENANTS_SUCCESS:
            return { ...state, getAllTenantsResult: { success: true }, allTenantsResult: action.payload.result };
        case GET_ALL_TENANTS_FAILURE:
            return { ...state, getAllTenantsResult: { success: false, ...action.payload.error }, allTenantsResult: { Items: [] } };
        case GET_ALL_RUNNING_JOBS_SUCCESS:
            return { ...state, getAllRunningJobsResult: { success: true }, allRunningJobsResult: action.payload.result };
        case GET_ALL_RUNNING_JOBS_FAILURE:
            return { ...state, getAllRunningJobsResult: { success: false, ...action.payload.error }, allRunningJobsResult: { Items: [] } };
        case GET_TENANT_BY_ID_SUCCESS:
            return { ...state, getTenantByIdResult: { success: true }, tenant: action.payload.tenant };
        case GET_TENANT_BY_ID_FAILURE:
            return { ...state, getTenantByIdResult: { success: false, ...action.payload.error }, tenant: null };
        case GET_TENANT_USERS_SUCCESS:
            return { ...state, getTenantUsersResult: { success: true }, tenantUsers: action.payload.result };
        case GET_TENANT_USERS_FAILURE:
            return { ...state, getTenantUsersResult: { success: false, ...action.payload.error }, tenantUsers: { Items: [] } };
        case UPDATE_TENANT_ADMIN_SUCCESS:
            if (action.payload.result) {
                const { prevAdminUserId, newAdminUserId } = action.payload.result;
                if (prevAdminUserId && newAdminUserId) {
                    let users = [...state.tenantUsers.Items];
                    for (let i = 0; i < users.length; i++) {
                        let user = users[i];
                        if (user.userId === prevAdminUserId) {
                            users[i] = {
                                ...user,
                                userType: "ACCOUNT_USER"
                            }
                        }
                        if (user.userId === newAdminUserId) {
                            users[i] = {
                                ...user,
                                userType: "ACCOUNT_ADMIN"
                            }
                        }
                    }
                    return { ...state, updateTenantAdminResult: { success: true }, tenantUsers: { ...state.tenantUsers, Items: users } };
                }
            }
            return { ...state, updateTenantAdminResult: { success: true } };
        case UPDATE_TENANT_ADMIN_FAILURE:
            return { ...state, updateTenantAdminResult: { success: false, ...action.payload.error } };
        case UPDATE_TENANT_SUCCESS:
            return { ...state, updateTenantResult: { success: true } };
        case UPDATE_TENANT_FAILURE:
            return { ...state, updateTenantResult: { success: false, ...action.payload.error } };
        case DELETE_TENANT_SUCCESS:
            let allTenantsResult = state.allTenantsResult;
            if (action.payload.result.tenantId) {
                allTenantsResult = { ...allTenantsResult };
                allTenantsResult.Items = [...allTenantsResult.Items];
                let tenantIndex = allTenantsResult.Items.findIndex(tenant => tenant.tenantId === action.payload.result.tenantId);
                if (tenantIndex > -1) {
                    allTenantsResult.Items.splice(tenantIndex, 1);
                }
            }
            return { ...state, deleteTenantResult: { success: true }, allTenantsResult };
        case DELETE_TENANT_FAILURE:
            return { ...state, deleteTenantResult: { success: false, ...action.payload.error } };
        default:
            return state;
    }
};
