export const GET_TENANT_PROVISION_STEPS_REQUEST = "GET_TENANT_PROVISION_STEPS_REQUEST";
export const GET_TENANT_PROVISION_STEPS_SUCCESS = "GET_TENANT_PROVISION_STEPS_SUCCESS";
export const GET_TENANT_PROVISION_STEPS_FAILURE = "GET_TENANT_PROVISION_STEPS_FAILURE";

export const TENANT_PROVISION_PROGRESS = "TENANT_PROVISION_PROGRESS";

export const TENANT_PROVISIONED = "TENANT_PROVISIONED";

export const getTenantProvisionStepsRequest = () => ({
    type: GET_TENANT_PROVISION_STEPS_REQUEST
})

export const getTenantProvisionStepsSuccess = (items) => ({
    type: GET_TENANT_PROVISION_STEPS_SUCCESS,
    payload: { items }
});

export const getTenantProvisionStepsFailure = (error) => ({
    type: GET_TENANT_PROVISION_STEPS_FAILURE,
    payload: { error }
});

export const tenantProvisionProgress = (progressStep) => ({
    type: TENANT_PROVISION_PROGRESS,
    payload: { progressStep }
});

export const tenantProvisioned = () => ({
    type: TENANT_PROVISIONED
});