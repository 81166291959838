import React, { useState, useEffect } from 'react';
import { Dropdown, Menu,Button } from "antd";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/fontawesome-free-solid';

const HoverMenu = ({ onClick, items, children }) => {
    return <Dropdown
        overlay={<Menu onClick={(e) => onClick({ ...e })}
            items={items}
        />}
        trigger={['hover']}>
        {children}
    </Dropdown>
}

export default HoverMenu