import React from "react";
import { List, Row, Col, Typography } from "antd";
import { ListRecordAttributeItem } from 'components';
import moment from 'moment';

const { Title, Text } = Typography;

const displayLabels = {
    "keyId": "Key ID",
    "name": "Name",
    "description": "Description",
    "type": "Type",
    "tenantId": "Tenant ID",
    "createdBy": "Created By",
    "updatedBy": "Updated By",
    "createdDate": "Created Date",
    "updatedDate": "Updated Date",
    "version": "Version"
};

export default function ({ record }) {
    return (
        <>
            <Row className="middle-row">
                <Col span={24}>
                    <Title level={5}><Text>{record.data.name}</Text></Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <List
                        size="small"
                        bordered
                        dataSource={Object.keys(record.data)}
                        renderItem={item => {
                            if (displayLabels[item]) {
                                if (item === "updatedDate") {
                                    return <ListRecordAttributeItem name={displayLabels[item]} value={moment.utc(record.data[item]).toDate().toLocaleString()} previousValue={record.previousData && record.previousData[item] && moment.utc(record.previousData[item]).toDate().toLocaleString()}></ListRecordAttributeItem>
                                }
                                return <ListRecordAttributeItem name={displayLabels[item]} value={record.data[item]} previousValue={record.previousData && record.previousData[item]}></ListRecordAttributeItem>
                            }
                            return <></>
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}
