import React, { useEffect, useState } from "react";
import { Switch, Table, Typography, Tooltip, Pagination, Row, Col, InputNumber, Select, Descriptions, Alert } from "antd";
import { PlusOutlined, WarningTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { FullHeightContainerLayout, BatchProcessResult } from 'components';
import moment from 'moment';

const { Title, Text } = Typography;
const { Column } = Table;
const { Option } = Select;

const getElapsedTime = (job) => {
    if (job.startedDate && job.completedDate) {
        let startDate = moment.utc(job.startedDate);
        let completedDate = moment.utc(job.completedDate);
        let duration = moment.duration(completedDate.diff(startDate));
        let displayValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
    }
    return <>NA</>;
}

const getJobStatus = (job) => {
    switch (job.status) {
        case "CREATED":
            return "Created";
        case "RE-STARTED":
            if (job.undo) {
                if (job.jobType === "FIND_DATA_OBJECTS_DUPLICATES") {
                    return "Marked for delete duplicate search result";
                }
                return "Marked for undo";
            }
            return "Marked for restart";
        case "PENDING":
            return "Pending";
        case "RUNNING":
            return "Started";
        case "CANCELLING":
            return "Marked for cancellation";
        case "CANCELLED":
            return "Canelled";
        case "COMPLETED":
            return "Completed";
        case "FAILED":
            return "Failed";
        case "DELETED":
            return "Deleted";
    }
}

const JobDetails = ({ job }) => {

    return (
        <FullHeightContainerLayout
            showHeader={false}
            content={
                <Descriptions size="small" column={5} labelStyle={{ fontWeight: "bold" }}>
                    <Descriptions.Item label="Name">
                        <Tooltip placement="topLeft" title={`${job.name} (${job.jobId})`}>{`${job.name} (${job.jobId})`}</Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="User">
                        <Tooltip placement="topLeft" title={job.createdByEmail}>{job.createdByEmail}</Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="Job Status">
                        <Tooltip placement="topLeft" title={getJobStatus(job)}>{getJobStatus(job)}</Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="Started Date">
                        {
                            job.startedDate
                                ?
                                <Tooltip placement="topLeft" title={moment.utc(job.startedDate).toDate().toLocaleDateString()}>{moment.utc(job.startedDate).toDate().toLocaleDateString()}</Tooltip>
                                :
                                <>NA</>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Started Time">
                        {
                            job.startedDate
                                ?
                                <Tooltip placement="topLeft" title={moment.utc(job.startedDate).toDate().toLocaleTimeString()}>{moment.utc(job.startedDate).toDate().toLocaleTimeString()}</Tooltip>
                                :
                                <>NA</>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Completed Date">
                        {
                            job.completedDate
                                ?
                                <Tooltip placement="topLeft" title={moment.utc(job.completedDate).toDate().toLocaleDateString()}>{moment.utc(job.completedDate).toDate().toLocaleDateString()}</Tooltip>
                                :
                                <>NA</>
                        }

                    </Descriptions.Item>
                    <Descriptions.Item label="Completed Time">
                        {
                            job.completedDate
                                ?
                                <Tooltip placement="topLeft" title={moment.utc(job.completedDate).toDate().toLocaleTimeString()}>{moment.utc(job.completedDate).toDate().toLocaleTimeString()}</Tooltip>
                                :
                                <>NA</>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Elapsed Time">
                        <Tooltip placement="topLeft" title={getElapsedTime(job)}>{getElapsedTime(job)}</Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="Details">
                        {
                            job.status === "CANCELLED" || job.status === "COMPLETED" || job.status === "FAILED" || job.status === "DELETED"
                                ?
                                <BatchProcessResult
                                    batchProcessResult={job.runInfo}
                                    textMaxWidth="12rem"
                                    onBatchOperationClick={(item) => {
                                    }}>
                                </BatchProcessResult>
                                :
                                <>NA</>
                        }
                    </Descriptions.Item>
                    {
                        job.jobType === "FIND_DATA_OBJECTS_DUPLICATES"
                            ?
                            <Descriptions.Item label="Tags">
                                <Tooltip placement="topLeft" title={job.jobInfo.dataTags.join(", ")}>{job.jobInfo.dataTags.join(", ")}</Tooltip>
                            </Descriptions.Item>
                            :
                            <></>
                    }
                </Descriptions>
            }
            showFooter={false}>
        </FullHeightContainerLayout>
    );
}

export default JobDetails;