import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Divider, Switch, Space, Button, Table, Checkbox, Tooltip, Input, Alert } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout, PathBreadcrumb } from 'components';
import moment from 'moment';
import { FileTwoTone, FolderTwoTone } from '@ant-design/icons';
import prettyBytes from 'pretty-bytes';

const { Meta } = Card;
const { Text, Link } = Typography;
const { Column } = Table;

const AWSS3BucketContentList = ({ fetchingContent, home, bucket, path, contentList, onFolderSelected, onFileSelected, allowedFileTypes = [], allowNewKey = false, readFile = true }) => {
    const [bucketContentList, setBucketContentList] = useState(contentList);
    const [newFileName, setNewFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setBucketContentList(contentList);
    }, [contentList]);

    const onFileItemClick = (file, index) => {
        let fileExtension = file.path.split('.').pop();
        if (allowedFileTypes && allowedFileTypes.length > 0 && allowedFileTypes.indexOf(fileExtension) === -1) {
            return;
        }

        let currentBucketContentList = [...bucketContentList];
        for (let i = 0; i < currentBucketContentList.length; i++) {
            if (index === i) {
                currentBucketContentList[i] = { ...currentBucketContentList[i], selected: true };
            }
            else {
                currentBucketContentList[i] = { ...currentBucketContentList[i], selected: false };
            }
        }
        setBucketContentList(currentBucketContentList);
        onFileSelected({ ...file, read: readFile });
        setSelectedFile(file);
        setNewFileName("");
    }

    const newFileItemClick = () => {
        setSelectedFile(null);
        let folderPath = path;
        if (folderPath.startsWith(`${home}/${bucket}/`)) {
            folderPath = folderPath.split(`${home}/${bucket}/`)[1];
        }
        onFileSelected({
            path: `${folderPath}${newFileName}.csv`,
            name: `${newFileName}.csv`,
            newFile: true,
            read: false
        });
    }

    const onNewFileNameValueChange = (e) => {
        let newFileNameValue = e.target.value;
        setNewFileName(newFileNameValue);
    }

    const onRemoveSelectedFile = () => {
        setSelectedFile(null);
        onFileSelected(null);
    }

    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={true}
                    showFooter={allowNewKey}
                    header={<>
                        <PathBreadcrumb path={path} onPathClick={onFolderSelected} homeImageSrc="/icons/aws/Arch_Amazon-Simple-Storage-Service_16.png"></PathBreadcrumb>
                        <Divider style={{ margin: "0.5rem 0 0 0" }} />
                        {allowedFileTypes && allowedFileTypes.length > 0 && <Alert message={`Only ${allowedFileTypes.join(", ")} file types are allowed.`} type="warning" />}
                    </>}
                    headerStyle={{ paddingTop: "1rem" }}
                    content={
                        !fetchingContent
                            ?
                            <Table size="small"
                                rowClassName={(file, index) => file.selected ? "ant-table-row-selected" : ""}
                                rowKey="path"
                                dataSource={bucketContentList}
                                pagination={false}
                                scroll={{ y: "100vh" }}
                                className="container-height-100">
                                <Column
                                    dataIndex="name"
                                    title="Name"
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, content, index) => {
                                        return <Tooltip placement="topLeft" title={value}>
                                            <Space>
                                                {
                                                    content.type === "FOLDER"
                                                        ?
                                                        <>
                                                            <FolderTwoTone style={{ fontSize: "16px" }} />
                                                            <Link onClick={() => onFolderSelected(`${home}/${bucket}/${content.path}`)}>
                                                                {content.name}
                                                            </Link>
                                                        </>
                                                        :
                                                        <>
                                                            <FileTwoTone style={{ fontSize: "16px" }} />
                                                            <Link onClick={() => onFileItemClick(content, index)}>
                                                                {content.name}
                                                            </Link>
                                                        </>
                                                }

                                            </Space>
                                        </Tooltip>
                                    }}
                                />
                                <Column
                                    width={"10%"}
                                    dataIndex="name"
                                    title="Type"
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, content, index) => {
                                        if (content.type === "FILE") {
                                            let fileExtension = value.split('.').pop();
                                            return <Tooltip placement="topLeft" title={fileExtension}>
                                                <Text>{fileExtension}</Text>
                                            </Tooltip>
                                        }
                                        return <></>
                                    }}
                                />
                                <Column
                                    width={"20%"}
                                    dataIndex="lastModified"
                                    title="Last Modified"
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, content, index) => {
                                        return <Tooltip placement="topLeft" title={value}>
                                            {value && <Text>{moment.utc(value).toDate().toLocaleString()}</Text>}
                                        </Tooltip>
                                    }}
                                />
                                <Column
                                    width={"20%"}
                                    dataIndex="size"
                                    title="Size"
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, content, index) => {
                                        return <Tooltip placement="topLeft" title={value}>
                                            {value && <Text>{prettyBytes(value)}</Text>}
                                        </Tooltip>
                                    }}
                                />
                            </Table>
                            :
                            <></>}
                    footerStyle={{ paddingTop: "5px" }}
                    footer={
                        <Row>
                            <Col span={24}>
                                {
                                    selectedFile && <Row>
                                        <Col span={24}>
                                            <Alert
                                                message="Selected File"
                                                description={`${selectedFile.name}`}
                                                type="info"
                                                closable
                                                onClose={() => onRemoveSelectedFile()}
                                            />
                                        </Col>
                                    </Row>
                                }
                                {
                                    !selectedFile && <Row>
                                        <Col span={24}>
                                            <Input.Group compact>
                                                <Row style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
                                                    <Col style={{ flexBasis: "100%" }}>
                                                        <Input value={newFileName} onChange={onNewFileNameValueChange} placeholder="Enter new file name" addonAfter=".csv" />
                                                    </Col>
                                                    <Col style={{ flexBasis: "auto" }}>
                                                        <Tooltip title="Press submit to create new file">
                                                            <Button type="primary" disabled={newFileName ? false : true} onClick={() => newFileItemClick()}>Submit</Button>
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </Input.Group>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>

                    }>

                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
}

export default AWSS3BucketContentList;