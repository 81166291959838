import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Select, Input, Button, Table, Card, Row, Col, Layout, AutoComplete, Typography, InputNumber, Form } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FullHeightContainerLayout } from "components";
import { Parser } from 'hot-formula-parser';
import { SUPPORTED_FORMULAS } from 'hot-formula-parser';
import { v4 as uuidv4 } from 'uuid';
import flatten from 'flat';
import validIdentifier from 'valid-identifier';

const { Header, Footer, Sider, Content } = Layout;
const { Column } = Table;
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;
const { Text } = Typography;

const FormulaVariableValue = ({ value, onChange, valueType, dataColumns, ...props }) => {
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        onChange(val);
    }, [val]);

    useEffect(() => {
        if (valueType === "CONSTANT") {
            setVal("");
        }
        else {
            setVal(dataColumns[0]);
        }
    }, [valueType]);

    const onConstantValueChange = (e) => {
        setVal(e.target.value);
    }

    const onDataColumnChange = (value) => {
        setVal(value);
    };

    return (
        valueType === "CONSTANT"
            ?
            <Input placeholder="Enter value" value={val} onChange={onConstantValueChange}></Input>
            :
            <Select placeholder="Select column" defaultValue={dataColumns[0]} value={val} onChange={onDataColumnChange}>
                {
                    dataColumns.map((column, index) => <Option key={index} value={column}>{column}</Option>)
                }
            </Select>
    )
}

const FormulaParser = forwardRef(({ expression, variables, inputColumns, inputData }, ref) => {
    const [currentDataRow, setCurrentDataRow] = useState(1);
    const [evaluationResult, setEvaluationResult] = useState({ result: "", error: "" });
    const [evaluateExpressionEnabled, setEvaluateExpressionEnabled] = useState(true);
    const [formulaExpression, setFormulaExpression] = useState(expression);
    const [formulaVariables, setFormulaVariables] = useState({ "variables": variables || [] });
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        evaluateExpression() {
            return evaluate();
        },
    }));

    const onEvaluateExpressionClick = (e) => {
        evaluate();
    }

    const evaluate = () => {
        if (!evaluateExpressionEnabled) {
            return {
                error: "ERROR"
            };
        }

        const parser = new Parser();
        let dataObject = inputData && inputData.length > 0 ? inputData[currentDataRow - 1] : null;
        if (formulaVariables.variables && formulaVariables.variables.length > 0) {
            for (let variable of formulaVariables.variables) {
                let variableValue = variable.value;
                if (variable.valueType === "DATA_COLUMN") {
                    if (dataObject) {
                        variableValue = inputData && inputData.length > 0 ? inputData[currentDataRow - 1][variableValue] : "";
                    }
                }
                parser.setVariable(variable.name, variableValue);
            }
        }
        if (dataObject) {
            for (let key in dataObject) {
                parser.setVariable(key, dataObject[key]);
            }
        }
        const parserResult = parser.parse(formulaExpression);
        const currentEvaluationResult = { ...evaluationResult };
        currentEvaluationResult.result = parserResult.result;
        if (parserResult.error) {
            switch (parserResult.error) {
                case "#ERROR!":
                    currentEvaluationResult.error = "Something went wrong with the expression execution.";
                    break;
                case "#DIV/0!":
                    currentEvaluationResult.error = "A divide by zero error occurred.";
                    break;
                case "#NAME?":
                    currentEvaluationResult.error = "Function or variable not found.";
                    break;
                case "#N/A":
                    currentEvaluationResult.error = "Value could not be available to function.";
                    break;
                case "#NUM!":
                    currentEvaluationResult.error = "Invalid numeric value provided to function.";
                    break;
                case "#VALUE!":
                    currentEvaluationResult.error = "Invalid argument type provided to function.";
                    break;
            }
        }
        else {
            currentEvaluationResult.error = "";
        }

        setEvaluationResult(currentEvaluationResult);
        return {
            ...currentEvaluationResult,
            variables: formulaVariables.variables,
            formulaExpression
        };
    }

    const onVariablesValueChanged = async (changedValues, allValues) => {
        try {
            await form.validateFields();
            setEvaluateExpressionEnabled(true);
            setFormulaVariables(allValues);
        }
        catch (error) {
            if (error.errorFields && error.errorFields.length > 0) {
                setEvaluateExpressionEnabled(false);
            }
            else {
                setEvaluateExpressionEnabled(true);
                setFormulaVariables(allValues);
            }
        }
    }

    const onFormulaExpressionChanged = (e) => {
        setFormulaExpression(e.target.value);
    }

    const onInputDataRowChange = (value) => {
        setCurrentDataRow(value);
    }

    const getDataRecords = (record) => {
        const attributeList = [];
        if (record) {
            for (let key in record) {
                attributeList.push({
                    name: key,
                    value: record[key],
                    warning: validIdentifier(key) === false ? "Attribute is not a valid formula expression variable. Please use variable with data column mapping." : ""
                })
            }
        }
        return attributeList;
    }

    return (
        <Layout className='full-height-container-layout'>
            <Content style={{ height: "auto", padding: "unset", color: "unset", background: "unset" }}>
                <Row style={{ height: "100%" }}>
                    <Col span={10} style={{ height: "100%" }}>
                        <Layout className='full-height-container-layout' style={{ paddingRight: "1rem" }}>
                            <Header style={{ height: "auto", padding: "unset", color: "unset", background: "unset", lineHeight: "unset" }}>
                                <Title level={5}>Variables</Title>
                            </Header>
                            <Content style={{ height: "auto", padding: "unset", color: "unset", background: "unset" }}>
                                <Form
                                    form={form}
                                    initialValues={formulaVariables}
                                    name="basic"
                                    layout="vertical"
                                    onValuesChange={onVariablesValueChanged}>
                                    <Form.List name="variables">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row key={key} wrap={false} gutter={[4, 4]}>
                                                        <Col>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'name']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Name is required',
                                                                    },
                                                                    {
                                                                        pattern: /^[a-zA-Z]+$/,
                                                                        message: 'Only alphabets allowed',
                                                                    }
                                                                ]}>
                                                                <Input placeholder="Name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'valueType']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select type of value',
                                                                    },
                                                                ]}
                                                                initialValue="DATA_COLUMN">
                                                                <Select placeholder="Value Type">
                                                                    <Option value="CONSTANT">= Constant</Option>
                                                                    <Option value="DATA_COLUMN">= Data column</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <Form.Item
                                                                noStyle
                                                                shouldUpdate={(prevValues, currentValues) => {
                                                                    if (prevValues.variables[name] && currentValues.variables[name]) {
                                                                        if (prevValues.variables[name].valueType !== currentValues.variables[name].valueType) {
                                                                            return true;
                                                                        }
                                                                        if (prevValues.variables[name].value !== currentValues.variables[name].value) {
                                                                            return true;
                                                                        }
                                                                    }
                                                                    return false;
                                                                }}>
                                                                {({ getFieldValue }) => {
                                                                    const valueType = getFieldValue(['variables', name, 'valueType'])
                                                                    return <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'value']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Value is required',
                                                                            },
                                                                        ]}>
                                                                        <FormulaVariableValue
                                                                            dataColumns={inputColumns}
                                                                            valueType={valueType}>
                                                                        </FormulaVariableValue>
                                                                    </Form.Item>
                                                                }
                                                                }
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => add()}
                                                        block
                                                        style={{ width: "100%" }}
                                                        size="small"
                                                        type="primary">
                                                        <PlusOutlined /> Add variable
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form>
                            </Content>
                        </Layout>
                    </Col>
                    <Col span={14} style={{ height: "100%" }}>
                        <Layout className='full-height-container-layout'>
                            <Header style={{ height: "auto", padding: "unset", color: "unset", background: "unset", lineHeight: "unset" }}>
                                <Row style={{ width: "100%" }}>
                                    <Col span={12}>
                                        <InputNumber style={{ width: "100%" }} addonBefore="Data row" min={1} max={inputData ? inputData.length : 1} value={currentDataRow} onChange={onInputDataRowChange} />
                                        {/* <AutoComplete
                                            allowClear={true}
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="Search data columns"
                                            options={inputColumns.map((column, index) => ({ value: column }))}>
                                        </AutoComplete> */}
                                    </Col>
                                    <Col span={12}>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            showSearch
                                            placeholder="Supported formulas"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {[...SUPPORTED_FORMULAS].map((formula, index) => <Option key={index} value={formula}>{formula}</Option>)}=
                                        </Select>
                                    </Col>
                                </Row>
                            </Header>
                            <Content style={{ height: "100%", padding: "unset", color: "unset", background: "unset" }}>
                                <FullHeightContainerLayout
                                    style={{ height: "50%" }}
                                    showHeader={false}
                                    showFooter={false}
                                    content={
                                        <Table size="small"
                                            rowKey={(record) => uuidv4()}
                                            dataSource={getDataRecords(inputData && inputData.length > 0 ? inputData[currentDataRow - 1] : null)}
                                            pagination={false}
                                            scroll={{ y: "100vh" }}
                                            className='container-height-100'>
                                            <Column
                                                dataIndex="name"
                                                title="Attribute Name"
                                                render={(value, record, index) => <>{value}</>}
                                            />
                                            <Column
                                                dataIndex="value"
                                                title="Value"
                                                render={(value, record, index) => <>{value}</>}
                                            />
                                            <Column
                                                dataIndex="warning"
                                                title="Warning"
                                                render={(value, record, index) => <>{value && <Text type="danger">{value}</Text>}</>}
                                            />
                                        </Table>
                                    }>
                                </FullHeightContainerLayout>
                                <FullHeightContainerLayout
                                    style={{ height: "50%" }}
                                    showHeader={false}
                                    showFooter={evaluationResult.error ? true : false}
                                    content={
                                        <TextArea placeholder="Enter expression" style={{ height: "100%" }} value={formulaExpression} onChange={onFormulaExpressionChanged} />
                                    }
                                    footer={
                                        <Text type="danger">{evaluationResult.error}</Text>
                                    }>
                                </FullHeightContainerLayout>
                            </Content>
                            <Footer>
                                <Input readOnly={true} addonBefore="Result" value={evaluationResult.result} addonAfter={<Button size="small" type="link" disabled={!evaluateExpressionEnabled} onClick={onEvaluateExpressionClick}>Evaluate</Button>} />
                            </Footer>
                        </Layout>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
});

export default FormulaParser;