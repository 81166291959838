
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEventDataSet(api, tenantId, eventId, dataSetId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/datasets/${dataSetId}`, { tenantid: tenantId });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventDataSetRequest(api, { eventId, dataSetId }) {
    try {
        let tenant = yield select(getTenant);
        const dataSet = yield call(getEventDataSet, api, tenant.tenantId, eventId, dataSetId);
        yield put(actions.getEventDataSetSuccess(dataSet));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event dataset details");
        yield put(actions.getEventDataSetFailure(errorObject));
    }
}

export function* watchGetEventDataSetRequest(api, { params }) {
    yield call(getEventDataSetRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_DATA_SET_REQUEST, watchGetEventDataSetRequest, api);
}