export const GET_DATA_TAGS_REQUEST = "GET_DATA_TAGS_REQUEST";
export const GET_DATA_TAGS_SUCCESS = "GET_DATA_TAGS_SUCCESS";
export const GET_DATA_TAGS_FAILURE = "GET_DATA_TAGS_FAILURE";

export const CREATE_DATA_TAG_REQUEST = "CREATE_DATA_TAG_REQUEST";
export const CREATE_DATA_TAG_SUCCESS = "CREATE_DATA_TAG_SUCCESS";
export const CREATE_DATA_TAG_FAILURE = "CREATE_DATA_TAG_FAILURE";

export const GET_DATA_TAG_BY_ID_REQUEST = "GET_DATA_TAG_BY_ID_REQUEST";
export const GET_DATA_TAG_BY_ID_SUCCESS = "GET_DATA_TAG_BY_ID_SUCCESS";
export const GET_DATA_TAG_BY_ID_FAILURE = "GET_DATA_TAG_BY_ID_FAILURE";

export const GET_DATA_TAGS_BY_BUSINESS_AREA_ID_REQUEST = "GET_DATA_TAGS_BY_BUSINESS_AREA_ID_REQUEST";
export const GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS = "GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS";
export const GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE = "GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE";

export const GET_USER_BUSINESS_AREA_DATA_TAGS_REQUEST = "GET_USER_BUSINESS_AREA_DATA_TAGS_REQUEST";
export const GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS = "GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS";
export const GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE = "GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE";

export const DELETE_DATA_TAG_REQUEST = "DELETE_DATA_TAG_REQUEST";
export const DELETE_DATA_TAG_SUCCESS = "DELETE_DATA_TAG_SUCCESS";
export const DELETE_DATA_TAG_FAILURE = "DELETE_DATA_TAG_FAILURE";

export const UPDATE_DATA_TAG_REQUEST = "UPDATE_DATA_TAG_REQUEST";
export const UPDATE_DATA_TAG_SUCCESS = "UPDATE_DATA_TAG_SUCCESS";
export const UPDATE_DATA_TAG_FAILURE = "UPDATE_DATA_TAG_FAILURE";

export const GET_USER_DATA_TAG_PERMISSION_REQUEST = "GET_USER_DATA_TAG_PERMISSION_REQUEST";
export const GET_USER_DATA_TAG_PERMISSION_SUCCESS = "GET_USER_DATA_TAG_PERMISSION_SUCCESS";
export const GET_USER_DATA_TAG_PERMISSION_FAILURE = "GET_USER_DATA_TAG_PERMISSION_FAILURE";

export const getDataTagsRequest = () => ({
    type: GET_DATA_TAGS_REQUEST
})

export const getDataTagsSuccess = (dataTagsResult) => ({
    type: GET_DATA_TAGS_SUCCESS,
    payload: {
        dataTagsResult
    }
})

export const getDataTagsFailure = (error) => ({
    type: GET_DATA_TAGS_FAILURE,
    payload: {
        error
    }
})

export const createDataTagRequest = (dataTag) => ({
    type: CREATE_DATA_TAG_REQUEST,
    params: {
        dataTag
    }
})

export const createDataTagSuccess = (dataTag) => ({
    type: CREATE_DATA_TAG_SUCCESS,
    payload: {
        dataTag
    }
})

export const createDataTagFailure = (error) => ({
    type: CREATE_DATA_TAG_FAILURE,
    payload: {
        error
    }
})

export const getDataTagByIdRequest = (tagId) => ({
    type: GET_DATA_TAG_BY_ID_REQUEST,
    params: {
        tagId
    }
})

export const getDataTagByIdSuccess = (dataTag) => ({
    type: GET_DATA_TAG_BY_ID_SUCCESS,
    payload: {
        dataTag
    }
})

export const getDataTagByIdFailure = (error) => ({
    type: GET_DATA_TAG_BY_ID_FAILURE,
    payload: {
        error
    }
})


export const getDataTagsByBusinessAreaIdRequest = (businessAreaId) => ({
    type: GET_DATA_TAGS_BY_BUSINESS_AREA_ID_REQUEST,
    params: {
        businessAreaId
    }
})

export const getDataTagsByBusinessAreaIdSuccess = (result, businessAreaId) => ({
    type: GET_DATA_TAGS_BY_BUSINESS_AREA_ID_SUCCESS,
    payload: {
        result,
        businessAreaId
    }
})

export const getDataTagsByBusinessAreaIdFailure = (error, businessAreaId) => ({
    type: GET_DATA_TAGS_BY_BUSINESS_AREA_ID_FAILURE,
    payload: {
        error,
        businessAreaId
    }
})

export const getUserBusinessAreaDataTagsRequest = () => ({
    type: GET_USER_BUSINESS_AREA_DATA_TAGS_REQUEST
})

export const getUserBusinessAreaDataTagsSuccess = (result) => ({
    type: GET_USER_BUSINESS_AREA_DATA_TAGS_SUCCESS,
    payload: {
        result
    }
})

export const getUserBusinessAreaDataTagsFailure = (error) => ({
    type: GET_USER_BUSINESS_AREA_DATA_TAGS_FAILURE,
    payload: {
        error
    }
})

export const deleteDataTagRequest = (tagId) => ({
    type: DELETE_DATA_TAG_REQUEST,
    params: {
        tagId
    }
})

export const deleteDataTagSuccess = (tagId) => ({
    type: DELETE_DATA_TAG_SUCCESS,
    payload: {
        tagId
    }
})

export const deleteDataTagFailure = (error) => ({
    type: DELETE_DATA_TAG_FAILURE,
    payload: {
        error
    }
})

export const updateDataTagRequest = (dataTag, updatedDataTag) => ({
    type: UPDATE_DATA_TAG_REQUEST,
    params: {
        dataTag,
        updatedDataTag
    }
})

export const updateDataTagSuccess = () => ({
    type: UPDATE_DATA_TAG_SUCCESS
})

export const updateDataTagFailure = (error) => ({
    type: UPDATE_DATA_TAG_FAILURE,
    payload: {
        error
    }
})

export const getUserDataTagPermissionRequest = () => ({
    type: GET_USER_DATA_TAG_PERMISSION_REQUEST
})

export const getUserDataTagPermissionSuccess = (permission) => ({
    type: GET_USER_DATA_TAG_PERMISSION_SUCCESS,
    payload: {
        permission
    }
})

export const getUserDataTagPermissionFailure = (error) => ({
    type: GET_USER_DATA_TAG_PERMISSION_FAILURE,
    payload: {
        error
    }
})