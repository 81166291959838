import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { FullHeightContainerLayout, SchemaModelCreateOptions, SchemaModelCreateFromFile } from "components";
import { SchemaModelClone } from 'containers';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Typography, Upload } from 'antd';


const { Title, Text } = Typography;
const { Dragger } = Upload;

class SchemaModelCreateAutomation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createOption: "CREATE_FROM_FILE"
        }
    }

    componentWillMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    onOptionSelected = option => {
        if (option === "CREATE_MANUALLY") {
            this.props.onManuallyCreateSchema && this.props.onManuallyCreateSchema();
        }
        else {
            this.setState({
                createOption: option
            })
        }
    }

    getOptionComponent = () => {
        switch (this.state.createOption) {
            case "CREATE_FROM_FILE":
                return <SchemaModelCreateFromFile
                    onCreateSchemaModel={this.props.onCreateSchemaModel}
                    onUploadFile={this.props.onUploadSchemaDefinitionFile}
                    uploadFileResult={this.props.uploadSchemaModelDefinitionResult}
                    uploadedFile={this.props.schemaModelDefinitionFile}
                    getParsedSchemaDefinition={this.props.getParsedSchemaDefinition}
                    getParsedSchemaDefinitionResult={this.props.getParsedSchemaDefinitionResult}
                    schemaDefinition={this.props.schemaDefinition}
                    onManageParsedSchemaModel={this.props.onManageParsedSchemaModel}
                    onCancelManageParsedSchemaModel={this.props.onCancelManageParsedSchemaModel}
                    manageParsedSchemaModelCancelled={this.props.manageParsedSchemaModelCancelled}>
                </SchemaModelCreateFromFile>
            case "CLONE_EXISTING_SCHEMA":
                return <SchemaModelClone
                    tenant={this.props.tenant}
                    businessArea={this.props.businessArea}>
                </SchemaModelClone>
            default:
                return <></>
        }
    }

    render() {
        return <FullHeightContainerLayout
            showHeader={true}
            header={<SchemaModelCreateOptions
                selectedOption={this.state.createOption}
                onOptionSelected={this.onOptionSelected}>
            </SchemaModelCreateOptions>}
            content={this.getOptionComponent()}>
        </FullHeightContainerLayout>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModelCreateAutomation));