import react, { useState } from 'react';
import { Tooltip, Progress, Row, Col } from "antd";
import { WarningTwoTone, CheckCircleOutlined, MinusOutlined } from "@ant-design/icons";

const getInsightRecord = (successDataCount, erroredDataCount, fieldDataType) => {
    const totalRecords = successDataCount + erroredDataCount;
    return {
        totalRecords,
        errorRecordsPercentage: (erroredDataCount / totalRecords) * 100,
        successRecordsPercentage: (successDataCount / totalRecords) * 100,
        erroredRecordsToolTip: fieldDataType ? `Column is of type ${fieldDataType}.\r\n${totalRecords} rows evaluated.\r\n${erroredDataCount} rows failed validation.` : `${totalRecords} rows evaluated.\r\n${erroredDataCount} rows failed validation.`,
        successRecordsToolTip: fieldDataType ? `Column is of type ${fieldDataType}.\r\n${totalRecords} rows evaluated.\r\nNo rows failed validation.` : `${totalRecords} rows evaluated.\r\nNo rows failed validation.`
    }
}

function FieldInsightProgress({ successDataCount, erroredDataCount, fieldDataType }) {
    let [insightRecord, setInsightRecord] = useState(getInsightRecord(successDataCount, erroredDataCount, fieldDataType));
    return <Row style={{ flexDirection: "row", flexWrap: "nowrap" }}>
        <Col style={{ flex: 1, flexWrap: "nowrap" }}>
            {
                insightRecord.errorRecordsPercentage ?
                    <Tooltip placement="topLeft" title={insightRecord.erroredRecordsToolTip}>
                        <Row style={{ flexDirection: "row-reverse", flexWrap: "nowrap" }}>
                            <Col style={{ alignSelf: "center" }}>
                                <WarningTwoTone twoToneColor="#FF0000" />
                            </Col>
                            <Col style={{ flex: 1, flexWrap: "nowrap" }}>
                                <Progress strokeColor="#fc5a03" percent={insightRecord.errorRecordsPercentage} size="small" format={percent => ""} />
                            </Col>
                        </Row>
                    </Tooltip>
                    :
                    <Tooltip placement="topLeft" title={insightRecord.successRecordsToolTip}>
                        <Row style={{ flexDirection: "row-reverse", flexWrap: "nowrap" }}>
                            <Col style={{ alignSelf: "center" }}>
                                <CheckCircleOutlined style={{ color: "#00cf18" }} />
                            </Col>
                            <Col style={{ flex: 1, flexWrap: "nowrap" }}>
                                <Progress strokeColor="#00cf18" percent={insightRecord.successRecordsPercentage} size="small" format={percent => ""} />
                            </Col>
                        </Row>
                    </Tooltip>
            }
        </Col>
    </Row >
}

export default FieldInsightProgress