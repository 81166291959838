import {
    ClearOutlined,
    DeleteOutlined,
    DeleteRowOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { AntDValueSelector, QueryBuilderAntD } from "@react-querybuilder/antd";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import { Button, Flex, Select } from "antd";
import React from "react";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import type {
    ActionProps,
    ActionWithRulesProps,
    Field,
    FullOption,
    RuleGroupType,
    ValueSelectorProps,
} from "react-querybuilder";
import { QueryBuilder, defaultOperators } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { formatQuery } from "react-querybuilder/formatQuery";
import "../../../assets/css/query-builder.css";

const initialQuery: RuleGroupType = { combinator: "and", rules: [] };
interface IFieldOption extends Field {
    name: string;
    label: string;
}

function FieldSearchableSelector(props: ValueSelectorProps) {
    if (props.options) {
        return (
            <Select
                showSearch
                style={{ width: 200 }}
                options={props.options as FullOption<string>[]}
                onChange={props.handleOnChange}
                disabled={props.disabled}
                value={props.value}
                placement="bottomLeft"
                popupMatchSelectWidth={false}
            />
        );
    }
    return <AntDValueSelector {...props} />;
}

function RemoveGroupAction(props: ActionWithRulesProps) {
    return (
        <Button
            {...props}
            type="text"
            danger
            onClick={props.handleOnClick}
            icon={<DeleteOutlined />}
        />
    );
}

function RemoveRuleAction(props: ActionProps) {
    return (
        <Button
            {...props}
            type="text"
            danger
            onClick={props.handleOnClick}
            icon={<DeleteRowOutlined />}
        />
    );
}

export default function ElasticSearchQueryBuilder({
    query: propQuery,
    properties,
    onUpdateQuery,
    onSubmit,
    additionalActions,
}: IElasticSearchQueryBuilderProps) {
    const [query, setQuery] = React.useState<RuleGroupType>(
        propQuery ?? initialQuery,
    );
    const [fields, setFields] = React.useState<IFieldOption[]>([]);

    const getFields = React.useCallback(() => {
        const _fields: IFieldOption[] = [];
        if (properties) {
            for (let property of properties) {
                const _field: IFieldOption = {
                    name: property.name,
                    label: property.name,
                    inputType: "text",
                };

                if (property.fieldType) {
                    switch (property.fieldType) {
                        case "integer":
                        case "float":
                        case "currency":
                            _field.inputType = "number";
                            _field.operators = defaultOperators.filter(
                                (op) =>
                                    ["=", "!=", "<", ">", "<=", ">="].indexOf(
                                        op.value,
                                    ) > -1,
                            );
                            break;
                        case "string":
                            _field.inputType = "text";
                            break;
                        case "boolean":
                            _field.inputType = "checkbox";
                            _field.operators = defaultOperators.filter(
                                (op) => ["=", "!="].indexOf(op.value) > -1,
                            );
                            break;
                        case "datetime":
                        case "date":
                            _field.inputType = "date";
                            _field.operators = defaultOperators.filter(
                                (op) =>
                                    ["=", "!=", "<", ">", "<=", ">="].indexOf(
                                        op.value,
                                    ) > -1,
                            );
                            break;
                        case "time":
                            _field.inputType = "time";
                            _field.operators = defaultOperators.filter(
                                (op) =>
                                    ["=", "!=", "<", ">", "<=", ">="].indexOf(
                                        op.value,
                                    ) > -1,
                            );
                            break;
                        case "date-year":
                        case "date-month":
                        case "date-day":
                            _field.inputType = "number";
                            _field.operators = defaultOperators.filter(
                                (op) =>
                                    ["=", "!=", "<", ">", "<=", ">="].indexOf(
                                        op.value,
                                    ) > -1,
                            );
                            break;
                        case "picklist":
                            _field.inputType = "text";
                            break;
                        case "email":
                            _field.inputType = "email";
                            _field.operators = defaultOperators.filter(
                                (op) =>
                                    [
                                        "=",
                                        "!=",
                                        "contains",
                                        "beginsWith",
                                        "endsWith",
                                        "doesNotContain",
                                        "doesNotBeginWith",
                                        "doesNotEndWith",
                                    ].indexOf(op.value) > -1,
                            );
                            break;
                        case "url":
                        case "phone":
                            _field.inputType = "url";
                            _field.operators = defaultOperators.filter(
                                (op) =>
                                    [
                                        "=",
                                        "!=",
                                        "contains",
                                        "beginsWith",
                                        "endsWith",
                                        "doesNotContain",
                                        "doesNotBeginWith",
                                        "doesNotEndWith",
                                    ].indexOf(op.value) > -1,
                            );
                            break;
                        default:
                            _field.inputType = "text";
                            break;
                    }
                } else {
                    switch (property.type) {
                        case "long":
                        case "integer":
                        case "double":
                            _field.inputType = "number";
                            break;
                        case "text":
                        case "keyword":
                            _field.inputType = "text";
                            break;
                        case "boolean":
                            _field.inputType = "checkbox";
                            _field.operators = defaultOperators.filter(
                                (op) => op.name === "=",
                            );
                            break;
                        case "date":
                            _field.inputType = "date";
                            break;
                        default:
                            _field.inputType = "text";
                            break;
                    }
                }
                _fields.push(_field);
            }
        }

        setFields(_fields);
    }, [properties]);

    const handleQueryChange = React.useCallback(
        (_query: RuleGroupType) => {
            setQuery(_query);
            const _dslQuery = formatQuery(_query, "elasticsearch");
            onUpdateQuery && onUpdateQuery(_dslQuery, _query);
        },
        [onUpdateQuery],
    );

    React.useEffect(() => {
        // On load, get fields and parse logic
        if (propQuery) {
            setQuery(propQuery);
        }
        getFields();
    }, [getFields, properties, propQuery]);

    const handleClearAll = React.useCallback(() => {
        setQuery(initialQuery);
        onUpdateQuery && onUpdateQuery({}, initialQuery);
    }, [onUpdateQuery]);

    return (
        <QueryBuilderAntD>
            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                <QueryBuilder
                    fields={fields}
                    query={query}
                    onQueryChange={handleQueryChange}
                    controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                    }}
                    controlElements={{
                        fieldSelector: FieldSearchableSelector,
                        removeGroupAction: RemoveGroupAction,
                        removeRuleAction: RemoveRuleAction,
                    }}
                />
            </QueryBuilderDnD>
            <Flex gap="small" justify="flex-end">
                {additionalActions}
                <Button icon={<ClearOutlined />} onClick={handleClearAll}>
                    Clear All
                </Button>
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={onSubmit}
                >
                    Search
                </Button>
            </Flex>
        </QueryBuilderAntD>
    );
}
