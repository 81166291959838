import {
    GET_NOMINATED_VALUE_DATAOBJECTS_REQUEST,
    GET_NOMINATED_VALUE_DATAOBJECTS_SUCCESS,
    GET_NOMINATED_VALUE_DATAOBJECTS_FAILURE,
} from "./actions";

const initialState = {
    getNominatedValueDataObjectsRequestResult: {
        success: true,
        inProgress: false,
    },
    nominatedValueDataObjects: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_NOMINATED_VALUE_DATAOBJECTS_REQUEST:
            return {
                ...state,
                getNominatedValueDataObjectsRequestResult:
                {
                    success: true,
                    inProgress: true,
                },
                nominatedValueDataObjects: {
                    Items: []
                }
            };
        case GET_NOMINATED_VALUE_DATAOBJECTS_SUCCESS:
            return {
                ...state,
                getNominatedValueDataObjectsRequestResult:
                {
                    success: true,
                    inProgress: action.payload.result.loadingMore
                },
                nominatedValueDataObjects: {
                    Items: [...state.nominatedValueDataObjects.Items, action.payload.result.data]
                }
            };
        case GET_NOMINATED_VALUE_DATAOBJECTS_FAILURE:
            return {
                ...state,
                getNominatedValueDataObjectsRequestResult:
                {
                    success: false,
                    inProgress: false,
                    ...action.payload.error
                }
            };
        default:
            return state;
    }
};
