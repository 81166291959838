import React, { useState, useRef, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { AdminTenantOverview } from 'containers';
import { Row, Col, Breadcrumb, Space, Tooltip, Button } from "antd";
import { Segment } from 'semantic-ui-react';
import { LockOutlined, LockTwoTone, UnlockOutlined, UnlockTwoTone, DeleteOutlined, DeleteTwoTone } from '@ant-design/icons';

const TenantOverviewPage = (props) => {
    const [breadcrumbNavigationItems, setBreadcrumbNavigationItems] = useState([]);
    const [tenant, setTenant] = useState(null);
    const tenantInstance = useRef(null);
    const { tenantid } = useParams();

    useEffect(() => {
        return () => {
            tenantInstance.current = null;
        };
    }, []);

    const onDisableTenantClick = () => {
        if (tenantInstance.current) {
            tenantInstance.current.disableTenant();
        }
    }

    const onEnableTenantClick = () => {
        if (tenantInstance.current) {
            tenantInstance.current.enableTenant();
        }
    }

    const onDeleteTenantClick = () => {
        if (tenantInstance.current) {
            tenantInstance.current.deleteTenant();
        }
    }

    return (
        <>
            <Row className="bread-crumbs-container" style={{ "justifyContent": "space-between", "alignItems": "center" }}>
                <Col>
                    {
                        breadcrumbNavigationItems.length > 0
                            ?
                            <Breadcrumb separator=">">
                                {
                                    breadcrumbNavigationItems.map((item, index) => <Breadcrumb.Item className='breadcrumb-item' key={`${index}_${item.text}`}>{item.route && index < breadcrumbNavigationItems.length - 1 ? <Link to={item.route}>{item.text}</Link> : <>{item.text}</>}</Breadcrumb.Item>)
                                }
                            </Breadcrumb>
                            :
                            <></>
                    }
                </Col>
                <Col>
                    <Space.Compact block>
                        <Tooltip title="Disable Tenant">
                            <Button
                                onClick={onDisableTenantClick}
                                icon={(!tenant || !tenant.enabled) ? <LockOutlined /> : <LockTwoTone />}
                                disabled={!tenant || !tenant.enabled} />
                        </Tooltip>
                        <Tooltip title="Enable Tenant">
                            <Button
                                onClick={onEnableTenantClick}
                                icon={(!tenant || tenant.enabled) ? <UnlockOutlined /> : <UnlockTwoTone />}
                                disabled={!tenant || tenant.enabled} />
                        </Tooltip>
                        <Tooltip title="Delete Tenant">
                            <Button
                                onClick={onDeleteTenantClick}
                                icon={(!tenant || tenant.enabled) ? <DeleteOutlined /> : <DeleteTwoTone />}
                                disabled={!tenant || tenant.enabled} />
                        </Tooltip>
                    </Space.Compact>
                </Col>
            </Row>
            <Row wrap={false} className="page-body-container">
                <Col span={24}>
                    <Segment className="full-height-flex-container">
                        <AdminTenantOverview instance={tenantInstance} tenantId={tenantid} setBreadcrumbNavigationItems={setBreadcrumbNavigationItems} onFetchTenant={setTenant}></AdminTenantOverview>
                    </Segment>
                </Col>
            </Row>
        </>
    )
}

export default TenantOverviewPage
