import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getDataDomainHistory(api, tenantId, dataDomainId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datadomains/${dataDomainId}/history`, { tenantid: tenantId });
    return result.data;
}

function* getDataDomainEvents(api, tenantId, dataDomainId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/datadomains/${dataDomainId}/events`, { tenantid: tenantId });
    return result.data;
}

export function* getDataDomainHistoryRequest(api, { dataDomainId }) {
    try {
        let tenant = yield select(getTenant);
        let dataDomainHistoryResult = yield call(getDataDomainHistory, api, tenant.tenantId, dataDomainId);
        yield put(actions.getDataDomainHistorySuccess(dataDomainHistoryResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area data history");
        yield put(actions.getDataDomainHistoryFailure(errorObject));
    }
}

export function* getDataDomainEventsRequest(api, { dataDomainId }) {
    try {
        let tenant = yield select(getTenant);
        let dataDomainEventsResult = yield call(getDataDomainEvents, api, tenant.tenantId, dataDomainId);
        yield put(actions.getDataDomainEventsSuccess(dataDomainEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area data events");
        yield put(actions.getDataDomainEventsFailure(errorObject));
    }
}

export function* watchGetDataDomainHistoryRequest(api, { params }) {
    yield call(getDataDomainHistoryRequest, api, params);
}

export function* watchGetDataDomainEventsRequest(api, { params }) {
    yield call(getDataDomainEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_DOMAIN_HISTORY_REQUEST, watchGetDataDomainHistoryRequest, api);
    yield takeLatest(actions.GET_DATA_DOMAIN_EVENTS_REQUEST, watchGetDataDomainEventsRequest, api);
}