import React from 'react';
import { MainPageLogin } from 'components';

const MainPage = (props) => {
  return (
    <MainPageLogin {...props}></MainPageLogin>
  )
}

export default MainPage
