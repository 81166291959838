import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Col, Tabs } from 'antd';
import actions from 'store/actions';
import {
    GuestDataObjectSelfVerificationForm,
    LoadingOverlay,
    VerificationLinkExpiredMessage,
    NotFoundError,
    SuccessResult
} from "components";
import { showError, showSuccess, showWarning, showInfo } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";

class GuestDataObjectSelfVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingRecord: true
        }
    }

    componentWillMount() {
        this.props.getDataObjectGuestVerification(
            this.props.tenantId,
            this.props.businessAreaId,
            this.props.schemaId,
            this.props.dataObjectId,
            this.props.verificationId,
            this.props.sender,
            this.props.version,
            this.props.toEmail,
            this.props.expires,
            this.props.date,
            this.props.signature);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataObjectGuestVerificationResultChanged(prevProps);
        this.onUpdateDataObjectVerificationResultChanged(prevProps);
    }

    onGetDataObjectGuestVerificationResultChanged = (prevProps) => {
        if (this.props.getDataObjectGuestVerificationResult && this.props.getDataObjectGuestVerificationResult !== prevProps.getDataObjectGuestVerificationResult) {
            if (!this.props.getDataObjectGuestVerificationResult.success) {
                if (this.props.getDataObjectGuestVerificationResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get verification record.");
                }
            }
            this.setState({
                fetchingRecord: false
            });
        }
    }

    onUpdateDataObjectVerificationResultChanged = (prevProps) => {
        if (this.props.updateDataObjectVerificationResult && this.props.updateDataObjectVerificationResult !== prevProps.updateDataObjectVerificationResult) {
            if (!this.props.updateDataObjectVerificationResult.success) {
                if (this.props.updateDataObjectVerificationResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to update verification.");
                }
            }
            else {
                this.setState({
                    showSuccessResult: true
                });
            }
            this.setState({
                savingRecord: false
            });
        }
    }

    getError = () => {
        if (this.props.getDataObjectGuestVerificationResult.success && !this.props.dataObjectGuestVerificationResult) {
            return {
                title: "Dataset record not found.",
                description: "The dataset record you are looking for does not exist."
            }
        }
        else if (!this.props.getDataObjectGuestVerificationResult.success) {
            return {
                title: "System Error",
                description: this.props.getDataObjectGuestVerificationResult.message || "An error occurred while fetching the record."
            }
        }
        return null;
    }

    updateVerification = (dataObjectId, updatedDataObject, consent) => {
        this.setState({
            savingRecord: true
        });
        this.props.updateDataObjectGuestVerification(
            this.props.tenantId,
            this.props.businessAreaId,
            this.props.schemaId,
            dataObjectId,
            this.props.verificationId,
            this.props.sender,
            this.props.version,
            this.props.toEmail,
            this.props.expires,
            this.props.date,
            this.props.signature,
            updatedDataObject,
            consent,
            this.props.dataObjectGuestVerificationResult.verification.version);
    }

    closeSuccessResult = () => {
        this.setState({
            showSuccessResult: false
        });
        this.props.history.push(AppPaths.LOGIN);
    }

    render() {
        if (this.state.fetchingRecord) {
            return <LoadingOverlay
                busy={this.state.fetchingRecord}
                spinner
                message={"Please wait..."}>
            </LoadingOverlay>;
        }
        if (this.state.permissionDenied) {
            return <VerificationLinkExpiredMessage></VerificationLinkExpiredMessage>;
        }
        let error = this.getError();
        return !error ?
            <>
                <LoadingOverlay
                    busy={this.state.savingRecord}
                    spinner
                    message={"Please wait..."}>
                </LoadingOverlay>
                <SuccessResult
                    show={this.state.showSuccessResult}
                    title="Verification completed."
                    okText="Ok"
                    cancelText="Close"
                    onOkClick={this.closeSuccessResult}
                    onCancelClick={this.closeSuccessResult}>
                </SuccessResult>
                <GuestDataObjectSelfVerificationForm
                    dataObject={this.props.dataObjectGuestVerificationResult.dataObject}
                    schemaModel={this.props.dataObjectGuestVerificationResult.schemaModel}
                    showConsent={this.props.dataObjectGuestVerificationResult.verification.consent}
                    onContinue={this.updateVerification}>
                </GuestDataObjectSelfVerificationForm>
            </>
            :
            <NotFoundError
                title={error.title}
                description={error.description}>
            </NotFoundError>

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataObjectGuestVerificationResult: state.dataObjectVerification.getDataObjectGuestVerificationResult,
        dataObjectGuestVerificationResult: state.dataObjectVerification.dataObjectGuestVerificationResult,
        updateDataObjectVerificationResult: state.dataObjectVerification.updateDataObjectVerificationResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataObjectGuestVerification: (
            tenantId,
            businessAreaId,
            schemaId,
            dataObjectId,
            verificationId,
            sender,
            version,
            toEmail,
            expires,
            date,
            signature) => dispatch(actions.getDataObjectGuestVerificationRequest(
                {
                    tenantId,
                    businessAreaId,
                    schemaId,
                    dataObjectId,
                    verificationId,
                    sender,
                    version,
                    toEmail,
                    expires,
                    date,
                    signature
                })),
        updateDataObjectGuestVerification: (
            tenantId,
            businessAreaId,
            schemaId,
            dataObjectId,
            verificationId,
            sender,
            version,
            toEmail,
            expires,
            date,
            signature,
            dataObject,
            consent,
            verificationVersion) => dispatch(actions.updateDataObjectGuestVerificationRequest(
                {
                    tenantId,
                    businessAreaId,
                    schemaId,
                    dataObjectId,
                    verificationId,
                    sender,
                    version,
                    toEmail,
                    expires,
                    date,
                    signature,
                    dataObject,
                    consent,
                    verificationVersion
                })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GuestDataObjectSelfVerification));