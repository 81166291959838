export default {
    "LOGIN": "/",
    "SIGN_UP": "/signup",
    "VERIFY_USER_EMAIL": "/verifyemail",
    "RESET_PASSWORD": "/resetpassword",
    "TENANT_PROVISION": "/:tenant/provision",
    "TENANT_HOME": "/:tenant/home",
    "TENANT_BUSINESS_AREA_SCHEMA": "/:tenant/businessareas/:businessarea/schemas",
    "TENANT_BUSINESS_AREA_SCHEMA_ACTION": "/:tenant/businessareas/:businessarea/schemas/:schemaAction",
    "TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION": "/:tenant/businessareas/:businessarea/schemas/:schemaId/:schemaAction",
    "TENANT_SCHEMA_DATASET_ACTION": "/:tenant/businessareas/:businessarea/schemas/:schema/datasets/:dataSetAction",
    "TENANT_SCHEMA_DATASET_ID_ACTION": "/:tenant/businessareas/:businessarea/schemas/:schema/datasets/:dataSetId/:dataSetAction",
    "TENANT_DATASET_DATAOBJECT_ACTION": "/:tenant/businessareas/:businessarea/schemas/:schema/datasets/:dataset/dataobjects/:dataObjectAction",
    "TENANT_DATASET_DATAOBJECT_ID_ACTION": "/:tenant/businessareas/:businessarea/schemas/:schema/datasets/:dataset/dataobjects/:dataObjectId/:dataObjectAction",
    "TENANT_DOMAIN_SERVICE_CONSOLE": "/:tenant/domainserviceconsole",
    "TENANT_CONFIGURATION": "/:tenant/configuration",
    "TENANT_CONFIGURATION_ITEM": "/:tenant/configuration/:configurationItem",
    "TENANT_CONFIGURATION_ITEM_ACTION": "/:tenant/configuration/:configurationItem/:itemAction",
    "TENANT_CONFIGURATION_ITEM_ID_ACTION": "/:tenant/configuration/:configurationItem/:itemId/:itemAction",
    "TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION": "/:tenant/configuration/:configurationItem/:subItem/:itemId/:itemAction",
    "TENANT_LICENSE_INFO": "/:tenant/licenseinfo",
    "TENANT_USER_PROFILE": "/:tenant/userprofile",
    "TENANT_MONITOR": "/:tenant/monitor",
    "TENANT_MONITOR_ITEM": "/:tenant/monitor/:monitorItem",
    "TENANT_DATA_OBJECT_SEARCH": "/:tenant/search",
    "TENANT_DATA_OBJECT_DUPLICATE_SEARCH": "/:tenant/search/duplicates",
    "TENANT_LINEAGE": "/:tenant/lineage",
    "UUID": "/:uuid",
    "SHARED_DATAOBJECT": "/shared/:uuid",
    "DATA_OBJECT_SELF_VERIFICATION": "/dataobjects/verification/:tenantid/:businessareaid/:schemaid/:dataobjectid/:verificationid",
    "ADMIN_CONSOLE": "/adminconsole",
    "TENANT_OVERVIEW": "/tenants/:tenantid",
}