import { RuleGroupType } from "react-querybuilder";
import { QUERY_BUILDER_OBJECT } from "./actionTypes";

export const setQueryBuilderObjectAction = (
    query: RuleGroupType | null | string,
): IQueryBuilderObjectAction => ({
    type: QUERY_BUILDER_OBJECT,
    payload: {
        query,
    },
});
