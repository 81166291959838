import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import {
    Handle,
    Position
} from 'reactflow';
import { DoubleRightOutlined, DoubleLeftOutlined, CaretDownFilled, FullscreenOutlined } from '@ant-design/icons';
import 'reactflow/dist/style.css';

const { Text, Link, Title } = Typography;

function LineageTagNode({ data }) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Row style={{ marginLeft: "1px" }}>
                <Col style={{ display: "flex" }}>
                    <Tag color="volcano" style={{ marginRight: "unset", lineHeight: "unset", fontSize: "0.8vh", height: "1.6vh" }}>{data.name}</Tag>
                </Col>
            </Row >
        </>
    );
}

export default LineageTagNode;