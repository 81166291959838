
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';

function* getAggregations(api) {
    try {
        let result = yield call([api, api.get], `${Endpoints.aggregations}/api/v1/counts`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getRecentLoggedInUsers(api) {
    let result = yield call([api, api.get], `${Endpoints.users}/api/v1/users/recent/logins`);
    return result.data;
}

function* getRunningJobs(api) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs?status=RUNNING`);
    return result.data;
}

function* getFailedJobs(api) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs?status=FAILED`);
    return result.data;
}

export function* getAggregationsRequest(api) {
    try {
        const result = yield call(getAggregations, api);
        yield put(actions.getAggregationsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "aggregation counts");
        yield put(actions.getAggregationsFailure(errorObject));
    }
}

export function* getRecentLoggedInUsersRequest(api) {
    try {
        const result = yield call(getRecentLoggedInUsers, api);
        yield put(actions.getRecentLoggedInUsersSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "recent logged-in users");
        yield put(actions.getRecentLoggedInUsersFailure(errorObject));
    }
}

export function* getRunningJobsRequest(api) {
    try {
        const result = yield call(getRunningJobs, api);
        yield put(actions.getRunningJobsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "running jobs");
        yield put(actions.getRunningJobsFailure(errorObject));
    }
}

export function* getFailedJobsRequest(api) {
    try {
        const result = yield call(getFailedJobs, api);
        yield put(actions.getFailedJobsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "failed jobs");
        yield put(actions.getFailedJobsFailure(errorObject));
    }
}

export function* watchGetAggregationsRequest(api, params) {
    yield call(getAggregationsRequest, api);
}

export function* watchGetRecentLoggedInUsersRequest(api, params) {
    yield call(getRecentLoggedInUsersRequest, api);
}

export function* watchGetRunningJobsRequest(api, params) {
    yield call(getRunningJobsRequest, api);
}

export function* watchGetFailedJobsRequest(api, params) {
    yield call(getFailedJobsRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_AGGREGATIONS_REQUEST, watchGetAggregationsRequest, api);
    yield takeLatest(actions.GET_RECENT_LOGGED_IN_USERS_REQUEST, watchGetRecentLoggedInUsersRequest, api);
    yield takeLatest(actions.GET_RUNNING_JOBS_REQUEST, watchGetRunningJobsRequest, api);
    yield takeLatest(actions.GET_FAILED_JOBS_REQUEST, watchGetFailedJobsRequest, api);
}
