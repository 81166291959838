export const GET_EVENT_APP_CLIENT_REQUEST = "GET_EVENT_APP_CLIENT_REQUEST";
export const GET_EVENT_APP_CLIENT_SUCCESS = "GET_EVENT_APP_CLIENT_SUCCESS";
export const GET_EVENT_APP_CLIENT_FAILURE = "GET_EVENT_APP_CLIENT_FAILURE";

export const getEventAppClientRequest = (eventId, clientId) => ({
    type: GET_EVENT_APP_CLIENT_REQUEST,
    params: {
        eventId,
        clientId
    }
})

export const getEventAppClientSuccess = (appClientRevision) => ({
    type: GET_EVENT_APP_CLIENT_SUCCESS,
    payload: {
        appClientRevision
    }
})

export const getEventAppClientFailure = (error) => ({
    type: GET_EVENT_APP_CLIENT_FAILURE,
    payload: {
        error
    }
})