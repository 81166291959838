
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';

function* getAwsS3Connections(api) {
    let result = yield call([api, api.get], `${Endpoints.systemConnections}/api/v1/s3`);
    return result.data;
}

function* createAwsS3Connection(api, connection) {
    let result = yield call([api, api.post], `${Endpoints.systemConnections}/api/v1/s3`, connection);
    return result.data;
}

function* getAwsS3Connection(api, connectionId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.systemConnections}/api/v1/s3/item/${connectionId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateAwsS3Connection(api, connectionId, updatedAwsS3Connection) {
    let response = yield call([api, api.put], `${Endpoints.systemConnections}/api/v1/s3/item/${connectionId}`, updatedAwsS3Connection);
    return response.data;
}

function* deleteAwsS3Connection(api, connectionId) {
    yield call([api, api.delete], `${Endpoints.systemConnections}/api/v1/s3/item/${connectionId}`);
}

function* getAwsS3ConnectionBuckets(api, connectionId) {
    let result = yield call([api, api.get], `${Endpoints.systemConnections}/api/v1/s3/item/${connectionId}/buckets`);
    return result.data;
}

function* getAwsS3ConnectionBucketContentList(api, connectionId, bucket, path) {
    let result = yield call([api, api.post], `${Endpoints.systemConnections}/api/v1/s3/item/${connectionId}/buckets/list`, { bucket, path });
    return result.data;
}

function* getAwsS3ConnectionBucketDownloadFile(api, connectionId, bucket, file) {
    let result = yield call([api, api.post], `${Endpoints.systemConnections}/api/v1/s3/item/${connectionId}/buckets/file/download`, { bucket, file });
    return result.data;
}

export function* getAwsS3ConnectionsRequest(api) {
    try {
        const result = yield call(getAwsS3Connections, api);
        yield put(actions.getAwsS3ConnectionsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "connections");
        yield put(actions.getAwsS3ConnectionsFailure(errorObject));
    }
}

export function* createAwsS3ConnectionsRequest(api, { connection }) {
    try {
        let createdAwsS3Connection = yield call(createAwsS3Connection, api, connection);
        yield put(actions.createAwsS3ConnectionSuccess(createdAwsS3Connection));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "connection");
        yield put(actions.createAwsS3ConnectionFailure(errorObject));
    }
}

export function* getAwsS3ConnectionRequest(api, { connectionId }) {
    try {
        const connection = yield call(getAwsS3Connection, api, connectionId);
        yield put(actions.getAwsS3ConnectionSuccess(connection));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "app client");
        yield put(actions.getAwsS3ConnectionFailure(errorObject));
    }
}

export function* updateAwsS3ConnectionRequest(api, { connectionId, updatedAwsS3Connection }) {
    try {
        const connection = yield call(updateAwsS3Connection, api, connectionId, updatedAwsS3Connection);
        yield put(actions.updateAwsS3ConnectionSuccess(connection));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "connection");
        yield put(actions.updateAwsS3ConnectionFailure(errorObject));
    }
}

export function* deleteAwsS3ConnectionRequest(api, { connectionId }) {
    try {
        yield call(deleteAwsS3Connection, api, connectionId);
        yield put(actions.deleteAwsS3ConnectionSuccess(connectionId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "connection");
        yield put(actions.deleteAwsS3ConnectionFailure(errorObject));
    }
}

export function* getAwsS3ConnectionBucketsRequest(api, { connectionId }) {
    try {
        let buckets = yield call(getAwsS3ConnectionBuckets, api, connectionId);
        yield put(actions.getAwsS3ConnectionBucketsSuccess(buckets));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "connection buckets");
        yield put(actions.getAwsS3ConnectionBucketsFailure(errorObject));
    }
}

export function* getAwsS3ConnectionBucketContentListRequest(api, { connectionId, bucket, path }) {
    try {
        let contentList = yield call(getAwsS3ConnectionBucketContentList, api, connectionId, bucket, path);
        yield put(actions.getAwsS3ConnectionBucketContentListSuccess(contentList));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "bucket content list");
        yield put(actions.getAwsS3ConnectionBucketContentListFailure(errorObject));
    }
}

export function* getAwsS3ConnectionBucketDownloadFileRequest(api, { connectionId, bucket, file }) {
    try {
        let downloadFile = yield call(getAwsS3ConnectionBucketDownloadFile, api, connectionId, bucket, file);
        yield put(actions.getAwsS3ConnectionBucketDownloadFileSuccess(downloadFile));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "bucket file");
        yield put(actions.getAwsS3ConnectionBucketDownloadFileFailure(errorObject));
    }
}

export function* watchGetAwsS3ConnectionsRequest(api, params) {
    yield call(getAwsS3ConnectionsRequest, api);
}

export function* watchCreateAwsS3ConnectionRequest(api, { params }) {
    yield call(createAwsS3ConnectionsRequest, api, params);
}

export function* watchGetAwsS3ConnectionRequest(api, { params }) {
    yield call(getAwsS3ConnectionRequest, api, params);
}

export function* watchUpdateAwsS3ConnectionRequest(api, { params }) {
    yield call(updateAwsS3ConnectionRequest, api, params);
}

export function* watchDeleteAwsS3ConnectionRequest(api, { params }) {
    yield call(deleteAwsS3ConnectionRequest, api, params);
}

export function* watchGetAwsS3ConnectionBucketsRequest(api, { params }) {
    yield call(getAwsS3ConnectionBucketsRequest, api, params);
}


export function* watchGetAwsS3ConnectionBucketContentListRequest(api, { params }) {
    yield call(getAwsS3ConnectionBucketContentListRequest, api, params);
}

export function* watchGetAwsS3ConnectionBucketDownloadFileRequest(api, { params }) {
    yield call(getAwsS3ConnectionBucketDownloadFileRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_AWS_S3_CONNECTIONS_REQUEST, watchGetAwsS3ConnectionsRequest, api);
    yield takeEvery(actions.CREATE_AWS_S3_CONNECTION_REQUEST, watchCreateAwsS3ConnectionRequest, api);
    yield takeLatest(actions.GET_AWS_S3_CONNECTION_REQUEST, watchGetAwsS3ConnectionRequest, api);
    yield takeLatest(actions.UPDATE_AWS_S3_CONNECTION_REQUEST, watchUpdateAwsS3ConnectionRequest, api);
    yield takeLatest(actions.DELETE_AWS_S3_CONNECTION_REQUEST, watchDeleteAwsS3ConnectionRequest, api);
    yield takeLatest(actions.GET_AWS_S3_CONNECTION_BUCKETS_REQUEST, watchGetAwsS3ConnectionBucketsRequest, api);
    yield takeLatest(actions.GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_REQUEST, watchGetAwsS3ConnectionBucketContentListRequest, api);
    yield takeLatest(actions.GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_REQUEST, watchGetAwsS3ConnectionBucketDownloadFileRequest, api);
}
