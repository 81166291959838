import { eventChannel, END } from 'redux-saga';
import { put, race, call, take, takeLeading, select } from "redux-saga/effects";
import * as actions from './actions';
import * as globalActions from '../actions';

let webSocket;

const getDataObjectsByDataSetIdResult = (state) => state.dataObjects.dataObjectsByDataSetIdResult;

function createEventChannel(tenantId, userId) {
    return eventChannel(emit => {
        function createWebSocketConnection() {
            webSocket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET}?tenantId=${tenantId}&userId=${userId}`);

            webSocket.onopen = () => {
                console.log("Opening User Websocket");
            };

            webSocket.onerror = error => {
                console.log("ERROR USER: ", error);
            };

            webSocket.onmessage = e => {
                return emit({ data: JSON.parse(e.data) })
            };

            webSocket.onclose = e => {
                if (e.code === 1005) {
                    console.log("USER WebSocket: closed");
                    emit(END);
                } else {
                    console.log('USER Socket is closed Unexpectedly. Reconnect will be attempted in 1 second.', e.reason);
                    setTimeout(() => {
                        createWebSocketConnection();
                    }, 1000);
                }
            };
        }
        createWebSocketConnection();

        return () => {
            console.log("Closing USER Websocket");
            webSocket.close();
        };
    });
}

export function* watchRegisterNotificationRequest({ params }) {
    const channel = yield call(createEventChannel, params.tenantId, params.userId);
    while (true) {
        try {
            const { notification, unRegisterNotification } = yield race({
                notification: take(channel),
                unRegisterNotification: take(actions.UNREGISTER_NOTIFICATION_REQUEST)
            })
            if (notification) {
                const { data } = notification;
                switch (data.action) {
                    case "TENANT_PROVISIONING":
                        yield put(globalActions.tenantProvisionProgress(data.message));
                        break;
                    case "TENANT_PROVISIONED":
                        yield put(globalActions.tenantProvisioned());
                        break;
                    case "JOB_EXECUTION_PROGRESS":
                        yield put(globalActions.jobExecutionProgress(data.message));
                        yield put(globalActions.dataSetJobExecutionProgress(data.message));
                        break;
                    case "DATA_SET_UPDATED":
                        yield put(globalActions.dataSetUpdatedEvent(data.message));
                        break;
                    case "JOB_DELETED":
                        yield put(globalActions.onJobDeleted(data.message));
                        break;
                    case "ADMIN_USER_LOGOUT":
                        yield put(globalActions.adminForceUserLogout(data.message));
                        break;
                    case "DATA_SET_RECORD_UPDATED":
                        let dataObjectsByDataSetIdResult = yield select(getDataObjectsByDataSetIdResult);
                        if (dataObjectsByDataSetIdResult && dataObjectsByDataSetIdResult.Items && dataObjectsByDataSetIdResult.Items.length > 0) {
                            let dataObjectIndex = dataObjectsByDataSetIdResult.Items.findIndex(item => item.dataObjectId === data.message.dataObjectId);
                            if (dataObjectIndex > -1) {
                                yield put(globalActions.getDataObjectSuccess(data.message));
                            }
                        }
                        break;
                }
            }
            else if (unRegisterNotification) {
                channel.close();
                return;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
}

export default function* () {
    yield takeLeading(actions.REGISTER_NOTIFICATION_REQUEST, watchRegisterNotificationRequest)
}