export const GET_EVENT_SYSTEM_CONNECTION_REQUEST = "GET_EVENT_SYSTEM_CONNECTION_REQUEST";
export const GET_EVENT_SYSTEM_CONNECTION_SUCCESS = "GET_EVENT_SYSTEM_CONNECTION_SUCCESS";
export const GET_EVENT_SYSTEM_CONNECTION_FAILURE = "GET_EVENT_SYSTEM_CONNECTION_FAILURE";

export const getEventSystemConnectionRequest = (eventId, connectionId) => ({
    type: GET_EVENT_SYSTEM_CONNECTION_REQUEST,
    params: {
        eventId,
        connectionId
    }
})

export const getEventSystemConnectionSuccess = (connectionRevision) => ({
    type: GET_EVENT_SYSTEM_CONNECTION_SUCCESS,
    payload: {
        connectionRevision
    }
})

export const getEventSystemConnectionFailure = (error) => ({
    type: GET_EVENT_SYSTEM_CONNECTION_FAILURE,
    payload: {
        error
    }
})