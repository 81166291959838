import React from "react";
import { Switch, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";

const { Column } = Table;

const SystemConnectionAwsS3List = ({ connectionList, onAddConnection, onViewConnection, onEditConnection, onActivateDeactivateConnection, onViewConnectionHistory, onDeleteConnection, permission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }

    return <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
        <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={true}
                content={
                    <Table size="small"
                        rowKey="connectionId"
                        dataSource={connectionList}
                        pagination={false}
                        scroll={{ y: "100vh" }}
                        className="container-height-100">
                        <Column
                            width="6em"
                            render={(value, connection, index) => <>
                                <Icon
                                    disabled={!permission.canEdit}
                                    color='blue'
                                    name='pencil'
                                    className="action-cursor"
                                    onClick={() => onEditConnection(connection.connectionId)} />
                                <Icon
                                    disabled={!permission.canView}
                                    color='blue'
                                    name='eye'
                                    className="action-cursor"
                                    onClick={() => onViewConnection(connection.connectionId)} />
                                <Icon
                                    disabled={!permission.canView}
                                    color='blue'
                                    name='history'
                                    className="action-cursor"
                                    onClick={() => onViewConnectionHistory(connection.connectionId)} />
                            </>}
                        />
                        <Column
                            dataIndex="name"
                            title="Name"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, connection, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="20%"
                            dataIndex="description"
                            title="Description"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, connection, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="12%"
                            dataIndex="businessAreaCount"
                            title="Business Areas"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, connection, index) => <Tooltip placement="topLeft" title={value || 0}>{value || 0}</Tooltip>}
                        />
                        <Column
                            width="15%"
                            dataIndex="createdDate"
                            title="Created Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, connection, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="15%"
                            dataIndex="updatedDate"
                            title="Updated Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, connection, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="10%"
                            dataIndex="active"
                            title={"Active"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, connection, index) => {
                                return <Switch checked={value} disabled={!permission.canEdit} onClick={(checked, event) => onActivateDeactivateConnection(connection, checked)}></Switch>;
                            }}
                        />
                        <Column
                            width="5%"
                            render={(value, connection, index) => {
                                return (
                                    <Icon
                                        disabled={!permission.canDelete}
                                        color='blue'
                                        name='trash alternate outline'
                                        className="action-cursor"
                                        onClick={() => onDeleteConnection(connection)} />
                                );
                            }}
                        />
                    </Table>}
                footer={
                    <Row className="table-footer-row">
                        <Col span={24} className="footer-right-column">
                            <Button type="primary"
                                disabled={!permission.canAdd}
                                onClick={onAddConnection}>
                                <PlusOutlined /> Add Connection
                            </Button>
                        </Col>
                    </Row>}
                footerStyle={{ paddingTop: "1rem" }}>

            </FullHeightContainerLayout>
        </Col>
    </Row>
};

export default SystemConnectionAwsS3List;