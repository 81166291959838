export const GET_DATA_SET_HISTORY_REQUEST = "GET_DATA_SET_HISTORY_REQUEST";
export const GET_DATA_SET_HISTORY_SUCCESS = "GET_DATA_SET_HISTORY_SUCCESS";
export const GET_DATA_SET_HISTORY_FAILURE = "GET_DATA_SET_HISTORY_FAILURE";

export const GET_DATA_SET_EVENTS_REQUEST = "GET_DATA_SET_EVENTS_REQUEST";
export const GET_DATA_SET_EVENTS_SUCCESS = "GET_DATA_SET_EVENTS_SUCCESS";
export const GET_DATA_SET_EVENTS_FAILURE = "GET_DATA_SET_EVENTS_FAILURE";


export const getDataSetHistoryRequest = (businessAreaId, schemaId, dataSetId) => ({
    type: GET_DATA_SET_HISTORY_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId
    }
})

export const getDataSetHistorySuccess = (dataSetHistoryResult) => ({
    type: GET_DATA_SET_HISTORY_SUCCESS,
    payload: {
        dataSetHistoryResult
    }
})

export const getDataSetHistoryFailure = (error) => ({
    type: GET_DATA_SET_HISTORY_FAILURE,
    payload: {
        error
    }
})

export const getDataSetEventsRequest = (dataSetId) => ({
    type: GET_DATA_SET_EVENTS_REQUEST,
    params: {
        dataSetId
    }
})

export const getDataSetEventsSuccess = (dataSetEventsResult) => ({
    type: GET_DATA_SET_EVENTS_SUCCESS,
    payload: {
        dataSetEventsResult
    }
})

export const getDataSetEventsFailure = (error) => ({
    type: GET_DATA_SET_EVENTS_FAILURE,
    payload: {
        error
    }
})