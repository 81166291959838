import React from "react";
import { Switch, Space, Button, Typography, Checkbox, Row, Col, Tooltip, Input } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { AdminConsoleHeader, FullHeightContainerLayout } from "components";
import { AdminConsoleTenants, AdminConsoleJobs } from 'containers';

const { Title } = Typography;
const { Search } = Input;

const AdminConsole = () => {
    return <FullHeightContainerLayout
        showHeader={true}
        showFooter={false}
        content={<>
            <Row style={{ height: "50%" }}>
                <Col span={24} style={{ height: "100%" }}>
                    <AdminConsoleTenants></AdminConsoleTenants>
                </Col>
            </Row>
            <Row style={{ height: "50%" }}>
                <Col span={24} style={{ height: "100%" }}>
                    <AdminConsoleJobs></AdminConsoleJobs>
                </Col>
            </Row>
        </>}
        header={<AdminConsoleHeader></AdminConsoleHeader>}>
    </FullHeightContainerLayout>
};

export default AdminConsole;