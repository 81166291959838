import {
    GET_EVENT_SYSTEM_KEY_SUCCESS,
    GET_EVENT_SYSTEM_KEY_FAILURE
} from "./actions";

const initialState = {
    getEventSystemKeysResult: {
        success: true
    },
    systemKeyRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_SYSTEM_KEY_SUCCESS:
            return { ...state, getEventSystemKeysResult: { success: true }, systemKeyRevision: action.payload.systemKeyRevision };
        case GET_EVENT_SYSTEM_KEY_FAILURE:
            return {
                ...state, getEventSystemKeysResult: { success: false, ...action.payload.error }, systemKeyRevision: null
            };
        default:
            return state;
    }
}