import React from "react";
import { Typography, Row, Col, Tooltip, Input } from "antd";

const { Title } = Typography;
const { Search } = Input;

const AdminConsoleHeader = () => {
    return <Row>
        <Col><Title level={3}>Admin Console</Title></Col>
        <Col style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <Tooltip title="Search for a tenant via anything">
                <Search
                    placeholder="Search"
                    allowClear
                    style={{ maxWidth: "50rem" }}
                />
            </Tooltip>
        </Col>
    </Row>
};

export default AdminConsoleHeader;