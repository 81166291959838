import { Typography } from "antd";
import { PageLayout } from "components";
import { DataObjectSearch } from "containers";

const SearchPage = () => {
    return (
        <PageLayout
            mainContent={({
                setMainColumnSpan,
                setBreadcrumbNavigationItems,
            }: IPageLayoutMainContentProps) => (
                <DataObjectSearch
                    setMainColumnSpan={setMainColumnSpan}
                    setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}
                />
            )}
            helpContent={() => (
                <Typography>We're working on this documentation.</Typography>
            )}
        />
    );
};

export default SearchPage;
