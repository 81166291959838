import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class SystemConnectionAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectRecordDetail: null
        }
    }

    componentWillMount() {
        this.props.getSystemConnectionEvents(this.props.connection.connectionId);
        this.setState({
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetConnectionEventsResultUpdate(prevProps);
    }

    onGetConnectionEventsResultUpdate = (prevProps) => {
        if (this.props.getConnectionEventsResult && this.props.getConnectionEventsResult !== prevProps.getConnectionEventsResult) {
            if (!this.props.getConnectionEventsResult.success) {
                if (this.props.getConnectionEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connection events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    this.state.fetchingEvents
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message="Getting connection events...">
                        </LoadingOverlay>
                        :
                        <EventList eventList={this.props.connectionEventsResult.Items}></EventList>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getConnectionEventsResult: state.systemConnectionAudit.getConnectionEventsResult,
        connectionEventsResult: state.systemConnectionAudit.connectionEventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSystemConnectionEvents: (connectionId) => dispatch(actions.getSystemConnectionEventsRequest(connectionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemConnectionAudit));