import {
    GET_DUPLICATE_MERGE_RULES_SUCCESS,
    GET_DUPLICATE_MERGE_RULES_FAILURE,

    SAVE_DUPLICATE_MERGE_RULES_SUCCESS,
    SAVE_DUPLICATE_MERGE_RULES_FAILURE
} from "./actions";

const initialState = {
    getDuplicateMergeRulesResult: {
        success: true
    },
    duplicateMergeRulesResult: {
        Items: []
    },
    saveDuplicateMergeRulesResult: {
        success: true
    },
    saveDuplicateMergeRules: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DUPLICATE_MERGE_RULES_SUCCESS:
            return {
                ...state,
                getDuplicateMergeRulesResult:
                {
                    success: true
                },
                duplicateMergeRulesResult: action.payload.result
            };
        case GET_DUPLICATE_MERGE_RULES_FAILURE:
            return {
                ...state,
                getDuplicateMergeRulesResult:
                {
                    success: false,
                    ...action.payload.error
                },
                duplicateMergeRulesResult:
                {
                    Items: []
                }
            };
        case SAVE_DUPLICATE_MERGE_RULES_SUCCESS:
            return {
                ...state,
                saveDuplicateMergeRulesResult:
                {
                    success: true
                },
                saveDuplicateMergeRules: action.payload.result
            };
        case SAVE_DUPLICATE_MERGE_RULES_FAILURE:
            return {
                ...state,
                saveDuplicateMergeRulesResult:
                {
                    success: false,
                    ...action.payload.error
                },
                saveDuplicateMergeRules: []
            };
        default:
            return state;
    }
};
