import {
    GET_EVENT_SCHEMA_MODEL_SUCCESS,
    GET_EVENT_SCHEMA_MODEL_FAILURE
} from "./actions";

const initialState = {
    getEventSchemaModelResult: {
        success: true
    },
    schemaModelRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_SCHEMA_MODEL_SUCCESS:
            return { ...state, getEventSchemaModelResult: { success: true }, schemaModelRevision: action.payload };
        case GET_EVENT_SCHEMA_MODEL_FAILURE:
            return {
                ...state, getEventSchemaModelResult: { success: false, ...action.payload }, schemaModelRevision: null
            };
        default:
            return state;
    }
};
