import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Row, Col, Space } from 'antd';
import { TenantUserList, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea, OkCancelConfirmationBox } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

const { Title } = Typography;

class AdminTenantUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingUsers: false,
            permissionDenied: false,
            busy: false,
            showPromoteToAdminWarning: false,
            busyMessage: "Fetching users..."
        }
    }

    componentWillMount() {
        this.setState({
            fetchingUsers: true
        })
        this.props.getTenantUsers(this.props.tenantId);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getTenantUsersResultChanged(prevProps);
        this.updateTenantAdminResultChanged(prevProps);
    }

    componentWillUnmount() {
    }

    getTenantUsersResultChanged = (prevProps) => {
        if (this.props.getTenantUsersResult && this.props.getTenantUsersResult !== prevProps.getTenantUsersResult) {
            if (!this.props.getTenantUsersResult.success) {
                if (this.props.getTenantUsersResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get tenant users at this moment.");
                }
            }
            this.setState({
                fetchingUsers: false,
            });
        }
    }

    updateTenantAdminResultChanged = (prevProps) => {
        if (this.props.updateTenantAdminResult && this.props.updateTenantAdminResult !== prevProps.updateTenantAdminResult) {
            if (!this.props.updateTenantAdminResult.success) {
                if (this.props.updateTenantAdminResult.code === "PERMISSION_DENIED") {
                    showError("Permission Denied");
                }
                else {
                    showError("Could not able to update tenant users at this moment.");
                }
            }
            else {
                showSuccess("Account Admin Role Assigned");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onChangeAdminClick = (userId) => {
        this.setState({
            newAdminUserId: userId,
            showPromoteToAdminWarning: true
        })
    }

    fetchInProgress = () => {
        let isBusy = this.state.fetchingUsers;
        return isBusy;
    }

    isBusy = () => {
        return this.state.busy || this.fetchInProgress();
    }

    onPromoteToAccountAdminCancel = () => {
        this.setState({
            newAdminUserId: null,
            showPromoteToAdminWarning: false
        })
    }

    onPromoteToAccountAdminConfimed = () => {
        this.props.updateTenantAdmin(this.props.tenantId, this.state.newAdminUserId);
        this.setState({
            busy: true,
            busyMessage: "Updating admin...",
            showPromoteToAdminWarning: false,
            newAdminUserId: null
        });
    }

    render() {
        return <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <Row style={{ height: this.fetchInProgress() ? "15vh" : "unset" }}>
                    <Col span={24}>
                        <LoadingOverlay
                            busy={this.isBusy()}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        <>
                            {
                                !this.fetchInProgress()
                                    ?
                                    <>
                                        {
                                            this.state.permissionDenied
                                                ?
                                                <ProhibitedArea></ProhibitedArea>
                                                :
                                                <>
                                                    <OkCancelConfirmationBox
                                                        show={this.state.showPromoteToAdminWarning}
                                                        title={<Space><ExclamationCircleTwoTone twoToneColor={"orange"}/> <>{"Promote to Account Admin"}</></Space>}
                                                        message="Only one user can be assigned the role of the account admin at any one time - you are advised to not use a generic or shared account for this admin functions."
                                                        onCancel={this.onPromoteToAccountAdminCancel}
                                                        onOk={this.onPromoteToAccountAdminConfimed}>
                                                    </OkCancelConfirmationBox>
                                                    <TenantUserList
                                                        userList={(this.props.tenantUsers && this.props.tenantUsers.Items) || []}
                                                        onChangeAdmin={this.onChangeAdminClick}>
                                                    </TenantUserList>
                                                </>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                        </>
                    </Col>
                </Row>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getTenantUsersResult: state.adminConsole.getTenantUsersResult,
        tenantUsers: state.adminConsole.tenantUsers,
        updateTenantAdminResult: state.adminConsole.updateTenantAdminResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantUsers: (tenantId) => dispatch(actions.getTenantUsersRequest(tenantId)),
        updateTenantAdmin: (tenantId, userId) => dispatch(actions.updateTenantAdminRequest(tenantId, userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminTenantUsers));