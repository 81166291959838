import React from 'react';
import { UserSignup } from 'containers';

const UserSignupPage = (props) => {
    return (
        <div className="flex-row-container">
            <div className="row-container-column-percent-50">
                <UserSignup {...props}></UserSignup>
            </div>
        </div>
    )
}

export default UserSignupPage