import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";
import getDslExistClauseValue from "./getDslExistClauseValue";
import getDslMatchClauseValue from "./getDslMatchClauseValue";
import getDslPrefixClauseValue from "./getDslPrefixClauseValue";
import getDslRangeClauseValue from "./getDslRangeClauseValue";
import getDslRegexpClauseValue from "./getDslRegexpClauseValue";
import getDslTermClauseValue from "./getDslTermClauseValue";
import getDslWildcardClauseValue from "./getDslWildcardClauseValue";

export default function convertDsltoQueryBuilder(
    dslQuery: IDslCompoundQueryClause,
    fields: ISearchProperty[],
): RuleGroupType {
    // Check for Clause type and return type
    function getDslClauseValue(
        context: IDslQueryContext,
        clause: IDslLeafQueryClause,
        fields: ISearchProperty[],
    ): RuleGroupArray<RuleGroupType<RuleType, string>, RuleType> {
        if (clause.bool) {
            // BOOL
            const _boolQuery = getDslBoolValue(clause.bool);
            return [_boolQuery];
        } else if (clause.match) {
            // MATCH
            return getDslMatchClauseValue(context, clause.match, fields);
        } else if (clause.exists) {
            // EXISTS
            return getDslExistClauseValue(context, clause.exists, fields);
        } else if (clause.term) {
            // TERM
            return getDslTermClauseValue(context, clause.term, fields);
        } else if (clause.range) {
            // RANGE
            return getDslRangeClauseValue(context, clause.range, fields);
        } else if (clause.regexp) {
            // Regexp
            return getDslRegexpClauseValue(context, clause.regexp, fields);
        } else if (clause.wildcard) {
            // Wildcard
            return getDslWildcardClauseValue(context, clause.wildcard, fields);
        } else if (clause.prefix) {
            // Prefix
            return getDslPrefixClauseValue(context, clause.prefix, fields);
        }

        return [];
    }

    // Check for bool and return group
    function getDslBoolValue(
        contextObject?: IDslQueryContextObject,
    ): RuleGroupType<RuleType, string> {
        const builderQuery: RuleGroupType<RuleType, string> = {
            id: uuidv4(),
            combinator: "and",
            rules: [],
        };

        if (!contextObject) {
            return builderQuery;
        }

        let leafies: {
            context: IDslQueryContext;
            nodes: IDslLeafQueryClause[];
        }[] = [];

        // MUST
        if (contextObject.must) {
            if (Array.isArray(contextObject.must)) {
                leafies.push({
                    context: "must",
                    nodes: contextObject.must,
                });
            } else {
                leafies.push({
                    context: "must",
                    nodes: [contextObject.must],
                });
            }
        }

        // MUST NOT
        if (contextObject.must_not) {
            if (Array.isArray(contextObject.must_not)) {
                leafies.push({
                    context: "must_not",
                    nodes: contextObject.must_not,
                });
            } else {
                leafies.push({
                    context: "must_not",
                    nodes: [contextObject.must_not],
                });
            }
        }

        // SHOULD
        if (contextObject.should) {
            if (leafies.length === 0) {
                builderQuery.combinator = "or";
                builderQuery.not = false;
            }

            if (Array.isArray(contextObject.should)) {
                leafies.push({
                    context: "should",
                    nodes: contextObject.should,
                });
            } else {
                leafies.push({
                    context: "should",
                    nodes: [contextObject.should],
                });
            }
        }

        // FILTER
        if (contextObject.filter) {
            if (Array.isArray(contextObject.filter)) {
                leafies.push({
                    context: "filter",
                    nodes: contextObject.filter,
                });
            } else {
                leafies.push({
                    context: "filter",
                    nodes: [contextObject.filter],
                });
            }
        }

        if (leafies.length > 0) {
            leafies.forEach((leaf) => {
                leaf.nodes.forEach((clause) => {
                    builderQuery.rules.push(
                        ...getDslClauseValue(leaf.context, clause, fields),
                    );
                });
            });
        }

        return builderQuery;
    }

    // Single clause, not bool and context
    if (!dslQuery.bool) {
        const builderQuery: RuleGroupType<RuleType, string> = {
            id: uuidv4(),
            combinator: "and",
            rules: [],
        };
        builderQuery.rules.push(...getDslClauseValue("must", dslQuery, fields));
        return builderQuery;
    }

    // Root level query
    return getDslBoolValue(dslQuery.bool);
}
