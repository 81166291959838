
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* getCDCDestinations(api) {
    let result = yield call([api, api.get], `${Endpoints.changeDataCapture}/api/v1/destinations`);
    return result.data;
}

function* createCDCDestination(api, cdcDestination) {
    let result = yield call([api, api.post], `${Endpoints.changeDataCapture}/api/v1/destinations`, cdcDestination);
    return result.data;
}

function* getCDCDestination(api, destinationId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.changeDataCapture}/api/v1/destinations/item/${destinationId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateCDCDestination(api, destinationId, updatedCDCDestination) {
    let response = yield call([api, api.put], `${Endpoints.changeDataCapture}/api/v1/destinations/item/${destinationId}`, updatedCDCDestination);
    return response.data;
}

function* deleteCDCDestination(api, destinationId) {
    yield call([api, api.delete], `${Endpoints.changeDataCapture}/api/v1/destinations/item/${destinationId}`);
}

export function* getCDCDestinationPermission(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/cdcdestinations`, { tenantid: tenantId });
    return result.data;
}

export function* getCDCDestinationsRequest(api) {
    try {
        const result = yield call(getCDCDestinations, api);
        yield put(actions.getCDCDestinationsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "destinations");
        yield put(actions.getCDCDestinationsFailure(errorObject));
    }
}

export function* createCDCDestinationsRequest(api, { cdcDestination }) {
    try {
        let createdCDCDestination = yield call(createCDCDestination, api, cdcDestination);
        yield put(actions.createCDCDestinationSuccess(createdCDCDestination));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "destination");
        yield put(actions.createCDCDestinationFailure(errorObject));
    }
}

export function* getCDCDestinationRequest(api, { cdcDestinationId }) {
    try {
        const cdcDestination = yield call(getCDCDestination, api, cdcDestinationId);
        yield put(actions.getCDCDestinationSuccess(cdcDestination));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "destination");
        yield put(actions.getCDCDestinationFailure(errorObject));
    }
}

export function* updateCDCDestinationRequest(api, { cdcDestinationId, updatedCDCDestination }) {
    try {
        const cdcDestination = yield call(updateCDCDestination, api, cdcDestinationId, updatedCDCDestination);
        yield put(actions.updateCDCDestinationSuccess(cdcDestination));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "destination");
        yield put(actions.updateCDCDestinationFailure(errorObject));
    }
}

export function* deleteCDCDestinationRequest(api, { cdcDestinationId }) {
    try {
        yield call(deleteCDCDestination, api, cdcDestinationId);
        yield put(actions.deleteCDCDestinationSuccess(cdcDestinationId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "destination");
        yield put(actions.deleteCDCDestinationFailure(errorObject));
    }
}

export function* getCDCDestinationPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        let permissionResponse = yield call(getCDCDestinationPermission, api, tenant.tenantId, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getCDCDestinationPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user cdc destination permission");
        yield put(actions.getCDCDestinationPermissionFailure(errorObject));
    }
}

export function* watchGetCDCDestinationsRequest(api, params) {
    yield call(getCDCDestinationsRequest, api);
}

export function* watchCreateCDCDestinationRequest(api, { params }) {
    yield call(createCDCDestinationsRequest, api, params);
}

export function* watchGetCDCDestinationRequest(api, { params }) {
    yield call(getCDCDestinationRequest, api, params);
}

export function* watchUpdateCDCDestinationRequest(api, { params }) {
    yield call(updateCDCDestinationRequest, api, params);
}

export function* watchDeleteCDCDestinationRequest(api, { params }) {
    yield call(deleteCDCDestinationRequest, api, params);
}

export function* watchGetCDCDestinationPermissionRequest(api, { params }) {
    yield call(getCDCDestinationPermissionRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_CDC_DESTINATIONS_REQUEST, watchGetCDCDestinationsRequest, api);
    yield takeEvery(actions.CREATE_CDC_DESTINATION_REQUEST, watchCreateCDCDestinationRequest, api);
    yield takeLatest(actions.GET_CDC_DESTINATION_REQUEST, watchGetCDCDestinationRequest, api);
    yield takeLatest(actions.UPDATE_CDC_DESTINATION_REQUEST, watchUpdateCDCDestinationRequest, api);
    yield takeLatest(actions.DELETE_CDC_DESTINATION_REQUEST, watchDeleteCDCDestinationRequest, api);
    yield takeEvery(actions.GET_CDC_DESTINATION_PERMISSIONS_REQUEST, watchGetCDCDestinationPermissionRequest, api);
}
