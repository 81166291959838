
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;

function* getEventDomainDataList(api, tenantId, eventId, itemOperation, pageKey) {
    try {
        let params = {
            operation: itemOperation
        };
        if (pageKey) {
            params.pageKey = pageKey
        }
        
        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/domaindata`, { tenantid: tenantId }, { params });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventDomainDataListRequest(api, { eventId, itemOperation, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        const dataDomain = yield call(getEventDomainDataList, api, tenant.tenantId, eventId, itemOperation, pageKey);
        yield put(actions.getEventDomainDataListSuccess(dataDomain));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event domain data list");
        yield put(actions.getEventDomainDataListFailure(errorObject));
    }
}

export function* watchGetEventDomainDataListRequest(api, { params }) {
    yield call(getEventDomainDataListRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_DOMAIN_DATA_LIST_REQUEST, watchGetEventDomainDataListRequest, api);
}