import {
    CREATE_DATA_SET_SUCCESS,
    CREATE_DATA_SET_FAILURE,

    GET_DATA_SET_BY_SCHEMA_ID_SUCCESS,
    GET_DATA_SET_BY_SCHEMA_ID_FAILURE,

    GET_RECENT_DATA_SET_SUCCESS,
    GET_RECENT_DATA_SET_FAILURE,

    GET_DATA_SET_BY_ID_SUCCESS,
    GET_DATA_SET_BY_ID_FAILURE,

    GET_DATA_SET_BY_NAME_SUCCESS,
    GET_DATA_SET_BY_NAME_FAILURE,

    GET_DATA_SET_FIELD_INSIGHTS_SUCCESS,
    GET_DATA_SET_FIELD_INSIGHTS_FAILURE,

    DELETE_DATA_SET_SUCCESS,
    DELETE_DATA_SET_FAILURE,

    UPDATE_DATA_SET_SUCCESS,
    UPDATE_DATA_SET_FAILURE,

    GET_USER_DATA_SET_PERMISSION_SUCCESS,
    GET_USER_DATA_SET_PERMISSION_FAILURE,

    GET_DATA_SET_JOBS_SUCCESS,
    GET_DATA_SET_JOBS_FAILURE,

    DATA_SET_JOB_EXECUTION_PROGRESS,
    DATA_SET_UPDATED_EVENT,

    UNDO_JOB_SUCCESS,
    UNDO_JOB_FAILURE,

    INDEX_DATA_SET_SUCCESS,
    INDEX_DATA_SET_FAILURE,

    EXPORT_DATA_SET_TO_FILE_SUCCESS,
    EXPORT_DATA_SET_TO_FILE_FAILURE,

    DOWNLOAD_EXPORT_DATA_SET_SUCCESS,
    DOWNLOAD_EXPORT_DATA_SET_FAILURE,

    EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_SUCCESS,
    EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_FAILURE,

    GET_USER_EXPORT_JOB_PERMISSION_SUCCESS,
    GET_USER_EXPORT_JOB_PERMISSION_FAILURE
} from "./actions";

import {
    GET_USER_BUSINESS_AREAS_SUCCESS
} from '../home/actions';

const initialState = {
    getSchemaDataSetResult: {
        success: true
    },
    schemaDataSetResult: {
        Items: []
    },
    getRecentDataSetResult: {
        success: true
    },
    recentDataSetListResult: {
        Items: []
    },
    createDataSetResult: {
        success: true
    },
    getDataSetByIdResult: {
        success: true
    },
    getDataSetByNameResult: {
        success: true
    },
    dataSet: null,
    deleteDataSetResult: {
        success: true
    },
    updateDataSetResult: {
        success: true
    },
    getUserDataSetPermissionResult: {
        success: true
    },
    permission: null,
    getJobsResult: {
        success: true
    },
    jobsResult: {
        Items: []
    },
    undoJobResult: {
        success: true
    },
    getDataSetFieldInsightsResult: {
        success: true
    },
    dataSetFieldInsightsResult: {
        Items: []
    },
    indexDataSetResult: {
        success: true
    },
    exportDataSetToFileResult: {
        success: true
    },
    downloadExportDataSetResult: {
        success: true
    },
    downloadExportDataSet: null,
    exportDataSetToSystemConnectionResult: {
        success: true
    },
    getUserExportJobPermissionResult: {
        success: true
    },
    exportPermission: null
};

function findUndoableJob(jobs) {
    let undoJobFound = false;
    for (let job of jobs) {
        if (job.status !== "CANCELLED" && job.status !== "FAILED" && job.status !== "COMPLETED") {
            break;
        }
        if (!undoJobFound && job.status === "COMPLETED" && !job.undo) {
            job.canUndo = true;
            break;
        }
    }
}

export default (state = initialState, action) => {
    let schemaDataSetResult = {
        Items: []
    };
    let jobsResult = null;
    let jobItems = null;
    let recentDataSetListResult = {
        Items: []
    }
    switch (action.type) {
        case CREATE_DATA_SET_SUCCESS:
            return { ...state, createDataSetResult: { success: true } };
        case CREATE_DATA_SET_FAILURE:
            return { ...state, createDataSetResult: { success: false, ...action.payload.error } };
        case GET_DATA_SET_BY_ID_SUCCESS:
            return { ...state, getDataSetByIdResult: { success: true }, dataSet: action.payload.dataSet };
        case GET_DATA_SET_BY_ID_FAILURE:
            return { ...state, getDataSetByIdResult: { success: false, ...action.payload.error }, dataSet: null };
        case GET_DATA_SET_BY_NAME_SUCCESS:
            return { ...state, getDataSetByNameResult: { success: true }, dataSet: action.payload.dataSet };
        case GET_DATA_SET_BY_NAME_FAILURE:
            return { ...state, getDataSetByNameResult: { success: false, ...action.payload.error }, dataSet: null };
        case GET_DATA_SET_BY_SCHEMA_ID_SUCCESS:
            return { ...state, getSchemaDataSetResult: { success: true }, schemaDataSetResult: action.payload.schemaDataSetResult };
        case GET_DATA_SET_BY_SCHEMA_ID_FAILURE:
            return { ...state, getSchemaDataSetResult: { success: false, ...action.payload.error }, schemaDataSetResult: { Items: [] } };
        case GET_RECENT_DATA_SET_SUCCESS:
            return { ...state, getRecentDataSetResult: { success: true }, recentDataSetListResult: action.payload };
        case GET_RECENT_DATA_SET_FAILURE:
            return {
                ...state, getRecentDataSetResult: { success: false, ...action.payload.error }, recentDataSetListResult: {
                    Items: []
                }
            };
        case GET_USER_BUSINESS_AREAS_SUCCESS:
            let businessAreasDict = action.payload.businessAreasDict;
            recentDataSetListResult = state.recentDataSetListResult;
            if (recentDataSetListResult.Items.length > 0) {
                recentDataSetListResult = { ...state.recentDataSetListResult };
                recentDataSetListResult.Items = [...recentDataSetListResult.Items];
                for (let i = 0; i < recentDataSetListResult.Items.length; i++) {
                    let recentDataSet = recentDataSetListResult.Items[i];
                    if (businessAreasDict && businessAreasDict[recentDataSet.businessAreaId]) {
                        recentDataSetListResult.Items[i] = { ...recentDataSet, businessAreaName: businessAreasDict[recentDataSet.businessAreaId].name };
                    }
                }
            }
            return { ...state, recentDataSetListResult };
        case DELETE_DATA_SET_SUCCESS:
            schemaDataSetResult = { ...state.schemaDataSetResult };
            let deletedDataSetIndex = schemaDataSetResult.Items.findIndex(dataSet => dataSet.dataSetId === action.payload.dataSetId);
            if (deletedDataSetIndex !== -1) {
                schemaDataSetResult.Items = [...schemaDataSetResult.Items];
                schemaDataSetResult.Items.splice(deletedDataSetIndex, 1);
            }
            let dataSet = state.dataSet;
            if (dataSet && action.payload.dataSetId === dataSet.dataSetId) {
                dataSet = null;
            }
            return { ...state, deleteDataSetResult: { success: true }, schemaDataSetResult, dataSet };
        case GET_DATA_SET_FIELD_INSIGHTS_SUCCESS:
            return { ...state, getDataSetFieldInsightsResult: { success: true }, dataSetFieldInsightsResult: action.payload.dataSetFieldInsightsResult };
        case GET_DATA_SET_FIELD_INSIGHTS_FAILURE:
            return { ...state, getDataSetFieldInsightsResult: { success: false, ...action.payload.error }, dataSetFieldInsightsResult: { Items: [] } };
        case DELETE_DATA_SET_FAILURE:
            return { ...state, deleteDataSetResult: { success: false, ...action.payload.error } };
        case UPDATE_DATA_SET_SUCCESS:
            return { ...state, updateDataSetResult: { success: true }, dataSet: action.payload.dataSet };
        case UPDATE_DATA_SET_FAILURE:
            return { ...state, updateDataSetResult: { success: false, ...action.payload.error } };
        case GET_USER_DATA_SET_PERMISSION_SUCCESS:
            return { ...state, getUserDataSetPermissionResult: { success: true }, permission: action.payload.permission };
        case GET_USER_DATA_SET_PERMISSION_FAILURE:
            return { ...state, getUserDataSetPermissionResult: { success: false, ...action.payload.error }, permission: null };
        case GET_DATA_SET_JOBS_SUCCESS:
            jobsResult = action.payload.jobResult;
            findUndoableJob(jobsResult.Items);
            return { ...state, getJobsResult: { success: true }, jobsResult };
        case GET_DATA_SET_JOBS_FAILURE:
            return {
                ...state, getJobsResult: { success: false, ...action.payload.error }, jobsResult: {
                    Items: []
                }
            };
        case DATA_SET_JOB_EXECUTION_PROGRESS:
            jobsResult = { ...state.jobsResult };
            jobItems = [...jobsResult.Items];
            jobsResult.Items = jobItems;
            let updatedJobIndex = jobsResult.Items.findIndex(job => job.jobId === action.payload.message.jobId);
            if (updatedJobIndex > -1) {
                jobsResult.Items[updatedJobIndex] = { ...jobsResult.Items[updatedJobIndex], ...action.payload.message };
            }
            findUndoableJob(jobsResult.Items);
            return { ...state, jobsResult };
        case DATA_SET_UPDATED_EVENT:
            recentDataSetListResult = { ...state.recentDataSetListResult };
            recentDataSetListResult.Items = [...recentDataSetListResult.Items];
            let updatedDataSetIndex = recentDataSetListResult.Items.findIndex(dataSet => dataSet.dataSetId === action.payload.dataSet.dataSetId);
            if (updatedDataSetIndex > -1) {
                recentDataSetListResult.Items[updatedDataSetIndex] = { ...recentDataSetListResult.Items[updatedDataSetIndex], ...action.payload.dataSet };
            }
            return { ...state, recentDataSetListResult };
        case UNDO_JOB_SUCCESS:
            jobsResult = { ...state.jobsResult };
            jobItems = [...jobsResult.Items];
            jobsResult.Items = jobItems;
            let undoJobIndex = jobsResult.Items.findIndex(job => job.jobId === action.payload.updatedJob.jobId);
            if (undoJobIndex > -1) {
                jobsResult.Items[undoJobIndex] = action.payload.updatedJob;
            }
            findUndoableJob(jobsResult.Items);
            return { ...state, undoJobResult: { success: true }, jobsResult };
        case UNDO_JOB_FAILURE:
            return {
                ...state, undoJobResult: { success: false, ...action.payload.error }
            };
        case INDEX_DATA_SET_SUCCESS:
            return {
                ...state, indexDataSetResult: { success: true }
            };
        case INDEX_DATA_SET_FAILURE:
            return {
                ...state, indexDataSetResult: { success: false, ...action.payload.error }
            };
        case EXPORT_DATA_SET_TO_FILE_SUCCESS:
            return {
                ...state, exportDataSetToFileResult: { success: true }
            };
        case EXPORT_DATA_SET_TO_FILE_FAILURE:
            return {
                ...state, exportDataSetToFileResult: { success: false, ...action.payload.error }
            };
        case DOWNLOAD_EXPORT_DATA_SET_SUCCESS:
            return {
                ...state, downloadExportDataSetResult: { success: true }, downloadExportDataSet: action.payload.result
            };
        case DOWNLOAD_EXPORT_DATA_SET_FAILURE:
            return {
                ...state, downloadExportDataSetResult: { success: false, ...action.payload.error }, downloadExportDataSet: null
            };
        case EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_SUCCESS:
            return {
                ...state, exportDataSetToSystemConnectionResult: { success: true }
            };
        case EXPORT_DATA_SET_TO_SYSTEM_CONNECTION_FAILURE:
            return {
                ...state, exportDataSetToSystemConnectionResult: { success: false, ...action.payload.error }
            };
        case GET_USER_EXPORT_JOB_PERMISSION_SUCCESS:
            return { ...state, getUserExportJobPermissionResult: { success: true }, exportPermission: action.payload.permission };
        case GET_USER_EXPORT_JOB_PERMISSION_FAILURE:
            return { ...state, getUserExportJobPermissionResult: { success: false, ...action.payload.error }, exportPermission: null };
        default:
            return state;
    }
};
