import react, { Component } from 'react';
import { UserLogin, UserSignup, UserAccountRecover, UserChangePassword } from 'containers';
import queryString from 'query-string'
import { Row, Col, Typography, Image, Radio, Carousel, Modal } from 'antd';
import CypressTestIds from 'cypress/CypressTestIds';

const { Title, Text, Link } = Typography;
const wallpapers = [
    "barista-4k-scaled.jpg",
    "bell-4k-scaled.jpg",
    "beverage-4k-scaled.jpg",
    "clothing-4k-scaled.jpg",
    "cocktail-4k-scaled.jpg",
    "flight-4k-scaled.jpg",
    "florist-4k-scaled.jpg",
    "grocery-4k-scaled.jpg",
    "meal-4k-scaled.jpg",
    "team-4k-scaled.jpg",
    "towels-4k-scaled.jpg"
]

class MainPageLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginOption: "login",
            wallpaper: `/wallpapers/mainpage/${wallpapers[Math.floor(Math.random() * wallpapers.length)]}`,
            viewType: "LOGIN"
        }
    }

    onLoginOptionChange = (e) => {
        this.setState({
            loginOption: e.target.value
        })
    }

    onShowForgotPassword = () => {
        this.setState({
            viewType: "FORGOT_PASSWORD"
        })
    }

    onShowResendVerificationLink = () => {
        this.setState({
            viewType: "RESEND_VERIFICATION_LINK"
        })
    }

    showLoginView = () => {
        this.setState({
            viewType: "LOGIN"
        })
    }

    showLogin = () => {
        this.setState({
            loginOption: "login"
        });
    }

    onLoginProgressStatusChange = ({ inProgress }) => {
        this.setState({
            loginInProgress: inProgress
        })
    }

    getView = () => {
        switch (this.state.viewType) {
            case "FORGOT_PASSWORD":
                return <>
                    <UserAccountRecover recoveryType="FORGOT_PASSWORD"></UserAccountRecover>
                    <Row style={{ flexDirection: "row" }} justify="space-between" className="middle-row">
                        <Col span={24} className="full-height-container center-align-items">
                            <Link data-testid={CypressTestIds.USER_RESET_PASSWORD_BACKTOLOGIN_BUTTON} underline onClick={this.showLoginView} disabled={this.state.loginInProgress}>
                                Back to login
                            </Link>
                        </Col>
                    </Row>
                </>
            case "RESEND_VERIFICATION_LINK":
                return <>
                    <UserAccountRecover recoveryType="RESEND_VERIFICATION_LINK"></UserAccountRecover>
                    <Row style={{ flexDirection: "row" }} justify="space-between" className="middle-row">
                        <Col span={24} className="full-height-container center-align-items">
                            <Link data-testid={CypressTestIds.USER_RESET_PASSWORD_BACKTOLOGIN_BUTTON} underline onClick={this.showLoginView} disabled={this.state.loginInProgress}>
                                Back to login
                            </Link>
                        </Col>
                    </Row>
                </>
            default:
                return <>
                    <Row className="middle-row">
                        <Col span={24} className="login-signup-options-container">
                            <Radio.Group defaultValue={this.state.loginOption} value={this.state.loginOption} buttonStyle="solid" size="large" onChange={this.onLoginOptionChange}>
                                <Radio.Button value="login" disabled={this.state.loginInProgress}>Existing user</Radio.Button>
                                <Radio.Button value="signup" disabled={this.state.loginInProgress}>New user</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className="middle-row">
                        <Col span={24}>
                            {
                                this.state.loginOption === "login"
                                    ?
                                    <UserLogin {...this.props} onProgressStatusChange={this.onLoginProgressStatusChange}></UserLogin>
                                    :
                                    <UserSignup {...this.props} onGoBackToLogin={this.showLogin}></UserSignup>
                            }
                        </Col>
                    </Row>
                    <Row style={{ flexDirection: "row" }} justify="space-between" className="middle-row">
                        <Col span={24} className="content-align-right">
                            <Link underline onClick={this.onShowForgotPassword} disabled={this.state.loginInProgress} data-testid={CypressTestIds.USER_LOGIN_FORGOT_PASSWORD_LINK}>
                                Forgot your password?
                            </Link>
                        </Col>
                        <Col span={24} className="content-align-right">
                            <Link underline onClick={this.onShowResendVerificationLink} disabled={this.state.loginInProgress} data-testid={CypressTestIds.USER_LOGIN_RESEND_VERIFY_EMAIL_LINK}>
                                Resend verification email
                            </Link>
                        </Col>
                    </Row>
                </>
        }
    }

    render() {
        return (
            <Row className="full-height-container">
                <Col className="full-height-container" span={12}>
                    <Row className="full-height-container center-align-items">
                        <Col span={12}>
                            <Row>
                                <Col span={24} className="full-height-container center-align-items">
                                    <Image
                                        height={57.92}
                                        width={57.92}
                                        src="/icons/pretectum.png" />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="full-height-container center-align-items">
                                    <Title level={2}>Pretectum</Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="full-height-container center-align-items">
                                    <Title level={4}><Text type="secondary">Intelligent choice for good customer data</Text></Title>
                                </Col>
                            </Row>
                            {
                                this.props.anonymousWrapperProps && this.props.anonymousWrapperProps.resetPassword ?
                                    <UserChangePassword {...queryString.parse(this.props.location.search)}></UserChangePassword>
                                    :
                                    this.getView()
                            }
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="main-login-image-carousel-container">
                    <Image style={{ maxWidth: "100%" }} height="100%" preview={false} src={this.state.wallpaper} />
                </Col>
            </Row>
        );
    }
}

export default MainPageLogin