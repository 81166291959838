import React, { useEffect, useRef } from 'react';

export const useComponentDidUpdateEffect = (callback, state = []) => {
    const componentDidUpdate = useRef(false);
    useEffect(() => {
        if (componentDidUpdate.current) {
            callback();
        }
        else {
            componentDidUpdate.current = true;
        }
    }, state);
}

