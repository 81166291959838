export const GET_SCHEMA_MODEL_HISTORY_REQUEST = "GET_SCHEMA_MODEL_HISTORY_REQUEST";
export const GET_SCHEMA_MODEL_HISTORY_SUCCESS = "GET_SCHEMA_MODEL_HISTORY_SUCCESS";
export const GET_SCHEMA_MODEL_HISTORY_FAILURE = "GET_SCHEMA_MODEL_HISTORY_FAILURE";

export const GET_SCHEMA_MODEL_EVENTS_REQUEST = "GET_SCHEMA_MODEL_EVENTS_REQUEST";
export const GET_SCHEMA_MODEL_EVENTS_SUCCESS = "GET_SCHEMA_MODEL_EVENTS_SUCCESS";
export const GET_SCHEMA_MODEL_EVENTS_FAILURE = "GET_SCHEMA_MODEL_EVENTS_FAILURE";

export const GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_REQUEST = "GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_REQUEST";
export const GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_SUCCESS = "GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_SUCCESS";
export const GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_FAILURE = "GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_FAILURE";


export const getSchemaModelHistoryRequest = (businessAreaId, schemaId) => ({
    type: GET_SCHEMA_MODEL_HISTORY_REQUEST,
    params: {
        businessAreaId,
        schemaId
    }
})

export const getSchemaModelHistorySuccess = (schemaModelHistoryResult) => ({
    type: GET_SCHEMA_MODEL_HISTORY_SUCCESS,
    payload: schemaModelHistoryResult
})

export const getSchemaModelHistoryFailure = (error) => ({
    type: GET_SCHEMA_MODEL_HISTORY_FAILURE,
    payload: error
})

export const getSchemaModelEventsRequest = (schemaId) => ({
    type: GET_SCHEMA_MODEL_EVENTS_REQUEST,
    params: {
        schemaId
    }
})

export const getSchemaModelEventsSuccess = (schemaModelEventsResult) => ({
    type: GET_SCHEMA_MODEL_EVENTS_SUCCESS,
    payload: schemaModelEventsResult
})

export const getSchemaModelEventsFailure = (error) => ({
    type: GET_SCHEMA_MODEL_EVENTS_FAILURE,
    payload: error
})

export const getSchemaModelVersionFieldRevisionsRequest = (businessAreaId, schemaId, version) => ({
    type: GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_REQUEST,
    params: {
        schemaId,
        version,
        businessAreaId
    }
})

export const getSchemaModelVersionFieldRevisionsSuccess = (result) => ({
    type: GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_SUCCESS,
    payload: result
})

export const getSchemaModelVersionFieldRevisionsFailure = (error) => ({
    type: GET_SCHEMA_MODEL_VERSION_FIELD_REVISIONS_FAILURE,
    payload: error
})