import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, List, Space, Button, Table, Checkbox, Tooltip } from "antd";
import moment from 'moment';

const { Title, Paragraph, Text, Link } = Typography;

const DataObjectConsentListItem = ({ consentItem }) => {

    return (
        <List.Item key={`${consentItem.consentId}_${consentItem.version}`}>
            <Typography>
                <Paragraph>
                    {consentItem.consent
                        ?
                        <Text type="secondary">{`Consent was granted by: ${consentItem.email}`}</Text>
                        :
                        <Text type="secondary">{`Consent was not provided by: ${consentItem.email}`}</Text>
                    }
                </Paragraph>
                <Paragraph>
                    <Text type="secondary">{`on ${moment.utc(consentItem.updatedDate).toDate().toLocaleString()}`}</Text>
                </Paragraph>
                <Paragraph>
                    <Text type="secondary">{`under IP Address: ${consentItem.ipAddress}`}</Text>
                </Paragraph>
            </Typography>
        </List.Item>
    );
}

export default DataObjectConsentListItem;