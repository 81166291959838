import React from "react";
import { Row, Col, Button } from 'antd';

const PageFooter = (props) => {
    return (
        <Row>
            <Col span={15} className="footer-center-align-items">
                <span className="main-footer-caption">
                    Intelligent choice for good customer data
                </span>
            </Col>
            <Col span={3} className="footer-right-column">
                <Button type="link">Pretectum</Button>
            </Col>
            <Col span={3} className="footer-right-column">
                <Button type="link">Documentation</Button>
            </Col>
            <Col span={3} className="footer-right-column">
                <Button type="link">Privacy</Button>
            </Col>
        </Row>
    )
}

export default PageFooter