import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Pie, Bar } from '@ant-design/plots';
import { LoadingOverlay, BasicFormBuilder, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Row, Col, Modal, Empty, Switch, Space } from 'antd';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class DataObjectDuplicateMergePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingPreview: false,
            showPreview: true,
            busy: false,
            busyMessage: "Please wait..."
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            fetchingPreview: true
        })
        this.props.getNominatedDataObjectMergePreview(this.props.duplicateSetId, this.props.duplicateSetVersion, this.props.groupId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetNominatedDataObjectMergePreviewResultChanged(prevProps);
    }

    onGetNominatedDataObjectMergePreviewResultChanged = (prevProps) => {
        if (this.props.getNominatedDataObjectMergePreviewResult && this.props.getNominatedDataObjectMergePreviewResult !== prevProps.getNominatedDataObjectMergePreviewResult) {
            if (!this.props.getNominatedDataObjectMergePreviewResult.success) {
                showError("Could not able to generate preview at the moment");
            }
            this.setState({
                fetchingPreview: false
            })
        }
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.state.fetchingPreview;
        return isBusy;
    }

    onOkClicK = () => {
        this.setState({
            showPreview: false
        })
        this.props.onClose();
    };

    onCancelClick = () => {
        this.setState({
            showPreview: false
        })
        this.props.onClose();
    };

    getPreviewComponent = () => {
        return <>
            {
                this.props.nominatedDataObjectMergePreview.record ?
                    <FullHeightContainerLayout
                        showHeader={false}
                        showFooter={false}
                        content={
                            <BasicFormBuilder
                                showHeader={false}
                                key={this.props.nominatedDataObjectMergePreview.record.dataObjectId}
                                formDataObject={this.props.nominatedDataObjectMergePreview.record}
                                schemaFields={this.props.nominatedDataObjectMergePreview.schema.fields}
                                readOnly={true}>
                            </BasicFormBuilder>}>
                    </FullHeightContainerLayout>
                    :
                    <Empty description="No preview found" />
            }
        </>
    }

    render() {
        return <Modal
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            title="Merge Preview"
            style={{ top: 20 }}
            open={this.state.showPreview}
            visible={this.state.showPreview}
            onOk={this.onOkClicK}
            onCancel={this.onCancelClick}
            bodyStyle={{ height: "80vh", width: "70vh" }}
            width="70vh">
            <Row style={{ background: "#fffff", overflowY: "auto", width: "100%", height: "100%" }} className="layout-row">
                <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.isBusy() ? this.getPreviewComponent() : <></>}
                </Col>
            </Row>
        </Modal>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getNominatedDataObjectMergePreviewResult: state.dataObjectDuplicateMergePreview.getNominatedDataObjectMergePreviewResult,
        nominatedDataObjectMergePreview: state.dataObjectDuplicateMergePreview.nominatedDataObjectMergePreview
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNominatedDataObjectMergePreview: (duplicateSetId, duplicateSetVersion, groupId) => dispatch(actions.getNominatedDataObjectMergePreviewRequest(duplicateSetId, duplicateSetVersion, groupId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectDuplicateMergePreview));