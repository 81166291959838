module.exports = {
    "common": {
        name: "",
        description: "",
        active: true,
        isPrimaryKey: false,
        isSortKey: false,
        isRequired: false,
        isMultivalue: false,
        isPII: false,
        classifiers: [],
        validationRules: []
    },
    "integer": {
        dataType: "integer",
        max: Number.MAX_SAFE_INTEGER,
        min: 0
    },
    "float": {
        dataType: "float",
        max: Number.MAX_SAFE_INTEGER,
        min: 0
    },
    "string": {
        dataType: "string",
        max: 500,
        min: 0
    },
    "boolean": {
        dataType: "boolean",
        allowedBooleanValues: "y/n"
    },
    "datetime": {
        dataType: "datetime"
    },
    "date": {
        dataType: "date"
    },
    "time": {
        dataType: "time"
    },
    "date-year": {
        dataType: "date-year"
    },
    "date-month": {
        dataType: "date-month"
    },
    "date-day": {
        dataType: "date-day"
    },
    "currency": {
        dataType: "currency",
        max: 1000000000.00,
        min: 0
    },
    "picklist": {
        dataType: "picklist",
        picklistType: "static",
        staticPicklist: [],
    },
    "email": {
        dataType: "email",
        max: 320,
        min: 3
    },
    "url": {
        dataType: "url",
        max: 2048,
        min: 2
    },
    "phone": {
        dataType: "phone",
        max: 20,
        min: 4
    },
    "latlng": {
        dataType: "latlng",
        latitude: {
            min: -90,
            max: 90
        },
        longitude: {
            min: -180,
            max: 180
        },
        format: "decimal",
        precision: 4,
        validationRules: [
            {
                rule: "isValidLatLong",
                message: "Invalid latitude-longitude pair"
            }
        ]
    }
}
