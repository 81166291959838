import {
    GET_EVENT_DATA_SET_SUCCESS,
    GET_EVENT_DATA_SET_FAILURE
} from "./actions";

const initialState = {
    getEventDataSetResult: {
        success: true
    },
    dataSetRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_DATA_SET_SUCCESS:
            return { ...state, getEventDataSetResult: { success: true }, dataSetRevision: action.payload };
        case GET_EVENT_DATA_SET_FAILURE:
            return {
                ...state, getEventDataSetResult: { success: false, ...action.payload }, dataSetRevision: null
            };
        default:
            return state;
    }
};
