import React, { Component } from 'react';
import { Radio, Space, Row, Col, Button, Typography, Divider, Select } from 'antd';
import fieldDataTypes from 'common/data/fieldDataTypes';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Parser } from 'json2csv';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

class SchemaExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exportOption: "Basic",
            exportFormat: "csv"
        }
    }

    componentWillMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    onExportOptionsChanged = event => {
        if (event && event.target) {
            this.setState({
                exportOption: event.target.value
            });
        }
    }

    onExportFormatChanged = value => {
        this.setState({
            exportFormat: value
        });
    }

    onExport = () => {
        switch (this.state.exportFormat) {
            case "csv":
                this.saveCSVData("text/csv;charset=utf-8", "csv");
                break;
            case "xlsx":
                this.saveXLSXData();
                break;
            case "text":
                this.saveCSVData("text/plain;charset=utf-8", "txt");
                break;
        }
    }

    saveCSVData = (fileType, extension) => {
        let fields = null;
        let config = { header: true, quote: '' };
        switch (this.state.exportOption) {
            case "Basic":
                fields = this.getBasicFields();
                config.header = false;
                break;
            case "List":
                fields = this.getBasicVerticallyStackedFields();
                config.header = false;
                break;
            case "DD":
                fields = this.getCondensedAttributeFields();
                break;
            case "DDV":
                fields = this.getVerboseAttributeFields();
                break;
        }
        const json2csvParser = new Parser(config);
        const csv = json2csvParser.parse(fields);
        const csvBuffer = Buffer.from(csv, 'utf8');
        const data = new Blob([csvBuffer], { type: fileType });
        FileSaver.saveAs(data, `${this.props.schemaModel.name} ${this.state.exportOption}.${extension}`);
    }

    saveXLSXData = () => {
        let fields = null;
        let writeOptions = {};
        switch (this.state.exportOption) {
            case "Basic":
                fields = this.getBasicFields();
                writeOptions.skipHeader = true;
                break;
            case "List":
                fields = this.getBasicVerticallyStackedFields();
                writeOptions.skipHeader = true;
                break;
            case "DD":
                fields = this.getCondensedAttributeFields();
                break;
            case "DDV":
                fields = this.getVerboseAttributeFields();
                break;
        }
        const ws = XLSX.utils.json_to_sheet(fields, writeOptions);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(data, `${this.props.schemaModel.name} ${this.state.exportOption}.xlsx`);
    }

    getBasicFields = () => {
        let fields = [];
        for (let field of this.props.schemaModel.fields) {
            fields.push(field.name);
        }
        return [fields];
    }


    getBasicVerticallyStackedFields = () => {
        let fields = [];
        for (let field of this.props.schemaModel.fields) {
            fields.push([field.name]);
        }
        return fields;
    }

    getCondensedAttributeFields = () => {
        let fields = [];
        for (let field of this.props.schemaModel.fields) {
            fields.push({
                "COLUMN": field.name,
                "DESCRIPTION": field.description,
                "DATATYPE": field.dataType,
                "PRIMARY": (field.isPrimaryKey && "Y") || "N",
                "SORT": (field.isSortKey && "SortKey") || "N",
                "MANDATORY": (field.isRequired && "Y") || "N",
                "MV": (field.isMultivalue && "Y") || "N",
                "PII": (field.isPII && "Y") || "N",
            });
        }
        return fields;
    }

    getVerboseAttributeFields = () => {
        let fields = [];
        for (let field of this.props.schemaModel.fields) {
            let exportData = {
                "COLUMN": field.name,
                "DESCRIPTION": field.description,
                "DATATYPE": field.dataType,
                "MIN": "",
                "MAX": "",
                "PRIMARY": (field.isPrimaryKey && "Y") || "N",
                "SORT": (field.isSortKey && "Y") || "N",
                "MANDATORY": (field.isRequired && "Y") || "N",
                "MV": (field.isMultivalue && "Y") || "N",
                "PII": (field.isPII && "Y") || "N",
                "PICKLIST": field.picklistType === "static" ? field.staticPicklist.map(item => item.code).join("|") : ""
            }
            switch (field.dataType) {
                case 'integer':
                case 'float':
                case 'string':
                case 'date-day':
                case 'date-month':
                case 'date-year':
                case 'currency':
                case "email":
                case "url":
                case "phone":
                    exportData.MAX = (fieldDataTypes[field.dataType].max !== field.max && field.max) || "";
                    exportData.MIN = (fieldDataTypes[field.dataType].min !== field.min && field.min) || "";
                    break;
                case 'boolean':
                case "picklist":
                    exportData.MAX = "";
                    exportData.MIN = "";
                    break;
                case 'datetime':
                case 'date':
                    let datetimeFormat = `${_.replace(field.dateFormat, /\$/g, field.dateSeparator)} ${field.timeFormat}`;
                    if (field.dataType === "date") {
                        datetimeFormat = `${_.replace(field.dateFormat, /\$/g, field.dateSeparator)}`;
                    }
                    if (field.min) {
                        let minDateTimeActualValue = moment(field.min, "YYYY-MM-DDTHH:mm:ss");
                        let minDateTimeValue = moment("1970-01-01T00:00:00", "YYYY-MM-DDTHH:mm:ss");
                        if (minDateTimeActualValue.isAfter(minDateTimeValue)) {
                            exportData.MIN = minDateTimeActualValue.format(datetimeFormat);
                        }
                    }
                    if (field.max) {
                        let maxDateTimeActualValue = moment(field.max, "YYYY-MM-DDTHH:mm:ss");
                        let maxDateTimeValue = moment("9999-01-01T23:59:59", "YYYY-MM-DDTHH:mm:ss");
                        if (maxDateTimeActualValue.isBefore(maxDateTimeValue)) {
                            exportData.MAX = maxDateTimeActualValue.format(datetimeFormat);
                        }
                    }
                    break;
                case 'time':
                    if (field.min) {
                        let minTimeActualValue = moment(field.min);
                        let minTimeValue = moment("12:00:00 am", "hh:mm:ss a");
                        if (minTimeActualValue.isAfter(minTimeValue)) {
                            exportData.MIN = minTimeActualValue.format(field.timeFormat);
                        }
                    }
                    if (field.max) {
                        let maxTimeActualValue = moment(field.max);
                        let maxTimeValue = moment("11:59:59 pm", "hh:mm:ss a");
                        if (maxTimeActualValue.isAfter(maxTimeValue)) {
                            exportData.MAX = maxTimeActualValue.format(field.timeFormat);
                        }
                    }
                    break;
            }
            fields.push(exportData);
        }
        return fields;
    }

    render() {
        return (
            <div className="full-height-flex-container verticle-scroll">
                <Row>
                    <Col span={24}>
                        <Title level={2} style={{ marginBottom: "unset" }}>Export Schema</Title>
                    </Col>
                </Row>
                <Divider />
                <Row wrap={false} className="layout-row-stretched">
                    <Col span={24}>
                        <Space direction="vertical" size="large">
                            <Row>
                                <Col>
                                    <Radio.Group defaultValue={1} value={this.state.exportOption} onChange={this.onExportOptionsChanged}>
                                        <Space direction="vertical">
                                            <Radio value={"Basic"}>Horizontal List (basic)</Radio>
                                            <Radio value={"List"}>Stacked List (list)</Radio>
                                            <Radio value={"DD"}>Schema Data Definition (DD)</Radio>
                                            <Radio value={"DDV"}>Schema Data Definition with Validations (DDV)</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <Text>File Format:</Text>
                                </Col>
                                <Col span={18}>
                                    <Select defaultValue="csv" value={this.state.exportFormat} style={{ width: 120 }} onChange={this.onExportFormatChanged}>
                                        <Option value="csv">CSV</Option>
                                        <Option value="xlsx">XLSX</Option>
                                        <Option value="text">TXT</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="footer-right-column">
                        <Space>
                            <Button onClick={this.props.cancelExportSchema}>Cancel</Button>
                            <Button type="primary" onClick={this.onExport}>Export</Button>
                        </Space>
                    </Col>
                </Row>
            </div>);
    }
}

export default SchemaExport;