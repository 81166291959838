import {
    GET_PICKLIST_DOMAIN_DATA_REQUEST,
    GET_PICKLIST_DOMAIN_DATA_SUCCESS,
    GET_PICKLIST_DOMAIN_DATA_FAILURE
} from "./actions";

const initialState = {
    domainData: {
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PICKLIST_DOMAIN_DATA_REQUEST:
            return {
                ...state,
                domainData: {
                    ...state.domainData,
                    [action.params.dataDomainId]: {
                        getDomainDataResult:{
                            inProgress: true,
                            success: true
                        },
                        domainDataResult: !action.params.pageKey ? { Items: [] } : state.domainData[action.params.dataDomainId].domainDataResult
                    }
                }
            };
        case GET_PICKLIST_DOMAIN_DATA_SUCCESS:
            let domainDataResult = action.payload.domainDataResult;
            let sNo = state.domainData[action.payload.dataDomainId].domainDataResult.Items.length;
            for (let item of domainDataResult.Items) {
                item.sNo = sNo + 1;
                sNo = item.sNo;
            }
            domainDataResult.Items = state.domainData[action.payload.dataDomainId].domainDataResult.Items.concat(domainDataResult.Items)
            return {
                ...state,
                domainData: {
                    ...state.domainData,
                    [action.payload.dataDomainId]: {
                        getDomainDataResult:{
                            inProgress: false,
                            success: true
                        },
                        domainDataResult
                    }
                }
            };
        case GET_PICKLIST_DOMAIN_DATA_FAILURE:
            return {
                ...state,
                domainData: {
                    ...state.domainData,
                    [action.payload.dataDomainId]: {
                        getDomainDataResult:{
                            inProgress: false,
                            success: false,
                            ...action.payload.error
                        },
                        domainDataResult: state.domainData[action.payload.dataDomainId].domainDataResult
                    }
                }
            };
        default:
            return state;
    }
};
