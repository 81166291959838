import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { FormPicklistField } from "components";
import { withRouter } from "react-router";
import _ from 'lodash';

const GET_DOMAIN_DATA_RESULT = { success: true, inProgress: true };
const DOMAIN_DATA_RESULT = { Items: [] };

class FormDomainDataPicklistField extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        this.props.getPicklistDomainData(this.props.dataDomainId, null);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return <FormPicklistField
            {...this.props}
            fetchingData={this.props.getDomainDataResult.inProgress}
            items={this.props.domainDataResult.Items} ></FormPicklistField>;
    }
}

const mapStateToProps = (state, ownProps) => {
    let getDomainDataResult = GET_DOMAIN_DATA_RESULT;
    let domainDataResult = DOMAIN_DATA_RESULT;
    if (state.formDomainDataPicklistField.domainData[ownProps.dataDomainId]) {
        getDomainDataResult = state.formDomainDataPicklistField.domainData[ownProps.dataDomainId].getDomainDataResult;
        domainDataResult = state.formDomainDataPicklistField.domainData[ownProps.dataDomainId].domainDataResult;
    }

    return {
        getDomainDataResult,
        domainDataResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPicklistDomainData: (dataDomainId, pageKey) => dispatch(actions.getPicklistDomainDataRequest(dataDomainId, pageKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormDomainDataPicklistField));