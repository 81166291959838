import React from 'react';
import { useParams } from "react-router-dom";
import queryString from 'query-string'
import { GuestDataObjectSelfVerification } from 'containers';

const DataObjectSelfVerificationPage = (props) => {
    const { tenantid, businessareaid, schemaid, dataobjectid, verificationid } = useParams();
    const { s, v, to, expires, date, signature } = queryString.parse(props.location.search);
    return (
        <GuestDataObjectSelfVerification
            {...props}
            tenantId={tenantid}
            businessAreaId={businessareaid}
            schemaId={schemaid}
            dataObjectId={dataobjectid}
            verificationId={verificationid}
            sender={s}
            version={v}
            toEmail={to}
            expires={expires}
            date={date}
            signature={signature}>
        </GuestDataObjectSelfVerification>
    )
}

export default DataObjectSelfVerificationPage
