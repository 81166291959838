import React from "react";
import { FullHeightContainerLayout } from "components";
import { DataObjectShared } from 'containers';

const SharedDataObject = (props) => {
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={<DataObjectShared {...props}></DataObjectShared>}>
        </FullHeightContainerLayout>)
};

export default SharedDataObject;