import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, DataSetHistoryList, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class DataSetAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        this.props.getDataSetHistory(this.props.dataSet.businessAreaId, this.props.dataSet.schemaId, this.props.dataSet.dataSetId);
        this.props.getDataSetEvents(this.props.dataSet.dataSetId);
        this.setState({
            fetchingHistory: true,
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataSetHistoryResultUpdate(prevProps);
        this.onGetDataSetEventsResultUpdate(prevProps);
    }

    onGetDataSetHistoryResultUpdate = (prevProps) => {
        if (this.props.getDataSetHistoryResult && this.props.getDataSetHistoryResult !== prevProps.getDataSetHistoryResult) {
            if (!this.props.getDataSetHistoryResult.success) {
                if (this.props.getDataSetHistoryResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset history.");
                }
            }
            this.setState({
                fetchingHistory: false
            });
        }
    }

    onGetDataSetEventsResultUpdate = (prevProps) => {
        if (this.props.getDataSetEventsResult && this.props.getDataSetEventsResult !== prevProps.getDataSetEventsResult) {
            if (!this.props.getDataSetEventsResult.success) {
                if (this.props.getDataSetEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Tabs defaultActiveKey="1" className="data-audit-tabs">
                        <TabPane tab="Events" key="1">
                            {
                                this.state.fetchingEvents
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting dataset events...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <EventList eventList={this.props.dataSetEventsResult.Items}>
                                            </EventList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                        <TabPane tab="Revisions" key="2">
                            {
                                this.state.fetchingHistory
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting dataset history...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <DataSetHistoryList
                                                dataSetRevisions={this.props.dataSetHistoryResult.Items}>
                                            </DataSetHistoryList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                    </Tabs>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataSetHistoryResult: state.dataSetAudit.getDataSetHistoryResult,
        dataSetHistoryResult: state.dataSetAudit.dataSetHistoryResult,
        getDataSetEventsResult: state.dataSetAudit.getDataSetEventsResult,
        dataSetEventsResult: state.dataSetAudit.dataSetEventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataSetHistory: (businessAreaId, schemaId, dataSetId) => dispatch(actions.getDataSetHistoryRequest(businessAreaId, schemaId, dataSetId)),
        getDataSetEvents: (dataSetId) => dispatch(actions.getDataSetEventsRequest(dataSetId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSetAudit));