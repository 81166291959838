export const GET_EVENT_BUSINESS_AREA_APP_CLIENTS_REQUEST = "GET_EVENT_BUSINESS_AREA_APP_CLIENTS_REQUEST";
export const GET_EVENT_BUSINESS_AREA_APP_CLIENTS_SUCCESS = "GET_EVENT_BUSINESS_AREA_APP_CLIENTS_SUCCESS";
export const GET_EVENT_BUSINESS_AREA_APP_CLIENTS_FAILURE = "GET_EVENT_BUSINESS_AREA_APP_CLIENTS_FAILURE";

export const getEventBusinessAreaAppClientsRequest = (eventId, itemOperation, pageKey) => ({
    type: GET_EVENT_BUSINESS_AREA_APP_CLIENTS_REQUEST,
    params: {
        eventId,
        itemOperation,
        pageKey
    }
})

export const getEventBusinessAreaAppClientsSuccess = (appClientsResult) => ({
    type: GET_EVENT_BUSINESS_AREA_APP_CLIENTS_SUCCESS,
    payload: {
        appClientsResult
    }
})

export const getEventBusinessAreaAppClientsFailure = (error) => ({
    type: GET_EVENT_BUSINESS_AREA_APP_CLIENTS_FAILURE,
    payload: {
        error
    }
})