
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;
const getActiveBusinessAreaId = (state) => state.home.activeBusinessAreaId;

function* getUserBusinessAreas(api, tenantId, userId) {
    let result = yield call([api, api.get], `${Endpoints.tenants}/api/v1/users/${userId}/businessareas`, { tenantid: tenantId });
    return result.data;
}

function* getSchemaModels(api, tenantId, businessAreaId, pageKey = null) {
    let params = {
    };
    if (pageKey) {
        params.pageKey = pageKey
    }
    let result = yield call([api, api.get], `${Endpoints.schemas}/api/v1/businessareas/${businessAreaId}/schemas`, { tenantid: tenantId }, {
        params
    });
    return result.data;
}

function* createSchemaClone(api, activeBusinessAreaId, schemaModel) {
    let result = yield call([api, api.post], `${Endpoints.schemas}/api/v1/businessareas/${activeBusinessAreaId}/schemas/clone`, schemaModel);
    return result.data;
}

export function* getBusinessAreaSchemaTreeRequest(api) {
    try {
        let businessAreaSchemaTree = [];
        let tenant = yield select(getTenant);
        let user = yield select(getUser);
        const response = yield call(getUserBusinessAreas, api, tenant.tenantId, user.userId);
        if (response.Items) {
            let businessAreas = response.Items;
            if (businessAreas && businessAreas.length > 0) {
                for (let userBusinessArea of businessAreas) {
                    businessAreaSchemaTree.push({
                        ...userBusinessArea,
                        loadingSchema: true
                    });
                }
                yield put(actions.getBusinessAreaSchemaTreeSuccess(businessAreaSchemaTree));
                for (let index = 0; index < businessAreaSchemaTree.length; index++) {
                    let userBusinessArea = { ...businessAreaSchemaTree[index] };
                    let schemaResult = yield call(getSchemaModels, api, tenant.tenantId, userBusinessArea.businessAreaId);
                    userBusinessArea.loadingSchema = false;
                    if (schemaResult && schemaResult.Items && schemaResult.Items.length > 0) {
                        userBusinessArea.schemaModels = schemaResult.Items;
                    }
                    businessAreaSchemaTree[index] = userBusinessArea;
                    yield put(actions.getBusinessAreaSchemaTreeSuccess([...businessAreaSchemaTree]));
                }
            }
        }
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "business area schemas");
        yield put(actions.getBusinessAreaSchemaTreeFailure(errorObject));
    }
}

export function* createSchemaCloneRequest(api, { schemaModel }) {
    try {
        let activeBusinessAreaId = yield select(getActiveBusinessAreaId);
        const clonedSchemaModel = yield call(createSchemaClone, api, activeBusinessAreaId, schemaModel);
        yield put(actions.createSchemaCloneSuccess(clonedSchemaModel));
    } catch (error) {
        let errorObject = errorHandler(error, "Clone", "schema model");
        yield put(actions.createSchemaCloneFailure(errorObject));
    }
}

export function* watchGetBusinessAreaSchemaTreeRequest(api, { params }) {
    yield call(getBusinessAreaSchemaTreeRequest, api);
}

export function* watchCreateSchemaCloneRequest(api, { params }) {
    yield call(createSchemaCloneRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_BUSINESS_AREA_SCHEMA_TREE_REQUEST, watchGetBusinessAreaSchemaTreeRequest, api);
    yield takeLatest(actions.CREATE_SCHEMA_CLONE_REQUEST, watchCreateSchemaCloneRequest, api);
}