export const GET_EVENT_BUSINESS_AREA_REQUEST = "GET_EVENT_BUSINESS_AREA_REQUEST";
export const GET_EVENT_BUSINESS_AREA_SUCCESS = "GET_EVENT_BUSINESS_AREA_SUCCESS";
export const GET_EVENT_BUSINESS_AREA_FAILURE = "GET_EVENT_BUSINESS_AREA_FAILURE";

export const getEventBusinessAreaRequest = (eventId, businessAreaId) => ({
    type: GET_EVENT_BUSINESS_AREA_REQUEST,
    params: {
        eventId,
        businessAreaId
    }
})

export const getEventBusinessAreaSuccess = (businessAreaResult) => ({
    type: GET_EVENT_BUSINESS_AREA_SUCCESS,
    payload: businessAreaResult
})

export const getEventBusinessAreaFailure = (error) => ({
    type: GET_EVENT_BUSINESS_AREA_FAILURE,
    payload: error
})