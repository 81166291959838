import React, { Component } from "react";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { HeaderMenu, PageFooter, LoadingOverlay, ProhibitedArea } from 'components';
import { Layout } from 'antd';
import actions from 'store/actions';
import { Redirect } from 'react-router-dom';
import AppPaths from 'constants/appPaths';
import { withRouter } from 'react-router-dom';

const { Header, Content, Footer } = Layout;
function AuthenticatedPageWrapper(ComposedComponent, wrapperProps = {}) {
    class Authentication extends Component {
        constructor(props) {
            super(props);
            this.state = {
                redirectToLogin: false,
                fetchingTenant: props.tenant ? false : true,
                tenantPermissionDenied: false
            };
        }

        componentWillMount() {
            this.props.getUserAndTenant();
            if (!wrapperProps.requireUserBusinessAreas) {
                this.props.getUserBusinessAreas();
                this.props.getUserActiveBusinessArea();
            }
        }

        componentDidMount() {
            if (wrapperProps.activeBusinessAreaBasePath) {
                let pathParams = this.props.match.params;
                let activeBusinessAreaBasePath = wrapperProps.activeBusinessAreaBasePath;
                for (let param in pathParams) {
                    if (param !== "businessarea") {
                        activeBusinessAreaBasePath = activeBusinessAreaBasePath.replace(`:${param}`, pathParams[param]);
                    }
                }
                this.props.setActiveBusinessAreaPath(activeBusinessAreaBasePath);
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.props.getUserTenantResult !== prevProps.getUserTenantResult
                || this.props.userLogoutResult !== prevProps.userLogoutResult) {
                if (this.props.getUserTenantResult.success === false || this.props.userLogoutResult.success) {
                    if (this.props.getUserTenantResult.code === "PERMISSION_DENIED") {
                        this.setState({
                            fetchingTenant: false,
                            tenantPermissionDenied: true
                        });
                    }
                    else {
                        this.setState({
                            redirectToLogin: true,
                            userLogOut: (this.props.userLogoutResult !== prevProps.userLogoutResult) && this.props.userLogoutResult.success
                        });
                    }
                }
                else if (!this.props.tenant) {
                    this.setState({
                        fetchingTenant: false,
                        tenantPermissionDenied: true
                    });
                }
                else if ((this.props.tenant.name === this.props.match.params.tenant) || !this.props.match.params.tenant) {
                    this.setState({
                        fetchingTenant: false,
                        tenantPermissionDenied: false
                    });
                }
                else {
                    this.setState({
                        fetchingTenant: false,
                        tenantPermissionDenied: true
                    });
                }
            }
        }

        componentWillUnmount() {
            this.props.setActiveBusinessAreaPath(null);
        }

        getLoginPath = () => {
            let loginPath = AppPaths.LOGIN;
            if (!this.state.userLogOut && this.props.location && this.props.location.pathname) {
                let redirectPath = this.props.location.pathname;
                if (this.props.location.search) {
                    redirectPath = encodeURI(`${redirectPath}${this.props.location.search}`);
                }

                loginPath = `${loginPath}?redirect_to=${redirectPath}`;
            }
            return loginPath;
        }

        render() {
            if (this.state.redirectToLogin) {
                return <Redirect to={this.getLoginPath()}></Redirect>
            }
            return <>
                {this.state.fetchingTenant ? <LoadingOverlay busy={true} message="Please wait..."></LoadingOverlay> :
                    (this.state.tenantPermissionDenied === false ?
                        <>
                            <Layout className="page-layout">
                                {
                                    wrapperProps.showHeader === false
                                        ?
                                        <></>
                                        :
                                        <Header className="layout-header">
                                            <HeaderMenu {...this.props} {...wrapperProps}></HeaderMenu>
                                        </Header>
                                }

                                <Content style={{ overflow: "hidden" }} className="page-content">
                                    <div className="page-container">
                                        <ComposedComponent {...this.props} {...wrapperProps} />
                                    </div>
                                </Content>
                                {
                                    wrapperProps.showFooter === false
                                        ?
                                        <></>
                                        :
                                        <Footer className="layout-footer">
                                            <PageFooter></PageFooter>
                                        </Footer>
                                }
                            </Layout >
                        </> : <ProhibitedArea />)}
            </>
        }
    }

    return Authentication;
}

const mapStateToProps = (state) => {
    return {
        tenant: state.userLogin.tenant,
        activeBusinessAreaId: state.home.activeBusinessAreaId,
        userBusinessAreas: state.home.businessAreas,
        user: state.userLogin.user,
        cognitoUser: state.userLogin.cognitoUser,
        getUserTenantResult: state.userLogin.getUserTenantResult,
        userLogoutResult: state.userLogin.userLogoutResult
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAndTenant: () => dispatch(actions.getUserAndTenantRequest()),
        userLogout: () => dispatch(actions.userLogoutRequest()),
        getUserBusinessAreas: () => dispatch(actions.getUserBusinessAreasRequest()),
        getUserActiveBusinessArea: () => dispatch(actions.getUserActiveBusinessAreaRequest()),
        setActiveBusinessAreaPath: (activeBusinessAreaPath) => dispatch(actions.setActiveBusinessAreaPathRequest(activeBusinessAreaPath))
    }
}

export default (composedComponent, props = {}) => connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticatedPageWrapper(composedComponent, props)));