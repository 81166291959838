export const GET_EVENT_USER_REQUEST = "GET_EVENT_USER_REQUEST";
export const GET_EVENT_USER_SUCCESS = "GET_EVENT_USER_SUCCESS";
export const GET_EVENT_USER_FAILURE = "GET_EVENT_USER_FAILURE";

export const getEventUserRequest = (eventId, userId) => ({
    type: GET_EVENT_USER_REQUEST,
    params: {
        eventId,
        userId
    }
})

export const getEventUserSuccess = (userRevision) => ({
    type: GET_EVENT_USER_SUCCESS,
    payload: userRevision
})

export const getEventUserFailure = (error) => ({
    type: GET_EVENT_USER_FAILURE,
    payload: error
})