import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Tooltip, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

function CopyInput({ value, onChange, readOnly, copyToolTip }) {
    const [inputValue, setInputValue] = useState(value);
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const onInputValueChange = (e) => {
        setInputValue(e.target.value);
        onChange(e.target.value);
    }

    return <Input.Group compact>
        <Row style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
            <Col style={{ flexBasis: "100%" }}>
                <Input onChange={onInputValueChange} readOnly={readOnly} value={inputValue} style={{ width: '100%' }} />
            </Col>
            <Col style={{ flexBasis: "auto" }}>
                <Tooltip title={copyToolTip || "Copy Secret"}>
                    <Button icon={<CopyOutlined />} onClick={() => navigator.clipboard.writeText(inputValue)} />
                </Tooltip>
            </Col>
        </Row>
    </Input.Group>
}

export default CopyInput