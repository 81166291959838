import React from 'react';
import { Form, InputNumber, Checkbox, Tooltip } from 'antd';
import { Segment } from 'semantic-ui-react';
import { InfoCircleTwoTone } from '@ant-design/icons';

const LatLongFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors }) => {
  return (
    <Segment.Group>
      <Segment>
        <Form.Item
          label="Latitude Range"
          name={['fields', selectedFieldRow.name, 'latitudeRange']}
          initialValue={{ min: -90, max: 90 }}
          rules={[{ required: true, message: 'Latitude range is required' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.latitudeRange`)}>
          <Tooltip title="The latitude range is fixed between -90 and 90">
            <div>-90 to 90</div>
          </Tooltip>
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Longitude Range"
          name={['fields', selectedFieldRow.name, 'longitudeRange']}
          initialValue={{ min: -180, max: 180 }}
          rules={[{ required: true, message: 'Longitude range is required' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.longitudeRange`)}>
          <Tooltip title="The longitude range is fixed between -180 and 180">
            <div>-180 to 180</div>
          </Tooltip>
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Data Type"
          name={['fields', selectedFieldRow.name, 'dataType']}
          initialValue="latlng">
          <Tooltip title="The data type is fixed to 'latlng' for this field">
            <div>latlng</div>
          </Tooltip>
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Precision"
          name={['fields', selectedFieldRow.name, 'precision']}
          initialValue={4}
          rules={[{ required: true, message: 'Please enter a precision value' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.precision`)}>
          <InputNumber min={1} max={4} disabled={readOnly} />
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Multi Value"
          name={['fields', selectedFieldRow.name, 'isMultivalue']}
          valuePropName="checked">
          <Checkbox disabled={readOnly} />
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="PII"
          name={['fields', selectedFieldRow.name, 'isPII']}
          valuePropName="checked">
          <Checkbox disabled={readOnly}>
            <Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data">
              <InfoCircleTwoTone />
            </Tooltip>
          </Checkbox>
        </Form.Item>
      </Segment>
    </Segment.Group>
  );
};

export default LatLongFieldProperties;