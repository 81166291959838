export const GET_DATA_DOMAIN_HISTORY_REQUEST = "GET_DATA_DOMAIN_HISTORY_REQUEST";
export const GET_DATA_DOMAIN_HISTORY_SUCCESS = "GET_DATA_DOMAIN_HISTORY_SUCCESS";
export const GET_DATA_DOMAIN_HISTORY_FAILURE = "GET_DATA_DOMAIN_HISTORY_FAILURE";

export const GET_DATA_DOMAIN_EVENTS_REQUEST = "GET_DATA_DOMAIN_EVENTS_REQUEST";
export const GET_DATA_DOMAIN_EVENTS_SUCCESS = "GET_DATA_DOMAIN_EVENTS_SUCCESS";
export const GET_DATA_DOMAIN_EVENTS_FAILURE = "GET_DATA_DOMAIN_EVENTS_FAILURE";


export const getDataDomainHistoryRequest = (dataDomainId) => ({
    type: GET_DATA_DOMAIN_HISTORY_REQUEST,
    params: {
        dataDomainId
    }
})

export const getDataDomainHistorySuccess = (dataDomainHistoryResult) => ({
    type: GET_DATA_DOMAIN_HISTORY_SUCCESS,
    payload: dataDomainHistoryResult
})

export const getDataDomainHistoryFailure = (error) => ({
    type: GET_DATA_DOMAIN_HISTORY_FAILURE,
    payload: error
})

export const getDataDomainEventsRequest = (businessAreaId, dataDomainId) => ({
    type: GET_DATA_DOMAIN_EVENTS_REQUEST,
    params: {
        businessAreaId,
        dataDomainId
    }
})

export const getDataDomainEventsSuccess = (dataDomainEventsResult) => ({
    type: GET_DATA_DOMAIN_EVENTS_SUCCESS,
    payload: dataDomainEventsResult
})

export const getDataDomainEventsFailure = (error) => ({
    type: GET_DATA_DOMAIN_EVENTS_FAILURE,
    payload: error
})