import React from "react";
import { connect } from "react-redux";
import actions from 'store/actions';
import queryString from 'query-string'
import { LoadingOverlay } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';

class UserVerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                verificationCode: "",
                email: "",
                userName: "",
            },
            busy: false,
            busyMessage: "Verifying email..."
        }
    }

    componentDidMount() {
        const urlParams = queryString.parse(this.props.location.search);
        this.setState({
            user: {
                verificationCode: urlParams.code,
                userName: urlParams.username,
                email: urlParams.email
            },
            busy: true
        }, () => {
            this.props.verifyUserEmail(this.state.user);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.userEmailVerificationResult && this.props.userEmailVerificationResult !== prevProps.userEmailVerificationResult) {
            if (this.props.userEmailVerificationResult.success) {
                showSuccess("Email verified", () => {
                    this.props.history.push("/")
                });
            } else {
                //TODO - proper handling of exceptions
                if (this.props.userEmailVerificationResult.code === "ExpiredCodeException") {
                    showError("Verification link expired.", () => {
                        //this.props.history.push("/")
                    });
                }
                else {
                    showError("Verification Failed.", () => {
                        //this.props.history.push("/")
                    });
                }
            }
        }
    }

    render() {
        return (
            <LoadingOverlay busy={true} message={this.state.busyMessage}></LoadingOverlay>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userEmailVerificationResult: state.verifyUserEmail.userEmailVerificationResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyUserEmail: (user) => dispatch(actions.verifyUserEmailRequest(user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserVerifyEmail);