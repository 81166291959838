import React from "react";
    import { Table, Button, Row, Col, Tooltip } from "antd";
    import { PlusOutlined } from "@ant-design/icons";
    import { Icon } from 'semantic-ui-react';
    
    const { Column } = Table;
    const keyTypesMap = {
        'GOOGLE_MAP_KEY':'Google Maps',
        'OPEN_AI_KEY':'Open AI',
    };
    
    
    const KeysList = ({ 
        keyList, 
        onCreateNewKey, 
        onEditKey, 
        onDeleteKey, 
        onViewKey ,
        onShowHistory
    }) => {
        // console.log("keylist componeent me keys",keyList)
        return (
            <>
                <Table size="small" rowKey="keyId" dataSource={keyList} pagination={false} scroll={{ y: 600 }}>
                    <Column
                        width="6em"
                        render={(text, record) => (
                            <>
                            <Tooltip title ='Edit Key'>
                                <Icon color='blue' name='pencil' className="action-cursor" onClick={() => onEditKey(record.keyId)} /></Tooltip>
                                <Tooltip title ='View Key'> <Icon color='blue' name='eye' className="action-cursor" onClick={() => onViewKey(record.keyId)} /></Tooltip>
                                <Tooltip title ='View Key History'>  <Icon color='blue' name='history' className="action-cursor" onClick={() => onShowHistory(record.keyId)} /></Tooltip>
                            </>
                        )}
                    />
                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        ellipsis={{ showTitle: false }}
                        render={(text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>}
                    />
                    <Column
                        title="Description"
                        dataIndex="description"
                        key="description"
                        ellipsis={{ showTitle: false }}
                        render={(text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>}
                    />
                    <Column
                        title="Type"
                        dataIndex="type"
                        key="type"
                        ellipsis={{ showTitle: false }}
                        render={(text) => <Tooltip placement="topLeft" title={keyTypesMap[text] }>{keyTypesMap[text] }</Tooltip>}
                    />
                    <Column
                        width="6em"
                        render={(text, record) => (
                        <Tooltip title="Delete Key">
                            <Icon color='blue' name='trash alternate outline' className="action-cursor" onClick={() => onDeleteKey(record)} />
                        </Tooltip>
                        )}
                    />
                </Table>
                <Row className="table-footer-row">
                    <Col span={24} className="footer-right-column">
                        <Button type="primary" onClick={onCreateNewKey}>
                            <PlusOutlined /> Add Key
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };
    
    export default KeysList;