import {
    GET_EVENT_DATA_TAG_SUCCESS,
    GET_EVENT_DATA_TAG_FAILURE
} from "./actions";

const initialState = {
    getEventDataTagResult: {
        success: true
    },
    dataTagRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_DATA_TAG_SUCCESS:
            return { ...state, getEventDataTagResult: { success: true }, dataTagRevision: action.payload.dataTagResult};
        case GET_EVENT_DATA_TAG_FAILURE:
            return {
                ...state, getEventDataTagResult: { success: false, ...action.payload.error }, dataTagRevision: null
            };
        default:
            return state;
    }
};
