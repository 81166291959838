import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Pie, Bar } from '@ant-design/plots';
import { LoadingOverlay, DataObjectVerificationList } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Row, Col, Carousel, Card, Switch, Space } from 'antd';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class DataObjectVerifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingRecords: false,
            busy: false,
            busyMessage: "Please wait..."
        }
    }

    componentWillMount() {
        this.setState({
            fetchingRecords: true
        });
        this.props.getDataObjectVerifications(this.props.businessAreaId, this.props.schemaId, this.props.dataObjectId)
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataObjectVerificationsResultChanged(prevProps);
    }

    onGetDataObjectVerificationsResultChanged = (prevProps) => {
        if (this.props.getDataObjectVerificationsResult && this.props.getDataObjectVerificationsResult !== prevProps.getDataObjectVerificationsResult) {
            if (!this.props.getDataObjectVerificationsResult.success) {
                showError("Unable to get verifications.");
            }
            this.setState({
                fetchingRecords: false
            });
        }
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.state.fetchingRecords;
        return isBusy;
    }

    render() {
        return <Row style={{ background: "#fffff", overflowY: "auto", width: "100%", height: "100%" }} className="layout-row">
            <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                <LoadingOverlay
                    busy={this.isBusy()}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {
                    !this.isBusy()
                        ?
                        <DataObjectVerificationList verificationList={this.props.dataObjectVerifications}></DataObjectVerificationList>
                        :
                        <></>
                }
            </Col>
        </Row>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataObjectVerificationsResult: state.dataObjectVerification.getDataObjectVerificationsResult,
        dataObjectVerifications: state.dataObjectVerification.dataObjectVerifications
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataObjectVerifications: (businessAreaId, schemaId, dataObjectId) => dispatch(actions.getDataObjectVerificationsRequest(businessAreaId, schemaId, dataObjectId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectVerifications));