export const GET_USER_BUSINESS_AREA_BY_NAME_REQUEST = "GET_USER_BUSINESS_AREA_BY_NAME_REQUEST";
export const GET_USER_BUSINESS_AREA_BY_NAME_SUCCESS = "GET_USER_BUSINESS_AREA_BY_NAME_SUCCESS";
export const GET_USER_BUSINESS_AREA_BY_NAME_FAILURE = "GET_USER_BUSINESS_AREA_BY_NAME_FAILURE";

export const GET_SCHEMA_MODELS_REQUEST = "GET_SCHEMA_MODELS_REQUEST";
export const GET_SCHEMA_MODELS_SUCCESS = "GET_SCHEMA_MODELS_SUCCESS";
export const GET_SCHEMA_MODELS_FAILURE = "GET_SCHEMA_MODELS_FAILURE";

export const GET_RECENT_SCHEMA_MODELS_REQUEST = "GET_RECENT_SCHEMA_MODELS_REQUEST";
export const GET_RECENT_SCHEMA_MODELS_SUCCESS = "GET_RECENT_SCHEMA_MODELS_SUCCESS";
export const GET_RECENT_SCHEMA_MODELS_FAILURE = "GET_RECENT_SCHEMA_MODELS_FAILURE";

export const GET_SCHEMA_MODELS_NEXT_PAGE_REQUEST = "GET_SCHEMA_MODELS_NEXT_PAGE_REQUEST";
export const GET_SCHEMA_MODELS_NEXT_PAGE_SUCCESS = "GET_SCHEMA_MODELS_NEXT_PAGE_SUCCESS";
export const GET_SCHEMA_MODELS_NEXT_PAGE_FAILURE = "GET_SCHEMA_MODELS_NEXT_PAGE_FAILURE";

export const CREATE_SCHEMA_MODEL_REQUEST = "CREATE_SCHEMA_MODEL_REQUEST";
export const CREATE_SCHEMA_MODEL_SUCCESS = "CREATE_SCHEMA_MODEL_SUCCESS";
export const CREATE_SCHEMA_MODEL_FAILURE = "CREATE_SCHEMA_MODEL_FAILURE";

export const DELETE_SCHEMA_MODEL_REQUEST = "DELETE_SCHEMA_MODEL_REQUEST";
export const DELETE_SCHEMA_MODEL_SUCCESS = "DELETE_SCHEMA_MODEL_SUCCESS";
export const DELETE_SCHEMA_MODEL_FAILURE = "DELETE_SCHEMA_MODEL_FAILURE";

export const GET_SCHEMA_MODEL_REQUEST = "GET_SCHEMA_MODEL_REQUEST";
export const GET_SCHEMA_MODEL_SUCCESS = "GET_SCHEMA_MODEL_SUCCESS";
export const GET_SCHEMA_MODEL_FAILURE = "GET_SCHEMA_MODEL_FAILURE";

export const GET_SCHEMA_MODEL_BY_NAME_REQUEST = "GET_SCHEMA_MODEL_BY_NAME_REQUEST";
export const GET_SCHEMA_MODEL_BY_NAME_SUCCESS = "GET_SCHEMA_MODEL_BY_NAME_SUCCESS";
export const GET_SCHEMA_MODEL_BY_NAME_FAILURE = "GET_SCHEMA_MODEL_BY_NAME_FAILURE";

export const UPDATE_SCHEMA_MODEL_REQUEST = "UPDATE_SCHEMA_MODEL_REQUEST";
export const UPDATE_SCHEMA_MODEL_SUCCESS = "UPDATE_SCHEMA_MODEL_SUCCESS";
export const UPDATE_SCHEMA_MODEL_FAILURE = "UPDATE_SCHEMA_MODEL_FAILURE";

export const GET_USER_SCHEMA_MODELS_PERMISSION_REQUEST = "GET_USER_SCHEMA_MODELS_PERMISSION_REQUEST";
export const GET_USER_SCHEMA_MODELS_PERMISSION_SUCCESS = "GET_USER_SCHEMA_MODELS_PERMISSION_SUCCESS";
export const GET_USER_SCHEMA_MODELS_PERMISSION_FAILURE = "GET_USER_SCHEMA_MODELS_PERMISSION_FAILURE";

export const GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_REQUEST = "GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_REQUEST";
export const GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_SUCCESS = "GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_SUCCESS";
export const GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_FAILURE = "GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_FAILURE";

export const UPLOAD_SCHEMA_MODEL_DEFINITION_REQUEST = "UPLOAD_SCHEMA_MODEL_DEFINITION_REQUEST";
export const UPLOAD_SCHEMA_MODEL_DEFINITION_SUCCESS = "UPLOAD_SCHEMA_MODEL_DEFINITION_SUCCESS";
export const UPLOAD_SCHEMA_MODEL_DEFINITION_FAILURE = "UPLOAD_SCHEMA_MODEL_DEFINITION_FAILURE";

export const getUserBusinessAreaByNameRequest = (businessAreaName) => ({
    type: GET_USER_BUSINESS_AREA_BY_NAME_REQUEST,
    params: {
        businessAreaName
    }
})

export const getUserBusinessAreaByNameSuccess = (userBusinessArea) => ({
    type: GET_USER_BUSINESS_AREA_BY_NAME_SUCCESS,
    payload: userBusinessArea
})

export const getUserBusinessAreaByNameFailure = (error) => ({
    type: GET_USER_BUSINESS_AREA_BY_NAME_FAILURE,
    payload: error
})

export const getSchemaModelsRequest = (businessAreaId) => ({
    type: GET_SCHEMA_MODELS_REQUEST,
    params: {
        businessAreaId
    }
})

export const getSchemaModelsSuccess = (schemaModels) => ({
    type: GET_SCHEMA_MODELS_SUCCESS,
    payload: schemaModels
})

export const getSchemaModelsFailure = (error) => ({
    type: GET_SCHEMA_MODELS_FAILURE,
    payload: error
})

export const getRecentSchemaModelsRequest = () => ({
    type: GET_RECENT_SCHEMA_MODELS_REQUEST
})

export const getRecentSchemaModelsSuccess = (schemaModelsResult) => ({
    type: GET_RECENT_SCHEMA_MODELS_SUCCESS,
    payload: schemaModelsResult
})

export const getRecentSchemaModelsFailure = (error) => ({
    type: GET_RECENT_SCHEMA_MODELS_FAILURE,
    payload: {
        error
    }
})

export const getSchemaModelsNextPageRequest = (businessAreaId, pageKey) => ({
    type: GET_SCHEMA_MODELS_NEXT_PAGE_REQUEST,
    params: {
        businessAreaId,
        pageKey
    }
})

export const getSchemaModelsNextPageSuccess = (schemaModels) => ({
    type: GET_SCHEMA_MODELS_NEXT_PAGE_SUCCESS,
    payload: schemaModels
})

export const getSchemaModelsNextPageFailure = (error) => ({
    type: GET_SCHEMA_MODELS_NEXT_PAGE_FAILURE,
    payload: error
})

export const createSchemModelRequest = (businessAreaId, schemaModel) => ({
    type: CREATE_SCHEMA_MODEL_REQUEST,
    params: {
        businessAreaId,
        schemaModel
    }
})

export const createSchemModelSuccess = ({ schemaModel }) => ({
    type: CREATE_SCHEMA_MODEL_SUCCESS,
    payload: {
        schemaModel
    }
})

export const createSchemModelFailure = (error) => ({
    type: CREATE_SCHEMA_MODEL_FAILURE,
    payload: error
})

export const deleteSchemaModelRequest = (businessAreaId, schemaId) => ({
    type: DELETE_SCHEMA_MODEL_REQUEST,
    params: {
        businessAreaId,
        schemaId
    }
})

export const deleteSchemaModelSuccess = (businessAreaId, schemaId) => ({
    type: DELETE_SCHEMA_MODEL_SUCCESS,
    payload: {
        businessAreaId,
        schemaId
    }
})

export const deleteSchemaModelFailure = (error) => ({
    type: DELETE_SCHEMA_MODEL_FAILURE,
    payload: error
})

export const getSchemaModelRequest = (businessAreaId, schemaId) => ({
    type: GET_SCHEMA_MODEL_REQUEST,
    params: {
        businessAreaId,
        schemaId
    }
})

export const getSchemaModelSuccess = (schemaModel) => ({
    type: GET_SCHEMA_MODEL_SUCCESS,
    payload: schemaModel
})

export const getSchemaModelFailure = (error) => ({
    type: GET_SCHEMA_MODEL_FAILURE,
    payload: error
})

export const getSchemaModelByNameRequest = (businessAreaId, schemaName) => ({
    type: GET_SCHEMA_MODEL_BY_NAME_REQUEST,
    params: {
        businessAreaId,
        schemaName
    }
})

export const getSchemaModelByNameSuccess = (schemaModel) => ({
    type: GET_SCHEMA_MODEL_BY_NAME_SUCCESS,
    payload: {
        schemaModel
    }
})

export const getSchemaModelByNameFailure = (error) => ({
    type: GET_SCHEMA_MODEL_BY_NAME_FAILURE,
    payload: error
})

export const updateSchemaModelRequest = (businessAreaId, schemaId, updateSchemaModelData, fieldIndexMappings) => ({
    type: UPDATE_SCHEMA_MODEL_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        updateSchemaModelData,
        fieldIndexMappings
    }
})

export const updateSchemaModelSuccess = () => ({
    type: UPDATE_SCHEMA_MODEL_SUCCESS
})

export const updateSchemaModelFailure = (error) => ({
    type: UPDATE_SCHEMA_MODEL_FAILURE,
    payload: error
})

export const getUserSchemaModelsPermissionRequest = () => ({
    type: GET_USER_SCHEMA_MODELS_PERMISSION_REQUEST
})

export const getUserSchemaModelsPermissionSuccess = (permission) => ({
    type: GET_USER_SCHEMA_MODELS_PERMISSION_SUCCESS,
    payload: permission
})

export const getUserSchemaModelsPermissionFailure = (error) => ({
    type: GET_USER_SCHEMA_MODELS_PERMISSION_FAILURE,
    payload: error
})

export const getBusinessAreaDataForBusinessAreaRequest = (businessAreaId) => ({
    type: GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_REQUEST,
    params: {
        businessAreaId
    }
})

export const getBusinessAreaDataForBusinessAreaSuccess = (businessAreaDataList) => ({
    type: GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_SUCCESS,
    payload: businessAreaDataList
})

export const getBusinessAreaDataForBusinessAreaFailure = (error) => ({
    type: GET_BUSINESS_AREA_DATA_FOR_BUSINESS_AREA_FAILURE,
    payload: error
})

export const uploadSchemaModelDefinitionRequest = (businessAreaId, dataFile) => ({
    type: UPLOAD_SCHEMA_MODEL_DEFINITION_REQUEST,
    params: {
        businessAreaId,
        dataFile
    }
})

export const uploadSchemaModelDefinitionSuccess = ({ bucket, key, region }) => ({
    type: UPLOAD_SCHEMA_MODEL_DEFINITION_SUCCESS,
    payload: {
        bucket, key, region
    }
})

export const uploadSchemaModelDefinitionFailure = (error) => ({
    type: UPLOAD_SCHEMA_MODEL_DEFINITION_FAILURE,
    payload: {
        error
    }
})