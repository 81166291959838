
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

const getTenant = (state) => state.userLogin.tenant;
const getUserBusinessAreasDict = (state) => state.home.businessAreasDict;

function* getJobs(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs`, { tenantid: tenantId });
    return result.data;
}

function* getJobById(api, tenantId, jobId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/${jobId}`, { tenantid: tenantId });
    return result.data;
}

function* getJob(api, tenantId, jobId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/${jobId}`, { tenantid: tenantId });
    return result.data;
}

function* restartJob(api, tenantId, jobId, version, carryRunInfo, undo) {
    yield call([api, api.put], `${Endpoints.jobs}/api/v1/jobs/${jobId}/restartjob`, { version, carryRunInfo, undo }, { tenantid: tenantId });
}

function* cancelJob(api, tenantId, jobId, version) {
    yield call([api, api.put], `${Endpoints.jobs}/api/v1/jobs/${jobId}/markcancel`, { version }, { tenantid: tenantId });
}

function* getJobsDomainData(api, tenantId, jobId, itemOperation, dataVersion, pageKey) {
    let config = {
        params: {
        }
    };
    if (dataVersion) {
        config.params.dataVersion = dataVersion;
    }
    if (pageKey) {
        config.params.pageKey = pageKey;
    }
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/${jobId}/domaindata/itemoperations/${itemOperation}`, { tenantid: tenantId }, config);
    return result.data;
}

function* getJobsDataObjects(api, tenantId, jobId, itemOperation, dataVersion, pageKey) {
    let config = {
        params: {
        }
    };
    if (dataVersion) {
        config.params.dataVersion = dataVersion;
    }
    if (pageKey) {
        config.params.pageKey = pageKey;
    }
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/${jobId}/dataobjects/itemoperations/${itemOperation}`, { tenantid: tenantId }, config);
    return result.data;
}

function* getRecentDataObjectJobs(api, tenantId) {
    let result = yield call([api, api.get], `${Endpoints.jobs}/api/v1/jobs/dataobjects/recents`, { tenantid: tenantId });
    return result.data;
}

export function* getJobsRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const jobsResult = yield call(getJobs, api, tenant.tenantId);
        yield put(actions.getJobsSuccess(jobsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "jobs");
        yield put(actions.getJobsFailure(errorObject));
    }
}

export function* getJobByIdRequest(api, { jobId }) {
    try {
        let tenant = yield select(getTenant);
        const job = yield call(getJobById, api, tenant.tenantId, jobId);
        yield put(actions.getJobByIdSuccess(job));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "job");
        yield put(actions.getJobByIdFailure(jobId, errorObject));
    }
}

export function* getJobsDomainDataRequest(api, { jobId, itemOperation, dataVersion, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        const domainDataResult = yield call(getJobsDomainData, api, tenant.tenantId, jobId, itemOperation, dataVersion, pageKey);
        yield put(actions.getJobsDomainDataSuccess(domainDataResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "job domain data");
        yield put(actions.getJobsDomainDataFailure(errorObject));
    }
}

export function* getJobsDataObjectsRequest(api, { jobId, itemOperation, dataVersion, pageKey }) {
    try {
        let tenant = yield select(getTenant);
        const dataObjectsResult = yield call(getJobsDataObjects, api, tenant.tenantId, jobId, itemOperation, dataVersion, pageKey);
        yield put(actions.getJobsDataObjectsSuccess(dataObjectsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "job data objects");
        yield put(actions.getJobsDataObjectsFailure(errorObject));
    }
}


export function* restartJobRequest(api, { jobId, version, carryRunInfo, undo }) {
    try {
        let tenant = yield select(getTenant);
        yield call(restartJob, api, tenant.tenantId, jobId, version, carryRunInfo, undo);
        let updatedJob = yield call(getJob, api, tenant.tenantId, jobId);
        yield put(actions.restartJobSuccess(updatedJob));
    } catch (error) {
        let errorObject = errorHandler(error, "Restart", "job");
        yield put(actions.restartJobFailure(errorObject));
    }
}

export function* cancelJobRequest(api, { jobId, version }) {
    try {
        let tenant = yield select(getTenant);
        yield call(cancelJob, api, tenant.tenantId, jobId, version);
        let updatedJob = yield call(getJob, api, tenant.tenantId, jobId);
        yield put(actions.cancelJobSuccess(updatedJob));
    } catch (error) {
        let errorObject = errorHandler(error, "Cancel", "job");
        yield put(actions.cancelJobFailure(errorObject));
    }
}

export function* getRecentDataObjectJobsRequest(api) {
    try {
        let tenant = yield select(getTenant);
        const jobsResult = yield call(getRecentDataObjectJobs, api, tenant.tenantId);
        let userBusinessAreasDict = yield select(getUserBusinessAreasDict);
        if (jobsResult && jobsResult.Items && jobsResult.Items.length > 0) {
            for (let job of jobsResult.Items) {
                if (userBusinessAreasDict && userBusinessAreasDict[job.businessAreaId]) {
                    job.businessAreaName = userBusinessAreasDict[job.businessAreaId].name;
                }
            }
        }
        yield put(actions.getRecentDataObjectJobsSuccess(jobsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "recent job");
        yield put(actions.getRecentDataObjectJobsFailure(errorObject));
    }
}

export function* watchGetJobsRequest(api, params) {
    yield call(getJobsRequest, api);
}

export function* watchGetJobByIdRequest(api, { params }) {
    yield call(getJobByIdRequest, api, params);
}

export function* watchGetJobsDomainDataRequest(api, { params }) {
    yield call(getJobsDomainDataRequest, api, params);
}

export function* watchGetJobsDataObjectsRequest(api, { params }) {
    yield call(getJobsDataObjectsRequest, api, params);
}

export function* watchRestartJobRequest(api, { params }) {
    yield call(restartJobRequest, api, params);
}

export function* watchCancelJobRequest(api, { params }) {
    yield call(cancelJobRequest, api, params);
}

export function* watchGetRecentDataObjectJobsRequest(api, params) {
    yield call(getRecentDataObjectJobsRequest, api);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_JOBS_REQUEST, watchGetJobsRequest, api);
    yield takeLatest(actions.GET_JOB_BY_ID_REQUEST, watchGetJobByIdRequest, api);
    yield takeLatest(actions.GET_JOBS_DOMAIN_DATA_REQUEST, watchGetJobsDomainDataRequest, api);
    yield takeLatest(actions.GET_JOBS_DATA_OBJECTS_REQUEST, watchGetJobsDataObjectsRequest, api);
    yield takeLatest(actions.RESTART_JOB_REQUEST, watchRestartJobRequest, api);
    yield takeLatest(actions.CANCEL_JOB_REQUEST, watchCancelJobRequest, api);
    yield takeLatest(actions.GET_RECENT_DATAOBJECT_JOBS_REQUEST, watchGetRecentDataObjectJobsRequest, api);
}