import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import {
    Handle,
    Position
} from 'reactflow';
import { DoubleRightOutlined, DoubleLeftOutlined, CaretDownFilled, WarningTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import 'reactflow/dist/style.css';

const { Text, Link, Title } = Typography;

function LineageNode({ data }) {

    const onChange = (checked) => {
        if (checked) {
            data.onNodeExpand();
        }
        else {
            data.onNodeContract();
        }
    }

    return (
        <>
            {
                data.destination
                    ?
                    <Handle type="target" position={Position.Left} />
                    :
                    <></>
            }
            <Row style={{
                flexDirection: "column",
                flexWrap: false,
                padding: "0rem 0.5rem",
                width: "100%",
                height: "100%"
            }}>
                <Row style={{ justifyContent: "space-between", width: "100%" }}>
                    <Col>
                        <Text style={{ fontSize: "8px" }}>{data.title}</Text>
                    </Col>
                    <Col style={{ marginLeft: "1rem" }}>
                        <Space direction="horizontal" size="small">
                            {data.onMoreClick && data.node.fields && data.node.fields.length > 0
                                ?
                                <Tooltip title={"Show detail view"}>
                                    <Button type="link" size="small" style={{ fontSize: "1vh", cursor: "pointer", zIndex: 101 }} onClick={() => data.onMoreClick(data.node)}>More</Button>
                                </Tooltip>
                                :
                                <></>
                            }
                            {data.onLessClick && data.node.fields && data.node.fields.length > 0
                                ?
                                <Tooltip title={"Show normal view"}>
                                    <Button type="link" size="small" style={{ fontSize: "1vh", cursor: "pointer", zIndex: 101 }} onClick={() => data.onLessClick(data.node)}>Less</Button>
                                </Tooltip>
                                :
                                <></>
                            }
                            {data.node.nodes && data.node.nodes.length > 0 ?
                                <Tooltip title={"Expand node"}>
                                    <Switch
                                        style={{ zIndex: 101 }}
                                        size="small"
                                        onChange={onChange}
                                        checkedChildren={<DoubleLeftOutlined />}
                                        unCheckedChildren={<DoubleRightOutlined />}
                                        defaultChecked={false}
                                    />
                                </Tooltip>
                                :
                                <></>
                            }
                            <Tooltip title={"Actions"}>
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: 'View',
                                                key: 'View',
                                                disabled: !data.permission.canView,
                                                onClick: () => data.onActionClick("VIEW")
                                            },
                                            {
                                                label: 'Edit',
                                                key: 'Edit',
                                                disabled: !data.permission.canEdit || data.disableEditAction,
                                                onClick: () => data.onActionClick("EDIT")
                                            }
                                        ]
                                    }}>
                                    <CaretDownFilled style={{ fontSize: "1.2vh", color: "#0E9FFF" }} />
                                </Dropdown>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ flexDirection: "row", flexWrap: "nowrap" }}>
                    <Col style={{ flexWrap: "nowrap" }}>
                        <Tooltip title={data.name}>
                            <Text ellipsis={true} style={{ fontSize: "10px" }} type="secondary">{data.name}</Text>
                        </Tooltip>
                    </Col>
                    {
                        data.type === "DATA_SET"
                            ?
                            <Col style={{ paddingLeft: "0.5rem" }}>
                                {
                                    data.node.erroredRecordsCount
                                        ?
                                        <WarningTwoTone twoToneColor="#FF0000" />
                                        :
                                        <CheckCircleOutlined style={{ color: "#00cf18" }} />
                                }
                            </Col>
                            :
                            <></>
                    }
                </Row>
                {
                    data.type === "DATA_SET" && data.node.recordCount !== undefined
                        ?
                        <Row style={{ flexDirection: "row", flexWrap: "nowrap" }}>
                            <Col style={{ flex: 1, flexWrap: "nowrap" }}>
                                <Tooltip title={data.node.recordCount}>
                                    <Text ellipsis={true} style={{ fontSize: "10px" }}>{data.node.recordCount}</Text>
                                </Tooltip>
                            </Col>
                        </Row>
                        :
                        <></>
                }
            </Row>
            {
                data.source
                    ?
                    <Handle type="source" position={Position.Right} />
                    :
                    <></>
            }
        </>
    );
}

export default LineageNode;