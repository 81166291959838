

export async function getArrayBufferFromFileObject(fileObj) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onloadend = (obj) => {
            resolve(fileReader.result);
        };
        fileReader.readAsArrayBuffer(fileObj);
    })
}

export function sanitizeFileName(name) {
    return name.replace(/ /g, "_").replace(/[^0-9a-zA-Z_.]+/g, "");
}