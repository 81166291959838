export const GET_USER_EVENTS_REQUEST = "GET_USER_EVENTS_REQUEST";
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS";
export const GET_USER_EVENTS_FAILURE = "GET_USER_EVENTS_FAILURE";

export const getUserEventsRequest = (userId) => ({
    type: GET_USER_EVENTS_REQUEST,
    params: {
        userId
    }
})

export const getUserEventsSuccess = (userEventResults) => ({
    type: GET_USER_EVENTS_SUCCESS,
    payload: userEventResults
})

export const getUserEventsFailure = (error) => ({
    type: GET_USER_EVENTS_FAILURE,
    payload: error
})