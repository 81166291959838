import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { BusinessAreaHistoryList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class EventBusinessArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        if (!this.props.itemOperation) {
            this.setState({
                busy: true,
                busyMessage: "Getting business area...",
            });
            this.props.getEventBusinessArea(this.props.eventId, this.props.businessAreaId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventBusinessAreaResultUpdate(prevProps);
    }

    onGetEventBusinessAreaResultUpdate = (prevProps) => {
        if (this.props.getEventBusinessAreaResult && this.props.getEventBusinessAreaResult !== prevProps.getEventBusinessAreaResult) {
            if (!this.props.getEventBusinessAreaResult.success) {
                if (this.props.getEventBusinessAreaResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get business area at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Revision - </Text><Text type="secondary" italic>{`(Business Area: ${this.props.businessAreaName})`}</Text></Title>}
                            content={this.props.businessAreaRevision ?
                                <BusinessAreaHistoryList
                                    businessAreaRevisions={[this.props.businessAreaRevision]}>
                                </BusinessAreaHistoryList>
                                :
                                <Empty></Empty>}>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventBusinessAreaResult: state.eventBusinessArea.getEventBusinessAreaResult,
        businessAreaRevision: state.eventBusinessArea.businessAreaRevision
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBusinessArea: (eventId, businessAreaId) => dispatch(actions.getEventBusinessAreaRequest(eventId, businessAreaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventBusinessArea));