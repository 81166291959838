import {
    GET_EVENT_BUSINESS_AREA_SUCCESS,
    GET_EVENT_BUSINESS_AREA_FAILURE
} from "./actions";

const initialState = {
    getEventBusinessAreaResult: {
        success: true
    },
    businessAreaRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_BUSINESS_AREA_SUCCESS:
            return { ...state, getEventBusinessAreaResult: { success: true }, businessAreaRevision: action.payload };
        case GET_EVENT_BUSINESS_AREA_FAILURE:
            return {
                ...state, getEventBusinessAreaResult: { success: false, ...action.payload }, businessAreaRevision: null
            };
        default:
            return state;
    }
};
