import React, { useState, useEffect } from "react";
import { Table, Pagination, Row, Col, Typography } from "antd";
import moment from 'moment';
import { BatchProcessResult, Tooltip, FullHeightContainerLayout } from 'components';
import {
    PlayCircleTwoTone,
    StopTwoTone
} from '@ant-design/icons';
import { Icon } from 'semantic-ui-react';
const { Column } = Table;
const { Link, Text } = Typography;

const AdminConsoleRunningJobList = ({ jobList }) => {

    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedJobList, setPaginatedJobList] = useState(jobList && jobList.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setPaginatedJobList(jobList && jobList.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [jobList]);

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedJobList = jobList && jobList.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedJobList(currentPaginatedJobList);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
    }
    return (
        <FullHeightContainerLayout
            showHeader={false}
            content={
                <Table size="small"
                    rowKey="jobId"
                    dataSource={paginatedJobList}
                    pagination={false}
                    className="container-height-100"
                    scroll={{ y: `100vh` }}>
                    <Column
                        width="15rem"
                        dataIndex={"tenantName"}
                        title="Tenant"
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />
                    <Column
                        width="15rem"
                        dataIndex={"jobId"}
                        title="Job ID"
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />
                    <Column
                        width="15rem"
                        dataIndex={"name"}
                        title={"Name"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />
                    <Column
                        width="15rem"
                        dataIndex={"description"}
                        title={"Description"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />
                    <Column
                        width="7rem"
                        dataIndex={"status"}
                        title={"Status"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            return <Tooltip placement="topLeft" title="Running"><span style={{ color: "#1F81FF" }}>Running</span></Tooltip>
                        }}
                    />
                    <Column
                        width="15rem"
                        dataIndex={"createdByEmail"}
                        title={"User"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value || job.createdBy}</Tooltip>}
                    />
                    <Column
                        width="8rem"
                        dataIndex={"startedDate"}
                        title={"Started Date"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                displayValue = moment.utc(value).toDate().toLocaleDateString();
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="8rem"
                        dataIndex={"startedDate"}
                        title={"Started Time"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                displayValue = moment.utc(value).toDate().toLocaleTimeString();
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                </Table>
            }
            showFooter={true}
            footer={
                <Row justify="end" style={{ padding: "0.2rem 0rem" }}>
                    <Col span={24} style={{ textAlign: "end" }}>
                        <Pagination
                            size="small"
                            hideOnSinglePage={true}
                            total={jobList.length}
                            defaultPageSize={pagination.defaultPageSize}
                            pageSize={pagination.pageSize}
                            responsive={true}
                            showSizeChanger={false}
                            onChange={onPageClick} />
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    );
};

export default AdminConsoleRunningJobList;