import {
    GET_EVENT_DATA_OBJECT_VERIFICATIONS_SUCCESS,
    GET_EVENT_DATA_OBJECT_VERIFICATIONS_FAILURE
} from "./actions";

const initialState = {
    getEventDataObjectVerificationsResult: {
        success: true
    },
    dataObjectVerifications: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_DATA_OBJECT_VERIFICATIONS_SUCCESS:
            return { ...state, getEventDataObjectVerificationsResult: { success: true }, dataObjectVerifications: action.payload.result };
        case GET_EVENT_DATA_OBJECT_VERIFICATIONS_FAILURE:
            return {
                ...state, getEventDataObjectVerificationsResult: { success: false, ...action.payload.error }, dataObjectVerifications: null
            };
        default:
            return state;
    }
};
