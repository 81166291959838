import React from "react";
import { Table, List, Space, Row, Col, Typography } from "antd";
import { ListRecordAttributeItem } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const displayLabels = {
    "name": "Name",
    "type": "Type",
    "active": "Active",
    "updatedByEmail": "Updated by",
    "updatedDate": "Updated at",
    "deleted": "Deleted"
}

export default function ({ record }) {
    let currentData = { ...record.data };
    let previousData = { ...(record.previousData || {}) };
    return <>
        <Row className="middle-row">
            <Col span={24}>
                <Title level={5}><Text>{record.name}</Text></Title>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <List
                    size="small"
                    bordered
                    dataSource={Object.keys(currentData)}
                    renderItem={item => {
                        if (item === "systemConnection") {
                            let currentUserItem = currentData["systemConnection"];
                            let previousUserItem = previousData && previousData["systemConnection"];
                            return Object.keys(currentUserItem).map(userKey => {
                                if (displayLabels[userKey]) {
                                    return <ListRecordAttributeItem name={displayLabels[userKey]} value={currentUserItem[userKey]} previousValue={previousUserItem && previousUserItem[userKey]}></ListRecordAttributeItem>
                                }
                                return <></>
                            });
                        }
                        if (displayLabels[item]) {
                            if (item === "updatedDate") {
                                return <ListRecordAttributeItem name={displayLabels[item]} value={moment.utc(currentData[item]).toDate().toLocaleString()} previousValue={previousData[item] && moment.utc(previousData[item]).toDate().toLocaleString()}></ListRecordAttributeItem>
                            }
                            return <ListRecordAttributeItem name={displayLabels[item]} value={currentData[item]} previousValue={previousData && previousData[item]}></ListRecordAttributeItem>
                        }
                        return <></>
                    }}
                />
            </Col>
        </Row>
    </>
}