import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { DataSetHistoryList, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class EventDataSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        if (!this.props.itemOperation) {
            this.setState({
                busy: true,
                busyMessage: "Getting dataset...",
            });
            this.props.getEventDataSet(this.props.eventId, this.props.dataSetId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventDataSetResultUpdate(prevProps);
    }

    onGetEventDataSetResultUpdate = (prevProps) => {
        if (this.props.getEventDataSetResult && this.props.getEventDataSetResult !== prevProps.getEventDataSetResult) {
            if (!this.props.getEventDataSetResult.success) {
                if (this.props.getEventDataSetResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Revision - </Text><Text type="secondary" italic>{`(Dataset: ${this.props.dataSetName})`}</Text></Title>}
                            content={
                                this.props.dataSetRevision
                                    ?
                                    <DataSetHistoryList
                                        dataSetRevisions={[this.props.dataSetRevision]}>
                                    </DataSetHistoryList>
                                    :
                                    <Empty></Empty>
                            }>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventDataSetResult: state.eventDataSet.getEventDataSetResult,
        dataSetRevision: state.eventDataSet.dataSetRevision
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventDataSet: (eventId, dataSetId) => dispatch(actions.getEventDataSetRequest(eventId, dataSetId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventDataSet));