export const REGISTER_NOTIFICATION_REQUEST = "REGISTER_NOTIFICATION_REQUEST";
export const UNREGISTER_NOTIFICATION_REQUEST = "UNREGISTER_NOTIFICATION_REQUEST";

export const registerNotificationRequest = (tenantId, userId) => ({
    type: REGISTER_NOTIFICATION_REQUEST,
    params: {
        tenantId,
        userId
    }
})

export const unRegisterNotificationRequest = (tenantId, userId) => ({
    type: UNREGISTER_NOTIFICATION_REQUEST,
    params: {
        tenantId,
        userId
    }
})