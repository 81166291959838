export const GET_DUPLICATE_MERGE_RULES_REQUEST = "GET_DUPLICATE_MERGE_RULES_REQUEST";
export const GET_DUPLICATE_MERGE_RULES_SUCCESS = "GET_DUPLICATE_MERGE_RULES_SUCCESS";
export const GET_DUPLICATE_MERGE_RULES_FAILURE = "GET_DUPLICATE_MERGE_RULES_FAILURE";

export const SAVE_DUPLICATE_MERGE_RULES_REQUEST = "SAVE_DUPLICATE_MERGE_RULES_REQUEST";
export const SAVE_DUPLICATE_MERGE_RULES_SUCCESS = "SAVE_DUPLICATE_MERGE_RULES_SUCCESS";
export const SAVE_DUPLICATE_MERGE_RULES_FAILURE = "SAVE_DUPLICATE_MERGE_RULES_FAILURE";

export const getDuplicateMergeRulesRequest = (duplicateSetId, duplicateSetVersion, groupId, dataObjectId) => ({
    type: GET_DUPLICATE_MERGE_RULES_REQUEST,
    params: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId
    }
})

export const getDuplicateMergeRulesSuccess = (result) => ({
    type: GET_DUPLICATE_MERGE_RULES_SUCCESS,
    payload: {
        result
    }
})

export const getDuplicateMergeRulesFailure = (error) => ({
    type: GET_DUPLICATE_MERGE_RULES_FAILURE,
    payload: {
        error
    }
})

export const saveDuplicateMergeRulesRequest = (duplicateSetId, duplicateSetVersion, groupId, dataObjectId, rules) => ({
    type: SAVE_DUPLICATE_MERGE_RULES_REQUEST,
    params: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId,
        rules
    }
})

export const saveDuplicateMergeRulesSuccess = (result) => ({
    type: SAVE_DUPLICATE_MERGE_RULES_SUCCESS,
    payload: {
        result
    }
})

export const saveDuplicateMergeRulesFailure = (error) => ({
    type: SAVE_DUPLICATE_MERGE_RULES_FAILURE,
    payload: {
        error
    }
})