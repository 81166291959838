import {
    GET_EVENT_APP_CLIENT_SUCCESS,
    GET_EVENT_APP_CLIENT_FAILURE
} from "./actions";

const initialState = {
    getEventAppClientResult: {
        success: true
    },
    appClientRevision: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_APP_CLIENT_SUCCESS:
            return { ...state, getEventAppClientResult: { success: true }, appClientRevision: action.payload.appClientRevision };
        case GET_EVENT_APP_CLIENT_FAILURE:
            return {
                ...state, getEventAppClientResult: { success: false, ...action.payload.error }, appClientRevision: null
            };
        default:
            return state;
    }
};
