export const GET_BUSINESS_AREA_HISTORY_REQUEST = "GET_BUSINESS_AREA_HISTORY_REQUEST";
export const GET_BUSINESS_AREA_HISTORY_SUCCESS = "GET_BUSINESS_AREA_HISTORY_SUCCESS";
export const GET_BUSINESS_AREA_HISTORY_FAILURE = "GET_BUSINESS_AREA_HISTORY_FAILURE";

export const GET_BUSINESS_AREA_EVENTS_REQUEST = "GET_BUSINESS_AREA_EVENTS_REQUEST";
export const GET_BUSINESS_AREA_EVENTS_SUCCESS = "GET_BUSINESS_AREA_EVENTS_SUCCESS";
export const GET_BUSINESS_AREA_EVENTS_FAILURE = "GET_BUSINESS_AREA_EVENTS_FAILURE";


export const getBusinessAreaHistoryRequest = (businessAreaId) => ({
    type: GET_BUSINESS_AREA_HISTORY_REQUEST,
    params: {
        businessAreaId
    }
})

export const getBusinessAreaHistorySuccess = (businessAreaHistoryResult) => ({
    type: GET_BUSINESS_AREA_HISTORY_SUCCESS,
    payload: businessAreaHistoryResult
})

export const getBusinessAreaHistoryFailure = (error) => ({
    type: GET_BUSINESS_AREA_HISTORY_FAILURE,
    payload: error
})

export const getBusinessAreaEventsRequest = (businessAreaId) => ({
    type: GET_BUSINESS_AREA_EVENTS_REQUEST,
    params: {
        businessAreaId
    }
})

export const getBusinessAreaEventsSuccess = (businessAreaEventsResult) => ({
    type: GET_BUSINESS_AREA_EVENTS_SUCCESS,
    payload: businessAreaEventsResult
})

export const getBusinessAreaEventsFailure = (error) => ({
    type: GET_BUSINESS_AREA_EVENTS_FAILURE,
    payload: error
})