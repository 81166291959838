import {
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAILURE
} from "./actions";

const initialState = {
    updateUserPasswordResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_PASSWORD_SUCCESS:
            return { ...state, updateUserPasswordResult: { success: true } };
        case UPDATE_USER_PASSWORD_FAILURE:
            return { ...state, updateUserPasswordResult: { success: false, ...action.payload } };
        default:
            return state;
    }
};
