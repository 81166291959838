import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, DataTagHistoryList, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class DataTagAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
        this.props.getDataTagHistory(this.props.dataTag.tagId);
        this.props.getDataTagEvents(this.props.dataTag.tagId);
        this.setState({
            fetchingHistory: true,
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataTagHistoryResultUpdate(prevProps);
        this.onGetDataTagEventsResultUpdate(prevProps);
    }

    onGetDataTagHistoryResultUpdate = (prevProps) => {
        if (this.props.getDataTagHistoryResult && this.props.getDataTagHistoryResult !== prevProps.getDataTagHistoryResult) {
            if (!this.props.getDataTagHistoryResult.success) {
                if (this.props.getDataTagHistoryResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get data tag history.");
                }
            }
            this.setState({
                fetchingHistory: false
            });
        }
    }

    onGetDataTagEventsResultUpdate = (prevProps) => {
        if (this.props.getDataTagEventsResult && this.props.getDataTagEventsResult !== prevProps.getDataTagEventsResult) {
            if (!this.props.getDataTagEventsResult.success) {
                if (this.props.getDataTagEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get data tag events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={
                    <Tabs defaultActiveKey="1" className="data-audit-tabs">
                        <TabPane tab="Events" key="1">
                            {
                                this.state.fetchingEvents
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting data tag events...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <EventList eventList={this.props.dataTagEventsResult.Items}>
                                            </EventList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                        <TabPane tab="Revisions" key="2">
                            {
                                this.state.fetchingHistory
                                    ?
                                    <LoadingOverlay
                                        busy={true}
                                        spinner
                                        message="Getting data tag history...">
                                    </LoadingOverlay>
                                    :
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <DataTagHistoryList
                                                dataTagRevisions={this.props.dataTagHistoryResult.Items}>
                                            </DataTagHistoryList>
                                        }>
                                    </FullHeightContainerLayout>
                            }
                        </TabPane>
                    </Tabs>
                }>
            </FullHeightContainerLayout>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataTagHistoryResult: state.dataTagAudit.getDataTagHistoryResult,
        dataTagHistoryResult: state.dataTagAudit.dataTagHistoryResult,
        getDataTagEventsResult: state.dataTagAudit.getDataTagEventsResult,
        dataTagEventsResult: state.dataTagAudit.dataTagEventsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataTagHistory: (tagId) => dispatch(actions.getDataTagHistoryRequest(tagId)),
        getDataTagEvents: (tagId) => dispatch(actions.getDataTagEventsRequest(tagId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTagAudit));