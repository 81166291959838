import {
    GET_DATA_TAG_HISTORY_SUCCESS,
    GET_DATA_TAG_HISTORY_FAILURE,

    GET_DATA_TAG_EVENTS_SUCCESS,
    GET_DATA_TAG_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getDataTagHistoryResult: {
        success: true
    },
    dataTagHistoryResult: {
        Items: []
    },
    getDataTagEventsResult: {
        success: true
    },
    dataTagEventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_TAG_HISTORY_SUCCESS:
            return { ...state, getDataTagHistoryResult: { success: true }, dataTagHistoryResult: action.payload.dataTagHistoryResult };
        case GET_DATA_TAG_HISTORY_FAILURE:
            return { ...state, getDataTagHistoryResult: { success: false, ...action.payload.error }, dataTagHistoryResult: { Items: [] } };
        case GET_DATA_TAG_EVENTS_SUCCESS:
            return { ...state, getDataTagEventsResult: { success: true }, dataTagEventsResult: action.payload.dataTagEventsResult };
        case GET_DATA_TAG_EVENTS_FAILURE:
            return {
                ...state, getDataTagEventsResult: { success: false, ...action.payload.error }, dataTagEventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
