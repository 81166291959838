import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Typography, Spin } from 'antd';
import { AdminConsoleRunningJobList, FullHeightContainerLayout, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';

const { Title } = Typography;

class AdminConsoleJobs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingJobs: false,
            busy: false,
            busyMessage: "Please wait...",
            refresh: false
        }
    }

    componentWillMount() {
        this.props.getAllRunningJobs();
        if (!this.props.getAllRunningJobsResult ||
            !this.props.getAllRunningJobsResult.Items ||
            this.props.getAllRunningJobsResult.Items.length === 0) {
            this.setState({
                fetchingJobs: true
            })
        }
        else {
            this.setState({
                refresh: true
            });
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.GetAllRunningJobsResultChanged(prevProps);
    }

    GetAllRunningJobsResultChanged = (prevProps) => {
        if (this.props.getAllRunningJobsResult && this.props.getAllRunningJobsResult !== prevProps.getAllRunningJobsResult) {
            if (!this.props.getAllRunningJobsResult.success) {
                showError("Could not able to get running jobs at this moment.");
            }
            this.setState({
                fetchingJobs: false,
                refresh: false
            });
        }
    }


    componentWillUnmount() {
    }

    fetchInProgress = () => {
        let isBusy = this.state.fetchingJobs;
        return isBusy;
    }

    isBusy = () => {
        return this.state.isBusy || this.fetchInProgress();
    }

    render() {
        return <FullHeightContainerLayout
            showHeader={true}
            header={<Title level={4}>Active Jobs Running Across Tenants{this.state.refresh ? <> <Spin size="small" /></> : <></>}</Title>}
            content={
                <>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.fetchInProgress() ?
                        (
                            <AdminConsoleRunningJobList jobList={this.props.allRunningJobsResult.Items}></AdminConsoleRunningJobList>
                        )
                        :
                        <></>
                    }
                </>
            } />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getAllRunningJobsResult: state.adminConsole.getAllRunningJobsResult,
        allRunningJobsResult: state.adminConsole.allRunningJobsResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllRunningJobs: () => dispatch(actions.getAllRunningJobsRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminConsoleJobs));