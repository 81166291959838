import React, { useState, useEffect } from 'react';
import { Upload, Typography } from 'antd';
import { InboxOutlined } from "@ant-design/icons";
import { LoadingOverlay, DataPreview } from 'components';
import Papaparse from 'papaparse/papaparse';

const { Text, Link } = Typography;

const { Dragger } = Upload;

function FileDragger({ dataHeader = true, disabled = false, destinationConnection, onCancelUpload, onReadingFile, onReadFileComplete, onReadFileError, previewColumns = [], ...props }) {

    const [header, setHeader] = useState(dataHeader);

    let [fileReadResult, setFileReadResult] = useState({
        file: null,
        data: null,
        columns: null,
        error: null
    });

    const [readingFile, setReadingFile] = useState(false);

    useEffect(() => {
        if (destinationConnection && destinationConnection.fileInfo) {
            fileReadResult = { ...fileReadResult };
            fileReadResult.file = destinationConnection;
            setFileReadResult(fileReadResult);
            setReadingFile(true);
            onReadingFile && onReadingFile(fileReadResult);
            Papaparse.parse(destinationConnection.fileInfo.downloadUrl, {
                download: true,
                delimiter: ",",
                header: { header },
                preview: 100,
                encoding: "UTF-8",
                worker: true,
                complete: onFileReadComplete,
                error: onFileReadError
            });
        }
    }, []);

    useEffect(() => {
        setHeader(dataHeader);
    }, [dataHeader]);

    const onUploadFileChange = (file) => {
        fileReadResult = { ...fileReadResult };
        fileReadResult.file = file;
        setFileReadResult(fileReadResult);
        setReadingFile(true);
        onReadingFile && onReadingFile(fileReadResult);
        Papaparse.parse(file, {
            delimiter: ",",
            header: { header },
            preview: 100,
            encoding: "UTF-8",
            worker: true,
            complete: onFileReadComplete,
            error: onFileReadError
        });
    }

    const onFileReadComplete = (results) => {
        if (results) {
            fileReadResult = { ...fileReadResult };
            fileReadResult.data = results.data;
            //Remove last empty row.
            if (fileReadResult.data.length > 0) {
                let lastRow = fileReadResult.data[fileReadResult.data.length - 1];
                let lastRowValues = Object.values(lastRow);
                if (!lastRowValues.some(value => !value === false)) {
                    fileReadResult.data.splice(fileReadResult.data.length - 1, 1);
                }
            }
            fileReadResult.columns = results.meta.fields.filter(field => field);
            fileReadResult.error = null;
            setFileReadResult(fileReadResult);
            setReadingFile(false);
            onReadFileComplete && onReadFileComplete(fileReadResult);
        }
    }

    const onFileReadError = (error) => {
        fileReadResult = { ...fileReadResult };
        fileReadResult.data = null;
        fileReadResult.columns = null;
        fileReadResult.error = error;
        setFileReadResult(fileReadResult);
        setReadingFile(false);
        onReadFileError && onReadFileError(fileReadResult);
    }

    const onFilePreviewClose = () => {
        fileReadResult = {
            file: null,
            data: null,
            columns: null,
            error: null
        };
        setFileReadResult(fileReadResult);
        onCancelUpload && onCancelUpload(fileReadResult);
    }

    return <>
        <LoadingOverlay
            busy={readingFile}
            spinner
            message={"Preparing preview..."}>
        </LoadingOverlay>
        {
            fileReadResult.data ?
                <DataPreview
                    title={<><Text>{fileReadResult && fileReadResult.file && fileReadResult.file.name}</Text> - <Text type="secondary">Data Preview (100 Records)</Text></>}
                    data={fileReadResult.data}
                    columns={fileReadResult.columns}
                    dataSchemaColumns={previewColumns}
                    onClose={onFilePreviewClose}>
                </DataPreview>
                :
                <Dragger
                    accept=".csv"
                    name="file"
                    multiple={false}
                    showUploadList={false}
                    disabled={disabled}
                    action={onUploadFileChange}
                    {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a singleupload.
                    </p>
                </Dragger>
        }
    </>
}

export default FileDragger