export const GET_DATA_OBJECTS_DUPLICATE_JOBS_REQUEST = "GET_DATA_OBJECTS_DUPLICATE_JOBS_REQUEST";
export const GET_DATA_OBJECTS_DUPLICATE_JOBS_SUCCESS = "GET_DATA_OBJECTS_DUPLICATE_JOBS_SUCCESS";
export const GET_DATA_OBJECTS_DUPLICATE_JOBS_FAILURE = "GET_DATA_OBJECTS_DUPLICATE_JOBS_FAILURE";

export const SEARCH_DUPLICATE_DATA_OBJECTS_REQUEST = "SEARCH_DUPLICATE_DATA_OBJECTS_REQUEST";
export const SEARCH_DUPLICATE_DATA_OBJECTS_SUCCESS = "SEARCH_DUPLICATE_DATA_OBJECTS_SUCCESS";
export const SEARCH_DUPLICATE_DATA_OBJECTS_FAILURE = "SEARCH_DUPLICATE_DATA_OBJECTS_FAILURE";

export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_REQUEST = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_REQUEST";
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUCCESS = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUCCESS";
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_FAILURE = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_FAILURE";

export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_REQUEST = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_REQUEST"
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_SUCCESS = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_SUCCESS"
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_FAILURE = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_FAILURE"

export const GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST = "GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST";
export const GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS = "GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS";
export const GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE = "GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE";

export const NOMINATE_SURVIVAL_RECORD_REQUEST = "NOMINATE_SURVIVAL_RECORD_REQUEST";
export const NOMINATE_SURVIVAL_RECORD_SUCCESS = "NOMINATE_SURVIVAL_RECORD_SUCCESS";
export const NOMINATE_SURVIVAL_RECORD_FAILURE = "NOMINATE_SURVIVAL_RECORD_FAILURE";

export const REMOVE_SURVIVAL_RECORD_NOMINATION_REQUEST = "REMOVE_SURVIVAL_RECORD_NOMINATION_REQUEST";
export const REMOVE_SURVIVAL_RECORD_NOMINATION_SUCCESS = "REMOVE_SURVIVAL_RECORD_NOMINATION_SUCCESS";
export const REMOVE_SURVIVAL_RECORD_NOMINATION_FAILURE = "REMOVE_SURVIVAL_RECORD_NOMINATION_FAILURE";

export const RUN_DEDUPLICATE_JOB_REQUEST = "RUN_DEDUPLICATE_JOB_REQUEST";
export const RUN_DEDUPLICATE_JOB_SUCCESS = "RUN_DEDUPLICATE_JOB_SUCCESS";
export const RUN_DEDUPLICATE_JOB_FAILURE = "RUN_DEDUPLICATE_JOB_FAILURE";

export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_REQUEST = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_REQUEST";
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_SUCCESS = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_SUCCESS";
export const GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_FAILURE = "GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_FAILURE";

export const GET_DEDUPLICATED_RECORDS_REQUEST = "GET_DEDUPLICATED_RECORDS_REQUEST";
export const GET_DEDUPLICATED_RECORDS_SUCCESS = "GET_DEDUPLICATED_RECORDS_SUCCESS";
export const GET_DEDUPLICATED_RECORDS_FAILURE = "GET_DEDUPLICATED_RECORDS_FAILURE";

export const getDataObjectsDuplicateJobsRequest = () => ({
    type: GET_DATA_OBJECTS_DUPLICATE_JOBS_REQUEST
})

export const getDataObjectsDuplicateJobsSuccess = (jobResult) => ({
    type: GET_DATA_OBJECTS_DUPLICATE_JOBS_SUCCESS,
    payload: {
        jobResult
    }
})

export const getDataObjectsDuplicateJobsFailure = (error) => ({
    type: GET_DATA_OBJECTS_DUPLICATE_JOBS_FAILURE,
    payload: {
        error
    }
})

export const searchDuplicateDataObjectsRequest = (name, description, businessAreaId, schemaId, dataSetId, dataTags) => ({
    type: SEARCH_DUPLICATE_DATA_OBJECTS_REQUEST,
    params: {
        name,
        description,
        businessAreaId,
        schemaId,
        dataSetId,
        dataTags
    }
})

export const searchDuplicateDataObjectsSuccess = (job) => ({
    type: SEARCH_DUPLICATE_DATA_OBJECTS_SUCCESS,
    payload: {
        job
    }
})

export const searchDuplicateDataObjectsFailure = (error) => ({
    type: SEARCH_DUPLICATE_DATA_OBJECTS_FAILURE,
    payload: {
        error
    }
})

export const getDuplicateDataObjectsResultSetRequest = (jobId) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_REQUEST,
    params: {
        jobId
    }
})

export const getDuplicateDataObjectsResultSetSuccess = ({ jobId, result }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_SUCCESS,
    payload: {
        jobId,
        result
    }
})

export const getDuplicateDataObjectsResultSetFailure = ({ jobId, error }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_FAILURE,
    payload: {
        jobId,
        error
    }
})

export const getDuplicateDataObjectsResultSetNextPageRequest = (jobId, pageKey) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_REQUEST,
    params: {
        jobId,
        pageKey
    }
})

export const getDuplicateDataObjectsResultSetNextPageSuccess = ({ jobId, result }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_SUCCESS,
    payload: {
        jobId,
        result
    }
})

export const getDuplicateDataObjectsResultSetNextPageFailure = ({ jobId, error }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_NEXT_PAGE_FAILURE,
    payload: {
        jobId,
        error
    }
})

export const getUserSearchDataObjectsPermissionRequest = () => ({
    type: GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_REQUEST
})

export const getUserSearchDataObjectsPermissionSuccess = (permission) => ({
    type: GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_SUCCESS,
    payload: {
        permission
    }
})

export const getUserSearchDataObjectsPermissionFailure = (error) => ({
    type: GET_USER_SEARCH_DATA_OBJECTS_PERMISSIONS_FAILURE,
    payload: {
        error
    }
})

export const nominateSurvivalRecordRequest = (duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption) => ({
    type: NOMINATE_SURVIVAL_RECORD_REQUEST,
    params: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId,
        deduplicationOption
    }
})

export const nominateSurvivalRecordSuccess = ({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption }) => ({
    type: NOMINATE_SURVIVAL_RECORD_SUCCESS,
    payload: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId,
        deduplicationOption
    }
})

export const nominateSurvivalRecordFailure = ({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId, deduplicationOption }) => ({
    type: NOMINATE_SURVIVAL_RECORD_FAILURE,
    payload: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId,
        deduplicationOption
    }
})

export const removeSurvivalRecordNominationRequest = (duplicateSetId, duplicateSetVersion, groupId, dataObjectId) => ({
    type: REMOVE_SURVIVAL_RECORD_NOMINATION_REQUEST,
    params: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId
    }
})

export const removeSurvivalRecordNominationSuccess = ({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId }) => ({
    type: REMOVE_SURVIVAL_RECORD_NOMINATION_SUCCESS,
    payload: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId
    }
})

export const removeSurvivalRecordNominationFailure = ({ duplicateSetId, duplicateSetVersion, groupId, dataObjectId, error }) => ({
    type: REMOVE_SURVIVAL_RECORD_NOMINATION_FAILURE,
    payload: {
        duplicateSetId,
        duplicateSetVersion,
        groupId,
        dataObjectId,
        error
    }
})

export const runDeDuplicateJobRequest = (duplicateSetId) => ({
    type: RUN_DEDUPLICATE_JOB_REQUEST,
    params: {
        duplicateSetId
    }
})

export const runDeDuplicateJobSuccess = (job) => ({
    type: RUN_DEDUPLICATE_JOB_SUCCESS,
    payload: {
        job
    }
})

export const runDeDuplicateJobFailure = (error) => ({
    type: RUN_DEDUPLICATE_JOB_FAILURE,
    payload: {
        error
    }
})

export const getDuplicateDataObjectsResultSetDataObjectRequest = (duplicateSetId, groupId, setVersion, id, options = { fetchJob: true }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_REQUEST,
    params: {
        duplicateSetId,
        groupId,
        setVersion,
        id,
        options
    }
})

export const getDuplicateDataObjectsResultSetDataObjectSuccess = ({ dataObject, job }) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_SUCCESS,
    payload: {
        dataObject,
        job
    }
})

export const getDuplicateDataObjectsResultSetDataObjectFailure = (error) => ({
    type: GET_DUPLICATE_DATA_OBJECTS_RESULT_SET_DATA_OBJECT_FAILURE,
    payload: {
        error
    }
})

export const getDeDuplicatedRecordsRequest = (survivedRecordId) => ({
    type: GET_DEDUPLICATED_RECORDS_REQUEST,
    params: {
        survivedRecordId
    }
})

export const getDeDuplicatedRecordsSuccess = (result) => ({
    type: GET_DEDUPLICATED_RECORDS_SUCCESS,
    payload: {
        result
    }
})

export const getDeDuplicatedRecordsFailure = (error) => ({
    type: GET_DEDUPLICATED_RECORDS_FAILURE,
    payload: {
        error
    }
})
