import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { JobDetails, LoadingOverlay, FullHeightContainerLayout } from "components";
import { showError } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';
import { Row, Col, Typography, Empty } from 'antd';
import AppPaths from 'constants/appPaths';

const { Title, Text } = Typography;

class EventJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true
        }
    }

    componentWillMount() {
        this.setState({
            busy: true,
            busyMessage: "Getting job...",
        });
        this.props.getEventJob(this.props.eventId, this.props.jobId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetEventJobResultChanged(prevProps);
    }

    onGetEventJobResultChanged = (prevProps) => {
        if (this.props.getEventJobResult && this.props.getEventJobResult !== prevProps.getEventJobResult) {
            if (!this.props.getEventJobResult.success) {
                if (this.props.getEventJobResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get job at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingData: false
            });
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingData);
        return isBusy;
    }

    render() {
        return (
            <>
                {
                    this.isBusy()
                        ?
                        <LoadingOverlay
                            busy={true}
                            spinner
                            message={this.state.busyMessage || "Please wait..."}>
                        </LoadingOverlay>
                        :
                        <></>
                }
                {
                    !this.state.fetchingData
                        ?
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={false}
                            header={<Title level={5}><Text>Event Revision - </Text><Text type="secondary" italic>{`(Job: ${this.props.jobId})`}</Text></Title>}
                            content={
                                this.props.jobRevision
                                    ?
                                    <JobDetails
                                        job={this.props.jobRevision.data}>
                                    </JobDetails>
                                    :
                                    <Empty></Empty>}>
                        </FullHeightContainerLayout>
                        :
                        <></>
                }
            </>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getEventJobResult: state.eventJob.getEventJobResult,
        jobRevision: state.eventJob.jobRevision
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEventJob: (eventId, jobId) => dispatch(actions.getEventJobRequest(eventId, jobId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventJob));