export const GET_AWS_S3_CONNECTIONS_REQUEST = "GET_AWS_S3_CONNECTIONS_REQUEST";
export const GET_AWS_S3_CONNECTIONS_SUCCESS = "GET_AWS_S3_CONNECTIONS_SUCCESS";
export const GET_AWS_S3_CONNECTIONS_FAILURE = "GET_AWS_S3_CONNECTIONS_FAILURE";

export const CREATE_AWS_S3_CONNECTION_REQUEST = "CREATE_AWS_S3_CONNECTION_REQUEST";
export const CREATE_AWS_S3_CONNECTION_SUCCESS = "CREATE_AWS_S3_CONNECTION_SUCCESS";
export const CREATE_AWS_S3_CONNECTION_FAILURE = "CREATE_AWS_S3_CONNECTION_FAILURE";

export const GET_AWS_S3_CONNECTION_REQUEST = "GET_AWS_S3_CONNECTION_REQUEST";
export const GET_AWS_S3_CONNECTION_SUCCESS = "GET_AWS_S3_CONNECTION_SUCCESS";
export const GET_AWS_S3_CONNECTION_FAILURE = "GET_AWS_S3_CONNECTION_FAILURE";

export const UPDATE_AWS_S3_CONNECTION_REQUEST = "UPDATE_AWS_S3_CONNECTION_REQUEST";
export const UPDATE_AWS_S3_CONNECTION_SUCCESS = "UPDATE_AWS_S3_CONNECTION_SUCCESS";
export const UPDATE_AWS_S3_CONNECTION_FAILURE = "UPDATE_AWS_S3_CONNECTION_FAILURE";

export const DELETE_AWS_S3_CONNECTION_REQUEST = "DELETE_AWS_S3_CONNECTION_REQUEST";
export const DELETE_AWS_S3_CONNECTION_SUCCESS = "DELETE_AWS_S3_CONNECTION_SUCCESS";
export const DELETE_AWS_S3_CONNECTION_FAILURE = "DELETE_AWS_S3_CONNECTION_FAILURE";

export const GET_AWS_S3_CONNECTION_BUCKETS_REQUEST = "GET_AWS_S3_CONNECTION_BUCKETS_REQUEST";
export const GET_AWS_S3_CONNECTION_BUCKETS_SUCCESS = "GET_AWS_S3_CONNECTION_BUCKETS_SUCCESS";
export const GET_AWS_S3_CONNECTION_BUCKETS_FAILURE = "GET_AWS_S3_CONNECTION_BUCKETS_FAILURE";

export const GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_REQUEST = "GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_REQUEST";
export const GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_SUCCESS = "GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_SUCCESS";
export const GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_FAILURE = "GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_FAILURE";

export const GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_REQUEST = "GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_REQUEST";
export const GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_SUCCESS = "GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_SUCCESS";
export const GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_FAILURE = "GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_FAILURE";

export const getAwsS3ConnectionsRequest = () => ({
    type: GET_AWS_S3_CONNECTIONS_REQUEST
})

export const getAwsS3ConnectionsSuccess = (result) => ({
    type: GET_AWS_S3_CONNECTIONS_SUCCESS,
    payload: {
        result
    }
})

export const getAwsS3ConnectionsFailure = (error) => ({
    type: GET_AWS_S3_CONNECTIONS_FAILURE,
    payload: {
        error
    }
})

export const createAwsS3ConnectionRequest = (connection) => ({
    type: CREATE_AWS_S3_CONNECTION_REQUEST,
    params: {
        connection
    }
})

export const createAwsS3ConnectionSuccess = (connection) => ({
    type: CREATE_AWS_S3_CONNECTION_SUCCESS,
    payload: {
        connection
    }
})

export const createAwsS3ConnectionFailure = (error) => ({
    type: CREATE_AWS_S3_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const getAwsS3ConnectionRequest = (connectionId) => ({
    type: GET_AWS_S3_CONNECTION_REQUEST,
    params: {
        connectionId
    }
})

export const getAwsS3ConnectionSuccess = (connection) => ({
    type: GET_AWS_S3_CONNECTION_SUCCESS,
    payload: {
        connection
    }
})

export const getAwsS3ConnectionFailure = (error) => ({
    type: GET_AWS_S3_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const updateAwsS3ConnectionRequest = (connectionId, updatedAwsS3Connection) => ({
    type: UPDATE_AWS_S3_CONNECTION_REQUEST,
    params: {
        connectionId,
        updatedAwsS3Connection
    }
})

export const updateAwsS3ConnectionSuccess = (connection) => ({
    type: UPDATE_AWS_S3_CONNECTION_SUCCESS,
    payload: {
        connection
    }
})

export const updateAwsS3ConnectionFailure = (error) => ({
    type: UPDATE_AWS_S3_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const deleteAwsS3ConnectionRequest = (connectionId) => ({
    type: DELETE_AWS_S3_CONNECTION_REQUEST,
    params: {
        connectionId
    }
})

export const deleteAwsS3ConnectionSuccess = (connectionId) => ({
    type: DELETE_AWS_S3_CONNECTION_SUCCESS,
    payload: {
        connectionId
    }
})

export const deleteAwsS3ConnectionFailure = (error) => ({
    type: DELETE_AWS_S3_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const getAwsS3ConnectionBucketsRequest = (connectionId) => ({
    type: GET_AWS_S3_CONNECTION_BUCKETS_REQUEST,
    params: {
        connectionId
    }
})

export const getAwsS3ConnectionBucketsSuccess = (result) => ({
    type: GET_AWS_S3_CONNECTION_BUCKETS_SUCCESS,
    payload: {
        result
    }
})

export const getAwsS3ConnectionBucketsFailure = (error) => ({
    type: GET_AWS_S3_CONNECTION_BUCKETS_FAILURE,
    payload: {
        error
    }
})

export const getAwsS3ConnectionBucketContentListRequest = (connectionId, bucket, path) => ({
    type: GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_REQUEST,
    params: {
        connectionId,
        bucket,
        path
    }
})

export const getAwsS3ConnectionBucketContentListSuccess = (result) => ({
    type: GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_SUCCESS,
    payload: {
        result
    }
})

export const getAwsS3ConnectionBucketContentListFailure = (error) => ({
    type: GET_AWS_S3_CONNECTION_BUCKET_CONTENT_LIST_FAILURE,
    payload: {
        error
    }
})

export const getAwsS3ConnectionBucketDownloadFileRequest = (connectionId, bucket, file) => ({
    type: GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_REQUEST,
    params: {
        connectionId,
        bucket,
        file
    }
})

export const getAwsS3ConnectionBucketDownloadFileSuccess = (result) => ({
    type: GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_SUCCESS,
    payload: {
        result
    }
})

export const getAwsS3ConnectionBucketDownloadFileFailure = (error) => ({
    type: GET_AWS_S3_CONNECTION_BUCKET_DOWNLOAD_FILE_FAILURE,
    payload: {
        error
    }
})
