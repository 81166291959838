import React, { Component } from 'react';
import { connect } from "react-redux";
import LABELS from 'constants/labels';
import actions from 'store/actions';
import { DataTagList, ManageDataTag, OkCancelConfirmationBox, LoadingOverlay, ProhibitedArea } from "components";
import { DataTagAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import flatten from 'flat';

class DataTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingPermission: true
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        this.props.getUserDataTagPermission();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting Data Tags...",
                fetchingDataTags: true
            });
            this.props.getDataTags();
        }
        else {
            this.props.getUserBusinessAreas();
            if (this.props.tagId) {
                this.props.getDataTagById(this.props.tagId);
            }
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting Data Tag...",
                    fetchingDataTag: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingDataTag: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.onGetDataTagsResultUpdate(prevProps);

        this.onCreateDataTagResultUpdate(prevProps);

        this.onGetDataTagByIdResultUpdate(prevProps);

        this.onUpdateDataTagResultUpdate(prevProps);

        this.onDeleteDataTagResultUpdate(prevProps);

        this.onGetUserDataTagPermissionResultUpdate(prevProps);
    }

    onGetDataTagsResultUpdate = (prevProps) => {
        if (this.props.getDataTagsResult && this.props.getDataTagsResult !== prevProps.getDataTagsResult) {
            if (!this.props.getDataTagsResult.success) {
                if (this.props.getDataTagsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get data tag at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingDataTags: false
            });
        }
    }

    onCreateDataTagResultUpdate = (prevProps) => {
        if (this.props.createDataTagResult && this.props.createDataTagResult !== prevProps.createDataTagResult) {
            if (!this.props.createDataTagResult.success) {
                showError(this.props.createDataTagResult.message || "Data tag cannot be created.");
                this.setState({
                    busy: false,
                    manageDataTagErrors: this.props.createDataTagResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Data tag created. Please wait..."
                });
                showSuccess("Data tag created successfully.", () => {
                    this.cancelManageDataTag();
                });
            }
        }
    }

    onGetDataTagByIdResultUpdate = (prevProps) => {
        if (this.props.getDataTagByIdResult && this.props.getDataTagByIdResult !== prevProps.getDataTagByIdResult) {
            if (!this.props.getDataTagByIdResult.success) {
                if (this.props.getDataTagByIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get data tag.");
                }
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: true
                });
            }
            this.setState({
                busy: false,
                fetchingDataTag: false
            });
            if (!this.props.dataTagsResult || !this.props.dataTagsResult.Items || this.props.dataTagsResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateDataTagResultUpdate = (prevProps) => {
        if (this.props.updateDataTagResult && this.props.updateDataTagResult !== prevProps.updateDataTagResult) {
            if (!this.props.updateDataTagResult.success) {
                showError(this.props.updateDataTagResult.message || "Data tag cannot be updated.");
                this.setState({
                    busy: false,
                    manageDataTagErrors: this.props.updateDataTagResult.fields
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Data tag updated. Please wait..."
                });
                showSuccess("Data tag updated successfully.", () => {
                    this.cancelManageDataTag();
                });
            }
        }
    }

    onDeleteDataTagResultUpdate = (prevProps) => {
        if (this.props.deleteDataTagResult && this.props.deleteDataTagResult !== prevProps.deleteDataTagResult) {
            if (!this.props.deleteDataTagResult.success) {
                showError(this.props.deleteDataTagResult.message || "Data tag cannot be deleted.");
            }
            else {
                showSuccess("Data tag deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onGetUserDataTagPermissionResultUpdate = (prevProps) => {
        if (this.props.getUserDataTagPermissionResult && this.props.getUserDataTagPermissionResult !== prevProps.getUserDataTagPermissionResult) {
            if (!this.props.getUserDataTagPermissionResult.success) {
                this.setState({
                    permissionDenied: true,
                    fetchingPermission: false
                });
            }
            else {
                this.setState({
                    fetchingPermission: false,
                });
            }
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant.toLowerCase())
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()),
                    text: "Data Tag"
                }
            ];
            if (this.props.action) {
                let dataTagName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New Data Tag"
                        });
                        break;
                    case "view":
                    case "edit":
                        dataTagName = this.getDataTagName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: dataTagName
                        }]);
                        break;
                    case "history":
                        dataTagName = this.getDataTagName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: dataTagName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getDataTagName = () => {
        let dataTagName = this.props.tagId;
        if (this.props.dataTagsResult && this.props.dataTagsResult.Items && this.props.dataTagsResult.Items.length > 0) {
            let dataTag = this.props.dataTagsResult.Items.find(dataTag => dataTag.tagId === this.props.tagId);
            if (dataTag) {
                dataTagName = dataTag.tagName;
            }
        }
        else if (this.props.dataTag && this.props.dataTag.tagId === this.props.tagId) {
            dataTagName = this.props.dataTag.tagName;
        }
        return dataTagName;
    }

    showCreateDataTag = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }

    showViewDataTag = (tagId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", tagId)
            .replace(":itemAction", "view"));
    }

    showEditDataTag = (tagId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", tagId)
            .replace(":itemAction", "edit"));
    }

    showDataTagHistory = (tagId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", tagId)
            .replace(":itemAction", "history"));
    }

    cancelManageDataTag = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase()));
    }

    createDataTag = dataTag => {
        this.setState({
            busy: true,
            busyMessage: "Creating data tag"
        });
        this.props.createDataTag(dataTag);
    }

    updateDataTag = (dataTag, updatedDataTag) => {
        this.setState({
            busy: true,
            busyMessage: "Updating data tag..."
        });
        this.props.updateDataTag(dataTag, updatedDataTag);
    }

    showDeleteDataTagConfirmation = (dataTag) => {
        this.setState({
            deletedDataTag: dataTag,
            showDataTagDeleteConfirmation: true,
            deleteDataTagConfirmMessage: `Are you sure, you want to delete '${dataTag.tagName}' data tag?`
        })
    }

    onDeleteDataTagConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting data tag..."
        });
        this.props.deleteDataTag(this.state.deletedDataTag.tagId);
        this.onDeleteDataTagCancel();
    }

    onDeleteDataTagCancel = () => {
        this.setState({
            deletedDataTag: null,
            showDataTagDeleteConfirmation: false,
            deleteDataTagConfirmMessage: ""
        })
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingDataTags || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingDataTag;
        }
        return isBusy;
    }

    onDataTagValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageDataTagErrors) === false) {
            let manageDataTagErrors = { ...this.state.manageDataTagErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageDataTagErrors[key];
            }
            this.setState({
                manageDataTagErrors
            })
        }
    }

    getDataTagComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return <>
                <OkCancelConfirmationBox
                    show={this.state.showDataTagDeleteConfirmation}
                    message={this.state.deleteDataTagConfirmMessage}
                    onCancel={this.onDeleteDataTagCancel}
                    onOk={this.onDeleteDataTagConfirmed}>
                </OkCancelConfirmationBox>
                {!this.state.fetchingDataTags ? <DataTagList
                    dataTagList={this.props.dataTagsResult.Items}
                    onCreateNewDataTag={this.showCreateDataTag}
                    onEditDataTag={this.showEditDataTag}
                    onDeleteDataTag={this.showDeleteDataTagConfirmation}
                    onViewDataTag={this.showViewDataTag}
                    onViewDataTagHistory={this.showDataTagHistory}
                    permission={this.props.permission}
                ></DataTagList> : <></>}
            </>
        }
        switch (this.props.action) {
            case "create":
                return <ManageDataTag
                    action={this.props.action}
                    businessAreaList={this.props.businessAreaList}
                    createDataTag={this.createDataTag}
                    onCancel={this.cancelManageDataTag}
                    formErrors={this.state.manageDataTagErrors}
                    onValuesChanged={this.onDataTagValuesChanged}
                    permission={this.props.permission}>
                </ManageDataTag>;
            case "view":
            case "edit":
                return !this.state.fetchingDataTag ?
                    (
                        this.props.dataTag ?
                            <ManageDataTag
                                action={this.props.action}
                                dataTag={this.props.dataTag}
                                businessAreaList={this.props.businessAreaList}
                                onCancel={this.cancelManageDataTag}
                                updateDataTag={this.updateDataTag}
                                formErrors={this.state.manageDataTagErrors}
                                onValuesChanged={this.onDataTagValuesChanged}
                                permission={this.props.permission}>
                            </ManageDataTag> :
                            (
                                this.props.getDataTagByIdResult.success ?
                                    <Message floating size='big'>
                                        <Message.Header>Data Tag not found.</Message.Header>
                                        <p>The Data Tag you are looking for does not exist.</p>
                                    </Message>
                                    :
                                    <></>
                            )
                    )
                    :
                    <></>;
            case "history":
                return this.state.showHistory ? <DataTagAudit dataTag={this.props.dataTag} onClose={this.cancelManageDataTag}></DataTagAudit> : <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <div className="full-height-flex-container verticle-scroll">
                <LoadingOverlay
                    busy={this.isBusy()}
                    spinner
                    message={this.state.busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {!this.state.fetchingPermission ? this.getDataTagComponent() : <></>}
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreaList: state.home.businessAreas,
        getUserBusinessAreasResult: state.home.getUserBusinessAreasResult,
        dataTagsResult: state.dataTags.dataTagsResult,
        getDataTagsResult: state.dataTags.getDataTagsResult,
        createDataTagResult: state.dataTags.createDataTagResult,
        getDataTagByIdResult: state.dataTags.getDataTagByIdResult,
        dataTag: state.dataTags.dataTag,
        updateDataTagResult: state.dataTags.updateDataTagResult,
        deleteDataTagResult: state.dataTags.deleteDataTagResult,
        getUserDataTagPermissionResult: state.dataTags.getUserDataTagPermissionResult,
        permission: state.dataTags.permission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreas: () => dispatch(actions.getUserBusinessAreasRequest()),
        getDataTags: () => dispatch(actions.getDataTagsRequest()),
        createDataTag: (dataTag) => dispatch(actions.createDataTagRequest(dataTag)),
        getDataTagById: (tagId) => dispatch(actions.getDataTagByIdRequest(tagId)),
        updateDataTag: (dataTag, updatedDataTag) => dispatch(actions.updateDataTagRequest(dataTag, updatedDataTag)),
        deleteDataTag: (tagId) => dispatch(actions.deleteDataTagRequest(tagId)),
        getUserDataTagPermission: () => dispatch(actions.getUserDataTagPermissionRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTags));