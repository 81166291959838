import {
    GET_SCHEMA_MODEL_LINEAGE_SUCCESS,
    GET_SCHEMA_MODEL_LINEAGE_FAILURE,

    GET_LINEAGE_SUCCESS,
    GET_LINEAGE_FAILURE
} from "./actions";

const initialState = {
    getSchemaModelLineageResult: {
        success: true
    },
    schemaModelLineageResult: null,
    getLineageResult: {
        success: true
    },
    lineageResult: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHEMA_MODEL_LINEAGE_SUCCESS:
            return {
                ...state,
                getSchemaModelLineageResult:
                {
                    success: true
                },
                schemaModelLineageResult: action.payload.result
            };
        case GET_SCHEMA_MODEL_LINEAGE_FAILURE:
            return {
                ...state,
                getSchemaModelLineageResult:
                {
                    success: false,
                    ...action.payload.error
                },
                schemaModelLineageResult: null
            };
        case GET_LINEAGE_SUCCESS:
            return {
                ...state,
                getLineageResult:
                {
                    success: true
                },
                lineageResult: action.payload.result
            };
        case GET_LINEAGE_FAILURE:
            return {
                ...state,
                getLineageResult:
                {
                    success: false,
                    ...action.payload.error
                },
                lineageResult: null
            };
        default:
            return state;
    }
};
