import {
    SEND_USER_FORGOT_PASSWORD_SUCCESS,
    SEND_USER_FORGOT_PASSWORD_FAILURE
} from "./actions";

const initialState = {
    sendUserForgotPasswordResult: {
        success: true
    },
    userForgotPasswordResult: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEND_USER_FORGOT_PASSWORD_SUCCESS:
            return { ...state, sendUserForgotPasswordResult: { success: true, ...action.payload }, userForgotPasswordResult: action.payload };
        case SEND_USER_FORGOT_PASSWORD_FAILURE:
            return { ...state, sendUserForgotPasswordResult: { success: false, ...action.payload }, userForgotPasswordResult: null };
        default:
            return state;
    }
};
