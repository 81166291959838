import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Tabs, Row, Col, Button } from 'antd';
import { LoadingOverlay, SchemaModelHistoryList, EventList, FullHeightContainerLayout } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import { withRouter } from "react-router";
import _ from 'lodash';

const { TabPane } = Tabs;

class SchemaModelAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectRecordDetail: null
        }
    }

    componentWillMount() {
        this.props.getSchemaModelHistory(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId);
        this.props.getSchemaModelEvents(this.props.schemaModel.schemaId);
        this.setState({
            fetchingHistory: true,
            fetchingEvents: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetSchemaModelHistoryResultUpdate(prevProps);
        this.onGetSchemaModelEventsResultUpdate(prevProps);
        this.onGetSchemaModelVersionFieldRevisionsResultUpdate(prevProps);
    }

    onGetSchemaModelHistoryResultUpdate = (prevProps) => {
        if (this.props.getSchemaModelHistoryResult && this.props.getSchemaModelHistoryResult !== prevProps.getSchemaModelHistoryResult) {
            if (!this.props.getSchemaModelHistoryResult.success) {
                if (this.props.getSchemaModelHistoryResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema history.");
                }
            }
            this.setState({
                fetchingHistory: false
            });
        }
    }

    onGetSchemaModelEventsResultUpdate = (prevProps) => {
        if (this.props.getSchemaModelEventsResult && this.props.getSchemaModelEventsResult !== prevProps.getSchemaModelEventsResult) {
            if (!this.props.getSchemaModelEventsResult.success) {
                if (this.props.getSchemaModelEventsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema events.");
                }
            }
            this.setState({
                fetchingEvents: false
            });
        }
    }

    onGetSchemaModelVersionFieldRevisionsResultUpdate = (prevProps) => {
        if (this.props.getSchemaModelVersionFieldRevisionsResult && this.props.getSchemaModelVersionFieldRevisionsResult !== prevProps.getSchemaModelVersionFieldRevisionsResult) {
            if (this.props.getSchemaModelVersionFieldRevisionsResult.success) {
                this.setState({
                    selectRecordDetail: {
                        type: "SCHEMA_MODEL_FIELDS",
                        recordId: this.props.schemaModelVersionFieldsResult.schemaId,
                        data: this.props.schemaModelVersionFieldsResult.fieldResult.Items
                    }
                })
            }
            else {
                this.setState({
                    selectRecordDetail: {
                        type: "SCHEMA_MODEL_FIELDS",
                        recordId: this.props.getSchemaModelVersionFieldRevisionsResult.schemaId,
                        data: []
                    }
                })
            }
        }
    }

    loadSchemaModelFieldRevisions = (businessAreaId, schemaId, version) => {
        this.props.getSchemaModelVersionFieldRevisions(businessAreaId, schemaId, version);
    }

    render() {
        return (
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={false}
                content={<Tabs defaultActiveKey="1" className="data-audit-tabs">
                    <TabPane tab="Events" key="1">
                        {
                            this.state.fetchingEvents
                                ?
                                <LoadingOverlay
                                    busy={true}
                                    spinner
                                    message="Getting schema events...">
                                </LoadingOverlay>
                                :
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    showFooter={false}
                                    content={<EventList eventList={this.props.schemaModelEventsResult.Items}></EventList>}>
                                </FullHeightContainerLayout>
                        }
                    </TabPane>
                    <TabPane tab="Revisions" key="2">
                        {
                            this.state.fetchingHistory
                                ?
                                <LoadingOverlay
                                    busy={true}
                                    spinner
                                    message="Getting schema history...">
                                </LoadingOverlay>
                                :
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    showFooter={false}
                                    content={<SchemaModelHistoryList
                                        schemaModelRevisions={this.props.schemaModelHistoryResult.Items}
                                        selectRecordDetail={this.state.selectRecordDetail}
                                        onLoadSchemaModelFieldRevisions={this.loadSchemaModelFieldRevisions}>
                                    </SchemaModelHistoryList>}>
                                </FullHeightContainerLayout>
                        }

                    </TabPane>
                </Tabs>}>
            </FullHeightContainerLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getSchemaModelHistoryResult: state.schemaModelAudit.getSchemaModelHistoryResult,
        schemaModelHistoryResult: state.schemaModelAudit.schemaModelHistoryResult,
        getSchemaModelEventsResult: state.schemaModelAudit.getSchemaModelEventsResult,
        schemaModelEventsResult: state.schemaModelAudit.schemaModelEventsResult,
        getSchemaModelVersionFieldRevisionsResult: state.schemaModelAudit.getSchemaModelVersionFieldRevisionsResult,
        schemaModelVersionFieldsResult: state.schemaModelAudit.schemaModelVersionFieldsResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSchemaModelHistory: (businessAreaId, schemaId) => dispatch(actions.getSchemaModelHistoryRequest(businessAreaId, schemaId)),
        getSchemaModelEvents: (schemaId) => dispatch(actions.getSchemaModelEventsRequest(schemaId)),
        getSchemaModelVersionFieldRevisions: (businessAreaId, schemaId, version) => dispatch(actions.getSchemaModelVersionFieldRevisionsRequest(businessAreaId, schemaId, version))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModelAudit));