import React from "react";
import { Table, List, Space, Row, Col, Typography } from "antd";
import { ListRecordAttributeItem } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const displayLabels = {
    "name": "Name",
    "description": "Description",
    "canAdd": "Add",
    "canView": "View",
    "canEdit": "Edit",
    "canDelete": "Delete",
    "active": "Active",
    "updatedByEmail": "Updated by",
    "updatedDate": "Updated at",
    "deleted": "Deleted"
}

function parseAction(permission) {
    if (permission && permission.actions) {
        permission.canAdd = false;
        permission.canView = false;
        permission.canEdit = false;
        permission.canDelete = false;
        if (permission.actions.includes("add")) {
            permission.canAdd = true;
        }
        if (permission.actions.includes("view")) {
            permission.canView = true;
        }
        if (permission.actions.includes("edit")) {
            permission.canEdit = true;
        }
        if (permission.actions.includes("delete")) {
            permission.canDelete = true;
        }
        delete permission.actions;
    }
}

export default function ({ record }) {
    let currentData = { ...record.data };
    let previousData = { ...(record.previousData || {}) };
    if (currentData) {
        parseAction(currentData);
    }
    if (previousData) {
        parseAction(previousData);
    }
    return <>
        <Row className="middle-row">
            <Col span={24}>
                <Title level={5}><Text>{record.name}</Text></Title>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <List
                    size="small"
                    bordered
                    dataSource={Object.keys(currentData)}
                    renderItem={item => {
                        if (displayLabels[item]) {
                            if (item === "updatedDate") {
                                return <ListRecordAttributeItem name={displayLabels[item]} value={moment.utc(currentData[item]).toDate().toLocaleString()} previousValue={previousData[item] && moment.utc(previousData[item]).toDate().toLocaleString()}></ListRecordAttributeItem>
                            }
                            return <ListRecordAttributeItem name={displayLabels[item]} value={currentData[item]} previousValue={previousData && previousData[item]}></ListRecordAttributeItem>
                        }
                        return <></>
                    }}
                />
            </Col>
        </Row>
    </>
}