import {
    GET_APP_CLIENTS_SUCCESS,
    GET_APP_CLIENTS_FAILURE,

    CREATE_APP_CLIENT_SUCCESS,
    CREATE_APP_CLIENT_FAILURE,

    GET_APP_CLIENT_SUCCESS,
    GET_APP_CLIENT_FAILURE,

    UPDATE_APP_CLIENT_SUCCESS,
    UPDATE_APP_CLIENT_FAILURE,

    DELETE_APP_CLIENT_SUCCESS,
    DELETE_APP_CLIENT_FAILURE,

    REGENERATE_APP_CLIENT_SECRET_SUCCESS,
    REGENERATE_APP_CLIENT_SECRET_FAILURE,

    GET_APP_CLIENT_PERMISSIONS_SUCCESS,
    GET_APP_CLIENT_PERMISSIONS_FAILURE
} from "./actions";

const initialState = {
    getAppClientsResult: {
        success: true
    },
    appClientListResult: {
        Items: []
    },
    createAppClientResult: {
        success: true
    },
    createdAppClient: null,

    getAppClientResult: {
        success: true
    },
    appClient: null,
    updateAppClientResult: {
        success: true
    },
    deleteAppClientResult: {
        success: true
    },
    regenerateAppClientSecretResult: {
        success: true
    },
    getAppClientPermissionResult: {
        success: true,
    },
    userAppClientPermission: null,
};

export default (state = initialState, action) => {
    let appClientListResult = {
        Items: []
    };
    let appClientIndex = -1;
    switch (action.type) {
        case GET_APP_CLIENTS_SUCCESS:
            return { ...state, getAppClientsResult: { success: true }, appClientListResult: action.payload.result };
        case GET_APP_CLIENTS_FAILURE:
            return { ...state, getAppClientsResult: { success: false, ...action.payload.error }, appClientListResult: { Items: [] } };
        case CREATE_APP_CLIENT_SUCCESS:
            return { ...state, createAppClientResult: { success: true }, createdAppClient: action.payload.appClient };
        case CREATE_APP_CLIENT_FAILURE:
            return { ...state, createAppClientResult: { success: false, ...action.payload.error }, createdAppClient: null };
        case GET_APP_CLIENT_SUCCESS:
            return { ...state, getAppClientResult: { success: true }, appClient: action.payload.appClient };
        case GET_APP_CLIENT_FAILURE:
            return { ...state, getAppClientResult: { success: false, ...action.payload.error }, appClient: null };
        case UPDATE_APP_CLIENT_SUCCESS:
            appClientListResult = state.appClientListResult;
            if (action.payload.appClient) {
                appClientIndex = appClientListResult.Items.findIndex(item => item.clientId === action.payload.appClient.clientId);
                if (appClientIndex > -1) {
                    appClientListResult = { ...appClientListResult };
                    appClientListResult.Items = [...appClientListResult.Items];
                    appClientListResult.Items[appClientIndex] = action.payload.appClient;
                }
            }
            return { ...state, updateAppClientResult: { success: true }, appClientListResult };
        case UPDATE_APP_CLIENT_FAILURE:
            return { ...state, updateAppClientResult: { success: false, ...action.payload.error } };
        case DELETE_APP_CLIENT_SUCCESS:
            appClientListResult = { ...state.appClientListResult };
            appClientListResult.Items = state.appClientListResult.Items.filter(item => item.clientId !== action.payload.clientId);
            return { ...state, deleteAppClientResult: { success: true }, appClientListResult };
        case DELETE_APP_CLIENT_FAILURE:
            return { ...state, deleteAppClientResult: { success: false, ...action.payload.error } };
        case REGENERATE_APP_CLIENT_SECRET_SUCCESS:
            let appClient = state.appClient;
            appClientListResult = state.appClientListResult;
            if (action.payload.appClient) {
                if (appClient && action.payload.appClient.clientId === appClient.clientId) {
                    appClient = action.payload.appClient;
                }

                appClientIndex = appClientListResult.Items.findIndex(item => item.clientId === action.payload.appClient.clientId);
                if (appClientIndex > -1) {
                    appClientListResult = { ...appClientListResult };
                    appClientListResult.Items = [...appClientListResult.Items];
                    appClientListResult.Items[appClientIndex] = action.payload.appClient;
                }
            }

            return { ...state, regenerateAppClientSecretResult: { success: true }, appClient, appClientListResult };
        case REGENERATE_APP_CLIENT_SECRET_FAILURE:
            return { ...state, regenerateAppClientSecretResult: { success: false, ...action.payload.error } };
        case GET_APP_CLIENT_PERMISSIONS_SUCCESS:
            return { ...state, getAppClientPermissionResult: { success: true }, userAppClientPermission: action.payload.permission };
        case GET_APP_CLIENT_PERMISSIONS_FAILURE:
            return { ...state, getAppClientPermissionResult: { success: false, ...action.payload.error }, userAppClientPermission: null };
        default:
            return state;
    }
};
