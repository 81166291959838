
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

function* getNominatedDataObjectMergePreview(api,  duplicateSetId, duplicateSetVersion, groupId) {
    let result = yield call([api, api.get], `${Endpoints.duplicates}/api/v1/dataobjects/nominate/survivor/set/${duplicateSetId}/group/${groupId}/version/${duplicateSetVersion}/preview`);
    return result.data;
}

export function* getNominatedDataObjectMergePreviewRequest(api, { duplicateSetId, duplicateSetVersion, groupId }) {
    try {
        const result = yield call(getNominatedDataObjectMergePreview, api, duplicateSetId, duplicateSetVersion, groupId);
        yield put(actions.getNominatedDataObjectMergePreviewSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "Nominated record preview");
        yield put(actions.getNominatedDataObjectMergePreviewFailure(errorObject));
    }
}

export function* watchGetNominatedDataObjectMergePreviewRequest(api, { params }) {
    yield call(getNominatedDataObjectMergePreviewRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_NOMINATED_DATA_OBJECT_MERGE_PREVIEW_REQUEST, watchGetNominatedDataObjectMergePreviewRequest, api);
}