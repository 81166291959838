
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';

function* getDuplicateMergeRules(api,  duplicateSetId, duplicateSetVersion, groupId, dataObjectId) {
    let result = yield call([api, api.get], `${Endpoints.duplicates}/api/v1/dataobjects/mergerules/set/${duplicateSetId}/group/${groupId}/version/${duplicateSetVersion}/record/${dataObjectId}`);
    return result.data;
}

function* saveDuplicateMergeRules(api,  duplicateSetId, duplicateSetVersion, groupId, dataObjectId, rules) {
    let result = yield call([api, api.post], `${Endpoints.duplicates}/api/v1/dataobjects/mergerules/set/${duplicateSetId}/group/${groupId}/version/${duplicateSetVersion}/record/${dataObjectId}`, rules);
    return result.data;
}

export function* getDuplicateMergeRulesRequest(api, { duplicateSetId, duplicateSetVersion, groupId, dataObjectId }) {
    try {
        const result = yield call(getDuplicateMergeRules, api, duplicateSetId, duplicateSetVersion, groupId, dataObjectId);
        yield put(actions.getDuplicateMergeRulesSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "duplicate merge rules");
        yield put(actions.getDuplicateMergeRulesFailure(errorObject));
    }
}

export function* saveDuplicateMergeRulesRequest(api, { duplicateSetId, duplicateSetVersion, groupId, dataObjectId, rules }) {
    try {
        const result = yield call(saveDuplicateMergeRules, api, duplicateSetId, duplicateSetVersion, groupId, dataObjectId, rules);
        yield put(actions.saveDuplicateMergeRulesSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Save", "duplicate merge rules");
        yield put(actions.saveDuplicateMergeRulesFailure(errorObject));
    }
}

export function* watchGetDuplicateMergeRulesRequest(api, { params }) {
    yield call(getDuplicateMergeRulesRequest, api, params);
}

export function* watchSaveDuplicateMergeRulesRequest(api, { params }) {
    yield call(saveDuplicateMergeRulesRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DUPLICATE_MERGE_RULES_REQUEST, watchGetDuplicateMergeRulesRequest, api);
    yield takeLatest(actions.SAVE_DUPLICATE_MERGE_RULES_REQUEST, watchSaveDuplicateMergeRulesRequest, api);
}