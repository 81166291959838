export const GET_APP_CLIENT_EVENTS_REQUEST = "GET_APP_CLIENT_EVENTS_REQUEST";
export const GET_APP_CLIENT_EVENTS_SUCCESS = "GET_APP_CLIENT_EVENTS_SUCCESS";
export const GET_APP_CLIENT_EVENTS_FAILURE = "GET_APP_CLIENT_EVENTS_FAILURE";

export const getAppClientEventsRequest = (clientId) => ({
    type: GET_APP_CLIENT_EVENTS_REQUEST,
    params: {
        clientId
    }
})

export const getAppClientEventsSuccess = (appClientEventResults) => ({
    type: GET_APP_CLIENT_EVENTS_SUCCESS,
    payload: {
        appClientEventResults
    }
})

export const getAppClientEventsFailure = (error) => ({
    type: GET_APP_CLIENT_EVENTS_FAILURE,
    payload: {
        error
    }
})