
import { put, call, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";

export function* verifyUserEmailRequest(api, { user }) {
    try {
        const status = yield call([Auth, Auth.confirmSignUp], user.userName, user.verificationCode);
        yield put(actions.verifyUserEmailSuccess({ code: "success", message: status }));
    } catch (error) {
        if (error.code && error.message) {
            yield put(actions.verifyUserEmailFailure(error));
        }
        else {
            yield put(actions.verifyUserEmailFailure({ code: "failed", message: "verification failed" }));
        }
    }
}


export function* watchVerifyUserEmailRequest(api, { params }) {
    yield call(verifyUserEmailRequest, api, params)
}

export default function* ({ api }) {
    yield takeEvery(actions.VERIFY_USER_EMAIL_REQUEST, watchVerifyUserEmailRequest, api);
}
