import { SearchQueryBuilderModal } from "components";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { Action, Dispatch } from "redux";
import AppState from "store/AppState";
import actions from "store/actions";

const mapStateToProps = (state: AppState) => {
    return {
        getSearchFieldsResult: state.dataObjectSearch.getSearchFieldsResult,
        searchFields: state.dataObjectSearch.searchFields,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<string>>) => {
    return {
        getSearchFields: (
            businessArea: string,
            schema: string,
            dataSet: string,
        ) =>
            dispatch(
                actions.getSearchFieldsRequest(businessArea, schema, dataSet),
            ),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type IPropsFromRedux = ConnectedProps<typeof connector>;

interface ISearchQueryBuilderProps extends IPropsFromRedux {
    businessArea?: string;
    schema?: string;
    dataSet?: string;
    onClose?: () => void;
    onSearch?: (d: unknown) => void;
}

function SearchQueryBuilder({
    businessArea,
    schema,
    dataSet,
    getSearchFieldsResult,
    searchFields,
    onClose,
    onSearch,
    getSearchFields,
}: ISearchQueryBuilderProps) {
    const [isBusy, setIsBusy] = React.useState<boolean>(true);
    const [showModal, setShowModal] = React.useState<boolean>(true);
    const [dslQuery, setDslQuery] = React.useState<unknown | null>(null); // Need to check the type

    // On load
    React.useEffect(() => {
        getSearchFields(businessArea ?? "", schema ?? "", dataSet ?? "");
    }, [businessArea, schema, dataSet, getSearchFields]);

    React.useEffect(() => {
        // Wait for getSearchFieldsResultto be successful or searchFields contains data.
        setIsBusy(!getSearchFieldsResult.success || searchFields.length === 0);
    }, [searchFields, getSearchFieldsResult]);

    const onCloseQueryBuilder = React.useCallback(() => {
        setShowModal(false);
        onClose && onClose();
    }, [onClose]);

    const onUpdateQuery = React.useCallback((_dslQuery: unknown) => {
        setDslQuery(_dslQuery);
    }, []);

    const handleSearch = React.useCallback(() => {
        setShowModal(false);
        onSearch && onSearch(dslQuery);
    }, [onSearch, dslQuery]);

    return (
        <SearchQueryBuilderModal
            isBusy={isBusy}
            properties={searchFields}
            open={showModal}
            okText={"Search"}
            onCancel={onCloseQueryBuilder}
            onUpdateQuery={onUpdateQuery}
            onOk={handleSearch}
        />
    );
}

export default connector(SearchQueryBuilder);
