export default Object.freeze(
    {
        'DD$MM$YYYY': {
            name: 'DD$MM$YYYY'
        },
        'DD$MMM$YYYY': {
            name: 'DD$MMM$YYYY'
        },
        'MM$DD$YYYY': {
            name: 'MM$DD$YYYY'
        },
        'MMM$DD$YYYY': {
            name: 'MMM$DD$YYYY'
        },
        'MM$DD$YY': {
            name: 'MM$DD$YY'
        },
        'YYYY$MM$DD': {
            name: 'YYYY$MM$DD'
        },
        'YYYY$MMM$DD': {
            name: 'YYYY$MMM$DD'
        },
        'YY$MM$DD': {
            name: 'YY$MM$DD'
        },
        'YYMMDD': {
            name: 'YYMMDD'
        },
        'YYYYMMDD': {
            name: 'YYYYMMDD'
        }
    });