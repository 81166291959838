export default Object.freeze(
    {
        "y/n": {
            name: "y or n"
        },
        "Y/N": {
            name: "Y or N"
        },
        "1/0": {
            name: "1 or 0"
        },
        "t/f": {
            name: "t or f"
        },
        "T/F": {
            name: "T or F"
        }
    });