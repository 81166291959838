export default [
    {
        name: "UniqueID",
        value: "UniqueID"
    },
    {
        name: "Boolean/Flag",
        value: "Boolean/Flag"
    },
    {
        name: "Date",
        value: "Date"
    },
    {
        name: "Timedate",
        value: "Timedate"
    },
    {
        name: "Time",
        value: "Time"
    },
    {
        name: "Gender",
        value: "Gender"
    },
    {
        name: "Language",
        value: "Language"
    },
    {
        name: "Marital Status",
        value: "Marital Status"
    },
    {
        name: "Email",
        value: "Email"
    },
    {
        name: "Phone",
        value: "Phone"
    },
    {
        name: "Fax",
        value: "Fax"
    },
    {
        name: "Number",
        value: "Number"
    },
    {
        name: "Money",
        value: "Money"
    },
    
    {
        name: "Percent",
        value: "Percent"
    },
    {
        name: "ForeName",
        value: "ForeName"
    },
    {
        name: "LastName",
        value: "LastName"
    },
    {
        name: "Street",
        value: "Street"
    },
    {
        name: "Locale",
        value: "Locale"
    },
    {
        name: "City",
        value: "City"
    },
    {
        name: "Province",
        value: "Province"
    },
    {
        name: "State",
        value: "State"
    },
    {
        name: "Postcode",
        value: "Postcode"
    },
    {
        name: "Country",
        value: "Country"
    },
    {
        name: "URL",
        value: "URL"
    },
    {
        name: "Credit Card",
        value: "Credit Card"
    },
    {
        name: "US Social Security Number",
        value: "US Social Security Number"
    },
    {
        name: "Text",
        value: "Text"
    }
]