import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Menu, Icon, Popup, Loader } from 'semantic-ui-react';
import { Tooltip, Typography } from 'antd';
import CypressTestIds from 'cypress/CypressTestIds';

const { Text } = Typography;

class BusinessAreaSelectMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingBusinessAreas: true
        }
    }

    componentWillMount() {
        this.props.getUserBusinessAreas();
        this.props.getUserActiveBusinessArea();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getUserBusinessAreasResult && this.props.getUserBusinessAreasResult !== prevProps.getUserBusinessAreasResult) {
            if (!this.props.getUserBusinessAreasResult.success) {
                if (this.props.getUserBusinessAreasResult.code === "PERMISSION_DENIED") {
                    showError("Business Areas permission denied.");
                }
                else {
                    showError("Could not able to get Business Areas at this moment.");
                }
            }
            this.setState({
                fetchingBusinessAreas: false
            });
        }
    }

    onBusinessAreaSelectionChange = (businessAreaId, businessAreaName) => {
        this.props.saveUserActiveBusinessArea(businessAreaId);
        this.setActiveBusinessAreaMenuOpen(false);
        if (this.props.activeBusinessAreaPath) {
            this.props.history.push(this.props.activeBusinessAreaPath.replace(":businessarea", businessAreaName.toLowerCase()));
        }
    }

    setActiveBusinessAreaMenuOpen = (value) => {
        this.setState({
            activeBusinessAreaMenuOpen: value
        });
    }

    getActiveBusinessAreaTitle = () => {
        if (this.props.activeBusinessAreaId && this.props.businessAreas && this.props.businessAreas.length > 0) {
            let activeBusinessArea = this.props.businessAreas.find(item => item.businessAreaId === this.props.activeBusinessAreaId);
            if (activeBusinessArea) {
                return <span><Text type="success" strong>{activeBusinessArea.name}</Text><Text style={{ color: "white" }}>{" (Business Area)"}</Text></span>;
            }
        }
        return <Text  type="success" strong>Active Business Area</Text>;
    }

    render() {
        return <Popup
            basic
            position='bottom left'
            hideOnScroll
            offset={[0, -10]}
            eventsEnabled={true}
            on='hover'
            hoverable={true}
            onClose={() => this.setActiveBusinessAreaMenuOpen(false)}
            onOpen={() => this.setActiveBusinessAreaMenuOpen(true)}
            open={this.state.activeBusinessAreaMenuOpen}
            trigger={<Menu.Item data-testid={CypressTestIds.HEADER_MENU_BUSINESS_AREA_SELECT_MENU_BUTTON}>
                {this.getActiveBusinessAreaTitle()}
            </Menu.Item>}>
            <Popup.Content>
                {this.state.fetchingBusinessAreas && this.props.businessAreas.length === 0 ? <Loader active inline='centered' /> :
                    <Menu secondary vertical icon='labeled' className="header-floating-menu" data-testid={CypressTestIds.HEADER_MENU_BUSINESS_AREA_SELECT_MENU}>
                        {this.props.businessAreas.map(businessArea => {
                            return <Menu.Item
                                key={businessArea.businessAreaId}
                                name={businessArea.name}
                                onClick={() => this.onBusinessAreaSelectionChange(businessArea.businessAreaId, businessArea.name)}>
                                <span><Icon color='green' name='check' className={this.props.activeBusinessAreaId === businessArea.businessAreaId ? "active-icon" : "inactive-icon"} /> {businessArea.name}</span>
                            </Menu.Item>
                        })}
                    </Menu>}
            </Popup.Content>
        </Popup>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreas: state.home.businessAreas,
        activeBusinessAreaId: state.home.activeBusinessAreaId,
        getUserBusinessAreasResult: state.home.getUserBusinessAreasResult,
        activeBusinessAreaPath: state.home.activeBusinessAreaPath
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreas: () => dispatch(actions.getUserBusinessAreasRequest()),
        getUserActiveBusinessArea: () => dispatch(actions.getUserActiveBusinessAreaRequest()),
        saveUserActiveBusinessArea: (businessAreaId) => dispatch(actions.saveUserActiveBusinessAreaRequest(businessAreaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreaSelectMenu));