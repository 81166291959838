import {
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE
} from "./actions";

const initialState = {
    getEventsResult: {
        success: true
    },
    eventsResult: {
        Items: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_SUCCESS:
            return { ...state, getEventsResult: { success: true }, eventsResult: action.payload };
        case GET_EVENTS_FAILURE:
            return {
                ...state, getEventsResult: { success: false, ...action.payload }, eventsResult: {
                    Items: []
                }
            };
        default:
            return state;
    }
};
