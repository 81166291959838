import {
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,

    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,

    GET_USER_SUCCESS,
    GET_USER_FAILURE,

    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,

    RESET_USER_PASSWORD_SUCCESS,
    RESET_USER_PASSWORD_FAILURE,

    GET_USER_USERS_PERMISSION_SUCCESS,
    GET_USER_USERS_PERMISSION_FAILURE
} from "./actions";

const initialState = {
    getUsersResult: {
        success: true
    },
    createUserResult: {
        success: true
    },
    latestCreatedUser: null,
    userList: [],
    getUserResult: {
        success: true
    },
    user: null,
    updateUserResult: {
        success: true
    },
    resetUserPasswordResult: {
        success: true
    },
    getUserUsersPermissionResult: {
        success: true
    },
    permission: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_SUCCESS:
            let userList = action.payload;
            if (state.latestCreatedUser) {
                let createdUserIndex = userList.findIndex(user => user.userId === state.latestCreatedUser.userId);
                if (createdUserIndex > -1) {
                    userList[createdUserIndex] = { ...userList[createdUserIndex], ...state.latestCreatedUser };
                }
            }
            return { ...state, getUsersResult: { success: true }, userList };
        case GET_USERS_FAILURE:
            return { ...state, getUsersResult: { success: false, ...action.payload }, userList: [] };
        case CREATE_USER_SUCCESS:
            return { ...state, createUserResult: { success: true }, latestCreatedUser: action.payload };
        case CREATE_USER_FAILURE:
            return { ...state, createUserResult: { success: false, ...action.payload } };
        case GET_USER_SUCCESS:
            return { ...state, getUserResult: { success: true }, user: action.payload };
        case GET_USER_FAILURE:
            return { ...state, getUserResult: { success: false, ...action.payload }, user: null };
        case UPDATE_USER_SUCCESS:
            return { ...state, updateUserResult: { success: true } };
        case UPDATE_USER_FAILURE:
            return { ...state, updateUserResult: { success: false, ...action.payload } };
        case RESET_USER_PASSWORD_SUCCESS:
            return { ...state, resetUserPasswordResult: { success: true } };
        case RESET_USER_PASSWORD_FAILURE:
            return { ...state, resetUserPasswordResult: { success: false, ...action.payload } };
        case GET_USER_USERS_PERMISSION_SUCCESS:
            return { ...state, getUserUsersPermissionResult: { success: true }, permission: action.payload };
        case GET_USER_USERS_PERMISSION_FAILURE:
            return { ...state, getUserUsersPermissionResult: { success: false, ...action.payload }, permission: null };
        default:
            return state;
    }
};
