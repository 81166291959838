import React, { useState, useEffect } from "react";
import { Table, Pagination, Row, Col, Typography } from "antd";
import moment from 'moment';
import { BatchProcessResult, Tooltip, FullHeightContainerLayout } from 'components';
import {
    PlayCircleTwoTone,
    StopTwoTone
} from '@ant-design/icons';
const { Column } = Table;
const { Link, Text } = Typography;

const JobList = ({ jobList, onGetProcessedData, onRestartJob, onCancelJob, containerHeight }) => {

    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedJobList, setPaginatedJobList] = useState(jobList && jobList.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setPaginatedJobList(jobList && jobList.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [jobList]);

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedJobList = jobList && jobList.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedJobList(currentPaginatedJobList);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
    }
    return (
        <FullHeightContainerLayout
            showHeader={false}
            content={
                <Table size="small"
                    rowKey="jobId"
                    dataSource={paginatedJobList}
                    pagination={false}
                    className="container-height-100"
                    scroll={{ y: `100vh` }}>
                    <Column
                        width="3rem"
                        render={(value, job, index) => {
                            return (
                                <>
                                    {["COMPLETED", "CANCELLED", "FAILED"].includes(job.status) ?
                                        <Tooltip title="Run job again">
                                            <PlayCircleTwoTone onClick={() => onRestartJob(job)}>
                                            </PlayCircleTwoTone>
                                        </Tooltip>
                                        :
                                        <></>}
                                    {["CREATED", "RESTARTED", "RUNNING", "PENDING"].includes(job.status) ?
                                        <Tooltip title="Cancel Job">
                                            <StopTwoTone twoToneColor="red" onClick={() => onCancelJob(job.jobId, job.version)}>
                                            </StopTwoTone>
                                        </Tooltip>
                                        :
                                        <></>}
                                </>
                            );
                        }}
                    />
                    <Column
                        width="3rem"
                        title="No."
                        key="index"
                        render={(value, item, index) => index + 1}
                    />
                    <Column
                        width="7rem"
                        dataIndex={"status"}
                        title={"Status"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let color = "#5C5B5B";
                            switch (value) {
                                case "COMPLETED":
                                    color = "#11A400";
                                    break;
                                case "FAILED":
                                case "CANCELLING":
                                case "CANCELLED":
                                    color = "#FF0000";
                                    break;
                                case "RUNNING":
                                case "PENDING":
                                    color = "#1F81FF";
                                    break;
                            }
                            let statusText = value;
                            if (job.undo) {
                                if (job.jobType === "FIND_DATA_OBJECTS_DUPLICATES") {
                                    statusText = `DELETE ${statusText}`;
                                }
                                else {
                                    statusText = `UNDO ${statusText}`;
                                }
                            }
                            return <Tooltip placement="topLeft" title={statusText}><span style={{ color: color }}>{statusText}</span></Tooltip>
                        }}
                    />
                    <Column
                        width="12rem"
                        dataIndex={"name"}
                        title={"Name"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />
                    <Column
                        width="12rem"
                        dataIndex={"runInfo"}
                        title={"Details"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            return value ?
                                <BatchProcessResult
                                    batchProcessResult={value}
                                    textMaxWidth="12rem"
                                    onBatchOperationClick={(item) => {
                                        if (job.status === "COMPLETED") {
                                            onGetProcessedData(job, item)
                                        }
                                    }}
                                    readOnly={job.jobType === "INDEX_SEARCH_DATA_OBJECTS"}
                                    jobType={job.jobType}>
                                </BatchProcessResult> :
                                <></>
                        }}
                    />
                    <Column
                        width="10rem"
                        dataIndex={"recordType"}
                        title={"Data Object"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                switch (value) {
                                    case "DATA_DOMAIN":
                                        displayValue = "Business Area Data";
                                        break;
                                    case "DOMAIN_DATA":
                                        displayValue = "Domain";
                                        break;
                                    case "DATA_OBJECTS":
                                        displayValue = "Dataset";
                                        break;
                                    default:
                                        displayValue = <></>
                                }
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="12rem"
                        dataIndex={"recordType"}
                        title={"Object Parent"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                switch (value) {
                                    case "DOMAIN_DATA":
                                        displayValue = job.domainName;
                                        break;
                                    case "DATA_OBJECTS":
                                        displayValue = job.dataSetName;
                                        break;
                                    default:
                                        displayValue = <></>
                                }
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="15rem"
                        dataIndex={"businessAreaName"}
                        title={"Business Area"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value || job.businessAreaId}</Tooltip>}
                    />
                    <Column
                        width="15rem"
                        dataIndex={"createdByEmail"}
                        title={"User"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value || job.createdBy}</Tooltip>}
                    />
                    <Column
                        width="8rem"
                        dataIndex={"startedDate"}
                        title={"Started Date"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                displayValue = moment.utc(value).toDate().toLocaleDateString();
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="8rem"
                        dataIndex={"startedDate"}
                        title={"Started Time"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                displayValue = moment.utc(value).toDate().toLocaleTimeString();
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="9rem"
                        dataIndex={"completedDate"}
                        title={"Completed Date"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                displayValue = moment.utc(value).toDate().toLocaleDateString();
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="9rem"
                        dataIndex={"completedDate"}
                        title={"Completed Time"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(value, job, index) => {
                            let displayValue = null;
                            if (value) {
                                displayValue = moment.utc(value).toDate().toLocaleTimeString();
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                    <Column
                        width="8rem"
                        title={"Elapsed Time"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        render={(job) => {
                            if (job.startedDate && job.completedDate) {
                                let startDate = moment.utc(job.startedDate);
                                let completedDate = moment.utc(job.completedDate);
                                let duration = moment.duration(completedDate.diff(startDate));
                                let displayValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                            }
                            return <></>;
                        }}
                    />
                </Table>
            }
            showFooter={true}
            footer={
                <Row justify="end" style={{ padding: "0.2rem 0rem" }}>
                    <Col span={24} style={{ textAlign: "end" }}>
                        <Pagination
                            size="small"
                            hideOnSinglePage={true}
                            total={jobList.length}
                            defaultPageSize={pagination.defaultPageSize}
                            pageSize={pagination.pageSize}
                            responsive={true}
                            showSizeChanger={false}
                            onChange={onPageClick} />
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    );
};

export default JobList;