import React from 'react'
import { Message } from 'semantic-ui-react'

const VerificationLinkExpiredMessage = () => (
    <Message negative floating size='big'>
        <Message.Header>Permission Denied</Message.Header>
        <p>The record verification link is expired.</p>
    </Message>
)

export default VerificationLinkExpiredMessage