export const GET_EVENT_BUSINESS_AREA_CONNECTIONS_REQUEST = "GET_EVENT_BUSINESS_AREA_CONNECTIONS_REQUEST";
export const GET_EVENT_BUSINESS_AREA_CONNECTIONS_SUCCESS = "GET_EVENT_BUSINESS_AREA_CONNECTIONS_SUCCESS";
export const GET_EVENT_BUSINESS_AREA_CONNECTIONS_FAILURE = "GET_EVENT_BUSINESS_AREA_CONNECTIONS_FAILURE";

export const getEventBusinessAreaConnectionsRequest = (eventId, itemOperation, pageKey) => ({
    type: GET_EVENT_BUSINESS_AREA_CONNECTIONS_REQUEST,
    params: {
        eventId,
        itemOperation,
        pageKey
    }
})

export const getEventBusinessAreaConnectionsSuccess = (connectionsResult) => ({
    type: GET_EVENT_BUSINESS_AREA_CONNECTIONS_SUCCESS,
    payload: {
        connectionsResult
    }
})

export const getEventBusinessAreaConnectionsFailure = (error) => ({
    type: GET_EVENT_BUSINESS_AREA_CONNECTIONS_FAILURE,
    payload: {
        error
    }
})