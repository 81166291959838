import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Button, } from "antd";
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;

const TagSearchInput = ({ value, onChange, loadingDataTags, dataTags, onSearch }) => {
    const [selectedValues, setSelectedValues] = useState(value);

    useEffect(() => {
        setSelectedValues(value);
    }, [value]);

    const onTagsChange = (value) => {
        setSelectedValues(value);
        onChange(value);
    }

    return <Row style={{ flexWrap: "nowrap" }}>
        <Col style={{ width: "100%" }}>
            <Select
                mode="tags"
                placeholder="Tag Search: search for a tags to add as a duplicate match"
                loading={loadingDataTags}
                value={selectedValues}
                onChange={onTagsChange}>
                {
                    dataTags.map((dataTag, index) => <Option key={dataTag.tagId} value={dataTag.tagName}>{`${dataTag.tagName}`}</Option>)
                }
            </Select>
        </Col>
        <Col style={{ width: "auto" }}>
            <Button icon={<SearchOutlined />} onClick={onSearch}>
            </Button>
        </Col>
    </Row>
}

export default TagSearchInput