import react from 'react';
import { Alert, List, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";

function ErrorList({ title = "Errors", errors = [], onClose = () => { }, ...props }) {
    return errors && errors.length > 0 ?
        <Row style={{ ...(props.style || {}), marginBottom: "1rem" }}>
            <Col span={24}>
                <Alert
                    message={title}
                    description={<>{errors.map((error, index) => <p key={index}>{error}</p>)}</>}
                    type="error"
                    closable
                    onClose={onClose}
                />
            </Col>
        </Row>
        :
        <></>
}

export default ErrorList