import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space, Tag } from "antd";
import React from "react";

/**
 * Dropdown menu that supports Form, dropdown menu fits the content without truncation.
 * Different from antd's Select where menu fits the label instead of the content.
 */
export default function FormDropDownTag({
    id,
    value = "",
    title,
    items,
    label,
    disabled = false,
    loading = false,
    selectable = true,
    icon: propIcon,
    iconPosition = "end",
    showIcon = false,
    color,
    direction = "horizontal",
    onChange,
}: IFormDropDownTagProps) {
    const triggerChange = (changedValue: string) => {
        onChange?.(changedValue);
    };

    const handleClick: MenuProps["onClick"] = ({ key }) => {
        triggerChange(key);
    };

    const iconCss = React.useMemo(
        () => ({
            fontSize: 12,
            marginRight: iconPosition === "start" ? 6 : 0,
            marginLeft: iconPosition === "end" ? 6 : 0,
        }),
        [iconPosition],
    );

    const icon = React.useMemo(
        () =>
            loading ? (
                <LoadingOutlined style={iconCss} />
            ) : (
                propIcon ?? <DownOutlined style={iconCss} />
            ),
        [iconCss, loading, propIcon],
    );

    const startIcon = React.useMemo(
        () => showIcon && iconPosition === "start" && icon,
        [showIcon, iconPosition, icon],
    );

    const endIcon = React.useMemo(
        () => showIcon && iconPosition === "end" && icon,
        [showIcon, iconPosition, icon],
    );

    return (
        <Space.Compact direction={direction} id={id}>
            {title && (
                <Tag
                    color={color}
                    bordered={false}
                    style={{
                        backgroundColor: "transparent",
                        padding: 0,
                    }}
                >
                    {title}
                </Tag>
            )}
            <Dropdown
                menu={{
                    items: items,
                    onClick: handleClick,
                    selectable: selectable,
                    selectedKeys: [value],
                }}
                placement="bottomLeft"
                trigger={["click"]}
                disabled={disabled}
            >
                <Tag color={color} style={{ cursor: "pointer" }}>
                    <Space.Compact block>
                        {startIcon}
                        {label}
                        {endIcon}
                    </Space.Compact>
                </Tag>
            </Dropdown>
        </Space.Compact>
    );
}
