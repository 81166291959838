import {
    GET_BUSINESS_AREA_SCHEMA_TREE_REQUEST,
    GET_BUSINESS_AREA_SCHEMA_TREE_SUCCESS,
    GET_BUSINESS_AREA_SCHEMA_TREE_FAILURE,

    CREATE_SCHEMA_CLONE_SUCCESS,
    CREATE_SCHEMA_CLONE_FAILURE
} from "./actions";

const initialState = {
    getBusinessAreaSchemaTreeResult: {
        success: true
    },
    businessAreaSchemaTree: [],
    createSchemaCloneRequestResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BUSINESS_AREA_SCHEMA_TREE_REQUEST:
            return {
                ...state,
                getBusinessAreaSchemaTreeResult:
                {
                    success: true,
                    inProgress: true
                }
            };
        case GET_BUSINESS_AREA_SCHEMA_TREE_SUCCESS:
            return {
                ...state,
                getBusinessAreaSchemaTreeResult:
                {
                    success: true,
                    inProgress: false
                },
                businessAreaSchemaTree: action.payload.result
            };
        case GET_BUSINESS_AREA_SCHEMA_TREE_FAILURE:
            return {
                ...state,
                getBusinessAreaSchemaTreeResult:
                {
                    success: false,
                    inProgress: false,
                    ...action.payload.error
                }
            };
        case CREATE_SCHEMA_CLONE_SUCCESS:
            return {
                ...state,
                createSchemaCloneRequestResult:
                {
                    success: true
                }
            };
        case CREATE_SCHEMA_CLONE_FAILURE:
            return {
                ...state,
                createSchemaCloneRequestResult:
                {
                    success: false,
                    ...action.payload.error
                }
            };
        default:
            return state;
    }
};
