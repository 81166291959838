import React from "react";
import { Switch, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { CrownTwoTone, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FullHeightContainerLayout } from "components";
import { toLocalFromUtcDate } from 'common/Utility';

const { Column } = Table;

const TenantUserList = ({ userList, onChangeAdmin }) => {

    const onChangeAdminClick = (user) => {
        if (user.userType !== "ACCOUNT_ADMIN") {
            onChangeAdmin(user.userId)
        }
    }

    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="userId"
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            dataSource={userList}
                            className={userList.length === 0 ? "container-height-100 table-empty-body" : "container-height-100"}>
                            <Column
                                dataIndex="email"
                                title="UserID"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, user, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex="firstName"
                                title="User Name"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, user, index) => <Tooltip placement="topLeft" title={`${user.firstName} ${user.lastName}`}>{`${user.firstName} ${user.lastName}`}</Tooltip>}
                            />
                            <Column
                                dataIndex="createdDate"
                                title="Created Date"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, user, index) => <Tooltip placement="topLeft" title={toLocalFromUtcDate(value)}>{toLocalFromUtcDate(value)}</Tooltip>}
                            />
                            <Column
                                dataIndex="createdByEmail"
                                title="Created By"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, user, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                dataIndex="active"
                                title="Current Status"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, user, index) => <Tooltip placement="topLeft" title={value ? "Active and Enabled" : "In-Active"}>{value ? "Active and Enabled" : "In-Active"}</Tooltip>}
                            />
                            <Column
                                width="10%"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, user, index) => {
                                    return <Space>
                                        <Tooltip title={user.userType === "ACCOUNT_ADMIN" ? "User is an account admin" : "Promote a user to Admin"}>
                                            <CrownTwoTone
                                                twoToneColor={user.userType === "ACCOUNT_ADMIN" ? "#ff9412" : ""}
                                                onClick={() => onChangeAdminClick(user)} />
                                        </Tooltip>
                                    </Space>
                                }}
                            />
                        </Table>
                    </div>
                </div >}>
        </FullHeightContainerLayout >
    );
};

export default TenantUserList;