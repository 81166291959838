import React from 'react';
import { useParams } from "react-router-dom";
import { Configuration, PageLayout } from 'components';

const ConfigurationPage = (props) => {
    const { configurationItem, itemAction, itemId, subItem } = useParams();
    return (
        <PageLayout
            mainContent={({ setBreadcrumbNavigationItems }) => {
                return <Configuration setBreadcrumbNavigationItems={setBreadcrumbNavigationItems} activeItem={configurationItem} itemAction={itemAction && itemAction.toLowerCase()} itemId={itemId} subItem={subItem}></Configuration>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default ConfigurationPage
