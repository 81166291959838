import React, { useState, useEffect } from 'react';
import { Pagination, Row, Col } from "antd";
import { FullHeightContainerLayout } from "components";

const PaginatedListContainer = ({ items, render }) => {
    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedItems, setPaginatedItems] = useState(items && items.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setPaginatedItems(items && items.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [items]);

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedItems = items && items.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedItems(currentPaginatedItems);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
    }

    return (
        <FullHeightContainerLayout
            showHeader={false}
            content={render({ paginatedItems })}
            showFooter={true}
            footer={
                <Row justify="end" style={{ padding: "0.2rem 0rem" }}>
                    <Col span={24} style={{ textAlign: "end" }}>
                        <Pagination
                            size="small"
                            hideOnSinglePage={true}
                            total={items.length}
                            defaultPageSize={pagination.defaultPageSize}
                            pageSize={pagination.pageSize}
                            responsive={true}
                            showSizeChanger={false}
                            onChange={onPageClick} />
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    );
}

export default PaginatedListContainer;