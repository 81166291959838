import { Col, Flex, Row, Tabs } from "antd";
import { showError } from "common/ToastNotifications";
import convertDsltoQueryBuilder from "common/convertDslToQueryBuilder";
import {
    DataObjectSearchForm,
    DataObjectSearchResultList,
    DataSetRecordForm,
    FullHeightContainerLayout,
    LoadingOverlay,
    ProhibitedArea,
} from "components";
import AppPaths from "constants/appPaths";
import React from "react";
import { RuleGroupType } from "react-querybuilder";
import { ConnectedProps, connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { Segment } from "semantic-ui-react";
import AppState from "store/AppState";
import actions from "store/actions";
import NaturalLanguageQuery from "./NaturalLanguageQuery";

const mapStateToProps = (state: AppState) => {
    const {
        businessAreas: userBusinessAreas,
        activeBusinessAreaId,
        getUserBusinessAreasResult,
    } = state.home;

    const {
        getSearchFieldsResult,
        getUserSearchDataObjectsPermisionResult,
        searchDataObjectsResult,
        searchResult,
        searchFields,
        userSearchDataObjectsPermision,
    } = state.dataObjectSearch;

    const {
        getSchemaModelResult,
        getSchemaModelsResult,
        schemaModel,
        schemaModelListResult,
    } = state.schemaModels;

    const { getSchemaDataSetResult, schemaDataSetResult } = state.dataSets;

    const { dataObject, getDataObjectResult } = state.dataObjects;

    const rawQuery = state.queryBuilderObject.query;

    return {
        // home
        activeBusinessAreaId,
        userBusinessAreas,
        getUserBusinessAreasResult,
        // dataObjectSearch
        getSearchFieldsResult,
        getUserSearchDataObjectsPermisionResult,
        searchDataObjectsResult,
        searchResult,
        searchFields,
        userSearchDataObjectsPermision,
        // dataSets
        getSchemaDataSetResult,
        schemaDataSetResult,
        // schemaModels
        getSchemaModelResult,
        getSchemaModelsResult,
        schemaModel,
        schemaModelListResult,
        // dataObjects
        dataObject,
        getDataObjectResult,
        // For Query Builder fields
        rawQuery,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<string>>) => {
    const searchDataObjects: IDataObjectsSearchRequest = (
        query,
        searchId,
        queryType,
        businessArea,
        schema,
        dataSet,
        from,
        size,
    ) => {
        dispatch(
            actions.searchDataObjectsRequest(
                query,
                searchId,
                queryType,
                businessArea,
                schema,
                dataSet,
                from,
                size,
            ),
        );
    };
    return {
        getUserSearchDataObjectsPermission: () =>
            dispatch(actions.getUserSearchDataObjectsPermissionRequest()),
        searchDataObjects,
        getSchemaModels: (businessAreaId: string) =>
            dispatch(actions.getSchemaModelsRequest(businessAreaId)),
        getDataSetBySchemaId: (businessAreaId: string, schemaId: string) =>
            dispatch(
                actions.getDataSetBySchemaIdRequest(businessAreaId, schemaId),
            ),
        getSchemaModel: (businessAreaId: string, schemaId: string) =>
            dispatch(actions.getSchemaModelRequest(businessAreaId, schemaId)),
        getSearchFields: (
            businessArea: string,
            schema: string,
            dataSet: string,
        ) =>
            dispatch(
                actions.getSearchFieldsRequest(businessArea, schema, dataSet),
            ),
        getDataObject: (
            businessAreaId: string,
            schemaId: string,
            dataSetId: string,
            dataObjectId: string,
        ) =>
            dispatch(
                actions.getDataObjectRequest(
                    businessAreaId,
                    schemaId,
                    dataSetId,
                    dataObjectId,
                ),
            ),
        clearSearchDataObjectsResult: () =>
            dispatch(actions.clearSearchDataObjectsResultRequest()),
        setQueryBuilderObjectAction: (_q: RuleGroupType | string | null) =>
            dispatch(actions.setQueryBuilderObjectAction(_q)),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type IPropsFromRedux = ConnectedProps<typeof connector>;

interface IDataObjectSearchProps extends IPropsFromRedux {
    setMainColumnSpan?: (i: number) => void; // Deprecated, not in use
    setBreadcrumbNavigationItems?: (items: IBreadcrumbItem[]) => void;
}

function DataObjectSearch({
    activeBusinessAreaId,
    dataObject,
    getUserBusinessAreasResult,
    getDataObjectResult,
    getSchemaDataSetResult,
    getSearchFieldsResult,
    getSchemaModelResult,
    getSchemaModelsResult,
    getUserSearchDataObjectsPermisionResult,
    rawQuery,
    schemaDataSetResult,
    schemaModel,
    schemaModelListResult,
    searchDataObjectsResult,
    searchFields,
    searchResult,
    userBusinessAreas,
    userSearchDataObjectsPermision,
    clearSearchDataObjectsResult,
    getDataObject,
    getDataSetBySchemaId,
    getSchemaModel,
    getSchemaModels,
    getSearchFields,
    getUserSearchDataObjectsPermission,
    searchDataObjects,
    setBreadcrumbNavigationItems,
    setQueryBuilderObjectAction,
}: IDataObjectSearchProps) {
    const [resultListSpan, setResultListSpan] = React.useState<number>(24);
    const [fetchingPermission, setFetchingPermission] =
        React.useState<boolean>(true);
    const [activeTab, setActiveTab] = React.useState<string>("QUERY");
    const [permissionDenied, setPermissionDenied] =
        React.useState<boolean>(false);
    const [loadingSchemaList, setLoadingSchemaList] =
        React.useState<boolean>(true);
    const [loadingDataSetList, setLoadingDataSetList] =
        React.useState<boolean>(true);
    const [showDataObjectFormContainer, setShowDataObjectFormContainer] =
        React.useState<boolean>(false);
    const [closeDataObject, setCloseDataObject] =
        React.useState<boolean>(false);
    const [fetchingSearchResult, setFetchingSearchResult] =
        React.useState<boolean>(true);
    const [dataObjectPermissionDenied, setDataObjectPermissionDenied] =
        React.useState<boolean>(false);
    const [fetchingDataObject, setFetchingDataObject] =
        React.useState<boolean>(true);
    const [schemaPermissionDenied, setSchemaPermissionDenied] =
        React.useState<boolean>(false);
    const [fetchingSchemaModel, setFetchingSchemaModel] =
        React.useState<boolean>(true);
    const [queryType, setQueryType] = React.useState<IQueryType>("QUERY");
    const [searchId, setSearchId] = React.useState<string>();
    const [naturallyDerivedDslQuery, setNaturallyDerivedDslQuery] =
        React.useState<IDslCompoundQueryClause | null>(null);

    // Check if still loading Query Builder properties
    const isLoadingBuilderProperties = React.useMemo(
        () => getSearchFieldsResult?.inProgress ?? true,
        [getSearchFieldsResult],
    );

    const [searchInput, setSearchInput] = React.useState<ISearchFormValueType>({
        query: null,
        queryType: "QUERY",
        businessArea: "all",
        schema: "all",
        dataSet: "all",
    });

    const { tenant } = useParams<{ tenant: string }>();

    const showBreadCrumbNavigationItems = React.useCallback(() => {
        if (setBreadcrumbNavigationItems) {
            const breadcrumbNavigationItems: IBreadcrumbItem[] = [
                {
                    route: AppPaths.TENANT_HOME.replace(
                        ":tenant",
                        tenant.toLowerCase(),
                    ),
                    text: "Home",
                },
                {
                    route: AppPaths.TENANT_DATA_OBJECT_SEARCH.replace(
                        ":tenant",
                        tenant.toLowerCase(),
                    ),
                    text: "Search",
                },
            ];
            setBreadcrumbNavigationItems(breadcrumbNavigationItems);
        }
    }, [setBreadcrumbNavigationItems, tenant]);

    React.useEffect(() => {
        // On mount
        getUserSearchDataObjectsPermission();
        showBreadCrumbNavigationItems();

        // On dismount
        return () => {
            clearSearchDataObjectsResult();
        };
    }, [
        clearSearchDataObjectsResult,
        getUserSearchDataObjectsPermission,
        showBreadCrumbNavigationItems,
    ]);

    React.useEffect(() => {
        if (
            getUserSearchDataObjectsPermisionResult &&
            userSearchDataObjectsPermision
        ) {
            setPermissionDenied(
                !getUserSearchDataObjectsPermisionResult.success ||
                    !userSearchDataObjectsPermision.canView,
            );
            setFetchingPermission(false);
        } else {
            setFetchingPermission(true);
        }
    }, [
        getUserSearchDataObjectsPermisionResult,
        userSearchDataObjectsPermision,
    ]);

    React.useEffect(() => {
        if (getSchemaModelsResult) {
            setLoadingSchemaList(false);
        }
    }, [getSchemaModelsResult]);

    React.useEffect(() => {
        if (getSchemaDataSetResult) {
            setLoadingDataSetList(false);
        }
    }, [getSchemaDataSetResult]);

    React.useEffect(() => {
        if (searchDataObjectsResult) {
            if (!searchDataObjectsResult.success) {
                if (searchDataObjectsResult.code === "PERMISSION_DENIED") {
                    setPermissionDenied(true);
                } else {
                    showError("Could not able to search dataset records.");
                }
            } else {
                if (searchInput.queryType === "NATURAL_LANGUAGE_QUERY") {
                    setResultListSpan(18);
                    setShowDataObjectFormContainer(true);
                    setActiveTab("QUERY");
                    setCloseDataObject(true);
                } else {
                    setResultListSpan(24);
                    setShowDataObjectFormContainer(false);
                }
            }
            setFetchingSearchResult(
                searchDataObjectsResult.inProgress || false,
            );
        }
    }, [searchDataObjectsResult, searchInput.queryType]);

    React.useEffect(() => {
        if (getDataObjectResult) {
            if (!getDataObjectResult.success) {
                if (getDataObjectResult.code === "PERMISSION_DENIED") {
                    setDataObjectPermissionDenied(true);
                } else if (getDataObjectResult.code === "NOT_FOUND_ERROR") {
                    showError("Record not found!");
                } else {
                    showError("Could not able to get dataset record.");
                }
            }
            setFetchingDataObject(false);
        }
    }, [getDataObjectResult]);

    React.useEffect(() => {
        if (getSchemaModelResult) {
            if (!getSchemaModelResult.success) {
                if (getSchemaModelResult.code === "PERMISSION_DENIED") {
                    setSchemaPermissionDenied(true);
                } else {
                    showError("Could not able to get schema.");
                }
            }
            setFetchingSchemaModel(false);
        }
    }, [getSchemaModelResult]);

    const handleSearch = React.useCallback(
        (_searchInput: ISearchFormValueType) => {
            if (!_searchInput.queryType) {
                return; // Prevent wrong input
            }

            if (_searchInput.query && _searchInput.query.trim()) {
                searchDataObjects(
                    _searchInput.query,
                    null,
                    _searchInput.queryType,
                    _searchInput.businessArea,
                    _searchInput.schema,
                    _searchInput.dataSet,
                    0,
                    100,
                );
                setFetchingSearchResult(true);
                setResultListSpan(24);
                setShowDataObjectFormContainer(false);
                setQueryType(_searchInput.queryType);
            }
            setSearchInput(_searchInput);
        },
        [searchDataObjects],
    );

    const searchBySearchId = React.useCallback(
        (_searchId: string) => {
            if (searchInput.query && searchInput.query.trim()) {
                searchDataObjects(
                    null,
                    _searchId,
                    searchInput.queryType,
                    searchInput.businessArea,
                    searchInput.schema,
                    searchInput.dataSet,
                    0,
                    100,
                );
                setFetchingSearchResult(true);
                setResultListSpan(24);
                setShowDataObjectFormContainer(false);
            }
            setSearchId(_searchId);
        },
        [
            searchDataObjects,
            searchInput.businessArea,
            searchInput.dataSet,
            searchInput.query,
            searchInput.queryType,
            searchInput.schema,
        ],
    );

    const onSearchPageSelect = React.useCallback(
        (pagination: IPagination) => {
            if (searchInput.query && searchInput.query.trim()) {
                const _searchId =
                    searchId ?? (searchResult && searchResult.searchId) ?? null;
                searchDataObjects(
                    searchInput.query,
                    _searchId,
                    searchInput.queryType,
                    searchInput.businessArea,
                    searchInput.schema,
                    searchInput.dataSet,
                    (pagination.current - 1) * pagination.pageSize,
                    pagination.pageSize,
                );
                setFetchingSearchResult(true);

                if (searchInput.queryType === "NATURAL_LANGUAGE_QUERY") {
                    setResultListSpan(18);
                    setShowDataObjectFormContainer(true);
                    setActiveTab("QUERY");
                    setCloseDataObject(true);
                } else {
                    setResultListSpan(24);
                    setShowDataObjectFormContainer(false);
                }
            }
        },
        [
            searchInput.query,
            searchInput.queryType,
            searchInput.businessArea,
            searchInput.schema,
            searchInput.dataSet,
            searchId,
            searchResult,
            searchDataObjects,
        ],
    );

    const onBusinessAreaChange = React.useCallback(
        (businessAreaId: string) => {
            setLoadingSchemaList(true);
            getSchemaModels(businessAreaId);
        },
        [getSchemaModels],
    );

    const onSchemaChange = React.useCallback(
        (businessAreaId: string, schemaId: string) => {
            setLoadingDataSetList(true);
            getDataSetBySchemaId(businessAreaId, schemaId);
        },
        [getDataSetBySchemaId],
    );

    const onDataObjectSelected = React.useCallback(
        (dataObject: IDataObjectRecord) => {
            if (!dataObject) {
                return;
            }

            setResultListSpan(18);
            setShowDataObjectFormContainer(true);
            setActiveTab("DATASET_RECORD");
            setCloseDataObject(false);

            setSchemaPermissionDenied(false);
            setDataObjectPermissionDenied(false);
            setFetchingSchemaModel(true);
            setFetchingDataObject(true);

            getSchemaModel(dataObject._businessAreaId, dataObject._schemaId);
            getDataObject(
                dataObject._businessAreaId,
                dataObject._schemaId,
                dataObject._dataSetId,
                dataObject._dataObjectId,
            );
        },
        [getDataObject, getSchemaModel],
    );

    const onTabChanged = React.useCallback((activeKey: string) => {
        setActiveTab(activeKey);
    }, []);

    const onQueryBuilderSearch = React.useCallback(
        (
            dslQuery: Record<string, any> | null | undefined,
            _businessAreaId: string,
            _schemaId: string,
            _dataSetId: string,
        ) => {
            if (dslQuery) {
                searchDataObjects(
                    JSON.stringify(dslQuery),
                    null,
                    "DSL_QUERY",
                    _businessAreaId,
                    _schemaId,
                    _dataSetId,
                    0,
                    100,
                );
                // Set states
                setFetchingSearchResult(true);
                setResultListSpan(24);
                setShowDataObjectFormContainer(false);
                setQueryType("DSL_QUERY");
                setSearchInput({
                    query: JSON.stringify(dslQuery),
                    queryType: "DSL_QUERY",
                    businessArea: _businessAreaId,
                    schema: _schemaId,
                    dataSet: _dataSetId,
                });
            }
        },
        [searchDataObjects],
    );

    const handleUpdateQuery = React.useCallback(
        (_query: RuleGroupType | string | null) => {
            setQueryBuilderObjectAction(_query);
        },
        [setQueryBuilderObjectAction],
    );

    const handleQueryTypeChange = React.useCallback((q: IQueryType) => {
        setQueryType(q);
        setResultListSpan(24);
        setShowDataObjectFormContainer(false); // Change of tab, hide the dataset form
    }, []);

    const handleEditNaturalLanagugeInBuilder = React.useCallback(
        (dsl: IDslCompoundQueryClause) => {
            handleQueryTypeChange("DSL_QUERY"); // Switch to Query Builder
            setNaturallyDerivedDslQuery(dsl);
        },
        [handleQueryTypeChange],
    );

    React.useEffect(() => {
        if (
            !isLoadingBuilderProperties &&
            naturallyDerivedDslQuery &&
            searchFields.length > 0
        ) {
            const builderQuery = convertDsltoQueryBuilder(
                naturallyDerivedDslQuery,
                searchFields,
            );
            handleUpdateQuery(builderQuery);
        }
    }, [
        handleUpdateQuery,
        naturallyDerivedDslQuery,
        searchFields,
        isLoadingBuilderProperties,
    ]);

    if (!fetchingPermission && permissionDenied) {
        return <ProhibitedArea />;
    }

    return (
        <Row gutter={[8, 8]} wrap={false} className="layout-row">
            <Col span={resultListSpan}>
                <LoadingOverlay
                    busy={fetchingPermission}
                    message="Please wait..."
                />
                <FullHeightContainerLayout
                    showHeader={true}
                    header={
                        <DataObjectSearchForm
                            selectedQueryType={queryType}
                            onQueryTypeChange={handleQueryTypeChange}
                            onSearch={handleSearch}
                            activeBusinessAreaId={activeBusinessAreaId}
                            isLoadingBusinessAreas={
                                getUserBusinessAreasResult ? false : true
                            }
                            businessAreas={userBusinessAreas}
                            onBusinessAreaChange={onBusinessAreaChange}
                            loadingSchemaList={loadingSchemaList}
                            schemaList={schemaModelListResult}
                            onSchemaChange={onSchemaChange}
                            loadingDataSetList={loadingDataSetList}
                            dataSetList={schemaDataSetResult}
                            // For Query Builder
                            rawQuery={rawQuery}
                            builderProperties={searchFields}
                            isLoadingBuilderProperties={
                                isLoadingBuilderProperties
                            }
                            onUpdateQuery={handleUpdateQuery}
                            onSearchBuilderProperties={getSearchFields}
                            onQueryBuilderSearch={onQueryBuilderSearch}
                        />
                    }
                    content={
                        <Flex
                            className="container-height-100"
                            style={{
                                marginTop: "1rem",
                                height: "calc(100% - 60px)",
                            }}
                        >
                            <DataObjectSearchResultList
                                hits={
                                    fetchingSearchResult
                                        ? []
                                        : searchResult.hits
                                }
                                loading={fetchingSearchResult}
                                tableHeight={100}
                                tableClasses={["container-height-100"]}
                                onDataObjectSelected={onDataObjectSelected}
                                totalHits={searchResult.total}
                                onPageSelect={onSearchPageSelect}
                            />
                        </Flex>
                    }
                ></FullHeightContainerLayout>
            </Col>
            {showDataObjectFormContainer && (
                <Col span={6}>
                    <Segment className="field-properties-container">
                        {queryType === "NATURAL_LANGUAGE_QUERY" ? (
                            <Tabs
                                defaultActiveKey="QUERY"
                                onChange={onTabChanged}
                                activeKey={activeTab}
                                hideAdd={true}
                                style={{ height: "100%" }}
                                items={[
                                    {
                                        key: "QUERY",
                                        label: "Question Query",
                                        closable: false,
                                        style: { height: "100%" },
                                        children: (
                                            <NaturalLanguageQuery
                                                naturalLanguageText={
                                                    searchInput?.query
                                                }
                                                dslQuery={
                                                    searchResult?.dslQuery
                                                }
                                                error={searchResult?.error}
                                                searchId={
                                                    searchResult?.searchId
                                                }
                                                properties={
                                                    searchResult?.properties
                                                }
                                                onSearch={(_searchId: string) =>
                                                    searchBySearchId(_searchId)
                                                }
                                                onEditInBuilder={
                                                    handleEditNaturalLanagugeInBuilder
                                                }
                                            />
                                        ),
                                    },
                                    {
                                        key: "DATASET_RECORD",
                                        label: "Dataset Record",
                                        closable: false,
                                        style: { height: "100%" },
                                        children: !closeDataObject && (
                                            <DataSetRecordForm
                                                isLoading={
                                                    fetchingSchemaModel ||
                                                    fetchingDataObject
                                                }
                                                isProhibited={
                                                    dataObjectPermissionDenied ||
                                                    schemaPermissionDenied
                                                }
                                                dataObject={dataObject}
                                                schemaModel={schemaModel}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        ) : (
                            <DataSetRecordForm
                                isLoading={
                                    fetchingSchemaModel || fetchingDataObject
                                }
                                isProhibited={
                                    dataObjectPermissionDenied ||
                                    schemaPermissionDenied
                                }
                                dataObject={dataObject}
                                schemaModel={schemaModel}
                            />
                        )}
                    </Segment>
                </Col>
            )}
        </Row>
    );
}

export default connector(DataObjectSearch);
