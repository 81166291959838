import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getAppClientEvents(api, clientId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/appclients/${clientId}/events`);
    return result.data;
}

export function* getAppClientEventsRequest(api, { clientId }) {
    try {
        let appClientEventsResult = yield call(getAppClientEvents, api, clientId);
        yield put(actions.getAppClientEventsSuccess(appClientEventsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "credential events");
        yield put(actions.getAppClientEventsFailure(errorObject));
    }
}

export function* watchGetAppClientEventsRequest(api, { params }) {
    yield call(getAppClientEventsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_APP_CLIENT_EVENTS_REQUEST, watchGetAppClientEventsRequest, api);
}