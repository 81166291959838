import {
    GET_PARSED_SCHEMA_DEFINITION_SUCCESS,
    GET_PARSED_SCHEMA_DEFINITION_FAILURE
} from "./actions";

const initialState = {
    getParsedSchemaDefinitionResult: {
        success: true
    },
    schemaDefinition: {
        schemaFields: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PARSED_SCHEMA_DEFINITION_SUCCESS:
            return { ...state, getParsedSchemaDefinitionResult: { success: true }, schemaDefinition: action.payload.schemaDefinition };
        case GET_PARSED_SCHEMA_DEFINITION_FAILURE:
            return { ...state, getParsedSchemaDefinitionResult: { success: false, ...action.payload.error }, schemaDefinition: { schemaFields: [] } };
        default:
            return state;
    }
};
