import React from 'react';
import { useParams } from "react-router-dom";
import { PageLayout } from 'components';
import { Lineage } from 'containers';

const LineagePage = (props) => {
    const { tenant } = useParams();
    return (
        <PageLayout
            mainContent={({ setMainColumnSpan, setBreadcrumbNavigationItems }) => {
                return <Lineage
                    setMainColumnSpan={setMainColumnSpan}
                    setBreadcrumbNavigationItems={setBreadcrumbNavigationItems}
                    tenant={tenant}></Lineage>
            }}
            helpContent={() => {
                return <></>;
            }}>
        </PageLayout>
    )
}

export default LineagePage