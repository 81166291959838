
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    MarkerType
} from 'reactflow';
import { DoubleRightOutlined, DoubleLeftOutlined, CaretDownFilled, FullscreenOutlined } from '@ant-design/icons';
import 'reactflow/dist/style.css';
import {
    LineageBusinessAreaNode,
    LineageBusinessAreaExpandNode,
    LineageSchemaNode,
    LineageDataSetNode,
    LineageDataSetFieldNode,
    LineageNode,
    LineageDuplicateConfigNode,
    LineageFieldNode,
    LineageTagNode
} from 'components';
import businessAreaDataDomains from "common/data/businessAreaDataDomains";

const { Text, Link, Title } = Typography;
const NODE_HEIGHT = {
    "BUSINESS_AREA": 50,
    "DATA_DOMAIN": 50,
    "SCHEMA": 50,
    "DATA_SET": 70,
    "JOB": 150,
}

const NODE_TYPES = {
    "BUSINESS_AREA": "businessAreaNode",
    "EXPANDED_BUSINESS_AREA": "businessAreaExpandNode",
    "DATA_DOMAIN": "lineageNode",
    "SCHEMA": "schemaNode",
    "DATA_SET": "dataSetNode",
    "JOB": "duplicateConfigNode",
}

const NODE_WIDTH = 200;
const DUPLICATE_CONFIG_NODE_WIDTH = 250;

const GRAPH_START_X = 50
const NODE_VERTICLE_GAP = 20
const NODE_HORIZONTAL_GAP = 100

const FIELD_NODE_VERTICLE_GAP = 10
const FIELD_NODE_HEIGHT = 20
const FIELD_NODE_Y_OFFSET = 20

const UNSELECTED_EDGE_NODES_OPACITY = 0.3
const SELECTED_EDGE_NODES_OPACITY = 1

const LineageView = ({
    lineageNodes,
    businessAreaPermission,
    schemaPermission,
    dataSetPermission,
    businessAreaDataPermission,
    onNodeActionClick,
    treeInitNodeFilter = ["BUSINESS_AREA", "JOB"]
}) => {
    const nodeTypes = useMemo(() => ({
        businessAreaNode: LineageBusinessAreaNode,
        businessAreaExpandNode: LineageBusinessAreaExpandNode,
        schemaNode: LineageSchemaNode,
        dataSetNode: LineageDataSetNode,
        dataSetFieldNode: LineageDataSetFieldNode,
        lineageNode: LineageNode,
        duplicateConfigNode: LineageDuplicateConfigNode,
        fieldNode: LineageFieldNode,
        tagNode: LineageTagNode,
    }), []);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const nodeFilter = useRef(treeInitNodeFilter);
    const reactFlow = useRef(null);
    const lastClickedEdge = useRef(null);
    const selectedEdgesSet = useRef(new Set());

    useEffect(() => {
        setGraphNodesRowAndColumn(lineageNodes);
        buildGraph(lineageNodes, treeInitNodeFilter);
    }, [lineageNodes]);

    useEffect(() => {
        if (reactFlow.current) {
            setTimeout(() => {
                reactFlow.current.fitView({ includeHiddenNodes: true })
            }, 10);
        }
    }, [nodes]);

    const onInit = (reactFlowInstance) => {
        reactFlow.current = reactFlowInstance;
    }

    const setGraphNodesRowAndColumn = (lineageNodes) => {
        let row = 1;
        let column = 1;
        let previousNode = null;
        for (let lineageNode of lineageNodes) {
            if (previousNode) {
                if (previousNode.type !== lineageNode.type) {
                    column = column + 1;
                    row = 1;
                }
                else {
                    row = row + 1;
                }
            }
            lineageNode.row = row;
            lineageNode.column = column;
            previousNode = lineageNode;
        }
    }

    const buildGraph = (lineageNodes, nodeTypes = ["BUSINESS_AREA", "JOB"]) => {
        let graphNodes = [];
        addGraphNodes(graphNodes, null, lineageNodes, nodeTypes, 0, 0);
        setNodes(graphNodes);
        addGraphEdges(graphNodes)
    }

    const addGraphNodes = (graphNodes, parentNodeId, lineageNodes, nodeTypeFilter, startX, startY) => {
        let x = startX;
        let y = startY;
        let maxHeight = y;
        let maxWidth = 0;
        let xGap = 50;
        let yGap = 20;
        let lastGraphNode = null;
        let filteredLineageNodes = lineageNodes.reduce((filteredNodes, lineageNode, i) => {
            if (isSatisfyingFilterCriteria(lineageNode, nodeTypeFilter, parentNodeId)) {
                filteredNodes.push({
                    index: i,
                    lineageNode
                });
            }
            return filteredNodes;
        }, []);

        for (let i = 0; i < filteredLineageNodes.length; i++) {
            let { index, lineageNode } = filteredLineageNodes[i];
            let graphNode = prepareGraphNode(x, y, index, lineageNode, lineageNodes, businessAreaPermission, businessAreaDataPermission, schemaPermission, dataSetPermission);
            if (parentNodeId) {
                graphNode.extent = 'parent';
                graphNode.parentNode = parentNodeId.value;
            }
            graphNodes.push(graphNode);
            if (lineageNode.type === "BUSINESS_AREA") {
                if (lineageNode.isExpanded) {
                    let childNodeCoordinates = addGraphNodes(graphNodes, { name: "businessAreaId", value: lineageNode.id }, lineageNodes, ["DATA_DOMAIN", "SCHEMA", "DATA_SET"], xGap, NODE_HEIGHT[lineageNode.type])
                    if (childNodeCoordinates) {
                        graphNode.style.width = childNodeCoordinates.width + xGap;
                        graphNode.style.height = childNodeCoordinates.height + yGap;
                    }
                }
            }
            else {
                if (lineageNode.isExpanded) {
                    let fieldNodeCoordinates = null;
                    if (lineageNode.type === "SCHEMA") {
                        fieldNodeCoordinates = addSchemaFields(graphNode, lineageNode, graphNodes);
                    }
                    else if (lineageNode.type === "DATA_SET") {
                        fieldNodeCoordinates = addDataSetFields(graphNode, lineageNode, graphNodes);
                    }
                    else if (lineageNode.type === "JOB") {
                        fieldNodeCoordinates = addDuplicateConfigTagFields(graphNode, lineageNode, graphNodes);
                    }

                    if (fieldNodeCoordinates) {
                        graphNode.style.width = fieldNodeCoordinates.width + 5;
                        graphNode.style.height = fieldNodeCoordinates.height + yGap;
                    }
                }
            }

            if (graphNode) {
                maxWidth = Math.max(maxWidth, graphNode.style.width);
                lastGraphNode = graphNode;
                y = y + graphNode.style.height + yGap;
                maxHeight = Math.max(maxHeight, y);
                if (i !== filteredLineageNodes.length - 1) {
                    if (filteredLineageNodes[i].lineageNode.column !== filteredLineageNodes[i + 1].lineageNode.column) {
                        x = x + maxWidth + xGap;
                        y = startY;
                    }
                }
            }
        }
        return lastGraphNode ? { width: lastGraphNode.position.x + lastGraphNode.style.width, height: maxHeight - yGap } : null
    }

    const isSatisfyingFilterCriteria = (lineageNode, nodeTypeFilter, parentNodeId) => {
        if (nodeTypeFilter.includes(lineageNode.type)) {
            if (parentNodeId) {
                if (lineageNode[parentNodeId.name] === parentNodeId.value) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    const prepareGraphNode = (
        x,
        y,
        lineageNodeIndex,
        lineageNode,
        lineageNodes,
        businessAreaPermission,
        businessAreaDataPermission,
        schemaPermission,
        dataSetPermission) => {
        let graphNode = {
            id: lineageNode.id,
            type: NODE_TYPES[lineageNode.type],
            data: {
                name: lineageNode.name,
                type: lineageNode.type,
                description: lineageNode.description,
                node: lineageNode
            },
            position: {
                x: x,
                y: y
            },
            style: {
                height: NODE_HEIGHT[lineageNode.type],
                width: NODE_WIDTH,
                opacity: 1
            }
        };
        switch (lineageNode.type) {
            case "BUSINESS_AREA":
            case "EXPANDED_BUSINESS_AREA":
                graphNode.data.onZoomIn = () => zoomInBusinessArea(lineageNodeIndex, lineageNode);
                graphNode.data.onZoomOut = () => zoomOutBusinessArea(lineageNodeIndex, lineageNode);
                if (lineageNode.isExpanded) {
                    graphNode.type = NODE_TYPES["EXPANDED_BUSINESS_AREA"];
                    graphNode.data.onContract = () => contractNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                else {
                    graphNode.data.onExpand = () => expandNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, lineageNode);
                graphNode.data.source = true;
                graphNode.data.destination = false;
                graphNode.data.background = '#FFFACD';
                graphNode.data.permission = businessAreaPermission;
                graphNode.data.title = getTitleFromNodeType(lineageNode.type);
                graphNode.data.disableEditAction = lineageNode.name === "DEFAULT";
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#FFFACD',
                    color: 'black',
                }
                break;
            case "DATA_DOMAIN":
                graphNode.data.source = true;
                graphNode.data.destination = true;
                graphNode.data.background = '#ffd4d4';
                graphNode.data.permission = businessAreaDataPermission;
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, lineageNode)
                graphNode.data.title = getTitleFromNodeType(lineageNode.type);
                graphNode.data.disableEditAction = true
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#ffd4d4',
                    color: 'black',
                }
                break;
            case "SCHEMA":
                graphNode.data.onZoomIn = () => zoomInSchema(lineageNodeIndex, lineageNode);
                graphNode.data.onZoomOut = () => zoomOutSchema(lineageNodeIndex, lineageNode);
                if (lineageNode.isExpanded) {
                    graphNode.data.onContract = () => contractNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                else {
                    graphNode.data.onExpand = () => expandNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, lineageNode);
                graphNode.data.source = true;
                graphNode.data.destination = true;
                graphNode.data.background = '#E0FFFF';
                graphNode.data.permission = schemaPermission;
                graphNode.data.title = getTitleFromNodeType(lineageNode.type);
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#E0FFFF',
                    color: 'black',
                }
                if (lineageNode.parentNode && lineageNode.parentNode.descendentTypes && lineageNode.parentNode.descendentTypes.includes("DATA_DOMAIN")) {
                    graphNode.position.x = graphNode.position.x + NODE_WIDTH + NODE_HORIZONTAL_GAP;
                }
                break;
            case "DATA_SET":
                graphNode.data.onZoomIn = () => zoomInDataSet(lineageNodeIndex, lineageNode);
                graphNode.data.onZoomOut = () => zoomOutDataSet(lineageNodeIndex, lineageNode);
                if (lineageNode.isExpanded) {
                    graphNode.data.onContract = () => contractNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                else {
                    graphNode.data.onExpand = () => expandNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, lineageNode);
                graphNode.data.source = true;
                graphNode.data.destination = true;
                graphNode.data.background = '#E6E6FA';
                graphNode.data.permission = dataSetPermission;
                graphNode.data.title = getTitleFromNodeType(lineageNode.type);
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#E6E6FA',
                    color: 'black',
                }
                break;
            case "JOB":
                if (lineageNode.isExpanded) {
                    graphNode.data.onContract = () => contractNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                else {
                    graphNode.data.onExpand = () => expandNode(lineageNodeIndex, lineageNode, lineageNodes);
                }
                graphNode.data.onActionClick = (action) => onNodeActionClick(action, lineageNode)
                graphNode.data.source = false;
                graphNode.data.destination = true;
                graphNode.data.background = '#E0FFE0';
                graphNode.data.title = getTitleFromNodeType(lineageNode.type);
                graphNode.data.duplicateRecords = lineageNode.duplicateRecords;
                graphNode.data.maxClusterSize = lineageNode.maxClusterSize;
                graphNode.data.totalClusters = lineageNode.totalClusters;
                graphNode.data.totalRecords = lineageNode.totalRecords;
                graphNode.data.dataTags = lineageNode.dataTags;
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#E0FFE0',
                    color: 'black',
                    width: DUPLICATE_CONFIG_NODE_WIDTH
                }
                break;
            default:
                graphNode.data.source = false;
                graphNode.data.destination = false;
                graphNode.data.background = '#FFFACD';
                graphNode.style = {
                    ...(graphNode.style || {}),
                    background: '#FFFACD',
                    color: 'black',
                }
                break;
        }
        return graphNode;
    }

    const addSchemaFields = (schemaGraphNode, schemaLineageNode, graphNodes) => {
        let yGap = 5;
        let y = 50;
        let x = 5;
        let graphNode = null;
        if (schemaLineageNode.fields && schemaLineageNode.fields.length > 0) {
            schemaGraphNode.data.source = false;
            schemaGraphNode.data.destination = false;
            let destination = false;
            let source = true;
            for (let i = 0; i < schemaLineageNode.fields.length; i++) {
                let field = schemaLineageNode.fields[i];
                graphNode = {
                    id: `${schemaLineageNode.id}_${field.name}`,
                    type: 'fieldNode',
                    data: {
                        name: field.name,
                        tags: field.tags,
                        background: '#ffd9c9',
                        destination: destination || field.dataDomainId,
                        source,
                        node: {
                            ...field,
                            type: "SCHEMA_FIELD"
                        }
                    },
                    extent: 'parent',
                    parentNode: schemaLineageNode.id,
                    position: {
                        x: x,
                        y: y
                    },
                    style: {
                        background: '#ffd9c9',
                        color: 'black',
                        height: "auto",
                        width: "auto",
                        fontSize: "1vh",
                        height: FIELD_NODE_HEIGHT,
                        width: NODE_WIDTH,
                        opacity: 1
                    }
                };
                graphNodes.push(graphNode);

                y = y + graphNode.style.height + yGap;
            }
        }

        return graphNode ? { width: graphNode.position.x + graphNode.style.width, height: graphNode.position.y + graphNode.style.height } : null;
    }

    const addDataSetFields = (dataSetGraphNode, dataSetLineageNode, graphNodes) => {
        let yGap = 10;
        let y = 50;
        let x = 5;
        let graphNode = null;
        if (dataSetLineageNode.fields && dataSetLineageNode.fields.length > 0) {
            dataSetGraphNode.data.source = false;
            dataSetGraphNode.data.destination = false;
            for (let i = 0; i < dataSetLineageNode.fields.length; i++) {
                let field = dataSetLineageNode.fields[i];
                graphNode = {
                    id: `${dataSetLineageNode.id}_${field.name}`,
                    type: 'dataSetFieldNode',
                    data: {
                        name: field.name,
                        tags: field.tags,
                        successDataCount: field.successDataCount,
                        erroredDataCount: field.erroredDataCount,
                        background: '#ffd9c9',
                        destination: true,
                        source: true,
                        node: {
                            ...field,
                            type: "DATA_SET_FIELD"
                        }
                    },
                    extent: 'parent',
                    parentNode: dataSetLineageNode.id,
                    position: {
                        x: x,
                        y: y
                    },
                    style: {
                        background: '#ffd9c9',
                        color: 'black',
                        height: "auto",
                        width: "auto",
                        fontSize: "1vh",
                        height: FIELD_NODE_HEIGHT,
                        width: NODE_WIDTH,
                        opacity: 1
                    }
                };
                graphNodes.push(graphNode);

                y = y + graphNode.style.height + yGap;
            }
        }

        return graphNode ? { width: graphNode.position.x + graphNode.style.width, height: graphNode.position.y + graphNode.style.height } : null;
    }

    const addDuplicateConfigTagFields = (duplicateConfigGraphNode, duplicateConfigLineageNode, graphNodes) => {
        let yGap = 5;
        let y = 125;
        let x = 5;
        let graphNode = null;
        if (duplicateConfigLineageNode.dataTags && duplicateConfigLineageNode.dataTags.length > 0) {
            duplicateConfigGraphNode.data.source = false;
            duplicateConfigGraphNode.data.destination = false;
            for (let i = 0; i < duplicateConfigLineageNode.dataTags.length; i++) {
                let dataTag = duplicateConfigLineageNode.dataTags[i];
                graphNode = {
                    id: `${duplicateConfigLineageNode.id}_${dataTag}`,
                    type: 'tagNode',
                    data: {
                        name: dataTag,
                        background: '#ffd9c9',
                        node: {
                            type: "DUPLICATE_CONFIG_TAG_FIELD",
                            name: dataTag
                        }
                    },
                    extent: 'parent',
                    parentNode: duplicateConfigLineageNode.id,
                    position: {
                        x: x,
                        y: y
                    },
                    style: {
                        background: '#ffd9c9',
                        color: 'black',
                        height: "auto",
                        width: "auto",
                        fontSize: "1vh",
                        height: 15,
                        width: NODE_WIDTH,
                        opacity: 1
                    }
                };
                graphNodes.push(graphNode);

                y = y + graphNode.style.height + yGap;
            }
        }

        return graphNode ? { width: graphNode.position.x + graphNode.style.width, height: graphNode.position.y + graphNode.style.height } : null;
    }

    const getTitleFromNodeType = (type) => {
        switch (type) {
            case "BUSINESS_AREA":
                return "Business Area";
            case "DATA_DOMAIN":
                return "Business Area Data";
            case "SCHEMA":
                return "Schema";
            case "DATA_SET":
                return "Dataset";
            case "JOB":
                return "Duplicate Search Config";
            default:
                return "";
        }
    }

    const addGraphEdges = (graphNodes) => {
        setEdges([]);
        setTimeout(() => {
            let graphEdges = [];
            let graphNodeDict = {};
            for (let graphNode of graphNodes) {
                switch (graphNode.data.node.type) {
                    case "BUSINESS_AREA":
                    case "DATA_DOMAIN":
                        graphNodeDict[graphNode.id] = graphNode;
                        break;
                    case "SCHEMA":
                    case "DATA_SET":
                    case "JOB":
                        graphNodeDict[graphNode.id] = [
                            graphNode
                        ]
                        break;
                    case "SCHEMA_FIELD":
                    case "DATA_SET_FIELD":
                    case "DUPLICATE_CONFIG_TAG_FIELD":
                        graphNodeDict[graphNode.parentNode].push(graphNode);
                        break;
                }
            }

            for (let graphNode of graphNodes) {
                switch (graphNode.data.node.type) {
                    case "SCHEMA":
                        addSchemaTargetEdges(graphNodeDict, graphNode, graphEdges);
                        break;
                    case "DATA_SET":
                        addDataSetTargetEdges(graphNodeDict, graphNode, graphEdges);
                        break;
                    case "JOB":
                        addDuplicateSearchConfigTargetEdges(graphNodeDict, graphNode, graphEdges);
                        break;
                }

            }
            setEdges(graphEdges);
            setTimeout(() => {
                if (lastClickedEdge.current) {
                    onEdgeClick(null, lastClickedEdge.current);
                }
            }, 10)
        }, 10);
    }

    const addSchemaTargetEdges = (graphNodeDict, graphNode, graphEdges) => {
        if (graphNodeDict[graphNode.id].length === 1) {
            let dataDomainSet = new Set();
            let schemaGraphNode = graphNodeDict[graphNode.id][0];
            let schemaLineageNode = schemaGraphNode.data.node;
            for (let field of schemaLineageNode.fields) {
                if (field.dataDomainId && dataDomainSet.has(field.dataDomainId) === false && graphNodeDict[field.dataDomainId]) {
                    dataDomainSet.add(field.dataDomainId);
                    graphEdges.push({
                        id: `${field.dataDomainId}_${graphNode.id}`,
                        source: `${field.dataDomainId}`,
                        target: `${graphNode.id}`,
                        markerEnd: { type: MarkerType.Arrow },
                        data: {
                            leftNode: graphNodeDict[field.dataDomainId],
                            rightNode: schemaGraphNode
                        },
                        zIndex: 100,
                        interactionWidth: 5,
                        animated: true,
                        selected: false,
                        style: {
                            opacity: 1
                        }
                    });
                }
            }
        }
        else {
            for (let schemaGraphNode of graphNodeDict[graphNode.id]) {
                let lineageNode = schemaGraphNode.data.node;
                if (lineageNode.type === "SCHEMA_FIELD") {
                    if (lineageNode.dataDomainId && graphNodeDict[lineageNode.dataDomainId]) {
                        graphEdges.push({
                            id: `${lineageNode.dataDomainId}_${graphNode.id}:${lineageNode.name}`,
                            source: `${lineageNode.dataDomainId}`,
                            target: schemaGraphNode.id,
                            markerEnd: { type: MarkerType.Arrow },
                            data: {
                                leftNode: graphNodeDict[lineageNode.dataDomainId],
                                rightNode: schemaGraphNode
                            },
                            zIndex: 100,
                            interactionWidth: 5,
                            animated: true,
                            selected: false,
                            style: {
                                opacity: 1
                            }
                        });
                    }
                }
            }
        }
    }

    const addDataSetTargetEdges = (graphNodeDict, dataSetGraphNode, graphEdges) => {
        let dataSetLineageNode = dataSetGraphNode.data.node;
        if (graphNodeDict[dataSetLineageNode.schemaId]) {
            if (graphNodeDict[dataSetGraphNode.id].length === 1) {
                if (graphNodeDict[dataSetLineageNode.schemaId].length === 1) {
                    let schemaGraphNode = graphNodeDict[dataSetLineageNode.schemaId][0];
                    graphEdges.push({
                        id: `${schemaGraphNode.id}_${dataSetGraphNode.id}`,
                        source: `${schemaGraphNode.id}`,
                        target: `${dataSetGraphNode.id}`,
                        markerEnd: { type: MarkerType.Arrow },
                        data: {
                            leftNode: schemaGraphNode,
                            rightNode: dataSetGraphNode
                        },
                        zIndex: 100,
                        interactionWidth: 5,
                        animated: true,
                        selected: false,
                        style: {
                            opacity: 1
                        }
                    });
                }
                else {
                    let dataSetLineageFieldsDict = {};
                    for (let field of dataSetLineageNode.fields) {
                        dataSetLineageFieldsDict[field.id] = field;
                    }

                    for (let schemaFieldGraphNode of graphNodeDict[dataSetLineageNode.schemaId]) {
                        let schemaFieldLineageNode = schemaFieldGraphNode.data.node;
                        if (schemaFieldLineageNode.type === "SCHEMA_FIELD") {
                            if (dataSetLineageFieldsDict[schemaFieldLineageNode.id]) {
                                graphEdges.push({
                                    id: `${dataSetLineageNode.schemaId}_${schemaFieldLineageNode.name}:${dataSetGraphNode.id}`,
                                    source: `${dataSetLineageNode.schemaId}_${schemaFieldLineageNode.name}`,
                                    target: dataSetGraphNode.id,
                                    markerEnd: { type: MarkerType.Arrow },
                                    data: {
                                        leftNode: schemaFieldGraphNode,
                                        rightNode: dataSetGraphNode
                                    },
                                    zIndex: 100,
                                    interactionWidth: 5,
                                    animated: true,
                                    selected: false,
                                    style: {
                                        opacity: 1
                                    }
                                });
                            }
                        }
                    }
                }
            }
            else {
                if (graphNodeDict[dataSetLineageNode.schemaId].length === 1) {
                    let schemaGraphNode = graphNodeDict[dataSetLineageNode.schemaId][0];
                    let schemaLineageNode = schemaGraphNode.data.node;
                    let schemaLineageFieldsDict = {};
                    for (let field of schemaLineageNode.fields) {
                        schemaLineageFieldsDict[field.id] = field;
                    }

                    for (let dataSetFieldGraphNode of graphNodeDict[dataSetGraphNode.id]) {
                        let dataSetFieldLineageNode = dataSetFieldGraphNode.data.node;
                        if (dataSetFieldLineageNode.type === "DATA_SET_FIELD") {
                            if (schemaLineageFieldsDict[dataSetFieldLineageNode.id]) {
                                graphEdges.push({
                                    id: `${schemaGraphNode.id}_${dataSetGraphNode.id}:${dataSetFieldLineageNode.name}`,
                                    source: `${schemaGraphNode.id}`,
                                    target: `${dataSetGraphNode.id}_${dataSetFieldLineageNode.name}`,
                                    markerEnd: { type: MarkerType.Arrow },
                                    data: {
                                        leftNode: schemaGraphNode,
                                        rightNode: dataSetFieldGraphNode
                                    },
                                    zIndex: 100,
                                    interactionWidth: 5,
                                    animated: true,
                                    selected: false,
                                    style: {
                                        opacity: 1
                                    }
                                });
                            }
                        }
                    }
                }
                else {
                    let schemaFieldGraphNodesDict = {};
                    for (let schemaFieldGraphNode of graphNodeDict[dataSetLineageNode.schemaId]) {
                        let lineageFieldNode = schemaFieldGraphNode.data.node;
                        if (lineageFieldNode.type === "SCHEMA_FIELD") {
                            schemaFieldGraphNodesDict[lineageFieldNode.id] = schemaFieldGraphNode;
                        }
                    }
                    for (let dataSetFieldGraphNode of graphNodeDict[dataSetGraphNode.id]) {
                        let dataSetFieldLineageNode = dataSetFieldGraphNode.data.node;
                        if (dataSetFieldLineageNode.type === "DATA_SET_FIELD") {
                            if (schemaFieldGraphNodesDict[dataSetFieldLineageNode.id]) {
                                let schemaFieldGraphNode = schemaFieldGraphNodesDict[dataSetFieldLineageNode.id];
                                let schemaFieldLineageNode = schemaFieldGraphNode.data.node;
                                graphEdges.push({
                                    id: `${dataSetLineageNode.schemaId}:${schemaFieldLineageNode.name}_${dataSetGraphNode.id}:${dataSetFieldLineageNode.name}`,
                                    source: `${schemaFieldGraphNode.id}`,
                                    target: `${dataSetFieldGraphNode.id}`,
                                    markerEnd: { type: MarkerType.Arrow },
                                    data: {
                                        leftNode: schemaFieldGraphNode,
                                        rightNode: dataSetFieldGraphNode
                                    },
                                    zIndex: 100,
                                    interactionWidth: 5,
                                    animated: true,
                                    selected: false,
                                    style: {
                                        opacity: 1
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    const addDuplicateSearchConfigTargetEdges = (graphNodeDict, duplicateSearchConfigGraphNode, graphEdges) => {
        let duplicateSearchConfigLineageNode = duplicateSearchConfigGraphNode.data.node;
        let businessAreaNodeEdgeSet = new Set();
        //If there are no tag nodes/child node of duplicate search config node
        if (graphNodeDict[duplicateSearchConfigGraphNode.id].length === 1) {
            for (let relation of duplicateSearchConfigLineageNode.relations) {
                let { businessAreaId, dataSetId } = relation;
                //Create graph edge between dataset and duplicate search config if dataset node exist
                if (graphNodeDict[dataSetId]) {
                    //Create graph edge between duplicate search config node and dataset node
                    if (graphNodeDict[dataSetId].length === 1) {
                        graphEdges.push({
                            id: `${dataSetId}_${duplicateSearchConfigGraphNode.id}`,
                            source: `${dataSetId}`,
                            target: `${duplicateSearchConfigGraphNode.id}`,
                            markerEnd: { type: MarkerType.Arrow },
                            data: {
                                leftNode: graphNodeDict[dataSetId][0],
                                rightNode: duplicateSearchConfigGraphNode
                            },
                            zIndex: 100,
                            interactionWidth: 5,
                            animated: true,
                            selected: false,
                            style: {
                                opacity: 1
                            }
                        });
                    }//Create graph edges between duplicate search config node and dataset field nodes
                    else {
                        for (let dataSetFieldGraphNode of graphNodeDict[dataSetId]) {
                            let dataSetFieldLineageNode = dataSetFieldGraphNode.data.node;
                            if (dataSetFieldLineageNode.type === "DATA_SET_FIELD") {
                                graphEdges.push({
                                    id: `${dataSetId}_${dataSetFieldLineageNode.name}:${duplicateSearchConfigGraphNode.id}`,
                                    source: `${dataSetId}_${dataSetFieldLineageNode.name}`,
                                    target: duplicateSearchConfigGraphNode.id,
                                    markerEnd: { type: MarkerType.Arrow },
                                    data: {
                                        leftNode: dataSetFieldGraphNode,
                                        rightNode: duplicateSearchConfigGraphNode
                                    },
                                    zIndex: 100,
                                    interactionWidth: 5,
                                    animated: true,
                                    selected: false,
                                    style: {
                                        opacity: 1
                                    }
                                });
                            }
                        }
                    }
                }
                //Create graph edge between business area node and duplicate search config node
                else if (graphNodeDict[businessAreaId]) {
                    if (businessAreaNodeEdgeSet.has(businessAreaId) === false) {
                        graphEdges.push({
                            id: `${businessAreaId}_${duplicateSearchConfigGraphNode.id}`,
                            source: `${businessAreaId}`,
                            target: `${duplicateSearchConfigGraphNode.id}`,
                            markerEnd: { type: MarkerType.Arrow },
                            data: {
                                leftNode: graphNodeDict[businessAreaId],
                                rightNode: duplicateSearchConfigGraphNode
                            },
                            zIndex: 100,
                            interactionWidth: 5,
                            animated: true,
                            selected: false,
                            style: {
                                opacity: 1
                            }
                        });
                    }
                    businessAreaNodeEdgeSet.add(businessAreaId);
                }
            }
        }
        else if (graphNodeDict[duplicateSearchConfigGraphNode.id].length > 1) {//When there are tag nodes/child nodes then graph edge should connect to them
            for (let relation of duplicateSearchConfigLineageNode.relations) {
                let { businessAreaId, dataSetId } = relation;
                //Create graph edge between dataset and duplicate search config if dataset node exist
                if (graphNodeDict[dataSetId]) {
                    //Create graph edge between duplicate search config tag fields and dataset node
                    if (graphNodeDict[dataSetId].length === 1) {
                        let dataSetTagFieldsDict = {};
                        let dataSetLineageNode = graphNodeDict[dataSetId][0].data.node;
                        for (let field of dataSetLineageNode.fields) {
                            if (field.tags && field.tags.length > 0) {
                                for (let tag of field.tags) {
                                    if (!dataSetTagFieldsDict[tag]) {
                                        dataSetTagFieldsDict[tag] = []
                                    }
                                    dataSetTagFieldsDict[tag].push(field);
                                }
                            }
                        }
                        for (let tagGraphNode of graphNodeDict[duplicateSearchConfigGraphNode.id]) {
                            if (tagGraphNode.data.node.type === "DUPLICATE_CONFIG_TAG_FIELD") {
                                let tagLineageNode = tagGraphNode.data.node;
                                if (dataSetTagFieldsDict[tagLineageNode.name]) {
                                    graphEdges.push({
                                        id: `${dataSetId}_${duplicateSearchConfigGraphNode.id}:${tagLineageNode.name}`,
                                        source: `${dataSetId}`,
                                        target: `${duplicateSearchConfigGraphNode.id}_${tagLineageNode.name}`,
                                        markerEnd: { type: MarkerType.Arrow },
                                        data: {
                                            leftNode: graphNodeDict[dataSetId][0],
                                            rightNode: tagGraphNode
                                        },
                                        zIndex: 100,
                                        interactionWidth: 5,
                                        animated: true,
                                        selected: false,
                                        style: {
                                            opacity: 1
                                        }
                                    });
                                }
                            }
                        }
                    }//Create graph edges between duplicate search config node and dataset field nodes
                    else {
                        let dataSetTagGraphFieldsDict = {};
                        for (let dataSetFieldGraphNode of graphNodeDict[dataSetId]) {
                            let dataSetFieldLineageNode = dataSetFieldGraphNode.data.node;
                            if (dataSetFieldLineageNode.type === "DATA_SET_FIELD") {
                                if (dataSetFieldLineageNode.tags && dataSetFieldLineageNode.tags.length > 0) {
                                    for (let tag of dataSetFieldLineageNode.tags) {
                                        if (!dataSetTagGraphFieldsDict[tag]) {
                                            dataSetTagGraphFieldsDict[tag] = []
                                        }
                                        dataSetTagGraphFieldsDict[tag].push(dataSetFieldGraphNode);
                                    }
                                }
                            }
                        }

                        for (let tagGraphNode of graphNodeDict[duplicateSearchConfigGraphNode.id]) {
                            if (tagGraphNode.data.node.type === "DUPLICATE_CONFIG_TAG_FIELD") {
                                let tagLineageNode = tagGraphNode.data.node;
                                if (dataSetTagGraphFieldsDict[tagLineageNode.name]) {
                                    for (let dataSetGraphField of dataSetTagGraphFieldsDict[tagLineageNode.name]) {
                                        graphEdges.push({
                                            id: `${dataSetGraphField.id}_${duplicateSearchConfigGraphNode.id}:${tagLineageNode.name}`,
                                            source: `${dataSetGraphField.id}`,
                                            target: `${duplicateSearchConfigGraphNode.id}_${tagLineageNode.name}`,
                                            markerEnd: { type: MarkerType.Arrow },
                                            data: {
                                                leftNode: dataSetGraphField,
                                                rightNode: tagGraphNode
                                            },
                                            zIndex: 100,
                                            interactionWidth: 5,
                                            animated: true,
                                            selected: false,
                                            style: {
                                                opacity: 1
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                //Create graph edge between business area node and duplicate search config node
                else if (graphNodeDict[businessAreaId]) {
                    if (businessAreaNodeEdgeSet.has(businessAreaId) === false) {
                        for (let tagGraphNode of graphNodeDict[duplicateSearchConfigGraphNode.id]) {
                            if (tagGraphNode.data.node.type === "DUPLICATE_CONFIG_TAG_FIELD") {
                                let tagLineageNode = tagGraphNode.data.node;
                                graphEdges.push({
                                    id: `${businessAreaId}_${duplicateSearchConfigGraphNode.id}:${tagLineageNode.name}`,
                                    source: `${businessAreaId}`,
                                    target: `${duplicateSearchConfigGraphNode.id}_${tagLineageNode.name}`,
                                    markerEnd: { type: MarkerType.Arrow },
                                    data: {
                                        leftNode: graphNodeDict[businessAreaId],
                                        rightNode: tagGraphNode
                                    },
                                    zIndex: 100,
                                    interactionWidth: 5,
                                    animated: true,
                                    selected: false,
                                    style: {
                                        opacity: 1
                                    }
                                });
                            }
                        }
                    }
                    businessAreaNodeEdgeSet.add(businessAreaId);
                }
            }
        }
    }

    const expandNode = (index, lineageNode, lineageNodes) => {
        lineageNode.isExpanded = true;
        buildGraph(lineageNodes, nodeFilter.current);
    }

    const contractNode = (index, lineageNode, lineageNodes) => {
        lineageNode.isExpanded = false;
        buildGraph(lineageNodes, nodeFilter.current);
    }

    const zoomInBusinessArea = (index, businessAreaNode) => {
        businessAreaNode.isZoom = true;
        let filteredLineageNodes = [];

        let businessAreaDataDict = {};
        let businessAreaDict = {};
        let allowedBusinessAreas = new Set();
        let allowedBusinessAreaData = new Set();

        let businessAreaNodes = [];

        if (businessAreaNode.isExpanded) {
            for (let lineageNode of lineageNodes) {
                if (lineageNode.type === "BUSINESS_AREA") {
                    businessAreaDict[lineageNode.id] = lineageNode;
                }
                else if (lineageNode.type === "DATA_DOMAIN") {
                    businessAreaDataDict[lineageNode.id] = lineageNode;
                }
                else if (lineageNode.type === "SCHEMA" && lineageNode.businessAreaId === businessAreaNode.id) {
                    for (let field of lineageNode.fields) {
                        if (field.dataDomainId) {
                            if (businessAreaDataDict[field.dataDomainId] && businessAreaDataDict[field.dataDomainId].businessAreaId !== businessAreaNode.id) {
                                allowedBusinessAreas.add(businessAreaDataDict[field.dataDomainId].businessAreaId);
                                allowedBusinessAreaData.add(field.dataDomainId);
                            }
                        }
                    }
                }
            }
        }

        for (let lineageNode of lineageNodes) {
            if (lineageNode.id === businessAreaNode.id) {
                filteredLineageNodes.push(lineageNode);
                businessAreaNodes.push(lineageNode);
            }
            else if (lineageNode.type === "BUSINESS_AREA" && allowedBusinessAreas.has(lineageNode.id)) {
                lineageNode.isExpanded = true;
                filteredLineageNodes.push(lineageNode);
                businessAreaNodes.push(lineageNode);
            }
            else if (lineageNode.type === "DATA_DOMAIN" && allowedBusinessAreaData.has(lineageNode.id)) {
                filteredLineageNodes.push(lineageNode);
            }
            else if (lineageNode.businessAreaId === businessAreaNode.id) {
                filteredLineageNodes.push(lineageNode);
            }
            else if (lineageNode.type === "JOB") {
                if (lineageNode.relations.find(relation => relation.businessAreaId === businessAreaNode.id)) {
                    filteredLineageNodes.push(lineageNode);
                }
            }
        }

        for (let i = 0; i < businessAreaNodes.length; i++) {
            businessAreaNodes[i].row = 1;
            businessAreaNodes[i].column = i + 1;
        }

        buildGraph(filteredLineageNodes);
        nodeFilter.current = treeInitNodeFilter;
    }

    const zoomOutBusinessArea = (index, businessAreaNode) => {
        setGraphNodesRowAndColumn(lineageNodes);
        businessAreaNode.isZoom = false;
        buildGraph(lineageNodes);
        nodeFilter.current = treeInitNodeFilter;
    }

    const zoomInSchema = (index, schemaNode) => {
        schemaNode.isZoom = true;
        let filteredLineageNodes = [];
        let dataDomainIds = new Set();
        for (let field of schemaNode.fields) {
            if (field.dataDomainId) {
                dataDomainIds.add(field.dataDomainId);
            }
        }

        for (let i = 0; i < lineageNodes.length; i++) {
            let lineageNode = lineageNodes[i];
            if (lineageNode.type === "DATA_DOMAIN" && dataDomainIds.has(lineageNode.id)) {
                filteredLineageNodes.push(lineageNode);
            }
            else if (lineageNode.id === schemaNode.id) {
                filteredLineageNodes.push(lineageNode);
            }
            else if (lineageNode.schemaId === schemaNode.id) {
                filteredLineageNodes.push(lineageNode);
            }
            else if (lineageNode.type === "JOB") {
                if (lineageNode.relations.find(relation => relation.schemaId === schemaNode.id)) {
                    filteredLineageNodes.push(lineageNode);
                }
            }
        }

        if (filteredLineageNodes.length === 0) {
            filteredLineageNodes.push(schemaNode);
        }
        buildGraph(filteredLineageNodes, ["DATA_DOMAIN", "SCHEMA", "DATA_SET", "JOB"]);
        nodeFilter.current = ["DATA_DOMAIN", "SCHEMA", "DATA_SET", "JOB"];
    }

    const zoomOutSchema = (index, schemaNode) => {
        schemaNode.isZoom = false;
        buildGraph(lineageNodes, treeInitNodeFilter);
        nodeFilter.current = treeInitNodeFilter;
    }

    const zoomInDataSet = (index, dataSetLineageNode) => {
        dataSetLineageNode.isZoom = true;
        let filteredLineageNodes = [];
        let schemaLineageNode = null;
        for (let lineageNode of lineageNodes) {
            if (lineageNode.type === "SCHEMA" && lineageNode.id === dataSetLineageNode.schemaId) {
                schemaLineageNode = lineageNode;
                break;
            }
        }
        if (schemaLineageNode) {
            let dataDomainIds = new Set();
            for (let field of schemaLineageNode.fields) {
                if (field.dataDomainId) {
                    dataDomainIds.add(field.dataDomainId);
                }
            }
            for (let lineageNode of lineageNodes) {
                if (lineageNode.type === "DATA_DOMAIN" && dataDomainIds.has(lineageNode.id)) {
                    filteredLineageNodes.push(lineageNode);
                }
                else if (lineageNode.type === "SCHEMA" && lineageNode.id === dataSetLineageNode.schemaId) {
                    filteredLineageNodes.push(lineageNode);
                }
                else if (lineageNode.id === dataSetLineageNode.id) {
                    filteredLineageNodes.push(lineageNode);
                }
                else if (lineageNode.dataSetId === dataSetLineageNode.id) {
                    filteredLineageNodes.push(lineageNode);
                }
                else if (lineageNode.type === "JOB") {
                    if (lineageNode.relations.find(relation => relation.dataSetId === dataSetLineageNode.id)) {
                        filteredLineageNodes.push(lineageNode);
                    }
                }
            }

            if (filteredLineageNodes[filteredLineageNodes.length - 1].type === "SCHEMA") {
                filteredLineageNodes.push(dataSetLineageNode);
            }

            buildGraph(filteredLineageNodes, ["DATA_DOMAIN", "SCHEMA", "DATA_SET", "JOB"]);
            nodeFilter.current = ["DATA_DOMAIN", "SCHEMA", "DATA_SET", "JOB"];
        }
    }

    const zoomOutDataSet = (index, dataSetNode) => {
        dataSetNode.isZoom = false;
        buildGraph(lineageNodes, treeInitNodeFilter);
        nodeFilter.current = treeInitNodeFilter;
    }


    const onEdgeDoubleClick = (event, edge) => {
        if (edge.data.leftNode && edge.data.leftNode.data && edge.data.leftNode.data.node) {
            let lineageNode = edge.data.leftNode.data.node;
            let graphNodes = [...reactFlow.current.getNodes()];
            let graphNodeDict = {};
            for (let node of graphNodes) {
                graphNodeDict[node.id] = node;
            }
            switch (lineageNode.type) {
                case "BUSINESS_AREA":
                    zoomInBusinessArea(null, graphNodeDict[lineageNode.id].data.node);
                    break;
                case "DATA_DOMAIN":
                    if (edge.data.rightNode && edge.data.rightNode.data && edge.data.rightNode.data.node) {
                        if (edge.data.rightNode.data.node.type === "SCHEMA") {
                            zoomInSchema(null, graphNodeDict[edge.data.rightNode.data.node.id].data.node);
                        }
                        else if (edge.data.rightNode.data.node.type === "SCHEMA_FIELD") {
                            zoomInSchema(null, graphNodeDict[edge.data.rightNode.data.node.schemaId].data.node);
                        }
                    }
                    break;
                case "SCHEMA":
                    zoomInSchema(null, graphNodeDict[lineageNode.id].data.node);
                    break;
                case "DATA_SET":
                    zoomInDataSet(null, graphNodeDict[lineageNode.id].data.node);
                    break;
                case "SCHEMA_FIELD":
                    zoomInSchema(null, graphNodeDict[lineageNode.schemaId].data.node);
                    break;
                case "DATA_SET_FIELD":
                    zoomInDataSet(null, graphNodeDict[lineageNode.dataSetId].data.node);
                    break;
            }
        }
    }

    const onEdgeClick = (event, clickedEdge) => {
        let edges = reactFlow.current.getEdges();
        let graphNodes = [...reactFlow.current.getNodes()];
        let graphNodeDict = {};
        let graphNodeEdgeDict = {};
        let clickedEdgeFound = false;
        let isClickedSelectedEdge = false;

        if (event && selectedEdgesSet.current.has(clickedEdge.id)) {
            isClickedSelectedEdge = true;
        }

        for (let edge of edges) {
            edge.selected = false;
            edge.animated = true;
            if (!graphNodeEdgeDict[edge.source]) {
                graphNodeEdgeDict[edge.source] = {
                    source: [],
                    target: []
                }
            }
            graphNodeEdgeDict[edge.source].source.push(edge);
            if (!graphNodeEdgeDict[edge.target]) {
                graphNodeEdgeDict[edge.target] = {
                    source: [],
                    target: []
                }
            }
            graphNodeEdgeDict[edge.target].target.push(edge);

            if (isClickedSelectedEdge === false && clickedEdge.id === edge.id) {
                edge.selected = true;
                edge.animated = false;
                clickedEdgeFound = true;
                lastClickedEdge.current = clickedEdge;
            }
        }

        selectedEdgesSet.current = new Set();
        if (clickedEdgeFound) {
            selectedEdgesSet.current.add(clickedEdge.id);
            for (let node of graphNodes) {
                graphNodeDict[node.id] = node;
            }
            selectAllLeftNodes(graphNodeDict[clickedEdge.source], graphNodeDict, graphNodeEdgeDict);
            selectAllRightNodes(graphNodeDict[clickedEdge.target], graphNodeDict, graphNodeEdgeDict)
            setEdges(edges);
        }
        else {
            lastClickedEdge.current = null;
            if (isClickedSelectedEdge) {
                setEdges(edges);
            }
        }
    }

    const selectAllLeftNodes = (currentNode, graphNodeDict, graphNodeEdgeDict) => {
        if (currentNode && graphNodeEdgeDict[currentNode.id] && graphNodeEdgeDict[currentNode.id].target && graphNodeEdgeDict[currentNode.id].target.length > 0) {
            for (let targetEdge of graphNodeEdgeDict[currentNode.id].target) {
                targetEdge.selected = true;
                targetEdge.animated = false;
                selectedEdgesSet.current.add(targetEdge.id);
                if (targetEdge.source) {
                    selectAllLeftNodes(graphNodeDict[targetEdge.source], graphNodeDict, graphNodeEdgeDict);
                }
            }
        }
    }

    const selectAllRightNodes = (currentNode, graphNodeDict, graphNodeEdgeDict) => {
        if (currentNode && graphNodeEdgeDict[currentNode.id] && graphNodeEdgeDict[currentNode.id].source && graphNodeEdgeDict[currentNode.id].source.length > 0) {
            for (let sourceEdge of graphNodeEdgeDict[currentNode.id].source) {
                sourceEdge.selected = true;
                sourceEdge.animated = false;
                selectedEdgesSet.current.add(sourceEdge.id);
                if (sourceEdge.target) {
                    selectAllRightNodes(graphNodeDict[sourceEdge.target], graphNodeDict, graphNodeEdgeDict);
                }
            }
        }
    }

    const nodeColor = (node) => {
        return node.style.background;
    };

    return <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        fitView
        attributionPosition="top-right"
        onInit={onInit}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onEdgeClick={onEdgeClick}>
        <MiniMap nodeColor={nodeColor} zoomable pannable />
        <Controls />
        <Background variant="lines" />
    </ReactFlow>
};

export default LineageView;