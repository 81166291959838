import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Typography, Tooltip, Space, Row, Col, Switch, Button, Tag, Dropdown } from 'antd';
import {
    Handle,
    Position
} from 'reactflow';
import { ZoomOutOutlined, ZoomInOutlined, CaretDownFilled, PlusCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import 'reactflow/dist/style.css';

const { Text, Link, Title } = Typography;

function LineageSchemaNode({ data }) {
    return (
        <>
            {
                data.destination
                    ?
                    <Handle type="target" position={Position.Left} />
                    :
                    <></>
            }
            <Row style={{
                flexDirection: "column",
                flexWrap: false,
                padding: "0rem 0.5rem",
                width: "100%",
                height: "100%",
                justifyContent: "space-between"
            }}>
                <Row style={{ width: "100%" }}>
                    <Row style={{ justifyContent: "space-between", width: "100%" }}>
                        <Col>
                            <Text style={{ fontSize: "8px" }}>{data.title}</Text>
                        </Col>
                        <Col style={{ marginLeft: "1rem" }}>
                            <Space direction="horizontal" size="small">
                                {data.node.linkedResourceExist ?
                                    <>
                                        {
                                            data.node.isZoom
                                                ?
                                                <Tooltip title={"Zoom-out schema"}>
                                                    <ZoomOutOutlined style={{ cursor: "pointer", color: "#1990FE" }} onClick={() => data.onZoomOut && data.onZoomOut()} />
                                                </Tooltip>
                                                :
                                                <Tooltip title={"Zoom-in schema"}>
                                                    <ZoomInOutlined style={{ cursor: "pointer", color: "#1990FE" }} onClick={() => data.onZoomIn && data.onZoomIn()} />
                                                </Tooltip>
                                        }
                                    </>
                                    :
                                    <></>
                                }
                                <Tooltip title={"Actions"}>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    label: 'View',
                                                    key: 'View',
                                                    disabled: !data.permission.canView,
                                                    onClick: () => data.onActionClick("VIEW")
                                                },
                                                {
                                                    label: 'Edit',
                                                    key: 'Edit',
                                                    disabled: !data.permission.canEdit || data.disableEditAction,
                                                    onClick: () => data.onActionClick("EDIT")
                                                }
                                            ]
                                        }}>
                                        <CaretDownFilled style={{ fontSize: "1.2vh", color: "#0E9FFF" }} />
                                    </Dropdown>
                                </Tooltip>
                            </Space>
                        </Col>
                    </Row>
                    <Row style={{ flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", width: "100%" }}>
                        <Col style={{ flexWrap: "nowrap", display: "flex", alignItems: "center" }}>
                            <Tooltip title={data.name}>
                                <Text ellipsis={true} style={{ fontSize: "10px" }} type="secondary">{data.name}</Text>
                            </Tooltip>
                        </Col>
                        {
                            !data.node.isExpanded && data.node.fields && data.node.fields.length > 0
                                ?
                                <Col style={{ flexWrap: "nowrap" }}>
                                    <Tooltip title="Expand schema fields">
                                        <PlusCircleTwoTone style={{ cursor: "pointer" }} onClick={() => data.onExpand && data.onExpand()} />
                                    </Tooltip>
                                </Col>
                                :
                                <></>
                        }
                    </Row>
                </Row>
                {
                    data.node.isExpanded && data.node.fields && data.node.fields.length > 0
                        ?
                        <Row style={{ flexDirection: "row", flexWrap: "nowrap", justifyContent: "end" }}>
                            <Col style={{ flexWrap: "nowrap" }}>
                                <Tooltip title="Contract schema fields">
                                    <MinusCircleTwoTone style={{ cursor: "pointer" }} onClick={() => data.onContract && data.onContract()} />
                                </Tooltip>
                            </Col>
                        </Row>
                        :
                        <></>
                }
            </Row>
            {
                data.source
                    ?
                    <Handle type="source" position={Position.Right} />
                    :
                    <></>
            }
        </>
    );
}

export default LineageSchemaNode;