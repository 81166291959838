import moment from 'moment';

export const keyGenerator = (offset) => {
    return moment().add(offset, "seconds").valueOf();
}

export const toLocalFromUtcDate = (utcDate) => {
    if (!utcDate) {
        return utcDate;
    }
    try {
        return moment.utc(utcDate).toDate().toLocaleString();
    }
    catch { }
    return utcDate;
}