import {
    SIGN_UP_NEW_USER_SUCCESS,
    SIGN_UP_NEW_USER_FAILURE
} from "./actions";

const initialState = {
    signupUserResult: {
        success: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP_NEW_USER_SUCCESS:
            return { ...state, signupUserResult: { success: true, ...action.payload } };
        case SIGN_UP_NEW_USER_FAILURE:
            return { ...state, signupUserResult: { success: false, ...action.payload } };
        default:
            return state;
    }
};
