
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getEventBusinessAreaAppClients(api, eventId, itemOperation, pageKey) {
    try {
        let params = {
            operation: itemOperation
        };
        if (pageKey) {
            params.pageKey = pageKey
        }

        let result = yield call([api, api.get], `${Endpoints.events}/api/v1/events/${eventId}/businessareaappclients`, {}, { params });
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

export function* getEventBusinessAreaAppClientsRequest(api, { eventId, itemOperation, pageKey }) {
    try {
        const appClientsResult = yield call(getEventBusinessAreaAppClients, api, eventId, itemOperation, pageKey);
        if (appClientsResult && appClientsResult.Items) {
            appClientsResult.Items = appClientsResult.Items.map((item, index) => {
                let businessAreaAppClient = item.data.appClient;
                businessAreaAppClient.canAdd = false;
                businessAreaAppClient.canView = false;
                businessAreaAppClient.canEdit = false;
                businessAreaAppClient.canDelete = false;
                if (businessAreaAppClient.actions.includes("add")) {
                    businessAreaAppClient.canAdd = true;
                }
                if (businessAreaAppClient.actions.includes("view")) {
                    businessAreaAppClient.canView = true;
                }
                if (businessAreaAppClient.actions.includes("edit")) {
                    businessAreaAppClient.canEdit = true;
                }
                if (businessAreaAppClient.actions.includes("delete")) {
                    businessAreaAppClient.canDelete = true;
                }
                return item;
            });
        }
        yield put(actions.getEventBusinessAreaAppClientsSuccess(appClientsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "event business area credentials");
        yield put(actions.getEventBusinessAreaAppClientsFailure(errorObject));
    }
}

export function* watchGetEventBusinessAreaAppClientsRequest(api, { params }) {
    yield call(getEventBusinessAreaAppClientsRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_EVENT_BUSINESS_AREA_APP_CLIENTS_REQUEST, watchGetEventBusinessAreaAppClientsRequest, api);
}